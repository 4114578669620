import { ArrowDown2 } from 'iconsax-react';
import styled from 'styled-components';

interface ArrowIconProps {
  $expanded: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.one};
`;

export const Group = styled(Container)`
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const ArrowIcon = styled(ArrowDown2)<ArrowIconProps>`
  color: ${({ theme }) => theme.colors.gray.six};
  width: ${({ theme }) => theme.spacing.five};
  transform: rotate(${({ $expanded }) => ($expanded ? '-180deg' : '0deg')});
  transition: transform 0.5s;
`;

export const Label = styled.span`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.gray.eight};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-right: ${({ theme }) => theme.spacing.two};
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray.three};
`;
