import React from 'react';

import { MdfeIssuanceStatus } from 'utils/enums';
import { toISOStringWithTimezone, ufOptions } from 'utils/helpers';

import DateRangeCalendarFilter from '@components/DateRangeCalendarFilter';
import ExpandableFilter from '@components/ExpandableFilter';
import ListFilters from '@components/ListFilters';
import Select from '@components/Select';

import { Container } from './styles';

interface FiltersProps {
  queryParams: ListMDFeParams;
  setQueryParams: React.Dispatch<React.SetStateAction<ListMDFeParams>>;
  onSearch: (value: string) => void;
}

const Filters: React.FC<FiltersProps> = ({
  queryParams,
  setQueryParams,
  onSearch,
}) => {
  const mdfeStatuses = [
    MdfeIssuanceStatus.ISSUED,
    MdfeIssuanceStatus.FINISHED,
    MdfeIssuanceStatus.CANCELED,
  ];

  const handleDateRangeFilterSelected = (startDate?: Date, endDate?: Date) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      issuanceGte: startDate ? toISOStringWithTimezone(startDate) : undefined,
      issuanceLte: endDate ? toISOStringWithTimezone(endDate) : undefined,
    }));
  };

  return (
    <Container>
      <ListFilters onSearch={onSearch}>
        <DateRangeCalendarFilter
          label="Selecionar data de emissão"
          clearableDateRange
          onDateRangeFilterSelected={handleDateRangeFilterSelected}
        />
      </ListFilters>
      <ExpandableFilter>
        <Select
          value={queryParams?.status}
          onChange={(event) =>
            setQueryParams((prevState) => ({
              ...prevState,
              page: 1,
              status: event.target.value,
            }))
          }
        >
          <option value="">Situação do MDF-e: todas</option>
          {mdfeStatuses.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </Select>
        <Select
          value={queryParams?.origin}
          onChange={(event) =>
            setQueryParams((prevState) => ({
              ...prevState,
              page: 1,
              origin: event.target.value,
            }))
          }
        >
          {ufOptions.map((state) => (
            <option key={state.value} value={state.value}>
              {state.label}
            </option>
          ))}
        </Select>
        <Select
          value={queryParams?.destination}
          onChange={(event) =>
            setQueryParams((prevState) => ({
              ...prevState,
              page: 1,
              destination: event.target.value,
            }))
          }
        >
          {ufOptions.map((state) => (
            <option key={state.value} value={state.value}>
              {state.label}
            </option>
          ))}
        </Select>
      </ExpandableFilter>
    </Container>
  );
};

export default Filters;
