import styled from 'styled-components';

interface PasswordInputProps {
  invalidValue?: boolean;
}

export const Container = styled.div<PasswordInputProps>`
  display: flex;
  position: relative;
  input {
    padding-right: ${({ theme, invalidValue }) =>
      invalidValue ? '4rem' : theme.spacing.eleven};
  }
`;

export const ButtonContainer = styled.div<PasswordInputProps>`
  position: absolute;
  top: 16px;
  right: ${({ theme, invalidValue }) =>
    invalidValue ? theme.spacing.ten : theme.spacing.four};
  color: ${({ theme }) => theme.colors.gray.ten};
  font-size: ${({ theme }) => theme.fontSize.medium};
  cursor: pointer;
`;
