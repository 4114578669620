import React from 'react';

import { Tooltip } from 'react-tooltip';

import { Container, IconWrapper, EmptyWalletIcon } from './styles';

interface LabelWithPaymentIconProps {
  label: string;
  tooltipMessage?: string;
  onClick: () => void;
}

const LabelWithPaymentIcon: React.FC<LabelWithPaymentIconProps> = ({
  label,
  tooltipMessage,
  onClick,
}) => {
  const tooltipId = `label-with-link-${label}-tooltip`;

  return (
    <Container>
      {label}
      <IconWrapper onClick={onClick}>
        {tooltipMessage && <Tooltip id={tooltipId} />}
        <EmptyWalletIcon />
      </IconWrapper>
    </Container>
  );
};

export default LabelWithPaymentIcon;
