import React, { useState } from 'react';

import { DocumentCode, DocumentText } from 'iconsax-react';
import { B2Button, B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { formatToBrlDate } from 'utils/formats';

import { useScope } from '@contexts/Scope';
import { useFiscal, useQueryParams } from '@hooks';

import BaseModal from '@components/BaseModal';
import DownloadAnchor from '@components/DownloadAnchor';
import { HorizontalDivider } from '@components/MdfeIssuanceModal/HeaderButtons/DownloadsButton/styles';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import { FiscalDocDownloadModalContainer } from '../styles';
import Filters from './Filters';

const MDFeList: React.FC = () => {
  const { queryParams, changePage, setQueryParams, onSearch } =
    useQueryParams<ListMDFeParams>({
      page: 1,
      search: '',
    });

  const [selectedMDFe, setSelectedMDFe] = useState<IMdfe>();

  const { listMDFes } = useFiscal();
  const { scope } = useScope();

  const { data: paginatedMDFe, isLoading } = useQuery(
    ['listMDFe', queryParams, scope],
    () => listMDFes(queryParams)
  );

  return (
    <>
      <Filters
        onSearch={onSearch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
      <Table
        data={paginatedMDFe?.results || []}
        isLoading={isLoading}
        headerData={[
          'Nº do MDF-e',
          'Emissão',
          'Situação',
          'Motorista',
          'Placa do veículo',
          'Origem',
          'Destino',
          'Documentos',
        ]}
        emptyMessage="Nenhum MDF-e encontrado"
        renderRow={(mdfe) => (
          <B2TableRow key={mdfe.id}>
            <TableDataCell>{mdfe.number}</TableDataCell>
            <TableDataCell>{formatToBrlDate(mdfe.issued_at!)}</TableDataCell>
            <TableDataCell>{mdfe.issuance_status}</TableDataCell>
            <TableDataCell>{mdfe.departure.driver.name}</TableDataCell>
            <TableDataCell>
              {mdfe.departure.truck.truck_license_plate}
            </TableDataCell>
            <TableDataCell>
              {mdfe.departure.origin.name}/{mdfe.departure.origin.state}
            </TableDataCell>
            <TableDataCell>
              {mdfe.departure.destination?.name}/
              {mdfe.departure.destination?.state}
            </TableDataCell>
            <TableDataCell>
              <B2Button onClick={() => setSelectedMDFe(mdfe)}>
                Ver documentos
              </B2Button>
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={paginatedMDFe?.count}
        changePage={changePage}
        hasClick={false}
      />
      <BaseModal
        title="Documentos"
        onClose={() => setSelectedMDFe(undefined)}
        isOpen={!!selectedMDFe}
        onBackdropClick={() => setSelectedMDFe(undefined)}
      >
        {selectedMDFe && (
          <FiscalDocDownloadModalContainer>
            <DownloadAnchor
              href={selectedMDFe.pdf_file?.url || ''}
              label="MDF-e em PDF"
              icon={DocumentText}
            />
            <HorizontalDivider />
            <DownloadAnchor
              href={selectedMDFe.xml_file?.url || ''}
              label="MDF-e em XML"
              icon={DocumentCode}
            />
            {selectedMDFe.closure_pdf_file && (
              <>
                <HorizontalDivider />
                <DownloadAnchor
                  href={selectedMDFe.closure_pdf_file.url}
                  label="PDF de encerramento"
                  icon={DocumentText}
                />
              </>
            )}
            {selectedMDFe.closure_xml_file && (
              <>
                <HorizontalDivider />
                <DownloadAnchor
                  href={selectedMDFe.closure_xml_file.url}
                  label="XML de encerramento"
                  icon={DocumentCode}
                />
              </>
            )}
            {selectedMDFe.cancel_pdf_file && (
              <>
                <HorizontalDivider />
                <DownloadAnchor
                  href={selectedMDFe.cancel_pdf_file.url}
                  label="PDF de cancelamento"
                  icon={DocumentText}
                />
              </>
            )}
            {selectedMDFe.cancel_xml_file && (
              <>
                <HorizontalDivider />
                <DownloadAnchor
                  href={selectedMDFe.cancel_xml_file.url}
                  label="XML de cancelamento"
                  icon={DocumentCode}
                />
              </>
            )}
          </FiscalDocDownloadModalContainer>
        )}
      </BaseModal>
    </>
  );
};

export default MDFeList;
