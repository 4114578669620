import { useCallback } from 'react';

import regex from 'utils/regex';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const generateExpenseRequestObject = (expense: IExpenseFormValues) => {
  return {
    value: expense.value.replace(regex.onlyNumbers, ''),
    date: expense.date,
    provider: expense.provider!.id,
    category: expense.category!.id,
    type: expense.type,
    description: expense.description,
  };
};

const transformExpenseQueryParams = (params: IExpenseQueryParams) => {
  return {
    page: params.page,
    search: params.search || undefined,
    date__lte: params?.date__lte || undefined,
    date__gte: params?.date__gte || undefined,
    type: params?.type || undefined,
    status: params?.status || undefined,
    provider: params?.provider?.id || undefined,
    category: params?.category?.id || undefined,
  };
};

const useExpenses = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const listExpenses = useCallback(
    async (params: IExpenseQueryParams) => {
      const response = await request<IPaginated<IExpense>>({
        method: 'get',
        url: endpoints.financial.expenses.list,
        headers: { scope },
        params: transformExpenseQueryParams(params),
      });
      return response.data;
    },
    [request, scope]
  );

  const listSimplifiedExpenses = useCallback(
    async (params: IExpenseQueryParams) => {
      const response = await request<IPaginated<ISimplifiedExpense>>({
        method: 'get',
        url: endpoints.financial.expenses.byProvider.list,
        headers: { scope },
        params: transformExpenseQueryParams(params),
      });
      return response.data;
    },
    [request, scope]
  );

  const createExpense = useCallback(
    async (values: IExpenseFormValues) => {
      const response = await request<IExpense>({
        method: 'post',
        url: endpoints.financial.expenses.create,
        headers: { scope },
        data: generateExpenseRequestObject(values),
      });

      return response.data;
    },
    [request, scope]
  );

  const getExpense = useCallback(
    async (expenseId: string) => {
      const response = await request<IExpense>({
        method: 'get',
        url: endpoints.financial.expenses.get.replace(':expenseId', expenseId),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const updateExpense = useCallback(
    async (expenseId: string, values: IExpenseFormValues) => {
      const response = await request<IExpense>({
        method: 'patch',
        url: endpoints.financial.expenses.update.replace(
          ':expenseId',
          expenseId
        ),
        headers: { scope },
        data: generateExpenseRequestObject(values),
      });

      return response.data;
    },
    [request, scope]
  );

  const deleteExpense = useCallback(
    async (expenseId: number, password: string) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.financial.expenses.delete.replace(
          ':expenseId',
          expenseId.toString()
        ),
        headers: { scope },
        data: { password },
      });

      return response.data;
    },
    [request, scope]
  );

  return {
    listExpenses,
    listSimplifiedExpenses,
    createExpense,
    getExpense,
    updateExpense,
    deleteExpense,
  };
};

export default useExpenses;
