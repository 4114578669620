import React from 'react';

import { cnpj, phone } from 'b2utils';
import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useCompanies, useQueryParams } from '@hooks';

import ListFilters from '@components/ListFilters';
import PageHeader from '@components/PageHeader';
import SituationBadge from '@components/SituationBadge';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';
import TableDataLogo from '@components/TableDataLogo';

import RoutesPath from '@router/routes';

const ListCompanies: React.FC = () => {
  const { changePage, onSearch, queryParams, changeIsActiveStatus } =
    useQueryParams<IQueryParams>({ page: 1 });

  const { listCompanies } = useCompanies();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { scope } = useScope();

  const { isLoading, data: paginatedCompanies } = useQuery(
    ['companies', queryParams, scope],
    () => listCompanies(queryParams),
    {
      onError: () => {
        addToast('Não foi possível carregar as empresas', 'error');
      },
    }
  );

  const navigateToCreateCompany = () => {
    scope
      ? addToast(
          'Não é possível cadastrar uma empresa estando com uma empresa selecionada',
          'error'
        )
      : navigate(RoutesPath.private.companies.createCompany.path);
  };

  return (
    <>
      <PageHeader
        title="Empresas"
        subtitle="Listagem das empresas no sistema"
        buttonProps={{
          text: 'Cadastrar empresa',
          onClick: navigateToCreateCompany,
        }}
      />
      <ListFilters
        onSearch={onSearch}
        changeIsActiveStatus={{ queryParams, changeIsActiveStatus }}
      />
      <Table
        data={paginatedCompanies?.results || []}
        headerData={[
          'Logo',
          'Razão Social',
          'CNPJ',
          'Telefone',
          'Endereço',
          'Situação',
        ]}
        emptyMessage="Nenhuma empresa encontrada"
        isLoading={isLoading}
        renderRow={(company) => (
          <B2TableRow
            key={company.id}
            onClick={() =>
              navigate(
                RoutesPath.private.companies.detailCompany.path.replace(
                  ':companyId',
                  company.id.toString()
                )
              )
            }
          >
            <TableDataCell>
              <TableDataLogo src={company.logo?.image_high_url} />
            </TableDataCell>
            <TableDataCell>{company.name}</TableDataCell>
            <TableDataCell>{cnpj.mask(company.cnpj)}</TableDataCell>
            <TableDataCell>
              {phone.fromObject({
                areaCode: company.phone.area_code,
                number: company.phone.number,
              })}
            </TableDataCell>
            <TableDataCell>
              {company.address.city.name}/{company.address.city.state}
            </TableDataCell>
            <TableDataCell>
              <SituationBadge isActive={company.is_active} />
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={paginatedCompanies?.count || 0}
        changePage={changePage}
      />
    </>
  );
};

export default ListCompanies;
