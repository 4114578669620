import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.financial.incomes.listIncomes.path,
    label: 'Financeiro',
  },
  {
    path: RoutesPath.private.financial.financialTransactions
      .createFinancialTransaction.path,
    label: 'Cadastrar movimentação',
  },
];

export default routes;
