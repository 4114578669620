import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.providers.listProviders.path,
    label: 'Fornecedores',
  },
  {
    path: RoutesPath.private.providers.detailProvider.path,
    label: 'Detalhes do fornecedor',
  },
];

export default routes;
