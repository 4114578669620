import { AxiosError } from 'axios';
import { cep, phone, transformObjectInArray } from 'b2utils';
import {
  Bank,
  Car,
  ClipboardText,
  Clock,
  CloseCircle,
  Coin1,
  Edit2,
  EmptyWalletChange,
  House,
  MoneyRecive,
  MoneySend,
  TickCircle,
  TruckFast,
} from 'iconsax-react';
import moment from 'moment';
import { ToastType } from 'react-b2components';
import { DefaultTheme } from 'styled-components';
import * as yup from 'yup';

import { errors } from '@utils';

import { MAX_VALUE } from './constants';
import {
  CountMetricType,
  CteEndorsementStatus,
  CteIssuanceStatus,
  DepartureStatus,
  DriverVehicleType,
  ExpenseType,
  FinancialReportType,
  FinancialType,
  IcmsType,
  InspectionStatus,
  ManagementButtonType,
  MdfeIssuanceStatus,
  PaymentStatus,
  QuotationStatus,
  SendingStatus,
  ServiceStatus,
  ServiceType,
  StateRegistrationStatus,
  TakerType,
} from './enums';
import { formatDate } from './formats';
import regex from './regex';
import CarCarrierTrailer from '/images/car-carrier-trailer.svg';
import DoubleCar from '/images/double-car.svg';
import TowTruck from '/images/tow-truck.svg';

export const toDateInput = (date: Date) => {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const toDateTimeInput = (date: Date) => {
  const formattedDate = toDateInput(date);
  const formattedTime = date.toLocaleTimeString('pt-br', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${formattedDate}T${formattedTime}`;
};

export const toISOStringWithTimezone = (date: Date) => {
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? '+' : '-';
  const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    diff +
    pad(tzOffset / 60) +
    ':' +
    pad(tzOffset % 60)
  );
};

export const pluralize = (qty: number, word: string, pluralForm?: string) => {
  if (qty === 1) {
    return word;
  }

  return pluralForm ?? word + 's';
};

export const ufOptions = [
  { value: '', label: 'Selecione um estado' },
  { value: 'AC', label: 'AC - Acre' },
  { value: 'AL', label: 'AL - Alagoas' },
  { value: 'AP', label: 'AP - Amapá' },
  { value: 'AM', label: 'AM - Amazonas' },
  { value: 'BA', label: 'BA - Bahia' },
  { value: 'CE', label: 'CE - Ceará' },
  { value: 'DF', label: 'DF - Distrito Federal' },
  { value: 'ES', label: 'ES - Espírito Santo' },
  { value: 'GO', label: 'GO - Goiás' },
  { value: 'MA', label: 'MA - Maranhão' },
  { value: 'MT', label: 'MT - Mato Grosso' },
  { value: 'MS', label: 'MS - Mato Grosso do Sul' },
  { value: 'MG', label: 'MG - Minas Gerais' },
  { value: 'PA', label: 'PA - Pará' },
  { value: 'PB', label: 'PB - Paraíba' },
  { value: 'PR', label: 'PR - Paraná' },
  { value: 'PE', label: 'PE - Pernambuco' },
  { value: 'PI', label: 'PI - Piauí' },
  { value: 'RJ', label: 'RJ - Rio de Janeiro' },
  { value: 'RN', label: 'RN - Rio Grande do Norte' },
  { value: 'RS', label: 'RS - Rio Grande do Sul' },
  { value: 'RO', label: 'RO - Rondônia' },
  { value: 'RR', label: 'RR - Roraima' },
  { value: 'SC', label: 'SC - Santa Catarina' },
  { value: 'SP', label: 'SP - São Paulo' },
  { value: 'SE', label: 'SE - Sergipe' },
  { value: 'TO', label: 'TO - Tocantins' },
];

export const managementButtonTypeProps = {
  [ManagementButtonType.EDIT]: {
    icon: Edit2,
    color: (theme: DefaultTheme) => theme.colors.info,
  },
  [ManagementButtonType.DELETE]: {
    icon: CloseCircle,
    color: (theme: DefaultTheme) => theme.colors.error,
  },
};

export const toAddressString = (address: IAddress) => {
  const addressAdditionalInfo = address.additional_info
    ? ', ' + address.additional_info
    : '';

  const addressString = `${address.city.name}/${address.city.state}: ${
    address.street
  } ${address.number}${addressAdditionalInfo} - ${address.district}, ${cep.mask(
    address.zip_code
  )}`;

  return addressString;
};

export const throwToastApiErrors = (
  error: AxiosError,
  addToast: (text: string, type?: ToastType) => void
) => {
  if (error.response?.data) {
    const messages = transformObjectInArray(error.response.data);
    messages.forEach((message) => addToast(message, 'error'));
  }
};

export const generateClientRequestObject = (client: IClientFormValues) => {
  const isCpfDocument = client.document.length === 14;

  const isTaxPayer =
    client.state_registration_status === StateRegistrationStatus.TAXPAYER;

  const phoneObject = phone.toObject(client.phone);

  const formattedAddresses: Array<IAddressRequest> = client.addresses?.map(
    (address) => ({
      city: address.city.id,
      zip_code: address.zipCode.replace(regex.onlyNumbers, ''),
      additional_info: address.complement,
      district: address.district,
      street: address.street,
      number: address.number,
    })
  );

  const requestObject: IClientRequest = {
    name: client.name,
    email: client.email,
    avatar: client.avatar?.id || null,
    document: client.document?.replace(regex.onlyNumbers, ''),
    phone: {
      area_code: phoneObject.areaCode || '',
      number: phoneObject.number,
    },
    addresses: formattedAddresses,
    state_registration_status: isCpfDocument
      ? StateRegistrationStatus.NON_TAXPAYER
      : client.state_registration_status,
    state_registration:
      isTaxPayer && !isCpfDocument ? client.state_registration : null,
    date_of_birth: client.date_of_birth,
  };

  return requestObject;
};

export const numberOfDaysBetween = (firstDate: Date, secondDate: Date) => {
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  const numberOfDays = Math.abs(
    Math.round(
      (firstDate.getTime() - secondDate.getTime()) / oneDayInMilliseconds
    )
  );
  return numberOfDays;
};

const defaultDateLabelMessage = ({
  status,
  statusChangedAt,
}: {
  status: string;
  statusChangedAt: string;
}) => {
  const numberOfDays = numberOfDaysBetween(
    new Date(statusChangedAt),
    new Date()
  );

  return `${status} há ${numberOfDays} ${numberOfDays === 1 ? 'dia' : `dias`}`;
};

export const serviceStatusProps = {
  [ServiceStatus.WAITING_COLLECTION]: {
    color: (theme: DefaultTheme) => theme.colors.gray.nine,
    dateLabel: (service: IService) => {
      if (service.type === ServiceType.STORAGE) {
        return `Data de chegada ${formatDate(service?.collection_date || '')}`;
      } else {
        return `Data prevista para coleta ${formatDate(
          service?.estimated_collection_date || ''
        )}`;
      }
    },
  },
  [ServiceStatus.WAITING_DEPARTURE]: {
    color: (theme: DefaultTheme) => theme.colors.complementary.four,
    dateLabel: (service: IService) =>
      defaultDateLabelMessage({
        status: service.status,
        statusChangedAt: service.status_changed_at,
      }),
  },
  [ServiceStatus.IN_TRANSIT]: {
    color: (theme: DefaultTheme) => theme.colors.complementary.five,
    dateLabel: (service: IService) =>
      defaultDateLabelMessage({
        status: service.status,
        statusChangedAt: service.status_changed_at,
      }),
  },
  [ServiceStatus.WAITING_DELIVERY]: {
    color: (theme: DefaultTheme) => theme.colors.main,
    dateLabel: (service: IService) =>
      defaultDateLabelMessage({
        status: service.status,
        statusChangedAt: service.status_changed_at,
      }),
  },
  [ServiceStatus.FINISHED]: {
    color: (theme: DefaultTheme) => theme.colors.success,
    dateLabel: (service: IService) =>
      `Veículo entregue em ${formatDate(
        service?.delivery_receipt.delivered_at || ''
      )}`,
  },
  [ServiceStatus.CANCELED]: {
    color: (theme: DefaultTheme) => theme.colors.error,
    dateLabel: () => undefined,
  },
};

export const serviceTypeProps = {
  [ServiceType.OWN]: {
    icon: Car,
  },
  [ServiceType.THIRD_PARTY]: {
    icon: DoubleCar,
  },
  [ServiceType.STORAGE]: {
    icon: House,
  },
};

export const isCurrencyFieldValueBetween = ({
  value,
  minValue,
  maxValue,
  required,
}: {
  value?: string;
  minValue: number;
  maxValue: number;
  required: boolean;
}) => {
  const onlyNumbers = value?.replace(regex.onlyNumbers, '');
  const isEmptyField = !onlyNumbers?.length;
  const valueAsNumber = Number(onlyNumbers);

  if (required && isEmptyField) {
    return false;
  }

  if (valueAsNumber < minValue || valueAsNumber > maxValue) {
    return false;
  }

  return true;
};

export const isCurrencyFieldGraterThan = ({
  value,
  minValue,
}: {
  value?: string;
  minValue: number;
}) => {
  const onlyNumbers = value?.replace(regex.onlyNumbers, '');
  const isEmptyField = !onlyNumbers?.length;
  const valueAsNumber = Number(onlyNumbers);
  return !isEmptyField && valueAsNumber > minValue;
};

export const isCurrencyFieldLessEqualThan = ({
  value,
  maxValue,
}: {
  value?: string;
  maxValue: number;
}) => {
  const onlyNumbers = value?.replace(regex.onlyNumbers, '');
  const isEmptyField = !onlyNumbers?.length;
  const valueAsNumber = Number(onlyNumbers);
  return !isEmptyField && valueAsNumber <= maxValue;
};

export const providerTypeProps = {
  [ExpenseType.PEOPLE]: {
    color: (theme: DefaultTheme) => theme.colors.info,
  },
  [ExpenseType.VARIABLE_EXPENSE]: {
    color: (theme: DefaultTheme) => theme.colors.complementary.two,
  },
  [ExpenseType.FIXED_EXPENSE]: {
    color: (theme: DefaultTheme) => theme.colors.main,
  },
  [ExpenseType.TAX]: {
    color: (theme: DefaultTheme) => theme.colors.complementary.three,
  },
};

export const driverVehicleTypeProps = {
  [DriverVehicleType.TOW_TRUCK]: {
    icon: TowTruck,
  },
  [DriverVehicleType.CAR_CARRIER_TRAILER]: {
    icon: CarCarrierTrailer,
  },
};

export const departureStatusProps = {
  [DepartureStatus.WAITING_START]: {
    color: (theme: DefaultTheme) => theme.colors.complementary.six,
    dateLabel: (departure: IDeparture) =>
      `Data de saída ${formatDate(departure.date)}`,
  },
  [DepartureStatus.IN_TRAFFIC]: {
    color: (theme: DefaultTheme) => theme.colors.complementary.seven,
    dateLabel: (departure: IDeparture) =>
      defaultDateLabelMessage({
        status: departure.status,
        statusChangedAt: departure.status_changed_at,
      }),
  },
  [DepartureStatus.FINISHED]: {
    color: (theme: DefaultTheme) => theme.colors.success,
    dateLabel: (departure: IDeparture) =>
      `Data de chegada ${formatDate(departure.finished_at)}`,
  },
  [DepartureStatus.CANCELED]: {
    color: (theme: DefaultTheme) => theme.colors.error,
    dateLabel: (departure: IDeparture) =>
      defaultDateLabelMessage({
        status: departure.status,
        statusChangedAt: departure.status_changed_at,
      }),
  },
};

export const getNextPageParam = <Type>(
  previousData: IPaginated<Type>,
  pages: Array<IPaginated<Type>>
) => {
  if (previousData.next) {
    return pages.length + 1;
  }
};

export const validatePercentageField = (value: string) => {
  const numberValue = Number(value);
  return !!value && numberValue <= 100 && numberValue > 0;
};

export const validateTwoDecimalPlaces = (value?: string) => {
  if (!value) {
    return false;
  }

  const includeDot = value?.includes('.');

  return includeDot ? value.length - 1 - value.indexOf('.') <= 2 : true;
};

export const percentageYupSchema = (
  schema: yup.StringSchema<string | undefined, yup.AnyObject, undefined, ''>,
  fieldKey: string
) =>
  schema
    .required(errors.required)
    .test(`is-${fieldKey}-valid`, errors.percentage, validatePercentageField)
    .test(
      `has-${fieldKey}-two-decimal-places`,
      errors.maxDecimalValue(2),
      validateTwoDecimalPlaces
    );

export const isIcmsRateRequired = (type: IcmsType | undefined | null) => {
  if (!type) {
    return false;
  }
  return [
    IcmsType.NORMAL,
    IcmsType.REDUCTION,
    IcmsType.SUBSTITUTION,
    IcmsType.OTHERS,
    IcmsType.OTHER_STATE,
  ].includes(type);
};

export const isIcmsReductionRequired = (type: IcmsType | undefined | null) => {
  if (!type) {
    return false;
  }
  return [IcmsType.REDUCTION, IcmsType.OTHERS, IcmsType.OTHER_STATE].includes(
    type
  );
};

export const isOptingForCreditGrantedRequired = (
  type: IcmsType | undefined | null
) => {
  if (!type) {
    return false;
  }
  return [IcmsType.SUBSTITUTION, IcmsType.OTHERS].includes(type);
};

export const getServiceCteValidationSchema = (companyIcmsType?: string) =>
  yup.object().shape({
    cfop: yup.object().shape({
      id: yup.number().notOneOf([0], errors.required).required(errors.required),
    }),
    icmsType: yup
      .string()
      .trim()
      .required(errors.required)
      .when({
        is: () => companyIcmsType === IcmsType.SIMPLE_NATIONAL,
        then: (schema) =>
          schema.oneOf([IcmsType.SIMPLE_NATIONAL], errors.icmsType),
      }),
    icmsRate: yup
      .string()
      .trim()
      .when('icmsType', {
        is: isIcmsRateRequired,
        then: (schema) => percentageYupSchema(schema, 'icms-rate'),
      }),
    icmsReduction: yup
      .string()
      .trim()
      .when('icmsType', {
        is: isIcmsReductionRequired,
        then: (schema) => percentageYupSchema(schema, 'icms-reduction'),
      }),
    cteFreightCost: yup
      .string()
      .trim()
      .test(
        'is-freight-cost-value-valid',
        errors.currencyBetween(0, MAX_VALUE),
        (value) =>
          isCurrencyFieldValueBetween({
            value,
            minValue: 0,
            maxValue: MAX_VALUE,
            required: false,
          })
      )
      .required(errors.required),
  });

export const cteIssuanceStatusProps: {
  [key in CteIssuanceStatus]: {
    color: (theme: DefaultTheme) => string;
    fontColor?: (theme: DefaultTheme) => string;
    imageSource: string;
    tooltipMessage: string;
  };
} = {
  [CteIssuanceStatus.PENDING]: {
    color: (theme: DefaultTheme) => theme.colors.gray.nine,
    imageSource: '/images/pending-cte.svg',
    tooltipMessage: 'Emitir CT-e',
  },
  [CteIssuanceStatus.PROCESSING]: {
    color: (theme: DefaultTheme) => theme.colors.info,
    imageSource: '/images/processing-cte.svg',
    tooltipMessage: 'CT-e em processamento',
  },
  [CteIssuanceStatus.ISSUED]: {
    color: (theme: DefaultTheme) => theme.colors.success,
    imageSource: '/images/issued-cte.svg',
    tooltipMessage: 'CT-e emitido',
  },
  [CteIssuanceStatus.FAILED]: {
    color: (theme: DefaultTheme) => theme.colors.danger,
    fontColor: (theme: DefaultTheme) => theme.colors.main,
    imageSource: '/images/failed-cte.svg',
    tooltipMessage: 'Falha no CT-e',
  },
  [CteIssuanceStatus.CANCELED]: {
    color: (theme: DefaultTheme) => theme.colors.error,
    imageSource: '/images/canceled-cte.svg',
    tooltipMessage: 'CT-e cancelado',
  },
};

export const mdfeIssuanceStatusProps: {
  [key in MdfeIssuanceStatus]: {
    color: (theme: DefaultTheme) => string;
    fontColor?: (theme: DefaultTheme) => string;
    imageSource: string;
    tooltipMessage: string;
  };
} = {
  [MdfeIssuanceStatus.PENDING]: {
    color: (theme: DefaultTheme) => theme.colors.gray.nine,
    imageSource: '/images/pending-mdfe.svg',
    tooltipMessage: 'Emitir MDF-e',
  },
  [MdfeIssuanceStatus.PROCESSING]: {
    color: (theme: DefaultTheme) => theme.colors.info,
    imageSource: '/images/processing-mdfe.svg',
    tooltipMessage: 'MDF-e em processamento',
  },
  [MdfeIssuanceStatus.ISSUED]: {
    color: (theme: DefaultTheme) => theme.colors.success,
    imageSource: '/images/issued-mdfe.svg',
    tooltipMessage: 'MDF-e emitido',
  },
  [MdfeIssuanceStatus.FINISHED]: {
    color: (theme: DefaultTheme) => theme.colors.success,
    imageSource: '/images/finalized-mdfe.svg',
    tooltipMessage: 'MDF-e finalizado',
  },
  [MdfeIssuanceStatus.FAILED]: {
    color: (theme: DefaultTheme) => theme.colors.danger,
    fontColor: (theme: DefaultTheme) => theme.colors.main,
    imageSource: '/images/failed-mdfe.svg',
    tooltipMessage: 'Falha no MDF-e',
  },
  [MdfeIssuanceStatus.CANCELED]: {
    color: (theme: DefaultTheme) => theme.colors.error,
    imageSource: '/images/canceled-mdfe.svg',
    tooltipMessage: 'MDF-e cancelado',
  },
};

export const cteEndorsementStatusProps: {
  [key in CteEndorsementStatus]: {
    color: (theme: DefaultTheme) => string;
    fontColor?: (theme: DefaultTheme) => string;
    imageSource: string;
    tooltipMessage: string;
  };
} = {
  [CteEndorsementStatus.PENDING]: {
    color: (theme: DefaultTheme) => theme.colors.gray.nine,
    imageSource: '/images/pending-endorsement.svg',
    tooltipMessage: 'Seguro pendente',
  },
  [CteEndorsementStatus.PROCESSING]: {
    color: (theme: DefaultTheme) => theme.colors.info,
    imageSource: '/images/processing-endorsement.svg',
    tooltipMessage: 'Seguro em processamento',
  },
  [CteEndorsementStatus.ENDORSED]: {
    color: (theme: DefaultTheme) => theme.colors.success,
    imageSource: '/images/endorsed-endorsement.svg',
    tooltipMessage: 'Seguro averbado',
  },
  [CteEndorsementStatus.FAILED]: {
    color: (theme: DefaultTheme) => theme.colors.danger,
    fontColor: (theme: DefaultTheme) => theme.colors.main,
    imageSource: '/images/failed-endorsement.svg',
    tooltipMessage: 'Falha na averbação do seguro',
  },
  [CteEndorsementStatus.CANCELED]: {
    color: (theme: DefaultTheme) => theme.colors.error,
    imageSource: '/images/canceled-endorsement.svg',
    tooltipMessage: 'Averbação do seguro cancelada',
  },
};

export const financialTransactionTypeProps = {
  [FinancialType.INCOME]: {
    icon: MoneyRecive,
    color: (theme: DefaultTheme) => theme.colors.success,
    forecast: {
      label: 'Recebimentos',
      description: 'Total de recebimentos previsto',
    },
  },
  [FinancialType.EXPENSE]: {
    icon: MoneySend,
    color: (theme: DefaultTheme) => theme.colors.error,
    forecast: {
      label: 'Despesas',
      description: 'Total de gastos previsto',
    },
  },
  [FinancialType.TRANSFER]: {
    icon: EmptyWalletChange,
    color: (theme: DefaultTheme) => theme.colors.info,
    forecast: undefined,
  },
  [FinancialType.CAPITAL_INJECTION]: {
    icon: Coin1,
    color: (theme: DefaultTheme) => theme.colors.danger,
    forecast: undefined,
  },
};

export const financialReportTypeProps = {
  [FinancialReportType.INCOME]:
    financialTransactionTypeProps[FinancialType.INCOME],
  [FinancialReportType.EXPENSE]:
    financialTransactionTypeProps[FinancialType.EXPENSE],
  [FinancialReportType.MOVEMENT]: {
    icon: Bank,
    color: (theme: DefaultTheme) => theme.colors.info,
    forecast: undefined,
  },
};

export const paymentStatusProps = {
  [PaymentStatus.TO_RECEIVE]: {
    color: (theme: DefaultTheme) => theme.colors.danger,
  },
  [PaymentStatus.PARTIALLY_PAID]: {
    color: (theme: DefaultTheme) => theme.colors.info,
  },
  [PaymentStatus.PAID]: {
    color: (theme: DefaultTheme) => theme.colors.success,
  },
};

export const getIncomeDescription = (income: IIncome) => {
  if (income.destination) {
    return `${income.vehicle_name} - ${income.vehicle_identifier} - ${income.origin.name} (${income.origin.state}) X ${income.destination.name} (${income.destination.state})`;
  }
  return `${income.vehicle_name} - ${income.vehicle_identifier} - ${income.origin.name} (${income.origin.state})`;
};

export const getExpenseDescription = (expense: IExpense) => {
  if (expense.departure && expense.service && !expense.description) {
    return `${expense.service.vehicle.model} - ${expense.service.vehicle.identifier} - ${expense.departure.origin.name} (${expense.departure.origin.state}) X ${expense.departure.destination.name} (${expense.departure.destination.state})`;
  }

  return expense.description || '-';
};

export const quotationStatusProps = {
  [QuotationStatus.IN_PROGRESS]: {
    color: (theme: DefaultTheme) => theme.colors.info,
    icon: undefined,
  },
  [QuotationStatus.WON]: {
    color: (theme: DefaultTheme) => theme.colors.success,
    icon: TickCircle,
  },
  [QuotationStatus.LOST]: {
    color: (theme: DefaultTheme) => theme.colors.error,
    icon: CloseCircle,
  },
  [QuotationStatus.EXPIRED]: {
    color: (theme: DefaultTheme) => theme.colors.danger,
    icon: Clock,
  },
};

export const countMetricTypeProps = {
  [CountMetricType.QUOTATIONS]: {
    color: (theme: DefaultTheme) => theme.colors.complementary.five,
    icon: ClipboardText,
  },
  [CountMetricType.SERVICES]: {
    color: (theme: DefaultTheme) => theme.colors.main,
    icon: Car,
  },
  [CountMetricType.DEPARTURES]: {
    color: (theme: DefaultTheme) => theme.colors.gray.eight,
    icon: TruckFast,
  },
  [CountMetricType.INSPECTIONS]: {
    color: (theme: DefaultTheme) => theme.colors.info,
    icon: ClipboardText,
  },
};

export const inspectionStatusProps = {
  [InspectionStatus.PENDING]: {
    color: (theme: DefaultTheme) => theme.colors.gray.nine,
  },
  [InspectionStatus.IN_PROGRESS]: {
    color: (theme: DefaultTheme) => theme.colors.info,
  },
  [InspectionStatus.FINISHED]: {
    color: (theme: DefaultTheme) => theme.colors.success,
  },
};

export const sendingStatusProps = {
  [SendingStatus.PENDING]: {
    color: (theme: DefaultTheme) => theme.colors.gray.nine,
  },
  [SendingStatus.FINISHED]: {
    color: (theme: DefaultTheme) => theme.colors.success,
  },
};

export const throwBlobError = async (
  error: AxiosError,
  addToast: (text: string, type?: ToastType) => void
) => {
  const response = error.response?.data as Blob;
  const data = await response.text();
  const parsedData = JSON.parse(data);
  const messages = transformObjectInArray(parsedData);
  messages.forEach((message: string) => addToast(message, 'error'));
};

export const checkIfDateIsAfterNow = (value?: string) => {
  if (!value) {
    return false;
  }

  const date = moment(value);
  const today = moment();

  return date.isSameOrBefore(today);
};

export const getServiceTakerType = (service: IService) => {
  if (service.client_type) {
    return service.client_type;
  }

  if (service.client.id === service.sender?.id) {
    return TakerType.SENDER;
  }

  if (service.client.id === service.recipient?.id) {
    return TakerType.RECIPIENT;
  }

  return TakerType.ANOTHER;
};

export const getFormattedPercentageDifference = (
  currentValue: number,
  previousValue: number
) => {
  if (currentValue === previousValue) {
    return '0%';
  }

  if (previousValue === 0) {
    return '100%';
  }

  const percentage = ((currentValue - previousValue) / previousValue) * 100;

  return `${Math.round(percentage * 100) / 100}%`;
};
