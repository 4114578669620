import React, { useRef } from 'react';

import { phone } from 'b2utils';
import { useMutation } from 'react-query';
import { StateRegistrationStatus, UserType } from 'utils/enums';
import { formatDocument } from 'utils/formats';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useAuthentication } from '@hooks';

import BaseForm from '@components/BaseForm';
import ClientInfo from '@components/ClientForm/ClientInfo';
import Loading from '@components/Loading';

import ProfileInfoForm from './ProfileInfoForm';

export const ProfileInfo = () => {
  const profileInfoFormRef = useRef<IFormStepRef>(null);

  const { updateProfile } = useAuthentication();
  const { userInfo, updateUserInfo } = useAuth();
  const { addToast } = useToast();

  const updateProfileRequest = useMutation(
    (profileInfo: IUpdateProfileFormValues) => updateProfile(profileInfo),
    {
      onSuccess: () => {
        addToast('Informações pessoais editadas com sucesso', 'success');
        updateUserInfo();
      },
      onError: () => {
        addToast('Erro ao tentar editar informações do usuário', 'error');
      },
    }
  );

  const getIProfileInitialValues = () => {
    if (userInfo?.type === UserType.CLIENT) {
      return {
        avatar: userInfo.avatar || null,
        name: userInfo.name || '',
        email: userInfo.email || '',
        document: userInfo.document ? formatDocument(userInfo.document) : '',
        phone: userInfo.phone
          ? phone.fromObject({
              areaCode: userInfo.phone.area_code,
              number: userInfo.phone.number,
            })
          : '',
        date_of_birth: userInfo.date_of_birth || '',
        state_registration_status:
          userInfo.state_registration_status || StateRegistrationStatus.EXEMPT,
        state_registration: userInfo.state_registration || '',
      };
    }

    return {
      name: userInfo?.name || '',
      email: userInfo?.email || '',
      avatar: userInfo?.avatar || null,
    };
  };

  const steps: Array<IStep> = [
    {
      title: 'Informações pessoais',
      ref: profileInfoFormRef,
      component:
        userInfo?.type === UserType.CLIENT ? ClientInfo : ProfileInfoForm,
    },
  ];

  if (!userInfo) {
    return <Loading />;
  }

  return (
    <BaseForm
      steps={steps}
      createRequest={updateProfileRequest}
      initialValues={getIProfileInitialValues()}
    />
  );
};
