import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.four};
`;

export const FormContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.five, theme.spacing.eight)};
`;
