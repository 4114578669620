import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import { useMutation } from 'react-query';
import { Tooltip } from 'react-tooltip';
import {
  checkIfDateIsAfterNow,
  throwToastApiErrors,
  toDateInput,
} from 'utils/helpers';
import * as yup from 'yup';

import { useToast } from '@contexts/Toast';
import { useDepartures } from '@hooks';

import {
  CustomBaseModal,
  CustomFormGroup,
} from '@components/Base/PrivateBase/styles';
import { IBaseModalProps } from '@components/BaseModal';
import ConfirmationModal from '@components/ConfirmationModal';
import FormError from '@components/FormError';
import Input from '@components/Input';
import Label from '@components/Label';

import { errors } from '@utils';

import CompletionInfo from './CompletionInfo';
import ServiceList from './ServiceList';
import { CustomButton, ModalContent } from './styles';

type FinishDepartureModalProps = Omit<IBaseModalProps, 'title' | 'children'> & {
  departure: IDeparture;
  onFinalizeSuccess: () => void;
};

const FinishDepartureModal: React.FC<FinishDepartureModalProps> = ({
  isOpen,
  onClose,
  departure,
  onFinalizeSuccess,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { finishDeparture } = useDepartures();
  const { addToast } = useToast();

  const {
    mutate: requestFinishDeparture,
    isLoading: isFinishDepartureLoading,
  } = useMutation(
    (values: IFinishDepartureValues) => finishDeparture(departure.id, values),
    {
      onSuccess: () => {
        onFinalizeSuccess();
        addToast('Embarque finalizado com sucesso', 'success');
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível finalizar o embarque', 'error');
        throwToastApiErrors(error, addToast);
      },
      onSettled: () => {
        setIsConfirmationModalOpen(false);
      },
    }
  );

  const mdfeIssuanceDate = departure.mdfe.issued_at
    ? moment(departure.mdfe.issued_at).startOf('day')
    : undefined;

  const formikInitialValues: IFinishDepartureValues = {
    servicesSelectedPerPage: {},
    isAllServicesSelected: false,
    finishedAt: '',
  };

  const formikValidationSchema = yup.object().shape({
    finishedAt: yup
      .string()
      .required(errors.required)
      .test('min-finish-date', errors.mdfePastDate, (value) => {
        if (!mdfeIssuanceDate) {
          return true;
        }

        if (!value) {
          return false;
        }

        const finishedAtDate = moment(value);

        return finishedAtDate.isSameOrAfter(mdfeIssuanceDate);
      })
      .test('is-future-date', errors.futureDate, checkIfDateIsAfterNow),
  });

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: () => setIsConfirmationModalOpen(true),
  });

  return (
    <CustomBaseModal
      isOpen={isOpen}
      onClose={onClose}
      title="Finalizar embarque"
    >
      <ModalContent>
        <FormikProvider value={formik}>
          <CompletionInfo destination={departure.destination} />
          <Tooltip id="services-list-tooltip" />
          <ServiceList departureId={departure.id} />
          <CustomFormGroup>
            <Label htmlFor="finishedAt">
              Data de finalização do embarque *
            </Label>
            <Input
              id="finishedAt"
              name="finishedAt"
              type="date"
              min={
                mdfeIssuanceDate
                  ? mdfeIssuanceDate.format('YYYY-MM-DD')
                  : undefined
              }
              max={toDateInput(new Date())}
              value={formik.values.finishedAt}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalidValue={
                !!formik.touched.finishedAt && !!formik.errors.finishedAt
              }
            />
            <FormError name="finishedAt" />
          </CustomFormGroup>
          <CustomButton type="button" onClick={() => formik.handleSubmit()}>
            Finalizar
          </CustomButton>
        </FormikProvider>
      </ModalContent>
      <ConfirmationModal
        title="Tem certeza que deseja finalizar o embarque?"
        isOpen={isConfirmationModalOpen}
        isLoading={isFinishDepartureLoading}
        confirmButtonText="Sim, finalizar"
        onConfirm={() => requestFinishDeparture(formik.values)}
        cancelButtonText="Não, voltar"
        onClose={() => setIsConfirmationModalOpen(false)}
      />
    </CustomBaseModal>
  );
};

export default FinishDepartureModal;
