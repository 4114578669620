import { B2Button, B2Card } from 'react-b2components';
import styled, { css } from 'styled-components';
import { addSpacing } from 'utils/styles';

import { BaseContainer } from '@components/Base/PrivateBase/styles';

interface BaseFormProps {
  isFormModal: boolean;
}

export const CustomBaseContainer = styled(BaseContainer)<BaseFormProps>`
  ${({ isFormModal }) =>
    isFormModal &&
    css`
      animation: none;
    `};
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Card = styled(B2Card)<BaseFormProps>`
  padding: ${({ theme }) => theme.spacing.six};

  & > div:first-child {
    margin-top: -${({ theme }) => theme.spacing.two};
  }
  ${({ isFormModal }) =>
    isFormModal
      ? css`
          box-shadow: none;
          overflow: scroll;
          @media (min-height: 679px) {
            max-height: 50svh;
          }
        `
      : css`
          margin-bottom: 4rem;
        `};
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.twelve)};
  box-sizing: border-box;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.white.one};
`;

export const Button = styled(B2Button)`
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.two, theme.spacing.twelve)};
`;
