import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.two};

  & > div:first-child {
    margin-bottom: 0;
  }

  & > div:nth-child(2) {
    margin: ${({ theme }) => theme.spacing.two} 0;
  }
`;
