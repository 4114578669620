import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { Additem, UserAdd } from 'iconsax-react';
import { DepartureOwnership } from 'utils/enums';
import * as yup from 'yup';

import { useDrivers, useProviders } from '@hooks';

import AddNewEntityButton from '@components/AddNewEntityButton';
import {
  InputGroup,
  RadioButtonsContainer,
} from '@components/Base/PrivateBase/styles';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';

import {
  ChooseItemContainer,
  CustomChooseItemFilter,
} from '@pages/Services/FormSteps/styles';
import RoutesPath from '@router/routes';
import { errors } from '@utils';

import VehicleOwnerInfo from './VehicleOwnerInfo';

interface IDriverInfoProps {
  initialValues?: IDepartureDriverInfoStepForm;
  onFinish: (data: IDepartureDriverInfoStepForm) => void;
}

const DriverInfo: React.ForwardRefRenderFunction<
  IFormStepRef,
  IDriverInfoProps
> = ({ onFinish, initialValues }, ref) => {
  const { listProviders } = useProviders();
  const { listDrivers } = useDrivers();

  const departureOwnershipValues = Object.values(DepartureOwnership);

  const formikInitialValues: IDepartureDriverInfoStepForm = {
    provider: null,
    driver: null,
    ownership: undefined,
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    provider: yup
      .object()
      .nullable()
      .notOneOf([null], errors.required)
      .required(errors.required),
    driver: yup
      .object()
      .nullable()
      .notOneOf([null], errors.required)
      .required(errors.required),
    ownership: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label>Fornecedor *</Label>
          <ChooseItemContainer>
            <CustomChooseItemFilter
              filterValue={
                formik.values.provider
                  ? formik.values.provider.name
                  : 'Selecionar fornecedor'
              }
              modalTitle="Selecionar fornecedor"
              fetch={listProviders}
              queryParams={{ isActive: 'true' }}
              queryKey="providers"
              inputLabel="Selecionar fornecedor *"
              selectedItemLabel="Fornecedor selecionado"
              shouldRenderSelectedItem={!!formik.values.provider}
              onSelect={(provider) =>
                formik.setFieldValue('provider', provider)
              }
              selectedItem={formik.values.provider || ({} as IProvider)}
              renderItemText={(provider) => provider.name}
              refetchOnWindowFocus="always"
              formikErrorName="provider"
              invalidValue={
                !!formik.touched.provider && !!formik.errors.provider
              }
            />
            <AddNewEntityButton
              routePath={RoutesPath.private.providers.createProvider.path}
              tooltip="Adicionar novo fornecedor"
              icon={Additem}
            />
          </ChooseItemContainer>
          <FormError name="provider" />
        </FormGroup>
        <FormGroup>
          <Label>Motorista *</Label>
          <ChooseItemContainer>
            <CustomChooseItemFilter
              filterValue={
                formik.values.driver
                  ? formik.values.driver.name
                  : 'Selecionar motorista'
              }
              modalTitle="Selecionar motorista"
              fetch={listDrivers}
              queryParams={{ isActive: 'true' }}
              queryKey="drivers"
              inputLabel="Selecionar motorista *"
              selectedItemLabel="Motorista selecionado"
              shouldRenderSelectedItem={!!formik.values.driver}
              onSelect={(driver) => formik.setFieldValue('driver', driver)}
              selectedItem={formik.values.driver || ({} as IDriver)}
              renderItemText={(driver) => driver.name}
              refetchOnWindowFocus="always"
              formikErrorName="driver"
              invalidValue={!!formik.touched.driver && !!formik.errors.driver}
            />
            <AddNewEntityButton
              routePath={RoutesPath.private.drivers.createDriver.path}
              tooltip="Adicionar novo motorista"
              icon={UserAdd}
            />
          </ChooseItemContainer>
          <FormError name="driver" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="ownership">Tipo do veículo *</Label>
          <RadioButtonsContainer>
            {departureOwnershipValues.map((value) => (
              <InputGroup key={value}>
                <Input
                  type="radio"
                  id={`ownership${value}`}
                  name="ownership"
                  checked={formik.values.ownership === value}
                  onChange={() => formik.setFieldValue('ownership', value)}
                  onBlur={formik.handleBlur}
                />
                <Label htmlFor={`ownership${value}`}>{value}</Label>
              </InputGroup>
            ))}
          </RadioButtonsContainer>
          <FormError name="ownership" />
        </FormGroup>
      </FormRow>
      {formik.values.driver &&
        formik.values.ownership === DepartureOwnership.THIRD_PARTY && (
          <VehicleOwnerInfo driver={formik.values.driver} />
        )}
    </FormikProvider>
  );
};

export default forwardRef(DriverInfo);
