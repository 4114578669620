import React from 'react';

import { Container, Label, CloseCircleIcon } from './styles';

interface StateProps {
  state: string;
  onClick: () => void;
}

const State: React.FC<StateProps> = ({ state, onClick }) => {
  return (
    <Container>
      <Label>{state}</Label>
      <CloseCircleIcon onClick={onClick} />
    </Container>
  );
};

export default State;
