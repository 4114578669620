import { useCallback } from 'react';

import { cep } from 'b2utils';

import { useApi, endpoints } from '@contexts/Api';

const useBrasilApi = () => {
  const { requestBrasilApi } = useApi();

  const getAddressByZipCode = useCallback(
    async (value: string) => {
      const unmaskedCep = value.replace(/\D/g, '');
      const maskedCep = cep.mask(unmaskedCep);

      let response;

      try {
        response = await requestBrasilApi<IBrasilApiAddress>({
          method: 'get',
          url: endpoints.zipCode.get.replace(':zipCode', unmaskedCep),
        });
      } catch (error) {
        response = await requestBrasilApi<IBrasilApiAddress>({
          method: 'get',
          url: endpoints.zipCode.get.replace(':zipCode', maskedCep),
        });
      }

      return response.data;
    },
    [requestBrasilApi]
  );

  return { getAddressByZipCode };
};

export default useBrasilApi;
