import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';

import BaseCityForm from '@components/BaseCityForm';

import { errors } from '@utils';

interface LastServiceLocationInfoFormValues {
  city: ICity | null;
}

interface LastServiceLocationInfoProps {
  initialValues?: LastServiceLocationInfoFormValues;
  onFinish: (city: ICity) => void;
}

const LastServiceLocationInfo: React.ForwardRefRenderFunction<
  IFormStepRef,
  LastServiceLocationInfoProps
> = ({ initialValues, onFinish }, ref) => {
  const formikInitialValues: LastServiceLocationInfoFormValues = {
    city: null,
    ...initialValues,
  };

  const formikValidationSchema = object().shape({
    city: object()
      .shape({
        name: string().trim().required(errors.required),
        state: string().trim().required(errors.required),
      })
      .nullable()
      .notOneOf([null], errors.required),
  });

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: ({ city }) => onFinish(city!),
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <BaseCityForm field="city" />
    </FormikProvider>
  );
};

export default forwardRef(LastServiceLocationInfo);
