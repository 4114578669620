import RoutesPath from '@router/routes';

const getRoutes = (departureId: string) => {
  return [
    {
      path: RoutesPath.private.departures.listDepartures.path,
      label: 'Embarques',
    },
    {
      path: RoutesPath.private.departures.detailDeparture.path.replace(
        ':departureId',
        departureId
      ),
      label: 'Detalhes do embarque',
    },
    {
      path: RoutesPath.private.departures.editDeparture.path.replace(
        ':departureId',
        departureId
      ),
      label: 'Editar embarque',
    },
  ];
};

export default getRoutes;
