import React, { forwardRef } from 'react';

import { StyledSelect } from './styles';

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  invalidValue?: boolean;
  className?: string;
}

const Select: React.ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (
  { ...props },
  ref
) => {
  return (
    <StyledSelect defaultValueSelected={!props.value} ref={ref} {...props} />
  );
};
export default forwardRef(Select);
