import React from 'react';

import BasePickItem from '@components/BasePickItem';

interface GenericItem {
  id: string | number;
}

type SelectItemsProps<
  ItemType extends GenericItem,
  FetchQueryParams extends IQueryParams
> = Omit<
  BasePickItemProps<ItemType, FetchQueryParams>,
  'type' | 'shouldRenderSelectedItem'
>;

const SelectItems = <
  ItemType extends GenericItem,
  FetchQueryParams extends IQueryParams
>({
  fetch,
  queryKey,
  queryParams,
  inputLabel,
  selectedItemLabel,
  formikErrorName,
  selectedItems,
  onSelect,
  renderItemText,
  itemRoutePath,
  disabled,
}: SelectItemsProps<ItemType, FetchQueryParams>) => {
  return (
    <BasePickItem
      type="checkbox"
      fetch={fetch}
      queryKey={queryKey}
      queryParams={queryParams}
      inputLabel={inputLabel}
      selectedItemLabel={selectedItemLabel}
      shouldRenderSelectedItem={!!selectedItems.length}
      formikErrorName={formikErrorName}
      selectedItems={selectedItems}
      onSelect={onSelect}
      renderItemText={renderItemText}
      itemRoutePath={itemRoutePath}
      disabled={disabled}
    />
  );
};

export default SelectItems;
