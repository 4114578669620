import React from 'react';

import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ServiceStatus } from 'utils/enums';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useNavigateWithScope, useServices } from '@hooks';

import Loading from '@components/Loading';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import ServiceInfo from './ServiceInfo';
import getRoutes from './routes';

const DetailService: React.FC = () => {
  const { serviceId } = useParams<{ serviceId: string }>();

  const { getService, cancelService } = useServices();
  const { hasAdminPermission, isClientUser } = useAuth();
  const { addToast } = useToast();
  const { navigateWithScope } = useNavigateWithScope();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    isLoading,
    data: service,
    refetch,
  } = useQuery(['service', serviceId], () => getService(serviceId!), {
    onError: () => {
      addToast('Não foi possível carregar os dados do serviço', 'error');
      navigate(RoutesPath.private.services.listServices.path, {
        replace: true,
      });
    },
  });

  const logQueryParams: IServiceLogQueryParams = {
    pageSize: 5,
    service: Number(service?.id),
  };

  const refetchServiceData = () => {
    refetch();
    queryClient.invalidateQueries(['serviceLogs', logQueryParams]);
  };

  const { isLoading: isCancelServiceLoading, mutate: handleCancelService } =
    useMutation((password: string) => cancelService(serviceId!, password), {
      onSuccess: () => {
        addToast('Serviço cancelado com sucesso', 'success');
        refetchServiceData();
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível cancelar o serviço', 'error');
        throwToastApiErrors(error, addToast);
      },
    });

  const statusToCancel =
    !!service?.status &&
    service.status !== ServiceStatus.FINISHED &&
    service.status !== ServiceStatus.CANCELED;

  const showCancelButton = hasAdminPermission && statusToCancel;

  const handleCancelServiceClick = (password?: string) => {
    if (!password) {
      return;
    }
    handleCancelService(password);
  };

  const editButtonProps = {
    onClick: () => {
      navigateWithScope({
        routePath: RoutesPath.private.services.editService.path.replace(
          ':serviceId',
          serviceId!
        ),
        company: service?.company,
        state: service,
      });
    },
  };

  const deleteButtonProps = {
    button: {
      text: 'Cancelar',
    },
    modal: {
      title: 'Deseja realmente cancelar o serviço?',
      confirmText: 'Sim, desejo cancelar',
      onConfirm: handleCancelServiceClick,
      cancelText: 'Não, manter',
      isLoading: isCancelServiceLoading,
      passwordRequired: true,
    },
  };

  return (
    <>
      <PageHeader
        title={`Detalhes do serviço ${service?.number || ''}`}
        routes={getRoutes(serviceId!)}
        editButtonProps={!isClientUser ? editButtonProps : undefined}
        deleteButtonProps={showCancelButton ? deleteButtonProps : undefined}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <ServiceInfo service={service!} refetchServices={refetchServiceData} />
      )}
    </>
  );
};

export default DetailService;
