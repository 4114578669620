import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { cep, cnpj, phone } from 'b2utils';
import { useQuery } from 'react-query';
import { formatDate } from 'utils/formats';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useServices } from '@hooks';

import VehiclesTables from './VehiclesTable';
import {
  Container,
  Grid,
  Header,
  HeaderInfoContainer,
  Logo,
  MainTitle,
  Title,
  TitleContainer,
} from './styles';

interface IDeparturePdfProps {
  departure: IDeparture;
  isEnableRequest: boolean;
  handlePrint: () => void;
}

const DeparturePdf: React.ForwardRefRenderFunction<
  HTMLDivElement,
  IDeparturePdfProps
> = ({ departure, isEnableRequest, handlePrint }, ref) => {
  const [isEnablePrint, setIsEnablePrint] = useState(false);

  const { addToast } = useToast();
  const { listServicesWithoutPagination } = useServices();

  const { data: services } = useQuery(
    ['services', departure.id],
    () => listServicesWithoutPagination(departure.id),
    {
      enabled: isEnableRequest,
      onSuccess: () => {
        setIsEnablePrint(true);
      },

      onError: (error: AxiosError) => {
        addToast(
          'Não foi possível carregar a lista de serviços do embarque',
          'error'
        );
        throwToastApiErrors(error, addToast);
      },
    }
  );

  useEffect(() => {
    if (isEnablePrint) {
      handlePrint();
      setIsEnablePrint(false);
    }
  }, [isEnablePrint, handlePrint]);

  const getFormattedAddress = useCallback(
    (address: IAddress) =>
      `${address.street}, ${address.number}${
        address.additional_info ? address.additional_info : ''
      } - ${address.district} - ${address.city.name}/${
        address.city.state
      } - CEP: ${cep.mask(address.zip_code)}`,
    []
  );

  return (
    <div hidden>
      <Container ref={ref}>
        <Header>
          <Logo src={departure.company.logo.image_medium_url} alt="Logo" />
          <HeaderInfoContainer>
            <strong>{departure.company.name}</strong>
            <strong>CNPJ: {cnpj.mask(departure.company.cnpj)}</strong>
            <strong>
              END: {getFormattedAddress(departure.company.address)}
            </strong>
            <strong>
              SITE: {departure.company.website_url}. FONE:{' '}
              {phone.fromObject({
                areaCode: departure.company.phone.area_code,
                number: departure.company.phone.number,
              })}
            </strong>
          </HeaderInfoContainer>
        </Header>
        <MainTitle>RELAÇÃO DE CARGA</MainTitle>
        <div>
          <TitleContainer>
            <Title>INFORMAÇÕES DO MOTORISTA</Title>
          </TitleContainer>
          <Grid>
            <div>
              <strong>NOME:</strong>
              <span>{departure.driver.name}</span>
            </div>
            <div>
              <strong>CPF:</strong>
              <span>
                {departure.driver.document
                  ? cnpj.mask(departure.driver.document)
                  : ''}
              </span>
            </div>
            <div>
              <strong>TELEFONE:</strong>
              <span>
                {phone.fromObject({
                  areaCode: departure.driver.phone.area_code,
                  number: departure.driver.phone.number,
                })}
              </span>
            </div>
            <div>
              <strong>PLACA DO CAMINHÃO:</strong>
              <span>{departure.truck.truck_license_plate}</span>
            </div>
            <div>
              <strong>PLACA DA CARRETA:</strong>
              <span>{departure.truck.trailer_license_plate || '-'}</span>
            </div>
            <div>
              <strong>ANTT:</strong>
              <span>{departure.truck.antt}</span>
            </div>
          </Grid>
        </div>
        <div>
          <TitleContainer>
            <Title>INFORMAÇÕES DO EMBARQUE</Title>
          </TitleContainer>
          <Grid>
            <div>
              <strong>ORIGEM:</strong>
              <span>
                {departure.origin.name}/{departure.origin.state}
              </span>
            </div>
            <div>
              <strong>DESTINO:</strong>
              <span>
                {departure.destination.name}/{departure.destination.state}
              </span>
            </div>
            <div>
              <strong>DATA DE SAÍDA:</strong>
              <span> {formatDate(departure.date)} </span>
            </div>
          </Grid>
        </div>
        <div>
          <TitleContainer>
            <Title>
              INFORMAÇÕES{' '}
              {departure.services_count === 1 ? 'DO VEÍCULO' : 'DOS VEÍCULOS'}
            </Title>
          </TitleContainer>
          <VehiclesTables services={services || []} />
        </div>
      </Container>
    </div>
  );
};

export default forwardRef(DeparturePdf);
