import React from 'react';

import { MdfeIssuanceStatus } from 'utils/enums';
import { formatToBrlDate } from 'utils/formats';

import {
  Container,
  CustomLoading,
  IssuanceDates,
  Status,
  Title,
} from './styles';

interface IssuanceStatusLabelProps {
  mdfe: IMdfe;
  isLoading: boolean;
}

const IssuanceStatusLabel: React.FC<IssuanceStatusLabelProps> = ({
  mdfe,
  isLoading,
}) => {
  const shouldRenderStatusMessage =
    mdfe.issuance_status === MdfeIssuanceStatus.ISSUED ||
    mdfe.issuance_status === MdfeIssuanceStatus.FAILED ||
    mdfe.issuance_status === MdfeIssuanceStatus.CANCELED;

  const shouldRenderDate = mdfe.issued_at || mdfe.canceled_at;

  const getMessage = () => {
    if (mdfe.issuance_status === MdfeIssuanceStatus.FAILED) {
      return mdfe.status_message?.replace('Rejeição', '');
    } else if (mdfe.issuance_status === MdfeIssuanceStatus.CANCELED) {
      return `: ${mdfe.cancel_reason}`;
    } else {
      return `: ${mdfe.status_message}`;
    }
  };

  return (
    <>
      <Container status={mdfe.issuance_status}>
        <Title>Situação do MDF-e</Title>
        {isLoading ? (
          <CustomLoading />
        ) : (
          <Status status={mdfe.issuance_status}>
            {mdfe.issuance_status}
            {shouldRenderStatusMessage && <>{getMessage()}</>}
          </Status>
        )}
        {shouldRenderDate && (
          <IssuanceDates>
            {mdfe.issued_at && `Emitido em: ${formatToBrlDate(mdfe.issued_at)}`}
            {mdfe.canceled_at &&
              ` / Cancelado em: ${formatToBrlDate(mdfe.canceled_at)}`}
            {mdfe.closed_at &&
              ` / Finalizado em: ${formatToBrlDate(mdfe.closed_at)}`}
          </IssuanceDates>
        )}
      </Container>
    </>
  );
};

export default IssuanceStatusLabel;
