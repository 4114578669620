import React, { useMemo } from 'react';

import { CteIssuanceStatus } from 'utils/enums';

import { Container } from './styles';

interface CteStatusLabelProps {
  status?: CteIssuanceStatus;
  accessKey: string | null;
}

const CteStatusLabel: React.FC<CteStatusLabelProps> = ({
  status,
  accessKey,
}) => {
  const customStatus: CteIssuanceStatus = useMemo(() => {
    if (status) {
      return status;
    }

    return accessKey ? CteIssuanceStatus.ISSUED : CteIssuanceStatus.PENDING;
  }, [accessKey, status]);

  const tooltipMessage = useMemo(() => {
    if (status) {
      return status;
    }

    return accessKey
      ? 'Chave de acesso cadastrada'
      : 'Chave de acesso pendente';
  }, [accessKey, status]);

  return (
    <Container
      data-tooltip-id="services-list-tooltip"
      data-tooltip-content={tooltipMessage}
      status={customStatus}
    >
      {customStatus}
    </Container>
  );
};

export default CteStatusLabel;
