import React from 'react';

import { ServiceType } from 'utils/enums';

import { useAuth } from '@contexts/Auth';

import MonitoringSectionUser from '@components/MonitoringSectionUser';

import ClientCard from './ClientCard';
import Comments from './Comments';
import LogCard from './LogCard';
import ServiceCard from './ServiceCard';
import ServiceStatusesInfo from './ServiceStatusesInfo';
import {
  Container,
  Divider,
  Header,
  HorizontalGroup,
  VerticalGroup,
} from './styles';

interface ServiceInfoProps {
  service: IService;
  refetchServices: () => void;
}

const ServiceInfo: React.FC<ServiceInfoProps> = ({
  service,
  refetchServices,
}) => {
  const { isClientUser } = useAuth();

  return (
    <Container>
      <Header>
        <MonitoringSectionUser user={service.person_in_charge} />
        <Divider />
        <ServiceStatusesInfo
          service={service}
          refetchServices={refetchServices}
        />
      </Header>
      <HorizontalGroup>
        <VerticalGroup>
          <ClientCard
            client={service.client}
            clientAddress={service.client_address}
          />
          {service.type === ServiceType.OWN && (
            <LogCard serviceId={service.id} />
          )}
        </VerticalGroup>
        <ServiceCard service={service} />
      </HorizontalGroup>
      <VerticalGroup>
        {service.type !== ServiceType.OWN && <LogCard serviceId={service.id} />}
        {!isClientUser && <Comments />}
      </VerticalGroup>
    </Container>
  );
};

export default ServiceInfo;
