import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

import { headerHeight } from '../PrivateBase/styles';

export const Container = styled.div`
  width: 100svw;
  height: 100svh;
  background-color: ${({ theme }) => theme.colors.white.two};
`;

export const Header = styled.div`
  width: 100%;
  height: ${headerHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1000;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.colors.white.main};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.eight)};
`;

export const Logo = styled.img`
  cursor: pointer;
  height: ${({ theme }) => theme.spacing.twelve};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100svh - ${headerHeight}px);
  overflow: auto;
  animation: show-rightwards 1s ease-out, fade-in 1s ease-out,
    float 1s ease-in-out infinite;
`;
