import React, { useState } from 'react';

import { UserType } from 'utils/enums';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';

import {
  Container,
  PersonInfoContainer,
  PersonName,
  PersonResponsible,
  UserContainer,
  UserIcon,
  UserImage,
  UserImageModal,
} from './styles';

interface MonitoringSectionUserProps {
  user: IUser;
}

const MonitoringSectionUser: React.FC<MonitoringSectionUserProps> = ({
  user,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isDriverUser = user?.type === UserType.DRIVER;

  return (
    <>
      <Container>
        {user.avatar?.image_high_url ? (
          <UserContainer onClick={() => setIsModalOpen(!isModalOpen)}>
            <UserImage
              draggable={false}
              src={user.avatar?.image_high_url}
              alt="person in charge image"
            />
          </UserContainer>
        ) : (
          <UserIcon variant="Bold" />
        )}
        <PersonInfoContainer>
          <PersonName>{user.name}</PersonName>
          <PersonResponsible>
            {isDriverUser ? 'Motorista' : 'Responsável'}
          </PersonResponsible>
        </PersonInfoContainer>
      </Container>
      <CustomBaseModal
        title="Imagem do responsável"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(!isModalOpen)}
      >
        <UserImageModal
          draggable={false}
          src={user.avatar?.image_high_url}
          alt="Imagem do responsável"
        />
      </CustomBaseModal>
    </>
  );
};

export default MonitoringSectionUser;
