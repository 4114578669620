import React, { forwardRef, useImperativeHandle } from 'react';

import { cnpj } from 'b2utils';
import { brlMask } from 'b2utils/dist/currency';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import PasswordInput from '@components/PasswordInput';
import SectionHeader from '@components/SectionHeader';
import ToggleInput from '@components/ToggleInput';

import { errors } from '@utils';

import { CustomLabel } from './styles';

interface InsuranceFormProps {
  initialValues?: ICompanyInsuranceForm;
  initialAtmUsername?: string;
  initialAtmCod?: string;
  onFinish: (data: ICompanyInsuranceForm) => void;
}

const InsuranceForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  InsuranceFormProps
> = ({ initialValues, initialAtmUsername, initialAtmCod, onFinish }, ref) => {
  const formikInitialValues: ICompanyInsuranceForm = {
    insurerName: '',
    insurerCnpj: '',
    insurerPolicyNumber: '',
    insurerValueLimitPerLoad: '',
    isLoadUnloadingOperation: false,
    isLoadLifting: false,
    isInternalRemoval: false,
    isTracked: false,
    isEscorted: false,
    isSelfOwnedVehicle: false,
    atmUsername: '',
    atmCod: '',
    atmPassword: '',
    ...initialValues,
  };

  const validationSchema = yup.object().shape(
    {
      insurerName: yup.string().required(errors.required),
      insurerCnpj: yup
        .string()
        .trim()
        .test(
          'is-cnpj-valid',
          errors.cnpj,
          (value) => !!value && cnpj.validate(value)
        )
        .required(errors.required),
      insurerPolicyNumber: yup.string().required(errors.required),
      insurerValueLimitPerLoad: yup
        .string()
        .max(15, errors.maxLength(11))
        .required(errors.required),
      atmUsername: yup.string().when('atmCod', {
        is: (atmCod?: string) => !!atmCod,
        then: (schema) => schema.required(errors.required),
      }),
      atmCod: yup.string().when('atmUsername', {
        is: (atmUsername?: string) => !!atmUsername,
        then: (schema) => schema.required(errors.required),
      }),
      atmPassword: yup.string().when(['atmUsername', 'atmCod'], {
        is: (atmUsername?: string, atmCod?: string) => {
          const hasCredentials = !!atmUsername && !!atmCod;

          const hasUsernameChanged = atmUsername !== initialAtmUsername;
          const hasCodChanged = atmCod !== initialAtmCod;

          return hasCredentials && (hasUsernameChanged || hasCodChanged);
        },
        then: (schema) => schema.required(errors.required),
      }),
    },
    [['atmUsername', 'atmCod']]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="insurerName">Nome *</Label>
          <Input
            id="insurerName"
            name="insurerName"
            placeholder="Nome da seguradora"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.insurerName}
            invalidValue={
              !!formik.touched.insurerName && !!formik.errors.insurerName
            }
          />
          <FormError name="insurerName" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="insurerCnpj">CNPJ *</Label>
          <Input
            name="insurerCnpj"
            id="insurerCnpj"
            placeholder="CNPJ da seguradora"
            onChange={(event) =>
              formik.setFieldValue('insurerCnpj', cnpj.mask(event.target.value))
            }
            onBlur={formik.handleBlur}
            value={formik.values.insurerCnpj}
            invalidValue={
              !!formik.touched.insurerCnpj && !!formik.errors.insurerCnpj
            }
          />
          <FormError name="insurerCnpj" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="insurerPolicyNumber">Número da apólice *</Label>
          <Input
            name="insurerPolicyNumber"
            id="insurerPolicyNumber"
            placeholder="Nº da apólice"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.insurerPolicyNumber}
            invalidValue={
              !!formik.touched.insurerPolicyNumber &&
              !!formik.errors.insurerPolicyNumber
            }
          />
          <FormError name="insurerPolicyNumber" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="insurerValueLimitPerLoad">
            Valor do limite por carga da seguradora (R$) *
          </Label>
          <Input
            name="insurerValueLimitPerLoad"
            id="insurerValueLimitPerLoad"
            placeholder="Valor por carga da seguradora"
            onChange={(event) =>
              formik.setFieldValue(
                'insurerValueLimitPerLoad',
                brlMask(event.target.value)
              )
            }
            maxLength={15}
            onBlur={formik.handleBlur}
            value={brlMask(formik.values.insurerValueLimitPerLoad)}
            invalidValue={
              !!formik.touched.insurerValueLimitPerLoad &&
              !!formik.errors.insurerValueLimitPerLoad
            }
          />
          <FormError name="insurerValueLimitPerLoad" />
        </FormGroup>
      </FormRow>
      <FormGroup>
        <CustomLabel>Serviços</CustomLabel>
      </FormGroup>
      <FormRow>
        <FormGroup>
          <ToggleInput.RootToggle isChecked={formik.values.isTracked}>
            <ToggleInput.Label htmlFor="isTracked">Rastreado</ToggleInput.Label>
            <ToggleInput.Switch
              name="isTracked"
              checked={formik.values.isTracked}
              onChange={formik.setFieldValue}
            />
          </ToggleInput.RootToggle>
        </FormGroup>
        <FormGroup>
          <ToggleInput.RootToggle isChecked={formik.values.isEscorted}>
            <ToggleInput.Label htmlFor="isEscorted">
              Escoltado
            </ToggleInput.Label>
            <ToggleInput.Switch
              name="isEscorted"
              checked={formik.values.isEscorted}
              onChange={formik.setFieldValue}
            />
          </ToggleInput.RootToggle>
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <ToggleInput.RootToggle isChecked={formik.values.isLoadLifting}>
            <ToggleInput.Label htmlFor="isLoadLifting">
              Içamento de carga
            </ToggleInput.Label>
            <ToggleInput.Switch
              name="isLoadLifting"
              checked={formik.values.isLoadLifting}
              onChange={formik.setFieldValue}
            />
          </ToggleInput.RootToggle>
        </FormGroup>
        <FormGroup>
          <ToggleInput.RootToggle isChecked={formik.values.isInternalRemoval}>
            <ToggleInput.Label htmlFor="isInternalRemoval">
              Remoção interna
            </ToggleInput.Label>
            <ToggleInput.Switch
              name="isInternalRemoval"
              checked={formik.values.isInternalRemoval}
              onChange={formik.setFieldValue}
            />
          </ToggleInput.RootToggle>
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <ToggleInput.RootToggle
            isChecked={formik.values.isLoadUnloadingOperation}
          >
            <ToggleInput.Label htmlFor="isLoadUnloadingOperation">
              Operação de carga e descarga
            </ToggleInput.Label>
            <ToggleInput.Switch
              name="isLoadUnloadingOperation"
              checked={formik.values.isLoadUnloadingOperation}
              onChange={formik.setFieldValue}
            />
          </ToggleInput.RootToggle>
        </FormGroup>
        <FormGroup>
          <ToggleInput.RootToggle isChecked={formik.values.isSelfOwnedVehicle}>
            <ToggleInput.Label htmlFor="isSelfOwnedVehicle">
              Veículo de meios próprios
            </ToggleInput.Label>
            <ToggleInput.Switch
              name="isSelfOwnedVehicle"
              checked={formik.values.isSelfOwnedVehicle}
              onChange={formik.setFieldValue}
            />
          </ToggleInput.RootToggle>
        </FormGroup>
      </FormRow>
      <SectionHeader title="Credenciais da AT&M averbação" isFormHeader />
      <FormRow>
        <FormGroup>
          <Label htmlFor="atmUsername">Usuário</Label>
          <Input
            id="atmUsername"
            name="atmUsername"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.atmUsername}
            invalidValue={
              !!formik.touched.atmUsername && !!formik.errors.atmUsername
            }
          />
          <FormError name="atmUsername" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="atmCod">Código</Label>
          <Input
            id="atmCod"
            name="atmCod"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.atmCod}
            invalidValue={!!formik.touched.atmCod && !!formik.errors.atmCod}
          />
          <FormError name="atmCod" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="atmPassword">Senha</Label>
          <PasswordInput
            type="password"
            placeholder="Digite aqui"
            id="atmPassword"
            name="atmPassword"
            autoComplete="new-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.atmPassword}
            invalidValue={
              !!formik.touched.atmPassword && !!formik.errors.atmPassword
            }
          />
          <FormError name="atmPassword" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(InsuranceForm);
