import React from 'react';

import DocumentDownloadButton from '@components/DocumentDownloadButton';
import SectionHeader from '@components/SectionHeader';

import { Card, DocumentGroup, EmptyText, Label } from './styles';

interface DocumentsSectionProps {
  data: IDocumentsSection;
}

const DocumentsSection: React.FC<DocumentsSectionProps> = ({ data }) => {
  return (
    <Card>
      <SectionHeader title={data.title} />
      {data.documents.length ? (
        data.documents.map((document) => (
          <DocumentGroup key={document.url}>
            <Label>{document.name}</Label>
            <DocumentDownloadButton fileUrl={document.url} />
          </DocumentGroup>
        ))
      ) : (
        <EmptyText>Nenhum documento encontrado</EmptyText>
      )}
    </Card>
  );
};

export default DocumentsSection;
