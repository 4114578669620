import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

import ChooseItemFilter, {
  ChooseItemFilterProps,
} from '@components/ChooseItemFilter';

export const Group = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.four};
`;

export const CustomChooseItemFilter = styled(ChooseItemFilter)`
  width: auto;
  padding: ${({ theme }) => addSpacing(theme.spacing.one, theme.spacing.four)};

  span {
    color: ${({ theme }) => theme.colors.gray.eleven};
    font-size: ${({ theme }) => theme.fontSize.regular};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  }
` as <ItemType extends GenericItem, FetchQueryParams extends IQueryParams>(
  props: ChooseItemFilterProps<ItemType, FetchQueryParams>
) => React.ReactElement;
