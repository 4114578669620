import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.paymentMethods.listPaymentMethods.path,
    label: 'Métodos de pagamento',
  },
  {
    path: RoutesPath.private.paymentMethods.createPaymentMethod.path,
    label: 'Cadastrar método de pagamento',
  },
];

export default routes;
