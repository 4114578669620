import React, { useCallback } from 'react';

import { phone as phoneHelper } from 'b2utils';
import { B2TableRow } from 'react-b2components';

import { TableDataCell } from '@components/Table/styles';
import VehiclePdfTable from '@components/VehiclePdfTable';

interface DeparturesVehiclesTablesProps {
  services: Array<IService>;
}

const VehiclesTables: React.FC<DeparturesVehiclesTablesProps> = ({
  services,
}) => {
  const getRecipientInfo = useCallback((recipient: IClientUser | null) => {
    if (!recipient) {
      return '-';
    }

    const name = recipient.name.split(' ')[0];
    const phone = phoneHelper.fromObject({
      areaCode: recipient.phone.area_code,
      number: recipient.phone.number,
    });

    return `${name} / ${phone} `;
  }, []);

  return (
    <VehiclePdfTable
      data={services}
      headerData={[
        '#',
        'Cliente',
        'Veículo',
        'Identificação',
        'Origem',
        'Destino',
        'Destinatário / Telefone',
      ]}
      renderRow={(service, index) => (
        <B2TableRow key={service.id}>
          <TableDataCell>#{index + 1}</TableDataCell>
          <TableDataCell>{service.client.name}</TableDataCell>
          <TableDataCell>{service.vehicle.model}</TableDataCell>
          <TableDataCell>{service.vehicle.identifier}</TableDataCell>
          <TableDataCell>
            {service.origin.name}/{service.origin.state}
          </TableDataCell>
          <TableDataCell>
            {service.destination?.name}/{service.destination?.state}
          </TableDataCell>
          <TableDataCell>{getRecipientInfo(service.recipient)}</TableDataCell>
        </B2TableRow>
      )}
    />
  );
};

export default VehiclesTables;
