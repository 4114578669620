import React from 'react';

import { AxiosError } from 'axios';
import { currency } from 'b2utils';
import { B2Switch, B2TableRow } from 'react-b2components';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { BankAccountType } from 'utils/enums';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useBankAccounts, useNavigateWithScope, useQueryParams } from '@hooks';

import ListFilters from '@components/ListFilters';
import PageHeader from '@components/PageHeader';
import Select from '@components/Select';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import RoutesPath from '@router/routes';

import CustomRadioButton from './CustomRadioButton';

const ListBankAccounts: React.FC = () => {
  const {
    queryParams,
    setQueryParams,
    onSearch,
    changePage,
    changeIsActiveStatus,
  } = useQueryParams<IBankAccountQueryParams>({ page: 1 });

  const {
    listBankAccounts,
    updateMainBankAccount,
    updateIsActiveBankAccountStatus,
  } = useBankAccounts();
  const { isManagementUser } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { navigateWithScope } = useNavigateWithScope();

  const tableHeaderData = isManagementUser
    ? ['Nome', 'Empresa', 'Tipo', 'Saldo inicial', 'Principal', 'Situação']
    : ['Nome', 'Tipo', 'Saldo inicial', 'Principal', 'Situação'];

  const bankAccountTypes = Object.values(BankAccountType);

  const {
    data: bankAccounts,
    refetch: refetchBankAccounts,
    isLoading,
  } = useQuery(
    ['bankAccounts', queryParams, scope],
    () => listBankAccounts(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista de contas bancárias',
          'error'
        );
      },
    }
  );

  const {
    mutate: requestUpdateMainBankAccount,
    isLoading: isUpdateMainBankAccountRequestLoading,
  } = useMutation(
    (bankAccount: IBankAccount) => updateMainBankAccount(bankAccount.id),
    {
      onSuccess: () => {
        refetchBankAccounts();
        addToast('Conta bancária principal atualizada com sucesso', 'success');
      },
      onError: (error: AxiosError) => {
        addToast(
          'Não foi possível alterar a conta bancária principal',
          'error'
        );
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const { mutate: requestUpdateIsActiveBankAccountStatus } = useMutation(
    (bankAccount: IBankAccount) =>
      updateIsActiveBankAccountStatus(bankAccount.id, !bankAccount.is_active),
    {
      onSuccess: () => {
        refetchBankAccounts();
        addToast(
          'Situação da conta bancária atualizada com sucesso',
          'success'
        );
      },
      onError: (error: AxiosError) => {
        addToast(
          'Não foi possível alterar a situação da conta bancária',
          'error'
        );
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const handleSelectBankAccountType = (type: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      type: type ? (type as BankAccountType) : undefined,
    }));
  };

  const navigateToCreateBankAccountPage = () => {
    if (isManagementUser && !scope) {
      addToast(
        'Não é possível cadastrar uma conta bancária sem estar com uma empresa selecionada',
        'error'
      );
      return;
    }
    navigate(RoutesPath.private.bankAccounts.createBankAccount.path);
  };

  return (
    <>
      <PageHeader
        title="Contas bancárias"
        subtitle="Listagem de todas as contas bancárias do sistema"
        buttonProps={{
          text: 'Cadastrar conta bancária',
          onClick: navigateToCreateBankAccountPage,
        }}
      />
      <ListFilters
        onSearch={onSearch}
        changeIsActiveStatus={{ queryParams, changeIsActiveStatus }}
      >
        <Select
          value={queryParams?.type || ''}
          onChange={(event) => handleSelectBankAccountType(event.target.value)}
        >
          <option value="">Tipo de contas bancárias: todas</option>
          {bankAccountTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Select>
      </ListFilters>
      <Table
        data={bankAccounts?.results || []}
        headerData={tableHeaderData}
        emptyMessage="Nenhuma conta bancária encontrada"
        isLoading={isLoading}
        renderRow={(bankAccount) => (
          <B2TableRow
            key={bankAccount.id}
            onClick={() =>
              navigateWithScope({
                routePath:
                  RoutesPath.private.bankAccounts.updateBankAccount.path.replace(
                    ':bankAccountId',
                    bankAccount.id.toString()
                  ),
                company: bankAccount.company,
              })
            }
          >
            <TableDataCell>{bankAccount.name}</TableDataCell>
            {isManagementUser && (
              <TableDataCell>{bankAccount.company.name}</TableDataCell>
            )}
            <TableDataCell>{bankAccount.type}</TableDataCell>
            <TableDataCell>
              {currency.brlMask(bankAccount.initial_balance.toString())}
            </TableDataCell>
            <TableDataCell onClick={(event) => event.stopPropagation()}>
              <CustomRadioButton
                checked={bankAccount.is_main}
                onChange={() => {
                  if (
                    !bankAccount.is_main &&
                    !isUpdateMainBankAccountRequestLoading
                  ) {
                    requestUpdateMainBankAccount(bankAccount);
                  }
                }}
              />
            </TableDataCell>
            <TableDataCell onClick={(event) => event.stopPropagation()}>
              <B2Switch
                isChecked={bankAccount.is_active}
                onChange={() =>
                  requestUpdateIsActiveBankAccountStatus(bankAccount)
                }
              />
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={bankAccounts?.count}
        changePage={changePage}
      />
    </>
  );
};

export default ListBankAccounts;
