import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Container = styled.div`
  display: flex;
  align-self: flex-end;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const Anchor = styled.a`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.main};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: ${({ theme }) => theme.fontSize.small};
  padding: ${({ theme }) => addSpacing(theme.spacing.two, theme.spacing.eight)};

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    flex-shrink: 0;
    width: ${({ theme }) => theme.spacing.five};
    height: ${({ theme }) => theme.spacing.five};
  }
`;
