import styled from 'styled-components';

export const ImageContainer = styled.div`
  display: flex;

  @media (width <= 768px) {
    justify-content: center;
  }
`;

export const PickerColorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
