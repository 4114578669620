import React from 'react';

import { B2TableRow } from 'react-b2components';

import ModalTable from '@components/ModalTable';
import { TableDataCell } from '@components/Table/styles';

interface ServiceTableProps {
  service: IService;
}

const ServiceTable: React.FC<ServiceTableProps> = ({ service }) => {
  const headerData = service.destination
    ? ['Cliente', 'Identificador do veículo', 'Origem', 'Destino']
    : ['Cliente', 'Identificador do veículo', 'Origem'];

  return (
    <ModalTable
      data={[service.vehicle]}
      isLoading={false}
      headerData={headerData}
      emptyMessage="Nenhum veículo encontrado"
      renderRow={(vehicle) => (
        <B2TableRow key={vehicle.id}>
          <TableDataCell>{service.client.name}</TableDataCell>
          <TableDataCell>
            {vehicle.model} - {vehicle.identifier}
          </TableDataCell>
          <TableDataCell>
            {service.origin.name}/{service.origin.state}
          </TableDataCell>
          <TableDataCell>
            {service.destination?.name}/{service.destination?.state}
          </TableDataCell>
        </B2TableRow>
      )}
      hasClick={false}
    />
  );
};

export default ServiceTable;
