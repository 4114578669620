import styled from 'styled-components';
import { addSpacing, modifyColor } from 'utils/styles';

import ContactItem from '@components/ContactItem';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  gap: ${({ theme }) => theme.spacing.one};
  margin-bottom: ${({ theme }) => theme.spacing.four};
  padding: ${({ theme }) => addSpacing(theme.spacing.four, theme.spacing.six)};
  background-color: ${({ theme }) =>
    modifyColor({ hex: theme.colors.main, lightness: 0.93 })};
`;

export const CustomContactItem = styled(ContactItem)`
  span {
    color: ${({ theme }) => theme.colors.black.two};
  }
`;
