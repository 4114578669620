import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.categories.expenses.listCategories.path,
    label: 'Categorias',
  },
  {
    path: RoutesPath.private.categories.expenses.createCategory.path,
    label: 'Cadastrar categoria de despesa',
  },
];

export default routes;
