import React from 'react';

import { currency } from 'b2utils';
import { B2TableRow } from 'react-b2components';

import { TableDataCell } from '@components/Table/styles';
import VehiclePdfTable from '@components/VehiclePdfTable';

interface VehiclesTableProps {
  vehicles: Array<IVehicleQuotation>;
}

const VehiclesTable: React.FC<VehiclesTableProps> = ({ vehicles }) => {
  return (
    <VehiclePdfTable
      data={vehicles}
      headerData={[
        '#',
        'Veículo',
        'Valor para seguro',
        'Origem',
        'Destino',
        'Prazo',
        'Valor do frete',
      ]}
      renderRow={(vehicle, index) => (
        <B2TableRow key={vehicle.id}>
          <TableDataCell>#{index + 1}</TableDataCell>
          <TableDataCell>{vehicle.model}</TableDataCell>
          <TableDataCell>
            {currency.centsToBrl(vehicle.insurance_value)}
          </TableDataCell>
          <TableDataCell>
            {vehicle.origin.name}/{vehicle.origin.state}
          </TableDataCell>
          <TableDataCell>
            {vehicle.destination.name}/{vehicle.destination.state}
          </TableDataCell>
          <TableDataCell>{vehicle.estimated_delivery_days}</TableDataCell>
          <TableDataCell>
            {currency.centsToBrl(vehicle.freight_value)}
          </TableDataCell>
        </B2TableRow>
      )}
    />
  );
};

export default VehiclesTable;
