import { color } from 'b2utils';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

import Loading from '@components/Loading';
import SearchFilter from '@components/SearchFilter';

interface ItemContainerProps {
  checked: boolean;
  disabled: boolean;
}

export const CustomSearchFilter = styled(SearchFilter)`
  border-radius: 12px;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.colors.white.two};
`;

export const Content = styled.div`
  width: 100%;
  max-height: 290px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: ${({ theme }) => theme.spacing.two};
  margin: ${({ theme }) =>
    addSpacing(theme.spacing.two, '0', theme.spacing.three)};
`;

export const ItemContainer = styled.div<ItemContainerProps>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.three};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.four)};
  border-radius: 12px;
  transition: all ease-in 0.25s;
  background-color: ${({ checked, theme }) =>
    checked
      ? color.addOpacityOnHexColor(theme.colors.main, 0.1)
      : theme.colors.white.three};

  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  input:disabled {
    cursor: not-allowed;
  }

  cursor: ${({ disabled }) => disabled && 'not-allowed'};

  &:has(div > a:hover) {
    opacity: 1;
  }
`;

export const SelectedItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomLoading = styled(Loading)`
  flex-shrink: 0;
`;
