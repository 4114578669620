import { B2Card } from 'react-b2components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.four};
`;

export const MainCard = styled(B2Card)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.four};
  padding: ${({ theme }) => theme.spacing.five};
`;
