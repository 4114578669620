import React from 'react';

import { Description, Title } from 'components/Base/AuthBase/styles';

import PublicBase from '@components/Base/PublicBase';

import UpdatePasswordForm from './UpdatePasswordForm';
import {
  Container,
  InfoContainer,
  LeftPanel,
  RightPanel,
  SecureLoginImage,
} from './styles';

const UpdatePassword: React.FC = () => {
  return (
    <PublicBase>
      <Container>
        <LeftPanel>
          <InfoContainer>
            <Title>Atualizar senha</Title>
            <Description>
              Insira a sua nova senha e confirme para atualizá-la.
            </Description>
          </InfoContainer>
          <UpdatePasswordForm />
        </LeftPanel>
        <RightPanel>
          <SecureLoginImage src="/images/secure-login.svg" alt="Secure Login" />
        </RightPanel>
      </Container>
    </PublicBase>
  );
};

export default UpdatePassword;
