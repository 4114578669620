import { color as b2utilsColor } from 'b2utils';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    to {
        transform: rotate(1turn);
    }
`;

export const Loading = styled.div<{ color?: string; size?: string }>`
  width: ${({ theme, size }) => size ?? theme.spacing.seven};
  height: ${({ theme, size }) => size ?? theme.spacing.seven};
  border: 4px solid transparent;
  border-top-color: ${({ theme, color }) =>
    color ?? b2utilsColor.addOpacityOnHexColor(theme.colors.main, 0.8)};
  border-radius: 50%;
  animation: ${rotate} 1s infinite;
  margin: 0 auto;
`;

export default Loading;
