import React, { useCallback, useState } from 'react';

import { UseMutationResult } from 'react-query';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';

import { IBaseModalProps } from '@components/BaseModal';

import Header from './Header';
import { Body, Button, Footer, Modal } from './styles';

interface BaseFormModalProps
  extends Omit<IBaseModalProps, 'title' | 'children'> {
  steps: Array<IModalStep>;
  initialValues?: any;
  lastButtonText?: string;
  submitFormRequest: UseMutationResult<any, unknown, any, unknown>;
}

const BaseFormModal: React.FC<BaseFormModalProps> = ({
  steps,
  initialValues,
  lastButtonText = 'Salvar',
  submitFormRequest,
  ...props
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);

  const { addToast } = useToast();

  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === steps.length - 1;

  const handleSubmit = useCallback(
    async <Type,>(data: Type) => {
      const newFormValues = { ...formValues, ...data };
      setFormValues(newFormValues);
      if (steps[currentStep].saveStepData) {
        steps[currentStep].saveStepData!(newFormValues);
      }
      if (currentStep !== steps.length - 1) {
        setCurrentStep(currentStep + 1);
      } else {
        try {
          await submitFormRequest.mutateAsync(newFormValues);
        } catch (error: any) {
          throwToastApiErrors(error, addToast);
        }
      }
    },
    [addToast, currentStep, formValues, steps, submitFormRequest]
  );
  const handleClose = useCallback(() => {
    props.onClose();
    setCurrentStep(0);
  }, [props]);

  const handleBackdropClick = useCallback(() => {
    if (props.onBackdropClick) {
      props.onBackdropClick();
      setCurrentStep(0);
    }
  }, [props]);

  const handleStepForward = useCallback(() => {
    steps[currentStep].ref?.current?.submit?.();
  }, [currentStep, steps]);

  const renderCurrentStep = () => {
    const step = steps[currentStep];

    return (
      <step.component
        ref={step.ref}
        initialValues={formValues}
        onFinish={handleSubmit}
        {...step.props}
      />
    );
  };

  return (
    <Modal {...props} onBackdropClick={handleBackdropClick}>
      <Header
        title={steps[currentStep].title}
        isFirstStep={isFirstStep}
        onBack={() => !isFirstStep && setCurrentStep(currentStep - 1)}
        onClose={handleClose}
      />
      <Body>{renderCurrentStep()}</Body>
      <Footer>
        {isLastStep ? (
          <Button type="button" onClick={handleStepForward}>
            {lastButtonText}
          </Button>
        ) : (
          <Button
            type="button"
            variant="transparent"
            onClick={handleStepForward}
          >
            Próximo
          </Button>
        )}
      </Footer>
    </Modal>
  );
};

export default BaseFormModal;
