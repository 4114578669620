import { color } from 'b2utils';
import { B2Button } from 'react-b2components';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) =>
    addSpacing('0', theme.spacing.eight, theme.spacing.four)};
`;

export const CustomButton = styled(B2Button)`
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 10px;
  gap: ${({ theme }) => theme.spacing.two};
  font-size: ${({ theme }) => theme.fontSize.small};
  padding: ${({ theme }) => addSpacing(theme.spacing.one, theme.spacing.three)};
  color: ${({ theme }) => theme.colors.main};
  background-color: ${({ theme }) =>
    color.addOpacityOnHexColor(theme.colors.main, 0.2)};
  animation: fade-in 0.5s ease-out;

  svg {
    flex-shrink: 0;
    width: ${({ theme }) => theme.spacing.five};
    height: ${({ theme }) => theme.spacing.five};
  }
`;
