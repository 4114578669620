import { color } from 'b2utils';
import { TrendDown, TrendUp } from 'iconsax-react';
import { B2Card } from 'react-b2components';
import styled, { css } from 'styled-components';
import { QuotationStatus } from 'utils/enums';
import { quotationStatusProps } from 'utils/helpers';

import Loading from '@components/Loading';

export const Container = styled(B2Card)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.four};
  padding: ${({ theme }) => theme.spacing.five};
`;

export const MainGroup = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.four};
`;

export const MetricTitle = styled.p`
  color: ${({ theme }) => theme.colors.gray.seven};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const MetricValue = styled.p`
  margin-top: ${({ theme }) => theme.spacing.one};
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const IconWrapper = styled.div<{ status?: QuotationStatus }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: ${({ theme }) => theme.spacing.five};

  ${({ theme, status }) =>
    status
      ? css`
          color: ${quotationStatusProps[status].color(theme)};
          background-color: ${color.addOpacityOnHexColor(
            quotationStatusProps[status].color(theme),
            0.2
          )};
        `
      : css`
          color: ${theme.colors.complementary.five};
          background-color: ${color.addOpacityOnHexColor(
            theme.colors.complementary.five,
            0.2
          )};
        `}

  svg {
    flex-shrink: 0;
    width: ${({ theme }) => theme.spacing.eight};
    height: ${({ theme }) => theme.spacing.eight};
  }
`;

const baseTrendIconStyles = css`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.six};
  height: ${({ theme }) => theme.spacing.six};
`;

export const TrendUpIcon = styled(TrendUp)`
  ${baseTrendIconStyles}
  color: ${({ theme }) => theme.colors.success};
`;

export const TrendDownIcon = styled(TrendDown)`
  ${baseTrendIconStyles}
  color: ${({ theme }) => theme.colors.error};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.gray.twelve};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const TrendUpText = styled.span`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.success};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const TrendDownText = styled(TrendUpText)`
  color: ${({ theme }) => theme.colors.error};
`;

export const CustomLoading = styled(Loading)`
  margin: 0;
`;
