import RoutesPath from '@router/routes';

const getRoutes = (quotationId: string) => {
  return [
    {
      path: RoutesPath.private.quotations.listQuotations.path,
      label: 'Orçamentos',
    },
    {
      path: RoutesPath.private.quotations.updateQuotation.path.replace(
        ':quotationId',
        quotationId
      ),
      label: 'Editar orçamentos',
    },
  ];
};

export default getRoutes;
