import { color } from 'b2utils';
import { css } from 'styled-components';

interface InputProps {
  invalidValue?: boolean;
}

export const commonInputStyle = css<InputProps>`
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing.three};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.four)};
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.black.two};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: ${({ theme }) => theme.colors.white.two};

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray.four};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.info};
    outline: 2px solid
      ${({ theme }) => color.addOpacityOnHexColor(theme.colors.info, 0.1)};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  ${({ theme, invalidValue }) =>
    invalidValue &&
    css`
      border: 1px solid ${theme.colors.error};
      padding-right: ${theme.spacing.eleven};
      background-position-y: 50%;
      background-repeat: no-repeat;
      background-position-x: calc(100% - ${theme.spacing.four});
      background-size: ${theme.spacing.three};
      background-image: url('/images/close.svg');
      outline: 2px solid ${color.addOpacityOnHexColor(theme.colors.error, 0.1)};

      &:focus {
        border: 1px solid ${theme.colors.error};
        outline: 2px solid
          ${color.addOpacityOnHexColor(theme.colors.error, 0.1)};
      }
    `}
`;

export const iconButtonStyle = css`
  flex-shrink: 0;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:active {
    transform: scale(0.85);
  }
  &:hover {
    opacity: 0.8;
  }
`;

export const addSpacing = (
  top: string,
  right: string = top,
  bottom: string = top,
  left: string = right
) => {
  return `${top} ${right} ${bottom} ${left}`;
};

export const modifyColor = ({
  hex,
  saturation = undefined,
  lightness = undefined,
}: {
  hex: string;
  saturation?: number;
  lightness?: number;
}) => {
  hex = hex.replace('#', '');

  const red = parseInt(hex.substring(0, 2), 16) / 255;
  const green = parseInt(hex.substring(2, 4), 16) / 255;
  const blue = parseInt(hex.substring(4, 6), 16) / 255;

  const max = Math.max(red, green, blue);
  const min = Math.min(red, green, blue);

  let hue;
  if (max === min) {
    hue = 0;
  } else if (max === red) {
    hue = ((green - blue) / (max - min)) % 6;
  } else if (max === green) {
    hue = (blue - red) / (max - min) + 2;
  } else {
    hue = (red - green) / (max - min) + 4;
  }
  hue = Math.round(hue * 60);

  saturation = saturation
    ? saturation * 100
    : Math.round(((max - min) / max) * 100);

  lightness = lightness ? lightness * 100 : Math.round(((max + min) / 2) * 100);

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};
