import React from 'react';

import { useFormikContext } from 'formik';
import { Send } from 'iconsax-react';

import { CustomFormGroup } from '@components/Base/PrivateBase/styles';
import FormError from '@components/FormError';
import Input from '@components/Input';
import Label from '@components/Label';
import Loading from '@components/Loading';

import { Container, CustomButton } from './styles';

interface ICommentFormik {
  text: string;
}

interface CommentFormProps {
  id: string;
  isEditing?: boolean;
  isLoading: boolean;
}

const CommentForm: React.FC<CommentFormProps> = ({
  id,
  isEditing = false,
  isLoading,
}) => {
  const formik = useFormikContext<ICommentFormik>();

  return (
    <>
      <Container>
        <CustomFormGroup>
          {isEditing && <Label htmlFor={id}>Comentário</Label>}
          <Container>
            <Input
              id={id}
              name="text"
              placeholder="Adicionar comentário"
              onChange={formik.handleChange}
              onKeyUp={(e) => e.key === 'Enter' && formik.handleSubmit()}
              value={formik.values.text}
              invalidValue={!!formik.errors.text && !!formik.touched.text}
            />
            <CustomButton
              type="submit"
              onClick={() => formik.handleSubmit()}
              disabled={isLoading}
            >
              {isLoading ? <Loading color="white" /> : <Send />}
            </CustomButton>
          </Container>
        </CustomFormGroup>
      </Container>
      <FormError name="text" />
    </>
  );
};

export default CommentForm;
