import Lottie from 'lottie-react';
import styled from 'styled-components';

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DownloadingLottie = styled(Lottie)`
  width: 30%;
  min-width: 13.5rem;
  max-width: 330px;

  @media (max-width: 425px) {
    width: 50%;
  }
`;
