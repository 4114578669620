import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.quotations.listQuotations.path,
    label: 'Orçamento',
  },
  {
    path: RoutesPath.private.quotations.createQuotation.path,
    label: 'Cadastrar orçamento',
  },
];

export default routes;
