import React, { useState } from 'react';

import { useTheme } from 'styled-components';

import { Container, CustomSkeleton, Image } from './styles';

interface InspectionImageProps {
  image: IImage;
  carouselPosition: number;
}

const InspectionImage: React.FC<InspectionImageProps> = ({
  image,
  carouselPosition,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const theme = useTheme();

  return (
    <>
      {isLoading && (
        <CustomSkeleton
          baseColor={theme.colors.gray.one}
          highlightColor={theme.colors.white.main}
        />
      )}
      <Container index={carouselPosition}>
        <Image
          onLoad={() => setIsLoading(false)}
          src={image.image_high_url}
          alt="Imagem da vistoria"
        />
      </Container>
    </>
  );
};

export default InspectionImage;
