import React, { useRef, useState } from 'react';

import { ChromePicker } from 'react-color';

import { useOutsideClick } from '@hooks';

import { Container, PickerContainer, SelectedColor } from './styles';

interface ColorPickerProps {
  color: string;
  onChange: (color: string) => void;
}

export const ColorPicker = ({ color, onChange }: ColorPickerProps) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const selectedColorRef = useRef<HTMLDivElement>(null);

  useOutsideClick(selectedColorRef, () => setIsPickerOpen(false));

  return (
    <Container
      ref={selectedColorRef}
      onClick={() => setIsPickerOpen(!isPickerOpen)}
    >
      <SelectedColor color={color} />
      <span>{color}</span>
      {isPickerOpen && (
        <PickerContainer onClick={(event) => event.stopPropagation()}>
          <ChromePicker
            color={color}
            onChange={(color) => onChange(color.hex)}
          />
        </PickerContainer>
      )}
    </Container>
  );
};
