import { B2Card } from 'react-b2components';
import styled from 'styled-components';

export const Card = styled(B2Card)`
  display: flex;
  flex-direction: column;
  height: min-content;
`;

export const Header = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing.four};
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Content = styled.div`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.two};
`;
