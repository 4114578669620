import React from 'react';

import { ServiceStatus } from 'utils/enums';

import { Container, Label, Square } from './styles';

interface ServiceStatusLabelProps {
  status: ServiceStatus;
}

const ServiceStatusLabel: React.FC<ServiceStatusLabelProps> = ({ status }) => {
  return (
    <Container>
      <Square status={status} />
      <Label status={status}>{status}</Label>
    </Container>
  );
};

export default ServiceStatusLabel;
