import React, { useMemo } from 'react';

import moment from 'moment';
import { useQueries } from 'react-query';
import { CountMetricType } from 'utils/enums';
import {
  countMetricTypeProps,
  getFormattedPercentageDifference,
  throwToastApiErrors,
} from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useMetrics } from '@hooks';

import { MetricCard } from '../styles';
import {
  CustomLoading,
  Footer,
  IconWrapper,
  MainGroup,
  MetricTitle,
  MetricValue,
  TrendDownIcon,
  TrendDownText,
  TrendUpIcon,
  TrendUpText,
} from './styles';

interface CountMetricCardProps {
  yearMonth: string;
  type: CountMetricType;
}

const CountMetricCard: React.FC<CountMetricCardProps> = ({
  yearMonth,
  type,
}) => {
  const {
    getQuotationsCount,
    getServicesCount,
    getDeparturesCount,
    getInspectionsCount,
  } = useMetrics();
  const { isCompanyUser, isManagementUser } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();

  const areQueriesEnabled = !!scope || isCompanyUser;
  const isManagementWithoutScope = isManagementUser && !scope;

  const countMetricRequest = {
    [CountMetricType.QUOTATIONS]: getQuotationsCount,
    [CountMetricType.SERVICES]: getServicesCount,
    [CountMetricType.DEPARTURES]: getDeparturesCount,
    [CountMetricType.INSPECTIONS]: getInspectionsCount,
  };

  const currentMonthQueryParams: IQuotationsCountQueryParams = {
    yearMonth,
  };

  const previousMonthQueryParams: IQuotationsCountQueryParams = {
    yearMonth: moment(yearMonth).subtract(1, 'month').format('YYYY-MM'),
  };

  const [fetchCurrentMonthCountMetric, fetchPreviousMonthCountMetric] =
    useQueries([
      {
        queryKey: [`countMetric${type}`, currentMonthQueryParams, scope],
        enabled: areQueriesEnabled,
        queryFn: () => countMetricRequest[type](currentMonthQueryParams),
        onError: (error: any) => {
          addToast(
            `Não foi possível carregar as métricas dos ${type.toLocaleLowerCase}`,
            'error'
          );
          throwToastApiErrors(error, addToast);
        },
      },
      {
        queryKey: [`countMetric${type}`, previousMonthQueryParams, scope],
        enabled: areQueriesEnabled,
        queryFn: () => countMetricRequest[type](previousMonthQueryParams),
        onError: (error: any) => {
          addToast(
            `Não foi possível carregar as métricas dos ${type.toLocaleLowerCase}`,
            'error'
          );
          throwToastApiErrors(error, addToast);
        },
      },
      {
        queryKey: [`countMetric${type}`, currentMonthQueryParams],
        enabled: areQueriesEnabled,
        queryFn: () => countMetricRequest[type](currentMonthQueryParams),
        onError: (error: any) => {
          addToast(
            `Não foi possível carregar as métricas dos ${type.toLocaleLowerCase}`,
            'error'
          );
          throwToastApiErrors(error, addToast);
        },
      },
      {
        queryKey: [`countMetric${type}`, previousMonthQueryParams],
        enabled: areQueriesEnabled,
        queryFn: () => countMetricRequest[type](previousMonthQueryParams),
        onError: (error: any) => {
          addToast(
            `Não foi possível carregar as métricas das ${type.toLocaleLowerCase}`,
            'error'
          );
          throwToastApiErrors(error, addToast);
        },
      },
    ]);

  const isLoading =
    fetchCurrentMonthCountMetric.isLoading ||
    fetchPreviousMonthCountMetric.isLoading;

  const { icon: Icon } = countMetricTypeProps[type];

  const currentValue = fetchCurrentMonthCountMetric.data?.count || 0;
  const previousValue = fetchPreviousMonthCountMetric.data?.count || 0;

  const formattedPercentageValue = useMemo(() => {
    if (isManagementWithoutScope) {
      return '-';
    }

    return getFormattedPercentageDifference(currentValue, previousValue);
  }, [currentValue, isManagementWithoutScope, previousValue]);

  return (
    <MetricCard>
      <MainGroup>
        <div>
          <MetricTitle>{type}</MetricTitle>
          {isLoading ? (
            <CustomLoading />
          ) : (
            <MetricValue>
              {isManagementWithoutScope ? '-' : currentValue}
            </MetricValue>
          )}
        </div>
        <IconWrapper type={type}>
          <Icon variant="Bold" />
        </IconWrapper>
      </MainGroup>
      {!isLoading && (
        <Footer>
          {currentValue >= previousValue ? (
            <>
              <TrendUpIcon />
              <TrendUpText>{formattedPercentageValue}</TrendUpText>
            </>
          ) : (
            <>
              <TrendDownIcon />
              <TrendDownText>{formattedPercentageValue}</TrendDownText>
            </>
          )}
          comparado ao mês anterior
        </Footer>
      )}
    </MetricCard>
  );
};

export default CountMetricCard;
