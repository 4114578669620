import { addOpacityOnHexColor } from 'b2utils/dist/color';
import { CloseSquare } from 'iconsax-react';
import { B2ImagePicker } from 'react-b2components';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const CustomImagePicker = styled(B2ImagePicker)`
  background-color: ${({ theme }) =>
    addOpacityOnHexColor(theme.colors.main, 0.2)};
  border-radius: ${({ theme }) => theme.borderRadius};
  width: 16rem;
  user-select: none;
  justify-content: center;
  img {
    object-fit: contain;
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`;

export const DeleteImage = styled(CloseSquare)`
  position: absolute;
  width: ${({ theme }) => theme.spacing.seven};
  height: ${({ theme }) => theme.spacing.seven};
  color: ${({ theme }) => theme.colors.error};
  top: -10px;
  right: -10px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  z-index: 10;

  &:active {
    transform: scale(0.5);
  }
`;
