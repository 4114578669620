import React from 'react';

import { DepartureStatus } from 'utils/enums';

import {
  Container,
  ProgressContainer,
  ProgressStatusItem,
  ProgressText,
  ProgressTextContainer,
  TruckFastIcon,
} from './styles';

interface StatusProgressProps {
  currentStatus: DepartureStatus;
}

const StatusProgress: React.FC<StatusProgressProps> = ({ currentStatus }) => {
  const departureStatuses = Object.values(DepartureStatus);
  const currentStatusIndex = departureStatuses.indexOf(currentStatus);

  return (
    <Container>
      <TruckFastIcon variant="Bold" />
      <ProgressTextContainer>
        <ProgressText>Histórico de etapas</ProgressText>
        <ProgressContainer>
          {departureStatuses.map(
            (status, index) =>
              index <= currentStatusIndex && (
                <ProgressStatusItem
                  key={status}
                  isCurrent={index === currentStatusIndex}
                >
                  <span>{currentStatusIndex >= index ? status : ''}</span>
                  <span>{currentStatusIndex > index ? ' > ' : ''}</span>
                </ProgressStatusItem>
              )
          )}
        </ProgressContainer>
      </ProgressTextContainer>
    </Container>
  );
};

export default StatusProgress;
