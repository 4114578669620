import RoutesPath from '@router/routes';

const routes = [
  { path: RoutesPath.private.companies.listCompanies.path, label: 'Empresas' },
  {
    path: RoutesPath.private.companies.detailCompany.path,
    label: 'Detalhes da empresa',
  },
];

export default routes;
