import styled, { css } from 'styled-components';

export const Option = styled.option<{ isSelected: boolean }>`
  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      color: ${theme.colors.white.main} !important;
      background-color: ${theme.colors.main} !important;
    `}
`;

export const RoutesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.two};
  margin-top: ${({ theme }) => theme.spacing.two};
`;
