import React from 'react';

import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ExpenseType } from 'utils/enums';
import { formatDocument } from 'utils/formats';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useProviders, useQueryParams } from '@hooks';

import ExpenseTypeLabel from '@components/ExpenseTypeLabel';
import ListFilters from '@components/ListFilters';
import PageHeader from '@components/PageHeader';
import Select from '@components/Select';
import SituationBadge from '@components/SituationBadge';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import RoutesPath from '@router/routes';

const ListProviders: React.FC = () => {
  const {
    queryParams,
    setQueryParams,
    onSearch,
    changePage,
    changeIsActiveStatus,
  } = useQueryParams<IProviderQueryParams>({ page: 1 });

  const { listProviders } = useProviders();
  const { isManagementUser } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const providerTypes = Object.values(ExpenseType);

  const { data: providers, isLoading } = useQuery(
    ['providers', queryParams, scope],
    () => listProviders(queryParams),
    {
      onError: () => {
        addToast('Não foi possível carregar a lista de fornecedores', 'error');
      },
    }
  );

  const handleSelectExpenseType = (type: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      type: type ? (type as ExpenseType) : undefined,
    }));
  };

  const navigateToCreateProviderPage = () => {
    if (isManagementUser && !scope) {
      addToast(
        'Não é possível cadastrar um fornecedor sem estar com uma empresa selecionada',
        'error'
      );
      return;
    }
    navigate(RoutesPath.private.providers.createProvider.path);
  };

  const navigateToProviderDetailPage = (providerId: number) => {
    navigate(
      RoutesPath.private.providers.detailProvider.path.replace(
        ':providerId',
        providerId.toString()
      )
    );
  };

  return (
    <>
      <PageHeader
        title="Fornecedores"
        subtitle="Listagem de todos os fornecedores do sistema"
        buttonProps={{
          text: 'Cadastrar fornecedor',
          onClick: navigateToCreateProviderPage,
        }}
      />
      <ListFilters
        onSearch={onSearch}
        changeIsActiveStatus={{ queryParams, changeIsActiveStatus }}
      >
        <Select
          value={queryParams?.type || ''}
          onChange={(event) => handleSelectExpenseType(event.target.value)}
        >
          <option value="">Tipo de fornecedor: todos</option>
          {providerTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Select>
      </ListFilters>
      <Table
        data={providers?.results || []}
        headerData={['Tipo', 'Nome', 'Descrição', 'CNPJ/CPF', 'Situação']}
        emptyMessage="Nenhum fornecedor encontrado"
        isLoading={isLoading}
        renderRow={(provider) => (
          <B2TableRow
            key={provider.id}
            onClick={() => navigateToProviderDetailPage(provider.id)}
          >
            <TableDataCell>
              <ExpenseTypeLabel type={provider.type} />
            </TableDataCell>
            <TableDataCell>{provider.name}</TableDataCell>
            <TableDataCell>{provider.description}</TableDataCell>
            <TableDataCell>
              {provider.document
                ? formatDocument(provider.document)
                : 'Não informado'}
            </TableDataCell>
            <TableDataCell>
              <SituationBadge isActive={provider.is_active} />
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={providers?.count}
        changePage={changePage}
      />
    </>
  );
};

export default ListProviders;
