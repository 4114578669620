import React, { useRef } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useQuotations, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';

import RoutesPath from '@router/routes';

import QuotationInfo from '../Form/QuotationInfo';
import VehiclesQuotation from '../Form/VehiclesQuotation';
import routes from './routes';

const CreateQuotation: React.FC = () => {
  const quotationInfoStepFormRef = useRef<IFormStepRef>(null);
  const vehiclesQuotationStepFormRef = useRef<IFormStepRef>(null);

  const { createQuotation } = useQuotations();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const createQuotationRequest = useMutation(
    (values: IQuotationFormValues) => createQuotation(values),
    {
      onSuccess: () => {
        addToast('Orçamento cadastrado com sucesso', 'success');
        navigate(RoutesPath.private.quotations.listQuotations.path, {
          replace: true,
        });
      },
      onError: () => {
        addToast('Erro ao cadastrar orçamento', 'error');
      },
    }
  );

  useVerifyScope({
    routePath: RoutesPath.private.quotations.listQuotations.path,
    toastErrorMessage: 'Selecione uma empresa para cadastrar um orçamento',
  });

  const steps: Array<IStep> = [
    {
      title: 'Informações do orçamento',
      ref: quotationInfoStepFormRef,
      component: QuotationInfo,
    },
    {
      title: 'Veículos',
      ref: vehiclesQuotationStepFormRef,
      component: VehiclesQuotation,
    },
  ];

  return (
    <BaseForm
      steps={steps}
      createRequest={createQuotationRequest}
      routes={routes}
      title="Cadastrar orçamento"
    />
  );
};

export default CreateQuotation;
