import React from 'react';

import { Icon as IconType } from 'iconsax-react';
import { Tooltip } from 'react-tooltip';

import { IconContainer } from './styles';

interface AddNewEntityButtonProps {
  routePath: string;
  tooltip: string;
  icon: IconType;
}

const AddNewEntityButton: React.FC<AddNewEntityButtonProps> = ({
  routePath,
  tooltip,
  icon: Icon,
}) => {
  return (
    <>
      <Tooltip id={`${routePath}-tooltip`} />
      <IconContainer
        href={routePath}
        target="_blank"
        rel="noreferrer"
        data-tooltip-id={`${routePath}-tooltip`}
        data-tooltip-content={tooltip}
      >
        <Icon />
      </IconContainer>
    </>
  );
};

export default AddNewEntityButton;
