import React from 'react';

import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import BaseDeleteModal from '@components/DeleteModal/BaseDeleteModal';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import PasswordInput from '@components/PasswordInput';

import { errors } from '@utils';

interface CancelCteModalProps
  extends Omit<IBaseDeleteModalProps, 'title' | 'onConfirm' | 'children'> {
  onConfirm: (values: ICancelFiscalDocument) => void;
}

const CancelCteModal: React.FC<CancelCteModalProps> = ({
  onConfirm,
  ...props
}) => {
  const formikInitialValues: ICancelFiscalDocument = {
    password: '',
    reason: '',
  };

  const formikValidationSchema = yup.object().shape({
    reason: yup
      .string()
      .trim()
      .required(errors.required)
      .min(15, errors.minLength(15))
      .max(255, errors.minLength(255)),
    password: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onConfirm,
  });

  if (!props.isOpen) {
    return null;
  }

  return (
    <BaseDeleteModal
      title="Tem certeza que deseja cancelar o CT-e?"
      message={`Ao cancelar o CT-e, não será possível emitir novamente.\nNeste caso, você terá que cancelar o serviço e criar um novo.\nEsta ação é irreversível!`}
      confirmText="Sim, cancelar"
      cancelText="Não, voltar"
      {...props}
      onConfirm={() => formik.handleSubmit()}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="false">
          <FormRow>
            <FormGroup>
              <Label htmlFor="reason">Razão do cancelamento *</Label>
              <Input
                id="reason"
                name="reason"
                placeholder="Digite a razão do cancelamento"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.reason}
                invalidValue={!!formik.errors.reason && !!formik.touched.reason}
              />
              <FormError name="reason" />
            </FormGroup>
          </FormRow>
          <FormGroup>
            <Label htmlFor="password">Senha *</Label>
            <PasswordInput
              type="password"
              placeholder="Digite sua senha"
              id="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              invalidValue={
                !!formik.touched.password && !!formik.errors.password
              }
            />
            <FormError name="password" />
          </FormGroup>
        </Form>
      </FormikProvider>
    </BaseDeleteModal>
  );
};

export default CancelCteModal;
