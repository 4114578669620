import React from 'react';

import { TableContainer, TableLogo } from './styles';
import defaultImage from '/defaultCompany.png';

interface TableDataLogoProps {
  src: string | undefined;
}

const TableDataLogo: React.FC<TableDataLogoProps> = ({ src }) => {
  return (
    <TableContainer>
      <TableLogo src={src ?? defaultImage} alt="Logo" draggable={false} />
    </TableContainer>
  );
};

export default TableDataLogo;
