import React from 'react';

import { Icon } from 'iconsax-react';

import ManagementButtons, {
  ManagementButtonProps,
  ManagementButtonsProps,
} from '@components/PageHeader/ManagementButtons';
import RoutePaths from '@components/RoutePaths';

import {
  Container,
  CustomButton,
  MainContent,
  PageTitles,
  Subtitle,
  Title,
} from './styles';

interface ButtonProps extends ManagementButtonProps {
  text: string;
  icon?: Icon;
}

interface PageHeaderProps extends ManagementButtonsProps {
  title: string;
  subtitle?: string;
  routes?: Array<IRoutePath>;
  buttonProps?: ButtonProps;
  extraButtonProps?: ButtonProps;
  className?: string;
  children?: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  routes,
  buttonProps,
  extraButtonProps,
  className,
  children,
  ...managementButtonsProps
}) => {
  return (
    <Container className={className}>
      {routes && <RoutePaths routes={routes} />}
      <MainContent>
        <PageTitles>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </PageTitles>
        <ManagementButtons {...managementButtonsProps}>
          {buttonProps && (
            <CustomButton {...buttonProps}>
              {buttonProps.icon && <buttonProps.icon variant="Bold" />}
              {buttonProps.text}
            </CustomButton>
          )}
          {extraButtonProps && (
            <CustomButton variant="secondary" {...extraButtonProps}>
              {extraButtonProps.icon && (
                <extraButtonProps.icon variant="Bold" />
              )}
              {extraButtonProps.text}
            </CustomButton>
          )}
          {children}
        </ManagementButtons>
      </MainContent>
    </Container>
  );
};

export default PageHeader;
