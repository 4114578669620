import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.four};
`;

export const ImageContainer = styled.div`
  position: relative;
  max-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.gray.one};
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  min-height: 600px;
  align-items: center;
  width: 100%;
`;

export const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.two};
  overflow-x: auto;
`;

export const EmptyInspectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.two};
  animation: fade-in 0.8s ease-in-out;
`;

export const EmptyInspectionText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.gray.eight};
`;

export const EmptyInspectionDetailText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.gray.eight};
`;

export const PreviewGrid = styled.div`
  display: flex;
  justify-content: center;
`;
