import RoutesPath from '@router/routes';

const getRoutes = (id: string) => {
  return [
    {
      path: RoutesPath.private.services.listServices.path,
      label: 'Serviços',
    },
    {
      path: RoutesPath.private.services.detailService.path.replace(
        ':serviceId',
        id
      ),
      label: 'Detalhes do serviço',
    },
    {
      path: RoutesPath.private.services.editLastServiceLocation.path.replace(
        ':serviceId',
        id
      ),
      label: 'Editar localização atual do serviço',
    },
  ];
};

export default getRoutes;
