import React from 'react';

import { CustomLabel } from './styles';

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  children: React.ReactNode;
}

const Label: React.FC<LabelProps> = ({ children }, props) => {
  return <CustomLabel {...props}>{children}</CustomLabel>;
};

export default Label;
