import React from 'react';

import { phone } from 'b2utils';
import { formatDocument } from 'utils/formats';
import { toAddressString } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';

import EditClientButton from './EditClientButton';
import {
  ClientInfo,
  ClientInfoContainer,
  Container,
  Description,
  Divider,
  InfoContainer,
  InfoIcon,
  InfoQuote,
  Title,
  TitleContainer,
} from './styles';

interface IClientCardProps {
  client: IClientUser;
  clientAddress: IAddress | null;
}

const ClientCard: React.FC<IClientCardProps> = ({ client, clientAddress }) => {
  const { isClientUser } = useAuth();

  return (
    <Container>
      <TitleContainer>
        <Title>Dados de cadastro do cliente</Title>
        {!isClientUser && <EditClientButton clientData={client} />}
      </TitleContainer>
      <Divider />
      <div>
        <InfoContainer>
          <InfoIcon />
          <InfoQuote>
            As informações do cliente são importantes para conseguir uma
            interação mais humana com o cliente
          </InfoQuote>
        </InfoContainer>
        <div>
          <ClientInfoContainer>
            <ClientInfo>Nome:</ClientInfo>
            <Description>{client.name}</Description>
          </ClientInfoContainer>
          <ClientInfoContainer>
            <ClientInfo>CPF/CNPJ:</ClientInfo>
            <Description>{formatDocument(client.document)}</Description>
          </ClientInfoContainer>
          <ClientInfoContainer>
            <ClientInfo>Telefone:</ClientInfo>
            <Description>
              {phone.fromObject({
                areaCode: client.phone.area_code,
                number: client.phone.number,
              })}
            </Description>
          </ClientInfoContainer>
          <ClientInfoContainer>
            <ClientInfo>Email:</ClientInfo>
            <Description>{client.email}</Description>
          </ClientInfoContainer>
          {clientAddress && (
            <ClientInfoContainer>
              <ClientInfo>Endereço do cliente:</ClientInfo>
              <Description>{toAddressString(clientAddress)}</Description>
            </ClientInfoContainer>
          )}
        </div>
      </div>
    </Container>
  );
};

export default ClientCard;
