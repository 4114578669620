import React, { forwardRef, useImperativeHandle } from 'react';

import { cnpj, cpf, phone } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { CarrierType, CarrierTypeMap } from 'utils/enums';
import { formatDocument } from 'utils/formats';
import { toDateInput } from 'utils/helpers';
import * as yup from 'yup';

import FileSinglePicker from '@components/FileSinglePicker';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';

import { errors } from '@utils';

interface IDriverInfoProps {
  initialValues?: IDriverInfoStepForm;
  onFinish: (data: IDriverInfoStepForm) => void;
}

const DriverInfo: React.ForwardRefRenderFunction<
  IFormStepRef,
  IDriverInfoProps
> = ({ onFinish, initialValues }, ref) => {
  const isCpfFieldValid = (value?: string) => {
    const isFieldEmpty = !value?.length;

    if (isFieldEmpty) {
      return true;
    }

    if (value.length > 14) {
      return true;
    }

    return cpf.validate(value);
  };

  const isCnpjFieldValid = (value?: string) => {
    const isFieldEmpty = !value?.length;

    if (isFieldEmpty) {
      return true;
    }

    if (value.length <= 14) {
      return true;
    }

    return cnpj.validate(value);
  };

  const carrierTypes = Object.values(CarrierType);

  const formikInitialValues: IDriverInfoStepForm = {
    name: '',
    email: '',
    dateOfBirth: '',
    document: '',
    phone: '',
    carrierType: undefined,
    driversLicenseFile: undefined,
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    name: yup.string().trim().required(errors.required),
    email: yup.string().email(errors.email),
    dateOfBirth: yup.string().trim(),
    document: yup
      .string()
      .trim()
      .test('is-cpf-valid', errors.cpf, isCpfFieldValid)
      .test('is-cnpj-valid', errors.cnpj, isCnpjFieldValid)
      .required(errors.required),
    phone: yup
      .string()
      .trim()
      .required(errors.required)
      .test(
        'is-phone-valid',
        errors.phone,
        (value) => !!value && phone.validate(value)
      ),
    carrierType: yup.string().trim().required(errors.required),
    driversLicenseFile: yup.object().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="name">Nome *</Label>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder="Digite aqui"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.touched.name && !!formik.errors.name}
          />
          <FormError name="name" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">E-mail</Label>
          <Input
            type="text"
            id="email"
            name="email"
            placeholder="Digite aqui"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.touched.email && !!formik.errors.email}
          />
          <FormError name="email" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="document">CPF / CNPJ *</Label>
          <Input
            type="text"
            id="document"
            name="document"
            placeholder="Digite aqui"
            value={formik.values.document}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'document',
                formatDocument(event.target.value)
              )
            }
            invalidValue={!!formik.touched.document && !!formik.errors.document}
          />
          <FormError name="document" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="dateOfBirth">Data de nascimento</Label>
          <Input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={formik.values.dateOfBirth}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            max={toDateInput(new Date())}
            invalidValue={
              !!formik.touched.dateOfBirth && !!formik.errors.dateOfBirth
            }
          />
          <FormError name="dateOfBirth" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="phone">Celular *</Label>
          <Input
            type="text"
            id="phone"
            name="phone"
            placeholder="Digite aqui"
            maxLength={15}
            value={formik.values.phone}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue('phone', phone.mask(event.target.value))
            }
            invalidValue={!!formik.touched.phone && !!formik.errors.phone}
          />
          <FormError name="phone" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="carrierType">Tipo do transportador *</Label>
          <Select
            id="carrierType"
            name="carrierType"
            value={formik.values.carrierType}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={
              !!formik.errors.carrierType && !!formik.touched.carrierType
            }
          >
            <option value="">Selecione um tipo</option>
            {carrierTypes.map((type) => (
              <option key={type} value={type}>
                {CarrierTypeMap[type]}
              </option>
            ))}
          </Select>
          <FormError name="carrierType" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="driversLicenseFile">CNH</Label>
          <FileSinglePicker
            file={formik.values.driversLicenseFile}
            onDeleteFile={() =>
              formik.setFieldValue('driversLicenseFile', null)
            }
            onAddFile={(file) =>
              formik.setFieldValue('driversLicenseFile', file)
            }
          />
          <FormError name="driversLicenseFile" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(DriverInfo);
