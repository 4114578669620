import React from 'react';

import RoutesPath from '@router/routes';

import ListPaymentMethods from './ListPaymentMethods';
import ListPaymentTerm from './ListPaymentTerm';

export const tabs: Array<ITab> = [
  {
    title: 'Métodos de pagamento',
    component: <ListPaymentMethods />,
    path: RoutesPath.private.paymentMethods.listPaymentMethods.path,
  },
  {
    title: 'Formas de pagamento',
    component: <ListPaymentTerm />,
    path: RoutesPath.private.paymentMethods.listPaymentTerms.path,
  },
];
