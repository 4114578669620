import React from 'react';

import moment from 'moment';
import {
  ContractStatusMap,
  SendingStatus,
  ServiceStatus,
  ServiceType,
} from 'utils/enums';

import { useAuth } from '@contexts/Auth';
import { useClients, useCompanyUsers, useIncomeCategories } from '@hooks';

import ChooseItemFilter from '@components/ChooseItemFilter';
import DateRangeCalendarFilter from '@components/DateRangeCalendarFilter';
import ExpandableFilter from '@components/ExpandableFilter';
import ListFilters from '@components/ListFilters';
import Select from '@components/Select';

import { helpers } from '@utils';

import { Container } from './styles';

interface ServiceFiltersProps {
  queryParams: IServicesQueryParams;
  setQueryParams: React.Dispatch<React.SetStateAction<IServicesQueryParams>>;
  onSearch: (value: string) => void;
  variant?: boolean;
  showStatusFilter?: boolean;
}

const ServiceFilters: React.FC<ServiceFiltersProps> = ({
  queryParams,
  setQueryParams,
  onSearch,
  variant,
  showStatusFilter = false,
}) => {
  const { isClientUser } = useAuth();
  const { listClients } = useClients();
  const { listCompanyUsers } = useCompanyUsers();
  const { listIncomeCategories } = useIncomeCategories();

  const emptyClient = {
    id: 0,
    name: 'Todos',
  } as IClientUser;

  const emptyPersonInCharge = {
    id: 0,
    name: 'Todos',
  } as ICompanyUser;

  const emptyCategory = {
    id: 0,
    name: 'Todas',
  } as IIncomeCategory;

  const serviceStatuses = Object.values(ServiceStatus);
  const serviceTypes = Object.values(ServiceType);
  const contractStatuses = Object.values(SendingStatus);

  const handleDateRangeFilterSelected = (startDate?: Date, endDate?: Date) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      dateGte: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
      dateLte: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
    }));
  };

  const handleCreatedDateRangeFilterSelected = (
    startDate?: Date,
    endDate?: Date
  ) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      createdDateGte: startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : undefined,
      createdDateLte: endDate
        ? moment(endDate).format('YYYY-MM-DD')
        : undefined,
    }));
  };

  const handleSelectClient = (client: IClientUser) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      client,
    }));
  };

  const handleSelectPersonInCharge = (personInCharge: ICompanyUser) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      personInCharge,
    }));
  };

  const handleSelectCategory = (category: IIncomeCategory) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      category,
    }));
  };

  const handleSelectServiceStatus = (status: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      status: status ? (status as ServiceStatus) : undefined,
    }));
  };

  const handleSelectServiceType = (type: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      type: type ? (type as ServiceType) : undefined,
    }));
  };

  const handleSelectContractStatus = (status: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      contractStatus: status ? (status as SendingStatus) : undefined,
    }));
  };

  const handleSelectOriginState = (state: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      originState: state || undefined,
    }));
  };

  const handleSelectDestinationState = (state: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      destinationState: state || undefined,
    }));
  };

  const handleSelectLastKnownState = (state: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      lastKnownState: state || undefined,
    }));
  };

  return (
    <Container>
      <ListFilters
        variant={variant}
        searchPlaceholder="Buscar por nº do serviço ou veículo"
        onSearch={onSearch}
      >
        <DateRangeCalendarFilter
          clearableDateRange
          label="Filtrar por data prevista para coleta"
          onDateRangeFilterSelected={handleDateRangeFilterSelected}
        />
        <DateRangeCalendarFilter
          clearableDateRange
          label="Filtrar por data de criação"
          onDateRangeFilterSelected={handleCreatedDateRangeFilterSelected}
        />
      </ListFilters>
      <ExpandableFilter>
        {!isClientUser && (
          <>
            {!variant && (
              <ChooseItemFilter
                filterValue={
                  queryParams?.client?.id && queryParams?.client?.id !== 0
                    ? queryParams?.client?.name
                    : 'Cliente: todos'
                }
                modalTitle="Filtrar serviços por cliente"
                fetch={listClients}
                queryParams={{ isActive: 'true' }}
                queryKey="clients"
                inputLabel="Selecionar cliente *"
                selectedItemLabel="Cliente selecionado"
                shouldRenderSelectedItem
                onSelect={handleSelectClient}
                selectedItem={queryParams.client || emptyClient}
                additionalItem={emptyClient}
                renderItemText={(client) => client.name}
              />
            )}
            <ChooseItemFilter
              filterValue={
                queryParams?.personInCharge?.id &&
                queryParams?.personInCharge?.id !== 0
                  ? queryParams?.personInCharge?.name
                  : 'Responsáveis: todos'
              }
              modalTitle="Filtrar serviços por responsável"
              fetch={listCompanyUsers}
              queryParams={{ isActive: 'true', canBeInChargeOfServices: true }}
              queryKey="companyUsers"
              inputLabel="Selecionar responsável *"
              selectedItemLabel="Responsável selecionado"
              shouldRenderSelectedItem
              onSelect={handleSelectPersonInCharge}
              selectedItem={queryParams.personInCharge || emptyPersonInCharge}
              additionalItem={emptyPersonInCharge}
              renderItemText={(personInCharge) => personInCharge.name}
            />
            <ChooseItemFilter
              filterValue={
                queryParams?.category?.id && queryParams?.category?.id !== 0
                  ? queryParams?.category?.name
                  : 'Categoria: todas'
              }
              modalTitle="Filtrar serviços por categoria"
              fetch={listIncomeCategories}
              queryParams={{ isActive: 'true' }}
              queryKey="incomeCategory"
              inputLabel="Selecionar categoria *"
              selectedItemLabel="Categoria selecionada"
              shouldRenderSelectedItem
              onSelect={handleSelectCategory}
              selectedItem={queryParams.category || emptyCategory}
              additionalItem={emptyCategory}
              renderItemText={(category) => category.name}
            />
          </>
        )}
        {showStatusFilter && (
          <Select
            value={queryParams?.status || ''}
            onChange={(event) =>
              handleSelectServiceStatus(event.target.value as ServiceStatus)
            }
          >
            <option value="">Situação do serviço: todas</option>
            {serviceStatuses.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </Select>
        )}
        <Select
          value={queryParams?.type || ''}
          onChange={(event) =>
            handleSelectServiceType(event.target.value as ServiceType)
          }
        >
          <option value="">Tipo do serviço: todos</option>
          {serviceTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Select>
        <Select
          value={queryParams?.contractStatus || ''}
          onChange={(event) => handleSelectContractStatus(event.target.value)}
        >
          <option value="">Situação do contrato: todas</option>
          {contractStatuses.map((status) => (
            <option key={status} value={status}>
              {ContractStatusMap[status]}
            </option>
          ))}
        </Select>
        <Select
          value={queryParams?.originState || ''}
          onChange={(event) => handleSelectOriginState(event.target.value)}
        >
          <option value="">Estado de origem: todos</option>
          {helpers.ufOptions.map(
            ({ label, value }, index) =>
              index !== 0 && (
                <option key={value} value={value}>
                  {label}
                </option>
              )
          )}
        </Select>
        <Select
          value={queryParams?.destinationState || ''}
          onChange={(event) => handleSelectDestinationState(event.target.value)}
        >
          <option value="">Estado de destino: todos</option>
          {helpers.ufOptions.map(
            ({ label, value }, index) =>
              index !== 0 && (
                <option key={value} value={value}>
                  {label}
                </option>
              )
          )}
        </Select>
        <Select
          value={queryParams?.lastKnownState || ''}
          onChange={(event) => handleSelectLastKnownState(event.target.value)}
        >
          <option value="">Estado de localização atual: todos</option>
          {helpers.ufOptions.map(
            ({ label, value }, index) =>
              index !== 0 && (
                <option key={value} value={value}>
                  {label}
                </option>
              )
          )}
        </Select>
      </ExpandableFilter>
    </Container>
  );
};

export default ServiceFilters;
