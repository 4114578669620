import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';

import { useToast } from '@contexts/Toast';
import { useQueryParams, useServices } from '@hooks';

import CteStatusLabel from '@components/CteStatusLabel';
import Input from '@components/Input';
import LabelWithLink from '@components/LabelWithLink';
import ModalTable from '@components/ModalTable';
import { TableDataCell } from '@components/Table/styles';

import RoutesPath from '@router/routes';

import SelectAllSection from './SelectAllSection';
import { Container } from './styles';

interface ServiceListProps {
  departureId: number;
}

const PAGE_SIZE = 8;

const ServiceList: React.FC<ServiceListProps> = ({ departureId }) => {
  const formik = useFormikContext<IFinishDepartureValues>();

  const { queryParams, changePage } = useQueryParams<IServicesQueryParams>({
    page: 1,
    departure: departureId.toString(),
    pageSize: PAGE_SIZE,
  });

  const { listServices } = useServices();
  const { addToast } = useToast();

  const { servicesSelectedPerPage, isAllServicesSelected } = formik.values;

  const servicesSelectedInPage =
    servicesSelectedPerPage[queryParams.page!] || [];

  const { data: services, isLoading } = useQuery(
    ['services', queryParams],
    () => listServices(queryParams),
    {
      onSuccess: (data) => {
        if (!servicesSelectedInPage.length && !isAllServicesSelected) {
          formik.setFieldValue('servicesSelectedPerPage', {
            ...servicesSelectedPerPage,
            [queryParams.page!]: [],
          });
        }
        if (isAllServicesSelected) {
          formik.setFieldValue('servicesSelectedPerPage', {
            ...servicesSelectedPerPage,
            [queryParams.page!]: data.results.map((service) => service.id),
          });
        }
      },
      onError: () => {
        addToast(
          'Não foi possível carregar a lista de serviços do embarque',
          'error'
        );
      },
      enabled: !!queryParams.page,
    }
  );

  const serviceList = services?.results || [];

  const handleSelectService = (serviceId: number) => {
    const alreadySelected = servicesSelectedInPage.includes(serviceId);

    const newServicesSelected = alreadySelected
      ? servicesSelectedInPage.filter((id) => id !== serviceId)
      : [...servicesSelectedInPage, serviceId];

    formik.setFieldValue('servicesSelectedPerPage', {
      ...servicesSelectedPerPage,
      [queryParams.page!]: newServicesSelected,
    });

    if (isAllServicesSelected) {
      formik.setFieldValue('isAllServicesSelected', false);
    }
  };

  const isAllServicesInThePageSelected = useMemo(() => {
    return (
      serviceList.length !== 0 &&
      servicesSelectedInPage.length === serviceList.length
    );
  }, [serviceList.length, servicesSelectedInPage.length]);

  const handleSelectAllServicesInThePage = () => {
    if (isAllServicesInThePageSelected) {
      formik.setFieldValue('servicesSelectedPerPage', {
        ...servicesSelectedPerPage,
        [queryParams.page!]: [],
      });
    } else {
      formik.setFieldValue('servicesSelectedPerPage', {
        ...servicesSelectedPerPage,
        [queryParams.page!]: serviceList.map((service) => service.id),
      });
    }
  };

  const isChecked = (selectedService: IService) => {
    return servicesSelectedInPage.includes(selectedService.id);
  };

  if (!queryParams.page) {
    return null;
  }

  return (
    <Container>
      <SelectAllSection
        services={services}
        isAllServicesInThePageSelected={isAllServicesInThePageSelected}
      />
      <ModalTable
        data={serviceList}
        isLoading={isLoading}
        headerData={[
          (
            <Input
              type="checkbox"
              id="select-all-services-checkbox"
              value="select-all-services-checkbox"
              name="service-checkbox"
              data-tooltip-id="services-list-tooltip"
              data-tooltip-content="Selecionar todos os serviços"
              checked={isAllServicesInThePageSelected}
              onChange={handleSelectAllServicesInThePage}
            />
          ) as unknown as string,
          'Nº do serviço',
          'Cliente',
          'Veículo',
          'Origem',
          'Destino',
          'Tipo',
          'CT-e',
        ]}
        emptyMessage="Nenhum serviço encontrado"
        renderRow={(service) => (
          <B2TableRow key={service.id}>
            <TableDataCell>
              <Input
                id={`service-checkbox-${service.id}`}
                value={`service-checkbox-${service.id}`}
                name="service-checkbox"
                type="checkbox"
                checked={isChecked(service)}
                onChange={() => handleSelectService(service.id)}
              />
            </TableDataCell>
            <TableDataCell>
              <LabelWithLink
                label={service.number}
                href={RoutesPath.private.services.detailService.path.replace(
                  ':serviceId',
                  service.id.toString()
                )}
                tooltipMessage="Ir para a página do serviço"
              />
            </TableDataCell>
            <TableDataCell>{service.client.name}</TableDataCell>
            <TableDataCell>
              {`${service.vehicle.model} - ${service.vehicle.identifier}`}
            </TableDataCell>
            <TableDataCell>
              {`${service.origin.name}/${service.origin.state}`}
            </TableDataCell>
            <TableDataCell>
              {service.destination
                ? `${service.destination.name}/${service.destination.state}`
                : '-'}
            </TableDataCell>
            <TableDataCell>{service.type}</TableDataCell>
            <TableDataCell>
              <CteStatusLabel
                status={service.cte?.status}
                accessKey={service.cte_access_key}
              />
            </TableDataCell>
          </B2TableRow>
        )}
        hasClick={false}
        paginator
        amountPerPage={PAGE_SIZE}
        currentPage={queryParams.page}
        total={services?.count}
        changePage={changePage}
      />
    </Container>
  );
};

export default ServiceList;
