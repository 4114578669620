import React, { useRef } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useManagementUsers } from '@hooks';

import BaseForm from '@components/BaseForm';
import Loading from '@components/Loading';
import ManagementUserForm from '@components/ManagementUserForm';

import RoutesPath from '@router/routes';

import getRoutes from './routes';

const UpdateManagementUser: React.FC = () => {
  const managementUserFormStepRef = useRef<IFormStepRef>(null);

  const { managementUserId } = useParams<{ managementUserId: string }>();

  const { getManagementUser, updateManagementUser } = useManagementUsers();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const { data: managementUser, isLoading } = useQuery(
    ['managementUser', managementUserId],
    () => getManagementUser(managementUserId!),
    {
      onError() {
        addToast(
          'Não foi possível carregar os dados do usuário interno',
          'error'
        );
      },
    }
  );

  const submitUpdateManagementUser = useMutation(
    (managementUserValues: IManagementUserFormValues) =>
      updateManagementUser(managementUserId!, managementUserValues),
    {
      onSuccess: (_, managementUserFormValues) => {
        addToast('Usuário editado com sucesso', 'success');
        if (managementUserFormValues.email !== managementUser?.email) {
          addToast('Confirmação de troca de e-mail enviada', 'success');
        }
        navigate(
          RoutesPath.private.managementUsers.detailManagementUser.path.replace(
            ':managementUserId',
            managementUserId!
          )
        );
      },
      onError: () => {
        addToast('Erro ao editar usuário interno', 'error');
      },
    }
  );

  const initialValues: IManagementUserFormValues = {
    avatar: managementUser?.avatar || null,
    name: managementUser?.name || '',
    email: managementUser?.email || '',
    role: managementUser?.role || undefined,
  };

  const steps: Array<IStep> = [
    {
      title: 'Informações do usuário',
      ref: managementUserFormStepRef,
      component: ManagementUserForm,
    },
  ];

  if (isLoading || !managementUser) {
    return <Loading />;
  }

  return (
    <BaseForm
      title="Editar usuário"
      createRequest={submitUpdateManagementUser}
      steps={steps}
      routes={getRoutes(managementUserId!)}
      initialValues={initialValues}
    />
  );
};

export default UpdateManagementUser;
