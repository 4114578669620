import { DocumentDownload } from 'iconsax-react';
import { B2Button } from 'react-b2components';
import styled from 'styled-components';

import Input from '@components/Input';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.four};
`;

export const Description = styled.span`
  text-align: center;
  color: ${({ theme }) => theme.colors.gray.twelve};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const CustomInput = styled(Input)`
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const DocumentDownloadButton = styled(B2Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const DocumentDownloadIcon = styled(DocumentDownload)`
  width: ${({ theme }) => theme.spacing.six};
  height: ${({ theme }) => theme.spacing.six};
  color: ${({ theme }) => theme.colors.white.one};
`;
