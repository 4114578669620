import React from 'react';

import BasePickItem from '@components/BasePickItem';

export interface ChooseItemProps<
  ItemType extends GenericItem,
  FetchQueryParams extends IQueryParams
> extends Omit<
    BasePickItemProps<ItemType, FetchQueryParams>,
    'type' | 'selectedItems'
  > {
  selectedItem?: ItemType;
}

const ChooseItem = <
  ItemType extends GenericItem,
  FetchQueryParams extends IQueryParams
>({
  selectedItem,
  ...props
}: ChooseItemProps<ItemType, FetchQueryParams>) => {
  return (
    <BasePickItem
      {...props}
      type="radio"
      selectedItems={selectedItem ? [selectedItem] : []}
    />
  );
};

export default ChooseItem;
