export const getVehicleDocuments = (truck: IDriverTruck): IDocumentsSection => {
  const documentsSection: IDocumentsSection = {
    title: 'Documentos do veículo',
    documents: [],
  };

  if (truck.antt_file) {
    documentsSection.documents.push({
      name: 'Documento ANTT',
      url: truck.antt_file?.url,
    });
  }

  if (truck.truck_crlv_file) {
    documentsSection.documents.push({
      name: 'CRLV do caminhão',
      url: truck.truck_crlv_file?.url,
    });
  }

  if (truck.trailer_crlv_file) {
    documentsSection.documents.push({
      name: 'CRLV da carreta',
      url: truck.trailer_crlv_file.url,
    });
  }

  return documentsSection;
};
