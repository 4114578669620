import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useMetrics } from '@hooks';

import { HeaderText, MetricCard } from '../../styles';
import DonutChart from '../DonutChart';

interface ServiceCountByPersonInChargeProps {
  queryParams: IMetricQueryParamsByDateInterval;
}

const ServiceCountByPersonInCharge: React.FC<
  ServiceCountByPersonInChargeProps
> = ({ queryParams }) => {
  const [series, setSeries] = useState<Array<number>>([]);
  const [labels, setLabels] = useState<Array<string>>([]);

  const { getServicesCountByPersonInCharge } = useMetrics();
  const { isManagementUser, isCompanyUser } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();

  const isManagementWithoutScope = isManagementUser && !scope;

  useQuery(
    ['servicesCountByPersonInCharge', queryParams, scope],
    () => getServicesCountByPersonInCharge(queryParams),
    {
      onSuccess: (data) => {
        const newSeries: Array<number> = [];
        const newLabels: Array<string> = [];

        for (const { person_in_charge, count } of data) {
          const personInChargeNames = person_in_charge.name.split(' ');
          const personInChargeFormattedName = personInChargeNames[1]
            ? `${personInChargeNames[0]} ${personInChargeNames[1]}`
            : personInChargeNames[0];

          newSeries.push(count);
          newLabels.push(personInChargeFormattedName);
        }

        setLabels(newLabels);
        setSeries(newSeries);
      },
      onError: (error: AxiosError) => {
        throwToastApiErrors(error, addToast);
      },
      enabled: !!scope || isCompanyUser,
    }
  );

  useEffect(() => {
    if (isManagementWithoutScope) {
      setLabels([]);
      setSeries([]);
    }
  }, [isManagementWithoutScope]);

  return (
    <MetricCard>
      <HeaderText>Serviços por responsável</HeaderText>
      <DonutChart labels={labels} series={series} />
    </MetricCard>
  );
};

export default ServiceCountByPersonInCharge;
