import styled from 'styled-components';

const Label = styled.label`
  margin-bottom: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.gray.twelve};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export default Label;
