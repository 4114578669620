import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { Form, FormikProvider, useFormik } from 'formik';
import { useMutation } from 'react-query';
import { FileExtension, FileStatus, FileType } from 'utils/enums';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useFiscal } from '@hooks';

import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import ReportGenerationStatus from '@components/ReportGenerationStatus';

import { CustomModal, ModalContent } from '../styles';
import GenerateFiscalZipFileButton from './GenerateFiscalZipFileButton';

interface FiscalZipFileModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: FileType;
}

const FiscalZipFileModal: React.FC<FiscalZipFileModalProps> = ({
  isOpen,
  onClose,
  type,
}) => {
  const [zipFileId, setZipFileId] = useState<string>();
  const { requestFiscalZipFileDownload, getFiscalZipFile } = useFiscal();
  const { addToast } = useToast();

  const { mutate: requestFiscalZipFileGeneration } = useMutation(
    requestFiscalZipFileDownload,
    {
      onSuccess: (data) => {
        if (data.status === FileStatus.FAILED) {
          addToast('Ocorreu um erro ao gerar arquivo');
          return;
        }

        if (data.files_qty === 0) {
          addToast(
            'Nenhum arquivo encontrado no intervalo de datas',
            'warning'
          );
          return;
        }

        setZipFileId(data.id);
      },
      onError: (error: AxiosError) => {
        addToast('Erro ao requisitar arquivo', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const formikInitialValues: IFiscalZipFileFormValues = {
    type,
    startAt: '',
    endAt: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    onSubmit: (values) => requestFiscalZipFileGeneration(values),
  });

  if (!isOpen) {
    return null;
  }

  const handleOnClose = () => {
    setZipFileId(undefined);
    formik.resetForm();
    onClose();
  };

  return (
    <FormikProvider value={formik}>
      <CustomModal
        title="Baixar documentos fiscais"
        isOpen={isOpen}
        onClose={handleOnClose}
      >
        <ModalContent>
          {zipFileId ? (
            <ReportGenerationStatus
              id={zipFileId}
              type={type}
              getFileRequest={getFiscalZipFile}
              queryKey="fiscalZipFile"
              fileExtension={FileExtension.ZIP}
            />
          ) : (
            <Form>
              <FormRow>
                <FormGroup>
                  <Label htmlFor="startAt">Data inicial</Label>
                  <Input
                    type="date"
                    id="startAt"
                    name="startAt"
                    value={formik.values.startAt}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="endAt">Data final</Label>
                  <Input
                    type="date"
                    id="endAt"
                    name="endAt"
                    value={formik.values.endAt}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </FormRow>
              <GenerateFiscalZipFileButton
                onClick={formik.handleSubmit}
                disabled={!formik.values.startAt || !formik.values.endAt}
              />
            </Form>
          )}
        </ModalContent>
      </CustomModal>
    </FormikProvider>
  );
};

export default FiscalZipFileModal;
