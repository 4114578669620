const onlyNumbers = /\D/g;
const decimalWithComma = /(\d{3})(\d{2})/g;

const fieldWithoutSpecialCharacters =
  /[a-zA-Z!@#$%^&*()_+\-=[\]ˆ{},;':"\\|<>/?]/;

const onlyNumbersAndLetters = /[^a-zA-Z0-9]/g;

const onlyNumbersAndDot = /[^\d.]|(?<=\..*)\./g;

export default {
  onlyNumbers,
  decimalWithComma,
  fieldWithoutSpecialCharacters,
  onlyNumbersAndLetters,
  onlyNumbersAndDot,
};
