import React, { useRef } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useIncomeCategories, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';
import Loading from '@components/Loading';

import RoutesPath from '@router/routes';

import IncomeCategoryForm from '../Form/IncomeCategoryForm';
import getRoutes from './routes';

const UpdateIncomeCategory: React.FC = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const categoryFormRef = useRef<IFormStepRef>(null);

  const { getIncomeCategory, updateIncomeCategory } = useIncomeCategories();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useVerifyScope({
    routePath: RoutesPath.private.categories.incomes.listCategories.path,
    toastErrorMessage: 'Selecione uma empresa para editar uma categoria',
  });

  const {
    data: incomeCategory,
    refetch,
    isLoading,
  } = useQuery(
    ['incomeCategory', categoryId],
    () => getIncomeCategory(categoryId!),
    {
      onError: () => {
        addToast('Falha ao buscar os dados da categoria', 'error');
      },
    }
  );

  const updateIncomeCategoryRequest = useMutation(
    (values: ICategoryBaseFormValues) =>
      updateIncomeCategory(categoryId!, values),
    {
      onSuccess: () => {
        refetch();
        addToast('Dados da categoria atualizados com sucesso', 'success');
        navigate(
          RoutesPath.private.categories.incomes.listCategories.path.replace(
            ':categoryId',
            categoryId!
          )
        );
      },
      onError: () => {
        addToast('Erro ao tentar editar informações da categoria', 'error');
      },
    }
  );

  const categoryInfoInitialValues: ICategoryBaseFormValues = {
    name: incomeCategory?.name || '',
  };

  const steps: Array<IStep> = [
    {
      title: 'Informações da categoria de recebível',
      ref: categoryFormRef,
      component: IncomeCategoryForm,
    },
  ];

  if (isLoading || !incomeCategory) {
    return <Loading />;
  }

  return (
    <BaseForm
      steps={steps}
      createRequest={updateIncomeCategoryRequest}
      routes={getRoutes(categoryId!)}
      title="Editar categoria de recebível"
      initialValues={categoryInfoInitialValues}
    />
  );
};

export default UpdateIncomeCategory;
