import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.paymentMethods.listPaymentMethods.path,
    label: 'Métodos de pagamento',
  },
  {
    path: RoutesPath.private.paymentMethods.updatePaymentMethod.path,
    label: 'Editar método de pagamento',
  },
];

export default routes;
