import styled, { css } from 'styled-components';

export const Container = styled.div<{ variant: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};
  margin-bottom: ${({ theme }) => theme.spacing.four};

  & > * {
    flex: 1 1 250px;
  }

  input,
  select {
    background-color: ${({ theme }) => theme.colors.white.main};
    border: 1px solid ${({ theme }) => theme.colors.white.three};
  }

  select {
    background-image: url('/images/arrow-drop-down-variant.svg');
  }

  ${({ variant, theme }) =>
    variant
      ? css`
          div {
            box-shadow: none;
          }
          @media (max-width: 1024px) {
            grid-template-columns: 1fr;
          }
        `
      : css`
          input,
          select {
            box-shadow: 6px 3px 24px ${theme.colors.gray.two};
          }
        `};
`;
