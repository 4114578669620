import { Car } from 'iconsax-react';

import RoutesPath from '@router/routes';

export const clientSidebarData: Array<ISidebarSection> = [
  {
    label: 'Monitoramento',
    sideBarItems: [
      {
        name: 'Serviços',
        icon: Car,
        route: RoutesPath.private.services.listServices.path,
      },
    ],
  },
];
