import {
  Car,
  Edit2,
  EmptyWallet,
  EmptyWalletTick,
  Receipt,
  Trash,
} from 'iconsax-react';
import styled, { css } from 'styled-components';
import { PaymentStatus } from 'utils/enums';
import { paymentStatusProps } from 'utils/helpers';
import { addSpacing, modifyColor } from 'utils/styles';

const IconStyle = css<{ disabled?: boolean }>`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
          cursor: not-allowed;
          color: ${({ theme }) => theme.colors.gray.ten};
        `
      : css`
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        `}
`;

export const ActionIconsContainer = styled.div`
  display: flex;
  align-self: center;
  gap: ${({ theme }) => theme.spacing.four};
`;

export const WalletTickIcon = styled(EmptyWalletTick)`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};
  color: ${({ theme }) => theme.colors.success};
`;

export const CarIcon = styled(Car)`
  color: ${({ theme }) => theme.colors.gray.ten};
  ${IconStyle}
`;

export const TrashIcon = styled(Trash)`
  color: ${({ theme }) => theme.colors.error};
  ${IconStyle}
`;

export const EditIcon = styled(Edit2)`
  color: ${({ theme }) => theme.colors.info};
  ${IconStyle}
`;

export const EmptyWalletIcon = styled(EmptyWallet)`
  color: ${({ theme }) => theme.colors.success};
  ${IconStyle}
`;

export const ReceiptIcon = styled(Receipt)`
  color: ${({ theme }) => theme.colors.success};
  ${IconStyle}
`;

export const PaymentStatusLabel = styled.span<{ status: PaymentStatus }>`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme, status }) => paymentStatusProps[status].color(theme)};
`;

export const PaymentCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) =>
    modifyColor({ hex: theme.colors.main, lightness: 0.93 })};
  padding: ${({ theme }) => addSpacing(theme.spacing.four, theme.spacing.six)};
  margin-bottom: ${({ theme }) => theme.spacing.four};
  animation: fade-in 0.5s ease-out;
`;

export const PaymentText = styled.span`
  color: ${({ theme }) => theme.colors.gray.ten};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export const FormContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.eight, theme.spacing.five)};
`;

export const FormErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 400px;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.five, theme.spacing.eight)};

  td {
    max-width: 160px;
  }
`;
