import React, { forwardRef, useImperativeHandle, useMemo } from 'react';

import { currency } from 'b2utils';
import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import {
  PaymentStatus,
  PaymentStatusMap,
  ReportDetailLevel,
} from 'utils/enums';
import { formatDate } from 'utils/formats';
import { getExpenseDescription } from 'utils/helpers';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useExpenses, useQueryParams } from '@hooks';

import ExpenseTypeLabel from '@components/ExpenseTypeLabel';
import ModalTable from '@components/ModalTable';
import { TableDataCell } from '@components/Table/styles';

import { PaymentStatusLabel } from '@pages/Financial/FinancialDashboard/styles';

import { CustomTableRow } from '../styles';

interface ExpenseListingProps {
  initialValues: IExpenseReportFormValues;
  onFinish: () => void;
}

const ExpenseListing: React.ForwardRefRenderFunction<
  IFormStepRef,
  ExpenseListingProps
> = ({ initialValues, onFinish }, ref) => {
  const { queryParams, changePage } = useQueryParams<IExpenseQueryParams>({
    page: 1,
    date__gte: initialValues.dateGte,
    date__lte: initialValues.dateLte,
    provider: initialValues.provider,
    category: initialValues.category,
    type: initialValues.type,
    status: initialValues.status,
  });

  const { listExpenses, listSimplifiedExpenses } = useExpenses();
  const { scope } = useScope();
  const { addToast } = useToast();

  const onError = () => {
    addToast('Não foi possível carregar a lista de despesas', 'error');
  };

  const { data: expenses, isLoading } = useQuery(
    ['expenses', queryParams, scope],
    () => listExpenses(queryParams),
    {
      enabled: initialValues.detailLevel === ReportDetailLevel.DETAILED,
      onError,
    }
  );

  const { data: simplifiedExpenses, isLoading: isLoadingSimplifiedExpense } =
    useQuery(
      ['simplifiedExpenses', queryParams, scope],
      () => listSimplifiedExpenses(queryParams),
      {
        enabled: initialValues.detailLevel === ReportDetailLevel.SIMPLIFIED,
        onError,
      }
    );

  useImperativeHandle(ref, () => ({
    submit: onFinish,
  }));

  const headerData = useMemo(() => {
    if (initialValues.detailLevel === ReportDetailLevel.DETAILED) {
      return [
        'Data',
        'Fornecedor',
        'Descrição',
        'Tipo',
        'Categoria',
        'Situação',
        'Valor a pagar',
        'Valor pago',
      ];
    }

    if (!queryParams.status) {
      return ['Fornecedor', 'Valor total'];
    }

    if (queryParams.status === PaymentStatus.PAID) {
      return ['Fornecedor', 'Valor pago'];
    }

    return ['Fornecedor', 'Valor a pagar'];
  }, [initialValues.detailLevel, queryParams.status]);

  if (initialValues.detailLevel === ReportDetailLevel.SIMPLIFIED) {
    return (
      <ModalTable
        data={simplifiedExpenses?.results || []}
        isLoading={isLoadingSimplifiedExpense}
        headerData={headerData}
        emptyMessage="Nenhuma despesa encontrada"
        renderRow={(simplifiedExpense) => (
          <CustomTableRow key={simplifiedExpense.provider_id}>
            <TableDataCell>{simplifiedExpense.provider_name}</TableDataCell>
            <TableDataCell>
              {currency.centsToBrl(simplifiedExpense.sum)}
            </TableDataCell>
          </CustomTableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={simplifiedExpenses?.count}
        changePage={changePage}
        hasClick={false}
      />
    );
  }

  return (
    <ModalTable
      data={expenses?.results || []}
      isLoading={isLoading}
      headerData={headerData}
      emptyMessage="Nenhuma despesa encontrada"
      renderRow={(expense) => (
        <B2TableRow key={expense.id}>
          <TableDataCell>{formatDate(expense.date)}</TableDataCell>
          <TableDataCell>{expense.provider.name}</TableDataCell>
          <TableDataCell>{getExpenseDescription(expense)}</TableDataCell>
          <TableDataCell>
            <ExpenseTypeLabel type={expense.type} />
          </TableDataCell>
          <TableDataCell>{expense.category?.name || '-'}</TableDataCell>
          <TableDataCell>
            <PaymentStatusLabel status={expense.status}>
              {PaymentStatusMap[expense.status]}
            </PaymentStatusLabel>
          </TableDataCell>
          <TableDataCell>{currency.centsToBrl(expense.value)}</TableDataCell>
          <TableDataCell>
            {currency.centsToBrl(expense.paid_value)}
          </TableDataCell>
        </B2TableRow>
      )}
      paginator
      amountPerPage={20}
      currentPage={queryParams.page}
      total={expenses?.count}
      changePage={changePage}
      hasClick={false}
    />
  );
};

export default forwardRef(ExpenseListing);
