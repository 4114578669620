import styled from 'styled-components';

import { TableDataCell } from '@components/Table/styles';

export const CustomTableDataCell = styled(TableDataCell)`
  cursor: auto;
`;

export const FiscalDocDownloadModalContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.four};
`;
