import React from 'react';

import { useNavigate } from 'react-router-dom';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';

import PageHeader from '@components/PageHeader';
import Tabs from '@components/Tabs';

import RoutesPath from '@router/routes';

import ExpenseCategoriesListing from './Tabs/ExpenseCategoriesListing';
import IncomeCategoriesListing from './Tabs/IncomeCategoriesListing';

const ListCategories: React.FC = () => {
  const { isManagementUser } = useAuth();
  const { scope } = useScope();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const isIncomeCategoriesUsersTab =
    location.pathname ===
    RoutesPath.private.categories.incomes.listCategories.path;

  const handleNavigateToCreateCategoryPage = () => {
    if (isManagementUser && !scope) {
      addToast(
        'Não é possível cadastrar uma categoria sem estar com uma empresa selecionada',
        'error'
      );
      return;
    }

    if (isIncomeCategoriesUsersTab) {
      navigate(RoutesPath.private.categories.incomes.createCategory.path);
    } else {
      navigate(RoutesPath.private.categories.expenses.createCategory.path);
    }
  };

  return (
    <>
      <PageHeader
        title="Categorias"
        subtitle="Listagem de todas as categorias do sistema"
        buttonProps={{
          text: `Cadastrar categoria de ${
            isIncomeCategoriesUsersTab ? 'recebível' : 'despesa'
          }`,
          onClick: handleNavigateToCreateCategoryPage,
        }}
      />
      <Tabs
        tabs={[
          {
            title: 'Recebíveis',
            component: <IncomeCategoriesListing />,
            path: RoutesPath.private.categories.incomes.listCategories.path,
          },
          {
            title: 'Despesas',
            component: <ExpenseCategoriesListing />,
            path: RoutesPath.private.categories.expenses.listCategories.path,
          },
        ]}
      />
    </>
  );
};

export default ListCategories;
