import React, { forwardRef, useImperativeHandle } from 'react';

import { cnpj, cpf } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { StateRegistrationStatus } from 'utils/enums';
import regex from 'utils/regex';
import * as yup from 'yup';

import FileSinglePicker from '@components/FileSinglePicker';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';

import { errors, helpers } from '@utils';

import VehicleOwnerInfo from '../VehicleOwnerInfo';

interface ITruckInfoProps {
  initialValues?: ITruckInfoStepForm;
  onFinish: (data: ITruckInfoStepForm) => void;
}

const MAX_TARE_WEIGHT = 999999;

const TruckInfo: React.ForwardRefRenderFunction<
  IFormStepRef,
  ITruckInfoProps
> = ({ onFinish, initialValues }, ref) => {
  const isCpfFieldValid = (value?: string) => {
    const isFieldEmpty = !value?.length;

    if (isFieldEmpty) {
      return true;
    }

    if (value.length > 14) {
      return true;
    }

    return cpf.validate(value);
  };

  const isCnpjFieldValid = (value?: string) => {
    const isFieldEmpty = !value?.length;

    if (isFieldEmpty) {
      return true;
    }

    if (value.length <= 14) {
      return true;
    }

    return cnpj.validate(value);
  };

  const formikInitialValues: ITruckInfoStepForm = {
    truckLicensePlate: '',
    truckLicenseState: '',
    truckTareWeight: '',
    antt: '',
    anttFile: undefined,
    truckCrlvFile: undefined,
    vehicleOwnerName: '',
    vehicleOwnerDocument: '',
    stateRegistrationStatus: StateRegistrationStatus.NON_TAXPAYER,
    stateRegistration: '',
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    truckLicensePlate: yup
      .string()
      .trim()
      .length(7, errors.length(7))
      .required(errors.required),
    truckLicenseState: yup.string().trim().required(errors.required),
    truckTareWeight: yup
      .string()
      .trim()
      .test(
        'is-truck-tare-weight-valid',
        errors.maxWeight(MAX_TARE_WEIGHT),
        (value) => !!value && Number(value) <= MAX_TARE_WEIGHT
      )
      .required(errors.required),
    antt: yup
      .string()
      .trim()
      .length(8, errors.length(8))
      .required(errors.required),
    anttFile: yup.object().nullable(),
    truckCrlvFile: yup.object().nullable(),
    vehicleOwnerName: yup.string().trim().required(errors.required),
    vehicleOwnerDocument: yup
      .string()
      .trim()
      .test('is-cpf-valid', errors.cpf, isCpfFieldValid)
      .test('is-cnpj-valid', errors.cnpj, isCnpjFieldValid)
      .required(errors.required),
    stateRegistrationStatus: yup.string().trim().required(errors.required),
    stateRegistration: yup
      .string()
      .trim()
      .when(['vehicleOwnerDocument', 'stateRegistrationStatus'], {
        is: (
          vehicleOwnerDocument?: string,
          stateRegistrationStatus?: StateRegistrationStatus
        ) =>
          vehicleOwnerDocument?.replace(regex.onlyNumbers, '').length === 14 &&
          stateRegistrationStatus === StateRegistrationStatus.TAXPAYER,
        then: (schema) =>
          schema
            .min(2, errors.minLength(2))
            .max(14, errors.maxLength(14))
            .required(errors.required),
      }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="truckLicensePlate">Placa do caminhão *</Label>
          <Input
            type="text"
            id="truckLicensePlate"
            name="truckLicensePlate"
            placeholder="Digite aqui"
            value={formik.values.truckLicensePlate}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'truckLicensePlate',
                event.target.value
                  .replace(regex.onlyNumbersAndLetters, '')
                  .toUpperCase()
              )
            }
            maxLength={7}
            invalidValue={
              !!formik.touched.truckLicensePlate &&
              !!formik.errors.truckLicensePlate
            }
          />
          <FormError name="truckLicensePlate" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="truckLicenseState">
            Estado da licença do caminhão *
          </Label>
          <Select
            id="truckLicenseState"
            name="truckLicenseState"
            value={formik.values.truckLicenseState}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={
              !!formik.touched.truckLicenseState &&
              !!formik.errors.truckLicenseState
            }
          >
            {helpers.ufOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <FormError name="truckLicenseState" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="truckTareWeight">Tara do caminhão (kg) *</Label>
          <Input
            id="truckTareWeight"
            name="truckTareWeight"
            type="text"
            placeholder="Digite aqui"
            value={formik.values.truckTareWeight}
            onChange={(event) =>
              formik.setFieldValue(
                'truckTareWeight',
                event.target.value.replace(regex.onlyNumbersAndDot, '')
              )
            }
            onBlur={formik.handleBlur}
            invalidValue={
              !!formik.touched.truckTareWeight &&
              !!formik.errors.truckTareWeight
            }
          />
          <FormError name="truckTareWeight" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="antt">Número do ANTT *</Label>
          <Input
            type="text"
            id="antt"
            name="antt"
            placeholder="Digite aqui"
            value={formik.values.antt}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'antt',
                event.target.value.replace(regex.onlyNumbers, '')
              )
            }
            maxLength={8}
            invalidValue={!!formik.touched.antt && !!formik.errors.antt}
          />
          <FormError name="antt" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="anttFile">Documento ANTT</Label>
          <FileSinglePicker
            file={formik.values.anttFile}
            onDeleteFile={() => formik.setFieldValue('anttFile', null)}
            onAddFile={(file) => formik.setFieldValue('anttFile', file)}
          />
          <FormError name="anttFile" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="truckCrlvFile">CRLV do caminhão</Label>
          <FileSinglePicker
            file={formik.values.truckCrlvFile}
            onDeleteFile={() => formik.setFieldValue('truckCrlvFile', null)}
            onAddFile={(file) => formik.setFieldValue('truckCrlvFile', file)}
          />
          <FormError name="truckCrlvFile" />
        </FormGroup>
      </FormRow>
      <VehicleOwnerInfo />
    </FormikProvider>
  );
};

export default forwardRef(TruckInfo);
