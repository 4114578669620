import React from 'react';

import { phone } from 'b2utils';
import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { formatDocument } from 'utils/formats';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useDrivers, useQueryParams } from '@hooks';

import ListFilters from '@components/ListFilters';
import PageHeader from '@components/PageHeader';
import SituationBadge from '@components/SituationBadge';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import RoutesPath from '@router/routes';

const ListDrivers: React.FC = () => {
  const { queryParams, onSearch, changePage, changeIsActiveStatus } =
    useQueryParams<IQueryParams>({ page: 1 });

  const { listDrivers } = useDrivers();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { isManagementUser } = useAuth();
  const { scope } = useScope();

  const { data: drivers, isLoading } = useQuery(
    ['drivers', queryParams, scope],
    () => listDrivers(queryParams),
    {
      onError: () => {
        addToast('Não foi possível carregar a lista de fornecedores', 'error');
      },
    }
  );

  const navigateToCreateDriver = () => {
    if (isManagementUser && !scope) {
      addToast(
        'Não é possível cadastrar um motorista sem estar com uma empresa selecionada',
        'error'
      );
      return;
    }
    navigate(RoutesPath.private.drivers.createDriver.path);
  };

  const navigateToDriverDetailPage = (driverId: number) => {
    navigate(
      RoutesPath.private.drivers.detailDriver.path.replace(
        ':driverId',
        driverId.toString()
      )
    );
  };

  return (
    <>
      <PageHeader
        title="Motoristas"
        subtitle="Listagem de todos os motoristas do sistema"
        buttonProps={{
          text: 'Cadastrar motorista',
          onClick: navigateToCreateDriver,
        }}
      />
      <ListFilters
        onSearch={onSearch}
        changeIsActiveStatus={{ queryParams, changeIsActiveStatus }}
      />
      <Table
        data={drivers?.results || []}
        headerData={[
          'Nome',
          'CPF/CNPJ',
          'Telefone',
          'Tipo do caminhão',
          'Placa do caminhão',
          'ANTT',
          'Situação',
        ]}
        emptyMessage="Nenhum motorista encontrado"
        isLoading={isLoading}
        renderRow={(driver) => (
          <B2TableRow
            key={driver.id}
            onClick={() => navigateToDriverDetailPage(driver.id)}
          >
            <TableDataCell>{driver.name}</TableDataCell>
            <TableDataCell>{formatDocument(driver.document)}</TableDataCell>
            <TableDataCell>
              {phone.fromObject({
                areaCode: driver.phone.area_code,
                number: driver.phone.number,
              })}
            </TableDataCell>
            <TableDataCell>{driver.truck.vehicle_type}</TableDataCell>
            <TableDataCell>{driver.truck.truck_license_plate}</TableDataCell>
            <TableDataCell>{driver.truck.antt}</TableDataCell>
            <TableDataCell>
              <SituationBadge isActive={driver.is_active} />
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={drivers?.count}
        changePage={changePage}
      />
    </>
  );
};

export default ListDrivers;
