import React from 'react';

import { currency } from 'b2utils';
import { useFormikContext } from 'formik';
import { formatDate } from 'utils/formats';
import { toDateInput } from 'utils/helpers';

import { useBankAccounts, usePaymentMethods } from '@hooks';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';
import FileSinglePicker from '@components/FileSinglePicker';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import ModalFooterButtons from '@components/ModalFooterButtons';
import TextArea from '@components/TextArea';

import { CustomChooseItemFilter } from '@pages/Services/FormSteps/styles';

import {
  FormContainer,
  FormErrorWrapper,
  PaymentCard,
  PaymentText,
} from '../../styles';

interface ExpensePaymentModalProps
  extends Pick<IBaseDeleteModalProps, 'isOpen' | 'onClose' | 'isLoading'> {
  totalRemainingAmount: number;
}

const ExpensePaymentModal: React.FC<ExpensePaymentModalProps> = ({
  isOpen,
  onClose,
  isLoading,
  totalRemainingAmount,
}) => {
  const formik = useFormikContext<IExpensePaymentFormValues>();

  const { listBankAccounts } = useBankAccounts();
  const { listPaymentMethods } = usePaymentMethods();

  const expenseLabel =
    formik.values.expenses.length > 1 ? 'despesas' : 'despesa';

  const getExpenseDescription = (expense: IExpense) => {
    return `${formatDate(expense.date)} - ${
      expense.provider.name
    } - ${currency.brlMask(expense.value.toString())}`;
  };

  const handleSelectPaymentMethod = (paymentMethod: IPaymentMethod) => {
    formik.setFieldTouched('paymentMethod');
    formik.setFieldValue('paymentMethod', paymentMethod);
  };

  const handleSelectBankAccount = (bankAccount: IBankAccount) => {
    formik.setFieldTouched('bankAccount');
    formik.setFieldValue('bankAccount', bankAccount);
  };

  const handleFileChange = (file?: IFile) => {
    formik.setFieldValue('proofOfPayment', file ?? null);
  };

  return (
    <CustomBaseModal
      title={`Adicionar pagamento para ${expenseLabel}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <FormContainer>
        <PaymentCard>
          <PaymentText>
            {formik.values.expenses.length > 1
              ? 'Foram selecionadas '
              : 'Foi selecionada '}
            <strong>{formik.values.expenses.length}</strong> {expenseLabel} e o
            valor total restante para pagamento é de
            <strong> {currency.centsToBrl(totalRemainingAmount)}</strong>
          </PaymentText>
        </PaymentCard>
        <FormRow>
          {formik.values.expenses.length > 1 && (
            <FormGroup>
              <Label htmlFor="value">Valor total do pagamento *</Label>
              <Input
                type="text"
                id="value"
                name="value"
                placeholder="R$ 0,00"
                value={formik.values.value}
                onBlur={formik.handleBlur}
                onChange={(event) =>
                  formik.setFieldValue(
                    'value',
                    currency.brlMask(event.target.value)
                  )
                }
                invalidValue={!!formik.touched.value && !!formik.errors.value}
              />
              <FormError name="value" />
            </FormGroup>
          )}
          <FormGroup>
            <Label htmlFor="accrualDate">Competência *</Label>
            <Input
              id="accrualDate"
              name="accrualDate"
              type="date"
              max={toDateInput(new Date())}
              value={formik.values.accrualDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalidValue={
                !!formik.touched.accrualDate && !!formik.errors.accrualDate
              }
            />
            <FormError name="accrualDate" />
          </FormGroup>
        </FormRow>
        {formik.values.expenses.map(({ expense, value }, index) => (
          <FormRow key={expense.id}>
            <FormGroup>
              <Label htmlFor={`expense-${index}`}>#{index + 1} despesa</Label>
              <Input
                id={`expense-${index}`}
                name={`expense-${index}`}
                type="text"
                value={getExpenseDescription(expense)}
                disabled
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor={`expenses[${index}].value`}>
                #{index + 1} Valor (
                {currency.brlMask(
                  (expense.value - expense.paid_value).toString()
                )}{' '}
                restante)*
              </Label>
              <Input
                type="text"
                id={`expenses[${index}].value`}
                name={`expenses[${index}].value`}
                placeholder="R$ 0,00"
                value={value}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.setFieldValue(
                    `expenses[${index}].value`,
                    currency.brlMask(event.target.value)
                  );
                  if (formik.values.expenses.length === 1) {
                    formik.setFieldValue(
                      'value',
                      currency.brlMask(event.target.value)
                    );
                  }
                }}
                invalidValue={
                  (!!formik.touched.expenses?.[index]?.value &&
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore Formik has the wrong type for touched object
                    !!formik.errors.expenses?.[index]?.value) ||
                  typeof formik.errors.expenses === 'string'
                }
              />
              <FormError name={`expenses[${index}].value`} />
            </FormGroup>
          </FormRow>
        ))}
        {typeof formik.errors.expenses === 'string' && (
          <FormErrorWrapper>
            <FormError name="expenses" />
          </FormErrorWrapper>
        )}
        <FormRow>
          <FormGroup>
            <Label>Método de pagamento *</Label>
            <CustomChooseItemFilter
              filterValue={
                formik.values.paymentMethod?.name ||
                'Selecionar método de pagamento'
              }
              modalTitle="Selecionar método de pagamento"
              fetch={listPaymentMethods}
              queryParams={{ isActive: 'true' }}
              queryKey="paymentMethods"
              inputLabel="Selecionar método de pagamento *"
              selectedItemLabel="Método de pagamento selecionado"
              shouldRenderSelectedItem={!!formik.values.paymentMethod}
              onSelect={(paymentMethod) =>
                handleSelectPaymentMethod(paymentMethod)
              }
              selectedItem={formik.values.paymentMethod || undefined}
              renderItemText={(paymentMethod) => paymentMethod?.name}
              invalidValue={
                !!formik.touched.paymentMethod && !!formik.errors.paymentMethod
              }
            />
            <FormError name="paymentMethod" />
          </FormGroup>
          <FormGroup>
            <Label>Conta bancária *</Label>
            <CustomChooseItemFilter
              filterValue={
                formik.values.bankAccount?.name || 'Selecionar conta bancária'
              }
              modalTitle="Selecionar conta bancária"
              fetch={listBankAccounts}
              queryParams={{ isActive: 'true' }}
              queryKey="bankAccounts"
              inputLabel="Selecionar conta bancária *"
              selectedItemLabel="Conta bancária selecionado"
              shouldRenderSelectedItem={!!formik.values.bankAccount}
              onSelect={(bankAccount) => handleSelectBankAccount(bankAccount)}
              selectedItem={formik.values.bankAccount || undefined}
              renderItemText={(bankAccount) => bankAccount?.name}
              invalidValue={
                !!formik.touched.bankAccount && !!formik.errors.bankAccount
              }
            />
            <FormError name="bankAccount" />
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Label>Comprovante</Label>
            <FileSinglePicker
              file={formik.values.proofOfPayment}
              onDeleteFile={() => handleFileChange()}
              onAddFile={handleFileChange}
            />
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Label htmlFor="observations">Observações</Label>
            <TextArea
              id="observations"
              name="observations"
              placeholder="Digite aqui"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.observations}
              invalidValue={
                !!formik.touched.observations && !!formik.errors.observations
              }
            />
          </FormGroup>
        </FormRow>
      </FormContainer>
      <ModalFooterButtons
        isLoading={isLoading}
        confirmText="Adicionar"
        onConfirm={() => formik.handleSubmit()}
        cancelText="Cancelar"
        onCancel={onClose}
      />
    </CustomBaseModal>
  );

  return <></>;
};

export default ExpensePaymentModal;
