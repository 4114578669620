import React from 'react';

import { cpf, phone } from 'b2utils';
import { useFormikContext } from 'formik';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import ImagePicker from '@components/ImagePicker';
import Input from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';

import { enums } from '@utils';

import { Container } from './styles';

interface IUserFormProps {
  isCompanyUserForm?: boolean;
  roles: Array<enums.ManagementUserRole | enums.CompanyUserRole>;
}

const UserForm: React.FC<IUserFormProps> = ({
  isCompanyUserForm = false,
  roles,
}) => {
  const formik = useFormikContext<IUserFormValues>();

  return (
    <>
      <FormRow>
        <FormGroup>
          <Container>
            <ImagePicker
              name="avatar"
              text="Escolha uma imagem"
              alt="Foto do usuário"
              formik={formik}
              src={formik.values.avatar?.image_low_url}
              onInvalidExtension="Formato inválido da imagem"
              onInvalidSize="Tamanho máximo permitido da logo é 5MB"
            />
          </Container>
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="name">Nome *</Label>
          <Input
            id="name"
            name="name"
            placeholder="Digite o nome do usuário"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            invalidValue={!!formik.errors.name && !!formik.touched.name}
          />
          <FormError name="name" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Email *</Label>
          <Input
            id="email"
            name="email"
            placeholder="Digite o email do usuário"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            invalidValue={!!formik.errors.email && !!formik.touched.email}
          />
          <FormError name="email" />
        </FormGroup>
      </FormRow>
      {isCompanyUserForm && (
        <FormRow>
          <FormGroup>
            <Label htmlFor="document">CPF *</Label>
            <Input
              type="text"
              id="document"
              name="document"
              placeholder="Digite aqui o CPF"
              value={cpf.mask(formik.values.document || '')}
              maxLength={14}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              invalidValue={
                !!formik.touched.document && !!formik.errors.document
              }
            />
            <FormError name="document" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="phone">Celular</Label>
            <Input
              type="text"
              id="phone"
              name="phone"
              placeholder="Digite aqui"
              maxLength={15}
              value={phone.mask(formik.values.phone || '')}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              invalidValue={!!formik.touched.phone && !!formik.errors.phone}
            />
            <FormError name="phone" />
          </FormGroup>
        </FormRow>
      )}
      <FormRow>
        <FormGroup>
          <Label htmlFor="role">Função *</Label>
          <Select
            id="role"
            name="role"
            onChange={(event) =>
              formik.setFieldValue('role', event.target.value)
            }
            value={formik.values.role}
          >
            <option value="">Selecione uma função</option>
            {roles.map((role) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </Select>
          <FormError name="role" />
        </FormGroup>
      </FormRow>
    </>
  );
};

export default UserForm;
