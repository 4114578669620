import { color } from 'b2utils';
import styled from 'styled-components';
import { CteIssuanceStatus } from 'utils/enums';
import { cteIssuanceStatusProps } from 'utils/helpers';
import { addSpacing } from 'utils/styles';

interface ContainerProps {
  status: CteIssuanceStatus;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  border-radius: 12px;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) => addSpacing(theme.spacing.one, theme.spacing.four)};
  color: ${({ theme }) => theme.colors.white.main};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme, status }) => cteIssuanceStatusProps[status].color(theme)};
  background-color: ${({ theme, status }) =>
    color.addOpacityOnHexColor(
      cteIssuanceStatusProps[status].color(theme),
      0.2
    )};
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
