import { CloseCircle } from 'iconsax-react';
import { B2Modal } from 'react-b2components';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Modal = styled(B2Modal)`
  max-width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: auto;
`;

export const Container = styled.div`
  max-height: 100%;
  overflow: auto;
  position: relative;
  min-width: 560px;
  border-radius: ${({ theme }) => theme.spacing.three};
  background-color: ${({ theme }) => theme.colors.white.main};

  @media (max-width: 620px) {
    min-width: 90svw;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.four};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white.three};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.four, theme.spacing.eight)};
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.main};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const HeaderGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.four};
`;

export const CloseButton = styled(CloseCircle)`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.six};
  color: ${({ theme }) => theme.colors.gray.four};
  transition: opacity 0.3s !important;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const Body = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
