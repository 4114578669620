import React from 'react';

import * as Sentry from '@sentry/react';
import 'moment/dist/locale/pt-br';
import { B2Theme } from 'react-b2components';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import 'react-loading-skeleton/dist/skeleton.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';

import { ApiProvider } from '@contexts/Api';
import { AuthProvider } from '@contexts/Auth';
import { ScopeProvider } from '@contexts/Scope';
import { ToastProvider } from '@contexts/Toast';

import ErrorBoundary from '@components/ErrorBoundary';

import Router from '@router';
import { lightTheme } from '@themes/default';

const container = document.getElementById('app');
const root = createRoot(container!);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_KEY,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      networkDetailAllowUrls: [import.meta.env.VITE_API_BASE_URL],
    }),
  ],
  environment: import.meta.env.VITE_ENVIRONMENT,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.VITE_ENVIRONMENT !== 'development',
});

const renderErrorBoundary = ({ error }: { error: IBoundaryError }) => {
  return <ErrorBoundary error={error} />;
};

root.render(
  <React.StrictMode>
    <ThemeProvider theme={lightTheme}>
      <ReactErrorBoundary fallbackRender={renderErrorBoundary}>
        <B2Theme theme={lightTheme}>
          <ToastProvider>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <ApiProvider>
                <AuthProvider>
                  <ScopeProvider>
                    <Router />
                  </ScopeProvider>
                </AuthProvider>
              </ApiProvider>
            </QueryClientProvider>
          </ToastProvider>
        </B2Theme>
      </ReactErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>
);
