import styled from 'styled-components';

export const FlexGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.three};

  & > * {
    flex: 1 1 270px;
  }
`;
