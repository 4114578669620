import RoutesPath from '@router/routes';

const getRoutes = (isCompanyUser: boolean) => [
  {
    path: isCompanyUser
      ? RoutesPath.auth.login.path
      : RoutesPath.auth.managementLogin.path,
    label: 'Entrar',
  },
  {
    path: RoutesPath.auth.resetPassword.path,
    label: 'Esqueceu senha',
  },
];

export default getRoutes;
