import RoutesPath from '@router/routes';

const getRoutes = (id: string) => {
  return [
    {
      label: 'Usuários internos',
      path: RoutesPath.private.companyUsers.listCompanyUsers.path,
    },
    {
      label: 'Detalhes do usuário',
      path: RoutesPath.private.companyUsers.detailCompanyUser.path.replace(
        ':companyUserId',
        id
      ),
    },
    {
      label: 'Editar usuário',
      path: RoutesPath.private.companyUsers.updateCompanyUser.path.replace(
        ':companyUserId',
        id
      ),
    },
  ];
};

export default getRoutes;
