import styled from 'styled-components';

interface SelectedColorProps {
  color: string;
}

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 17px;
  margin-top: 12px;
  background: #f4f4f4;
  color: ${(props) => props.theme.colors.gray.twelve};
  border: 2px solid transparent;
  border-radius: 12px;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.fontSize.small};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const SelectedColor = styled.div<SelectedColorProps>`
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.color};
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  left: 16px;
`;

export const PickerContainer = styled.div`
  position: absolute;
  background-color: red;
  z-index: 1;
  bottom: 100%;
  top: 100%;
  right: 100%;
  transform: translateY(-8px);
  padding: 8px;
  background-color: transparent;
  border-radius: 8px;
`;
