import React from 'react';

import { ErrorMessage, useFormikContext } from 'formik';

import { Error } from './styles';

interface IFormErrorProps {
  name: string;
  className?: string;
}

const FormError: React.FC<IFormErrorProps> = ({ name, className }) => {
  const { status } = useFormikContext<any>();

  return (
    <>
      <ErrorMessage
        name={name}
        render={(msg) => (
          <Error data-cy={`error_${name}`} className={className}>
            {msg}
          </Error>
        )}
      />
      {status &&
        status[name] &&
        status[name].map((error: string, index: number) => (
          <Error key={index} className={className}>
            {error}
          </Error>
        ))}
    </>
  );
};

export default FormError;
