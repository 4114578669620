import { AddSquare, CloseSquare } from 'iconsax-react';
import styled, { css } from 'styled-components';

const BaseButton = css`
  min-width: ${({ theme }) => theme.spacing.eight};
  min-height: ${({ theme }) => theme.spacing.eight};
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
  &:active {
    transform: scale(0.8);
  }
  cursor: pointer;
`;

export const FieldArrayContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: ${({ theme }) => theme.spacing.one};
`;

export const ArrayFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.one};
  animation: fade-in 0.2s ease-in-out;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const RemoveButton = styled(CloseSquare)`
  ${BaseButton}
  color: ${({ theme }) => theme.colors.error};
`;

export const AddButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.one};
`;

export const AddButton = styled(AddSquare)`
  ${BaseButton}
  color: ${({ theme }) => theme.colors.info};
`;

export const AddButtonLabel = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.black.two};
`;
