import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.bankAccounts.listBankAccounts.path,
    label: 'Contas bancárias',
  },
  {
    path: RoutesPath.private.bankAccounts.createBankAccount.path,
    label: 'Cadastrar conta bancária',
  },
];

export default routes;
