import React, { useEffect, useRef } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { usePaymentTerms } from '@hooks';

import BaseForm from '@components/BaseForm';

import RoutesPath from '@router/routes';

import PaymentTermForm from '../Form/PaymentTermForm';
import routes from './routes';

const UpdatePaymentTerm: React.FC = () => {
  const paymentTermFormRef = useRef<IFormStepRef>(null);

  const { paymentTermId } = useParams<{ paymentTermId: string }>();
  const { state } = useLocation();

  const paymentTermData: IPaymentTerm | undefined = state.data;

  const { updatePaymentTerm, getPaymentTerm } = usePaymentTerms();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { scope } = useScope();
  const { isManagementUser } = useAuth();

  const { data, isLoading } = useQuery(
    ['paymentTerm', paymentTermId],
    () => getPaymentTerm(Number(paymentTermId)),
    {
      enabled: !!paymentTermId,
      placeholderData: paymentTermData,
    }
  );

  const updatePaymentTermRequest = useMutation(
    (values: IPaymentTermFormValues) =>
      updatePaymentTerm(Number(paymentTermId), values),
    {
      onSuccess: () => {
        addToast('Forma de pagamento editada com sucesso', 'success');
        navigate(RoutesPath.private.paymentMethods.listPaymentTerms.path, {
          replace: true,
        });
      },
      onError: () => {
        addToast('Erro ao editar forma de pagamento', 'error');
      },
    }
  );

  useEffect(() => {
    if (!scope && isManagementUser) {
      addToast(
        'Selecione uma empresa para editar uma forma de pagamento',
        'error'
      );
      navigate(RoutesPath.private.paymentMethods.listPaymentTerms.path, {
        replace: true,
      });
    }
  }, [scope, navigate, addToast, isManagementUser]);

  const steps: Array<IStep> = [
    {
      title: 'Informações da forma de pagamento',
      ref: paymentTermFormRef,
      component: PaymentTermForm,
    },
  ];

  if (isLoading || !data) {
    return;
  }

  const initialValues = {
    description: data.description,
  };

  return (
    <BaseForm
      title="Editar forma de pagamento"
      steps={steps}
      createRequest={updatePaymentTermRequest}
      routes={routes}
      initialValues={initialValues}
    />
  );
};

export default UpdatePaymentTerm;
