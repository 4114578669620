import React, { forwardRef, useImperativeHandle } from 'react';

import { FieldArray, FormikProvider, useFormik } from 'formik';
import { B2Button } from 'react-b2components';
import * as yup from 'yup';

import SectionHeader from '@components/SectionHeader';

import { errors } from '@utils';

import VehicleQuotation from '../VehicleQuotation';
import { AddressesFormsContainer } from './styles';

interface VehiclesQuotationProps {
  initialValues?: IVehicleQuotationsFormValues;
  onFinish: (data: IVehicleQuotationsFormValues) => void;
}

const VehiclesQuotation: React.ForwardRefRenderFunction<
  IFormStepRef,
  VehiclesQuotationProps
> = ({ initialValues, onFinish }, ref) => {
  const emptyVehicle: IVehicleQuotationFormValues = {
    model: '',
    estimatedDeliveryDays: '',
    insuranceValue: '',
    freightValue: '',
    origin: null,
    destination: null,
  };

  const formikInitialValues: IVehicleQuotationsFormValues = {
    vehicles: [emptyVehicle],
    ...initialValues,
  };

  const vehicleValidationSchema = yup.object().shape({
    model: yup.string().trim().required(errors.required),
    estimatedDeliveryDays: yup.string().trim().required(errors.required),
    insuranceValue: yup.string().trim().required(errors.required),
    freightValue: yup.string().trim().required(errors.required),
    origin: yup
      .object()
      .shape({
        name: yup.string().trim().required(errors.required),
        state: yup.string().trim().required(errors.required),
      })
      .nullable()
      .notOneOf([null], errors.required),
    destination: yup
      .object()
      .shape({
        name: yup.string().trim().required(errors.required),
        state: yup.string().trim().required(errors.required),
      })
      .nullable()
      .notOneOf([null], errors.required),
  });

  const formikValidationSchema = yup.object().shape({
    vehicles: yup.array().of(vehicleValidationSchema),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name="vehicles"
        render={({ remove, push }) => (
          <AddressesFormsContainer>
            {formik.values.vehicles.map((friend, index) => (
              <div key={index}>
                <SectionHeader
                  title={`Veículo ${index + 1}`}
                  deleteButton={{
                    text: 'Remover veículo',
                    onClick: () => remove(index),
                    disabled: formik.values.vehicles.length === 1,
                  }}
                  isFormHeader
                />
                <VehicleQuotation index={index} />
              </div>
            ))}
            <B2Button variant="transparent" onClick={() => push(emptyVehicle)}>
              Adicionar novo veículo
            </B2Button>
          </AddressesFormsContainer>
        )}
      />
    </FormikProvider>
  );
};

export default forwardRef(VehiclesQuotation);
