import React from 'react';

import SectionHeader from '@components/SectionHeader';

import {
  Content,
  Description,
  EditIcon,
  FieldContainer,
  FieldName,
  LinkContainer,
} from './styles';

interface DetailSectionProps {
  data: Array<ISection>;
}

const DetailSection: React.FC<DetailSectionProps> = ({ data }) => {
  return (
    <>
      {data.map(({ title, fields }) => (
        <div key={title}>
          <SectionHeader title={title} />
          <Content>
            {fields.map(
              ({ name, description, hasHighlight, editHref }, index) => (
                <FieldContainer key={index}>
                  <FieldName>{name}</FieldName>
                  {editHref ? (
                    <LinkContainer to={editHref}>
                      <Description hasHighlight={hasHighlight}>
                        {description}
                      </Description>
                      <EditIcon />
                    </LinkContainer>
                  ) : (
                    <Description hasHighlight={hasHighlight}>
                      {description}
                    </Description>
                  )}
                </FieldContainer>
              )
            )}
          </Content>
        </div>
      ))}
    </>
  );
};

export default DetailSection;
