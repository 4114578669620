import React, { useRef } from 'react';

import { ServiceType } from 'utils/enums';

import BaseForm from '@components/BaseForm';

import GeneralServiceInfo from '@pages/Services/FormSteps/GeneralServiceInfo';
import ServiceCteForm from '@pages/Services/FormSteps/ServiceCteForm';
import ServiceRecipientForm from '@pages/Services/FormSteps/ServiceRecipientForm';
import ServiceSenderForm from '@pages/Services/FormSteps/ServiceSenderForm';
import ServiceVehicleForm from '@pages/Services/FormSteps/ServiceVehicleForm';

const OwnService: React.FC<IServiceFormProps> = ({
  onFinish,
  onGoBackOnFirstStep,
  initialValues,
}) => {
  const generalServiceInfoStepRef = useRef<IFormStepRef>(null);
  const serviceSenderStepRef = useRef<IFormStepRef>(null);
  const serviceRecipientStepRef = useRef<IFormStepRef>(null);
  const serviceVehicleStepRef = useRef<IFormStepRef>(null);
  const serviceCteStepRef = useRef<IFormStepRef>(null);

  const handleGoBackOnFirstStep = () => {
    onGoBackOnFirstStep?.();
    generalServiceInfoStepRef.current?.resetForm?.();
  };

  return (
    <BaseForm
      initialValues={{ type: ServiceType.OWN, ...initialValues }}
      onGoBackOnFirstStep={
        onGoBackOnFirstStep ? handleGoBackOnFirstStep : undefined
      }
      createRequest={onFinish}
      steps={[
        {
          title: 'Informações do serviço',
          ref: generalServiceInfoStepRef,
          component: GeneralServiceInfo,
        },
        {
          title: 'Remetente',
          ref: serviceSenderStepRef,
          component: ServiceSenderForm,
        },
        {
          title: 'Destinatário',
          ref: serviceRecipientStepRef,
          component: ServiceRecipientForm,
        },
        {
          title: 'Veículo',
          ref: serviceVehicleStepRef,
          component: ServiceVehicleForm,
        },
        {
          title: 'CT-e',
          ref: serviceCteStepRef,
          component: ServiceCteForm,
        },
      ]}
    />
  );
};

export default OwnService;
