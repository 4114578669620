import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { UserAdd } from 'iconsax-react';
import { CityType, CollectionAndDeliveryType } from 'utils/enums';
import { toAddressString } from 'utils/helpers';
import * as yup from 'yup';

import { useClients } from '@hooks';

import AddNewEntityButton from '@components/AddNewEntityButton';
import {
  InputGroup,
  RadioButtonsContainer,
} from '@components/Base/PrivateBase/styles';
import BaseCityForm from '@components/BaseCityForm';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';

import RoutesPath from '@router/routes';
import { errors } from '@utils';

import { ChooseItemContainer, CustomChooseItemFilter } from '../styles';

interface ServiceSenderFormProps {
  initialValues?: IServiceSender;
  onFinish: (data: IServiceSender) => void;
}

const ServiceSenderForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  ServiceSenderFormProps
> = ({ onFinish, initialValues }, ref) => {
  const { listClients } = useClients();

  const collectionAndDeliveryTypes = Object.values(CollectionAndDeliveryType);

  const emptyClient = { id: 0 } as IClientUser;
  const emptyCity: ICity = {
    id: 0,
    name: '',
    state: '',
  };

  const formikInitialValues: IServiceSender = {
    sender: emptyClient,
    collectionType: CollectionAndDeliveryType.BASE,
    collectionAddressId: 0,
    origin: emptyCity,
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    sender: yup.object().shape({
      id: yup.number().notOneOf([0], errors.required).required(errors.required),
    }),
    collectionType: yup.string().trim().required(errors.required),
    collectionAddressId: yup
      .number()
      .notOneOf([0], errors.required)
      .required(errors.required),
    origin: yup.object().shape({
      id: yup.number().min(1, errors.required).required(errors.required),
      name: yup.string().required(errors.required),
      state: yup.string().required(errors.required),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  const handleSelectSender = (sender: IClientUser) => {
    formik.setFieldTouched('sender');
    formik.setFieldValue('sender', sender);
    formik.setFieldValue(
      'collectionAddressId',
      sender.default_address?.id || 0
    );
    formik.setFieldValue('origin', sender.default_address?.city || emptyCity);
  };

  const handleSelectAddress = (addressId: number) => {
    const selectedAddress = formik.values.sender?.addresses.find(
      (address) => address.id === addressId
    );
    formik.setFieldValue('collectionAddressId', Number(addressId));
    formik.setFieldValue('origin', selectedAddress?.city || emptyCity);
  };

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label>Remetente *</Label>
          <ChooseItemContainer>
            <CustomChooseItemFilter
              filterValue={
                formik.values.sender.id !== 0
                  ? formik.values.sender.name
                  : 'Selecionar remetente'
              }
              modalTitle="Selecionar remetente"
              fetch={listClients}
              queryParams={{ isActive: 'true' }}
              queryKey="clients"
              inputLabel="Selecionar remetente *"
              selectedItemLabel="Remetente selecionado"
              shouldRenderSelectedItem={formik.values.sender.id !== 0}
              onSelect={handleSelectSender}
              selectedItem={formik.values.sender || emptyClient}
              renderItemText={(client) => client?.name}
              refetchOnWindowFocus="always"
              invalidValue={
                !!formik.touched.sender?.id && !!formik.errors.sender?.id
              }
            />
            <AddNewEntityButton
              routePath={RoutesPath.private.clients.createClient.path}
              tooltip="Adicionar novo remetente"
              icon={UserAdd}
            />
          </ChooseItemContainer>
          <FormError name="sender.id" />
        </FormGroup>
        <FormGroup>
          <Label>Endereço de coleta *</Label>
          <ChooseItemContainer>
            <Select
              id="collectionAddressId"
              name="collectionAddressId"
              onChange={(event) =>
                handleSelectAddress(Number(event.target.value))
              }
              value={formik.values.collectionAddressId}
              disabled={formik.values.sender.id === 0}
              invalidValue={
                !!formik.touched.collectionAddressId &&
                !!formik.errors.collectionAddressId
              }
            >
              <option value={0}>Selecionar endereço</option>
              {formik.values.sender?.addresses?.map((address) => (
                <option value={address.id} key={address.id}>
                  {toAddressString(address)}
                </option>
              ))}
            </Select>
          </ChooseItemContainer>
          <FormError name="collectionAddressId" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="collectionType">Tipo de coleta *</Label>
          <RadioButtonsContainer>
            {collectionAndDeliveryTypes.map((type) => (
              <InputGroup key={type}>
                <Input
                  type="radio"
                  id={`collectionType${type}`}
                  name="collectionType"
                  checked={formik.values.collectionType === type}
                  onChange={() => formik.setFieldValue('collectionType', type)}
                  onBlur={formik.handleBlur}
                />
                <Label htmlFor={`collectionType${type}`}>{type}</Label>
              </InputGroup>
            ))}
            <FormError name="collectionType" />
          </RadioButtonsContainer>
        </FormGroup>
      </FormRow>
      <BaseCityForm field="origin" cityType={CityType.ORIGIN} />
    </FormikProvider>
  );
};

export default forwardRef(ServiceSenderForm);
