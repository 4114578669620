import { B2Card } from 'react-b2components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.three};
`;

export const FlexGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.three};

  & > * {
    flex: 1 1 270px;
  }
`;

export const MetricCard = styled(B2Card)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.four};
  padding: ${({ theme }) => theme.spacing.five};
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.four};
  flex-wrap: wrap;
`;

export const HeaderText = styled.p`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;
