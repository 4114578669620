import {
  TickCircle,
  TruckFast,
  TruckRemove,
  TruckTick,
  TruckTime,
  DocumentDownload,
} from 'iconsax-react';
import styled, { css } from 'styled-components';

interface TruckIconProps {
  $isStarted?: boolean;
}

const IconStyle = css<TruckIconProps>`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.seven};
  height: ${({ theme }) => theme.spacing.seven};
  color: ${({ theme, $isStarted }) =>
    $isStarted ? theme.colors.success : theme.colors.gray.six};
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.one};

  img {
    ${IconStyle}
    width: ${({ theme }) => theme.spacing.six};
    height: ${({ theme }) => theme.spacing.six};
  }
`;

export const TruckTimeIcon = styled(TruckTime)<TruckIconProps>`
  ${IconStyle}
`;

export const TruckFastIcon = styled(TruckFast)<TruckIconProps>`
  ${IconStyle}
`;

export const TruckTickIcon = styled(TruckTick)<TruckIconProps>`
  ${IconStyle}
`;

export const TruckRemoveIcon = styled(TruckRemove)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.error};
`;

export const TickCircleIcon = styled(TickCircle)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.success};
`;

export const PdfReportIcon = styled(DocumentDownload)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.gray.six};
`;
