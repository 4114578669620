import React from 'react';

import { phone } from 'b2utils';
import { CarrierTypeMap } from 'utils/enums';
import { formatDate, formatDocument } from 'utils/formats';

import EditDriverButton from './EditDriverButton';
import {
  DriverInfo,
  DriverInfoContainer,
  Container,
  Description,
  Divider,
  InfoContainer,
  InfoIcon,
  InfoQuote,
  Title,
  TitleContainer,
} from './styles';

interface IDriverCardProps {
  driver: IDriver;
}

const DriverCard: React.FC<IDriverCardProps> = ({ driver }) => {
  return (
    <Container>
      <TitleContainer>
        <Title>Dados de cadastro do motorista</Title>
        <EditDriverButton driver={driver} />
      </TitleContainer>
      <Divider />
      <div>
        <InfoContainer>
          <InfoIcon />
          <InfoQuote>
            As informações do motorista são importantes para conseguir uma
            interação mais humana com o motorista
          </InfoQuote>
        </InfoContainer>
        <div>
          <DriverInfoContainer>
            <DriverInfo>Nome:</DriverInfo>
            <Description>{driver.name}</Description>
          </DriverInfoContainer>
          <DriverInfoContainer>
            <DriverInfo>Email:</DriverInfo>
            <Description>{driver.email}</Description>
          </DriverInfoContainer>
          <DriverInfoContainer>
            <DriverInfo>Telefone:</DriverInfo>
            <Description>
              {phone.fromObject({
                areaCode: driver.phone.area_code,
                number: driver.phone.number,
              })}
            </Description>
          </DriverInfoContainer>
          <DriverInfoContainer>
            <DriverInfo>CPF / CNPJ:</DriverInfo>
            <Description>{formatDocument(driver.document)}</Description>
          </DriverInfoContainer>
          <DriverInfoContainer>
            <DriverInfo>Data de nascimento:</DriverInfo>
            <Description>
              {driver.date_of_birth ? formatDate(driver.date_of_birth) : '-'}
            </Description>
          </DriverInfoContainer>
          <DriverInfoContainer>
            <DriverInfo>Tipo do transportador:</DriverInfo>
            <Description>{CarrierTypeMap[driver.carrier_type]}</Description>
          </DriverInfoContainer>
        </div>
      </div>
    </Container>
  );
};

export default DriverCard;
