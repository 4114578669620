import React from 'react';

import { B2Card } from 'react-b2components';

import { useAuth } from '@contexts/Auth';

import DetailSection from '@components/DetailSection';

import { getServiceData } from './serviceData';

interface ServiceCardProps {
  service: IService;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ service }) => {
  const { isClientUser } = useAuth();

  return (
    <B2Card>
      <DetailSection data={getServiceData(service, isClientUser)} />
    </B2Card>
  );
};

export default ServiceCard;
