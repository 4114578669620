import React from 'react';

import { useFormikContext } from 'formik';

import BaseModal from '@components/BaseModal';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import ModalFooterButtons from '@components/ModalFooterButtons';
import PasswordInput from '@components/PasswordInput';

import { Body, BodyMessage } from './styles';

interface ReasonForMissingCteModalProps
  extends Omit<IBaseDeleteModalProps, 'title' | 'children'> {
  servicesWithoutCteQty: number;
}

const ReasonForMissingCteModal: React.FC<ReasonForMissingCteModalProps> = ({
  servicesWithoutCteQty,
  ...props
}) => {
  const formik = useFormikContext<IIssueMdfeFormValues>();

  return (
    <BaseModal
      isOpen={props.isOpen}
      title="Este embarque possui serviços sem CT-e"
      onClose={props.onClose}
    >
      <Body>
        <BodyMessage>
          {`Este embarque possui ${servicesWithoutCteQty} serviço${
            servicesWithoutCteQty > 1 ? 's' : ''
          } sem CT-e emitido e/ou sem chave de
          acesso cadastrada. Deseja continuar mesmo assim?`}
        </BodyMessage>
        <div>
          <FormRow>
            <FormGroup>
              <Label htmlFor="reasonForMissingCteKeys">Justificativa *</Label>
              <Input
                id="reasonForMissingCteKeys"
                name="reasonForMissingCteKeys"
                placeholder="Digite a justificativa"
                onChange={formik.handleChange}
                onBlur={() => formik.handleBlur('reasonForMissingCteKeys')}
                value={formik.values.reasonForMissingCteKeys}
                invalidValue={
                  !!formik.errors.reasonForMissingCteKeys &&
                  !!formik.touched.reasonForMissingCteKeys
                }
              />
              <FormError name="reasonForMissingCteKeys" />
            </FormGroup>
          </FormRow>
          <FormGroup>
            <Label htmlFor="password">Senha *</Label>
            <PasswordInput
              type="password"
              placeholder="Digite sua senha"
              id="password"
              name="password"
              autoComplete="new-password"
              onChange={formik.handleChange}
              onBlur={() => formik.handleBlur('password')}
              value={formik.values.password}
              invalidValue={
                !!formik.touched.password && !!formik.errors.password
              }
            />
            <FormError name="password" />
          </FormGroup>
        </div>
      </Body>
      <ModalFooterButtons
        isLoading={props.isLoading}
        confirmText="Continuar e emitir"
        onConfirm={props.onConfirm}
        cancelText="Cancelar"
        onCancel={props.onClose}
      />
    </BaseModal>
  );
};

export default ReasonForMissingCteModal;
