import React from 'react';

import RoutesPath from '@router/routes';

import CteList from './Tabs/CteList';
import DeliveryReceiptsList from './Tabs/DeliveryReceiptsList';
import DeparturesList from './Tabs/DeparturesList';
import InspectionsList from './Tabs/InspectionsList';
import MDFeList from './Tabs/MDFeList';
import ServiceContractsList from './Tabs/ServiceContractsList';
import ServicesList from './Tabs/ServicesList';

export const tabs: Array<ITab> = [
  {
    title: 'Serviços',
    component: <ServicesList />,
    path: RoutesPath.private.documents.listServices.path,
  },
  {
    title: 'Vistorias',
    component: <InspectionsList />,
    path: RoutesPath.private.documents.listInspections.path,
  },
  {
    title: 'Embarques',
    component: <DeparturesList />,
    path: RoutesPath.private.documents.listDepartures.path,
  },
  {
    title: 'Contratos',
    component: <ServiceContractsList />,
    path: RoutesPath.private.documents.listServiceContracts.path,
  },
  {
    title: 'Termos de entrega',
    component: <DeliveryReceiptsList />,
    path: RoutesPath.private.documents.listTermOfReceipts.path,
  },
  {
    title: 'CT-es',
    component: <CteList />,
    path: RoutesPath.private.documents.listCte.path,
  },
  {
    title: 'MDF-es',
    component: <MDFeList />,
    path: RoutesPath.private.documents.listMDFe.path,
  },
];
