import React from 'react';

import { Icon as IconType } from 'iconsax-react';

import { Anchor } from './styles';

interface DownloadAnchorProps {
  href: string;
  label: string;
  icon: IconType;
}

const DownloadAnchor: React.FC<DownloadAnchorProps> = ({
  href,
  label,
  icon: Icon,
}) => {
  return (
    <Anchor href={href} target="_blank" rel="noreferrer" download>
      {label} <Icon variant="Bold" />
    </Anchor>
  );
};

export default DownloadAnchor;
