import { enums } from '@utils';

import { getAdminSidebarData } from './adminSidebarData';
import { getLogisticsSidebarData } from './logisticsSidebarData';
import { salesSidebarData } from './salesSidebarData';

export const getCompanySidebarData = (companyId: number) => ({
  [enums.CompanyUserRole.ADMIN]: getAdminSidebarData(companyId),
  [enums.CompanyUserRole.STAFF]: getAdminSidebarData(companyId),
  [enums.CompanyUserRole.INSPECTOR]: [],
  [enums.CompanyUserRole.SALES]: salesSidebarData,
  [enums.CompanyUserRole.LOGISTICS]: getLogisticsSidebarData(companyId),
});
