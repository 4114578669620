import React, { useEffect, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { currency } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { CteIssuanceStatus } from 'utils/enums';
import {
  getServiceCteValidationSchema,
  throwToastApiErrors,
} from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useFiscal, useServices } from '@hooks';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';
import { IBaseModalProps } from '@components/BaseModal';
import ClipBoardButton from '@components/ClipBoardButton';
import ConfirmationModal from '@components/ConfirmationModal';
import Loading from '@components/Loading';

import HeaderButtons from './HeaderButtons';
import IssuanceStatusLabel from './IssuanceStatusLabel';
import IssueCteButton from './IssueCteButton';
import ServiceCteForm from './ServiceCteForm';
import ServiceTable from './ServiceTable';
import { ModalContent } from './styles';

type CteIssuanceModalProps = Omit<IBaseModalProps, 'title' | 'children'> & {
  service: IService;
  refetchServices: () => void;
};

const CteIssuanceModal: React.FC<CteIssuanceModalProps> = ({
  isOpen,
  onClose,
  service,
  refetchServices,
}) => {
  const [enableFetchCte, setEnableFetchCte] = useState(false);
  const [isUnsavedDataModalOpen, setIsUnsavedDataModalOpen] = useState(false);

  const { getCte } = useFiscal();
  const { updateServiceCte } = useServices();
  const { addToast } = useToast();

  const formikInitialValues: IServiceCte = useMemo(
    () => ({
      cfop: service.cfop || { id: 0, code: '', description: 'Nenhum' },
      icmsType: service.icms_type || undefined,
      icmsRate: service.icms_rate?.toString() || '',
      icmsReduction: service.icms_reduction?.toString() || '',
      optingForCreditGranted: !!service.opting_for_credit_granted,
      cteFreightCost: currency.brlMask(
        service.cte_freight_cost?.toString() || ''
      ),
      cteGeneralObservations: service.cte_general_observations || '',
    }),
    [service]
  );

  const {
    data: cte,
    isLoading: isCteLoading,
    refetch: refetchCte,
    isRefetching: isCteRefetching,
  } = useQuery(['cte', service.cte!.id], () => getCte(service.cte!.id), {
    onSuccess: () => {
      refetchServices();
    },
    onError: () => {
      addToast('Não foi possível carregar os dados do CT-e', 'error');
    },
    placeholderData: {
      id: service.cte?.id || 0,
      service: {
        ...service,
        cte_series: '',
      },
      issuance_status: service.cte?.status || CteIssuanceStatus.PENDING,
      status_message: null,
      cancel_reason: null,
      number: '',
      requested_at: null,
      issued_at: null,
      canceled_at: null,
      cte_key: null,
      ns_receipt: null,
      authorization_protocol_number: null,
      pdf_file: null,
      xml_file: null,
      pdf_cancel_file: null,
      xml_cancel_file: null,
      rectifications: [],
      endorsement: null,
    },
    enabled: enableFetchCte && !!service.cte,
  });

  const updateServiceRequest = useMutation(
    (values: IServiceCte) => updateServiceCte(service.id, values),
    {
      onSuccess: () => {
        refetchServices();
        addToast('Informações do serviço editadas com sucesso', 'success');
      },
      onError: (error: AxiosError) => {
        addToast('Erro ao editar informações do serviço', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: getServiceCteValidationSchema(service.company.tax_regime),
    onSubmit: (values) => updateServiceRequest.mutate(values),
  });

  const hasUnsavedData = useMemo(
    () => JSON.stringify(formik.values) !== JSON.stringify(formikInitialValues),
    [formik.values, formikInitialValues]
  );

  useEffect(() => {
    if (isOpen) {
      setEnableFetchCte(true);
      if (enableFetchCte) {
        refetchCte();
      }
    }
  }, [enableFetchCte, isOpen, refetchCte]);

  const isLoading = isCteLoading || !cte;

  if (!isOpen) {
    return null;
  }

  return (
    <FormikProvider value={formik}>
      <CustomBaseModal
        title={`Emissão de CT-e ${
          cte?.number
            ? `: série ${cte?.service.cte_series} / n° ${cte.number}`
            : ''
        }`}
        isOpen={isOpen}
        onClose={onClose}
        headerComponent={
          <HeaderButtons
            cte={cte}
            hasUnsavedData={hasUnsavedData}
            onRequestSuccess={refetchCte}
            openUnsavedDataModal={() => setIsUnsavedDataModalOpen(true)}
          />
        }
      >
        <ModalContent>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <IssuanceStatusLabel cte={cte} isLoading={isCteRefetching} />
              {cte.cte_key && (
                <ClipBoardButton
                  label="Chave do CT-e"
                  value={cte.cte_key}
                  toastMessage="Chave do CT-e copiada com sucesso"
                  tooltipMessage="Copiar chave"
                />
              )}
              <ServiceCteForm
                service={service}
                onSubmit={updateServiceRequest}
              />
              <ServiceTable service={service} />
              <IssueCteButton
                cte={cte}
                hasUnsavedData={hasUnsavedData}
                onRequestSuccess={refetchCte}
                openUnsavedDataModal={() => setIsUnsavedDataModalOpen(true)}
              />
            </>
          )}
        </ModalContent>
      </CustomBaseModal>
      <ConfirmationModal
        title="Você possui informações não salvas"
        message="Deseja salvar as alterações antes de emitir o CT-e?"
        isOpen={isUnsavedDataModalOpen}
        isLoading={false}
        confirmButtonText="Sim, salvar alterações"
        onConfirm={() => {
          formik.handleSubmit();
          setIsUnsavedDataModalOpen(false);
        }}
        cancelButtonText="Não, cancelar"
        onClose={() => setIsUnsavedDataModalOpen(false)}
      />
    </FormikProvider>
  );
};

export default CteIssuanceModal;
