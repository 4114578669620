import { useCallback } from 'react';

import { ServiceRelatedEntity } from 'utils/enums';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const useMetrics = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const getQuotationsCount = useCallback(
    async (params: IQuotationsCountQueryParams) => {
      const response = await request<IBaseCountMetric>({
        method: 'get',
        url: endpoints.metrics.quotations.count,
        headers: { scope },
        params: {
          year_month: params.yearMonth,
          status: params.status || undefined,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getServicesCount = useCallback(
    async (params: IMetricQueryParamsByYearMonth) => {
      const response = await request<IBaseCountMetric>({
        method: 'get',
        url: endpoints.metrics.services.count,
        headers: { scope },
        params: {
          year_month: params.yearMonth,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getDeparturesCount = useCallback(
    async (params: IMetricQueryParamsByYearMonth) => {
      const response = await request<IBaseCountMetric>({
        method: 'get',
        url: endpoints.metrics.departures.count,
        headers: { scope },
        params: {
          year_month: params.yearMonth,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getInspectionsCount = useCallback(
    async (params: IMetricQueryParamsByYearMonth) => {
      const response = await request<IBaseCountMetric>({
        method: 'get',
        url: endpoints.metrics.inspections.count,
        headers: { scope },
        params: {
          year_month: params.yearMonth,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getQuotationsCountByDateAggregation = useCallback(
    async (params: IMetricQueryParamsByDateInterval) => {
      const response = await request<ICountMetricByDateAggregation>({
        method: 'get',
        url: endpoints.metrics.quotations.dateAggregation,
        headers: { scope },
        params: {
          start_at: params.startAt,
          end_at: params.endAt,
          time_unit: 'month',
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getServicesCountByDateAggregation = useCallback(
    async (params: IMetricQueryParamsByDateInterval) => {
      const response = await request<ICountMetricByDateAggregation>({
        method: 'get',
        url: endpoints.metrics.services.dateAggregation,
        headers: { scope },
        params: {
          start_at: params.startAt,
          end_at: params.endAt,
          time_unit: 'month',
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getDeparturesCountByDateAggregation = useCallback(
    async (params: IMetricQueryParamsByDateInterval) => {
      const response = await request<ICountMetricByDateAggregation>({
        method: 'get',
        url: endpoints.metrics.departures.dateAggregation,
        headers: { scope },
        params: {
          start_at: params.startAt,
          end_at: params.endAt,
          time_unit: 'month',
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getInspectionsCountByDateAggregation = useCallback(
    async (params: IMetricQueryParamsByDateInterval) => {
      const response = await request<ICountMetricByDateAggregation>({
        method: 'get',
        url: endpoints.metrics.inspections.dateAggregation,
        headers: { scope },
        params: {
          start_at: params.startAt,
          end_at: params.endAt,
          time_unit: 'month',
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getServicesCountByPersonInCharge = useCallback(
    async (params: IMetricQueryParamsByDateInterval) => {
      const response = await request<Array<IServicesCountByPersonInCharge>>({
        method: 'get',
        url: endpoints.metrics.services.countByRelatedEntity,
        headers: { scope },
        params: {
          start_at: params.startAt,
          end_at: params.endAt,
          entity: ServiceRelatedEntity.PERSON_IN_CHARGE,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getServicesCountByCategory = useCallback(
    async (params: IMetricQueryParamsByDateInterval) => {
      const response = await request<Array<IServicesCountByCategory>>({
        method: 'get',
        url: endpoints.metrics.services.countByRelatedEntity,
        headers: { scope },
        params: {
          start_at: params.startAt,
          end_at: params.endAt,
          entity: ServiceRelatedEntity.CATEGORY,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getServicesCountByVehicleType = useCallback(
    async (params: IMetricQueryParamsByDateInterval) => {
      const response = await request<Array<IServicesCountByVehicleType>>({
        method: 'get',
        url: endpoints.metrics.services.countByRelatedEntity,
        headers: { scope },
        params: {
          start_at: params.startAt,
          end_at: params.endAt,
          entity: ServiceRelatedEntity.VEHICLE_TYPE,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getServicesCountByOriginState = useCallback(
    async (params: IMetricQueryParamsByDateInterval) => {
      const response = await request<Array<IServicesCountByOriginState>>({
        method: 'get',
        url: endpoints.metrics.services.countByRelatedEntity,
        headers: { scope },
        params: {
          start_at: params.startAt,
          end_at: params.endAt,
          entity: ServiceRelatedEntity.ORIGIN_STATE,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  return {
    getQuotationsCount,
    getServicesCount,
    getDeparturesCount,
    getInspectionsCount,
    getQuotationsCountByDateAggregation,
    getServicesCountByDateAggregation,
    getDeparturesCountByDateAggregation,
    getInspectionsCountByDateAggregation,
    getServicesCountByPersonInCharge,
    getServicesCountByCategory,
    getServicesCountByVehicleType,
    getServicesCountByOriginState,
  };
};

export default useMetrics;
