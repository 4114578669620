import RoutesPath from '@router/routes';

const routes = [
  { path: RoutesPath.private.clients.listClients.path, label: 'Clientes' },
  {
    path: RoutesPath.private.clients.detailClient.path,
    label: 'Detalhes do cliente',
  },
];

export default routes;
