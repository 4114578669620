import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { CityType, ServiceType } from 'utils/enums';
import * as yup from 'yup';

import BaseCityForm from '@components/BaseCityForm';

import { errors } from '@utils';

interface DestinyOriginFormProps {
  initialValues?: IDestinyOriginServiceForm;
  onFinish: (data: IDestinyOriginServiceForm) => void;
}

const DestinyOriginForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  DestinyOriginFormProps
> = ({ onFinish, initialValues }, ref) => {
  const formikInitialValues: IDestinyOriginServiceForm = {
    destination: {
      id: 0,
      name: '',
      state: '',
    },
    origin: {
      id: 0,
      name: '',
      state: '',
    },
    type: '',
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    origin: yup.object().shape({
      id: yup.number().min(1, errors.required).required(errors.required),
      name: yup.string().required(errors.required),
      state: yup.string().required(errors.required),
    }),
    destination: yup.object().shape({
      id: yup.number().when('type', {
        is: () => formikInitialValues.type !== ServiceType.STORAGE,
        then: (schema) =>
          schema.min(1, errors.required).required(errors.required),
      }),
      name: yup.string().when('type', {
        is: () => formikInitialValues.type !== ServiceType.STORAGE,
        then: (schema) => schema.required(errors.required),
      }),
      state: yup.string().when('type', {
        is: () => formikInitialValues.type !== ServiceType.STORAGE,
        then: (schema) => schema.required(errors.required),
      }),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <BaseCityForm field="origin" cityType={CityType.ORIGIN} />
      {formik.values.type !== ServiceType.STORAGE && (
        <BaseCityForm field="destination" cityType={CityType.DESTINATION} />
      )}
    </FormikProvider>
  );
};

export default forwardRef(DestinyOriginForm);
