import React, { useState } from 'react';

import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useInspections, useQueryParams } from '@hooks';

import InspectionModal from '@components/InspectionModal';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import { CustomTableDataCell } from '../styles';
import Filters from './Filters';
import InspectionStatusLabel from './InspectionStatusLabel';

const InspectionsList: React.FC = () => {
  const { queryParams, setQueryParams, onSearch, changePage } =
    useQueryParams<IInspectionQueryParams>({
      page: 1,
    });

  const [selectedInspection, setSelectedInspection] = useState<IInspection>();
  const [isInspectionModalOpen, setIsInspectionModalOpen] = useState(false);

  const { listInspections } = useInspections();
  const { scope } = useScope();
  const { addToast } = useToast();

  const {
    data: inspections,
    refetch,
    isLoading,
  } = useQuery(
    ['inspections', queryParams, scope],
    () => listInspections(queryParams),
    {
      onError: () => {
        addToast('Não foi possível carregar a lista de vistorias', 'error');
      },
    }
  );

  return (
    <>
      <Filters
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onSearch={onSearch}
      />
      <Table
        data={inspections?.results || []}
        isLoading={isLoading}
        headerData={[
          'Status',
          'Cliente',
          'Veículo',
          'Origem',
          'Destino',
          'Vistoriador',
          'Código',
        ]}
        emptyMessage="Nenhum serviço encontrado"
        renderRow={(inspection) => (
          <B2TableRow
            key={inspection.id}
            onClick={() => {
              setSelectedInspection(inspection);
              setIsInspectionModalOpen(true);
            }}
          >
            <TableDataCell>
              <InspectionStatusLabel status={inspection.status} />
            </TableDataCell>
            <TableDataCell>{inspection.service.client.name}</TableDataCell>
            <TableDataCell>
              {inspection.service.vehicle.model} -{' '}
              {inspection.service.vehicle.identifier}
            </TableDataCell>
            <TableDataCell>
              {inspection.service.origin.name}/{inspection.service.origin.state}
            </TableDataCell>
            <TableDataCell>
              {inspection.service.destination
                ? `${inspection.service.destination.name}/${inspection.service.destination.state}`
                : '-'}
            </TableDataCell>
            <TableDataCell>{inspection.inspector?.name || '-'}</TableDataCell>
            <CustomTableDataCell onClick={(event) => event.stopPropagation()}>
              {inspection.code}
            </CustomTableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={inspections?.count}
        changePage={changePage}
      />
      {selectedInspection && (
        <InspectionModal
          isOpen={isInspectionModalOpen}
          onClose={() => setIsInspectionModalOpen(false)}
          service={selectedInspection.service}
          onCancelInspectionSuccess={refetch}
        />
      )}
    </>
  );
};

export default InspectionsList;
