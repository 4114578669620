import React from 'react';

import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { DepartureStatus } from 'utils/enums';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useDepartures, useNavigateWithScope } from '@hooks';

import DetailSection from '@components/DetailSection';
import DocumentsSection from '@components/DocumentsSection';
import Loading from '@components/Loading';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import Comments from './Comments';
import DriverCard from './DriverCard';
import Header from './Header';
import LogCard from './LogCard';
import ServiceList from './ServiceList';
import { getDepartureDetailedInfo } from './helpers/departureDetailedInfo';
import { getVehicleDocuments } from './helpers/vehicleDocuments';
import getRoutes from './routes';
import { GridGroup, Container, CustomCard, Group } from './styles';

const DetailDeparture: React.FC = () => {
  const { departureId } = useParams<{ departureId: string }>();

  const { getDeparture, cancelDeparture } = useDepartures();
  const { hasAdminPermission } = useAuth();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { navigateWithScope } = useNavigateWithScope();

  const {
    data: departure,
    refetch: refetchDeparture,
    isLoading: isDepartureLoading,
  } = useQuery(['departure', departureId], () => getDeparture(departureId!), {
    onError: () => {
      addToast('Não foi possível carregar os dados do embarque', 'error');
      navigate(RoutesPath.private.departures.listDepartures.path, {
        replace: true,
      });
    },
  });

  const { mutate: handleCancelDeparture, isLoading: isCancelDepartureLoading } =
    useMutation((password: string) => cancelDeparture(departureId!, password), {
      onSuccess: () => {
        addToast('Embarque cancelado com sucesso', 'success');
        refetchDeparture();
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível cancelar o embarque', 'error');
        throwToastApiErrors(error, addToast);
      },
    });

  const isDataLoading = isDepartureLoading || !departure;

  const statusToCancel = departure?.status === DepartureStatus.WAITING_START;

  const showCancelButton = hasAdminPermission && statusToCancel;

  const handleCancelDepartureClick = (password?: string) => {
    if (!password) {
      return;
    }
    handleCancelDeparture(password);
  };

  const editButtonProps = {
    onClick: () =>
      navigateWithScope({
        routePath: RoutesPath.private.departures.editDeparture.path.replace(
          ':departureId',
          departureId!
        ),
        company: departure?.company,
      }),
  };

  const deleteButtonProps = {
    button: {
      text: 'Cancelar',
    },
    modal: {
      title: 'Deseja realmente cancelar o embarque?',
      confirmText: 'Sim, desejo cancelar',
      onConfirm: handleCancelDepartureClick,
      cancelText: 'Não, manter',
      isLoading: isCancelDepartureLoading,
      passwordRequired: true,
    },
  };

  return (
    <>
      <PageHeader
        title="Detalhes do embarque"
        routes={getRoutes(departureId!)}
        editButtonProps={editButtonProps}
        deleteButtonProps={showCancelButton ? deleteButtonProps : undefined}
      />
      {isDataLoading ? (
        <Loading />
      ) : (
        <Container>
          <Header departure={departure} refetchDeparture={refetchDeparture} />
          <GridGroup>
            <Group>
              <DriverCard driver={departure.driver} />
              <DocumentsSection data={getVehicleDocuments(departure.truck)} />
            </Group>
            <CustomCard>
              <DetailSection data={getDepartureDetailedInfo(departure)} />
            </CustomCard>
          </GridGroup>
          <ServiceList />
          <Comments />
          <LogCard departureId={departure.id} />
        </Container>
      )}
    </>
  );
};

export default DetailDeparture;
