import styled from 'styled-components';

export const TableContainer = styled.div`
  display: flex;
`;

export const TableLogo = styled.img`
  height: ${({ theme }) => theme.spacing.twelve};
  padding: ${({ theme }) => theme.spacing.one};
  border-radius: 4px;
`;
