import React, { useRef } from 'react';

import { B2Button } from 'react-b2components';

import BaseModal from '@components/BaseModal';
import Loading from '@components/Loading';

import UpdatePasswordForm from '@pages/Profile/UpdatePassword/UpdatePasswordForm';

import { ButtonContainer, Container } from './styles';

interface ChangePasswordModalProps {
  isOpenModal: boolean;
  onClose: () => void;
  requestChangePassword: (data: IUpdatePasswordFormValues) => void;
  isLoading: boolean;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  requestChangePassword,
  isOpenModal,
  onClose,
  isLoading,
}) => {
  const formRef = useRef<IFormStepRef>(null);

  return (
    <BaseModal isOpen={isOpenModal} onClose={onClose} title="Alterar senha">
      <Container>
        {isOpenModal && (
          <UpdatePasswordForm ref={formRef} onFinish={requestChangePassword} />
        )}
        <ButtonContainer>
          {isLoading ? (
            <Loading />
          ) : (
            <B2Button
              onClick={() => formRef.current?.submit()}
              disabled={isLoading}
            >
              Alterar senha
            </B2Button>
          )}
        </ButtonContainer>
      </Container>
    </BaseModal>
  );
};

export default ChangePasswordModal;
