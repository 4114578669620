import React, { useCallback, useEffect, useState } from 'react';

import FormGroup from 'components/FormGroup';
import Label from 'components/Label';
import { useInfiniteQuery } from 'react-query';
import { getNextPageParam } from 'utils/helpers';

import { useScope } from '@contexts/Scope';

import EmptyList from '@components/EmptyList';
import FormError from '@components/FormError';
import Input from '@components/Input';

import ItemText from './ItemText';
import {
  Content,
  CustomLoading,
  CustomSearchFilter,
  ItemContainer,
  SelectedItemsContainer,
} from './styles';

const BasePickItem = <
  ItemType extends GenericItem,
  FetchQueryParams extends IQueryParams
>({
  type,
  fetch,
  queryKey,
  queryParams,
  inputLabel,
  selectedItemLabel,
  shouldRenderSelectedItem,
  formikErrorName,
  selectedItems,
  additionalItem,
  onSelect,
  renderItemText,
  itemRoutePath,
  onSuccess,
  disabled = false,
  refetchOnWindowFocus = false,
}: BasePickItemProps<ItemType, FetchQueryParams>) => {
  const [search, setSearch] = useState('');

  const { scope } = useScope();

  const fetchList = useInfiniteQuery(
    [queryKey, search, queryParams, scope],
    ({ pageParam: page = 1 }) => fetch({ page, search, ...queryParams }),
    {
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
      },
      getNextPageParam,
      refetchOnWindowFocus,
    }
  );

  const isLoading = fetchList.isLoading || fetchList.isFetchingNextPage;

  const itemList = fetchList.data?.pages?.flatMap((page) => page.results) || [];

  const isEmptyList = itemList.length === 0;

  const onScroll = useCallback(
    (event: any) => {
      const element = event.target;
      if (
        !isLoading &&
        Math.ceil(element.offsetHeight + element.scrollTop) >=
          element.scrollHeight
      ) {
        fetchList.fetchNextPage();
      }
    },
    [fetchList, isLoading]
  );

  const isChecked = (item: ItemType) =>
    selectedItems.some((selectedItem) => selectedItem.id === item.id);

  useEffect(() => {
    setSearch('');
  }, [scope]);

  return (
    <FormGroup>
      <Label>{inputLabel}</Label>
      <CustomSearchFilter onSearch={setSearch} disabled={disabled} />
      <Content onScroll={onScroll}>
        {!isLoading && isEmptyList && (
          <EmptyList>Nenhum resultado encontrado</EmptyList>
        )}
        {additionalItem && !search && !isLoading && (
          <ItemContainer
            disabled={disabled}
            checked={isChecked(additionalItem)}
            onClick={() => !disabled && onSelect(additionalItem)}
          >
            <Input
              readOnly
              type={type}
              disabled={disabled}
              checked={isChecked(additionalItem)}
            />
            <ItemText
              text={renderItemText(additionalItem)}
              itemRoutePath={itemRoutePath?.(additionalItem)}
            />
          </ItemContainer>
        )}
        {itemList.map((item) => (
          <ItemContainer
            key={item.id}
            disabled={disabled}
            checked={isChecked(item)}
            onClick={() => !disabled && onSelect(item)}
          >
            <Input
              readOnly
              type={type}
              disabled={disabled}
              checked={isChecked(item)}
            />
            <ItemText
              text={renderItemText(item)}
              itemRoutePath={itemRoutePath?.(item)}
            />
          </ItemContainer>
        ))}
        {isLoading && <CustomLoading />}
      </Content>
      {shouldRenderSelectedItem && (
        <SelectedItemsContainer>
          <Label>{selectedItemLabel}</Label>
          {selectedItems.map((selectedItem) => (
            <ItemContainer
              key={selectedItem.id}
              checked
              disabled={!!disabled}
              onClick={() => !disabled && onSelect(selectedItem)}
            >
              <Input readOnly type={type} checked />
              <ItemText
                text={renderItemText(selectedItem)}
                itemRoutePath={itemRoutePath?.(selectedItem)}
              />
            </ItemContainer>
          ))}
        </SelectedItemsContainer>
      )}
      {formikErrorName && <FormError name={formikErrorName} />}
    </FormGroup>
  );
};

export default BasePickItem;
