export const getDriverDocuments = (driver: IDriver): IDocumentsSection => {
  const documentsSection: IDocumentsSection = {
    title: 'Documentos',
    documents: [],
  };

  if (driver.drivers_license_file) {
    documentsSection.documents.push({
      name: 'CNH',
      url: driver.drivers_license_file.url,
    });
  }

  if (driver.truck.antt_file) {
    documentsSection.documents.push({
      name: 'Documento ANTT',
      url: driver.truck.antt_file.url,
    });
  }

  if (driver.truck.truck_crlv_file) {
    documentsSection.documents.push({
      name: 'CRLV do caminhão',
      url: driver.truck.truck_crlv_file?.url,
    });
  }

  if (driver.truck.trailer_crlv_file) {
    documentsSection.documents.push({
      name: 'CRLV da carreta',
      url: driver.truck.trailer_crlv_file.url,
    });
  }

  return documentsSection;
};
