import React from 'react';

import { cpf, phone } from 'b2utils';
import { B2Card } from 'react-b2components';
import { UserType } from 'utils/enums';

import { useAuth } from '@contexts/Auth';

import { InactivateSwitch } from '@components/InactivateSwitch';
import SectionHeader from '@components/SectionHeader';

import {
  AnchorContainer,
  Content,
  DefaultIcon,
  Description,
  FieldName,
  IconContainer,
  Session,
  SwitchContainer,
  UserImage,
  UserImageContainer,
} from './styles';

interface UserDetailProps {
  user: IUser;
  handleChangeUserSituation: () => void;
}

const UserDetail: React.FC<UserDetailProps> = ({
  user,
  handleChangeUserSituation,
}) => {
  const { userInfo } = useAuth();

  const isMe = userInfo?.id === user.id;
  const isCompanyUser = user.type === UserType.COMPANY;

  return (
    <B2Card>
      <SwitchContainer>
        {!isMe && (
          <InactivateSwitch
            isActive={user.is_active}
            onChange={handleChangeUserSituation}
          />
        )}
      </SwitchContainer>
      <SectionHeader title="Informações do usuário" />
      <UserImageContainer>
        {user.avatar ? (
          <AnchorContainer
            href={user.avatar.image_high_url}
            target="_blank"
            rel="noreferrer"
          >
            <UserImage src={user.avatar.image_low_url} alt="foto do usuário" />
          </AnchorContainer>
        ) : (
          <IconContainer>
            <DefaultIcon variant="Bold" />
          </IconContainer>
        )}
      </UserImageContainer>
      <Session>
        <Content>
          <FieldName>Nome:</FieldName>
          <Description>{user.name}</Description>
        </Content>
        <Content>
          <FieldName>Email:</FieldName>
          <Description>{user.email}</Description>
        </Content>
        {isCompanyUser && (
          <>
            <Content>
              <FieldName>CPF:</FieldName>
              <Description>{cpf.mask(user.document)}</Description>
            </Content>
            <Content>
              <FieldName>Celular:</FieldName>
              <Description>
                {user.phone
                  ? phone.fromObject({
                      areaCode: user.phone.area_code,
                      number: user.phone.number,
                    })
                  : 'Não informado'}
              </Description>
            </Content>
          </>
        )}
        <Content>
          <FieldName>Função:</FieldName>
          <Description>{user.role}</Description>
        </Content>
      </Session>
    </B2Card>
  );
};

export default UserDetail;
