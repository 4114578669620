import React, { useMemo, useState } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ServiceType } from 'utils/enums';

import { useToast } from '@contexts/Toast';
import { useServices, useVerifyScope } from '@hooks';

import { BaseFormWrapper } from '@components/Base/PrivateBase/styles';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import OwnService from '../FormSteps/OwnService';
import ServiceTypeForm from '../FormSteps/ServiceTypeForm';
import StorageService from '../FormSteps/StorageService';
import ThirdPartyService from '../FormSteps/ThirdPartyService';
import routes from './routes';

const CreateService: React.FC = () => {
  const [serviceType, setServiceType] = useState<ServiceType>();
  const [selectedServiceType, setSelectedServiceType] = useState<ServiceType>();

  const { createService } = useServices();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const createServiceRequest = useMutation(
    (service: IServiceFormValues) => createService(service),
    {
      onSuccess: () => {
        addToast('Serviço cadastrado com sucesso', 'success');
        navigate(RoutesPath.private.services.listServices.path, {
          replace: true,
        });
      },
      onError: () => {
        addToast('Erro ao cadastrar serviço', 'error');
      },
    }
  );

  useVerifyScope({
    routePath: RoutesPath.private.services.listServices.path,
    toastErrorMessage: 'Selecione uma empresa para cadastrar um serviço',
  });

  const handleGoBackOnFirstStep = () => setSelectedServiceType(undefined);

  const serviceBaseForm = useMemo(
    () => ({
      [ServiceType.OWN]: (
        <OwnService
          onFinish={createServiceRequest}
          onGoBackOnFirstStep={handleGoBackOnFirstStep}
        />
      ),
      [ServiceType.STORAGE]: (
        <StorageService
          onFinish={createServiceRequest}
          onGoBackOnFirstStep={handleGoBackOnFirstStep}
        />
      ),
      [ServiceType.THIRD_PARTY]: (
        <ThirdPartyService
          onFinish={createServiceRequest}
          onGoBackOnFirstStep={handleGoBackOnFirstStep}
        />
      ),
    }),
    [createServiceRequest]
  );

  return (
    <>
      <PageHeader title="Cadastrar serviço" routes={routes} />
      <BaseFormWrapper visible={!!selectedServiceType}>
        {serviceBaseForm[serviceType!]}
      </BaseFormWrapper>
      {!selectedServiceType && (
        <ServiceTypeForm
          selectedServiceType={selectedServiceType}
          setSelectedServiceType={(type) => {
            setServiceType(type);
            setSelectedServiceType(type);
          }}
        />
      )}
    </>
  );
};

export default CreateService;
