import React from 'react';

import AuthBase from 'components/Base/AuthBase';
import { Description, Title } from 'components/Base/AuthBase/styles';

import LoginForm from './LoginForm';
import { Content } from './styles';

const Login: React.FC = () => {
  return (
    <AuthBase>
      <Content>
        <Title>Seja bem-vindo!</Title>
        <Description>
          Digite suas credenciais para entrar no sistema
        </Description>
        <LoginForm />
      </Content>
    </AuthBase>
  );
};

export default Login;
