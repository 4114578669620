import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import axios, { AxiosResponse, HttpStatusCode } from 'axios';
import { useErrorBoundary } from 'react-error-boundary';

import { useToast } from '@contexts/Toast';

import { endpoints } from './settings';

interface IApiContextValues {
  request: <Type>(config: IRequestConfig) => Promise<AxiosResponse<Type>>;
  requestBrasilApi: <Type>(
    config: IRequestConfig
  ) => Promise<AxiosResponse<Type>>;
  setAuthorizationHeader: (accessToken?: string) => void;
  handleUnauthorizedRequest: (onUnauthorized: () => void) => void;
}

const ApiContext = createContext<IApiContextValues>({} as IApiContextValues);

interface IApiProviderProps {
  children: React.ReactNode;
}

const ApiProvider: React.FC<IApiProviderProps> = ({ children }) => {
  const { showBoundary } = useErrorBoundary<IBoundaryError>();
  const { addToast } = useToast();

  const apiInstance = useMemo(
    () =>
      axios.create({
        baseURL: import.meta.env.VITE_API_BASE_URL,
        headers: {
          Accept: `application/json; version=${
            import.meta.env.VITE_API_VERSION
          }`,
          'Accept-language': 'pt-BR',
        },
      }),
    []
  );

  const brasilApiInstance = useMemo(
    () =>
      axios.create({
        baseURL: import.meta.env.VITE_BRASIL_API_URL,
      }),
    []
  );

  const setAuthorizationHeader = useCallback(
    (accessToken: string) => {
      apiInstance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${accessToken}`;
    },
    [apiInstance.defaults.headers.common]
  );

  const clearAuthorizationHeader = useCallback(() => {
    delete apiInstance.defaults.headers.common['Authorization'];
  }, [apiInstance.defaults.headers.common]);

  const handleChangeAuthorizationHeader = useCallback(
    (accessToken?: string) => {
      if (accessToken) {
        setAuthorizationHeader(accessToken);
      } else {
        clearAuthorizationHeader();
      }
    },
    [clearAuthorizationHeader, setAuthorizationHeader]
  );

  const handleUnauthorized = useCallback(
    (onUnauthorized: () => void) => {
      apiInstance?.interceptors.response.use(
        (config) => config,
        (responseError) => {
          const isTryingLogin =
            responseError.config?.url === endpoints.auth.login;

          if (
            !isTryingLogin &&
            responseError?.response?.status === HttpStatusCode.Unauthorized
          ) {
            addToast('Sua sessão expirou. Por favor logue novamente.', 'info');
            onUnauthorized();
            clearAuthorizationHeader();
          }
          return Promise.reject(responseError);
        }
      );
    },
    [addToast, apiInstance?.interceptors.response, clearAuthorizationHeader]
  );

  const handleBadGateway = useCallback(() => {
    return apiInstance?.interceptors.response.use(
      (config) => config,
      (responseError) => {
        if (responseError?.response?.status === HttpStatusCode.BadGateway) {
          showBoundary({ serverError: true });
        }
        return Promise.reject(responseError);
      }
    );
  }, [apiInstance?.interceptors.response, showBoundary]);

  useEffect(() => {
    handleBadGateway();
  }, [handleBadGateway]);

  return (
    <ApiContext.Provider
      value={{
        request: apiInstance.request,
        requestBrasilApi: brasilApiInstance.request,
        setAuthorizationHeader: handleChangeAuthorizationHeader,
        handleUnauthorizedRequest: handleUnauthorized,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

const useApi = () => {
  const context = useContext(ApiContext);
  return context;
};

export { ApiProvider, useApi };
