import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { UseMutationResult } from 'react-query';
import * as yup from 'yup';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';
import CommonCard from '@components/CommonCard';
import DeleteModal from '@components/DeleteModal';
import Loading from '@components/Loading';
import TimeLineCard from '@components/TimeLineCard';

import { errors } from '@utils';

import Comment from './Comment';
import CommentForm from './CommentForm';
import CreateComment from './CreateComment';
import { Content, FormContainer } from './styles';

interface CommentsListProps {
  isLoading: boolean;
  comments: Array<IComment>;
  onCreate: UseMutationResult<
    IComment,
    AxiosError<unknown, any>,
    ICommentFormValues,
    unknown
  >;
  onUpdate: UseMutationResult<
    IComment,
    AxiosError<unknown, any>,
    IUpdateCommentFormValues,
    unknown
  >;
  onDelete: UseMutationResult<
    void,
    AxiosError<unknown, any>,
    IDeleteComment,
    unknown
  >;
}

const CommentsList: React.ForwardRefRenderFunction<
  CommentsListRef,
  CommentsListProps
> = ({ isLoading, comments, onCreate, onUpdate, onDelete }, ref) => {
  const createCommentRef = useRef<CreateCommentRef>(null);

  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState<number>();

  const formikInitialValues: IUpdateCommentFormValues = {
    id: 0,
    text: '',
  };

  const formikValidationSchema = yup.object().shape({
    text: yup.string().trim().required(errors.required),
  });

  const formik = useFormik<IUpdateCommentFormValues>({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: (values) => onUpdate.mutate(values),
  });

  const handleCloseFormModal = () => {
    formik.resetForm();
    setIsFormModalOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedCommentId(undefined);
  };

  useImperativeHandle(ref, () => ({
    onCreateSuccess: () => createCommentRef.current?.onCreateSuccess(),
    onUpdateSuccess: handleCloseFormModal,
    onDeleteSuccess: handleCloseDeleteModal,
  }));

  return (
    <>
      <CommonCard title="Deixe seu comentário">
        {isLoading ? (
          <Loading />
        ) : (
          <Content>
            <CreateComment ref={createCommentRef} onCreate={onCreate} />
            <TimeLineCard
              items={comments}
              emptyMessage="Nenhum comentário adicionado"
              renderItem={(comment) => (
                <Comment
                  comment={comment}
                  onUpdateClick={(comment) => {
                    setIsFormModalOpen(true);
                    formik.setFieldValue('id', comment.id);
                    formik.setFieldValue('text', comment.text);
                  }}
                  onDeleteClick={(commentId) => {
                    setIsDeleteModalOpen(true);
                    setSelectedCommentId(commentId);
                  }}
                />
              )}
            />
          </Content>
        )}
      </CommonCard>
      <CustomBaseModal
        isOpen={isFormModalOpen}
        onClose={handleCloseFormModal}
        title="Editar comentário"
      >
        <FormikProvider value={formik}>
          <FormContainer>
            <CommentForm
              id="updateComment"
              isEditing
              isLoading={onUpdate.isLoading}
            />
          </FormContainer>
        </FormikProvider>
      </CustomBaseModal>
      {selectedCommentId && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          title="Tem certeza que deseja deletar este comentário?"
          onConfirm={(password) =>
            password && onDelete.mutate({ id: selectedCommentId, password })
          }
          passwordRequired
          isLoading={onDelete.isLoading}
        />
      )}
    </>
  );
};

export default forwardRef(CommentsList);
