import React from 'react';

import { Content, Description, FieldName } from '../styles';

interface DetailInfoProps {
  label: string;
  description: string;
  hasHighlight?: boolean;
}

const DetailInfo: React.FC<DetailInfoProps> = ({
  description,
  label,
  hasHighlight,
}) => {
  return (
    <Content>
      <FieldName>{label}</FieldName>
      <Description hasHighlight={hasHighlight}>{description}</Description>
    </Content>
  );
};

export default DetailInfo;
