import React from 'react';

import { ServiceStatus } from 'utils/enums';

import { ProgressContent, ProgressValue } from './styles';

interface ServiceProgressProps {
  currentServiceStatus: ServiceStatus;
}

const ServiceProgress: React.FC<ServiceProgressProps> = ({
  currentServiceStatus,
}) => {
  const serviceStatuses = Object.values(ServiceStatus);
  const currentServiceStatusIndex =
    serviceStatuses.indexOf(currentServiceStatus);

  return (
    <ProgressContent>
      {serviceStatuses.map(
        (status, index) =>
          index <= currentServiceStatusIndex && (
            <ProgressValue
              key={status}
              isCurrentValue={index === currentServiceStatusIndex}
            >
              <span>{currentServiceStatusIndex >= index ? status : ''}</span>
              <span>{currentServiceStatusIndex > index ? ' > ' : ''}</span>
            </ProgressValue>
          )
      )}
    </ProgressContent>
  );
};

export default ServiceProgress;
