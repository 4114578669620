import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

import { headerHeight } from '../styles';

export const Container = styled.div`
  height: ${headerHeight}px;
  width: 100%;
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.eight)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  background-color: ${({ theme }) => theme.colors.white.main};
  position: sticky;
  z-index: 3;
`;

export const Logo = styled.img`
  height: 30px;

  @media (max-width: 725px) {
    display: none;
  }
`;

export const RightContainer = styled.div`
  display: flex;
`;
