import React from 'react';

import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useManagementUsers, useQueryParams } from '@hooks';

import ListFilters from '@components/ListFilters';
import PageHeader from '@components/PageHeader';
import SituationBadge from '@components/SituationBadge';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import RoutesPath from '@router/routes';

const ListManagementUsers: React.FC = () => {
  const { changePage, onSearch, queryParams, changeIsActiveStatus } =
    useQueryParams({ page: 1 });
  const { listManagementUsers } = useManagementUsers();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const { isLoading, data: managementUsers } = useQuery(
    ['managementUsers', queryParams],
    () => listManagementUsers(queryParams),
    {
      onError() {
        addToast('Não foi possível carregar os usuários', 'error');
      },
    }
  );

  const navigateToCreating = () =>
    navigate(RoutesPath.private.managementUsers.createManagementUser.path);

  const navigateToDetail = (managementUserId: number) =>
    navigate(
      RoutesPath.private.managementUsers.detailManagementUser.path.replace(
        ':managementUserId',
        managementUserId.toString()
      )
    );

  return (
    <>
      <PageHeader
        title="Usuários internos"
        subtitle="Listagem dos usuários internos no sistema"
        buttonProps={{
          text: 'Adicionar usuário',
          onClick: navigateToCreating,
        }}
      />
      <ListFilters
        onSearch={onSearch}
        changeIsActiveStatus={{ queryParams, changeIsActiveStatus }}
      />
      <Table
        data={managementUsers?.results || []}
        headerData={['Nome', 'Email', 'Função', 'Situação']}
        emptyMessage="Nenhum usuário encontrado"
        isLoading={isLoading}
        renderRow={(managementUser) => (
          <B2TableRow
            key={managementUser.id}
            onClick={() => navigateToDetail(managementUser.id)}
          >
            <TableDataCell>{managementUser.name}</TableDataCell>
            <TableDataCell>{managementUser.email}</TableDataCell>
            <TableDataCell>{managementUser.role}</TableDataCell>
            <TableDataCell>
              <SituationBadge isActive={managementUser.is_active} />
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={managementUsers?.count || 0}
        changePage={changePage}
      />
    </>
  );
};

export default ListManagementUsers;
