import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import { currency } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { ExpenseType, ExpenseTypeMap } from 'utils/enums';
import * as yup from 'yup';

import { useExpenseCategories, useProviders } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import SectionHeader from '@components/SectionHeader';
import Select from '@components/Select';
import TextArea from '@components/TextArea';

import { CustomChooseItemFilter } from '@pages/Services/FormSteps/styles';
import { errors } from '@utils';

interface ExpenseFormProps {
  initialValues?: IExpenseFormValues;
  onFinish: (data: IExpenseFormValues) => void;
}

const ExpenseInfoForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  ExpenseFormProps
> = ({ onFinish, initialValues }, ref) => {
  const { listProviders } = useProviders();
  const { listExpenseCategories } = useExpenseCategories();

  const expenseTypes = Object.values(ExpenseType);

  const formikInitialValues: IExpenseFormValues = {
    value: '',
    date: '',
    provider: null,
    category: null,
    type: '',
    description: '',
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    value: yup.string().trim().required(errors.required),
    date: yup.string().trim().required(errors.required),
    provider: yup
      .object()
      .nullable()
      .notOneOf([null], errors.required)
      .required(errors.required),
    type: yup.string().trim().required(errors.required),
    description: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
    resetForm: formik.resetForm,
  }));

  const handleSelectProvider = useCallback(
    (provider: IProvider) => {
      formik.setFieldTouched('provider');
      formik.setFieldValue('provider', provider);
    },
    [formik]
  );

  const handleSelectCategory = useCallback(
    (category: IExpenseCategory) => {
      formik.setFieldTouched('category');
      formik.setFieldValue('category', category);
    },
    [formik]
  );

  return (
    <FormikProvider value={formik}>
      <SectionHeader title="Informações da despesa" />
      <FormRow>
        <FormGroup>
          <Label htmlFor="value">Valor *</Label>
          <Input
            type="text"
            id="value"
            name="value"
            placeholder="R$ 0,00"
            value={formik.values.value}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'value',
                currency.brlMask(event.target.value)
              )
            }
            invalidValue={!!formik.touched.value && !!formik.errors.value}
          />
          <FormError name="value" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="date">Data *</Label>
          <Input
            id="date"
            name="date"
            type="date"
            value={formik.values.date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalidValue={!!formik.touched.date && !!formik.errors.date}
          />
          <FormError name="date" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Fornecedor *</Label>
          <CustomChooseItemFilter
            filterValue={
              formik.values.provider?.name || 'Selecionar fornecedor'
            }
            modalTitle="Selecionar fornecedor"
            fetch={listProviders}
            queryParams={{ isActive: 'true' }}
            queryKey="providers"
            inputLabel="Selecionar fornecedor *"
            selectedItemLabel="Fornecedor selecionado"
            shouldRenderSelectedItem={!!formik.values.provider}
            onSelect={(provider) => handleSelectProvider(provider)}
            selectedItem={formik.values.provider || undefined}
            renderItemText={(provider) => provider?.name}
            invalidValue={!!formik.touched.provider && !!formik.errors.provider}
          />
          <FormError name="provider" />
        </FormGroup>
        <FormGroup>
          <Label>Categoria *</Label>
          <CustomChooseItemFilter
            filterValue={formik.values.category?.name || 'Selecionar categoria'}
            modalTitle="Selecionar categoria"
            fetch={listExpenseCategories}
            queryParams={{ isActive: 'true' }}
            queryKey="incomeCategories"
            inputLabel="Selecionar categoria *"
            selectedItemLabel="Categoria selecionada"
            shouldRenderSelectedItem={!!formik.values.category}
            onSelect={(category) => handleSelectCategory(category)}
            selectedItem={formik.values.category || undefined}
            renderItemText={(category) => category.name}
            invalidValue={!!formik.touched.category && !!formik.errors.category}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="type">Tipo *</Label>
          <Select
            id="type"
            name="type"
            value={formik.values.type}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.errors.type && !!formik.touched.type}
          >
            <option value="">Selecione um tipo</option>
            {expenseTypes.map((type) => (
              <option key={type} value={type}>
                {ExpenseTypeMap[type]}
              </option>
            ))}
          </Select>
          <FormError name="type" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="description">Descrição *</Label>
          <TextArea
            id="description"
            name="description"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            invalidValue={
              !!formik.touched.description && !!formik.errors.description
            }
          />
          <FormError name="description" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(ExpenseInfoForm);
