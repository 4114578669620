import styled from 'styled-components';

import { InactivateSwitch } from '@components/InactivateSwitch';

export const MainContent = styled.div`
  display: none;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const HeaderFirstLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  gap: ${({ theme }) => theme.spacing.three};
`;

export const Name = styled.h2`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const CustomInactivateSwitch = styled(InactivateSwitch)`
  margin-left: auto;
`;

export const ContactGroup = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.three};
`;
