import { useCallback } from 'react';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const useInspections = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const listInspections = useCallback(
    async (params: IInspectionQueryParams) => {
      const response = await request<IPaginated<IInspection>>({
        method: 'get',
        url: endpoints.inspections.list,
        headers: { scope },
        params: {
          page: params.page,
          search: params.search || undefined,
          service: params.service || undefined,
          inspector: params.inspector?.id || undefined,
          status: params.status || undefined,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const listInspectionImages = useCallback(
    async (params: IVehicleImageParams) => {
      const response = await request<IPaginated<IInspectionImage>>({
        method: 'get',
        url: endpoints.inspections.images,
        headers: { scope },
        params: {
          page: params.page,
          inspection: params.inspection,
        },
      });

      return response.data;
    },
    [request, scope]
  );

  const listVehicleViews = useCallback(async () => {
    const response = await request<Array<IVehicleView>>({
      method: 'get',
      url: endpoints.inspections.views,
      headers: {
        scope,
      },
      params: {
        paginate: false,
      },
    });

    return response.data;
  }, [request, scope]);

  const cancelInspection = useCallback(
    async (inspectionCode: string, password: string) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.inspections.cancel.replace(
          ':inspectionCode',
          inspectionCode
        ),
        headers: { scope },
        data: { password },
      });
      return response.data;
    },
    [request, scope]
  );

  return {
    listInspections,
    listInspectionImages,
    listVehicleViews,
    cancelInspection,
  };
};

export default useInspections;
