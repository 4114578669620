import React from 'react';

import PublicBase from '@components/Base/PublicBase';
import BaseConfirmEmail from '@components/BaseConfirmEmail';

const PublicConfirmEmail: React.FC = () => {
  return (
    <PublicBase>
      <BaseConfirmEmail />
    </PublicBase>
  );
};

export default PublicConfirmEmail;
