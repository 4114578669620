import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { useAuthentication } from 'hooks';
import { useMutation } from 'react-query';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';

import { BaseContainer } from '@components/Base/PrivateBase/styles';
import DeleteModal from '@components/DeleteModal';
import SectionHeader from '@components/SectionHeader';

import { Body, Content, DeleteButton, Warning } from './styles';

const DeleteAccount = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { deleteAccount } = useAuthentication();
  const { logout } = useAuth();
  const { addToast } = useToast();

  const { mutate: requestDeleteAccount, isLoading } = useMutation(
    deleteAccount,
    {
      onSuccess: () => {
        logout();
      },
      onError: (error: AxiosError) => {
        setIsDeleteModalOpen(false);
        addToast('Não foi possível deletar a sua conta', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  return (
    <BaseContainer>
      <Content>
        <SectionHeader title="Deletar conta" />
        <Body>
          <Warning>
            Todos os seus dados serão excluídos e esta ação é irreversível
          </Warning>
          <DeleteButton
            variant="outline"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            Deletar conta
          </DeleteButton>
          <DeleteModal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onConfirm={(password) => password && requestDeleteAccount(password)}
            passwordRequired
            isLoading={isLoading}
            title="Deletar conta"
            message="Tem certeza que você deseja deletar a sua conta? Esta ação é irreversível."
          />
        </Body>
      </Content>
    </BaseContainer>
  );
};

export default DeleteAccount;
