import React, { useEffect, useRef } from 'react';

import moment from 'moment';
import { ExpenseType, FinancialReportType } from 'utils/enums';
import { toDateInput } from 'utils/helpers';

import { useExpenseCategories, useProviders } from '@hooks';

import ChooseItemFilter from '@components/ChooseItemFilter';
import DateRangeCalendarFilter from '@components/DateRangeCalendarFilter';
import ExpandableFilter from '@components/ExpandableFilter';
import ListFilters from '@components/ListFilters';
import PaymentStatusFilter from '@components/PaymentStatusFilter';
import Select from '@components/Select';

import { Container } from './styles';

interface FiltersProps {
  yearMonth?: string;
  removeProviderFilter?: boolean;
  queryParams: IExpenseQueryParams;
  setQueryParams: React.Dispatch<React.SetStateAction<IExpenseQueryParams>>;
  onSearch: (value: string) => void;
}

const Filters: React.FC<FiltersProps> = ({
  yearMonth,
  removeProviderFilter = false,
  queryParams,
  setQueryParams,
  onSearch,
}) => {
  const dateRangeCalendarFilterRef = useRef<DateRangeCalendarFilterRef>(null);

  const { listProviders } = useProviders();
  const { listExpenseCategories } = useExpenseCategories();

  const emptyProvider = {
    id: 0,
    name: 'Todos',
  } as IProvider;

  const emptyCategory = {
    id: 0,
    name: 'Todas',
  } as IExpenseCategory;

  const expenseTypes = Object.values(ExpenseType);

  const handleDateRangeFilterSelected = (startDate?: Date, endDate?: Date) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      date__gte: startDate ? toDateInput(startDate) : undefined,
      date__lte: endDate ? toDateInput(endDate) : undefined,
    }));
  };

  const handleSelectProvider = (provider: IProvider) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      provider,
    }));
  };

  const handleSelectCategory = (category: IExpenseCategory) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      category,
    }));
  };

  const handleSelectExpenseType = (type: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      type: type ? (type as ExpenseType) : null,
    }));
  };

  useEffect(() => {
    if (yearMonth) {
      setQueryParams((prevState) => ({
        ...prevState,
        page: 1,
        date__gte: moment(yearMonth).startOf('month').format('YYYY-MM-DD'),
        date__lte: moment(yearMonth).endOf('month').format('YYYY-MM-DD'),
      }));

      dateRangeCalendarFilterRef.current?.changeSelectedDateRange(
        moment(yearMonth).startOf('month').toDate(),
        moment(yearMonth).endOf('month').toDate()
      );
    }
  }, [setQueryParams, yearMonth]);

  return (
    <Container>
      <ListFilters
        variant
        onSearch={onSearch}
        searchPlaceholder="Buscar por descrição"
      >
        <DateRangeCalendarFilter
          clearableDateRange
          onDateRangeFilterSelected={handleDateRangeFilterSelected}
          initialDateRange={
            queryParams.date__gte
              ? [
                  moment(queryParams.date__gte).toDate(),
                  moment(queryParams.date__lte).toDate(),
                ]
              : undefined
          }
          ref={dateRangeCalendarFilterRef}
        />
      </ListFilters>
      <ExpandableFilter>
        {!removeProviderFilter && (
          <ChooseItemFilter
            filterValue={
              queryParams?.provider?.id && queryParams?.provider?.id !== 0
                ? queryParams?.provider?.name
                : 'Fornecedor: todos'
            }
            modalTitle="Filtrar despesas por fornecedor"
            fetch={listProviders}
            queryParams={{ isActive: 'true' }}
            queryKey="providers"
            inputLabel="Selecionar fornecedor *"
            selectedItemLabel="Fornecedor selecionado"
            shouldRenderSelectedItem
            onSelect={handleSelectProvider}
            selectedItem={queryParams.provider || emptyProvider}
            additionalItem={emptyProvider}
            renderItemText={(provider) => provider.name}
          />
        )}
        <ChooseItemFilter
          filterValue={
            queryParams?.category?.id && queryParams?.category?.id !== 0
              ? queryParams?.category?.name
              : 'Categoria: todas'
          }
          modalTitle="Filtrar despesas por categoria"
          fetch={listExpenseCategories}
          queryParams={{ isActive: 'true' }}
          queryKey="expenseCategory"
          inputLabel="Selecionar categoria *"
          selectedItemLabel="Categoria selecionada"
          shouldRenderSelectedItem
          onSelect={handleSelectCategory}
          selectedItem={queryParams.category || emptyCategory}
          additionalItem={emptyCategory}
          renderItemText={(category) => category.name}
        />
        <Select
          value={queryParams?.type || ''}
          onChange={(event) =>
            handleSelectExpenseType(event.target.value as ExpenseType)
          }
        >
          <option value="">Tipo da despesa: todas</option>
          {expenseTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Select>
        <PaymentStatusFilter
          type={FinancialReportType.EXPENSE}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      </ExpandableFilter>
    </Container>
  );
};

export default Filters;
