import { Trash } from 'iconsax-react';
import styled from 'styled-components';

export const TrashIcon = styled(Trash)`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};
  color: ${({ theme }) => theme.colors.error};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const PaymentDescription = styled.pre`
  white-space: pre-wrap;
`;
