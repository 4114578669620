import React from 'react';

import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ServiceType } from 'utils/enums';
import { formatDate } from 'utils/formats';

import { useToast } from '@contexts/Toast';
import { useQueryParams, useServices } from '@hooks';

import CommonCard from '@components/CommonCard';
import ServiceFilters from '@components/ServiceFilters';
import ServiceStatusLabel from '@components/ServiceStatusLabel';
import { TableDataCell } from '@components/Table/styles';
import TableVariant from '@components/TableVariant';

import RoutesPath from '@router/routes';

interface ServiceListProps {
  client: IClientUser;
}

const ServiceList: React.FC<ServiceListProps> = ({ client }) => {
  const { queryParams, setQueryParams, onSearch, changePage } =
    useQueryParams<IServicesQueryParams>({ page: 1, client });

  const { listServices } = useServices();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const { data: services, isLoading } = useQuery(
    ['services', queryParams],
    () => listServices(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista de serviços do cliente',
          'error'
        );
      },
    }
  );

  const getDateLabel = (service: IService) => {
    if (service.type === ServiceType.STORAGE) {
      return 'Data de chegada';
    } else {
      return 'Data prevista para coleta';
    }
  };

  const getDateDate = (service: IService) => {
    if (service.type === ServiceType.STORAGE) {
      return formatDate(service?.collection_date || '');
    } else {
      return formatDate(service?.estimated_collection_date || '');
    }
  };

  return (
    <CommonCard title="Lista de serviços">
      <ServiceFilters
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onSearch={onSearch}
        showStatusFilter
        variant
      />
      <TableVariant
        data={services?.results || []}
        isLoading={isLoading}
        headerData={[
          'Nº do serviço',
          'Data',
          'Veículo',
          'Origem',
          'Destino',
          'Tipo',
          'Situação',
        ]}
        emptyMessage="Nenhum serviço encontrado"
        renderRow={(service) => (
          <B2TableRow
            key={service.id}
            onClick={() =>
              navigate(
                RoutesPath.private.services.detailService.path.replace(
                  ':serviceId',
                  service.id.toString()
                )
              )
            }
          >
            <TableDataCell>{service.number}</TableDataCell>
            <TableDataCell>
              <Tooltip id={`service-${service.id}-date-tooltip`} />
              <span
                data-tooltip-id={`service-${service.id}-date-tooltip`}
                data-tooltip-content={getDateLabel(service)}
              >
                {getDateDate(service)}
              </span>
            </TableDataCell>
            <TableDataCell>
              {service.vehicle.model} - {service.vehicle.identifier}
            </TableDataCell>
            <TableDataCell>
              {`${service.origin.name}/${service.origin.state}`}
            </TableDataCell>
            <TableDataCell>
              {service.destination
                ? `${service.destination.name}/${service.destination.state}`
                : '-'}
            </TableDataCell>
            <TableDataCell>{service.type}</TableDataCell>
            <TableDataCell>
              <ServiceStatusLabel status={service.status} />
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={services?.count}
        changePage={changePage}
      />
    </CommonCard>
  );
};

export default ServiceList;
