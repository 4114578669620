import BaseConfirmEmail from '@components/BaseConfirmEmail';
import BaseDeliveryReceiptForm from '@components/BaseDeliveryReceiptForm';

import CreateBankAccount from '@pages/BankAccounts/CreateBankAccount';
import ListBankAccounts from '@pages/BankAccounts/ListBankAccounts';
import UpdateBankAccount from '@pages/BankAccounts/UpdateBankAccount';
import CreateExpenseCategory from '@pages/Categories/CreateExpenseCategory';
import CreateIncomeCategory from '@pages/Categories/CreateIncomeCategory';
import ListCategories from '@pages/Categories/ListCategories';
import UpdateExpenseCategory from '@pages/Categories/UpdateExpenseCategory';
import UpdateIncomeCategory from '@pages/Categories/UpdateIncomeCategory';
import CreateClient from '@pages/Clients/CreateClient';
import DetailClient from '@pages/Clients/DetailClient';
import ListClients from '@pages/Clients/ListClients';
import UpdateClient from '@pages/Clients/UpdateClient';
import CreateCompany from '@pages/Company/CreateCompany';
import DetailCompany from '@pages/Company/DetailCompany';
import ListCompanies from '@pages/Company/ListCompanies';
import UpdateCompany from '@pages/Company/UpdateCompany';
import CreateCompanyUser from '@pages/CompanyUsers/CreateCompanyUser';
import DetailCompanyUser from '@pages/CompanyUsers/DetailCompanyUser';
import ListCompanyUsers from '@pages/CompanyUsers/ListCompanyUsers';
import UpdateCompanyUser from '@pages/CompanyUsers/UpdateCompanyUser';
import Dashboard from '@pages/Dashboard';
import CreateDeparture from '@pages/Departures/CreateDeparture';
import DetailDeparture from '@pages/Departures/DetailDeparture';
import ListDepartures from '@pages/Departures/ListDepartures';
import UpdateDeparture from '@pages/Departures/UpdateDeparture';
import Documents from '@pages/Documents';
import CreateDriver from '@pages/Drivers/CreateDriver';
import DetailDriver from '@pages/Drivers/DetailDriver';
import ListDrivers from '@pages/Drivers/ListDrivers';
import UpdateDriver from '@pages/Drivers/UpdateDriver';
import CreateFinancialTransaction from '@pages/Financial/CreateFinancialTransaction';
import FinancialDashboard from '@pages/Financial/FinancialDashboard';
import UpdateExpense from '@pages/Financial/UpdateExpense';
import UpdateIncome from '@pages/Financial/UpdateIncome';
import UpdateTransfer from '@pages/Financial/UpdateTransfer';
import CreateManagementUser from '@pages/ManagementUsers/CreateManagementUser';
import DetailManagementUser from '@pages/ManagementUsers/DetailManagementUser';
import ListManagementUsers from '@pages/ManagementUsers/ListManagementUser';
import UpdateManagementUser from '@pages/ManagementUsers/UpdateManagementUser';
import PaymentMethods from '@pages/PaymentMethods';
import CreatePaymentMethod from '@pages/PaymentMethods/CreatePaymentMethod';
import CreatePaymentTerm from '@pages/PaymentMethods/CreatePaymentTerm';
import UpdatePaymentMethod from '@pages/PaymentMethods/UpdatePaymentMethod';
import UpdatePaymentTerm from '@pages/PaymentMethods/UpdatePaymentTerm';
import Profile from '@pages/Profile';
import CreateProvider from '@pages/Providers/CreateProvider';
import DetailProvider from '@pages/Providers/DetailProvider';
import ListProviders from '@pages/Providers/ListProviders';
import UpdateProvider from '@pages/Providers/UpdateProvider';
import CreateQuotation from '@pages/Quotations/CreateQuotation';
import ListQuotations from '@pages/Quotations/ListQuotations';
import UpdateQuotation from '@pages/Quotations/UpdateQuotation';
import CreateService from '@pages/Services/CreateService';
import DetailService from '@pages/Services/DetailService';
import ListServices from '@pages/Services/ListServices';
import UpdateLastServiceLocation from '@pages/Services/UpdateLastServiceLocation';
import UpdateService from '@pages/Services/UpdateService';
import RoutesPath from '@router/routes';

export default [
  {
    route: RoutesPath.private.profile.editProfile,
    component: Profile,
  },
  {
    route: RoutesPath.private.profile.manageAddresses,
    component: Profile,
  },
  {
    route: RoutesPath.private.profile.editEmail,
    component: Profile,
  },
  {
    route: RoutesPath.private.profile.editPassword,
    component: Profile,
  },
  {
    route: RoutesPath.private.profile.deleteAccount,
    component: Profile,
  },
  {
    route: RoutesPath.private.confirmEmail,
    component: BaseConfirmEmail,
  },
  {
    route: RoutesPath.private.companies.listCompanies,
    component: ListCompanies,
  },
  {
    route: RoutesPath.private.companies.detailCompany,
    component: DetailCompany,
  },
  {
    route: RoutesPath.private.companies.createCompany,
    component: CreateCompany,
  },
  {
    route: RoutesPath.private.companies.updateCompany,
    component: UpdateCompany,
  },
  {
    route: RoutesPath.private.companyUsers.listCompanyUsers,
    component: ListCompanyUsers,
  },
  {
    route: RoutesPath.private.companyUsers.detailCompanyUser,
    component: DetailCompanyUser,
  },
  {
    route: RoutesPath.private.companyUsers.createCompanyUser,
    component: CreateCompanyUser,
  },
  {
    route: RoutesPath.private.companyUsers.updateCompanyUser,
    component: UpdateCompanyUser,
  },
  {
    route: RoutesPath.private.managementUsers.listManagementUsers,
    component: ListManagementUsers,
  },
  {
    route: RoutesPath.private.managementUsers.detailManagementUser,
    component: DetailManagementUser,
  },
  {
    route: RoutesPath.private.managementUsers.createManagementUser,
    component: CreateManagementUser,
  },
  {
    route: RoutesPath.private.managementUsers.updateManagementUser,
    component: UpdateManagementUser,
  },
  {
    route: RoutesPath.private.clients.listClients,
    component: ListClients,
  },
  {
    route: RoutesPath.private.clients.createClient,
    component: CreateClient,
  },
  {
    route: RoutesPath.private.clients.detailClient,
    component: DetailClient,
  },
  {
    route: RoutesPath.private.clients.editClient,
    component: UpdateClient,
  },
  {
    route: RoutesPath.private.services.listServices,
    component: ListServices,
  },
  {
    route: RoutesPath.private.services.detailService,
    component: DetailService,
  },
  {
    route: RoutesPath.private.services.createService,
    component: CreateService,
  },
  {
    route: RoutesPath.private.services.editService,
    component: UpdateService,
  },
  {
    route: RoutesPath.private.services.editLastServiceLocation,
    component: UpdateLastServiceLocation,
  },
  {
    route: RoutesPath.private.deliveryReceipt,
    component: BaseDeliveryReceiptForm,
  },
  {
    route: RoutesPath.private.providers.listProviders,
    component: ListProviders,
  },
  {
    route: RoutesPath.private.providers.detailProvider,
    component: DetailProvider,
  },
  {
    route: RoutesPath.private.providers.createProvider,
    component: CreateProvider,
  },
  {
    route: RoutesPath.private.providers.editProvider,
    component: UpdateProvider,
  },
  {
    route: RoutesPath.private.drivers.listDrivers,
    component: ListDrivers,
  },
  {
    route: RoutesPath.private.drivers.createDriver,
    component: CreateDriver,
  },
  {
    route: RoutesPath.private.drivers.detailDriver,
    component: DetailDriver,
  },
  {
    route: RoutesPath.private.drivers.editDriver,
    component: UpdateDriver,
  },
  {
    route: RoutesPath.private.departures.createDeparture,
    component: CreateDeparture,
  },
  {
    route: RoutesPath.private.departures.listDepartures,
    component: ListDepartures,
  },
  {
    route: RoutesPath.private.departures.detailDeparture,
    component: DetailDeparture,
  },
  {
    route: RoutesPath.private.departures.editDeparture,
    component: UpdateDeparture,
  },
  {
    route: RoutesPath.private.categories.incomes.listCategories,
    component: ListCategories,
  },
  {
    route: RoutesPath.private.categories.incomes.createCategory,
    component: CreateIncomeCategory,
  },
  {
    route: RoutesPath.private.categories.incomes.editCategory,
    component: UpdateIncomeCategory,
  },
  {
    route: RoutesPath.private.categories.expenses.listCategories,
    component: ListCategories,
  },
  {
    route: RoutesPath.private.categories.expenses.createCategory,
    component: CreateExpenseCategory,
  },
  {
    route: RoutesPath.private.categories.expenses.editCategory,
    component: UpdateExpenseCategory,
  },
  {
    route: RoutesPath.private.financial.incomes.listIncomes,
    component: FinancialDashboard,
  },
  {
    route: RoutesPath.private.financial.expenses.listExpenses,
    component: FinancialDashboard,
  },
  {
    route: RoutesPath.private.financial.transfers.listTransfers,
    component: FinancialDashboard,
  },
  {
    route: RoutesPath.private.financial.transfers.updateTransfer,
    component: UpdateTransfer,
  },
  {
    route:
      RoutesPath.private.financial.financialTransactions
        .createFinancialTransaction,
    component: CreateFinancialTransaction,
  },
  {
    route: RoutesPath.private.financial.incomes.updateIncome,
    component: UpdateIncome,
  },
  {
    route: RoutesPath.private.financial.expenses.updateExpense,
    component: UpdateExpense,
  },
  {
    route: RoutesPath.private.paymentMethods.listPaymentTerms,
    component: PaymentMethods,
  },
  {
    route: RoutesPath.private.paymentMethods.createPaymentTerm,
    component: CreatePaymentTerm,
  },
  {
    route: RoutesPath.private.paymentMethods.updatePaymentTerm,
    component: UpdatePaymentTerm,
  },
  {
    route: RoutesPath.private.paymentMethods.listPaymentMethods,
    component: PaymentMethods,
  },
  {
    route: RoutesPath.private.paymentMethods.createPaymentMethod,
    component: CreatePaymentMethod,
  },
  {
    route: RoutesPath.private.paymentMethods.updatePaymentMethod,
    component: UpdatePaymentMethod,
  },
  {
    route: RoutesPath.private.bankAccounts.listBankAccounts,
    component: ListBankAccounts,
  },
  {
    route: RoutesPath.private.bankAccounts.createBankAccount,
    component: CreateBankAccount,
  },
  {
    route: RoutesPath.private.bankAccounts.updateBankAccount,
    component: UpdateBankAccount,
  },
  {
    route: RoutesPath.private.quotations.listQuotations,
    component: ListQuotations,
  },
  {
    route: RoutesPath.private.quotations.createQuotation,
    component: CreateQuotation,
  },
  {
    route: RoutesPath.private.quotations.updateQuotation,
    component: UpdateQuotation,
  },
  {
    route: RoutesPath.private.documents.listServices,
    component: Documents,
  },
  {
    route: RoutesPath.private.documents.listInspections,
    component: Documents,
  },
  {
    route: RoutesPath.private.documents.listDepartures,
    component: Documents,
  },
  {
    route: RoutesPath.private.documents.listServiceContracts,
    component: Documents,
  },
  {
    route: RoutesPath.private.documents.listTermOfReceipts,
    component: Documents,
  },
  {
    route: RoutesPath.private.documents.listCte,
    component: Documents,
  },
  {
    route: RoutesPath.private.documents.listMDFe,
    component: Documents,
  },
  {
    route: RoutesPath.private.dashboard,
    component: Dashboard,
  },
];
