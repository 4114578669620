import React from 'react';

import { DepartureStatus } from 'utils/enums';

import { Container, Label, Square } from './styles';

interface DepartureStatusLabelProps {
  status: DepartureStatus;
}

const DepartureStatusLabel: React.FC<DepartureStatusLabelProps> = ({
  status,
}) => {
  return (
    <Container>
      <Square status={status} />
      <Label status={status}>{status}</Label>
    </Container>
  );
};

export default DepartureStatusLabel;
