import { ArrowDown2 } from 'iconsax-react';
import styled, { css } from 'styled-components';
import { addSpacing, modifyColor } from 'utils/styles';

interface MenuProps {
  $isOpen: boolean;
}

export const ScopeButton = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.four)};
  margin: ${({ theme }) => addSpacing('0', theme.spacing.three)};
  color: ${({ theme }) => theme.colors.main};
  background-color: ${({ theme }) =>
    modifyColor({ hex: theme.colors.main, lightness: 0.93 })};
  border-radius: ${({ theme }) => theme.spacing.three};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  cursor: pointer;
`;

export const Text = styled.span`
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.main};

  @media (max-width: 836px) {
    max-width: 80px;
  }
`;

export const Icon = styled(ArrowDown2)<MenuProps>`
  width: ${({ theme }) => theme.spacing.five};
  transition: transform 0.3s ease-in-out;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(90deg);
    `}
`;

export const ScopeContainer = styled.div<MenuProps>`
  left: 25%;
  top: 75px;
  position: absolute;
  min-width: 40svw;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) =>
    modifyColor({ hex: theme.colors.main, lightness: 0.93 })};
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-in-out;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      grid-template-rows: 1fr;
    `};
`;

export const Content = styled.div`
  padding: ${({ theme }) => addSpacing('0', theme.spacing.three)};
  transition: padding 0.3s ease-in-out;
  overflow: hidden;

  input {
    margin-top: ${({ theme }) => theme.spacing.three};
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.white.one};
  }
`;

export const Menu = styled.div`
  overflow-y: auto;
  max-height: 300px;
  margin-bottom: ${({ theme }) => theme.spacing.two};
`;

export const Item = styled.div`
  border: 1px solid
    ${({ theme }) => modifyColor({ hex: theme.colors.main, lightness: 0.7 })};
  border-radius: 8px;
  background-color: ${({ theme }) =>
    modifyColor({ hex: theme.colors.main, lightness: 0.89 })};
  padding: ${({ theme }) => theme.spacing.two};
  margin: ${({ theme }) => addSpacing(theme.spacing.one)};
  overflow-x: hidden;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) =>
      modifyColor({ hex: theme.colors.main, lightness: 0.87 })};
  }
`;
