import React from 'react';

import { Tooltip } from 'react-tooltip';

import { useToast } from '@contexts/Toast';

import { CustomFormGroup } from '@components/Base/PrivateBase/styles';
import Label from '@components/Label';

import { Icon, IconWrapper, Input, InputText } from './styles';

interface ClipBoardButtonProps {
  label?: string;
  value: string;
  toastMessage: string;
  tooltipMessage: string;
}

const ClipBoardButton: React.FC<ClipBoardButtonProps> = ({
  label,
  value,
  toastMessage,
  tooltipMessage,
}) => {
  const { addToast } = useToast();

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(value);
    addToast(toastMessage, 'success');
  };

  return (
    <CustomFormGroup>
      {label && <Label>{label}</Label>}
      <Input>
        <InputText>{value}</InputText>
        <Tooltip id="copy-tooltip" />
        <IconWrapper
          data-tooltip-id="copy-tooltip"
          data-tooltip-content={tooltipMessage}
          onClick={(event) => {
            event.stopPropagation();
            copyToClipBoard();
          }}
        >
          <Icon variant="Bold" />
        </IconWrapper>
      </Input>
    </CustomFormGroup>
  );
};

export default ClipBoardButton;
