import React from 'react';

import { Wrapper, Circle } from './styles';

interface CustomRadioButtonProps {
  checked: boolean;
  onChange: () => void;
}

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({
  checked,
  onChange,
}) => {
  return (
    <Wrapper onClick={onChange}>
      <Circle checked={checked} />
    </Wrapper>
  );
};

export default CustomRadioButton;
