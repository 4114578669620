import React, { createContext, useContext, useEffect, useState } from 'react';

interface ScopeContextValues {
  scope?: number;
  companyName: string;
  taxRegime: string;
  digitalCertificateExpiration: string;
  selectScope: (company?: ICompany) => void;
}

interface ScopeProviderProps {
  children: React.ReactNode;
}

const ScopeContext = createContext<ScopeContextValues>(
  {} as ScopeContextValues
);

const ScopeProvider: React.FC<ScopeProviderProps> = ({ children }) => {
  const [scope, setScope] = useState(() => {
    const session = localStorage.getItem('scope');
    return session ? Number(session) : undefined;
  });

  const [companyName, setCompanyName] = useState(() => {
    const session = localStorage.getItem('company_name');
    return session || '';
  });

  const [taxRegime, setTaxRegime] = useState(() => {
    const session = localStorage.getItem('tax_regime');
    return session || '';
  });

  const [digitalCertificateExpiration, setDigitalCertificateExpiration] =
    useState(() => {
      const session = localStorage.getItem('digital_certificate_expiration');
      return session || '';
    });

  const handleSelectScope = (company?: ICompany) => {
    setScope(company?.id);
    setCompanyName(company?.name || '');
    setTaxRegime(company?.tax_regime || '');
    setDigitalCertificateExpiration(
      company?.digital_certificate_expiration || ''
    );
  };

  useEffect(() => {
    localStorage.setItem('scope', scope ? scope.toString() : '');
    localStorage.setItem('company_name', companyName);
    localStorage.setItem('tax_regime', taxRegime);
    localStorage.setItem(
      'digital_certificate_expiration',
      digitalCertificateExpiration
    );
  }, [scope, companyName, taxRegime, digitalCertificateExpiration]);

  return (
    <ScopeContext.Provider
      value={{
        scope,
        companyName,
        taxRegime,
        digitalCertificateExpiration,
        selectScope: handleSelectScope,
      }}
    >
      {children}
    </ScopeContext.Provider>
  );
};

const useScope = () => {
  const context = useContext(ScopeContext);
  return context;
};

export { ScopeProvider, useScope };
