import { enums } from '@utils';

import { clientSidebarData } from './clientSidebarData';
import { getCompanySidebarData } from './companySidebarData';
import { managementSidebarData } from './managementSidebarData';

export const sidebarData = (user: IUser): Array<ISidebarSection> =>
  ({
    [enums.UserType.MANAGEMENT]: managementSidebarData,
    [enums.UserType.COMPANY]:
      user.type === enums.UserType.COMPANY
        ? getCompanySidebarData(user.company.id)[user.role]
        : [],
    [enums.UserType.CLIENT]: clientSidebarData,
    [enums.UserType.DRIVER]: [],
  }[user.type]);
