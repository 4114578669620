import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

import BaseModal from '@components/BaseModal';

export const CustomModal = styled(BaseModal)`
  height: auto;
  width: 100%;
  max-height: 90svh;
  max-width: 90svw;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  & > div:first-of-type {
    width: 100%;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.five, theme.spacing.eight)};

  td {
    max-width: 160px;
  }
`;
