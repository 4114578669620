import React from 'react';

import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { FinancialType } from 'utils/enums';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useForecast } from '@hooks';

import BankBalanceCard from './BankBalanceCard';
import FinancialTransactionForecastCard from './FinancialTransactionForecastCard';
import MonthForecastCard from './MonthForecastCard';
import { FlexGroup } from './styles';

interface DashboardProps {
  queryParams: IMetricQueryParamsByYearMonth;
}

const Dashboard: React.FC<DashboardProps> = ({ queryParams }) => {
  const { getFinancialForecast } = useForecast();
  const { isCompanyUser } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();

  const { data: financialForecast, isLoading } = useQuery(
    ['financialForecast', queryParams, scope],
    () => getFinancialForecast(queryParams),
    {
      onError: (error: AxiosError) => {
        addToast('Não foi possível carregar o resumo financeiro', 'error');
        throwToastApiErrors(error, addToast);
      },
      enabled: !!scope || isCompanyUser,
    }
  );

  return (
    <>
      <FlexGroup>
        <MonthForecastCard
          financialForecast={financialForecast}
          isLoading={isLoading}
        />
        <BankBalanceCard />
      </FlexGroup>
      <FlexGroup>
        <FinancialTransactionForecastCard
          values={financialForecast?.incomes}
          type={FinancialType.INCOME}
          isLoading={isLoading}
        />
        <FinancialTransactionForecastCard
          values={financialForecast?.expenses}
          type={FinancialType.EXPENSE}
          isLoading={isLoading}
        />
      </FlexGroup>
    </>
  );
};

export default Dashboard;
