import React from 'react';

import { AxiosError } from 'axios';
import FormError from 'components/FormError';
import FormGroup from 'components/FormGroup';
import FormRow from 'components/FormRow';
import Input from 'components/Input';
import Label from 'components/Label';
import Loading from 'components/Loading';
import { useToast } from 'contexts/Toast';
import { Form, FormikProvider, useFormik } from 'formik';
import { useAuthentication } from 'hooks';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import RoutesPath from 'router/routes';
import { errors } from 'utils';
import * as yup from 'yup';

import { FittedButton } from '@components/Base/PrivateBase/styles';

import { FormSubmitContainer } from './styles';

const ResetPasswordForm: React.FC = () => {
  const { resetPassword } = useAuthentication();
  const { addToast } = useToast();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isCompanyLogin = pathname === RoutesPath.auth.resetPassword.path;

  const resetPasswordRequest = useMutation(
    (values: IResetPasswordRequest) => resetPassword(values),
    {
      onSuccess: (data) => {
        addToast(data.details, 'success');
        if (isCompanyLogin) {
          navigate(RoutesPath.auth.login.path);
        } else {
          navigate(RoutesPath.auth.managementLogin.path);
        }
      },
      onError: (error: AxiosError) => {
        const errorData = error.response?.data as { detail: string };
        addToast(errorData.detail, 'error');
      },
    }
  );

  const formikInitialValues: IResetPasswordRequest = {
    email: '',
    scope: '',
  };

  const formikValidationSchema = yup.object().shape({
    email: yup.string().trim().email(errors.email).required(errors.required),
    scope: yup
      .string()
      .trim()
      .when({
        is: () => !!isCompanyLogin,
        then: (schema) => schema.required(errors.required),
      }),
  });

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: (values) => resetPasswordRequest.mutate(values),
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        {isCompanyLogin && (
          <FormRow>
            <FormGroup>
              <Label htmlFor="scope">Identificador da empresa</Label>
              <Input
                type="text"
                id="scope"
                name="scope"
                placeholder="Identificador da empresa"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.scope}
                invalidValue={!!formik.touched.scope && !!formik.errors.scope}
              />
              <FormError name="scope" />
            </FormGroup>
          </FormRow>
        )}
        <FormRow>
          <FormGroup>
            <Label htmlFor="email">E-mail</Label>
            <Input
              type="email"
              id="email"
              name="email"
              placeholder="Digite o seu e-mail"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              invalidValue={!!formik.touched.email && !!formik.errors.email}
            />
            <FormError name="email" />
          </FormGroup>
        </FormRow>
        <FormSubmitContainer>
          {resetPasswordRequest.isLoading ? (
            <Loading />
          ) : (
            <FittedButton variant="primary" type="submit">
              Enviar link de redefinição
            </FittedButton>
          )}
        </FormSubmitContainer>
      </Form>
    </FormikProvider>
  );
};

export default ResetPasswordForm;
