import { TickCircle } from 'iconsax-react';
import { B2Card } from 'react-b2components';
import styled, { css } from 'styled-components';
import { addSpacing } from 'utils/styles';

interface CardProps {
  $isCompleted: boolean;
}

export const Container = styled(B2Card)<CardProps>`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: ${({ theme }) =>
    addSpacing('0', theme.spacing.six, theme.spacing.five)};
  animation: show-rightwards 1s ease-out, fade-in 1s ease-out,
    float 1s ease-in-out infinite;

  ${({ theme, $isCompleted }) =>
    $isCompleted &&
    css`
      align-items: center;
      justify-content: center;
      padding-top: ${theme.spacing.five};
    `}
`;

export const TickCircleIcon = styled(TickCircle)`
  color: ${({ theme }) => theme.colors.success};
  width: ${({ theme }) => theme.fontSize.big};
  height: ${({ theme }) => theme.fontSize.big};
`;

export const Message = styled.span`
  text-align: center;
  color: ${({ theme }) => theme.colors.success};
  font-size: ${({ theme }) => theme.fontSize.big};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
