import React from 'react';

import { Card, Content, Header } from './styles';

interface CommonCardProps {
  title: string;
  children: React.ReactNode;
}

const CommonCard: React.FC<CommonCardProps> = ({ title, children }) => {
  return (
    <Card>
      <Header>{title}</Header>
      <Content>{children}</Content>
    </Card>
  );
};

export default CommonCard;
