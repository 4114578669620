import React from 'react';

import { currency } from 'b2utils';
import { B2TableRow } from 'react-b2components';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';
import ModalTable from '@components/ModalTable';
import { TableDataCell } from '@components/Table/styles';

import { ModalContent } from './styles';

interface VehicleQuoteListingModalProps
  extends Pick<IBaseDeleteModalProps, 'isOpen' | 'onClose'> {
  quotation: IQuotation;
}

const VehicleQuoteListingModal: React.FC<VehicleQuoteListingModalProps> = ({
  isOpen,
  onClose,
  quotation,
}) => {
  return (
    <CustomBaseModal
      title={`Listagem de veículos do orçamento ${quotation.number} para o cliente ${quotation.client_name}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent>
        <ModalTable
          data={quotation.vehicles}
          isLoading={false}
          headerData={[
            'Veículo',
            'Valor para seguro',
            'Origem',
            'Destino',
            'Prazo (em dias)',
            'Valor do frete',
          ]}
          emptyMessage="Nenhum veículo encontrado"
          renderRow={(vehicle) => (
            <B2TableRow key={vehicle.id}>
              <TableDataCell>{vehicle.model}</TableDataCell>
              <TableDataCell>
                {currency.brlMask(vehicle.insurance_value.toString())}
              </TableDataCell>
              <TableDataCell>
                {vehicle.origin.name}/{vehicle.origin.state}
              </TableDataCell>
              <TableDataCell>
                {vehicle.destination.name}/{vehicle.destination.state}
              </TableDataCell>
              <TableDataCell>{vehicle.estimated_delivery_days}</TableDataCell>
              <TableDataCell>
                {currency.brlMask(vehicle.freight_value.toString())}
              </TableDataCell>
            </B2TableRow>
          )}
          hasClick={false}
        />
      </ModalContent>
    </CustomBaseModal>
  );
};

export default VehicleQuoteListingModal;
