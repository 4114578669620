import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { CompanyUserRole, DocumentsListType, FileType } from 'utils/enums';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';

import PageHeader from '@components/PageHeader';
import Tabs from '@components/Tabs';

import RoutesPath from '@router/routes';

import CoseMdfeModal from './CloseMdfeModal';
import FiscalZipFileModal from './FiscalZipFileModal';
import { documentListProps } from './documentList';
import { tabs } from './tabs';

const Documents: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getCurrentListType = useCallback(() => {
    const listPropsValues = Object.values(documentListProps);
    const currentListProps = listPropsValues.find(
      (item) => item.listPath === location.pathname
    );

    return currentListProps
      ? currentListProps.type
      : DocumentsListType.SERVICES;
  }, [location.pathname]);

  const [selectedListType, setSelectListType] = useState(getCurrentListType());
  const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);
  const [isCoseMdfeModalOpen, setIsCoseMdfeModalOpen] = useState(false);

  const { isCompanyUser, isManagementUser, userInfo } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();

  const { subtitle, actionButtonProps, extraButtonProps } =
    documentListProps[selectedListType];
  const isSalesUser = isCompanyUser && userInfo?.role === CompanyUserRole.SALES;

  const filteredTabs = useMemo(() => {
    if (!isSalesUser) {
      return tabs;
    }

    return tabs.filter(
      (tab) =>
        tab.path !== RoutesPath.private.documents.listDepartures.path &&
        tab.path !== RoutesPath.private.documents.listMDFe.path
    );
  }, [isSalesUser]);

  const navigateToCreatePage = useCallback(() => {
    if (!actionButtonProps?.createPath) {
      return;
    }

    if (isManagementUser && !scope) {
      addToast(actionButtonProps.toastMessage, 'error');
      return;
    }

    navigate(actionButtonProps.createPath);
  }, [addToast, actionButtonProps, isManagementUser, navigate, scope]);

  const handleDownloadFiscalFiles = useCallback(() => {
    if (!scope && isManagementUser && actionButtonProps?.toastMessage) {
      addToast(actionButtonProps.toastMessage, 'error');
    } else {
      setDownloadModalIsOpen(!downloadModalIsOpen);
    }
  }, [
    actionButtonProps?.toastMessage,
    addToast,
    downloadModalIsOpen,
    isManagementUser,
    scope,
  ]);

  const handleCoseMdfeClick = useCallback(() => {
    if (!scope && isManagementUser && extraButtonProps?.toastMessage) {
      addToast(extraButtonProps.toastMessage, 'error');
    } else {
      setIsCoseMdfeModalOpen(true);
    }
  }, [extraButtonProps?.toastMessage, addToast, isManagementUser, scope]);

  const handleButtonClick = useCallback(() => {
    if (actionButtonProps?.createPath) {
      return navigateToCreatePage();
    }
    handleDownloadFiscalFiles();
  }, [
    actionButtonProps?.createPath,
    handleDownloadFiscalFiles,
    navigateToCreatePage,
  ]);

  const handleExtraButtonClick = useCallback(() => {
    if (extraButtonProps?.createPath) {
      return navigateToCreatePage();
    }
    handleCoseMdfeClick();
  }, [extraButtonProps?.createPath, handleCoseMdfeClick, navigateToCreatePage]);

  const validatedExtraButtonProps = useMemo(() => {
    if (!extraButtonProps) {
      return;
    }

    if (selectedListType === DocumentsListType.MDFE && !isManagementUser) {
      return;
    }

    return {
      text: extraButtonProps.buttonText,
      onClick: handleExtraButtonClick,
    };
  }, [
    extraButtonProps,
    handleExtraButtonClick,
    isManagementUser,
    selectedListType,
  ]);

  useEffect(() => {
    const newListType = getCurrentListType();
    setSelectListType(newListType);
  }, [getCurrentListType]);

  return (
    <>
      <PageHeader
        title={selectedListType}
        subtitle={subtitle}
        buttonProps={
          actionButtonProps && {
            text: actionButtonProps.buttonText,
            onClick: handleButtonClick,
          }
        }
        extraButtonProps={validatedExtraButtonProps}
      />
      <Tabs tabs={filteredTabs} />
      <FiscalZipFileModal
        isOpen={downloadModalIsOpen}
        onClose={() => setDownloadModalIsOpen(false)}
        type={
          selectedListType === DocumentsListType.CTE
            ? FileType.CTE
            : FileType.MDFE
        }
      />
      <CoseMdfeModal
        isOpen={isCoseMdfeModalOpen}
        onClose={() => setIsCoseMdfeModalOpen(false)}
      />
    </>
  );
};

export default Documents;
