import React from 'react';

import { Add } from 'iconsax-react';
import { IB2Button } from 'react-b2components';

import { Container, CustomButton, Header, Title } from './styles';

interface ButtonProps extends Omit<IB2Button, 'variant' | 'children'> {
  text: string;
}

interface DashboardListingWrapperProps {
  title: string;
  buttonProps?: ButtonProps;
  children: React.ReactNode;
}

const DashboardListingWrapper: React.FC<DashboardListingWrapperProps> = ({
  title,
  buttonProps,
  children,
}) => {
  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        {buttonProps && (
          <CustomButton variant="transparent" {...buttonProps}>
            <Add />
            {buttonProps.text}
          </CustomButton>
        )}
      </Header>
      {children}
    </Container>
  );
};

export default DashboardListingWrapper;
