import React, { useEffect, useRef } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useCompanies } from '@hooks';

import BaseForm from '@components/BaseForm';
import CompanyInfo from '@components/CompanyForm/CompanyInfo';
import InsuranceForm from '@components/InsuranceForm';
import SingleAddressForm from '@components/SingleAddressForm';
import TransportForm from '@components/TransportForm';

import RoutesPath from '@router/routes';

import routes from './routes';

const CreateCompany: React.FC = () => {
  const { createCompany } = useCompanies();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { scope } = useScope();

  const FormInfoStepRef = useRef(null);
  const FormAddressStepRef = useRef(null);
  const FormInsuranceStepRef = useRef(null);
  const FormTransportStepRef = useRef(null);

  const handleSubmitCompany = useMutation(
    (companyValues: ICompanyFormRequest) => createCompany(companyValues),
    {
      onSuccess: () => {
        addToast('Empresa adicionada com sucesso', 'success');
        navigate(RoutesPath.private.companies.listCompanies.path, {
          replace: true,
        });
      },
      onError: () => {
        addToast('Erro ao cadastrar empresa', 'error');
      },
    }
  );

  useEffect(() => {
    if (scope) {
      addToast(
        'Não é possível cadastrar uma empresa estando com uma selecionada',
        'error'
      );
      navigate(RoutesPath.private.companies.listCompanies.path, {
        replace: true,
      });
    }
  }, [scope, navigate, addToast]);

  const steps: Array<IStep> = [
    {
      title: 'Informações básicas',
      ref: FormInfoStepRef,
      component: CompanyInfo,
    },
    {
      title: 'Endereço',
      ref: FormAddressStepRef,
      component: SingleAddressForm,
    },
    {
      title: 'Informações de seguro',
      ref: FormInsuranceStepRef,
      component: InsuranceForm,
    },
    {
      title: 'Emissão de CT-e & MDF-e',
      ref: FormTransportStepRef,
      component: TransportForm,
    },
  ];

  return (
    <BaseForm
      createRequest={handleSubmitCompany}
      steps={steps}
      title="Adicionar empresa"
      routes={routes()}
    />
  );
};

export default CreateCompany;
