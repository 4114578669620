import { B2Button } from 'react-b2components';
import styled from 'styled-components';

import FormGroup from '@components/FormGroup';

export const CustomFormGroup = styled(FormGroup)`
  div:nth-child(2),
  button {
    align-self: center;
  }

  img {
    object-fit: contain;
  }
`;

export const RemoveImageButton = styled(B2Button)`
  width: fit-content;
  color: ${({ theme }) => theme.colors.error};
`;
