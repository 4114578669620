import React, { useMemo } from 'react';

import { CompanyUserRole, CteIssuanceStatus } from 'utils/enums';

import { useAuth } from '@contexts/Auth';

import CancelCteButton from './CancelCteButton';
import DownloadsButton from './DownloadsButton';
import RectifyCteButton from './RectifyCteButton';
import { Container } from './styles';

interface HeaderButtonsProps {
  cte?: ICte;
  hasUnsavedData: boolean;
  onRequestSuccess: () => void;
  openUnsavedDataModal: () => void;
}

const SEVEN_DAYS_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;

const HeaderButtons: React.FC<HeaderButtonsProps> = ({
  cte,
  hasUnsavedData,
  onRequestSuccess,
  openUnsavedDataModal,
}) => {
  const { hasAdminPermission, isCompanyUser, userInfo } = useAuth();

  const hasStatus = cte?.issuance_status === CteIssuanceStatus.ISSUED;

  const isAbleToCancel = useMemo(() => {
    if (!cte?.issued_at || cte?.rectifications.length) {
      return false;
    }

    const issuanceDate = new Date(cte.issued_at);
    const todaysDate = new Date();

    const limitDate = new Date(
      issuanceDate.getTime() + SEVEN_DAYS_IN_MILLISECONDS
    );

    return todaysDate < limitDate;
  }, [cte?.issued_at, cte?.rectifications.length]);

  const hasPermission = useMemo(
    () =>
      hasAdminPermission ||
      (isCompanyUser && userInfo?.role === CompanyUserRole.LOGISTICS),
    [hasAdminPermission, isCompanyUser, userInfo?.role]
  );

  const shouldRenderActionButtons = hasPermission && hasStatus;

  if (!cte || !cte.pdf_file || !cte.xml_file) {
    return null;
  }

  return (
    <Container>
      {shouldRenderActionButtons && (
        <>
          <RectifyCteButton
            cte={cte}
            hasUnsavedData={hasUnsavedData}
            onRequestSuccess={onRequestSuccess}
            openUnsavedDataModal={openUnsavedDataModal}
          />
          {isAbleToCancel && (
            <CancelCteButton cte={cte} onRequestSuccess={onRequestSuccess} />
          )}
        </>
      )}
      <DownloadsButton cte={cte} />
    </Container>
  );
};

export default HeaderButtons;
