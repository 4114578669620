import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import regex from 'utils/regex';
import * as yup from 'yup';

import FileSinglePicker from '@components/FileSinglePicker';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';

import { errors, helpers } from '@utils';

interface ITrailerInfoProps {
  initialValues?: ITrailerInfoStepForm;
  onFinish: (data: ITrailerInfoStepForm) => void;
}

const MAX_WEIGHT = 999999;

const TrailerInfo: React.ForwardRefRenderFunction<
  IFormStepRef,
  ITrailerInfoProps
> = ({ onFinish, initialValues }, ref) => {
  const formikInitialValues: ITrailerInfoStepForm = {
    trailerLicensePlate: '',
    trailerLicenseState: '',
    trailerTareWeight: '',
    trailerPayloadCapacity: '',
    trailerCrlvFile: undefined,
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    trailerLicensePlate: yup
      .string()
      .trim()
      .length(7, errors.length(7))
      .required(errors.required),
    trailerLicenseState: yup.string().trim().required(errors.required),
    trailerTareWeight: yup
      .string()
      .trim()
      .test(
        'is-trailer-tare-weight-valid',
        errors.maxWeight(MAX_WEIGHT),
        (value) => !!value && Number(value) <= MAX_WEIGHT
      )
      .required(errors.required),
    trailerPayloadCapacity: yup
      .string()
      .trim()
      .test(
        'is-trailer-payload-capacity-valid',
        errors.maxWeight(MAX_WEIGHT),
        (value) => !!value && Number(value) <= MAX_WEIGHT
      )
      .required(errors.required),
    trailerCrlvFile: yup.object().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="trailerLicensePlate">Placa da carreta *</Label>
          <Input
            type="text"
            id="trailerLicensePlate"
            name="trailerLicensePlate"
            placeholder="Digite aqui"
            value={formik.values.trailerLicensePlate}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'trailerLicensePlate',
                event.target.value
                  .replace(regex.onlyNumbersAndLetters, '')
                  .toUpperCase()
              )
            }
            maxLength={7}
            invalidValue={
              !!formik.touched.trailerLicensePlate &&
              !!formik.errors.trailerLicensePlate
            }
          />
          <FormError name="trailerLicensePlate" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="trailerLicenseState">
            Estado da licença da carreta *
          </Label>
          <Select
            id="trailerLicenseState"
            name="trailerLicenseState"
            value={formik.values.trailerLicenseState}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={
              !!formik.touched.trailerLicenseState &&
              !!formik.errors.trailerLicenseState
            }
          >
            {helpers.ufOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <FormError name="trailerLicenseState" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="trailerTareWeight">Tara da carreta (kg) *</Label>
          <Input
            id="trailerTareWeight"
            name="trailerTareWeight"
            type="text"
            placeholder="Digite aqui"
            value={formik.values.trailerTareWeight}
            onChange={(event) =>
              formik.setFieldValue(
                'trailerTareWeight',
                event.target.value.replace(regex.onlyNumbersAndDot, '')
              )
            }
            onBlur={formik.handleBlur}
            invalidValue={
              !!formik.touched.trailerTareWeight &&
              !!formik.errors.trailerTareWeight
            }
          />
          <FormError name="trailerTareWeight" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="trailerPayloadCapacity">
            Capacidade da carreta (kg) *
          </Label>
          <Input
            id="trailerPayloadCapacity"
            name="trailerPayloadCapacity"
            type="text"
            placeholder="Digite aqui"
            value={formik.values.trailerPayloadCapacity}
            onChange={(event) =>
              formik.setFieldValue(
                'trailerPayloadCapacity',
                event.target.value.replace(regex.onlyNumbersAndDot, '')
              )
            }
            onBlur={formik.handleBlur}
            invalidValue={
              !!formik.touched.trailerPayloadCapacity &&
              !!formik.errors.trailerPayloadCapacity
            }
          />
          <FormError name="trailerPayloadCapacity" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="trailerCrlvFile">CRLV da carreta</Label>
          <FileSinglePicker
            file={formik.values.trailerCrlvFile}
            onDeleteFile={() => formik.setFieldValue('trailerCrlvFile', null)}
            onAddFile={(file) => formik.setFieldValue('trailerCrlvFile', file)}
          />
          <FormError name="trailerCrlvFile" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(TrailerInfo);
