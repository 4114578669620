import { color } from 'b2utils';
import { B2Button } from 'react-b2components';
import { Tooltip } from 'react-tooltip';
import styled, { css } from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Container = styled.div`
  display: flex;
  position: relative;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const CustomButton = styled(B2Button)<{ isCancelButton?: boolean }>`
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-size: ${({ theme }) => theme.fontSize.small};
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) => addSpacing(theme.spacing.one, theme.spacing.three)};
  animation: fade-in 0.5s ease-out;

  svg {
    flex-shrink: 0;
    width: ${({ theme }) => theme.spacing.five};
    height: ${({ theme }) => theme.spacing.five};
  }

  ${({ theme, isCancelButton }) =>
    isCancelButton
      ? css`
          color: ${theme.colors.error};
          gap: ${theme.spacing.two};
          padding: ${addSpacing(theme.spacing.one, theme.spacing.three)};
          background-color: ${color.addOpacityOnHexColor(
            theme.colors.error,
            0.2
          )};
        `
      : css`
          color: ${theme.colors.info};
          gap: ${theme.spacing.two};
          padding: ${addSpacing(theme.spacing.one, theme.spacing.three)};
          background-color: ${color.addOpacityOnHexColor(
            theme.colors.info,
            0.2
          )};
        `}

  & > span {
    animation: fade-in 0.5s ease-out;
    @media (max-width: 1180px) {
      display: none;
    }
  }
`;

export const CustomTooltip = styled(Tooltip)`
  @media (min-width: 1180px) {
    & {
      display: none;
    }
  }
`;
