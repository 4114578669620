import styled, { css } from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Container = styled.div`
  overflow: auto;
  display: flex;
  flex-shrink: 0;
  margin-bottom: ${({ theme }) => theme.spacing.four};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.two};
`;

export const Title = styled.div<{ isSelected: boolean }>`
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.gray.six};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding: ${({ theme }) => addSpacing('0', theme.spacing.three)};
  cursor: pointer;

  ${({ theme, isSelected }) =>
    isSelected &&
    css`
      color: ${theme.colors.black.two};
      font-weight: ${theme.fontWeight.bold};
      border-bottom: 3px solid ${({ theme }) => theme.colors.main};
      transition: border 0.2s 0s ease;
      cursor: default;
    `}
`;
