import React from 'react';

import { useFormikContext } from 'formik';
import { toDateInput } from 'utils/helpers';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import Input from '@components/Input';
import Label from '@components/Label';

interface BaseFormikValuesProps {
  label: string;
  formikKey: string;
}

interface BaseDateInputProps {
  [key: string]: string;
}

const BaseDateInput: React.FC<BaseFormikValuesProps> = ({
  formikKey,
  label,
}) => {
  const formik = useFormikContext<BaseDateInputProps>();

  return (
    <FormGroup>
      <Label htmlFor={formikKey}>{label}</Label>
      <Input
        type="date"
        id={formikKey}
        name={formikKey}
        value={formik.values[formikKey]}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        min={toDateInput(new Date())}
        invalidValue={!!formik.touched[formikKey] && !!formik.errors[formikKey]}
      />
      <FormError name={formikKey} />
    </FormGroup>
  );
};

export default BaseDateInput;
