import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { useAuth } from '@contexts/Auth';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import PasswordInput from '@components/PasswordInput';
import SectionHeader from '@components/SectionHeader';

import { errors } from '@utils';

interface IProfileInfoFormProps {
  onFinish: (data: IUpdateEmail) => void;
}

const ProfileInfoForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  IProfileInfoFormProps
> = ({ onFinish }, ref) => {
  const { userInfo } = useAuth();

  const formikInitialValues: IUpdateEmail = {
    oldEmail: userInfo?.email || '',
    email: '',
    password: '',
  };

  const formikValidationSchema = yup.object().shape({
    oldEmail: yup.string().trim(),
    email: yup
      .string()
      .trim()
      .email(errors.email)
      .required(errors.required)
      .notOneOf([yup.ref('oldEmail')], errors.newEmail),
    password: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <SectionHeader title="Atualizar e-mail" isFormHeader />
      <FormRow>
        <FormGroup>
          <Label htmlFor="oldEmail">E-mail atual *</Label>
          <Input
            type="email"
            placeholder="Digite o seu e-mail atual"
            id="oldEmail"
            name="oldEmail"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.oldEmail}
            invalidValue={!!formik.touched.oldEmail && !!formik.errors.oldEmail}
            disabled
          />
          <FormError name="oldEmail" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="email">Novo e-mail *</Label>
          <Input
            type="email"
            placeholder="Digite o novo e-mail"
            id="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            invalidValue={!!formik.touched.email && !!formik.errors.email}
          />
          <FormError name="email" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="password">Senha *</Label>
          <PasswordInput
            type="password"
            placeholder="Digite sua senha"
            id="password"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            invalidValue={!!formik.touched.password && !!formik.errors.password}
          />
          <FormError name="password" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(ProfileInfoForm);
