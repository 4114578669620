import { color } from 'b2utils';
import styled, { css } from 'styled-components';
import { addSpacing } from 'utils/styles';

interface ITab {
  selected?: boolean;
}

export const TabNames = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const TabName = styled.span<ITab>`
  font-size: ${({ theme }) => theme.fontSize.small};
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  padding: ${({ theme }) =>
    addSpacing(
      theme.spacing.three,
      theme.spacing.twelve,
      theme.spacing.three,
      theme.spacing.four
    )};
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:after {
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transition: width 0.5s;
  }

  ${({ selected, theme }) =>
    selected
      ? css`
          font-weight: ${theme.fontWeight.bold};
          border-right: 4px solid ${theme.colors.main};
          color: ${theme.colors.main};
          &:after {
            width: 100%;
            background-color: ${color.addOpacityOnHexColor(
              theme.colors.main,
              0.12
            )};
          }
        `
      : css`
          color: ${theme.colors.gray.six};
          font-weight: ${theme.fontWeight.semiBold};
          border-right: 1px solid ${theme.colors.gray.one};
          padding-right: ${({ theme }) => theme.spacing.twelve}};
          &:after {
            width: 0px;
            background-color: transparent;
          }
        `}
`;
