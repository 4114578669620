import styled, { css } from 'styled-components';
import { addSpacing } from 'utils/styles';

interface MenuItemProps {
  isLogoutItem?: boolean;
}

export const Container = styled.div<MenuItemProps>`
  margin: 0;
  padding: ${({ theme }) => addSpacing(theme.spacing.four, theme.spacing.six)};
  gap: ${({ theme }) => theme.spacing.four};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray.six};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white.two};
  }

  svg {
    width: ${({ theme }) => theme.spacing.five};
    color: ${({ theme }) => theme.colors.gray.six};
  }

  ${({ theme, isLogoutItem }) =>
    isLogoutItem &&
    css`
      border-top: 1px solid ${theme.colors.gray.one};
    `};
`;

export const Text = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;
