import React, { useRef } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { usePaymentMethods, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';
import Loading from '@components/Loading';

import RoutesPath from '@router/routes';

import PaymentMethodForm from '../Form/PaymentMethodForm';
import routes from './routes';

const UpdatePaymentMethod: React.FC = () => {
  const { paymentMethodId } = useParams<{ paymentMethodId: string }>();

  const paymentMethodFormRef = useRef<IFormStepRef>(null);

  const { getPaymentMethod, updatePaymentMethod } = usePaymentMethods();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useVerifyScope({
    routePath: RoutesPath.private.paymentMethods.listPaymentMethods.path,
    toastErrorMessage:
      'Selecione uma empresa para editar um método de pagamento',
  });

  const {
    data: paymentMethod,
    refetch,
    isLoading,
  } = useQuery(
    ['paymentMethod', paymentMethodId],
    () => getPaymentMethod(paymentMethodId!),
    {
      onError: () => {
        addToast('Falha ao buscar os dados do método de pagamento', 'error');
      },
      enabled: !!paymentMethodId,
    }
  );

  const updatePaymentMethodRequest = useMutation(
    (values: IPaymentMethodFormValues) =>
      updatePaymentMethod(paymentMethodId!, values),
    {
      onSuccess: () => {
        refetch();
        addToast(
          'Dados do método de pagamento atualizados com sucesso',
          'success'
        );
        navigate(RoutesPath.private.paymentMethods.listPaymentMethods.path);
      },
      onError: () => {
        addToast(
          'Erro ao tentar editar informações do método de pagamento',
          'error'
        );
      },
    }
  );

  const steps: Array<IStep> = [
    {
      title: 'Informações do método de pagamento de método de pagamento',
      ref: paymentMethodFormRef,
      component: PaymentMethodForm,
    },
  ];

  if (isLoading || !paymentMethod) {
    return <Loading />;
  }

  return (
    <BaseForm
      steps={steps}
      createRequest={updatePaymentMethodRequest}
      routes={routes}
      title="Editar método de pagamento"
      initialValues={{ name: paymentMethod.name }}
    />
  );
};

export default UpdatePaymentMethod;
