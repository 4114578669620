import { color } from 'b2utils';
import styled from 'styled-components';

export const Badge = styled.div<{ situation: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  padding: ${({ theme }) => theme.spacing.two};
  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ theme, situation }) =>
    situation ? theme.colors.success : theme.colors.error};
  border: 2px solid
    ${({ theme, situation }) =>
      situation
        ? color.addOpacityOnHexColor(theme.colors.success, 0.8)
        : color.addOpacityOnHexColor(theme.colors.error, 0.8)};
`;
