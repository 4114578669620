import { B2Button, B2Modal } from 'react-b2components';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Modal = styled(B2Modal)`
  height: auto;
  width: 100%;
  max-height: 90svh;
  max-width: 90svw;

  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.spacing.three};
  background-color: ${({ theme }) => theme.colors.white.main};
  cursor: auto;
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.four};
  padding: ${({ theme }) => addSpacing(theme.spacing.six, theme.spacing.eight)};
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colors.white.three};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.four, theme.spacing.eight)};
`;

export const Button = styled(B2Button)`
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.two, theme.spacing.twelve)};
`;
