import React from 'react';

import DeleteButton from './DeleteButton';
import { Container, Title } from './styles';

interface SectionHeaderProps {
  title: string;
  deleteButton?: {
    text: string;
    onClick: () => void;
    disabled?: boolean;
  };
  isFormHeader?: boolean;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  deleteButton,
  isFormHeader = false,
}) => {
  return (
    <Container isFormHeader={isFormHeader}>
      <Title>
        {title}
        {deleteButton && <DeleteButton {...deleteButton} />}
      </Title>
    </Container>
  );
};

export default SectionHeader;
