import RoutesPath from '@router/routes';

const routes = (companyId: string) => {
  return [
    {
      label: 'Empresas',
      path: RoutesPath.private.companies.listCompanies.path,
    },
    {
      label: 'Detalhes da empresa',
      path: RoutesPath.private.companies.detailCompany.path.replace(
        ':companyId',
        companyId
      ),
    },
    {
      label: 'Editar empresa',
      path: RoutesPath.private.companies.updateCompany.path.replace(
        ':companyId',
        companyId
      ),
    },
  ];
};

export default routes;
