import React, { useRef } from 'react';

import { currency, phone } from 'b2utils';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useQuotations, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';
import Loading from '@components/Loading';

import RoutesPath from '@router/routes';

import QuotationInfo from '../Form/QuotationInfo';
import VehiclesQuotation from '../Form/VehiclesQuotation';
import getRoutes from './routes';

const UpdateQuotation: React.FC = () => {
  const { quotationId } = useParams<{ quotationId: string }>();

  const quotationInfoStepFormRef = useRef<IFormStepRef>(null);
  const vehiclesQuotationStepFormRef = useRef<IFormStepRef>(null);

  const { getQuotation, updateQuotation } = useQuotations();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useVerifyScope({
    routePath: RoutesPath.private.quotations.listQuotations.path,
    toastErrorMessage: 'Selecione uma empresa para editar um orçamento',
  });

  const {
    data: quotation,
    refetch,
    isLoading,
  } = useQuery(['quotation', quotationId], () => getQuotation(quotationId!), {
    onError: () => {
      addToast('Falha ao buscar os dados do orçamento', 'error');
    },
  });

  const updateQuotationRequest = useMutation(
    (values: IQuotationFormValues) => updateQuotation(quotationId!, values),
    {
      onSuccess: () => {
        refetch();
        addToast('Dados do orçamento atualizados com sucesso', 'success');
        navigate(
          RoutesPath.private.quotations.listQuotations.path.replace(
            ':quotationId',
            quotationId!
          )
        );
      },
      onError: () => {
        addToast('Erro ao tentar editar informações do orçamento', 'error');
      },
    }
  );

  const getQuotationInfoInitialValues = (quotation: IQuotation) => {
    const initialValues: IQuotationFormValues = {
      clientName: quotation.client_name,
      phone: phone.fromObject({
        areaCode: quotation.phone.area_code,
        number: quotation.phone.number,
      }),
      paymentMethod: quotation.payment_method,
      collectionType: quotation.collection_type,
      collectionAddress: quotation.collection_address || '',
      deliveryType: quotation.delivery_type,
      deliveryAddress: quotation.delivery_address || '',
      estimatedShippingDate: quotation.estimated_shipping_date,
      expiration: quotation.expiration.toString(),
      vehicles: quotation.vehicles.map((vehicle) => ({
        model: vehicle.model,
        estimatedDeliveryDays: vehicle.estimated_delivery_days.toString(),
        insuranceValue: currency.brlMask(vehicle.insurance_value.toString()),
        freightValue: currency.brlMask(vehicle.freight_value.toString()),
        origin: vehicle.origin,
        destination: vehicle.destination,
      })),
    };
    return initialValues;
  };

  const steps: Array<IStep> = [
    {
      title: 'Informações do orçamento',
      ref: quotationInfoStepFormRef,
      component: QuotationInfo,
    },
    {
      title: 'Veículos',
      ref: vehiclesQuotationStepFormRef,
      component: VehiclesQuotation,
    },
  ];

  if (isLoading || !quotation) {
    return <Loading />;
  }

  return (
    <BaseForm
      steps={steps}
      createRequest={updateQuotationRequest}
      routes={getRoutes(quotationId!)}
      title="Editar orçamento"
      initialValues={getQuotationInfoInitialValues(quotation)}
    />
  );
};

export default UpdateQuotation;
