import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useMetrics } from '@hooks';

import { HeaderText, MetricCard } from '../../styles';
import DonutChart from '../DonutChart';

interface ServiceCountByOriginStateProps {
  queryParams: IMetricQueryParamsByDateInterval;
}

const ServiceCountByOriginState: React.FC<ServiceCountByOriginStateProps> = ({
  queryParams,
}) => {
  const [series, setSeries] = useState<Array<number>>([]);
  const [labels, setLabels] = useState<Array<string>>([]);

  const { getServicesCountByOriginState } = useMetrics();
  const { isManagementUser, isCompanyUser } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();

  const isManagementWithoutScope = isManagementUser && !scope;

  useQuery(
    ['servicesCountByOriginState', queryParams, scope],
    () => getServicesCountByOriginState(queryParams),
    {
      onSuccess: (data) => {
        const newSeries: Array<number> = [];
        const newLabels: Array<string> = [];

        data.map(({ origin__state, count }) => {
          newSeries.push(count);
          newLabels.push(origin__state);
        });

        setLabels(newLabels);
        setSeries(newSeries);
      },
      onError: (error: AxiosError) => {
        throwToastApiErrors(error, addToast);
      },
      enabled: !!scope || isCompanyUser,
    }
  );

  useEffect(() => {
    if (isManagementWithoutScope) {
      setLabels([]);
      setSeries([]);
    }
  }, [isManagementWithoutScope]);

  return (
    <MetricCard>
      <HeaderText>Serviços por estado</HeaderText>
      <DonutChart labels={labels} series={series} />
    </MetricCard>
  );
};

export default ServiceCountByOriginState;
