import { color } from 'b2utils';
import { Calendar, CloseCircle } from 'iconsax-react';
import styled, { css } from 'styled-components';
import { addSpacing } from 'utils/styles';

interface SelectedDateProps {
  isSelectedDate: boolean;
}

export const CalendarFilterContainer = styled.div<{ isOpen: boolean }>`
  min-width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.four)};
  border-radius: ${({ theme }) => theme.spacing.three};
  border: 1px solid ${({ theme }) => theme.colors.white.three};
  background-color: ${({ theme }) => theme.colors.white.main};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  position: relative;
  cursor: pointer;

  ${({ isOpen, theme }) =>
    isOpen &&
    css`
      border: 1px solid ${theme.colors.info};
      outline: 2px solid ${color.addOpacityOnHexColor(theme.colors.info, 0.1)};
    `};
`;

export const SelectedDate = styled.span<SelectedDateProps>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: ${({ theme }) => theme.spacing.two};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ isSelectedDate, theme }) =>
    isSelectedDate ? theme.colors.black.two : theme.colors.gray.four};
`;

export const IconsContainer = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const iconStyles = css`
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};
`;

export const CalendarIcon = styled(Calendar)`
  ${iconStyles};

  color: ${({ theme }) => theme.colors.secondary};

  &:hover {
    opacity: 0.7;
  }
`;

export const CloseIcon = styled(CloseCircle)`
  ${iconStyles};

  color: ${({ theme }) => theme.colors.error};

  &:hover {
    opacity: 0.7;
  }
`;
