import React, { useCallback, useState } from 'react';

import moment from 'moment';
import { CompanyUserRole, CountMetricType } from 'utils/enums';

import { useAuth } from '@contexts/Auth';

import MonthCalendar from '@components/MonthCalendar';
import PageHeader from '@components/PageHeader';

import CountMetricCard from './CountMetricCard';
import CountMetricsByDateAggregation from './CountMetricsByDateAggregation';
import ServiceCountByCategory from './ServiceCountByEntity/ServiceCountByCategory';
import ServiceCountByOriginState from './ServiceCountByEntity/ServiceCountByOriginState';
import ServiceCountByPersonInCharge from './ServiceCountByEntity/ServiceCountByPersonInCharge';
import ServiceCountByVehicleType from './ServiceCountByEntity/ServiceCountByVehicleType';
import { Container, FlexGroup } from './styles';

const Dashboard: React.FC = () => {
  const [yearMonth, setYearMonth] = useState(moment().format('YYYY-MM'));
  const [dateInterval, setDateInterval] =
    useState<IMetricQueryParamsByDateInterval>({
      startAt: moment().startOf('month').format('YYYY-MM-DD'),
      endAt: moment().endOf('month').format('YYYY-MM-DD'),
    });

  const { isCompanyUser, userInfo } = useAuth();

  const isSalesUser = isCompanyUser && userInfo?.role === CompanyUserRole.SALES;

  const handleMonthChange = useCallback((date?: Date) => {
    if (!date) {
      return;
    }

    setYearMonth(moment(date).format('YYYY-MM'));
    setDateInterval({
      startAt: moment(date).startOf('month').format('YYYY-MM-DD'),
      endAt: moment(date).endOf('month').format('YYYY-MM-DD'),
    });
  }, []);

  return (
    <>
      <PageHeader
        title="Dashboard"
        subtitle="Aqui estão as métricas do sistema"
      >
        <MonthCalendar
          initialDate={new Date()}
          onSelectMonth={handleMonthChange}
        />
      </PageHeader>
      <Container>
        <FlexGroup>
          <CountMetricCard
            yearMonth={yearMonth}
            type={CountMetricType.QUOTATIONS}
          />
          <CountMetricCard
            yearMonth={yearMonth}
            type={CountMetricType.SERVICES}
          />
        </FlexGroup>
        <FlexGroup>
          {!isSalesUser && (
            <CountMetricCard
              yearMonth={yearMonth}
              type={CountMetricType.DEPARTURES}
            />
          )}
          <CountMetricCard
            yearMonth={yearMonth}
            type={CountMetricType.INSPECTIONS}
          />
        </FlexGroup>
        <FlexGroup>
          {!isSalesUser && (
            <ServiceCountByPersonInCharge queryParams={dateInterval} />
          )}
          <ServiceCountByCategory queryParams={dateInterval} />
        </FlexGroup>
        <FlexGroup>
          <ServiceCountByVehicleType queryParams={dateInterval} />
          <ServiceCountByOriginState queryParams={dateInterval} />
        </FlexGroup>
        <CountMetricsByDateAggregation />
      </Container>
    </>
  );
};

export default Dashboard;
