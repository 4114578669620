import { useCallback } from 'react';

import { useApi, endpoints } from 'contexts/Api';

const useCfops = () => {
  const { request } = useApi();

  const listCfops = useCallback(
    async (params: ICfopQueryParams) => {
      const response = await request<IPaginated<ICfop>>({
        method: 'get',
        url: endpoints.services.cfops.list,
        params: {
          page: params.page,
          search: params.search || undefined,
        },
      });

      return response.data;
    },
    [request]
  );

  return { listCfops };
};

export default useCfops;
