import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { CloseCircle } from 'iconsax-react';
import { useMutation } from 'react-query';
import { useTheme } from 'styled-components';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useFiscal } from '@hooks';

import CancelFiscalDocumentFormModal from '@components/CancelFiscalDocumentFormModal';
import Loading from '@components/Loading';

import { CustomButton, CustomTooltip } from '../styles';

interface CancelMdfeButtonProps {
  mdfe: IMdfe;
  onRequestSuccess: () => void;
}

const CancelMdfeButton: React.FC<CancelMdfeButtonProps> = ({
  mdfe,
  onRequestSuccess,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { cancelMdfe } = useFiscal();
  const { addToast } = useToast();
  const theme = useTheme();

  const { mutate: requestCancelMdfe, isLoading: isCancelMdfeRequestLoading } =
    useMutation(
      (values: ICancelFiscalDocument) => cancelMdfe(mdfe.id, values),
      {
        onSuccess: () => {
          onRequestSuccess();
          setIsModalOpen(false);
          addToast('MDF-e cancelado com sucesso', 'success');
        },
        onError: (error: AxiosError) => {
          addToast('Erro ao cancelar MDF-e', 'error');
          throwToastApiErrors(error, addToast);
        },
      }
    );

  return (
    <>
      <CustomTooltip id="cancel-mdfe-tooltip" />
      <CustomButton
        data-tooltip-id="cancel-mdfe-tooltip"
        data-tooltip-content="Cancelar MDF-e"
        isCancelButton
        onClick={() => !isCancelMdfeRequestLoading && setIsModalOpen(true)}
      >
        {isCancelMdfeRequestLoading ? (
          <Loading color={theme.colors.white.main} />
        ) : (
          <>
            <CloseCircle variant="Bold" />
            <span>Cancelar MDF-e</span>
          </>
        )}
      </CustomButton>
      <CancelFiscalDocumentFormModal
        title="Tem certeza que deseja cancelar o MDF-e?"
        message={`Ao cancelar o MDF-e, não será possível emitir novamente.\nNeste caso, você terá que cancelar o serviço e criar um novo.\nEsta ação é irreversível!`}
        confirmText="Sim, cancelar"
        cancelText="Não, voltar"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={requestCancelMdfe}
        isLoading={isCancelMdfeRequestLoading}
      />
    </>
  );
};

export default CancelMdfeButton;
