import React from 'react';

import { QuotationStatus } from 'utils/enums';

import { Container, Label, Square } from './styles';

interface QuotationStatusLabelProps {
  status: QuotationStatus;
}

const QuotationStatusLabel: React.FC<QuotationStatusLabelProps> = ({
  status,
}) => {
  return (
    <Container title={status}>
      <Square status={status} />
      <Label status={status}>{status}</Label>
    </Container>
  );
};

export default QuotationStatusLabel;
