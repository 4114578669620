import { useState } from 'react';

type IGenericParams<Type extends IQueryParams> = Type;

const useQueryParams = <Type extends IQueryParams>(
  params: IGenericParams<Type>
) => {
  const [queryParams, setQueryParams] = useState<Type>(params);

  const onSearch = (value: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      search: value,
    }));
  };

  const changePage = (newPage: number) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: newPage,
    }));
  };

  const changeIsActiveStatus = (isActive: string) =>
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      isActive: isActive ?? undefined,
    }));

  return {
    queryParams,
    setQueryParams,
    onSearch,
    changePage,
    changeIsActiveStatus,
  };
};

export default useQueryParams;
