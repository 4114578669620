import { color } from 'b2utils';
import styled from 'styled-components';

import { ToggleInputStyleProps } from '..';

export const Container = styled.div<ToggleInputStyleProps>`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.four};
  border-radius: ${({ theme }) => theme.spacing.three};
  padding: ${({ theme }) => theme.spacing.four};
  color: ${({ theme }) => theme.colors.black.two};
  background-color: ${({ theme, $isChecked }) =>
    $isChecked
      ? color.addOpacityOnHexColor(theme.colors.main, 0.2)
      : theme.colors.white.two};
  transition: background-color 0.4s ease-in-out;
`;
