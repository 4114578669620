import React, { useCallback, useEffect, useRef, useState } from 'react';

import Calendar from 'components/Calendar';

import {
  CalendarFilterContainer,
  CalendarIcon,
  CloseIcon,
  SelectedDate,
  IconsContainer,
} from '../DateRangeCalendarFilter/styles';

interface MonthCalendarProps {
  placeholder?: string;
  initialDate?: Date;
  onSelectMonth: (date?: Date) => void;
  clearableDateRange?: boolean;
  className?: string;
}

const MonthCalendar: React.FC<MonthCalendarProps> = ({
  placeholder = 'Selecionar mês',
  initialDate,
  onSelectMonth,
  clearableDateRange,
  className,
}) => {
  const calendarFilterContainer = useRef<HTMLDivElement | null>(null);

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    initialDate
  );
  const [shouldRenderCalendar, setShouldRenderCalendar] = useState(false);

  const hide = useCallback((event: Event) => {
    const node = calendarFilterContainer.current;
    if (
      node &&
      event.target !== node &&
      !node.contains(event.target as HTMLElement) &&
      event.target !== calendarFilterContainer.current
    ) {
      setShouldRenderCalendar(false);
    }
  }, []);

  const handleCalendarClick = (
    event: React.MouseEvent<SVGElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setShouldRenderCalendar(!shouldRenderCalendar);
  };

  const handleClearDateRangeClick = (
    event: React.MouseEvent<SVGElement, MouseEvent>
  ) => {
    event.stopPropagation();
    onSelectMonth();
    setSelectedDate(undefined);
    setShouldRenderCalendar(false);
  };

  const handleMonthChange = (
    value: Date,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();

    const typedValue = value as Date;

    setSelectedDate(typedValue);
    onSelectMonth(typedValue);
    setShouldRenderCalendar(false);
  };

  useEffect(() => {
    if (shouldRenderCalendar) {
      document.addEventListener('click', hide);
    }

    return () => document.removeEventListener('click', hide);
  }, [hide, shouldRenderCalendar]);

  return (
    <CalendarFilterContainer
      ref={calendarFilterContainer}
      isOpen={shouldRenderCalendar}
      onClick={() => setShouldRenderCalendar(true)}
      className={className}
    >
      <SelectedDate isSelectedDate={!!selectedDate}>
        {selectedDate
          ? selectedDate.toLocaleDateString('pt-BR', {
              month: 'long',
              year: 'numeric',
            })
          : placeholder}
      </SelectedDate>
      <IconsContainer>
        <CalendarIcon onClick={(event) => handleCalendarClick(event)} />
        {clearableDateRange && (
          <CloseIcon onClick={handleClearDateRangeClick} />
        )}
      </IconsContainer>
      {shouldRenderCalendar && (
        <Calendar
          value={selectedDate}
          defaultView="year"
          onClickMonth={handleMonthChange}
          onClickYear={(_, event) => event.stopPropagation()}
          onClickDecade={(_, event) => event.stopPropagation()}
          locale="pt-br"
        />
      )}
    </CalendarFilterContainer>
  );
};

export default MonthCalendar;
