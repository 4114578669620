import React, { useCallback, useMemo, useState } from 'react';

import moment from 'moment';
import { useQueries } from 'react-query';
import { CompanyUserRole, CountMetricType } from 'utils/enums';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useMetrics } from '@hooks';

import { Header, HeaderText, MetricCard } from '../styles';
import LineChart from './LineChart';
import {
  CustomMonthCalendar,
  FilterLabel,
  MonthCalendarsContainer,
} from './styles';

type IDateAggregationChartData = {
  [key in CountMetricType]: {
    name: string;
    data: Array<{
      x: number;
      y: number;
    }>;
  };
};

const CountMetricsByDateAggregation: React.FC = () => {
  const [queryParams, setQueryParams] =
    useState<IMetricQueryParamsByDateInterval>({
      startAt: moment()
        .subtract(1, 'year')
        .startOf('month')
        .format('YYYY-MM-DD'),
      endAt: moment().format('YYYY-MM-DD'),
    });
  const [chartData, setChartData] = useState<IDateAggregationChartData>({
    [CountMetricType.QUOTATIONS]: {
      name: '',
      data: [],
    },
    [CountMetricType.SERVICES]: {
      name: '',
      data: [],
    },
    [CountMetricType.DEPARTURES]: {
      name: '',
      data: [],
    },
    [CountMetricType.INSPECTIONS]: {
      name: '',
      data: [],
    },
  });

  const {
    getQuotationsCountByDateAggregation,
    getServicesCountByDateAggregation,
    getDeparturesCountByDateAggregation,
    getInspectionsCountByDateAggregation,
  } = useMetrics();
  const { isCompanyUser, isManagementUser, userInfo } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();

  const areQueriesEnabled = !!scope || isCompanyUser;
  const isManagementWithoutScope = isManagementUser && !scope;
  const isSalesUser = isCompanyUser && userInfo?.role === CompanyUserRole.SALES;

  useQueries([
    {
      queryKey: ['quotationsCountByDateAggregation', queryParams, scope],
      enabled: areQueriesEnabled,
      queryFn: () => getQuotationsCountByDateAggregation(queryParams),
      onSuccess: ({ dates }: ICountMetricByDateAggregation) => {
        setChartData((prevChartData) => ({
          ...prevChartData,
          [CountMetricType.QUOTATIONS]: {
            name: `Número de ${CountMetricType.QUOTATIONS.toLocaleLowerCase()}`,
            data: dates.map(({ date, count }) => ({
              x: moment(date).toDate().getTime(),
              y: count,
            })),
          },
        }));
      },
      onError: (error: any) => {
        addToast(
          'Não foi possível carregar as métricas dos orçamentos por mês',
          'error'
        );
        throwToastApiErrors(error, addToast);
      },
    },
    {
      queryKey: ['servicesCountByDateAggregation', queryParams, scope],
      enabled: areQueriesEnabled,
      queryFn: () => getServicesCountByDateAggregation(queryParams),
      onSuccess: ({ dates }: ICountMetricByDateAggregation) => {
        setChartData((prevChartData) => ({
          ...prevChartData,
          [CountMetricType.SERVICES]: {
            name: `Número de ${CountMetricType.SERVICES.toLocaleLowerCase()}`,
            data: dates.map(({ date, count }) => ({
              x: moment(date).toDate().getTime(),
              y: count,
            })),
          },
        }));
      },
      onError: (error: any) => {
        addToast(
          'Não foi possível carregar as métricas dos serviços por mês',
          'error'
        );
        throwToastApiErrors(error, addToast);
      },
    },
    {
      queryKey: ['departuresCountByDateAggregation', queryParams, scope],
      enabled: areQueriesEnabled && !isSalesUser,
      queryFn: () => getDeparturesCountByDateAggregation(queryParams),
      onSuccess: ({ dates }: ICountMetricByDateAggregation) => {
        setChartData((prevChartData) => ({
          ...prevChartData,
          [CountMetricType.DEPARTURES]: {
            name: `Número de ${CountMetricType.DEPARTURES.toLocaleLowerCase()}`,
            data: dates.map(({ date, count }) => ({
              x: moment(date).toDate().getTime(),
              y: count,
            })),
          },
        }));
      },
      onError: (error: any) => {
        addToast(
          'Não foi possível carregar as métricas dos embarques por mês',
          'error'
        );
        throwToastApiErrors(error, addToast);
      },
    },
    {
      queryKey: ['inspectionsCountByDateAggregation', queryParams, scope],
      enabled: areQueriesEnabled,
      queryFn: () => getInspectionsCountByDateAggregation(queryParams),
      onSuccess: ({ dates }: ICountMetricByDateAggregation) => {
        setChartData((prevChartData) => ({
          ...prevChartData,
          [CountMetricType.INSPECTIONS]: {
            name: `Número de ${CountMetricType.INSPECTIONS.toLocaleLowerCase()}`,
            data: dates.map(({ date, count }) => ({
              x: moment(date).toDate().getTime(),
              y: count,
            })),
          },
        }));
      },
      onError: (error: any) => {
        addToast(
          'Não foi possível carregar as métricas das vistorias por mês',
          'error'
        );
        throwToastApiErrors(error, addToast);
      },
    },
  ]);

  const handleSelectStartMonth = useCallback((startDate?: Date) => {
    setQueryParams((prevState) => ({
      ...prevState,
      startAt: moment(startDate).startOf('month').format('YYYY-MM-DD'),
    }));
  }, []);

  const handleSelectEndMonth = useCallback((endDate?: Date) => {
    setQueryParams((prevState) => ({
      ...prevState,
      endAt: moment(endDate).endOf('month').format('YYYY-MM-DD'),
    }));
  }, []);

  const lineChartSeries: ApexAxisChartSeries = useMemo(() => {
    if (isManagementWithoutScope) {
      return [];
    }
    return Object.values(chartData);
  }, [chartData, isManagementWithoutScope]);

  return (
    <MetricCard>
      <Header>
        <HeaderText>Histórico</HeaderText>
        <MonthCalendarsContainer>
          <FilterLabel>De</FilterLabel>
          <CustomMonthCalendar
            initialDate={moment(queryParams.startAt).toDate()}
            onSelectMonth={handleSelectStartMonth}
          />
          <FilterLabel>até</FilterLabel>
          <CustomMonthCalendar
            initialDate={moment(queryParams.endAt).toDate()}
            onSelectMonth={handleSelectEndMonth}
          />
        </MonthCalendarsContainer>
      </Header>
      <LineChart series={lineChartSeries} />
    </MetricCard>
  );
};

export default CountMetricsByDateAggregation;
