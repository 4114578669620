import React from 'react';

import { Tooltip } from 'react-tooltip';

import { Container, CustomLink, LinkIcon, Text } from './styles';

interface ItemTextProps {
  text: string;
  itemRoutePath?: string;
}

const ItemText: React.FC<ItemTextProps> = ({ text, itemRoutePath }) => {
  return (
    <Container>
      <Text>{text}</Text>
      {itemRoutePath && (
        <CustomLink
          to={itemRoutePath}
          target="_blank"
          data-tooltip-id={text}
          data-tooltip-content="Ir para a página de detalhes"
          onClick={(event) => event.stopPropagation()}
        >
          <Tooltip id={text} />
          <LinkIcon />
        </CustomLink>
      )}
    </Container>
  );
};

export default ItemText;
