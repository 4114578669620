import React, { useRef } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useBankAccounts, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';

import RoutesPath from '@router/routes';

import BankAccountInfo from '../Form/BankAccountInfo';
import routes from './routes';

const CreateBankAccount: React.FC = () => {
  const bankAccountInfoStepRef = useRef<IFormStepRef>(null);

  const { createBankAccount } = useBankAccounts();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const createBankAccountRequest = useMutation(
    (values: IBankAccountFormValues) => createBankAccount(values),
    {
      onSuccess: () => {
        addToast('Conta bancária cadastrada com sucesso', 'success');
        navigate(RoutesPath.private.bankAccounts.listBankAccounts.path, {
          replace: true,
        });
      },
      onError: () => {
        addToast('Erro ao cadastrar conta bancária', 'error');
      },
    }
  );

  useVerifyScope({
    routePath: RoutesPath.private.bankAccounts.listBankAccounts.path,
    toastErrorMessage: 'Selecione uma empresa para editar uma conta bancária',
  });

  const steps: Array<IStep> = [
    {
      title: 'Informações da conta bancária',
      ref: bankAccountInfoStepRef,
      component: BankAccountInfo,
    },
  ];

  return (
    <BaseForm
      steps={steps}
      createRequest={createBankAccountRequest}
      routes={routes}
      title="Cadastrar conta bancária"
    />
  );
};

export default CreateBankAccount;
