import React from 'react';

import { endpoints } from '@contexts/Api';

import DocumentDownloadButton from '@components/DocumentDownloadButton';

import CancelInspectionButton from './CancelInspectionButton';
import { Container } from './styles';

interface ModalFooterProps {
  service: IService;
  onClose: () => void;
  onCancelInspectionSuccess: () => void;
}

const ModalFooter: React.FC<ModalFooterProps> = ({
  service,
  onClose,
  onCancelInspectionSuccess,
}) => {
  return (
    <Container>
      <DocumentDownloadButton
        label="Baixar relatório da vistoria"
        fileUrl={
          import.meta.env.VITE_API_BASE_URL.slice(0, -1) +
          endpoints.inspections.report.replace(
            ':inspectionCode',
            service.inspection.code
          )
        }
      />
      <CancelInspectionButton
        service={service}
        onSuccess={() => {
          onCancelInspectionSuccess();
          onClose();
        }}
      />
    </Container>
  );
};

export default ModalFooter;
