import React, { useMemo } from 'react';

import { MdfeIssuanceStatus } from 'utils/enums';

import { useAuth } from '@contexts/Auth';

import CancelMdfeButton from './CancelMdfeButton';
import DownloadsButton from './DownloadsButton';
import { Container } from './styles';

interface HeaderButtonsProps {
  mdfe?: IMdfe;
  onRequestSuccess: () => void;
}

const HOURS_AHEAD = 24;
const HOURS_AHEAD_IN_MILLISECONDS = HOURS_AHEAD * 60 * 60 * 1000;

const HeaderButtons: React.FC<HeaderButtonsProps> = ({
  mdfe,
  onRequestSuccess,
}) => {
  const { hasAdminPermission } = useAuth();

  const hasStatus = mdfe?.issuance_status === MdfeIssuanceStatus.ISSUED;

  const isAbleToCancel = useMemo(() => {
    if (!mdfe?.issued_at) {
      return false;
    }

    const issuanceDate = new Date(mdfe?.issued_at || '');
    const issuanceDateCopy = new Date(mdfe?.issued_at || '');
    const todaysDate = new Date();

    const limitDate = new Date(
      issuanceDateCopy.setTime(
        issuanceDate.getTime() + HOURS_AHEAD_IN_MILLISECONDS
      )
    );

    return todaysDate < limitDate;
  }, [mdfe?.issued_at]);

  const shouldRenderActionButtons = hasAdminPermission && hasStatus;

  if (!mdfe || !mdfe.pdf_file || !mdfe.xml_file) {
    return null;
  }

  return (
    <Container>
      {shouldRenderActionButtons && isAbleToCancel && (
        <CancelMdfeButton mdfe={mdfe} onRequestSuccess={onRequestSuccess} />
      )}
      <DownloadsButton mdfe={mdfe} />
    </Container>
  );
};

export default HeaderButtons;
