import React, { useRef } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useExpenseCategories, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';

import RoutesPath from '@router/routes';

import ExpenseCategoryForm from '../Form/ExpenseCategoryForm';
import routes from './routes';

const CreateExpenseCategory: React.FC = () => {
  const categoryFormRef = useRef<IFormStepRef>(null);

  const { createExpenseCategory } = useExpenseCategories();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const createExpenseCategoryRequest = useMutation(
    (values: IExpenseCategoryFormValues) => createExpenseCategory(values),
    {
      onSuccess: () => {
        addToast('Categoria cadastrada com sucesso', 'success');
        navigate(RoutesPath.private.categories.expenses.listCategories.path, {
          replace: true,
        });
      },
      onError: () => {
        addToast('Erro ao cadastrar categoria', 'error');
      },
    }
  );

  useVerifyScope({
    routePath: RoutesPath.private.categories.expenses.listCategories.path,
    toastErrorMessage: 'Selecione uma empresa para cadastrar uma categoria',
  });

  const steps: Array<IStep> = [
    {
      title: 'Informações da categoria de despesa',
      ref: categoryFormRef,
      component: ExpenseCategoryForm,
    },
  ];

  return (
    <BaseForm
      steps={steps}
      createRequest={createExpenseCategoryRequest}
      routes={routes}
      title="Cadastrar categoria de despesa"
    />
  );
};

export default CreateExpenseCategory;
