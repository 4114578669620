import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.departures.listDepartures.path,
    label: 'Embarques',
  },
  {
    path: RoutesPath.private.departures.createDeparture.path,
    label: 'Cadastrar embarque',
  },
];

export default routes;
