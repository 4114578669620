import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import { currency } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { CityType } from 'utils/enums';
import * as yup from 'yup';

import { useClients, useCompanyUsers, useIncomeCategories } from '@hooks';

import BaseCityForm from '@components/BaseCityForm';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import SectionHeader from '@components/SectionHeader';

import { CustomChooseItemFilter } from '@pages/Services/FormSteps/styles';
import { errors } from '@utils';

interface IncomeFormProps {
  initialValues?: IIncomeFormValues;
  onFinish: (data: IIncomeFormValues) => void;
}

const IncomeInfoForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  IncomeFormProps
> = ({ onFinish, initialValues }, ref) => {
  const { listIncomeCategories } = useIncomeCategories();
  const { listClients } = useClients();
  const { listCompanyUsers } = useCompanyUsers();

  const formikInitialValues: IIncomeFormValues = {
    date: '',
    client: null,
    personInCharge: null,
    category: null,
    vehicleName: '',
    vehicleIdentifier: '',
    value: '',
    origin: null,
    destination: null,
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    value: yup.string().trim().required(errors.required),
    date: yup.string().trim().required(errors.required),
    client: yup
      .object()
      .nullable()
      .notOneOf([null], errors.required)
      .required(errors.required),
    category: yup
      .object()
      .nullable()
      .notOneOf([null], errors.required)
      .required(errors.required),
    personInCharge: yup
      .object()
      .nullable()
      .notOneOf([null], errors.required)
      .required(errors.required),
    vehicleName: yup.string().trim().required(errors.required),
    vehicleIdentifier: yup.string().trim().required(errors.required),
    origin: yup
      .object()
      .shape({
        name: yup.string().trim().required(errors.required),
        state: yup.string().trim().required(errors.required),
      })
      .nullable()
      .notOneOf([null], errors.required),
    destination: yup
      .object()
      .shape({
        name: yup.string().trim().required(errors.required),
        state: yup.string().trim().required(errors.required),
      })
      .nullable()
      .notOneOf([null], errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
    resetForm: formik.resetForm,
  }));

  const handleSelectClient = useCallback(
    (client: IClientUser) => {
      formik.setFieldTouched('client');
      formik.setFieldValue('client', client);
    },
    [formik]
  );

  const handleSelectPersonInCharge = useCallback(
    (personInCharge: ICompanyUser) => {
      formik.setFieldTouched('personInCharge');
      formik.setFieldValue('personInCharge', personInCharge);
    },
    [formik]
  );

  const handleSelectCategory = useCallback(
    (category: IIncomeCategory) => {
      formik.setFieldTouched('category');
      formik.setFieldValue('category', category);
    },
    [formik]
  );

  return (
    <FormikProvider value={formik}>
      <SectionHeader title="Informações do recebível" />
      <FormRow>
        <FormGroup>
          <Label htmlFor="value">Valor *</Label>
          <Input
            type="text"
            id="value"
            name="value"
            placeholder="R$ 0,00"
            value={formik.values.value}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'value',
                currency.brlMask(event.target.value)
              )
            }
            invalidValue={!!formik.touched.value && !!formik.errors.value}
          />
          <FormError name="value" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="date">Data *</Label>
          <Input
            id="date"
            name="date"
            type="date"
            value={formik.values.date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalidValue={!!formik.touched.date && !!formik.errors.date}
          />
          <FormError name="date" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Cliente *</Label>
          <CustomChooseItemFilter
            filterValue={formik.values.client?.name || 'Selecionar cliente'}
            modalTitle="Selecionar cliente"
            fetch={listClients}
            queryParams={{ isActive: 'true' }}
            queryKey="clients"
            inputLabel="Selecionar cliente *"
            selectedItemLabel="Cliente selecionado"
            shouldRenderSelectedItem={!!formik.values.client}
            onSelect={(client) => handleSelectClient(client)}
            selectedItem={formik.values.client || undefined}
            renderItemText={(client) => client?.name}
            invalidValue={!!formik.touched.client && !!formik.errors.client}
          />
          <FormError name="client" />
        </FormGroup>
        <FormGroup>
          <Label>Responsável</Label>
          <CustomChooseItemFilter
            filterValue={
              formik.values.personInCharge?.name || 'Selecionar responsável'
            }
            modalTitle="Selecionar responsável"
            fetch={listCompanyUsers}
            queryParams={{ isActive: 'true', canBeInChargeOfServices: true }}
            queryKey="companyUsers"
            inputLabel="Selecionar responsável *"
            selectedItemLabel="Responsável selecionado"
            shouldRenderSelectedItem={!!formik.values.personInCharge}
            onSelect={(personInCharge) =>
              handleSelectPersonInCharge(personInCharge)
            }
            selectedItem={formik.values.personInCharge || undefined}
            renderItemText={(personInCharge) => personInCharge?.name}
            invalidValue={
              !!formik.touched.personInCharge && !!formik.errors.personInCharge
            }
          />
          <FormError name="personInCharge" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Categoria *</Label>
          <CustomChooseItemFilter
            filterValue={formik.values.category?.name || 'Selecionar categoria'}
            modalTitle="Selecionar categoria"
            fetch={listIncomeCategories}
            queryParams={{ isActive: 'true' }}
            queryKey="incomeCategories"
            inputLabel="Selecionar categoria *"
            selectedItemLabel="Categoria selecionada"
            shouldRenderSelectedItem={!!formik.values.category}
            onSelect={(category) => handleSelectCategory(category)}
            selectedItem={formik.values.category || undefined}
            renderItemText={(category) => category.name}
            invalidValue={!!formik.touched.category && !!formik.errors.category}
          />
          <FormError name="category" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="vehicleName">Veículo *</Label>
          <Input
            type="text"
            id="vehicleName"
            name="vehicleName"
            placeholder="Digite aqui"
            value={formik.values.vehicleName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={
              !!formik.touched.vehicleName && !!formik.errors.vehicleName
            }
          />
          <FormError name="vehicleName" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="vehicleIdentifier">Identificador do veículo *</Label>
          <Input
            type="text"
            id="vehicleIdentifier"
            name="vehicleIdentifier"
            placeholder="Digite aqui"
            value={formik.values.vehicleIdentifier}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={
              !!formik.touched.vehicleIdentifier &&
              !!formik.errors.vehicleIdentifier
            }
          />
          <FormError name="vehicleIdentifier" />
        </FormGroup>
      </FormRow>
      <BaseCityForm field="origin" cityType={CityType.ORIGIN} />
      <BaseCityForm field="destination" cityType={CityType.DESTINATION} />
    </FormikProvider>
  );
};

export default forwardRef(IncomeInfoForm);
