import styled from 'styled-components';

import MonthCalendar from '@components/MonthCalendar';

export const MonthCalendarsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.four};

  @media (max-width: 1160px) {
    flex-direction: column;
  }
`;

export const FilterLabel = styled.span`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const CustomMonthCalendar = styled(MonthCalendar)`
  box-shadow: none;
  margin-left: auto;
`;
