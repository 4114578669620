import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Label from '@components/Label';
import TextArea from '@components/TextArea';

import { errors } from '@utils';

type IPaymentTermFormProps = {
  initialValues?: IPaymentTermFormValues;
  onFinish: (data: IPaymentTermFormValues) => void;
};

const PaymentTermForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  IPaymentTermFormProps
> = ({ initialValues, onFinish }, ref) => {
  const formikInitialValues: IPaymentTermFormValues = {
    description: '',
    ...initialValues,
  };

  const formikValidationSchema = object().shape({
    description: string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });
  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="name">Descrição da forma de pagamento *</Label>
          <TextArea
            id="description"
            name="description"
            placeholder="Digite aqui"
            value={formik.values.description}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            maxLength={255}
            invalidValue={
              !!formik.touched.description && !!formik.errors.description
            }
          />
          <FormError name="description" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(PaymentTermForm);
