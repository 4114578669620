import React, { useEffect, useState } from 'react';

import { cpf } from 'b2utils';
import { useQuery } from 'react-query';
import { formatToBrlDate } from 'utils/formats';

import { useToast } from '@contexts/Toast';
import { useServices } from '@hooks';

import ContactItem from '@components/ContactItem';
import DocumentDownloadButton from '@components/DocumentDownloadButton';
import Loading from '@components/Loading';

import {
  ContactContainer,
  ContactGroup,
  Container,
  ReceiverName,
} from './styles';

interface ReceiptDetailProps {
  isModalOpen: boolean;
  deliveryReceiptId: string;
  hasDelivered?: boolean;
}

const ReceiptDetail: React.FC<ReceiptDetailProps> = ({
  isModalOpen,
  deliveryReceiptId,
  hasDelivered,
}) => {
  const [enableFetch, setEnableFetch] = useState(false);

  const { getDeliveryReceipt } = useServices();
  const { addToast } = useToast();

  const { data: deliveryReceipt, isLoading: isFetchLoading } = useQuery(
    ['deliveryReceipt', deliveryReceiptId],
    () => getDeliveryReceipt(deliveryReceiptId),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar os dados do termo de recebimento',
          'error'
        );
      },
      enabled: enableFetch && hasDelivered,
      retry: false,
    }
  );

  const isLoading = isFetchLoading || !deliveryReceipt;

  const deliveryReceiptAsForm =
    deliveryReceipt?.receiver_name && deliveryReceipt?.cpf;

  useEffect(() => {
    if (isModalOpen && !enableFetch) {
      setEnableFetch(true);
    }
  }, [enableFetch, isModalOpen]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <ContactContainer>
        {deliveryReceipt.receiver_name && (
          <ReceiverName>{deliveryReceipt.receiver_name}</ReceiverName>
        )}
        <ContactGroup>
          {deliveryReceipt.cpf && (
            <ContactItem label="CPF" value={cpf.mask(deliveryReceipt.cpf)} />
          )}
          <ContactItem
            label="Data da entrega do veículo"
            value={
              deliveryReceipt?.delivered_at
                ? formatToBrlDate(deliveryReceipt.delivered_at)
                : '-'
            }
          />
          <ContactItem
            label="Data de envio do termo de entrega"
            value={
              deliveryReceipt?.upload_date
                ? formatToBrlDate(deliveryReceipt.upload_date)
                : '-'
            }
          />
        </ContactGroup>
        {deliveryReceiptAsForm && (
          <ContactItem
            label="Observações"
            value={deliveryReceipt?.observations || '-'}
          />
        )}
      </ContactContainer>
      {deliveryReceipt.file?.url && (
        <DocumentDownloadButton fileUrl={deliveryReceipt.file.url} />
      )}
    </Container>
  );
};

export default ReceiptDetail;
