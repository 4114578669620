import React, { useCallback, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { currency } from 'b2utils';
import moment from 'moment';
import { B2TableRow } from 'react-b2components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Tooltip } from 'react-tooltip';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import {
  useBankAccounts,
  useNavigateWithScope,
  useQueryParams,
  useTransfers,
} from '@hooks';

import ActionIcon from '@components/ActionIcon';
import ChooseItemFilter from '@components/ChooseItemFilter';
import DeleteModal from '@components/DeleteModal';
import { TableDataCell } from '@components/Table/styles';
import TableVariant from '@components/TableVariant';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

import { ActionIconsContainer, EditIcon, TrashIcon } from '../styles';
import { FiltersContainer } from './styles';

const TransfersListing: React.FC = () => {
  const { queryParams, setQueryParams, changePage } =
    useQueryParams<ITransferQueryParams>({
      page: 1,
    });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState<ITransfer>();

  const { listTransfers, deleteTransfer } = useTransfers();
  const { listBankAccounts } = useBankAccounts();
  const { hasAdminPermission } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();
  const { navigateWithScope } = useNavigateWithScope();

  const queryClient = useQueryClient();

  const emptyBankAccount = {
    id: 0,
    name: 'Todos',
  } as IBankAccount;

  const {
    data: transfers,
    isLoading,
    refetch: refetchTransfers,
  } = useQuery(
    ['transfers', queryParams, scope],
    () => listTransfers(queryParams),
    {
      onError: () => {
        addToast('Não foi possível carregar a lista de transações', 'error');
      },
    }
  );

  const { mutate: requestDeleteTransfer, isLoading: isDeleteTransferLoading } =
    useMutation(
      (password: string) => deleteTransfer(selectedTransfer!.id, password),
      {
        onSuccess: () => {
          refetchTransfers();
          queryClient.invalidateQueries('bankAccounts');
          setIsDeleteModalOpen(false);
          addToast('Transação deletada com sucesso', 'success');
        },
        onError: (error: AxiosError) => {
          addToast('Não foi possível deletar a transação', 'error');
          throwToastApiErrors(error, addToast);
        },
      }
    );

  const handleSelectOrigin = useCallback(
    (origin: IBankAccount) => {
      setQueryParams((prevState) => ({
        ...prevState,
        page: 1,
        origin,
      }));
    },
    [setQueryParams]
  );

  const handleSelectDestination = useCallback(
    (destination: IBankAccount) => {
      setQueryParams((prevState) => ({
        ...prevState,
        page: 1,
        destination,
      }));
    },
    [setQueryParams]
  );

  const handleDeleteTransferClick = useCallback((income: ITransfer) => {
    setIsDeleteModalOpen(true);
    setSelectedTransfer(income);
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
    setSelectedTransfer(undefined);
  }, []);

  const handleDeleteTransfer = useCallback(
    (password?: string) => {
      if (password) {
        requestDeleteTransfer(password);
      }
    },
    [requestDeleteTransfer]
  );

  const headerData = useMemo(() => {
    const baseHeaderData = ['Data', 'Tipo', 'Origem', 'Destino', 'Valor'];

    if (hasAdminPermission) {
      return [...baseHeaderData, 'Ações'];
    }

    return baseHeaderData;
  }, [hasAdminPermission]);

  const getTransferDescription = useCallback((transfer: ITransfer) => {
    if (transfer.is_financial_support) {
      return `Deseja realmente deletar o aporte ${transfer.title} para ${
        transfer.destination.name
      } no valor de ${currency.centsToBrl(transfer.value)}?`;
    }

    return `Deseja realmente deletar a transferência de ${
      transfer.origin?.name
    } para ${transfer.destination.name} no valor de ${currency.centsToBrl(
      transfer.value
    )}`;
  }, []);

  return (
    <>
      <FiltersContainer>
        <ChooseItemFilter
          filterValue={
            queryParams?.origin?.id && queryParams?.origin?.id !== 0
              ? queryParams?.origin?.name
              : 'Banco de origem: todos'
          }
          modalTitle="Filtrar transações por banco de origem"
          fetch={listBankAccounts}
          queryParams={{ isActive: 'true' }}
          queryKey="originBankAccounts"
          inputLabel="Selecionar banco de origem *"
          selectedItemLabel="Banco de origem selecionado"
          shouldRenderSelectedItem
          onSelect={handleSelectOrigin}
          selectedItem={queryParams.origin || emptyBankAccount}
          additionalItem={emptyBankAccount}
          renderItemText={(origin) => origin.name}
        />
        <ChooseItemFilter
          filterValue={
            queryParams?.destination?.id && queryParams?.destination?.id !== 0
              ? queryParams?.destination?.name
              : 'Banco de destino: todos'
          }
          modalTitle="Filtrar transações por banco de destino"
          fetch={listBankAccounts}
          queryParams={{ isActive: 'true' }}
          queryKey="destinationBankAccounts"
          inputLabel="Selecionar banco de destino *"
          selectedItemLabel="Banco de destino selecionado"
          shouldRenderSelectedItem
          onSelect={handleSelectDestination}
          selectedItem={queryParams.destination || emptyBankAccount}
          additionalItem={emptyBankAccount}
          renderItemText={(destination) => destination.name}
        />
      </FiltersContainer>
      <Tooltip id="transfers-list-tooltip" />
      <TableVariant
        data={transfers?.results || []}
        isLoading={isLoading}
        headerData={headerData}
        emptyMessage="Nenhuma transação encontrada"
        renderRow={(transfer) => (
          <B2TableRow key={transfer.id}>
            <TableDataCell>{moment(transfer.date).format('L')}</TableDataCell>
            <TableDataCell>
              {transfer.is_financial_support
                ? enums.FinancialType.CAPITAL_INJECTION
                : enums.FinancialType.TRANSFER}
            </TableDataCell>
            <TableDataCell>
              {transfer.origin?.name || transfer.title}
            </TableDataCell>
            <TableDataCell>{transfer.destination.name}</TableDataCell>
            <TableDataCell>{currency.centsToBrl(transfer.value)}</TableDataCell>
            {hasAdminPermission && (
              <TableDataCell>
                <ActionIconsContainer>
                  <ActionIcon
                    tooltipId="transfers-list-tooltip"
                    icon={EditIcon}
                    tooltipMessage={`Editar ${
                      transfer.is_financial_support ? 'aporte' : 'transferência'
                    }`}
                    onClick={() =>
                      navigateWithScope({
                        routePath:
                          RoutesPath.private.financial.transfers.updateTransfer.path.replace(
                            ':transferId',
                            transfer.id.toString()
                          ),
                        company: transfer.company,
                      })
                    }
                  />
                  <ActionIcon
                    tooltipId="transfers-list-tooltip"
                    icon={TrashIcon}
                    tooltipMessage={`Deletar ${
                      transfer.is_financial_support ? 'aporte' : 'transferência'
                    }`}
                    onClick={() => handleDeleteTransferClick(transfer)}
                  />
                </ActionIconsContainer>
              </TableDataCell>
            )}
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={transfers?.count}
        changePage={changePage}
        hasClick={false}
      />
      {isDeleteModalOpen && selectedTransfer && (
        <DeleteModal
          title={getTransferDescription(selectedTransfer)}
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          onConfirm={handleDeleteTransfer}
          isLoading={isDeleteTransferLoading}
          passwordRequired
        />
      )}
    </>
  );
};

export default TransfersListing;
