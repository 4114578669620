import RoutesPath from '@router/routes';

const routes = () => {
  return [
    {
      label: 'Empresas',
      path: RoutesPath.private.companies.listCompanies.path,
    },
    {
      label: 'Adicionar empresa',
      path: RoutesPath.private.companies.createCompany.path,
    },
  ];
};

export default routes;
