import styled from 'styled-components';
import { ServiceStatus } from 'utils/enums';
import { serviceStatusProps } from 'utils/helpers';

interface CommonStyleProps {
  status: ServiceStatus;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.spacing.three};
  background-color: ${({ theme }) => theme.colors.white.main};
  overflow: hidden;
`;

export const Header = styled.div<CommonStyleProps>`
  display: flex;
  align-items: center;
  background-color: white;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) => theme.spacing.four};
  border-top-right-radius: ${({ theme }) => theme.spacing.three};
  border-top-left-radius: ${({ theme }) => theme.spacing.three};
  border-bottom: 2.5px solid
    ${({ theme, status }) => serviceStatusProps[status].color(theme)};
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const Circle = styled.div<CommonStyleProps>`
  border-radius: 50%;
  width: 0.375rem;
  height: 0.375rem;
  background-color: ${({ theme, status }) =>
    serviceStatusProps[status].color(theme)};
`;

export const ServicesCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: ${({ theme }) => theme.spacing.one};
  padding: ${({ theme }) => theme.spacing.one};
  width: ${({ theme }) => theme.spacing.six};
  height: ${({ theme }) => theme.spacing.six};
  color: ${({ theme }) => theme.colors.gray.eleven};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  background-color: ${({ theme }) => theme.colors.gray.one};
`;

export const ServicesList = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) => theme.spacing.four};
`;
