import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.financial.transfers.listTransfers.path,
    label: 'Financeiro',
  },
  {
    path: RoutesPath.private.financial.transfers.updateTransfer.path,
    label: 'Editar transação',
  },
];

export default routes;
