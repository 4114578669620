import React, { useMemo } from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import { useAuth } from '@contexts/Auth';

import PrivateBase from '@components/Base/PrivateBase';
import DigitalCertificateExpirationToast from '@components/DigitalCertificateExpirationToast';

import RoutesPath from '@router/routes';

import pages from './pages';

const PrivateRoutes: React.FC = () => {
  const { userInfo } = useAuth();

  const filteredPages = useMemo(
    () => pages.filter((page) => page.route.userType.includes(userInfo!.type)),
    [userInfo]
  );

  return (
    <PrivateBase>
      <Routes>
        {filteredPages.map((page) => (
          <Route
            key={page.route.path}
            path={page.route.path}
            element={<page.component />}
          />
        ))}
        <Route
          path="*"
          element={<Navigate to={RoutesPath.private.home.path} replace />}
        />
      </Routes>
      <DigitalCertificateExpirationToast />
    </PrivateBase>
  );
};

export default PrivateRoutes;
