import React, { forwardRef, useImperativeHandle } from 'react';

import { currency } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { MAX_VALUE } from 'utils/constants';
import { ServiceType, VehicleDocumentType, VehicleType } from 'utils/enums';
import { isCurrencyFieldValueBetween } from 'utils/helpers';
import regex from 'utils/regex';
import * as yup from 'yup';

import {
  InputGroup,
  RadioButtonsContainer,
} from '@components/Base/PrivateBase/styles';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';

import { errors } from '@utils';

interface ServiceVehicleProps {
  initialValues?: IServiceVehicle;
  onFinish: (data: IServiceVehicle) => void;
}

const ServiceVehicle: React.ForwardRefRenderFunction<
  IFormStepRef,
  ServiceVehicleProps
> = ({ onFinish, initialValues }, ref) => {
  const isYearFieldValueValid = (value?: string) => {
    const onlyNumbers = value?.replace(regex.onlyNumbers, '');
    const isFieldEmpty = !onlyNumbers?.length;
    const valueAsNumber = Number(onlyNumbers);

    if (isFieldEmpty || valueAsNumber < 1500 || valueAsNumber > 3000) {
      return false;
    }

    return true;
  };

  const vehicleTypes = Object.values(VehicleType);
  const vehicleDocumentTypes = Object.values(VehicleDocumentType);

  const formikInitialValues: IServiceVehicle = {
    vehicleType: VehicleType.CAR,
    model: '',
    identifier: '',
    documentType: VehicleDocumentType.CRLV,
    documentTypeValue: '',
    document: '',
    year: '',
    insuranceValue: '',
    type: '',
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    vehicleType: yup.string().trim().required(errors.required),
    model: yup.string().trim().required(errors.required),
    identifier: yup.string().trim().required(errors.required),
    documentType: yup
      .string()
      .trim()
      .when('type', {
        is: ServiceType.OWN,
        then: (schema) => schema.required(errors.required),
      }),
    documentTypeValue: yup
      .string()
      .nullable()
      .trim()
      .when('documentType', {
        is:
          VehicleDocumentType.ANOTHER &&
          formikInitialValues.type === ServiceType.OWN,
        then: (schema) => schema.required(errors.required),
      }),
    document: yup
      .string()
      .trim()
      .when('type', {
        is: ServiceType.OWN,
        then: (schema) => schema.required(errors.required),
      }),
    year: yup
      .string()
      .trim()
      .when('type', {
        is: ServiceType.OWN,
        then: (schema) =>
          schema
            .test(
              'is-year-value-valid',
              errors.invalidYear,
              isYearFieldValueValid
            )
            .required(errors.required),
      }),
    insuranceValue: yup
      .string()
      .trim()
      .when('type', {
        is: (type: ServiceType) => type !== ServiceType.STORAGE,
        then: (schema) =>
          schema
            .test(
              'is-insurance-value-valid',
              errors.currencyBetween(0, MAX_VALUE),
              (value) =>
                isCurrencyFieldValueBetween({
                  value,
                  minValue: 0,
                  maxValue: MAX_VALUE,
                  required: true,
                })
            )
            .required(errors.required),
      }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="vehicleType">Tipo do veículo *</Label>
          <RadioButtonsContainer>
            {vehicleTypes.map((type) => (
              <InputGroup key={type}>
                <Input
                  type="radio"
                  id={`vehicleType${type}`}
                  name="vehicleType"
                  checked={formik.values.vehicleType === type}
                  onChange={() => formik.setFieldValue('vehicleType', type)}
                  onBlur={formik.handleBlur}
                />
                <Label htmlFor={`vehicleType${type}`}>{type}</Label>
              </InputGroup>
            ))}
          </RadioButtonsContainer>
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="model">Veículo *</Label>
          <Input
            type="text"
            id="model"
            name="model"
            placeholder="Digite aqui a marca e modelo do veículo"
            value={formik.values.model}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.touched.model && !!formik.errors.model}
          />
          <FormError name="model" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="identifier">Identificador do veículo *</Label>
          <Input
            type="text"
            id="identifier"
            name="identifier"
            placeholder="Digite aqui o identificador do chassi, placa ou outro"
            value={formik.values.identifier}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={
              !!formik.touched.identifier && !!formik.errors.identifier
            }
          />
          <FormError name="identifier" />
        </FormGroup>
      </FormRow>
      {formikInitialValues.type === ServiceType.OWN && (
        <>
          <FormRow>
            <FormGroup>
              <Label htmlFor="documentType">Tipo do documento *</Label>
              <RadioButtonsContainer>
                {vehicleDocumentTypes.map((type) => (
                  <InputGroup key={type}>
                    <Input
                      type="radio"
                      id={`documentType${type}`}
                      name="documentType"
                      checked={formik.values.documentType === type}
                      onChange={() =>
                        formik.setFieldValue('documentType', type)
                      }
                      onBlur={formik.handleBlur}
                    />
                    <Label htmlFor={`documentType${type}`}>{type}</Label>
                  </InputGroup>
                ))}
              </RadioButtonsContainer>
              {formik.values.documentType === VehicleDocumentType.ANOTHER && (
                <>
                  <Input
                    type="text"
                    id="documentTypeValue"
                    name="documentTypeValue"
                    placeholder="Digite aqui o tipo do documento do veículo"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.documentTypeValue}
                    invalidValue={
                      !!formik.touched.documentTypeValue &&
                      !!formik.errors.documentTypeValue
                    }
                  />
                  <FormError name="documentTypeValue" />
                </>
              )}
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormGroup>
              <Label htmlFor="document">
                {formik.values.documentType === VehicleDocumentType.NFE
                  ? 'Chave de acesso *'
                  : 'Número do documento *'}
              </Label>
              <Input
                type="text"
                id="document"
                name="document"
                placeholder="Digite aqui o número do documento"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.document}
                invalidValue={
                  !!formik.touched.document && !!formik.errors.document
                }
              />
              <FormError name="document" />
            </FormGroup>
          </FormRow>
        </>
      )}
      <FormRow>
        {formikInitialValues.type === ServiceType.OWN && (
          <FormGroup>
            <Label htmlFor="year">Ano do veículo *</Label>
            <Input
              type="text"
              id="year"
              name="year"
              placeholder="Digite aqui o ano do veículo"
              value={formik.values.year}
              onBlur={formik.handleBlur}
              onChange={(event) =>
                formik.setFieldValue(
                  'year',
                  event.target.value.replace(regex.onlyNumbers, '')
                )
              }
              invalidValue={!!formik.touched.year && !!formik.errors.year}
            />
            <FormError name="year" />
          </FormGroup>
        )}
        <FormGroup>
          <Label htmlFor="insuranceValue">
            Valor do seguro {formik.values.type !== ServiceType.STORAGE && '*'}{' '}
          </Label>
          <Input
            type="text"
            id="insuranceValue"
            name="insuranceValue"
            placeholder="R$ 0,00"
            value={formik.values.insuranceValue}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'insuranceValue',
                currency.brlMask(event.target.value)
              )
            }
            invalidValue={
              !!formik.touched.insuranceValue && !!formik.errors.insuranceValue
            }
          />
          <FormError name="insuranceValue" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(ServiceVehicle);
