import React from 'react';

import { ArrowIcon, Container, Group, HorizontalLine, Label } from './styles';

interface HorizontalFilterLineProps {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

const HorizontalFilterLine: React.FC<HorizontalFilterLineProps> = ({
  isExpanded,
  setIsExpanded,
}) => {
  return (
    <Container>
      <Group onClick={() => setIsExpanded(!isExpanded)}>
        <ArrowIcon $expanded={isExpanded} />
        <Label>Ver mais filtros</Label>
      </Group>
      <HorizontalLine />
    </Container>
  );
};

export default HorizontalFilterLine;
