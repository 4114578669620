import { Car, Edit2, EmptyWallet, EmptyWalletTick, Trash } from 'iconsax-react';
import { B2Button } from 'react-b2components';
import styled, { css } from 'styled-components';
import { PaymentStatus } from 'utils/enums';
import { paymentStatusProps } from 'utils/helpers';
import { addSpacing, modifyColor } from 'utils/styles';

const IconStyle = css`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const CustomButton = styled(B2Button)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.one};

  svg {
    flex-shrink: 0;
    width: ${({ theme }) => theme.spacing.five};
    height: ${({ theme }) => theme.spacing.five};
  }
`;

export const ReportButton = styled(CustomButton)`
  gap: ${({ theme }) => theme.spacing.four};
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: ${({ theme }) => theme.colors.white.main};
  box-shadow: 6px 3px 24px ${({ theme }) => theme.colors.gray.two};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.four)};
  white-space: nowrap;

  svg {
    color: ${({ theme }) => theme.colors.black.two};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.three};
`;

export const ActionIconsContainer = styled.div`
  display: flex;
  align-self: center;
  gap: ${({ theme }) => theme.spacing.four};
`;

export const WalletTickIcon = styled(EmptyWalletTick)`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};
  color: ${({ theme }) => theme.colors.success};
`;

export const CarIcon = styled(Car)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.gray.ten};
`;

export const TrashIcon = styled(Trash)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.error};
`;

export const EditIcon = styled(Edit2)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.info};
`;

export const EmptyWalletIcon = styled(EmptyWallet)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.success};
`;

export const PaymentStatusLabel = styled.span<{ status: PaymentStatus }>`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme, status }) => paymentStatusProps[status].color(theme)};
`;

export const PaymentCard = styled.div`
  display: flex;
  justify-content: flex-end;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) =>
    modifyColor({ hex: theme.colors.main, lightness: 0.93 })};
  padding: ${({ theme }) => addSpacing(theme.spacing.four, theme.spacing.six)};
  margin-bottom: ${({ theme }) => theme.spacing.four};
  animation: fade-in 0.5s ease-out;
`;

export const FormContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.eight, theme.spacing.five)};
`;

export const FormErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
