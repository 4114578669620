import React, { useState } from 'react';

import Loading from '@components/Loading';

import {
  Content,
  CarouselContainer,
  PreviewContainer,
  PreviewGrid,
} from '../../styles';
import ModalContent from '../ModalContent';
import PreviewImages from '../PreviewImages';
import ColorLabels from './ColorLabels';
import { HeaderButton, HeaderContainer } from './styles';

interface InspectionByViewProps {
  vehicleViews: IVehicleView[];
  inspectionImages: IInspectionImage[];
  infiniteQueryProps: InfiniteQueryProps;
}

const InspectionByView: React.FC<InspectionByViewProps> = ({
  vehicleViews,
  inspectionImages,
  infiniteQueryProps,
}) => {
  const [carouselPosition, setCarouselPosition] = useState(0);

  const handleVehicleViewClick = (vehicleViewId: number) => {
    const vehicleViewIndex = inspectionImages.findIndex(
      (inspectionImage) => inspectionImage.vehicle_view.id === vehicleViewId
    );

    if (vehicleViewIndex === -1) {
      infiniteQueryProps.fetchNextPage();
    } else {
      setCarouselPosition(vehicleViewIndex);
    }
  };

  return (
    <>
      <HeaderContainer>
        {vehicleViews.map((vehicleView) => (
          <HeaderButton
            key={vehicleView.id}
            isSelected={
              !!inspectionImages.length &&
              inspectionImages[carouselPosition].vehicle_view.id ===
                vehicleView.id
            }
            onClick={() => {
              handleVehicleViewClick(vehicleView.id);
            }}
          >
            {vehicleView.name}
          </HeaderButton>
        ))}
      </HeaderContainer>
      <CarouselContainer>
        <Content>
          <ModalContent
            carouselPosition={carouselPosition}
            setCarouselPosition={setCarouselPosition}
            inspectionImages={inspectionImages || []}
            infiniteQueryProps={infiniteQueryProps}
          />
        </Content>
      </CarouselContainer>
      <PreviewGrid>
        <PreviewContainer>
          {inspectionImages.map((imageView, index) => (
            <PreviewImages
              key={imageView.id}
              image={imageView.image}
              isSelected={index === carouselPosition}
              onImageClick={() => setCarouselPosition(index)}
            />
          ))}
        </PreviewContainer>
        {infiniteQueryProps.isFetchingNextPage && <Loading />}
      </PreviewGrid>
      <ColorLabels />
    </>
  );
};

export default InspectionByView;
