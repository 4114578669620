import React, { useCallback, useState } from 'react';

import { DocumentDownload } from 'iconsax-react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';

import DashboardListingWrapper from '@components/DashboardListingWrapper';
import MonthCalendar from '@components/MonthCalendar';
import PageHeader from '@components/PageHeader';
import Tabs from '@components/Tabs';

import RoutesPath from '@router/routes';

import Dashboard from './Dashboard';
import FinancialReportModal from './FinancialReportModal';
import ExpensesListing from './Tabs/ExpensesListing';
import IncomesListing from './Tabs/IncomesListing';
import TransfersListing from './Tabs/TransfersListing';
import { Content, HeaderButtonsContainer, ReportButton } from './styles';

const FinancialDashboard: React.FC = () => {
  const [queryParams, setQueryParams] = useState<IMetricQueryParamsByYearMonth>(
    {
      yearMonth: moment().format('YYYY-MM'),
    }
  );
  const [isReportModalOPen, setIsReportModalOpen] = useState(false);

  const { isManagementUser } = useAuth();
  const { scope } = useScope();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const navigateToCreateFinancialTransaction = () => {
    if (isManagementUser && !scope) {
      addToast(
        'Não é possível cadastrar uma movimentação sem estar com uma empresa selecionada',
        'error'
      );
      return;
    }
    navigate(
      RoutesPath.private.financial.financialTransactions
        .createFinancialTransaction.path
    );
  };

  const handleMonthChange = useCallback((date?: Date) => {
    if (!date) {
      return;
    }
    setQueryParams({
      yearMonth: moment(date).format('YYYY-MM'),
    });
  }, []);

  const openReportModalForm = () => {
    if (isManagementUser && !scope) {
      addToast(
        'Não é possível gerar um relatório sem estar com uma empresa selecionada',
        'error'
      );
      return;
    }
    setIsReportModalOpen(true);
  };

  return (
    <>
      <PageHeader title="Financeiro" subtitle="Resumo financeiro do sistema">
        <HeaderButtonsContainer>
          <ReportButton variant="transparent" onClick={openReportModalForm}>
            Gerar relatório
            <DocumentDownload />
          </ReportButton>
          <MonthCalendar
            initialDate={new Date()}
            onSelectMonth={handleMonthChange}
          />
        </HeaderButtonsContainer>
      </PageHeader>
      <Content>
        <Dashboard queryParams={queryParams} />
        <DashboardListingWrapper
          title="Movimentações"
          buttonProps={{
            text: 'Adicionar movimentação',
            onClick: navigateToCreateFinancialTransaction,
          }}
        >
          <Tabs
            tabs={[
              {
                title: 'Recebíveis',
                component: <IncomesListing yearMonth={queryParams.yearMonth} />,
                path: RoutesPath.private.financial.incomes.listIncomes.path,
              },
              {
                title: 'Despesas',
                component: (
                  <ExpensesListing yearMonth={queryParams.yearMonth} />
                ),
                path: RoutesPath.private.financial.expenses.listExpenses.path,
              },
              {
                title: 'Transações',
                component: <TransfersListing />,
                path: RoutesPath.private.financial.transfers.listTransfers.path,
              },
            ]}
          />
        </DashboardListingWrapper>
        <FinancialReportModal
          isOpen={isReportModalOPen}
          onClose={() => setIsReportModalOpen(false)}
        />
      </Content>
    </>
  );
};

export default FinancialDashboard;
