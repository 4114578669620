import { color } from 'b2utils';
import { TickCircle } from 'iconsax-react';
import { B2Card } from 'react-b2components';
import styled from 'styled-components';
import { DepartureStatus } from 'utils/enums';
import { departureStatusProps } from 'utils/helpers';
import { addSpacing } from 'utils/styles';

import DepartureActionIcons from '@components/DepartureActionIcons';

interface StatusLabelProps {
  status: DepartureStatus;
}

export const Container = styled(B2Card)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.eight};

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray.one};
  margin-left: ${({ theme }) => theme.spacing.five};

  @media (max-width: 1024px) {
    width: 100%;
    height: 1px;
    margin-left: 0;
    margin-right: ${({ theme }) => theme.spacing.five};
  }
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.four};

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const CustomDepartureActionIcons = styled(DepartureActionIcons)`
  gap: ${({ theme }) => theme.spacing.four};
`;

export const StatusGroup = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.five};

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const StatusLabel = styled.div<StatusLabelProps>`
  display: flex;
  align-items: center;
  border-radius: 12px;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) => addSpacing(theme.spacing.two, theme.spacing.four)};
  color: ${({ theme }) => theme.colors.white.main};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme, status }) => departureStatusProps[status].color(theme)};
  background-color: ${({ theme, status }) =>
    color.addOpacityOnHexColor(departureStatusProps[status].color(theme), 0.2)};
`;

export const TickCircleIcon = styled(TickCircle)`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.seven};
  height: ${({ theme }) => theme.spacing.seven};
  color: ${({ theme }) => theme.colors.success};
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`;
