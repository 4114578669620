import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) =>
    addSpacing('0', theme.spacing.eight, theme.spacing.four)};
`;
