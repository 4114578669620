import React, { useCallback } from 'react';

import { useInfiniteQuery } from 'react-query';
import { getNextPageParam } from 'utils/helpers';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useServices } from '@hooks';

import EmptyList from '@components/EmptyList';
import Loading from '@components/Loading';

import ServiceCard from './ServiceCard';
import {
  Circle,
  Container,
  Header,
  ServicesCount,
  ServicesList,
  Title,
} from './styles';

interface ServiceStatusColumnProps {
  queryParams: IServicesQueryParams;
}

const ServiceStatusColumn: React.FC<ServiceStatusColumnProps> = ({
  queryParams,
}) => {
  const { listServices } = useServices();
  const { scope } = useScope();
  const { addToast } = useToast();

  const paramsWithoutPage = { ...queryParams, page: undefined };

  const {
    data: services,
    fetchNextPage,
    isLoading,
    isRefetching,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['services', paramsWithoutPage, scope],
    ({ pageParam: page = 1 }) => listServices({ ...paramsWithoutPage, page }),
    {
      onError: () => {
        addToast(
          `Não foi possível carregar a lista de serviços com status ${queryParams.status}`,
          'error'
        );
      },
      getNextPageParam,
    }
  );

  const isDataLoading = isLoading || isRefetching || isFetchingNextPage;

  const servicesList = services?.pages?.flatMap((page) => page.results) || [];

  const isEmptyList = servicesList.length === 0;

  const onScroll = useCallback(
    (event: any) => {
      const element = event.target;
      if (
        !isDataLoading &&
        element.offsetHeight + element.scrollTop >= element.scrollHeight
      ) {
        fetchNextPage();
      }
    },
    [fetchNextPage, isDataLoading]
  );

  if (!queryParams.status) {
    return null;
  }

  return (
    <Container>
      <Header status={queryParams.status}>
        <Circle status={queryParams.status} />
        <Title>{queryParams.status}</Title>
        <ServicesCount>{services?.pages?.[0]?.count || 0}</ServicesCount>
      </Header>
      <ServicesList onScroll={onScroll}>
        {!isDataLoading && isEmptyList && (
          <EmptyList>Nenhum serviço encontrado</EmptyList>
        )}
        {servicesList.map((service) => (
          <ServiceCard
            key={service.id}
            service={service}
            queryParams={queryParams}
          />
        ))}
        <div>{isDataLoading && <Loading />}</div>
      </ServicesList>
    </Container>
  );
};

export default ServiceStatusColumn;
