import React from 'react';

import { UserType } from 'utils/enums';

import { useAuth } from '@contexts/Auth';

import AddressesList from '@components/AddressesList';
import { BaseContainer } from '@components/Base/PrivateBase/styles';
import SectionHeader from '@components/SectionHeader';

import { Content } from './styles';

const Addresses: React.FC = () => {
  const { userInfo } = useAuth();

  if (userInfo?.type !== UserType.CLIENT) {
    return null;
  }

  return (
    <BaseContainer>
      <Content>
        <SectionHeader title="Meus endereços" />
        <AddressesList client={userInfo} />
      </Content>
    </BaseContainer>
  );
};

export default Addresses;
