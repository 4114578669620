import { color } from 'b2utils';
import { B2Table, B2TableDataCell, IB2Table } from 'react-b2components';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

interface ICustomTable {
  hasClick: boolean;
  hasPagination: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.white.one};
  box-shadow: 6px 3px 24px ${({ theme }) => theme.colors.gray.two};
  animation: show-rightwards 0.5s ease-out, fade-in 0.5s ease-out;
`;

export const CustomTable = styled(B2Table)<ICustomTable>`
  color: ${({ theme }) => theme.colors.gray.ten};
  overflow-x: auto;

  table {
    border-collapse: collapse;
    font-size: ${({ theme }) => theme.fontSize.small};
  }

  tbody {
    animation: none;
  }

  thead,
  tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.one};
  }

  tr:last-child {
    border-bottom: ${({ hasPagination }) => !hasPagination && 'none'};
  }

  tbody > tr {
    cursor: ${({ hasClick }) => (hasClick ? 'pointer' : 'auto')};
  }

  tbody > tr:hover {
    background-color: ${({ theme, hasClick }) =>
      hasClick && theme.colors.white.three};
  }

  tr > td,
  th > div {
    padding: ${({ theme }) =>
      addSpacing(
        theme.spacing.three,
        '0',
        theme.spacing.three,
        theme.spacing.eight
      )};
  }

  tr > td:last-child,
  th > div:last-child {
    padding-right: ${({ theme }) => theme.spacing.eight};
  }
` as <Type>(props: IB2Table<Type> & ICustomTable) => React.ReactElement;

export const TableLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableDataCell = styled(B2TableDataCell)`
  max-width: 200px;
  width: max-content;
  overflow-wrap: break-word;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.three};
  padding: ${({ theme }) => addSpacing(theme.spacing.two, theme.spacing.eight)};
  font-size: ${({ theme }) => theme.fontSize.small};

  span {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    color: ${({ theme }) => theme.colors.gray.ten};
  }

  button {
    border: 0;
    margin: 0;
    background-color: transparent;
    width: ${({ theme }) => theme.spacing.nine};
    height: ${({ theme }) => theme.spacing.nine};
    color: ${({ theme }) => theme.colors.gray.six};
    transition: all 0.25s ease-in-out;

    &:active {
      transform: scale(0.95);
    }

    &:hover {
      opacity: 0.6;
      border: 1px solid ${({ theme }) => theme.colors.gray.six};
    }

    &:disabled {
      border: 0;
      opacity: 1;
      color: ${({ theme }) => theme.colors.main};
      background-color: ${({ theme }) =>
        color.addOpacityOnHexColor(theme.colors.main, 0.1)};

      &:active {
        transform: scale(1);
      }
    }
  }

  @media (max-width: 425px) {
    flex-direction: column-reverse;
  }
`;
