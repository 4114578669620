import React from 'react';

import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useManagementUsers } from '@hooks';

import Loading from '@components/Loading';
import PageHeader from '@components/PageHeader';
import UserDetail from '@components/UserDetail';

import RoutesPath from '@router/routes';

import getRoutes from './routes';

const DetailManagementUser: React.FC = () => {
  const { managementUserId } = useParams<{ managementUserId: string }>();

  const { getManagementUser, updateIsActiveManagementStatus } =
    useManagementUsers();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const {
    data: managementUser,
    refetch: refetchManagementUser,
    isLoading,
  } = useQuery(
    ['managementUser', managementUserId],
    () => getManagementUser(managementUserId!),
    {
      onError() {
        addToast('Não foi possível carregar os dados do usuário', 'error');
        navigate(RoutesPath.private.managementUsers.listManagementUsers.path, {
          replace: true,
        });
      },
    }
  );

  const { mutate: requestUpdateIsActiveCategoryStatus } = useMutation(
    () =>
      updateIsActiveManagementStatus(
        managementUserId!,
        !managementUser?.is_active
      ),
    {
      onSuccess: () => {
        addToast('Situação do usuário atualizada com sucesso', 'success');
        refetchManagementUser();
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível alterar a situação do usuário', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  return (
    <>
      <PageHeader
        title="Detalhes do usuário"
        routes={getRoutes(managementUserId!)}
        editButtonProps={{
          onClick: () =>
            navigate(
              RoutesPath.private.managementUsers.updateManagementUser.path.replace(
                ':managementUserId',
                managementUserId!
              )
            ),
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <UserDetail
          user={managementUser!}
          handleChangeUserSituation={requestUpdateIsActiveCategoryStatus}
        />
      )}
    </>
  );
};

export default DetailManagementUser;
