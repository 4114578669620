import React, { useCallback, useState } from 'react';

import { AxiosError } from 'axios';
import { currency } from 'b2utils';
import { B2TableRow } from 'react-b2components';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { formatDate } from 'utils/formats';
import { throwToastApiErrors } from 'utils/helpers';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useIncomePayments, useQueryParams } from '@hooks';

import BasePaymentListingFilters from '@components/BasePaymentListingFilters';
import CommonCard from '@components/CommonCard';
import DeleteModal from '@components/DeleteModal';
import { TableDataCell } from '@components/Table/styles';
import TableVariant from '@components/TableVariant';

import { PaymentDescription, TrashIcon } from './styles';

const IncomesPaymentsList: React.FC = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedIncomesPayment, setSelectedIncomesPayment] =
    useState<IIncomePayment>();

  const { clientId } = useParams<{ clientId: string }>();
  const { queryParams, setQueryParams, onSearch, changePage } =
    useQueryParams<IIncomePaymentsQueryParams>({
      page: 1,
      client: Number(clientId),
    });

  const { listIncomePayments, deleteIncomesPayment } = useIncomePayments();
  const { scope } = useScope();
  const { addToast } = useToast();

  const {
    data: incomePayments,
    refetch: refetchIncomePayments,
    isLoading,
  } = useQuery(
    ['incomePayments', queryParams, scope],
    () => listIncomePayments(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista de pagamentos de recebíveis',
          'error'
        );
      },
      enabled: !!clientId,
    }
  );

  const {
    mutate: requestDeleteIncomesPayment,
    isLoading: isDeleteIncomesPaymentLoading,
  } = useMutation(
    (password: string) =>
      deleteIncomesPayment(selectedIncomesPayment!.id, password),
    {
      onSuccess: () => {
        refetchIncomePayments();
        setIsDeleteModalOpen(false);
        addToast('Pagamento deletado com sucesso', 'success');
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível deletar o pagamento', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const handleDeleteIncomesPaymentClick = useCallback(
    (incomesPayment: IIncomePayment) => {
      setIsDeleteModalOpen(true);
      setSelectedIncomesPayment(incomesPayment);
    },
    []
  );

  const handleDeleteIncomesPayment = useCallback(
    (password?: string) => {
      if (password) {
        requestDeleteIncomesPayment(password);
      }
    },
    [requestDeleteIncomesPayment]
  );

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
    setSelectedIncomesPayment(undefined);
  }, []);

  const getPaymentDescription = useCallback((payment: IIncomePayment) => {
    return payment.incomes
      .map(
        (paymentToIncomes) =>
          `${currency.centsToBrl(paymentToIncomes.value)} destinado o serviço ${
            paymentToIncomes.description
          }`
      )
      .join('\n\n');
  }, []);

  return (
    <CommonCard title="Listas de pagamentos de recebíveis">
      <Tooltip id="income-payments-list-tooltip" />
      <BasePaymentListingFilters
        searchPlaceholder="Buscar por descrição"
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onSearch={onSearch}
      />
      <TableVariant
        data={incomePayments?.results || []}
        isLoading={isLoading}
        headerData={[
          'Competência',
          'Método de pagamento',
          'Conta bancária',
          'Valor pago',
          'Descrição',
          'Ação',
        ]}
        emptyMessage="Nenhum pagamento encontrado"
        renderRow={(incomePayment) => (
          <B2TableRow key={incomePayment.id}>
            <TableDataCell>
              {formatDate(incomePayment.accrual_date)}
            </TableDataCell>
            <TableDataCell>{incomePayment.payment_method.name}</TableDataCell>
            <TableDataCell>{incomePayment.bank_account.name}</TableDataCell>
            <TableDataCell>
              {currency.brlMask(incomePayment.value.toString())}
            </TableDataCell>
            <TableDataCell>
              <PaymentDescription>
                {getPaymentDescription(incomePayment)}
              </PaymentDescription>
            </TableDataCell>
            <TableDataCell>
              <TrashIcon
                data-tooltip-id="income-payments-list-tooltip"
                data-tooltip-content="Deletar pagamento"
                onClick={() => handleDeleteIncomesPaymentClick(incomePayment)}
              />
            </TableDataCell>
          </B2TableRow>
        )}
        hasClick={false}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={incomePayments?.count}
        changePage={changePage}
      />
      {isDeleteModalOpen && selectedIncomesPayment && (
        <DeleteModal
          title={`Deseja realmente deletar o pagamento do dia ${formatDate(
            selectedIncomesPayment.accrual_date
          )} no valor de ${currency.brlMask(
            selectedIncomesPayment.value.toString()
          )}?`}
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          onConfirm={handleDeleteIncomesPayment}
          isLoading={isDeleteIncomesPaymentLoading}
          passwordRequired
        />
      )}
    </CommonCard>
  );
};

export default IncomesPaymentsList;
