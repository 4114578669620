import React, { useMemo } from 'react';

import { ApexOptions } from 'apexcharts';
import { useTheme } from 'styled-components';

import { Container, CustomReactApexChart, EmptyText } from './styles';

interface DonutChartProps {
  labels: Array<string>;
  series: Array<number>;
}

const DonutChart: React.FC<DonutChartProps> = ({ labels, series }) => {
  const theme = useTheme();

  const total = series.reduce((total, value) => total + value, 0);

  const apexOptions: ApexOptions = useMemo(() => {
    const commonTextStyle = {
      fontFamily: theme.fontFamily,
      colors: theme.colors.gray.seven,
      fontSize: theme.fontSize.smaller,
      fontWeight: theme.fontWeight.regular,
    };

    const colors = Object.values(theme.colors.complementary);

    colors.shift();

    const options: ApexOptions = {
      labels,
      colors,
      chart: {
        type: 'donut',
      },
      stroke: {
        width: 4,
      },
      tooltip: {
        style: commonTextStyle,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: 'right',
        fontFamily: commonTextStyle.fontFamily,
        fontWeight: commonTextStyle.fontWeight,
        labels: {
          colors: commonTextStyle.colors,
        },
        markers: {
          radius: 4,
          offsetX: -4,
        },
        itemMargin: {
          horizontal: 12,
        },
        formatter: (legend, { seriesIndex }) => {
          const percentage = (series[seriesIndex] * 100) / total;
          return `${percentage.toFixed(1)}% ${legend}`;
        },
      },
      noData: {
        text: 'Sem dados para exibir no momento',
        align: 'center',
        style: commonTextStyle,
      },
      responsive: [
        {
          breakpoint: 1440,
          options: {
            chart: {
              width: 640,
            },
          },
        },
      ],
    };

    return options;
  }, [
    labels,
    series,
    theme.colors.complementary,
    theme.colors.gray.seven,
    theme.fontFamily,
    theme.fontSize.smaller,
    theme.fontWeight.regular,
    total,
  ]);

  return (
    <Container>
      {series.length === 0 ? (
        <EmptyText>Sem dados para exibir no momento</EmptyText>
      ) : (
        <CustomReactApexChart
          options={apexOptions}
          series={series}
          type="donut"
        />
      )}
    </Container>
  );
};

export default DonutChart;
