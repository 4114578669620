import { color } from 'b2utils';
import { B2Button, B2Card } from 'react-b2components';
import styled from 'styled-components';
import { FinancialType } from 'utils/enums';
import { financialTransactionTypeProps } from 'utils/helpers';
import { addSpacing } from 'utils/styles';

import { baseContainerAnimation } from '@components/Base/PrivateBase/styles';
import FormError from '@components/FormError';

interface CommonInputProps {
  type: FinancialType;
}

interface InputGroupProps extends CommonInputProps {
  checked: boolean;
}

export const Container = styled(B2Card)`
  animation: ${baseContainerAnimation} 0.5s ease-in-out;
`;

export const RadioButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.four};
  padding-top: ${({ theme }) => theme.spacing.five};
`;

export const InputGroup = styled.div<InputGroupProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 140px;
  max-width: 360px;
  gap: ${({ theme }) => theme.spacing.three};
  padding: ${({ theme }) => theme.spacing.three};
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: all 0.5s ease-in-out;
  background-color: ${({ theme, checked, type }) =>
    checked
      ? color.addOpacityOnHexColor(
          financialTransactionTypeProps[type].color(theme),
          0.3
        )
      : theme.colors.white.three};

  input[type='radio']:checked {
    background-color: ${({ theme, type }) =>
      financialTransactionTypeProps[type].color(theme)};
    border-color: ${({ theme, type }) =>
      financialTransactionTypeProps[type].color(theme)};
  }

  &,
  label {
    cursor: pointer;
  }
`;

export const IconGroup = styled.div<CommonInputProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.one};
  color: ${({ theme, type }) =>
    financialTransactionTypeProps[type].color(theme)};

  svg,
  img {
    width: ${({ theme }) => theme.spacing.eight};
    height: ${({ theme }) => theme.spacing.eight};
  }
`;

export const Label = styled.label`
  margin-bottom: ${({ theme }) => theme.spacing.ten};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const CustomFormError = styled(FormError)`
  margin: 0 auto;
  width: fit-content;
  padding-top: ${({ theme }) => theme.spacing.two};
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.twelve)};
  box-sizing: border-box;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.white.one};
`;

export const Button = styled(B2Button)`
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.two, theme.spacing.twelve)};
`;
