import React from 'react';

import AuthBase from 'components/Base/AuthBase';
import { Description, Title } from 'components/Base/AuthBase/styles';
import { useLocation } from 'react-router-dom';

import RoutePaths from '@components/RoutePaths';

import RoutesPath from '@router/routes';

import ResetPasswordForm from './ResetPasswordForm';
import getRoutes from './routes';
import { Content } from './styles';

const ResetPassword: React.FC = () => {
  const { pathname } = useLocation();

  const isCompanyLogin = pathname === RoutesPath.auth.resetPassword.path;

  return (
    <AuthBase>
      <RoutePaths routes={getRoutes(isCompanyLogin)} />
      <Content>
        <Title>Esqueceu sua senha?</Title>
        <Description>
          Digite seu e-mail e lhe enviaremos um link para você redefinir sua
          senha voltar a acessar sua conta
        </Description>
        <ResetPasswordForm />
      </Content>
    </AuthBase>
  );
};

export default ResetPassword;
