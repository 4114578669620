import React, { useCallback } from 'react';

import { useFormikContext } from 'formik';
import { B2ImagePicker } from 'react-b2components';
import { useMutation } from 'react-query';

import { useToast } from '@contexts/Toast';
import { useAssets } from '@hooks';

import FormRow from '@components/FormRow';
import Label from '@components/Label';
import Loading from '@components/Loading';

import { CustomFormGroup, RemoveImageButton } from './styles';

const ProfileImagePicker: React.FC = () => {
  const formik = useFormikContext<{
    avatar: IImage | null;
  }>();

  const { getPresignedImageUrl, uploadS3Image, uploadImage } = useAssets();
  const { addToast } = useToast();

  const { mutateAsync: fetchPresignedUrl } = useMutation(getPresignedImageUrl);

  const { mutateAsync: sendS3UrlToApi } = useMutation(uploadImage);

  const { mutateAsync: requestUploadS3Image } = useMutation(
    ({ presignedUrl, file }: { presignedUrl: IPresignedUrl; file: File }) =>
      uploadS3Image(presignedUrl, file)
  );

  const handleChooseChange = useCallback(
    async (file: File) => {
      try {
        const presignedUrl = await fetchPresignedUrl();
        await requestUploadS3Image({ presignedUrl, file });
        const result = await sendS3UrlToApi(presignedUrl);
        formik.setFieldValue('avatar', result);
      } catch (_) {
        addToast(
          'Não foi possível enviar a imagem, tente novamente mais tarde',
          'error'
        );
      }
    },
    [addToast, fetchPresignedUrl, formik, requestUploadS3Image, sendS3UrlToApi]
  );

  return (
    <FormRow>
      <CustomFormGroup>
        <Label>Foto de perfil</Label>
        <B2ImagePicker
          text="Escolha uma imagem"
          extensions={['image/jpg', 'image/jpeg', 'image/png']}
          maxSize={5000000}
          imageUrl={formik.values.avatar?.image_high_url}
          imageAlt="Foto de perfil"
          onChooseImage={handleChooseChange}
          onInvalidExtension={() =>
            addToast('Formato da imagem inválido', 'error')
          }
          onInvalidSize={() =>
            addToast('A imagem deve ter no máximo 5MB', 'error')
          }
          loadingComponent={Loading}
        />
        {formik.values.avatar && (
          <RemoveImageButton
            variant="transparent"
            onClick={() => formik.setFieldValue('avatar', null)}
          >
            Remover foto
          </RemoveImageButton>
        )}
      </CustomFormGroup>
    </FormRow>
  );
};

export default ProfileImagePicker;
