import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import { currency } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { useBankAccounts } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import SectionHeader from '@components/SectionHeader';

import { CustomChooseItemFilter } from '@pages/Services/FormSteps/styles';
import { errors } from '@utils';

interface TransferFormProps {
  initialValues?: ITransferFormValues;
  isCapitalInjection?: boolean;
  onFinish: (data: ITransferFormValues) => void;
}

const TransferInfoForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  TransferFormProps
> = ({ onFinish, initialValues, isCapitalInjection }, ref) => {
  const { listBankAccounts } = useBankAccounts();

  const formikInitialValues: ITransferFormValues = {
    title: '',
    date: '',
    origin: null,
    destination: null,
    value: '',
    ...initialValues,
    isCapitalInjection,
  };

  const formikValidationSchema = yup.object().shape({
    title: yup
      .string()
      .trim()
      .when({
        is: () => isCapitalInjection,
        then: (schema) => schema.required(errors.required),
      }),
    date: yup.string().trim().required(errors.required),
    origin: yup
      .object()
      .shape({
        id: yup.number(),
      })
      .nullable()
      .when({
        is: () => !isCapitalInjection,
        then: (schema) =>
          schema
            .notOneOf([null], errors.required)
            .required(errors.required)
            .test(
              'is-origin-different-than-destination',
              errors.bankAccounts,
              (value, context) => {
                const { destination }: { destination: IBankAccount | null } =
                  context.parent;
                return value?.id !== destination?.id;
              }
            ),
      }),
    destination: yup
      .object()
      .shape({
        id: yup.number(),
      })
      .nullable()
      .notOneOf([null], errors.required)
      .required(errors.required)
      .test(
        'is-destination-different-than-origin',
        errors.bankAccounts,
        (value, context) => {
          const { origin }: { origin: IBankAccount | null } = context.parent;
          return value?.id !== origin?.id;
        }
      ),
    value: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
    resetForm: formik.resetForm,
  }));

  const handleSelectOrigin = useCallback(
    (origin: IBankAccount) => {
      formik.setFieldTouched('origin');
      formik.setFieldValue('origin', origin);
    },
    [formik]
  );

  const handleSelectDestination = useCallback(
    (destination: IBankAccount) => {
      formik.setFieldTouched('destination');
      formik.setFieldValue('destination', destination);
    },
    [formik]
  );

  return (
    <FormikProvider value={formik}>
      <SectionHeader title="Informações da transação" />
      <FormRow>
        {isCapitalInjection && (
          <FormGroup>
            <Label htmlFor="title">Título do aporte *</Label>
            <Input
              id="title"
              name="title"
              placeholder="Título do aporte"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              invalidValue={!!formik.touched.title && !!formik.errors.title}
            />
            <FormError name="title" />
          </FormGroup>
        )}
        <FormGroup>
          <Label htmlFor="date">
            {isCapitalInjection ? 'Data do aporte' : 'Data da transferência'} *
          </Label>
          <Input
            type="date"
            id="date"
            name="date"
            value={formik.values.date}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.touched.date && !!formik.errors.date}
          />
          <FormError name="date" />
        </FormGroup>
      </FormRow>
      <FormRow>
        {!isCapitalInjection && (
          <FormGroup>
            <Label>Conta bancária de origem *</Label>
            <CustomChooseItemFilter
              filterValue={
                formik.values.origin?.name ||
                'Selecionar conta bancária de origem'
              }
              modalTitle="Selecionar conta bancária de origem"
              fetch={listBankAccounts}
              queryParams={{ isActive: 'true' }}
              queryKey="originBankAccounts"
              inputLabel="Selecionar conta bancária de origem *"
              selectedItemLabel="Conta bancária de origem selecionado"
              shouldRenderSelectedItem={!!formik.values.origin}
              onSelect={(origin) => handleSelectOrigin(origin)}
              selectedItem={formik.values.origin || undefined}
              renderItemText={(origin) => origin?.name}
              invalidValue={!!formik.touched.origin && !!formik.errors.origin}
            />
            <FormError name="origin" />
          </FormGroup>
        )}
        <FormGroup>
          <Label>Conta bancária de destino *</Label>
          <CustomChooseItemFilter
            filterValue={
              formik.values.destination?.name ||
              'Selecionar conta bancária de destino'
            }
            modalTitle="Selecionar conta bancária de destino"
            fetch={listBankAccounts}
            queryParams={{ isActive: 'true' }}
            queryKey="destinationBankAccounts"
            inputLabel="Selecionar conta bancária de destino *"
            selectedItemLabel="Conta bancária de destino selecionado"
            shouldRenderSelectedItem={!!formik.values.destination}
            onSelect={(destination) => handleSelectDestination(destination)}
            selectedItem={formik.values.destination || undefined}
            renderItemText={(destination) => destination?.name}
            invalidValue={
              !!formik.touched.destination && !!formik.errors.destination
            }
          />
          <FormError name="destination" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="value">Valor *</Label>
          <Input
            type="text"
            id="value"
            name="value"
            placeholder="R$ 0,00"
            value={formik.values.value}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'value',
                currency.brlMask(event.target.value)
              )
            }
            invalidValue={!!formik.touched.value && !!formik.errors.value}
          />
          <FormError name="value" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(TransferInfoForm);
