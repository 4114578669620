import React from 'react';

import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useClients, useQueryParams, useServices } from '@hooks';

import ChooseItemFilter from '@components/ChooseItemFilter';
import CommonCard from '@components/CommonCard';
import ListFilters from '@components/ListFilters';
import { TableDataCell } from '@components/Table/styles';
import TableVariant from '@components/TableVariant';

import RoutesPath from '@router/routes';

const ServiceList: React.FC = () => {
  const { departureId } = useParams<{ departureId: string }>();

  const { queryParams, setQueryParams, onSearch, changePage } =
    useQueryParams<IServicesQueryParams>({ page: 1, departure: departureId });

  const { listServices } = useServices();
  const { listClients } = useClients();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const emptyClient = {
    id: 0,
    name: 'Todos',
  } as IClientUser;

  const { data: services, isLoading } = useQuery(
    ['services', queryParams],
    () => listServices(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista de veículos do embarque',
          'error'
        );
      },
    }
  );

  const handleSelectClient = (client: IClientUser) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      client,
    }));
  };

  return (
    <CommonCard title="Lista de veículos">
      <ListFilters
        variant
        searchPlaceholder="Buscar por veículo ou nº do serviço"
        onSearch={onSearch}
      >
        <ChooseItemFilter
          filterValue={
            queryParams?.client?.id && queryParams?.client?.id !== 0
              ? queryParams?.client?.name
              : 'Cliente: todos'
          }
          modalTitle="Filtrar serviços por cliente"
          fetch={listClients}
          queryParams={{ isActive: 'true' }}
          queryKey="clients"
          inputLabel="Selecionar cliente *"
          selectedItemLabel="Cliente selecionado"
          shouldRenderSelectedItem
          onSelect={handleSelectClient}
          selectedItem={queryParams.client || emptyClient}
          additionalItem={emptyClient}
          renderItemText={(client) => client.name}
        />
      </ListFilters>
      <TableVariant
        data={services?.results || []}
        isLoading={isLoading}
        headerData={[
          'Nº do serviço',
          'Cliente',
          'Veículo',
          'Origem',
          'Destino',
        ]}
        emptyMessage="Nenhum veículo encontrado"
        renderRow={(service) => (
          <B2TableRow
            key={service.id}
            onClick={() =>
              navigate(
                RoutesPath.private.services.detailService.path.replace(
                  ':serviceId',
                  service.id.toString()
                )
              )
            }
          >
            <TableDataCell>{service.number}</TableDataCell>
            <TableDataCell>{service.client.name}</TableDataCell>
            <TableDataCell>
              {service.vehicle.model} - {service.vehicle.identifier}
            </TableDataCell>
            <TableDataCell>
              {`${service.origin.name}/${service.origin.state}`}
            </TableDataCell>
            <TableDataCell>
              {service.destination
                ? `${service.destination.name}/${service.destination.state}`
                : '-'}
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={services?.count}
        changePage={changePage}
      />
    </CommonCard>
  );
};

export default ServiceList;
