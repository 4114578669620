export enum UserType {
  MANAGEMENT = 'managementuser',
  COMPANY = 'companyuser',
  CLIENT = 'client',
  DRIVER = 'driver',
}

export enum UserRole {
  ADMIN = 'Administrador',
  MANAGER = 'Gerente',
  SUPPORT = 'Suporte',
  STAFF = 'Equipe',
  INSPECTOR = 'Vistoriador',
}

export enum ManagementUserRole {
  ADMIN = 'Administrador',
  MANAGER = 'Gerente',
  SUPPORT = 'Suporte',
}

export enum CompanyUserRole {
  ADMIN = 'Administrador',
  STAFF = 'Equipe',
  INSPECTOR = 'Vistoriador',
  SALES = 'Vendedor',
  LOGISTICS = 'Logística',
}

export enum ManagementButtonType {
  EDIT = 'Editar',
  DELETE = 'Excluir',
}

export enum StateRegistrationStatus {
  NON_TAXPAYER = 'Não contribuinte',
  EXEMPT = 'Isento',
  TAXPAYER = 'Contribuinte',
}

export enum ServiceType {
  OWN = 'Próprio',
  THIRD_PARTY = 'Terceiros',
  STORAGE = 'Armazenagem',
}

export enum ServiceStatus {
  WAITING_COLLECTION = 'Aguardando coleta',
  WAITING_DEPARTURE = 'Aguardando embarque',
  IN_TRANSIT = 'Em trânsito',
  WAITING_DELIVERY = 'Aguardando entrega',
  FINISHED = 'Finalizado',
  CANCELED = 'Cancelado',
}

export enum CollectionAndDeliveryType {
  BASE = 'Base',
  WINCH = 'Guincho',
  CAR_CARRIER_TRUCK = 'Cegonha',
}

export enum TakerType {
  SENDER = 'Remetente',
  RECIPIENT = 'Destinatário',
  ANOTHER = 'Outro',
}

export enum ServiceClientType {
  SENDER = 'Remetente',
  RECIPIENT = 'Destinatário',
}

export enum VehicleType {
  CAR = 'Carro',
  MOTORCYCLE = 'Moto',
  TRUCK = 'Caminhão',
  ANOTHER = 'Outro',
}

export enum VehicleDocumentType {
  CRLV = 'CRLV',
  CRV = 'CRV',
  TIEM = 'TIEM',
  NFE = 'NF-e',
  ANOTHER = 'Outro',
}

export enum IcmsType {
  NORMAL = 'Normal',
  REDUCTION = 'Redução',
  EXEMPT = 'Isento',
  NOT_TAXED = 'Não tributado',
  DEFERRED = 'Diferido',
  SUBSTITUTION = 'Substituição',
  OTHERS = 'Outros',
  OTHER_STATE = 'Outra UF',
  SIMPLE_NATIONAL = 'Simples Nacional',
}

export enum InspectionType {
  PHOTOS = 'Fotos',
  PAPER_CHECKLIST = 'Checklist de papel',
}

export enum InspectionStatus {
  PENDING = 'Pendente',
  IN_PROGRESS = 'Em andamento',
  FINISHED = 'Finalizada',
}

export enum ServiceLogAction {
  CREATED_SERVICE = 'CREATED_SERVICE',
  CHANGED_STATUS = 'CHANGED_STATUS',
  PERFORMED_INSPECTION = 'PERFORMED_INSPECTION',
  CANCELED_INSPECTION = 'CANCELED_INSPECTION',
  EDITED_SERVICE = 'EDITED_SERVICE',
  STARTED_DEPARTURE = 'STARTED_DEPARTURE',
}

export const ServiceLogActionMap = {
  [ServiceLogAction.CREATED_SERVICE]: 'Serviço criado',
  [ServiceLogAction.CHANGED_STATUS]: 'Status alterado',
  [ServiceLogAction.PERFORMED_INSPECTION]: 'Vistoria realizada',
  [ServiceLogAction.CANCELED_INSPECTION]: 'Vistoria cancelada',
  [ServiceLogAction.EDITED_SERVICE]: 'Serviço editado',
  [ServiceLogAction.STARTED_DEPARTURE]: 'Embarque iniciado',
};

export enum CarrierType {
  ETC = 'ETC',
  TAC = 'TAC',
  CTC = 'CTC',
}

export const CarrierTypeMap = {
  [CarrierType.ETC]: 'ETC - Empresa de Transporte de Cargas',
  [CarrierType.TAC]: 'TAC - Transportador Autônomo de Cargas',
  [CarrierType.CTC]: 'CTC - Cooperativa de Transporte de Cargas',
};

export enum DriverVehicleType {
  TOW_TRUCK = 'Guincho',
  CAR_CARRIER_TRAILER = 'Cegonha',
}

export enum DepartureStatus {
  WAITING_START = 'Aguardando início',
  IN_TRAFFIC = 'Em trânsito',
  FINISHED = 'Finalizado',
  CANCELED = 'Cancelado',
}

export enum DepartureOwnership {
  OWN = 'Próprio',
  THIRD_PARTY = 'Terceiros',
}

export enum DepartureLogAction {
  CREATED_DEPARTURE = 'CREATED_DEPARTURE',
  CHANGED_STATUS = 'CHANGED_STATUS',
  EDITED_DEPARTURE = 'EDITED_DEPARTURE',
}

export const DepartureLogActionMap = {
  [DepartureLogAction.CREATED_DEPARTURE]: 'Embarque criado',
  [DepartureLogAction.CHANGED_STATUS]: 'Status alterado',
  [DepartureLogAction.EDITED_DEPARTURE]: 'Embarque editado',
};

export enum TaxRegime {
  SIMPLE_NATIONAL = 'Simples Nacional',
  PRESUMED_PROFIT = 'Lucro Presumido',
  REAL_PROFIT = 'Lucro Real',
}

export enum TaxRegimeCode {
  SIMPLE_NATIONAL = '1',
  SIMPLE_NATIONAL_SUBLIMIT_EXCESS = '2',
  NORMAL_REGIME = '3',
  SIMPLE_NATIONAL_MEI = '4',
}

export const TaxRegimeCodeMap = {
  [TaxRegimeCode.SIMPLE_NATIONAL]: 'Simples Nacional',
  [TaxRegimeCode.SIMPLE_NATIONAL_SUBLIMIT_EXCESS]:
    'Simples Nacional - excesso de sublimite da receita bruta',
  [TaxRegimeCode.NORMAL_REGIME]: 'Regime Normal',
  [TaxRegimeCode.SIMPLE_NATIONAL_MEI]:
    'Simples Nacional - Microempreendedor Individual (MEI)',
};

export enum CteIssuanceStatus {
  PENDING = 'Pendente',
  PROCESSING = 'Em processamento',
  ISSUED = 'Emitido',
  FAILED = 'Falhou',
  CANCELED = 'Cancelado',
}

export enum MdfeIssuanceStatus {
  PENDING = 'Pendente',
  PROCESSING = 'Em processamento',
  ISSUED = 'Emitido',
  FINISHED = 'Encerrado',
  FAILED = 'Falhou',
  CANCELED = 'Cancelado',
}

export enum MdfeIssuanceType {
  NORMAL = 1,
  CONTINGENCY = 2,
}

export const MdfeIssuanceTypeMap = {
  [MdfeIssuanceType.NORMAL]: 'Normal',
  [MdfeIssuanceType.CONTINGENCY]: 'Contingência',
};

export enum FileType {
  CTE = 'CT-e',
  MDFE = 'MDF-e',
  INCOME = 'Recebíveis',
  EXPENSE = 'Despesas',
  MOVEMENT = 'Conta bancária',
}

export enum FileStatus {
  PENDING = 'Pendente',
  PROCESSING = 'Em processamento',
  FINISHED = 'Finalizado',
  FAILED = 'Falhou',
}

export const FileStatusMapping = {
  [FileStatus.PENDING]: 'Preparando download',
  [FileStatus.PROCESSING]: 'Preparando download',
  [FileStatus.FINISHED]: 'Finalizado',
  [FileStatus.FAILED]: 'Ocorreu um erro',
};

export enum FileExtension {
  PDF = 'pdf',
  ZIP = 'zip',
}

export enum UnitOfMeasurement {
  KG = 1,
  TON = 2,
}

export const UnitOfMeasurementMap = {
  [UnitOfMeasurement.KG]: 'kg',
  [UnitOfMeasurement.TON]: 't',
};

export enum ServiceListOrdering {
  INSPECTION_DATE = 'inspection_date',
  DESCENDING_STATUS_CHANGED_AT = '-status_changed_at',
}

export enum DepartureListOrdering {
  DATE = 'date',
  DESCENDING_STATUS_CHANGED_AT = '-status_changed_at',
}

export enum ExpenseType {
  PEOPLE = 'Pessoas',
  VARIABLE_EXPENSE = 'Despesa variável',
  FIXED_EXPENSE = 'Despesa fixa',
  TAX = 'Imposto',
}

export const ExpenseTypeMap = {
  [ExpenseType.PEOPLE]: ExpenseType.PEOPLE,
  [ExpenseType.VARIABLE_EXPENSE]: 'Variável',
  [ExpenseType.FIXED_EXPENSE]: 'Fixa',
  [ExpenseType.TAX]: ExpenseType.TAX,
};

export enum FinancialType {
  INCOME = 'Recebível',
  EXPENSE = 'Despesa',
  TRANSFER = 'Transferência',
  CAPITAL_INJECTION = 'Aporte',
}

export enum FinancialReportType {
  INCOME = 'Recebível',
  EXPENSE = 'Despesa',
  MOVEMENT = 'Conta bancária',
}

export const FinancialTypePluralMap = {
  [FinancialType.INCOME]: 'Recebíveis',
  [FinancialType.EXPENSE]: 'Despesas',
  [FinancialType.TRANSFER]: 'Transações',
  [FinancialType.CAPITAL_INJECTION]: 'Aportes',
};

export const FinancialReportTypePluralMap = {
  [FinancialReportType.INCOME]: 'Recebíveis',
  [FinancialReportType.EXPENSE]: 'Despesas',
  [FinancialReportType.MOVEMENT]: 'Conta bancária',
};

export enum BankAccountType {
  CHECKING_ACCOUNT = 'Conta corrente',
  SAVINGS_ACCOUNT = 'Poupança',
  CASH_ACCOUNT = 'Caixa',
  INVESTMENT = 'Investimento',
  CREDIT_CARD = 'Cartão de crédito',
  OTHER = 'Outro',
}

export enum PaymentStatus {
  TO_RECEIVE = 'A receber',
  PARTIALLY_PAID = 'Parcialmente pago',
  PAID = 'Pago',
}

export const PaymentStatusMap = {
  [PaymentStatus.TO_RECEIVE]: PaymentStatus.TO_RECEIVE,
  [PaymentStatus.PARTIALLY_PAID]: 'Parcial',
  [PaymentStatus.PAID]: PaymentStatus.PAID,
};

export const PaymentStatusFilterMap = {
  [PaymentStatus.TO_RECEIVE]: [
    PaymentStatus.TO_RECEIVE,
    PaymentStatus.PARTIALLY_PAID,
  ].join(','),
  [PaymentStatus.PAID]: PaymentStatus.PAID,
};

export const ExpensePaymentStatusFilterMap = {
  ['A pagar']: PaymentStatusFilterMap[PaymentStatus.TO_RECEIVE],
  [PaymentStatus.PAID]: PaymentStatusFilterMap[PaymentStatus.PAID],
};

export enum CityType {
  ORIGIN = 'Origem',
  DESTINATION = 'Destino',
}

export enum QuotationStatus {
  IN_PROGRESS = 'Em andamento',
  WON = 'Ganho',
  LOST = 'Perdido',
  EXPIRED = 'Expirado',
}

export enum SendingStatus {
  PENDING = 'Pendente',
  FINISHED = 'Finalizado',
}

export const ContractStatusMap = {
  [SendingStatus.PENDING]: SendingStatus.PENDING,
  [SendingStatus.FINISHED]: 'Assinado',
};

export enum CountMetricType {
  QUOTATIONS = 'Orçamentos',
  SERVICES = 'Serviços',
  DEPARTURES = 'Embarques',
  INSPECTIONS = 'Vistorias',
}

export enum ServiceRelatedEntity {
  PERSON_IN_CHARGE = 'person_in_charge',
  CATEGORY = 'category',
  VEHICLE_TYPE = 'vehicle__type',
  ORIGIN_STATE = 'origin__state',
}

export enum CteEndorsementStatus {
  PENDING = 'Pendente',
  PROCESSING = 'Em processamento',
  ENDORSED = 'Averbado',
  FAILED = 'Falhou',
  CANCELED = 'Cancelado',
}

export enum DocumentsListType {
  SERVICES = 'Serviços',
  INSPECTIONS = 'Vistorias',
  DEPARTURES = 'Embarque',
  SERVICE_CONTRACTS = 'Contratos',
  TERM_OF_RECEIPTS = 'Termos de entrega',
  CTE = 'CT-es',
  MDFE = 'MDF-es',
}

export enum PaymentMethodListType {
  PAYMENT_METHOD = 'Métodos de pagamento',
  PAYMENT_TERM = 'Formas de pagamento',
}

export enum ReportDetailLevel {
  DETAILED = 'Detalhado',
  SIMPLIFIED = 'Simplificado',
}

export enum MovementType {
  EXPENSE_PAYMENT = 'ExpensePayment',
  INCOME_PAYMENT = 'IncomePayment',
  TRANSFER = 'Transfer',
}

export const MovementTypeMap = {
  [MovementType.INCOME_PAYMENT]: 'Recebimento',
  [MovementType.EXPENSE_PAYMENT]: 'Pagamento',
  [MovementType.TRANSFER]: 'Transação',
};
