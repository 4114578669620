import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { FinancialReportType, FinancialReportTypePluralMap } from 'utils/enums';
import { financialReportTypeProps } from 'utils/helpers';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import Input from '@components/Input';

import { errors } from '@utils';

import {
  Container,
  IconGroup,
  InputGroup,
  Label,
  RadioButtonsContainer,
} from './styles';

interface FinancialReportTypeSelectProps {
  initialValues?: IFinancialReportTypeFormValues;
  onFinish: (values: IFinancialReportTypeFormValues) => void;
}

const FinancialReportTypeSelect: React.ForwardRefRenderFunction<
  IFormStepRef,
  FinancialReportTypeSelectProps
> = ({ initialValues, onFinish }, ref) => {
  const financialReportTypes = Object.values(FinancialReportType);

  const formikInitialValues: IFinancialReportTypeFormValues = {
    reportType: null,
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    reportType: yup.string().nullable().notOneOf([null], errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: () => formik.handleSubmit(),
  }));

  return (
    <FormikProvider value={formik}>
      <Container>
        <FormGroup>
          <RadioButtonsContainer>
            {financialReportTypes.map((type) => {
              const { icon: Icon } = financialReportTypeProps[type];

              return (
                <InputGroup
                  key={type}
                  type={type}
                  checked={formik.values.reportType === type}
                  onClick={() => formik.setFieldValue('reportType', type)}
                >
                  <Input
                    type="radio"
                    checked={formik.values.reportType === type}
                    onChange={() => formik.setFieldValue('reportType', type)}
                  />
                  <IconGroup type={type}>
                    <Icon />
                    <Label>{FinancialReportTypePluralMap[type]}</Label>
                  </IconGroup>
                </InputGroup>
              );
            })}
          </RadioButtonsContainer>
        </FormGroup>
        <FormError name="reportType" />
      </Container>
    </FormikProvider>
  );
};

export default forwardRef(FinancialReportTypeSelect);
