import React from 'react';

import { FormikProvider, useFormik } from 'formik';
import { DriverVehicleType } from 'utils/enums';
import { driverVehicleTypeProps } from 'utils/helpers';
import * as yup from 'yup';

import FormError from '@components/FormError';
import Input from '@components/Input';
import SectionHeader from '@components/SectionHeader';

import { errors } from '@utils';

import {
  Button,
  ButtonsContainer,
  Container,
  CustomFormGroup,
  IconGroup,
  InputGroup,
  Label,
  RadioButtonsContainer,
} from './styles';

interface VehicleTypeProps {
  initialValue?: DriverVehicleType;
  selectedServiceType?: DriverVehicleType;
  setSelectedServiceType: (value: DriverVehicleType) => void;
}

const VehicleType: React.FC<VehicleTypeProps> = ({
  initialValue,
  selectedServiceType,
  setSelectedServiceType,
}) => {
  const vehicleTypes = Object.values(DriverVehicleType);

  const formikInitialValues: IDriverVehicleType = {
    vehicleType: initialValue || selectedServiceType,
  };

  const formikValidationSchema = yup.object().shape({
    vehicleType: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: ({ vehicleType }) => setSelectedServiceType(vehicleType!),
  });

  return (
    <FormikProvider value={formik}>
      <Container>
        <SectionHeader title="Selecione o tipo do veículo do motorista" />
        <CustomFormGroup>
          <RadioButtonsContainer>
            {vehicleTypes.map((type) => {
              const { icon: Icon } = driverVehicleTypeProps[type];
              return (
                <InputGroup
                  key={type}
                  checked={formik.values.vehicleType === type}
                  onClick={() => formik.setFieldValue('vehicleType', type)}
                >
                  <Input
                    type="radio"
                    checked={formik.values.vehicleType === type}
                    onChange={() => formik.setFieldValue('vehicleType', type)}
                  />
                  <IconGroup>
                    <img src={Icon} alt="Ícone do tipo de veículo" />
                    <Label>{type}</Label>
                  </IconGroup>
                </InputGroup>
              );
            })}
          </RadioButtonsContainer>
          <FormError name="vehicleType" />
        </CustomFormGroup>
      </Container>
      <ButtonsContainer>
        <Button type="button" onClick={() => formik.handleSubmit()}>
          Próximo
        </Button>
      </ButtonsContainer>
    </FormikProvider>
  );
};

export default VehicleType;
