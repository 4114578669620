import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

interface SectionHeaderProps {
  isFormHeader: boolean;
}

export const Container = styled.div<SectionHeaderProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.one};
  padding-top: ${({ theme, isFormHeader }) =>
    isFormHeader && theme.spacing.five};
`;

export const Title = styled.div`
  margin-bottom: -1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.four};
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  border-bottom: 3px solid ${({ theme }) => theme.colors.main};
  padding: ${({ theme }) =>
    addSpacing('0', theme.spacing.two, theme.spacing.two)};
`;
