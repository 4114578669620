import React from 'react';

import PublicBase from '@components/Base/PublicBase';
import BaseDeliveryReceiptForm from '@components/BaseDeliveryReceiptForm';

import { Container } from './styles';

const PublicDeliveryReceipt: React.FC = () => {
  return (
    <PublicBase>
      <Container>
        <BaseDeliveryReceiptForm />
      </Container>
    </PublicBase>
  );
};

export default PublicDeliveryReceipt;
