import React from 'react';

import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'utils/formats';
import { toDateInput } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useQueryParams, useDepartures } from '@hooks';

import CommonCard from '@components/CommonCard';
import DateRangeCalendarFilter from '@components/DateRangeCalendarFilter';
import ListFilters from '@components/ListFilters';
import { TableDataCell } from '@components/Table/styles';
import TableVariant from '@components/TableVariant';

import RoutesPath from '@router/routes';

interface DepartureListProps {
  driver: IDriver;
}

const DepartureList: React.FC<DepartureListProps> = ({ driver }) => {
  const { queryParams, setQueryParams, onSearch, changePage } =
    useQueryParams<IDeparturesQueryParams>({
      page: 1,
      driver,
    });

  const { listDepartures } = useDepartures();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const { data: departures, isLoading } = useQuery(
    ['departures', queryParams],
    () => listDepartures(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista de embarques do motorista',
          'error'
        );
      },
    }
  );

  const handleDateRangeFilterSelected = (startDate?: Date, endDate?: Date) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      date__gte: startDate ? toDateInput(startDate) : undefined,
      date__lte: endDate ? toDateInput(endDate) : undefined,
    }));
  };

  return (
    <CommonCard title="Lista de embarques">
      <ListFilters
        variant
        searchPlaceholder="Buscar por fornecedor"
        onSearch={onSearch}
      >
        <DateRangeCalendarFilter
          label="Selecionar um intervalo de datas de embarque"
          clearableDateRange
          onDateRangeFilterSelected={handleDateRangeFilterSelected}
        />
      </ListFilters>
      <TableVariant
        data={departures?.results || []}
        isLoading={isLoading}
        headerData={[
          'Data do embarque',
          'Fornecedor',
          'Tipo do caminhão',
          'Placa do caminhão',
          'Origem',
          'Destino',
        ]}
        emptyMessage="Nenhum veículo encontrado"
        renderRow={(departure) => (
          <B2TableRow
            key={departure.id}
            onClick={() =>
              navigate(
                RoutesPath.private.departures.detailDeparture.path.replace(
                  ':departureId',
                  departure.id.toString()
                )
              )
            }
          >
            <TableDataCell>{formatDate(departure.date)}</TableDataCell>
            <TableDataCell>{departure.provider.name}</TableDataCell>
            <TableDataCell>{departure.truck.vehicle_type}</TableDataCell>
            <TableDataCell>{departure.truck.truck_license_plate}</TableDataCell>
            <TableDataCell>{`${departure.origin.name}/${departure.origin.state}`}</TableDataCell>
            <TableDataCell>{`${departure.destination.name}/${departure.destination.state}`}</TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={departures?.count}
        changePage={changePage}
      />
    </CommonCard>
  );
};

export default DepartureList;
