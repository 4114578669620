import ReactApexChart from 'react-apexcharts';
import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const EmptyText = styled.p`
  color: ${({ theme }) => theme.colors.gray.seven};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const CustomReactApexChart = styled(ReactApexChart)`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 12px;

  foreignObject > div {
    width: 240px;
  }
`;
