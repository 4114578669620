import React from 'react';

import { CustomButton } from './styles';

interface GenerateFiscalZipFileButtonProps {
  onClick: () => void;
  disabled: boolean;
}

const GenerateFiscalZipFileButton: React.FC<
  GenerateFiscalZipFileButtonProps
> = ({ onClick, disabled }) => {
  return (
    <>
      <CustomButton type="submit" onClick={onClick} disabled={disabled}>
        Baixar
      </CustomButton>
    </>
  );
};

export default GenerateFiscalZipFileButton;
