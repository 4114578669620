import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { Lock } from 'iconsax-react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useCompanyUsers, useNavigateWithScope } from '@hooks';

import ChangePasswordModal from '@components/ChangePasswordModal';
import Loading from '@components/Loading';
import PageHeader from '@components/PageHeader';
import UserDetail from '@components/UserDetail';

import RoutesPath from '@router/routes';

import getRoutes from './routes';

const DetailCompanyUser: React.FC = () => {
  const { companyUserId } = useParams<{ companyUserId: string }>();

  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] =
    useState(false);

  const {
    getCompanyUser,
    updateCompanyUserIsActiveSituation,
    changeCompanyUserPassword,
    deleteCompanyUser,
  } = useCompanyUsers();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { navigateWithScope } = useNavigateWithScope();

  const { isManagementUser } = useAuth();

  const {
    isLoading,
    data: companyUser,
    refetch,
  } = useQuery(
    ['companyUser', companyUserId],
    () => getCompanyUser(companyUserId!),
    {
      onError() {
        addToast('Não foi possível carregar os dados do usuário', 'error');
        navigate(RoutesPath.private.companyUsers.listCompanyUsers.path, {
          replace: true,
        });
      },
    }
  );

  const { mutate: handleChangeCompanyUserSituation } = useMutation(
    () =>
      updateCompanyUserIsActiveSituation(
        companyUserId!,
        !companyUser!.is_active
      ),
    {
      onSuccess: () => {
        addToast('Situação do usuário alterada com sucesso', 'success');
        refetch();
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível alterar a situação do usuário', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const {
    mutate: handleChangeCompanyUserPassword,
    isLoading: isLoadingChangeCompanyUserPassword,
  } = useMutation(
    (newPassword: string) =>
      changeCompanyUserPassword(Number(companyUserId), newPassword),
    {
      onSuccess: () => {
        addToast('Senha alterada com sucesso', 'success');
        setIsOpenChangePasswordModal(false);
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível alterar a senha', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const {
    mutate: handleDeleteCompanyUser,
    isLoading: isLoadingDeleteCompanyUser,
  } = useMutation(
    (password: string) => deleteCompanyUser(Number(companyUserId), password),
    {
      onSuccess: () => {
        addToast('Usuário deletado sucesso', 'success');
        setIsOpenChangePasswordModal(false);
        navigate(RoutesPath.private.companyUsers.listCompanyUsers.path, {
          replace: true,
        });
      },
      onError: (error: AxiosError) => {
        addToast('Erro ao deletar usuário', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const handleDeleteCompanyUserClick = (password?: string) => {
    if (!password) {
      return;
    }
    handleDeleteCompanyUser(password);
  };

  const deleteButtonProps = {
    button: {
      text: 'Deletar',
    },
    modal: {
      title: 'Deseja realmente deletar este usuário?',
      confirmText: 'Sim, desejo deletar',
      onConfirm: handleDeleteCompanyUserClick,
      cancelText: 'Não, manter',
      isLoading: isLoadingDeleteCompanyUser,
      passwordRequired: true,
    },
  };

  return (
    <>
      <PageHeader
        title="Detalhes do usuário"
        routes={getRoutes(companyUserId!, !!isManagementUser)}
        buttonProps={
          isManagementUser
            ? {
                text: 'Trocar senha',
                icon: Lock,
                disabled: isLoadingChangeCompanyUserPassword,
                onClick: () => setIsOpenChangePasswordModal(true),
              }
            : undefined
        }
        deleteButtonProps={isManagementUser ? deleteButtonProps : undefined}
        editButtonProps={{
          onClick: () => {
            navigateWithScope({
              routePath:
                RoutesPath.private.companyUsers.updateCompanyUser.path.replace(
                  ':companyUserId',
                  companyUserId!
                ),
              company: companyUser?.company,
              state: companyUser,
            });
          },
        }}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <UserDetail
          user={companyUser!}
          handleChangeUserSituation={handleChangeCompanyUserSituation}
        />
      )}
      <ChangePasswordModal
        isOpenModal={isOpenChangePasswordModal}
        isLoading={isLoadingChangeCompanyUserPassword}
        onClose={() => setIsOpenChangePasswordModal(false)}
        requestChangePassword={({ password }) =>
          handleChangeCompanyUserPassword(password)
        }
      />
    </>
  );
};

export default DetailCompanyUser;
