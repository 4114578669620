import React from 'react';

import { useNavigate } from 'react-router-dom';
import { ServiceListOrdering, ServiceStatus } from 'utils/enums';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useQueryParams } from '@hooks';

import { KanbanColumns } from '@components/KanbanColumn';
import PageHeader from '@components/PageHeader';
import ServiceFilters from '@components/ServiceFilters';

import RoutesPath from '@router/routes';

import ServiceStatusColumn from './ServiceStatusColumn';

const ListServices: React.FC = () => {
  const { queryParams, setQueryParams, onSearch } = useQueryParams({
    pageSize: 10,
  } as IServicesQueryParams);

  const { isManagementUser, isClientUser } = useAuth();
  const { scope } = useScope();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const serviceStatuses = Object.values(ServiceStatus);

  const navigateToCreateService = () => {
    if (isManagementUser && !scope) {
      addToast(
        'Não é possível cadastrar um serviço sem estar com uma empresa selecionada',
        'error'
      );
      return;
    }
    navigate(RoutesPath.private.services.createService.path);
  };

  return (
    <>
      <PageHeader
        title="Serviços"
        subtitle="Listagem das empresas no sistema"
        buttonProps={
          !isClientUser
            ? {
                text: 'Cadastrar serviço',
                onClick: navigateToCreateService,
              }
            : undefined
        }
      />
      <ServiceFilters
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onSearch={onSearch}
      />
      <KanbanColumns length={serviceStatuses.length}>
        {serviceStatuses.map((status) => (
          <ServiceStatusColumn
            key={status}
            queryParams={{
              ...queryParams,
              status,
              ordering:
                status === ServiceStatus.WAITING_COLLECTION
                  ? ServiceListOrdering.INSPECTION_DATE
                  : ServiceListOrdering.DESCENDING_STATUS_CHANGED_AT,
            }}
          />
        ))}
      </KanbanColumns>
    </>
  );
};

export default ListServices;
