import styled from 'styled-components';

import Input from '@components/Input';

export const CustomInput = styled(Input)`
  text-overflow: ellipsis;
  padding-left: ${({ theme }) => theme.spacing.eleven};
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-position-x: ${({ theme }) => theme.spacing.four};
  background-image: url('/images/search.svg');
  background-size: ${({ theme }) => theme.spacing.four};
`;
