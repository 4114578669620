import styled from 'styled-components';

export const IconContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 3.6rem;
  width: max-content;
  border-radius: ${({ theme }) => theme.spacing.three};
  border: 1px solid ${({ theme }) => theme.colors.white.three};
  background-color: ${({ theme }) => theme.colors.white.two};
  transition: all 0.25s ease-in-out;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    color: ${({ theme }) => theme.colors.gray.six};
    width: ${({ theme }) => theme.spacing.five};
    height: ${({ theme }) => theme.spacing.five};
  }
`;
