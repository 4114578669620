import React from 'react';

import { AxiosError } from 'axios';
import { B2Switch, B2TableRow } from 'react-b2components';
import { useMutation, useQuery } from 'react-query';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import {
  useIncomeCategories,
  useNavigateWithScope,
  useQueryParams,
} from '@hooks';

import ListFilters from '@components/ListFilters';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import RoutesPath from '@router/routes';

const IncomeCategoriesListing: React.FC = () => {
  const { queryParams, onSearch, changePage, changeIsActiveStatus } =
    useQueryParams<IQueryParams>({ page: 1 });

  const { listIncomeCategories, updateIsActiveIncomeCategoryStatus } =
    useIncomeCategories();
  const { isManagementUser } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();
  const { navigateWithScope } = useNavigateWithScope();

  const tableHeaderData = isManagementUser
    ? ['Nome', 'Empresa', 'Situação']
    : ['Nome', 'Situação'];

  const {
    data: incomeCategories,
    refetch: refetchCategory,
    isLoading,
  } = useQuery(
    ['incomeCategories', queryParams, scope],
    () => listIncomeCategories(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista de categorias de despesas',
          'error'
        );
      },
    }
  );

  const { mutate: requestUpdateIsActiveCategoryStatus } = useMutation(
    (category: IIncomeCategory) =>
      updateIsActiveIncomeCategoryStatus(category.id, !category.is_active),
    {
      onSuccess: () => {
        addToast('Situação da categoria atualizada com sucesso', 'success');
        refetchCategory();
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível alterar a situação da categoria', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  return (
    <>
      <ListFilters
        onSearch={onSearch}
        changeIsActiveStatus={{ queryParams, changeIsActiveStatus }}
      />
      <Table
        data={incomeCategories?.results || []}
        headerData={tableHeaderData}
        emptyMessage="Nenhuma categoria encontrada"
        isLoading={isLoading}
        renderRow={(category) => (
          <B2TableRow
            key={category.id}
            onClick={() =>
              navigateWithScope({
                routePath:
                  RoutesPath.private.categories.incomes.editCategory.path.replace(
                    ':categoryId',
                    category.id.toString()
                  ),
                company: category.company,
              })
            }
          >
            <TableDataCell>{category.name}</TableDataCell>
            {isManagementUser && (
              <TableDataCell>{category.company.name}</TableDataCell>
            )}
            <TableDataCell onClick={(event) => event.stopPropagation()}>
              <B2Switch
                isChecked={category.is_active}
                onChange={() => requestUpdateIsActiveCategoryStatus(category)}
              />
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={incomeCategories?.count}
        changePage={changePage}
      />
    </>
  );
};

export default IncomeCategoriesListing;
