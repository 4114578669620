import { color } from 'b2utils';
import styled from 'styled-components';

import { TableProps } from '@components/Table';
import TableVariant from '@components/TableVariant';

const ModalTable = styled(TableVariant)`
  overflow: auto;

  table {
    padding: ${({ theme }) => theme.spacing.one};
    border-collapse: separate;
    border-spacing: 0px ${({ theme }) => theme.spacing.two};
  }

  tr {
    border-bottom: none;
    border-radius: ${({ theme }) => theme.borderRadius};
    box-shadow: 0px 4px 8px 0px
      ${({ theme }) => color.addOpacityOnHexColor(theme.colors.black.main, 0.1)};

    td:first-child {
      border-top-left-radius: ${({ theme }) => theme.borderRadius};
      border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
    }

    td:last-child {
      border-top-right-radius: ${({ theme }) => theme.borderRadius};
      border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
    }
  }

  thead > tr {
    th:first-child {
      border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
    }

    th:last-child {
      border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
    }
  }

  tbody > tr:hover {
    background-color: transparent;
  }
` as <Type>(props: TableProps<Type>) => React.ReactElement;

export default ModalTable;
