import { useCallback } from 'react';

import {
  ServiceStatus,
  ServiceType,
  TakerType,
  VehicleDocumentType,
} from 'utils/enums';
import {
  isIcmsRateRequired,
  isIcmsReductionRequired,
  isOptingForCreditGrantedRequired,
} from 'utils/helpers';
import regex from 'utils/regex';

import { useApi } from '@contexts/Api';
import { endpoints } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

export const generateServiceRequestObject = (
  service: IServiceFormValues | IEditServiceFormValues
) => {
  const shouldSendIcmsRate = isIcmsRateRequired(service.icmsType);
  const shouldSendIcmsReduction = isIcmsReductionRequired(service.icmsType);
  const shouldSendOptingForCreditGranted = isOptingForCreditGrantedRequired(
    service.icmsType
  );

  const requestObject: IServiceRequestObject = {
    [ServiceType.OWN]: {
      type: service.type,
      category: service.category.id,
      person_in_charge: service.personInCharge.id,
      client_type: service.takerType,
      client:
        service.takerType === TakerType.RECIPIENT
          ? service.recipient.id
          : service.takerType === TakerType.SENDER
          ? service.sender.id
          : service.client.id,
      client_address:
        service.takerType === TakerType.ANOTHER
          ? service.clientAddressId
          : null,
      estimated_collection_date: service.estimatedCollectionDate,
      estimated_min_days_for_delivery: Number(
        service.estimatedMinDaysForDelivery
      ),
      estimated_max_days_for_delivery: Number(
        service.estimatedMaxDaysForDelivery
      ),
      sender: service.sender?.id,
      collection_type: service.collectionType,
      collection_address: service.collectionAddressId,
      recipient: service.recipient?.id,
      delivery_type: service.deliveryType,
      delivery_address: service.deliveryAddressId,
      vehicle: {
        type: service.vehicleType,
        model: service.model,
        identifier: service.identifier,
        document_type:
          service.documentType !== VehicleDocumentType.ANOTHER
            ? service.documentType
            : service.documentTypeValue,
        document: service.document,
        year: Number(service.year),
        insurance_value: Number(
          service.insuranceValue.replace(regex.onlyNumbers, '')
        ),
      },
      cfop: service.cfop?.id,
      icms_type: service.icmsType!,
      icms_rate: shouldSendIcmsRate ? service.icmsRate : null,
      icms_reduction: shouldSendIcmsReduction ? service.icmsReduction : null,
      opting_for_credit_granted: shouldSendOptingForCreditGranted
        ? service.optingForCreditGranted
        : null,
      cte_freight_cost: service.cteFreightCost
        ? Number(service.cteFreightCost.replace(regex.onlyNumbers, ''))
        : null,
      cte_general_observations: service.cteGeneralObservations || null,
      value: Number(service.value.replace(regex.onlyNumbers, '')),
      payment_terms: service.paymentTerm.id,
      income_payment_method: service.paymentMethod.id,
      destination: service.destination.id,
      origin: service.origin.id,
      inspection_is_redeemable: service.redeemable,
      notify_client: service.notifyClient,
    },
    [ServiceType.STORAGE]: {
      type: service.type,
      person_in_charge: service.personInCharge.id,
      client: service.client.id,
      client_address: service.clientAddressId,
      value: Number(service.value.replace(regex.onlyNumbers, '')),
      payment_terms: service.paymentTerm.id,
      income_payment_method: service.paymentMethod.id,
      origin: service.origin.id,
      collection_date: service.collectionDate,
      vehicle: {
        type: service.vehicleType,
        model: service.model,
        identifier: service.identifier,
        insurance_value: Number(
          service.insuranceValue.replace(regex.onlyNumbers, '')
        ),
      },
      inspection_is_redeemable: service.redeemable,
      notify_client: service.notifyClient,
    },
    [ServiceType.THIRD_PARTY]: {
      type: service.type,
      person_in_charge: service.personInCharge.id,
      client: service.client.id,
      client_address: service.clientAddressId,
      value: Number(service.value.replace(regex.onlyNumbers, '')),
      payment_terms: service.paymentTerm.id,
      income_payment_method: service.paymentMethod.id,
      origin: service.origin.id,
      destination: service.destination.id,
      cte_access_key: service.cteAccessKey || null,
      estimated_collection_date: service.estimatedCollectionDate,
      vehicle: {
        type: service.vehicleType,
        model: service.model,
        identifier: service.identifier,
        insurance_value: Number(
          service.insuranceValue.replace(regex.onlyNumbers, '')
        ),
      },
      inspection_is_redeemable: service.redeemable,
      notify_client: service.notifyClient,
    },
  };
  return requestObject[service.type];
};

const useServices = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const listServices = useCallback(
    async (params: IServicesQueryParams) => {
      const response = await request<IPaginated<IService>>({
        method: 'get',
        url: endpoints.services.list,
        headers: { scope },
        params: {
          page: params.page,
          status: params.status || undefined,
          contract__status: params.contractStatus || undefined,
          page_size: params.pageSize || undefined,
          search: params.search || undefined,
          date__lte: params?.dateLte || undefined,
          date__gte: params?.dateGte || undefined,
          created__date__lte: params?.createdDateLte || undefined,
          created__date__gte: params?.createdDateGte || undefined,
          client: params?.client?.id || undefined,
          category: params?.category?.id || undefined,
          person_in_charge: params?.personInCharge?.id || undefined,
          type: params?.type || undefined,
          departure: params?.departure || undefined,
          linked_to_departure_or_unlinked:
            params?.linkedToDepartureOrUnlinked ?? undefined,
          ordering: params?.ordering || undefined,
          origin__state: params?.originState || undefined,
          destination__state: params?.destinationState || undefined,
          last_known_location__state: params?.lastKnownState || undefined,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const listServicesWithoutPagination = useCallback(
    async (departureId: number) => {
      const response = await request<Array<IService>>({
        method: 'get',
        url: endpoints.services.list,
        headers: { scope },
        params: {
          paginate: false,
          departure: departureId,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getService = useCallback(
    async (id: string) => {
      const response = await request<IService>({
        method: 'get',
        url: endpoints.services.get.replace(':serviceId', id),
      });
      return response.data;
    },
    [request]
  );

  const createService = useCallback(
    async (service: IServiceFormValues) => {
      const data = generateServiceRequestObject(service);
      const response = await request<IService>({
        method: 'post',
        url: endpoints.services.create,
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const cancelService = useCallback(
    async (id: string, password: string) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.services.cancel.replace(':serviceId', id),
        data: { password },
      });
      return response.data;
    },
    [request]
  );

  const finalizeService = useCallback(
    async (id: string, password: string) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.services.finalize.replace(':serviceId', id),
        data: { password },
      });
      return response.data;
    },
    [request]
  );

  const updateServiceStatus = useCallback(
    async (serviceId: string, status: ServiceStatus) => {
      const response = await request<IService>({
        method: 'patch',
        url: endpoints.services.update.replace(':serviceId', serviceId),
        headers: { scope },
        data: { status },
      });

      return response.data;
    },
    [request, scope]
  );

  const updateLastServiceLocation = useCallback(
    async (serviceId: string, city: ICity) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.services.changeLastKnownLocation.replace(
          ':serviceId',
          serviceId
        ),
        headers: { scope },
        data: { last_known_location: city.id },
      });

      return response.data;
    },
    [request, scope]
  );

  const listServiceLogs = useCallback(
    async (params: IServiceLogQueryParams) => {
      const response = await request<IPaginated<IServiceLog>>({
        method: 'get',
        url: endpoints.services.logs.list,
        headers: { scope },
        params: {
          page: params.page,
          page_size: params.pageSize,
          service: params.service,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const listDeliveryReceipts = useCallback(
    async (params: IDeliveryReceiptQueryParams) => {
      const response = await request<IPaginated<IDeliveryReceipt>>({
        method: 'get',
        url: endpoints.services.deliveryReceipt.list,
        params: {
          page: params.page,
          search: params.search || undefined,
          status: params.status || undefined,
          service_status: params.serviceStatus.join(','),
          delivered_at__lte: params.deliveredAtLte || undefined,
          delivered_at__gte: params.deliveredAtGte || undefined,
        },
      });
      return response.data;
    },
    [request]
  );

  const getDeliveryReceipt = useCallback(
    async (receiptId: string) => {
      const response = await request<IDeliveryReceipt>({
        method: 'get',
        url: endpoints.services.deliveryReceipt.get.replace(
          ':receiptId',
          receiptId
        ),
      });
      return response.data;
    },
    [request]
  );

  const sendDeliveryReceipt = useCallback(
    async (receiptId: string, values: IDeliveryReceiptFormValues) => {
      const data = {
        receiver_name: values?.receiverName || undefined,
        cpf: values?.cpf?.replace(regex.onlyNumbers, '') || undefined,
        delivered_at: new Date(values.deliveredAt).toISOString(),
        file: values.file?.id,
        observations: values.observations || undefined,
      };

      const response = await request<void>({
        method: 'post',
        url: endpoints.services.deliveryReceipt.post.replace(
          ':receiptId',
          receiptId
        ),
        data,
      });
      return response.data;
    },
    [request]
  );

  const getDeliveryReceiptTemplate = useCallback(
    async (receiptId: string) => {
      const response = await request<string>({
        method: 'get',
        url: endpoints.services.deliveryReceipt.template.replace(
          ':receiptId',
          receiptId
        ),
        responseType: 'blob',
      });
      return response.data;
    },
    [request]
  );

  const updateService = useCallback(
    async (id: number, service: IEditServiceFormValues) => {
      const data = generateServiceRequestObject(service);
      const response = await request<IService>({
        method: 'patch',
        url: endpoints.services.update.replace(':serviceId', id.toString()),
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const updateServiceCte = useCallback(
    async (id: number, data: IServiceCte) => {
      const shouldSendIcmsRate = isIcmsRateRequired(data.icmsType);
      const shouldSendIcmsReduction = isIcmsReductionRequired(data.icmsType);
      const shouldSendOptingForCreditGranted = isOptingForCreditGrantedRequired(
        data.icmsType
      );

      const response = await request<IService>({
        method: 'patch',
        url: endpoints.services.update.replace(':serviceId', id.toString()),
        headers: { scope },
        data: {
          cfop: data.cfop.id,
          icms_type: data.icmsType!,
          icms_rate: shouldSendIcmsRate ? data.icmsRate : null,
          icms_reduction: shouldSendIcmsReduction ? data.icmsReduction : null,
          opting_for_credit_granted: shouldSendOptingForCreditGranted
            ? data.optingForCreditGranted
            : null,
          cte_freight_cost: data.cteFreightCost
            ? Number(data.cteFreightCost.replace(regex.onlyNumbers, ''))
            : null,
          cte_general_observations: data.cteGeneralObservations || null,
        },
      });

      return response.data;
    },
    [request, scope]
  );

  const listComments = useCallback(
    async (service: number) => {
      const response = await request<Array<IServiceComment>>({
        method: 'get',
        url: endpoints.services.comments.list,
        headers: { scope },
        params: {
          service,
          paginate: false,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const createComment = useCallback(
    async (service: string, text: string) => {
      const response = await request<IServiceComment>({
        method: 'post',
        url: endpoints.services.comments.create,
        headers: { scope },
        data: { service, text },
      });

      return response.data;
    },
    [request, scope]
  );

  const updateComment = useCallback(
    async (commentId: number, text: string) => {
      const response = await request<IServiceComment>({
        method: 'patch',
        url: endpoints.services.comments.update.replace(
          ':commentId',
          commentId.toString()
        ),
        headers: { scope },
        data: { text },
      });

      return response.data;
    },
    [request, scope]
  );

  const deleteComment = useCallback(
    async (commentId: number, password: string) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.services.comments.delete.replace(
          ':commentId',
          commentId.toString()
        ),
        data: { password },
      });

      return response.data;
    },
    [request]
  );

  const getServiceContractTemplate = useCallback(
    async (contractId: string) => {
      const response = await request<string>({
        method: 'get',
        url: endpoints.services.contracts.template.replace(
          ':contractId',
          contractId
        ),
        responseType: 'blob',
      });
      return response.data;
    },
    [request]
  );

  const getServiceContract = useCallback(
    async (contractId: string) => {
      const response = await request<IServiceContract>({
        method: 'get',
        url: endpoints.services.contracts.get.replace(
          ':contractId',
          contractId
        ),
      });
      return response.data;
    },
    [request]
  );

  const listServiceContracts = useCallback(
    async (params: IServiceContractQueryParams) => {
      const response = await request<IPaginated<IServiceContract>>({
        method: 'get',
        url: endpoints.services.contracts.list,
        params: {
          page: params.page,
          search: params.search || undefined,
          status: params.status || undefined,
          created__date__lte: params.createdAtLte || undefined,
          created__date__gte: params.createdAtGte || undefined,
          uploaded_at__lte: params.uploadedAtLte || undefined,
          uploaded_at__gte: params.uploadedAtGte || undefined,
          service__person_in_charge: params?.personInCharge?.id || undefined,
        },
      });
      return response.data;
    },
    [request]
  );

  const uploadServiceContract = useCallback(
    async (contractId: string, values: IServiceContractFormValues) => {
      const response = await request<IServiceContract>({
        method: 'post',
        url: endpoints.services.contracts.upload.replace(
          ':contractId',
          contractId
        ),
        data: {
          file: values.file?.id,
          sign_date: values.signDate,
        },
      });
      return response.data;
    },
    [request]
  );

  return {
    listServices,
    listServicesWithoutPagination,
    getService,
    createService,
    cancelService,
    finalizeService,
    updateService,
    updateServiceStatus,
    updateLastServiceLocation,
    updateServiceCte,
    listServiceLogs,
    listDeliveryReceipts,
    getDeliveryReceipt,
    sendDeliveryReceipt,
    getDeliveryReceiptTemplate,
    listComments,
    createComment,
    updateComment,
    deleteComment,
    getServiceContractTemplate,
    uploadServiceContract,
    getServiceContract,
    listServiceContracts,
  };
};

export default useServices;
