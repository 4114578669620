import React, { useCallback, useState } from 'react';

import { UseMutationResult } from 'react-query';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';

import FormSteps from '@components/BaseForm/FormSteps';
import Loading from '@components/Loading';
import ModalFooterButtons from '@components/ModalFooterButtons';
import PageHeader from '@components/PageHeader';

import {
  Card,
  Content,
  ButtonsContainer,
  Button,
  CustomBaseContainer,
} from './styles';

interface IBaseFormProps {
  steps: Array<IStep>;
  createRequest: UseMutationResult<any, unknown, any, unknown>;
  routes?: Array<IRoutePath>;
  title?: string;
  subtitle?: string;
  initialValues?: any;
  isFormModal?: boolean;
  onCancelClick?: () => void;
  onGoBackOnFirstStep?: () => void;
}

const BaseForm: React.FC<IBaseFormProps> = ({
  steps,
  createRequest,
  routes,
  title,
  subtitle,
  initialValues,
  isFormModal = false,
  onCancelClick,
  onGoBackOnFirstStep,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form, setForm] = useState<any>(initialValues);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async <Type,>(data: Type) => {
      const newForm = { ...form, ...data };
      setForm(newForm);
      if (currentStep !== steps.length - 1) {
        setCurrentStep(currentStep + 1);
      } else {
        try {
          await createRequest.mutateAsync(newForm);
        } catch (error: any) {
          throwToastApiErrors(error, addToast);
        }
      }
    },
    [addToast, createRequest, currentStep, form, steps.length]
  );

  const renderCurrentStep = () => {
    const step = steps[currentStep];

    return (
      <step.component
        ref={step.ref}
        initialValues={form}
        onFinish={handleSubmit}
        {...step.props}
      />
    );
  };

  const hasGoBackOnFirstStep = currentStep === 0 && !!onGoBackOnFirstStep;
  const shouldRenderGoBackButton = currentStep !== 0 || hasGoBackOnFirstStep;

  return (
    <>
      <CustomBaseContainer isFormModal={isFormModal}>
        {title && (
          <PageHeader title={title} subtitle={subtitle} routes={routes} />
        )}
        <Content>
          {steps.length > 1 && (
            <FormSteps
              title={steps[currentStep].title}
              subtitle={steps[currentStep].subtitle}
              currentStep={currentStep}
              stepsLength={steps.length}
              isFormModal={isFormModal}
            />
          )}
          <Card isFormModal={isFormModal}>{renderCurrentStep()}</Card>
        </Content>
      </CustomBaseContainer>
      {isFormModal ? (
        <ModalFooterButtons
          isLoading={createRequest.isLoading}
          confirmText={currentStep === steps.length - 1 ? 'Salvar' : 'Próximo'}
          onConfirm={() => steps[currentStep].ref.current?.submit()}
          cancelText={currentStep === 0 ? 'Cancelar' : 'Voltar'}
          onCancel={() =>
            currentStep === 0
              ? onGoBackOnFirstStep?.() || onCancelClick?.()
              : setCurrentStep(currentStep - 1)
          }
        />
      ) : (
        <ButtonsContainer>
          {createRequest.isLoading ? (
            <Loading />
          ) : (
            <>
              {shouldRenderGoBackButton && (
                <Button
                  variant="transparent"
                  onClick={() =>
                    currentStep === 0
                      ? onGoBackOnFirstStep?.()
                      : setCurrentStep(currentStep - 1)
                  }
                >
                  Voltar
                </Button>
              )}
              <Button
                type="button"
                onClick={() => steps[currentStep].ref.current?.submit()}
              >
                {currentStep === steps.length - 1 ? 'Salvar' : 'Próximo'}
              </Button>
            </>
          )}
        </ButtonsContainer>
      )}
    </>
  );
};

export default BaseForm;
