import { useCallback } from 'react';

import axios from 'axios';

import { useApi, endpoints } from '@contexts/Api';

const useAssets = () => {
  const { request } = useApi();

  const getPresignedImageUrl = useCallback(async () => {
    const response = await request<IPresignedUrl>({
      method: 'get',
      url: endpoints.assets.images.presignedUrl.get,
    });

    return response.data;
  }, [request]);

  const getPresignedFileUrl = useCallback(
    async (extension: string) => {
      const response = await request<IPresignedUrl>({
        method: 'get',
        url: endpoints.assets.files.presignedUrl.get,
        params: {
          extension,
        },
      });

      return response.data;
    },
    [request]
  );

  const fillS3FormData = useCallback(
    (form: FormData, presignedUrl: IPresignedUrl, file: File) => {
      form.set('key', presignedUrl.fields.key);
      form.set('AWSAccessKeyId', presignedUrl.fields.AWSAccessKeyId);
      form.set('policy', presignedUrl.fields.policy);
      form.set('signature', presignedUrl.fields.signature);
      form.set('acl', 'public-read');
      form.set('file', file);
    },
    []
  );

  const uploadS3Image = useCallback(
    async (presignedUrl: IPresignedUrl, file: File) => {
      const data = new FormData();
      data.set('content-type', file.type);
      fillS3FormData(data, presignedUrl, file);

      const response = await axios.request<void>({
        method: 'post',
        url: presignedUrl.url,
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    },
    [fillS3FormData]
  );

  const uploadS3File = useCallback(
    async (presignedUrl: IPresignedUrl, file: File) => {
      const data = new FormData();
      fillS3FormData(data, presignedUrl, file);

      const response = await axios.request<void>({
        method: 'post',
        url: presignedUrl.url,
        data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    },
    [fillS3FormData]
  );

  const uploadImage = useCallback(
    async (presignedUrl: IPresignedUrl) => {
      const response = await request<IImage>({
        method: 'post',
        url: endpoints.assets.images.post,
        data: {
          image_high_url: presignedUrl.url + presignedUrl.fields.key,
        },
      });

      return response.data;
    },
    [request]
  );

  const uploadFile = useCallback(
    async (presignedUrl: IPresignedUrl) => {
      const response = await request<IFile>({
        method: 'post',
        url: endpoints.assets.files.post,
        data: {
          url: presignedUrl.url + presignedUrl.fields.key,
        },
      });

      return response.data;
    },
    [request]
  );

  return {
    getPresignedImageUrl,
    getPresignedFileUrl,
    uploadS3Image,
    uploadS3File,
    uploadImage,
    uploadFile,
  };
};

export default useAssets;
