import { useCallback } from 'react';

import useMovementReports from 'hooks/MovementReports';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const useMovements = () => {
  const { request } = useApi();
  const { scope } = useScope();
  const { generateMovementReportRequestObject } = useMovementReports();

  const listMovements = useCallback(
    async (movement: IMovementQueryParams) => {
      const response = await request<IPaginated<IMovement>>({
        method: 'get',
        url: endpoints.financial.movements.list,
        headers: { scope },
        params: {
          page: movement.page,
          search: movement.search || undefined,
          ordering: 'accrual_date',
          ...generateMovementReportRequestObject(movement),
        },
      });
      return response.data;
    },
    [generateMovementReportRequestObject, request, scope]
  );

  return { listMovements };
};

export default useMovements;
