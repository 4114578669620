import React, { useCallback, useState } from 'react';

import { AxiosError } from 'axios';
import { currency } from 'b2utils';
import { B2TableRow } from 'react-b2components';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { formatDate } from 'utils/formats';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useExpensePayments, useQueryParams } from '@hooks';

import BasePaymentListingFilters from '@components/BasePaymentListingFilters';
import CommonCard from '@components/CommonCard';
import DeleteModal from '@components/DeleteModal';
import { TableDataCell } from '@components/Table/styles';
import TableVariant from '@components/TableVariant';

import { PaymentDescription } from '@pages/Clients/DetailClient/IncomesPaymentsList/styles';
import { TrashIcon } from '@pages/Financial/FinancialDashboard/styles';

const ExpensesPaymentsList: React.FC = () => {
  const { providerId } = useParams<{ providerId: string }>();

  const { queryParams, setQueryParams, onSearch, changePage } =
    useQueryParams<IExpensePaymentsQueryParams>({
      page: 1,
      provider: Number(providerId),
    });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedExpensePayment, setSelectedExpensePayment] =
    useState<IExpensePayment>();

  const { listExpensePayments, deleteExpensePayment } = useExpensePayments();
  const { addToast } = useToast();

  const {
    data: expensesPayments,
    refetch: refetchExpensesPayments,
    isLoading,
  } = useQuery(
    ['expensesPayments', queryParams],
    () => listExpensePayments(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista de pagamentos de despesas',
          'error'
        );
      },
      enabled: !!providerId,
    }
  );

  const {
    mutate: requestDeleteExpensePayment,
    isLoading: isDeleteExpensePaymentLoading,
  } = useMutation(
    (password: string) =>
      deleteExpensePayment(selectedExpensePayment!.id, password),
    {
      onSuccess: () => {
        refetchExpensesPayments();
        setIsDeleteModalOpen(false);
        addToast('Pagamento deletado com sucesso', 'success');
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível deletar o pagamento', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const handleDeleteExpensePaymentClick = useCallback(
    (expensesPayment: IExpensePayment) => {
      setIsDeleteModalOpen(true);
      setSelectedExpensePayment(expensesPayment);
    },
    []
  );

  const handleDeleteExpensePayment = useCallback(
    (password?: string) => {
      if (password) {
        requestDeleteExpensePayment(password);
      }
    },
    [requestDeleteExpensePayment]
  );

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
    setSelectedExpensePayment(undefined);
  }, []);

  const getPaymentDescription = useCallback((payment: IExpensePayment) => {
    return payment.expenses
      .map(
        (paymentToExpense) =>
          `${currency.centsToBrl(paymentToExpense.value)} destinado o serviço ${
            paymentToExpense.description
          }`
      )
      .join('\n\n');
  }, []);

  return (
    <CommonCard title="Listas de pagamentos de despesas">
      <Tooltip id="payment-to-expense-list-tooltip" />
      <BasePaymentListingFilters
        searchPlaceholder="Buscar por descrição"
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onSearch={onSearch}
      />
      <TableVariant
        data={expensesPayments?.results || []}
        isLoading={isLoading}
        headerData={[
          'Competência',
          'Método de pagamento',
          'Conta bancária',
          'Valor pago',
          'Descrição',
          'Ação',
        ]}
        emptyMessage="Nenhum pagamento encontrado"
        renderRow={(paymentToExpense) => (
          <B2TableRow key={paymentToExpense.id}>
            <TableDataCell>
              {formatDate(paymentToExpense.accrual_date)}
            </TableDataCell>
            <TableDataCell>
              {paymentToExpense.payment_method.name}
            </TableDataCell>
            <TableDataCell>{paymentToExpense.bank_account.name}</TableDataCell>
            <TableDataCell>
              {currency.brlMask(paymentToExpense.value.toString())}
            </TableDataCell>
            <TableDataCell>
              <PaymentDescription>
                {getPaymentDescription(paymentToExpense)}
              </PaymentDescription>
            </TableDataCell>
            <TableDataCell>
              <TrashIcon
                data-tooltip-id="payment-to-expense-list-tooltip"
                data-tooltip-content="Deletar pagamento"
                onClick={() =>
                  handleDeleteExpensePaymentClick(paymentToExpense)
                }
              />
            </TableDataCell>
          </B2TableRow>
        )}
        hasClick={false}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={expensesPayments?.count}
        changePage={changePage}
      />
      {isDeleteModalOpen && selectedExpensePayment && (
        <DeleteModal
          title={`Deseja realmente deletar o pagamento do dia ${formatDate(
            selectedExpensePayment.accrual_date
          )} no valor de ${currency.brlMask(
            selectedExpensePayment.value.toString()
          )}?`}
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          onConfirm={handleDeleteExpensePayment}
          isLoading={isDeleteExpensePaymentLoading}
          passwordRequired
        />
      )}
    </CommonCard>
  );
};

export default ExpensesPaymentsList;
