import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { CloseCircle } from 'iconsax-react';
import { useMutation } from 'react-query';
import { useTheme } from 'styled-components';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useFiscal } from '@hooks';

import Loading from '@components/Loading';

import { CustomButton, CustomTooltip } from '../styles';
import CancelCteModal from './CancelCteModal';

interface CancelCteButtonProps {
  cte: ICte;
  onRequestSuccess: () => void;
}

const CancelCteButton: React.FC<CancelCteButtonProps> = ({
  cte,
  onRequestSuccess,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { cancelCte } = useFiscal();
  const { addToast } = useToast();
  const theme = useTheme();

  const { mutate: requestCancelCte, isLoading: isCancelCteRequestLoading } =
    useMutation((values: ICancelFiscalDocument) => cancelCte(cte.id, values), {
      onSuccess: () => {
        onRequestSuccess();
        setIsModalOpen(false);
        addToast('CT-e cancelado com sucesso', 'success');
      },
      onError: (error: AxiosError) => {
        addToast('Erro ao cancelar CT-e', 'error');
        throwToastApiErrors(error, addToast);
      },
    });

  return (
    <>
      <CustomTooltip id="cancel-cte-tooltip" />
      <CustomButton
        data-tooltip-id="cancel-cte-tooltip"
        data-tooltip-content="Cancelar CT-e"
        isCancelButton
        onClick={() => !isCancelCteRequestLoading && setIsModalOpen(true)}
      >
        {isCancelCteRequestLoading ? (
          <Loading color={theme.colors.white.main} />
        ) : (
          <>
            <CloseCircle variant="Bold" />
            <span>Cancelar CT-e</span>
          </>
        )}
      </CustomButton>
      <CancelCteModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={requestCancelCte}
        isLoading={isCancelCteRequestLoading}
      />
    </>
  );
};

export default CancelCteButton;
