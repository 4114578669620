import React, { useCallback } from 'react';

import moment from 'moment';
import { QuotationStatus } from 'utils/enums';

import { useCompanyUsers } from '@hooks';

import ChooseItemFilter from '@components/ChooseItemFilter';
import DateRangeCalendarFilter from '@components/DateRangeCalendarFilter';
import ExpandableFilter from '@components/ExpandableFilter';
import ListFilters from '@components/ListFilters';
import Select from '@components/Select';

import { Container } from './styles';

interface QuotationFiltersProps {
  queryParams: IQuotationQueryParams;
  setQueryParams: React.Dispatch<React.SetStateAction<IQuotationQueryParams>>;
  onSearch: (value: string) => void;
}

const QuotationFilters: React.FC<QuotationFiltersProps> = ({
  queryParams,
  setQueryParams,
  onSearch,
}) => {
  const { listCompanyUsers } = useCompanyUsers();

  const quotationStatuses = Object.values(QuotationStatus);

  const emptyPersonInCharge = {
    id: 0,
    name: 'Todos',
  } as ICompanyUser;

  const handleCreatedDateRangeFilterSelected = (
    startDate?: Date,
    endDate?: Date
  ) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      createdDateGte: startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : undefined,
      createdDateLte: endDate
        ? moment(endDate).format('YYYY-MM-DD')
        : undefined,
    }));
  };

  const handleEstimatedDateRangeFilterSelected = (
    startDate?: Date,
    endDate?: Date
  ) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      estimatedShippingDateGte: startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : undefined,
      estimatedShippingDateLte: endDate
        ? moment(endDate).format('YYYY-MM-DD')
        : undefined,
    }));
  };

  const handleSelectPersonInCharge = (personInCharge: ICompanyUser) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      personInCharge,
    }));
  };

  const handleSelectExpenseType = useCallback(
    (status: string) => {
      setQueryParams((prevState) => ({
        ...prevState,
        page: 1,
        status: status ? (status as QuotationStatus) : undefined,
      }));
    },
    [setQueryParams]
  );

  return (
    <Container>
      <ListFilters
        searchPlaceholder="Procurar por número, cliente ou por justificativa"
        onSearch={onSearch}
        variant
      >
        <DateRangeCalendarFilter
          clearableDateRange
          label="Filtrar por data de criação"
          onDateRangeFilterSelected={handleCreatedDateRangeFilterSelected}
        />
        <DateRangeCalendarFilter
          clearableDateRange
          label="Filtrar por previsão de envio"
          onDateRangeFilterSelected={handleEstimatedDateRangeFilterSelected}
        />
      </ListFilters>
      <ExpandableFilter>
        <ChooseItemFilter
          filterValue={
            queryParams?.personInCharge?.id &&
            queryParams?.personInCharge?.id !== 0
              ? queryParams?.personInCharge?.name
              : 'Responsáveis: todos'
          }
          modalTitle="Filtrar serviços por responsável"
          fetch={listCompanyUsers}
          queryParams={{ isActive: 'true' }}
          queryKey="companyUsers"
          inputLabel="Selecionar responsável *"
          selectedItemLabel="Responsável selecionado"
          shouldRenderSelectedItem
          onSelect={handleSelectPersonInCharge}
          selectedItem={queryParams.personInCharge || emptyPersonInCharge}
          additionalItem={emptyPersonInCharge}
          renderItemText={(personInCharge) => personInCharge.name}
        />
        <Select
          value={queryParams?.status || ''}
          onChange={(event) => handleSelectExpenseType(event.target.value)}
        >
          <option value="">Status: todos</option>
          {quotationStatuses.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </Select>
      </ExpandableFilter>
    </Container>
  );
};

export default QuotationFilters;
