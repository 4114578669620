import RoutesPath from '@router/routes';

const routes = [
  { path: RoutesPath.private.drivers.listDrivers.path, label: 'Motoristas' },
  {
    path: RoutesPath.private.drivers.detailDriver.path,
    label: 'Detalhes do motorista',
  },
];

export default routes;
