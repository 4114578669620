import React, { useState } from 'react';

import { DocumentCode, DocumentDownload, DocumentText } from 'iconsax-react';

import DownloadAnchor from '@components/DownloadAnchor';

import { CustomTooltip } from '../styles';
import {
  ArrowIcon,
  CustomButton,
  VerticalDivider,
  OptionsContainer,
  OptionsWrapper,
  HorizontalDivider,
  ButtonGroup,
} from './styles';

interface DownloadsButtonProps {
  mdfe: IMdfe;
}

const DownloadsButton: React.FC<DownloadsButtonProps> = ({ mdfe }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <CustomTooltip id="download-mdfe-documents-tooltip" />
      <CustomButton
        data-tooltip-id="download-mdfe-documents-tooltip"
        data-tooltip-content={isExpanded ? undefined : 'Baixar documentos'}
        $expanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <DocumentDownload variant="Bold" />
        <span>Baixar documentos</span>
        <ButtonGroup>
          <VerticalDivider />
          <ArrowIcon $expanded={isExpanded} />
        </ButtonGroup>
      </CustomButton>
      <OptionsContainer $expanded={isExpanded}>
        <OptionsWrapper>
          <DownloadAnchor
            href={mdfe.pdf_file?.url || ''}
            label="MDF-e em PDF"
            icon={DocumentText}
          />
          <HorizontalDivider />
          <DownloadAnchor
            href={mdfe.xml_file?.url || ''}
            label="MDF-e em XML"
            icon={DocumentCode}
          />
          {mdfe.cancel_pdf_file && (
            <>
              <HorizontalDivider />
              <DownloadAnchor
                href={mdfe.cancel_pdf_file.url}
                label="PDF de cancelamento"
                icon={DocumentText}
              />
            </>
          )}
          {mdfe.cancel_xml_file && (
            <>
              <HorizontalDivider />
              <DownloadAnchor
                href={mdfe.cancel_xml_file.url}
                label="XML de cancelamento"
                icon={DocumentCode}
              />
            </>
          )}
          {mdfe.closure_pdf_file && (
            <>
              <HorizontalDivider />
              <DownloadAnchor
                href={mdfe.closure_pdf_file.url}
                label="PDF de fechamento"
                icon={DocumentText}
              />
            </>
          )}
          {mdfe.closure_xml_file && (
            <>
              <HorizontalDivider />
              <DownloadAnchor
                href={mdfe.closure_xml_file.url}
                label="XML de fechamento"
                icon={DocumentCode}
              />
            </>
          )}
        </OptionsWrapper>
      </OptionsContainer>
    </>
  );
};

export default DownloadsButton;
