import { cnpj, cpf } from 'b2utils';

import regex from './regex';

export const formatDocument = (document: string) => {
  const documentWithoutMask = document.replace(regex.onlyNumbers, '');

  if (documentWithoutMask.length <= 11) {
    return cpf.mask(documentWithoutMask);
  }
  return cnpj.mask(documentWithoutMask);
};

export const formatDate = (str: string | Date) => {
  if (!str) {
    return '';
  }

  const formattedDate =
    str instanceof Date
      ? str.toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
        })
      : new Date(str).toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
        });

  return formattedDate;
};

export const formatToBrlDate = (date: string | Date) => {
  const dateInstance = date instanceof Date ? date : new Date(date);

  const formattedDate = dateInstance
    .toLocaleDateString('pt-br', {
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(',', ' às');

  return formattedDate;
};
