import React from 'react';

import {
  Container,
  FileNameContainer,
  FileName,
  OpenInNewIcon,
  DeleteIcon,
} from './styles';

interface FileItemProps {
  file: IFile;
  onDeleteFile?: (imagePk: number) => void;
  isDeleteDisabled?: boolean;
}

const FileItem: React.FC<FileItemProps> = ({
  file,
  onDeleteFile,
  isDeleteDisabled,
}) => {
  return (
    <Container>
      {onDeleteFile && (
        <DeleteIcon
          disabled={isDeleteDisabled}
          onClick={(event) => {
            event.preventDefault();
            !isDeleteDisabled && onDeleteFile(file.id);
          }}
        />
      )}
      <FileNameContainer
        href={file.url}
        download
        target="_blank"
        rel="noreferrer"
      >
        <FileName>{file.url.split('/')[5]}</FileName>
        <OpenInNewIcon />
      </FileNameContainer>
    </Container>
  );
};

export default FileItem;
