import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import CategoryBaseForm from '@pages/Categories/Form/CategoryBaseForm';
import { errors } from '@utils';

interface ICategoryFormProps {
  initialValues?: ICategoryBaseFormValues;
  onFinish: (data: ICategoryBaseFormValues) => void;
}

const IncomeCategoryForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  ICategoryFormProps
> = ({ onFinish, initialValues }, ref) => {
  const formikInitialValues: ICategoryBaseFormValues = {
    name: '',
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    name: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <CategoryBaseForm />
    </FormikProvider>
  );
};

export default forwardRef(IncomeCategoryForm);
