import React, { useCallback, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { currency } from 'b2utils';
import { B2TableRow } from 'react-b2components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Tooltip } from 'react-tooltip';
import { formatDate } from 'utils/formats';
import { getIncomeDescription, throwToastApiErrors } from 'utils/helpers';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useIncomePayments, useQueryParams } from '@hooks';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';
import BasePaymentListingFilters from '@components/BasePaymentListingFilters';
import DeleteModal from '@components/DeleteModal';
import ModalTable from '@components/ModalTable';
import PaymentInfo from '@components/PaymentInfo';
import { TableDataCell } from '@components/Table/styles';

import { ModalContent, TrashIcon } from '../../styles';

interface PaymentsListModalProps
  extends Pick<IBaseDeleteModalProps, 'isOpen' | 'onClose'> {
  income?: IIncome;
  client?: IClientUser;
  refetchIncomes: () => void;
}

const PaymentsListModal: React.FC<PaymentsListModalProps> = ({
  isOpen,
  onClose,
  income,
  client,
  refetchIncomes,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedIncomesPayment, setSelectedIncomesPayment] =
    useState<IIncomePayment>();

  const { queryParams, setQueryParams, onSearch, changePage } =
    useQueryParams<IIncomePaymentsQueryParams>({
      page: 1,
      income: income && !client ? income?.id : undefined,
      client: client && !income ? client?.id : undefined,
    });

  const { listIncomePayments, deleteIncomesPayment } = useIncomePayments();
  const { scope } = useScope();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const {
    data: incomePayments,
    refetch: refetchIncomePayments,
    isLoading,
  } = useQuery(
    ['incomePayments', queryParams, scope],
    () => listIncomePayments(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista pagamentos do recebível',
          'error'
        );
      },
    }
  );

  const {
    mutate: requestDeleteIncomesPayment,
    isLoading: isDeleteIncomesPaymentLoading,
  } = useMutation(
    (password: string) =>
      deleteIncomesPayment(selectedIncomesPayment!.id, password),
    {
      onSuccess: () => {
        refetchIncomePayments();
        refetchIncomes();
        queryClient.invalidateQueries('financialForecast');
        setIsDeleteModalOpen(false);
        addToast('Pagamento deletado com sucesso', 'success');
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível deletar o pagamento', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const handleDeleteIncomesPaymentClick = useCallback(
    (incomesPayment: IIncomePayment) => {
      setIsDeleteModalOpen(true);
      setSelectedIncomesPayment(incomesPayment);
    },
    []
  );

  const handleDeleteIncomesPayment = useCallback(
    (password?: string) => {
      if (password) {
        requestDeleteIncomesPayment(password);
      }
    },
    [requestDeleteIncomesPayment]
  );

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
    setSelectedIncomesPayment(undefined);
  }, []);

  const modalTitle = useMemo(() => {
    if (income) {
      return `Listagem de pagamentos do recebível: ${getIncomeDescription(
        income
      )}`;
    }
    return `Listagem de pagamentos do cliente: ${client?.name}`;
  }, [client?.name, income]);

  return (
    <CustomBaseModal title={modalTitle} isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <Tooltip id="income-payments-list-tooltip" />
        {income && (
          <PaymentInfo value={income.value} paidValue={income.paid_value} />
        )}
        <BasePaymentListingFilters
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          onSearch={onSearch}
        />
        <ModalTable
          data={incomePayments?.results || []}
          isLoading={isLoading}
          headerData={[
            'Competência',
            'Método de pagamento',
            'Conta bancária',
            'Valor pago',
            'Observações',
            'Ação',
          ]}
          emptyMessage="Nenhum pagamento encontrado"
          renderRow={(incomePayment) => (
            <B2TableRow key={incomePayment.id}>
              <TableDataCell>
                {formatDate(incomePayment.accrual_date)}
              </TableDataCell>
              <TableDataCell>{incomePayment.payment_method.name}</TableDataCell>
              <TableDataCell>{incomePayment.payment_method.name}</TableDataCell>
              <TableDataCell>
                {currency.brlMask(incomePayment.value.toString())}
              </TableDataCell>
              <TableDataCell>{incomePayment.observations || '-'}</TableDataCell>
              <TableDataCell>
                <TrashIcon
                  data-tooltip-id="income-payments-list-tooltip"
                  data-tooltip-content="Deletar pagamento"
                  onClick={() => handleDeleteIncomesPaymentClick(incomePayment)}
                />
              </TableDataCell>
            </B2TableRow>
          )}
          hasClick={false}
          paginator
          amountPerPage={20}
          currentPage={queryParams.page}
          total={incomePayments?.count}
          changePage={changePage}
        />
        {isDeleteModalOpen && selectedIncomesPayment && (
          <DeleteModal
            title={`Deseja realmente deletar o pagamento do dia ${formatDate(
              selectedIncomesPayment.accrual_date
            )} no valor de ${currency.brlMask(
              selectedIncomesPayment.value.toString()
            )}?`}
            isOpen={isDeleteModalOpen}
            onClose={handleCloseDeleteModal}
            onConfirm={handleDeleteIncomesPayment}
            isLoading={isDeleteIncomesPaymentLoading}
            passwordRequired
          />
        )}
      </ModalContent>
    </CustomBaseModal>
  );
};

export default PaymentsListModal;
