import React from 'react';

import { Container, DeleteDocumentIcon, DocumentName } from './styles';

interface DocumentPreviewProps {
  document?: IFile | null;
  onDeleteDocument: () => void;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  document,
  onDeleteDocument,
}) => {
  if (!document) {
    return null;
  }

  return (
    <Container href={document.url} target="_blank" rel="noreferrer">
      <DocumentName>{document.url}</DocumentName>
      <DeleteDocumentIcon
        variant="Bold"
        onClick={(event) => {
          event.preventDefault();
          onDeleteDocument();
        }}
      />
    </Container>
  );
};

export default DocumentPreview;
