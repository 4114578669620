import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};
  margin-top: ${({ theme }) => theme.spacing.two};
`;

export const Circle = styled.div<{ color: string }>`
  width: ${({ theme }) => theme.spacing.four};
  height: ${({ theme }) => theme.spacing.four};
  border-radius: 4px;
  background-color: ${({ color }) => color};
`;
export const Label = styled.p`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
