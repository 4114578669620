import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { cnpj, phone } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useTheme } from 'styled-components';
import regex from 'utils/regex';
import * as yup from 'yup';

import { useCompanies, useDebounce } from '@hooks';

import { ColorPicker } from '@components/ColorPicker';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import ImagePicker from '@components/ImagePicker';
import Input from '@components/Input';
import Label from '@components/Label';

import { errors } from '@utils';

import { ImageContainer, PickerColorContainer } from './styles';

interface IValidateProps {
  email: string;
  scope: string;
}

interface ICompanyInfoProps {
  initialValues?: ICompanyFormInfo;
  onFinish: (data: ICompanyFormInfo) => void;
  validateValues?: IValidateProps;
}

interface IValidateFieldValueDebounce {
  initialValue: string;
  value: string;
  validateValue: (value: string) => void;
  setIsValid: (isValid: boolean) => void;
}

const CompanyInfo: React.ForwardRefRenderFunction<
  IFormStepRef,
  ICompanyInfoProps
> = ({ onFinish, initialValues, validateValues }, ref) => {
  const [isValidScope, setIsValidScope] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const theme = useTheme();
  const { checkCompanyField } = useCompanies();

  const formikInitialValues: ICompanyFormInfo = {
    logo: {
      id: 0,
      image_high_url: '',
      image_low_url: '',
      image_medium_url: '',
    },
    primaryColor: theme.colors.main,
    secondaryColor: theme.colors.secondary,
    name: '',
    cnpj: '',
    antt: '',
    stateRegistration: '',
    phone: '',
    webSiteUrl: '',
    email: '',
    budgetExpiration: '',
    scope: '',
    ...initialValues,
  };

  const initialValidValues = {
    email: validateValues?.email || '',
    scope: validateValues?.scope || '',
  };

  const { mutate: handleCheckScope } = useMutation(
    (scope: string) =>
      checkCompanyField({
        scope,
      }),
    {
      onSuccess: (data) => {
        setIsValidScope(data.scope_is_available);
      },
    }
  );

  const { mutate: handleCheckEmail } = useMutation(
    (email: string) =>
      checkCompanyField({
        email,
      }),
    {
      onSuccess: (data) => {
        setIsValidEmail(data.email_is_available);
      },
    }
  );

  const debounceFn = useDebounce(
    ({
      initialValue,
      value,
      validateValue,
      setIsValid,
    }: IValidateFieldValueDebounce) => {
      if (!value.length) {
        setIsValid(false);
        return;
      }
      if (initialValue && initialValue.toLowerCase() === value.toLowerCase()) {
        setIsValid(true);
        return;
      }
      validateValue(value);
      return;
    },
    500
  );

  const validationSchema = yup.object().shape({
    scope: yup
      .string()
      .trim()
      .test('check-scope-is-valid', errors.scope, () => isValidScope)
      .required(errors.required),
    logo: yup.object().shape({
      image_high_url: yup.string().trim().required(errors.required),
    }),
    name: yup.string().trim().required(errors.required),
    cnpj: yup
      .string()
      .trim()
      .test(
        'is-cnpj-valid',
        errors.cnpj,
        (value) => !!value && cnpj.validate(value)
      )
      .required(errors.required),
    antt: yup
      .string()
      .trim()
      .length(8, errors.length(8))
      .required(errors.required),
    stateRegistration: yup
      .string()
      .trim()
      .min(2, errors.minLength(2))
      .max(14, errors.maxLength(14))
      .required(errors.required),
    phone: yup
      .string()
      .trim()
      .required(errors.required)
      .test(
        'is-phone-valid',
        errors.phone,
        (value) => !!value && phone.validate(value)
      ),
    email: yup
      .string()
      .test('check-email-is-valid', errors.email, () => isValidEmail)
      .email(errors.email)
      .required(errors.required),
    webSiteUrl: yup
      .string()
      .test(
        'is-http-missing',
        errors.urlHttp,
        (value) => !!value && value.includes('http')
      )
      .url(errors.url)
      .required(errors.required),

    budgetExpiration: yup
      .string()
      .trim()
      .max(9, errors.maxLength(9))
      .required(errors.required),
    primaryColor: yup.string().trim().required(errors.required),
    secondaryColor: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label>Adicione o logo da empresa *</Label>
          <ImageContainer>
            <ImagePicker
              text="Escolha uma imagem"
              alt="Logo da empresa"
              name="logo"
              formik={formik}
              src={formik.values.logo?.image_low_url}
              onInvalidExtension="Formato inválido da logo"
              onInvalidSize="Tamanho máximo permitido da logo é 5MB"
            />
          </ImageContainer>
          <FormError name="logo.image_high_url" />
        </FormGroup>
        <FormGroup>
          <PickerColorContainer>
            <Label>Cor primária *</Label>
            <ColorPicker
              color={formik.values.primaryColor}
              onChange={(colorEvent) =>
                formik.setFieldValue('primaryColor', colorEvent)
              }
            />
            <FormError name="primaryColor" />
            <Label>Cor secundária *</Label>
            <ColorPicker
              color={formik.values.secondaryColor}
              onChange={(colorEvent) =>
                formik.setFieldValue('secondaryColor', colorEvent)
              }
            />
            <FormError name="secondaryColor" />
          </PickerColorContainer>
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="name">Nome *</Label>
          <Input
            id="name"
            name="name"
            placeholder="Nome da empresa"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            invalidValue={!!formik.touched.name && !!formik.errors.name}
          />
          <FormError name="name" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="scope">Identificador da empresa *</Label>
          <Input
            id="scope"
            name="scope"
            placeholder="Id de acesso da empresa"
            onChange={(event) => {
              formik.handleChange(event);
              setIsValidScope(false);
              debounceFn({
                initialValue: initialValidValues.scope,
                value: event.target.value,
                validateValue: handleCheckScope,
                setIsValid: setIsValidScope,
              });
            }}
            invalidValue={!!formik.touched.scope && !!formik.errors.scope}
            onBlur={formik.handleBlur}
            value={formik.values.scope}
          />
          <FormError name="scope" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="cnpj">CNPJ *</Label>
          <Input
            id="cnpj"
            name="cnpj"
            placeholder="Digite o CNPJ"
            onChange={(event) =>
              formik.setFieldValue('cnpj', cnpj.mask(event.target.value))
            }
            onBlur={formik.handleBlur}
            value={formik.values.cnpj}
            invalidValue={!!formik.touched.cnpj && !!formik.errors.cnpj}
          />
          <FormError name="cnpj" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="antt">ANTT *</Label>
          <Input
            id="antt"
            name="antt"
            placeholder="Digite o ANTT"
            maxLength={8}
            onChange={(event) =>
              formik.setFieldValue(
                'antt',
                event.target.value.replace(regex.onlyNumbers, '')
              )
            }
            onBlur={formik.handleBlur}
            value={formik.values.antt}
            invalidValue={!!formik.touched.antt && !!formik.errors.antt}
          />
          <FormError name="antt" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="stateRegistration">Inscrição estadual *</Label>
          <Input
            id="stateRegistration"
            name="stateRegistration"
            placeholder="Digite a inscrição estadual"
            minLength={2}
            maxLength={14}
            value={formik.values.stateRegistration}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'stateRegistration',
                event.target.value.replace(regex.onlyNumbers, '')
              )
            }
            invalidValue={
              !!formik.touched.stateRegistration &&
              !!formik.errors.stateRegistration
            }
          />
          <FormError name="stateRegistration" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="phone">Telefone *</Label>
          <Input
            id="phone"
            name="phone"
            placeholder="Digite o telefone da empresa"
            onChange={(event) =>
              formik.setFieldValue('phone', phone.mask(event.target.value))
            }
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            maxLength={15}
            invalidValue={!!formik.touched.phone && !!formik.errors.phone}
          />
          <FormError name="phone" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="webSiteUrl">Site *</Label>
          <Input
            id="webSiteUrl"
            name="webSiteUrl"
            placeholder="Digite a URL do site"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.webSiteUrl}
            invalidValue={
              !!formik.touched.webSiteUrl && !!formik.errors.webSiteUrl
            }
          />
          <FormError name="webSiteUrl" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Email *</Label>
          <Input
            id="email"
            name="email"
            placeholder="Digite o email da empresa"
            onChange={(event) => {
              formik.handleChange(event);
              setIsValidEmail(false);
              debounceFn({
                initialValue: initialValidValues.email,
                value: event.target.value,
                validateValue: handleCheckEmail,
                setIsValid: setIsValidEmail,
              });
            }}
            invalidValue={!!formik.touched.email && !!formik.errors.email}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          <FormError name="email" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="budgetExpiration">
            Validade do orçamento (em dias) *
          </Label>
          <Input
            id="budgetExpiration"
            name="budgetExpiration"
            placeholder="Digite a validade do orçamento"
            maxLength={9}
            onChange={(event) =>
              formik.setFieldValue(
                'budgetExpiration',
                event.target.value.replace(regex.onlyNumbers, '')
              )
            }
            onBlur={formik.handleBlur}
            value={formik.values.budgetExpiration}
            invalidValue={
              !!formik.touched.budgetExpiration &&
              !!formik.errors.budgetExpiration
            }
          />
          <FormError name="budgetExpiration" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(CompanyInfo);
