import React, { useMemo, useRef, useState } from 'react';

import ReactDOM from 'react-dom';
import { useReactToPrint } from 'react-to-print';
import { Tooltip } from 'react-tooltip';
import { DepartureStatus } from 'utils/enums';
import { mdfeIssuanceStatusProps } from 'utils/helpers';

import FinishDepartureModal from '@components/FinishDepartureModal';
import Loading from '@components/Loading';
import MdfeIssuanceModal from '@components/MdfeIssuanceModal';
import StartDepartureModal from '@components/StartDepartureModal';

import DeparturePdf from './DeparturePdf';
import {
  Container,
  TickCircleIcon,
  TruckFastIcon,
  TruckRemoveIcon,
  TruckTickIcon,
  TruckTimeIcon,
  PdfReportIcon,
} from './styles';

interface DepartureActionIconsProps {
  departure: IDeparture;
  refetchDeparture: () => void;
  onFinalizeSuccess: () => void;
  className?: string;
}

const DepartureActionIcons: React.FC<DepartureActionIconsProps> = ({
  departure,
  refetchDeparture,
  onFinalizeSuccess,
  className,
}) => {
  const departurePdfRef = useRef<HTMLDivElement>(null);

  const [isOpenPrint, setIsOpenPrint] = useState(false);

  const [isMdfeModalOpen, setIsMdfeModalOpen] = useState(false);
  const [isStartDepartureModalOpen, setIsStartDepartureModalOpen] =
    useState(false);
  const [isFinishDepartureModalOpen, setIsFinishDepartureModalOpen] =
    useState(false);

  const handlePrint = useReactToPrint({
    documentTitle: `RELAÇÃO DE CARGA - ${departure.origin.name}/${departure.origin.state} x ${departure.destination.name}/${departure.destination.state} `,
    content: () => departurePdfRef.current,
    onAfterPrint: () => setIsOpenPrint(false),
  });

  const container = useMemo(() => document.getElementById('app'), []);

  const isStarted = departure.status !== DepartureStatus.WAITING_START;

  const truckIcon = {
    [DepartureStatus.WAITING_START]: (
      <TruckTimeIcon
        $isStarted={isStarted}
        data-tooltip-id="departure-action-icons-tooltip"
        data-tooltip-content="Iniciar embarque"
        variant="Bold"
        onClick={(event) => {
          event.preventDefault();
          setIsStartDepartureModalOpen(true);
        }}
      />
    ),
    [DepartureStatus.IN_TRAFFIC]: (
      <TruckFastIcon
        $isStarted={isStarted}
        data-tooltip-id="departure-action-icons-tooltip"
        data-tooltip-content="Acompanhar serviços"
        variant="Bold"
        onClick={(event) => {
          event.preventDefault();
          setIsStartDepartureModalOpen(true);
        }}
      />
    ),
    [DepartureStatus.FINISHED]: (
      <TruckTickIcon
        $isStarted={isStarted}
        data-tooltip-id="departure-action-icons-tooltip"
        data-tooltip-content="Acompanhar serviços"
        variant="Bold"
        onClick={(event) => {
          event.preventDefault();
          setIsStartDepartureModalOpen(true);
        }}
      />
    ),
    [DepartureStatus.CANCELED]: (
      <TruckRemoveIcon
        $isStarted={isStarted}
        data-tooltip-id="departure-action-icons-tooltip"
        data-tooltip-content="Acompanhar serviços"
        variant="Bold"
        onClick={(event) => {
          event.preventDefault();
          setIsStartDepartureModalOpen(true);
        }}
      />
    ),
  };

  return (
    <>
      <Container className={className}>
        <Tooltip id="departure-action-icons-tooltip" />
        {truckIcon[departure.status]}
        <>
          <img
            src={
              mdfeIssuanceStatusProps[departure.mdfe.issuance_status]
                .imageSource
            }
            alt="MDF-e ícone"
            data-tooltip-id="departure-action-icons-tooltip"
            data-tooltip-content={
              mdfeIssuanceStatusProps[departure.mdfe.issuance_status]
                .tooltipMessage
            }
            onClick={(e) => {
              e.preventDefault();
              setIsMdfeModalOpen(true);
            }}
          />
        </>
        {departure.status === DepartureStatus.IN_TRAFFIC && (
          <>
            <TickCircleIcon
              data-tooltip-id="departure-action-icons-tooltip"
              data-tooltip-content="Finalizar embarque"
              variant="Bold"
              onClick={(event) => {
                event.preventDefault();
                setIsFinishDepartureModalOpen(true);
              }}
            />
          </>
        )}

        {isOpenPrint ? (
          <Loading />
        ) : (
          <PdfReportIcon
            data-tooltip-id="departure-action-icons-tooltip"
            data-tooltip-content="Baixar relatório"
            variant="Bold"
            onClick={(event) => {
              event.preventDefault();
              setIsOpenPrint(true);
            }}
          />
        )}
        <DeparturePdf
          departure={departure}
          isEnableRequest={isOpenPrint}
          handlePrint={handlePrint}
          ref={departurePdfRef}
        />
      </Container>
      {ReactDOM.createPortal(
        <>
          <StartDepartureModal
            departure={departure}
            isOpen={isStartDepartureModalOpen}
            onClose={() => setIsStartDepartureModalOpen(false)}
          />
          <MdfeIssuanceModal
            departure={departure}
            isOpen={isMdfeModalOpen}
            onClose={() => setIsMdfeModalOpen(false)}
            refetchDeparture={refetchDeparture}
          />
          {isFinishDepartureModalOpen && (
            <FinishDepartureModal
              departure={departure}
              isOpen={isFinishDepartureModalOpen}
              onClose={() => setIsFinishDepartureModalOpen(false)}
              onFinalizeSuccess={onFinalizeSuccess}
            />
          )}
        </>,
        container!
      )}
    </>
  );
};

export default DepartureActionIcons;
