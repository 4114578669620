import React, { useRef } from 'react';

import { currency } from 'b2utils';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useExpenses, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';
import Loading from '@components/Loading';

import RoutesPath from '@router/routes';

import ExpenseInfoForm from '../Form/ExpenseForm/ExpenseInfoForm';
import routes from './routes';

const UpdateExpense: React.FC = () => {
  const { expenseId } = useParams<{ expenseId: string }>();
  const expenseFormRef = useRef<IFormStepRef>(null);

  const { getExpense, updateExpense } = useExpenses();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useVerifyScope({
    routePath: RoutesPath.private.financial.expenses.listExpenses.path,
    toastErrorMessage: 'Selecione uma empresa para editar uma despesa',
  });

  const {
    data: expense,
    refetch,
    isLoading,
  } = useQuery(['expense', expenseId], () => getExpense(expenseId!), {
    onError: () => {
      addToast('Falha ao buscar os dados da despesa', 'error');
    },
    enabled: !!expenseId,
  });

  const updateExpenseRequest = useMutation(
    (values: IExpenseFormValues) => updateExpense(expenseId!, values),
    {
      onSuccess: () => {
        refetch();
        addToast('Dados da despesa atualizados com sucesso', 'success');
        navigate(RoutesPath.private.financial.expenses.listExpenses.path);
      },
      onError: () => {
        addToast('Erro ao tentar editar informações da despesa', 'error');
      },
    }
  );

  const getInitialValues = (expense: IExpense): IExpenseFormValues => {
    return {
      value: currency.brlMask(expense.value.toString()),
      date: expense.date,
      type: expense.type,
      provider: expense.provider,
      category: expense.category,
      description: expense.description,
    };
  };

  const steps: Array<IStep> = [
    {
      title: 'Informações da despesa',
      ref: expenseFormRef,
      component: ExpenseInfoForm,
    },
  ];

  if (isLoading || !expense) {
    return <Loading />;
  }

  return (
    <BaseForm
      steps={steps}
      createRequest={updateExpenseRequest}
      routes={routes}
      title="Editar despesa"
      initialValues={getInitialValues(expense)}
    />
  );
};

export default UpdateExpense;
