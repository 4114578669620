import React, { useCallback, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { currency } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import { B2Button, B2TableRow } from 'react-b2components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Tooltip } from 'react-tooltip';
import { PaymentStatus, PaymentStatusMap } from 'utils/enums';
import { formatDate } from 'utils/formats';
import { getIncomeDescription, throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import {
  useIncomePayments,
  useIncomes,
  useNavigateWithScope,
  useQueryParams,
} from '@hooks';

import DeleteModal from '@components/DeleteModal';
import Input from '@components/Input';
import LabelWithPaymentIcon from '@components/LabelWithPaymentIcon';
import { TableDataCell } from '@components/Table/styles';
import TableVariant from '@components/TableVariant';

import RoutesPath from '@router/routes';

import ActionIcon from '../../../../../components/ActionIcon';
import {
  ActionIconsContainer,
  CarIcon,
  EditIcon,
  EmptyWalletIcon,
  PaymentCard,
  PaymentStatusLabel,
  PaymentText,
  TrashIcon,
  WalletTickIcon,
} from '../styles';
import Filters from './Filters';
import IncomePaymentModal from './IncomePaymentModal';
import PaymentsListModal from './PaymentsListModal';
import { incomePaymentFormValidationSchema } from './helpers';

interface IRequestCreateIncomePayment {
  values: IIncomePaymentFormValues;
  resetForm: () => void;
}

interface IncomesListingProps {
  yearMonth: string;
}

const IncomesListing: React.FC<IncomesListingProps> = ({ yearMonth }) => {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isPaymentsListModalOpen, setIsPaymentsListModalOpen] = useState(false);
  const [selectedIncome, setSelectedIncome] = useState<IIncome>();
  const [selectedCliente, setSelectedCliente] = useState<IClientUser>();
  const [paymentToIncomeCopy, setPaymentToIncomesCopy] = useState<
    Array<IPaymentToIncomeFormValues>
  >([]);

  const { queryParams, setQueryParams, onSearch, changePage } =
    useQueryParams<IIncomeQueryParams>({
      page: 1,
      date__gte: moment(yearMonth).startOf('month').format('YYYY-MM-DD'),
      date__lte: moment(yearMonth).endOf('month').format('YYYY-MM-DD'),
    });

  const { listIncomes, deleteIncome } = useIncomes();
  const { createIncomePayment } = useIncomePayments();
  const { scope } = useScope();
  const { isManagementUser, hasAdminPermission } = useAuth();
  const { addToast } = useToast();
  const { navigateWithScope } = useNavigateWithScope();
  const queryClient = useQueryClient();

  const {
    data: incomes,
    refetch: refetchIncomes,
    isLoading,
  } = useQuery(
    ['incomes', queryParams, scope],
    () => listIncomes(queryParams),
    {
      onSuccess: (data) => {
        const updatedSelectedIncome = data.results.find(
          (income) => income.id === selectedIncome?.id
        );

        if (!updatedSelectedIncome) {
          return;
        }

        setSelectedIncome(updatedSelectedIncome);
      },
      onError: () => {
        addToast('Não foi possível carregar a lista de recebíveis', 'error');
      },
    }
  );

  const { mutate: requestDeleteIncome, isLoading: isDeleteIncomeLoading } =
    useMutation(
      (password: string) => deleteIncome(selectedIncome!.id, password),
      {
        onSuccess: () => {
          refetchIncomes();
          queryClient.invalidateQueries('financialForecast');
          setIsDeleteModalOpen(false);
          addToast('Recebível deletado com sucesso', 'success');
        },
        onError: (error: AxiosError) => {
          addToast('Não foi possível deletar o recebível', 'error');
          throwToastApiErrors(error, addToast);
        },
      }
    );

  const { mutate: requestCreatePayment, isLoading: isCreatePaymentLoading } =
    useMutation(
      ({ values }: IRequestCreateIncomePayment) => createIncomePayment(values),
      {
        onSuccess: (_, { resetForm }) => {
          refetchIncomes();
          queryClient.invalidateQueries('financialForecast');
          setIsPaymentModalOpen(false);
          resetForm();
          addToast('Recebimento cadastrado com sucesso', 'success');
        },
        onError: (error: AxiosError) => {
          addToast('Não foi possível adicionar recebimento', 'error');
          throwToastApiErrors(error, addToast);
        },
      }
    );

  const formikInitialValues: IIncomePaymentFormValues = useMemo(
    () => ({
      client: 0,
      value: '',
      accrualDate: '',
      incomes: [],
      paymentMethod: null,
      bankAccount: null,
      observations: '',
    }),
    []
  );

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: incomePaymentFormValidationSchema,
    onSubmit: (values, { resetForm }) => {
      const newValues: IIncomePaymentFormValues = {
        ...values,
        client: values.incomes[0].income.client.id,
      };
      requestCreatePayment({ values: newValues, resetForm });
    },
  });

  const totalRemainingAmount = useMemo(
    () =>
      formik.values.incomes.reduce(
        (total, { income }) => total + income.value - income.paid_value,
        0
      ),
    [formik.values.incomes]
  );

  const incomeList = useMemo(() => incomes?.results || [], [incomes?.results]);

  const selectedClientId = useMemo(() => {
    if (formik.values.incomes.length === 0) {
      return null;
    }
    return formik.values.incomes[0].income.client.id;
  }, [formik.values.incomes]);

  const incomesWithSameClient = useMemo(() => {
    if (!selectedClientId) {
      return [];
    }
    return incomeList.filter((income) => income.client.id === selectedClientId);
  }, [incomeList, selectedClientId]);

  const hasSelectedIncomesInPage = useMemo(
    () =>
      formik.values.incomes.some(({ income }) =>
        incomesWithSameClient.some(({ id }) => id === income.id)
      ),
    [incomesWithSameClient, formik.values.incomes]
  );

  const areAllIncomesSelected = useMemo(
    () =>
      !!incomesWithSameClient.length &&
      incomesWithSameClient.every(
        (income) =>
          formik.values.incomes.some(
            (selectedIncome) => selectedIncome.income.id === income.id
          ) || income.status === PaymentStatus.PAID
      ),
    [incomesWithSameClient, formik.values.incomes]
  );

  const handleSelectAllIncomes = useCallback(() => {
    if (formik.values.incomes.length === 0 && !hasSelectedIncomesInPage) {
      return;
    }

    if (isManagementUser && !scope) {
      addToast('Selecione uma empresa para continuar', 'error');
      return;
    }

    if (areAllIncomesSelected) {
      const newIncomes = formik.values.incomes.filter(
        ({ income }) =>
          !incomeList.some((incomeInList) => incomeInList.id === income.id)
      );
      formik.setFieldValue('incomes', newIncomes);
    } else {
      const newIncomes = incomesWithSameClient
        .filter((currentIncome) => {
          const isIncomeInFormik = formik.values.incomes.some(
            ({ income: incomeInFormik }) =>
              incomeInFormik.id === currentIncome.id
          );
          const isPaymentPending = currentIncome.status !== PaymentStatus.PAID;

          return !isIncomeInFormik && isPaymentPending;
        })
        .map((income) => ({ value: '', income }));

      formik.setFieldValue('incomes', [
        ...formik.values.incomes,
        ...newIncomes,
      ]);
    }
  }, [
    formik,
    hasSelectedIncomesInPage,
    isManagementUser,
    scope,
    areAllIncomesSelected,
    addToast,
    incomeList,
    incomesWithSameClient,
  ]);

  const handleSelectIncome = useCallback(
    (selectedIncome: IIncome) => {
      if (
        !!formik.values.incomes.length &&
        formik.values.incomes[0].income.client.id !== selectedIncome.client.id
      ) {
        return;
      }

      if (isManagementUser && !scope) {
        addToast('Selecione uma empresa para continuar', 'error');
        return;
      }

      const incomeIndex = formik.values.incomes.findIndex(
        ({ income }) => income.id === selectedIncome.id
      );

      const isSelected = incomeIndex !== -1;

      if (isSelected) {
        formik.setFieldValue(
          'incomes',
          formik.values.incomes.filter(
            ({ income }) => income.id !== selectedIncome.id
          )
        );
      } else {
        formik.setFieldValue('incomes', [
          ...formik.values.incomes,
          { value: '', income: selectedIncome },
        ]);
      }
    },
    [addToast, formik, isManagementUser, scope]
  );

  const handleDeleteIncomeClick = useCallback((income: IIncome) => {
    setIsDeleteModalOpen(true);
    setSelectedIncome(income);
  }, []);

  const handleDeleteIncome = useCallback(
    (password?: string) => {
      if (password) {
        requestDeleteIncome(password);
      }
    },
    [requestDeleteIncome]
  );

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
    setSelectedIncome(undefined);
  }, []);

  const handleOpenPaymentsListModal = useCallback(
    ({ income, cliente }: { income?: IIncome; cliente?: IClientUser }) => {
      setIsPaymentsListModalOpen(true);
      setSelectedIncome(income);
      setSelectedCliente(cliente);
    },
    []
  );

  const handleClosePaymentsListModal = useCallback(() => {
    setIsPaymentsListModalOpen(false);
    setSelectedIncome(undefined);
    setSelectedCliente(undefined);
  }, []);

  const handleAddPaymentClick = useCallback(() => {
    if (isManagementUser && !scope) {
      addToast(
        'Não é possível cadastrar um recebimento sem estar com uma empresa selecionada',
        'error'
      );
    } else {
      setPaymentToIncomesCopy(formik.values.incomes);
      setIsPaymentModalOpen(true);
    }
  }, [addToast, formik.values.incomes, isManagementUser, scope]);

  const handleClosePaymentModal = useCallback(() => {
    formik.setValues({
      ...formikInitialValues,
      incomes: paymentToIncomeCopy,
    });
    formik.setTouched({});
    setIsPaymentModalOpen(false);
  }, [formik, formikInitialValues, paymentToIncomeCopy]);

  if (!queryParams.page) {
    return null;
  }

  return (
    <FormikProvider value={formik}>
      <Filters
        yearMonth={yearMonth}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onSearch={onSearch}
      />
      <Tooltip id="incomes-list-tooltip" />
      {formik.values.incomes.length > 0 && (
        <PaymentCard>
          <PaymentText>
            <strong>{formik.values.incomes.length}</strong>
            {formik.values.incomes.length > 1
              ? ' recebíveis selecionados '
              : ' recebível selecionado '}
            no valor de
            <strong> {currency.centsToBrl(totalRemainingAmount)}</strong>
          </PaymentText>
          <B2Button onClick={handleAddPaymentClick}>
            Receber ({formik.values.incomes.length})
          </B2Button>
        </PaymentCard>
      )}
      <TableVariant
        data={incomeList}
        isLoading={isLoading}
        headerData={[
          (
            <Input
              type="checkbox"
              id="select-all-incomes-checkbox"
              value="select-all-incomes-checkbox"
              name="income-checkbox"
              data-tooltip-id="incomes-list-tooltip"
              data-tooltip-content="Selecionar todos os recebíveis"
              checked={areAllIncomesSelected}
              onChange={handleSelectAllIncomes}
              disabled={
                formik.values.incomes.length === 0 && !hasSelectedIncomesInPage
              }
            />
          ) as unknown as string,
          'Data',
          'Cliente',
          'Descrição',
          'Valor',
          'Categoria',
          'Situação',
          hasAdminPermission ? 'Ações' : 'Ação',
        ]}
        emptyMessage="Nenhum recebível encontrado"
        renderRow={(income) => (
          <B2TableRow key={income.id}>
            <TableDataCell>
              {income.status === PaymentStatus.PAID ? (
                <WalletTickIcon
                  data-tooltip-id="incomes-list-tooltip"
                  data-tooltip-content="Pago"
                />
              ) : (
                <Input
                  id={`income-checkbox-${income.id}`}
                  value={`income-checkbox-${income.id}`}
                  name="income-checkbox"
                  type="checkbox"
                  checked={formik.values.incomes.some(
                    (selectedIncome) => selectedIncome.income.id === income.id
                  )}
                  onChange={() => handleSelectIncome(income)}
                  disabled={
                    !!formik.values.incomes.length &&
                    formik.values.incomes[0].income.client.id !==
                      income.client.id
                  }
                />
              )}
            </TableDataCell>
            <TableDataCell>{formatDate(income.date)}</TableDataCell>
            <TableDataCell>
              <LabelWithPaymentIcon
                label={income.client.name}
                tooltipMessage="Ver pagamentos"
                onClick={() =>
                  handleOpenPaymentsListModal({ cliente: income.client })
                }
              />
            </TableDataCell>
            <TableDataCell>{getIncomeDescription(income)}</TableDataCell>
            <TableDataCell>
              {currency.centsToBrl(income.value)}
              {income.paid_value !== income.value &&
                ` (${currency.centsToBrl(income.value - income.paid_value)})`}
            </TableDataCell>
            <TableDataCell>{income.category?.name || '-'}</TableDataCell>
            <TableDataCell>
              <PaymentStatusLabel status={income.status}>
                {PaymentStatusMap[income.status]}
              </PaymentStatusLabel>
            </TableDataCell>
            <TableDataCell>
              <ActionIconsContainer>
                <ActionIcon
                  tooltipId="incomes-list-tooltip"
                  icon={EmptyWalletIcon}
                  tooltipMessage="Ver pagamentos"
                  onClick={() => handleOpenPaymentsListModal({ income })}
                />
                {income.service ? (
                  <ActionIcon
                    tooltipId="incomes-list-tooltip"
                    icon={CarIcon}
                    tooltipMessage="Ir para a página do serviço"
                    routePath={RoutesPath.private.services.detailService.path.replace(
                      ':serviceId',
                      income.service.toString()
                    )}
                  />
                ) : (
                  <ActionIcon
                    tooltipId="incomes-list-tooltip"
                    icon={EditIcon}
                    tooltipMessage="Editar recebível"
                    onClick={() =>
                      navigateWithScope({
                        routePath:
                          RoutesPath.private.financial.incomes.updateIncome.path.replace(
                            ':incomeId',
                            income.id.toString()
                          ),
                        company: income.company,
                      })
                    }
                  />
                )}
                {hasAdminPermission && !income.service && (
                  <ActionIcon
                    tooltipId="incomes-list-tooltip"
                    icon={TrashIcon}
                    tooltipMessage="Deletar recebível"
                    onClick={() => handleDeleteIncomeClick(income)}
                  />
                )}
              </ActionIconsContainer>
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={incomes?.count}
        changePage={changePage}
        hasClick={false}
      />
      {isDeleteModalOpen && selectedIncome && (
        <DeleteModal
          title={`Deseja realmente deletar o recebível: ${getIncomeDescription(
            selectedIncome
          )}?`}
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          onConfirm={handleDeleteIncome}
          isLoading={isDeleteIncomeLoading}
          passwordRequired
        />
      )}
      {isPaymentsListModalOpen && (
        <PaymentsListModal
          isOpen={isPaymentsListModalOpen}
          onClose={handleClosePaymentsListModal}
          income={selectedIncome}
          client={selectedCliente}
          refetchIncomes={refetchIncomes}
        />
      )}
      <IncomePaymentModal
        isOpen={isPaymentModalOpen}
        onClose={handleClosePaymentModal}
        isLoading={isCreatePaymentLoading}
        totalRemainingAmount={totalRemainingAmount}
      />
    </FormikProvider>
  );
};

export default IncomesListing;
