import React, { useEffect, useRef } from 'react';

import moment from 'moment';
import { FinancialReportType } from 'utils/enums';
import { toDateInput } from 'utils/helpers';

import { useClients, useIncomeCategories } from '@hooks';

import ChooseItemFilter from '@components/ChooseItemFilter';
import DateRangeCalendarFilter from '@components/DateRangeCalendarFilter';
import ExpandableFilter from '@components/ExpandableFilter';
import ListFilters from '@components/ListFilters';
import PaymentStatusFilter from '@components/PaymentStatusFilter';

import { Container } from './styles';

interface FiltersProps {
  yearMonth: string;
  queryParams: IIncomeQueryParams;
  setQueryParams: React.Dispatch<React.SetStateAction<IIncomeQueryParams>>;
  onSearch: (value: string) => void;
}

const Filters: React.FC<FiltersProps> = ({
  yearMonth,
  queryParams,
  setQueryParams,
  onSearch,
}) => {
  const dateRangeCalendarFilterRef = useRef<DateRangeCalendarFilterRef>(null);

  const { listClients } = useClients();
  const { listIncomeCategories } = useIncomeCategories();

  const emptyClient = {
    id: 0,
    name: 'Todos',
  } as IClientUser;

  const emptyCategory = {
    id: 0,
    name: 'Todas',
  } as IIncomeCategory;

  const handleDateRangeFilterSelected = (startDate?: Date, endDate?: Date) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      date__gte: startDate ? toDateInput(startDate) : undefined,
      date__lte: endDate ? toDateInput(endDate) : undefined,
    }));
  };

  const handleSelectClient = (client: IClientUser) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      client,
    }));
  };

  const handleSelectCategory = (category: IIncomeCategory) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      category,
    }));
  };

  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      date__gte: moment(yearMonth).startOf('month').format('YYYY-MM-DD'),
      date__lte: moment(yearMonth).endOf('month').format('YYYY-MM-DD'),
    }));

    dateRangeCalendarFilterRef.current?.changeSelectedDateRange(
      moment(yearMonth).startOf('month').toDate(),
      moment(yearMonth).endOf('month').toDate()
    );
  }, [setQueryParams, yearMonth]);

  return (
    <Container>
      <ListFilters
        variant
        onSearch={onSearch}
        searchPlaceholder="Buscar por descrição"
      >
        <DateRangeCalendarFilter
          clearableDateRange
          onDateRangeFilterSelected={handleDateRangeFilterSelected}
          initialDateRange={[
            moment(queryParams.date__gte).toDate(),
            moment(queryParams.date__lte).toDate(),
          ]}
          ref={dateRangeCalendarFilterRef}
        />
      </ListFilters>
      <ExpandableFilter>
        <ChooseItemFilter
          filterValue={
            queryParams?.client?.id && queryParams?.client?.id !== 0
              ? queryParams?.client?.name
              : 'Cliente: todos'
          }
          modalTitle="Filtrar recebíveis por cliente"
          fetch={listClients}
          queryParams={{ isActive: 'true' }}
          queryKey="clients"
          inputLabel="Selecionar cliente *"
          selectedItemLabel="Cliente selecionado"
          shouldRenderSelectedItem
          onSelect={handleSelectClient}
          selectedItem={queryParams.client || emptyClient}
          additionalItem={emptyClient}
          renderItemText={(client) => client.name}
        />
        <ChooseItemFilter
          filterValue={
            queryParams?.category?.id && queryParams?.category?.id !== 0
              ? queryParams?.category?.name
              : 'Categoria: todas'
          }
          modalTitle="Filtrar recebíveis por categoria"
          fetch={listIncomeCategories}
          queryParams={{ isActive: 'true' }}
          queryKey="incomeCategory"
          inputLabel="Selecionar categoria *"
          selectedItemLabel="Categoria selecionada"
          shouldRenderSelectedItem
          onSelect={handleSelectCategory}
          selectedItem={queryParams.category || emptyCategory}
          additionalItem={emptyCategory}
          renderItemText={(category) => category.name}
        />
        <PaymentStatusFilter
          type={FinancialReportType.INCOME}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      </ExpandableFilter>
    </Container>
  );
};

export default Filters;
