import { CloseCircle } from 'iconsax-react';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) => addSpacing(theme.spacing.two, theme.spacing.four)};
  border-radius: ${({ theme }) => theme.spacing.three};
  background-color: ${({ theme }) => theme.colors.white.one};
  border: 1px solid ${({ theme }) => theme.colors.gray.four};
`;

export const Label = styled.p`
  color: ${({ theme }) => theme.colors.gray.twelve};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const CloseCircleIcon = styled(CloseCircle)`
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};
  color: ${({ theme }) => theme.colors.error};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
