import React, { forwardRef, useImperativeHandle } from 'react';

import { cnpj, cpf, phone } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { ExpenseType } from 'utils/enums';
import { formatDocument } from 'utils/formats';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';
import TextArea from '@components/TextArea';

import { errors } from '@utils';

interface ProviderInfoProps {
  initialValues?: IProviderInfoFormValues;
  onFinish: (data: IProviderInfoFormValues) => void;
}

const ProviderInfo: React.ForwardRefRenderFunction<
  IFormStepRef,
  ProviderInfoProps
> = ({ initialValues, onFinish }, ref) => {
  const isCpfFieldValid = (value?: string) => {
    const isFieldEmpty = !value?.length;

    if (isFieldEmpty || value.length > 14) {
      return true;
    }

    return cpf.validate(value);
  };

  const isCnpjFieldValid = (value?: string) => {
    const isFieldEmpty = !value?.length;

    if (isFieldEmpty || value.length <= 14) {
      return true;
    }

    return cnpj.validate(value);
  };

  const providerTypes = Object.values(ExpenseType);

  const formikInitialValues: IProviderInfoFormValues = {
    name: '',
    document: '',
    phone: '',
    email: '',
    description: '',
    type: undefined,
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    type: yup.string().trim().required(errors.required),
    name: yup.string().trim().required(errors.required),
    document: yup
      .string()
      .trim()
      .test('is-cpf-valid', errors.cpf, isCpfFieldValid)
      .test('is-cnpj-valid', errors.cnpj, isCnpjFieldValid),
    phone: yup
      .string()
      .trim()
      .min(14, errors.minLength(14))
      .test('is-phone-valid', errors.phone, (value) => {
        if (!value) {
          return true;
        }
        return phone.validate(value);
      }),
    email: yup.string().email(errors.email),
    description: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="type">Tipo *</Label>
          <Select
            id="type"
            name="type"
            value={formik.values.type}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.errors.type && !!formik.touched.type}
          >
            <option value="">Selecione um tipo</option>
            {providerTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
          <FormError name="type" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="name">Nome *</Label>
          <Input
            id="name"
            name="name"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            invalidValue={!!formik.errors.name && !!formik.touched.name}
          />
          <FormError name="name" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="document">CPF / CNPJ</Label>
          <Input
            type="text"
            id="document"
            name="document"
            placeholder="Digite aqui"
            value={formik.values.document}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'document',
                formatDocument(event.target.value)
              )
            }
            maxLength={18}
            invalidValue={!!formik.touched.document && !!formik.errors.document}
          />
          <FormError name="document" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="phone">Celular</Label>
          <Input
            type="text"
            id="phone"
            name="phone"
            placeholder="Digite aqui"
            maxLength={15}
            value={formik.values.phone}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue('phone', phone.mask(event.target.value))
            }
            invalidValue={!!formik.touched.phone && !!formik.errors.phone}
          />
          <FormError name="phone" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">E-mail</Label>
          <Input
            type="text"
            id="email"
            name="email"
            placeholder="Digite aqui"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.touched.email && !!formik.errors.email}
          />
          <FormError name="email" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="description">Descrição *</Label>
          <TextArea
            id="description"
            name="description"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            invalidValue={
              !!formik.touched.description && !!formik.errors.description
            }
          />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(ProviderInfo);
