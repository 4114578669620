import { color } from 'b2utils';
import { InfoCircle } from 'iconsax-react';
import { B2Card } from 'react-b2components';
import styled, { css } from 'styled-components';

const BaseIcon = css`
  color: ${({ theme }) => theme.colors.info};
  padding: ${({ theme }) => theme.spacing.one};
  min-height: ${({ theme }) => theme.spacing.seven};
  min-width: ${({ theme }) => theme.spacing.seven};
  background-color: ${({ theme }) =>
    color.addOpacityOnHexColor(theme.colors.info, 0.2)};
  border-radius: 8px;
`;

export const Container = styled(B2Card)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.four};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.smaller};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid ${({ theme }) => theme.colors.gray.one};
`;

export const InfoContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.four};
`;

export const InfoIcon = styled(InfoCircle)`
  ${BaseIcon}
  min-height: ${({ theme }) => theme.spacing.six};
  min-width: ${({ theme }) => theme.spacing.six};
`;

export const InfoQuote = styled.span`
  color: ${({ theme }) => theme.colors.gray.six};
  font-size: ${({ theme }) => theme.fontSize.smaller};
`;

export const DriverInfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.one};
  margin-top: ${({ theme }) => theme.spacing.two};
`;

export const DriverInfo = styled.span`
  color: ${({ theme }) => theme.colors.gray.six};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.colors.gray.seven};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  word-break: break-all;
`;
