import React, { useState } from 'react';

import {
  Content,
  CarouselContainer,
  PreviewContainer,
  PreviewGrid,
} from '../../styles';
import ModalContent from '../ModalContent';
import PreviewImages from '../PreviewImages';

interface InspectionByPaperCheckListProps {
  inspectionImages: Array<IImage>;
}

const InspectionByPaperCheckList: React.FC<InspectionByPaperCheckListProps> = ({
  inspectionImages,
}) => {
  const [carouselPosition, setCarouselPosition] = useState(0);

  const formattedInspectionImages = inspectionImages.map((image) => ({
    id: image.id,
    image: image,
  }));

  return (
    <>
      <CarouselContainer>
        <Content>
          <ModalContent
            carouselPosition={carouselPosition}
            setCarouselPosition={setCarouselPosition}
            inspectionImages={formattedInspectionImages}
          />
        </Content>
      </CarouselContainer>
      <PreviewGrid>
        <PreviewContainer>
          {inspectionImages.map((imageView, index) => (
            <PreviewImages
              key={imageView.id}
              image={imageView}
              isSelected={index === carouselPosition}
              onImageClick={() => setCarouselPosition(index)}
            />
          ))}
        </PreviewContainer>
      </PreviewGrid>
    </>
  );
};

export default InspectionByPaperCheckList;
