import { useCallback } from 'react';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const generateIncomeReportRequestObject = (income: IIncomeReportFormValues) => {
  return {
    date__gte: income.dateGte || undefined,
    date__lte: income.dateLte || undefined,
    client: income.client?.id || undefined,
    person_in_charge: income.personInCharge?.id || undefined,
    category: income.category?.id || undefined,
    status: income.status,
  };
};

const useIncomeReports = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const listIncomeReport = useCallback(
    async (params: IIncomeReportFormValues) => {
      const response = await request<IFinancialReport>({
        method: 'get',
        url: endpoints.financial.incomes.pdfReport.list,
        headers: { scope },
        params: generateIncomeReportRequestObject(params),
      });

      return response.data;
    },
    [request, scope]
  );

  const listSimplifiedIncomeReport = useCallback(
    async (params: IIncomeReportFormValues) => {
      const response = await request<IFinancialReport>({
        method: 'get',
        url: endpoints.financial.incomes.pdfReport.byClient,
        headers: { scope },
        params: generateIncomeReportRequestObject(params),
      });

      return response.data;
    },
    [request, scope]
  );

  return { listIncomeReport, listSimplifiedIncomeReport };
};

export default useIncomeReports;
