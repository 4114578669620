import React, { useRef } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useManagementUsers } from '@hooks';

import BaseForm from '@components/BaseForm';
import ManagementUserForm from '@components/ManagementUserForm';

import RoutesPath from '@router/routes';

import routes from './routes';

const CreateManagementUser: React.FC = () => {
  const managementUserFormStepRef = useRef<IFormStepRef>(null);

  const { createManagementUser } = useManagementUsers();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const submitCreateManagementUser = useMutation(
    (managementUserValues: IManagementUserFormValues) =>
      createManagementUser(managementUserValues),
    {
      onSuccess: () => {
        addToast('Usuário adicionado com sucesso', 'success');
        navigate(RoutesPath.private.managementUsers.listManagementUsers.path, {
          replace: true,
        });
      },
      onError: () => {
        addToast('Erro ao adicionar usuário interno', 'error');
      },
    }
  );

  const steps: Array<IStep> = [
    {
      title: 'Informações do usuário',
      ref: managementUserFormStepRef,
      component: ManagementUserForm,
    },
  ];

  return (
    <BaseForm
      title="Adicionar usuário interno"
      createRequest={submitCreateManagementUser}
      steps={steps}
      routes={routes}
    />
  );
};

export default CreateManagementUser;
