import styled from 'styled-components';

interface KanbanColumnsProps {
  length: number;
}

export const KanbanColumns = styled.div<KanbanColumnsProps>`
  display: grid;
  grid-template-columns: repeat(
    ${({ length }) => length},
    minmax(17.5rem, 1fr)
  );
  grid-gap: ${({ theme }) => theme.spacing.two};
  width: 100%;
  height: 100%;
  overflow-x: auto;
  min-height: 400px;
  padding-bottom: 2px;
  animation: show-rightwards 0.5s ease-out, fade-in 0.5s ease-out;
`;
