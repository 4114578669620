import { useCallback } from 'react';

import regex from 'utils/regex';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const generateIncomeRequestObject = (income: IIncomeFormValues) => {
  return {
    value: income.value.replace(regex.onlyNumbers, ''),
    date: income.date,
    client: income.client?.id,
    person_in_charge: income.personInCharge?.id,
    category: income.category?.id,
    vehicle_name: income.vehicleName,
    vehicle_identifier: income.vehicleIdentifier,
    origin: income.origin?.id,
    destination: income.destination?.id,
  };
};

const transformIncomeQueryParams = (params: IIncomeQueryParams) => {
  return {
    page: params.page,
    search: params.search || undefined,
    date__lte: params.date__lte || undefined,
    date__gte: params.date__gte || undefined,
    client: params.client?.id || undefined,
    person_in_charge: params.personInCharge?.id || undefined,
    category: params.category?.id || undefined,
    page_size: params.pageSize || undefined,
    status: params.status || undefined,
  };
};

const useIncomes = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const listIncomes = useCallback(
    async (params: IIncomeQueryParams) => {
      const response = await request<IPaginated<IIncome>>({
        method: 'get',
        url: endpoints.financial.incomes.list,
        headers: { scope },
        params: transformIncomeQueryParams(params),
      });
      return response.data;
    },
    [request, scope]
  );

  const listSimplifiedIncomes = useCallback(
    async (params: IIncomeQueryParams) => {
      const response = await request<IPaginated<ISimplifiedIncome>>({
        method: 'get',
        url: endpoints.financial.incomes.listByClient,
        headers: { scope },
        params: transformIncomeQueryParams(params),
      });
      return response.data;
    },
    [request, scope]
  );

  const createIncome = useCallback(
    async (values: IIncomeFormValues) => {
      const response = await request<IIncome>({
        method: 'post',
        url: endpoints.financial.incomes.create,
        headers: { scope },
        data: generateIncomeRequestObject(values),
      });

      return response.data;
    },
    [request, scope]
  );

  const getIncome = useCallback(
    async (incomeId: string) => {
      const response = await request<IIncome>({
        method: 'get',
        url: endpoints.financial.incomes.get.replace(':incomeId', incomeId),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const updateIncome = useCallback(
    async (incomeId: string, values: IIncomeFormValues) => {
      const response = await request<IIncome>({
        method: 'patch',
        url: endpoints.financial.incomes.update.replace(':incomeId', incomeId),
        headers: { scope },
        data: generateIncomeRequestObject(values),
      });

      return response.data;
    },
    [request, scope]
  );

  const deleteIncome = useCallback(
    async (incomeId: number, password: string) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.financial.incomes.delete.replace(
          ':incomeId',
          incomeId.toString()
        ),
        headers: { scope },
        data: { password },
      });

      return response.data;
    },
    [request, scope]
  );

  return {
    listIncomes,
    listSimplifiedIncomes,
    createIncome,
    getIncome,
    updateIncome,
    deleteIncome,
  };
};

export default useIncomes;
