import { B2Card } from 'react-b2components';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Container = styled.div`
  width: 100svw;
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('/images/auth-background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.white.main};
`;

export const Content = styled(B2Card)`
  display: flex;
  padding: 0;
  min-height: 485px;
  max-width: 90svw;
  max-height: 90svh;
  overflow: auto;
  border-radius: ${({ theme }) => theme.borderRadius};
  animation: show-rightwards 1s ease-out, fade-in 1s ease-out,
    float 1s ease-in-out infinite;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftPanel = styled.div`
  flex: 0.3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.twelve};
  padding: ${({ theme }) => addSpacing(theme.spacing.eight, theme.spacing.six)};
  background-image: url('/images/auth-card-background.svg');
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 768px) {
    background-position-y: 46%;
    padding: ${({ theme }) =>
      addSpacing(theme.spacing.four, theme.spacing.six)};
  }
`;

export const Logo = styled.img`
  margin-top: ${({ theme }) => theme.spacing.eight};
  width: min(80%, 200px);

  @media (max-width: 768px) {
    margin-top: ${({ theme }) => theme.spacing.four};
  }

  @media (max-width: 425px) {
    width: 120px;
    margin-top: ${({ theme }) => theme.spacing.four};
  }
`;

export const LeftPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const EffectPhrases = styled.div``;

export const Phrase = styled.p`
  color: ${({ theme }) => theme.colors.white.main};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const StrongPhrase = styled.strong`
  color: ${({ theme }) => theme.colors.white.main};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const RightPanel = styled.div`
  flex: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex: 1;
  }
`;

export const RightPanelContent = styled.div`
  flex: 0.8;
  padding: ${({ theme }) => addSpacing(theme.spacing.eight, '0')};
`;

export const Title = styled.h1`
  margin-bottom: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.gray.twelve};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const Description = styled.p`
  text-align: justify;
  color: ${({ theme }) => theme.colors.gray.six};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const SubmitContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.eight};
`;
