import { useCallback } from 'react';

import { phone } from 'b2utils';
import regex from 'utils/regex';

import { useApi } from '@contexts/Api';
import { endpoints } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const generateProviderRequestObject = (provider: IProviderInfoFormValues) => {
  const phoneObject = provider?.phone ? phone.toObject(provider.phone) : null;

  const requestObject = {
    ...provider,
    document: provider.document?.replace(regex.onlyNumbers, '') || null,
    phone: phoneObject && {
      area_code: phoneObject.areaCode || '',
      number: phoneObject.number,
    },
    email: provider.email || null,
  };

  return requestObject;
};

const useProviders = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const createProvider = useCallback(
    async (provider: IProviderInfoFormValues) => {
      const data = generateProviderRequestObject(provider);
      const response = await request<IProvider>({
        method: 'post',
        url: endpoints.providers.create,
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const listProviders = useCallback(
    async (params: IProviderQueryParams) => {
      const response = await request<IPaginated<IProvider>>({
        method: 'get',
        url: endpoints.providers.list,
        headers: { scope },
        params: {
          page: params.page,
          is_active: params.isActive || undefined,
          search: params.search || undefined,
          type: params.type || undefined,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getProvider = useCallback(
    async (providerId: string) => {
      const response = await request<IProvider>({
        method: 'get',
        url: endpoints.providers.get.replace(':providerId', providerId),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const updateIsActiveProviderStatus = useCallback(
    async (providerId: string, isActive: boolean) => {
      const response = await request<IProvider>({
        method: 'patch',
        url: endpoints.providers.update.replace(':providerId', providerId),
        headers: { scope },
        data: { is_active: isActive },
      });

      return response.data;
    },
    [request, scope]
  );

  const updateProvider = useCallback(
    async (providerId: string, provider: IProviderInfoFormValues) => {
      const data = generateProviderRequestObject(provider);
      const response = await request<IProvider>({
        method: 'patch',
        url: endpoints.providers.update.replace(':providerId', providerId),
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  return {
    createProvider,
    listProviders,
    getProvider,
    updateIsActiveProviderStatus,
    updateProvider,
  };
};

export default useProviders;
