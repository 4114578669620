import { useCallback } from 'react';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const useReports = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const getReports = useCallback(
    async (id: string) => {
      const response = await request<IFinancialReport>({
        method: 'get',
        url: endpoints.financial.reports.replace(':id', id),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  return { getReports };
};

export default useReports;
