import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.providers.listProviders.path,
    label: 'Fornecedores',
  },
  {
    path: RoutesPath.private.providers.createProvider.path,
    label: 'Cadastrar fornecedor',
  },
];

export default routes;
