import React, { useCallback, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { Container, Title } from './styles';

interface ITabsProps {
  tabs: Array<ITab>;
}

const Tabs: React.FC<ITabsProps> = ({ tabs }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const getCurrentTab = useCallback(() => {
    const currentTab = tabs.findIndex((tab) => tab.path === location.pathname);

    return currentTab !== -1 ? currentTab : 0;
  }, [location.pathname, tabs]);

  const [selectTab, setSelectTab] = useState(getCurrentTab());

  const handleTabClick = useCallback(
    (tab: ITab, index: number) => {
      setSelectTab(index);
      if (tab.path) {
        return navigate(tab.path);
      }
    },
    [navigate]
  );

  useEffect(() => {
    const newTab = getCurrentTab();

    setSelectTab(newTab);
  }, [getCurrentTab]);

  return (
    <>
      <Container>
        {tabs.map((tab, index) => (
          <Title
            key={index}
            isSelected={selectTab === index}
            onClick={() => handleTabClick(tab, index)}
          >
            {tab.title}
          </Title>
        ))}
      </Container>
      {tabs[selectTab].component}
    </>
  );
};

export default Tabs;
