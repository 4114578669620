import { useCallback } from 'react';

import { phone } from 'b2utils';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const generateQuotationRequestObject = (quotation: IQuotationFormValues) => {
  const phoneObject = phone.toObject(quotation.phone);

  return {
    client_name: quotation.clientName,
    phone: {
      area_code: phoneObject.areaCode || '',
      number: phoneObject.number,
    },
    payment_method: quotation.paymentMethod?.id,
    collection_type: quotation.collectionType,
    collection_address: quotation.collectionAddress || null,
    delivery_type: quotation.deliveryType,
    delivery_address: quotation.deliveryAddress || null,
    estimated_shipping_date: quotation.estimatedShippingDate || null,
    expiration: quotation.expiration,
    vehicles: quotation.vehicles.map((vehicle) => ({
      model: vehicle.model,
      estimated_delivery_days: vehicle.estimatedDeliveryDays,
      insurance_value: vehicle.insuranceValue.replace(/\D/g, ''),
      freight_value: vehicle.freightValue.replace(/\D/g, ''),
      origin: vehicle.origin?.id,
      destination: vehicle.destination?.id,
    })),
  };
};

const useQuotations = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const getQuotation = useCallback(
    async (quotationId: string) => {
      const response = await request<IQuotation>({
        method: 'get',
        url: endpoints.quotations.get.replace(':quotationId', quotationId),
        headers: { scope },
      });
      return response.data;
    },
    [request, scope]
  );

  const listQuotations = useCallback(
    async (params: IQuotationQueryParams) => {
      const response = await request<IPaginated<IQuotation>>({
        method: 'get',
        url: endpoints.quotations.list,
        headers: { scope },
        params: {
          page: params.page,
          search: params.search || undefined,
          status: params.status || undefined,
          person_in_charge: params.personInCharge?.id || undefined,
          created__date__lte: params.createdDateLte || undefined,
          created__date__gte: params.createdDateGte || undefined,
          estimated_shipping_date__lte:
            params.estimatedShippingDateLte || undefined,
          estimated_shipping_date__gte:
            params.estimatedShippingDateGte || undefined,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const createQuotation = useCallback(
    async (quotation: IQuotationFormValues) => {
      const response = await request<IQuotation>({
        method: 'post',
        url: endpoints.quotations.create,
        headers: { scope },
        data: generateQuotationRequestObject(quotation),
      });
      return response.data;
    },
    [request, scope]
  );

  const updateQuotation = useCallback(
    async (quotationId: string, quotation: IQuotationFormValues) => {
      const response = await request<IQuotation>({
        method: 'patch',
        url: endpoints.quotations.update.replace(':quotationId', quotationId),
        headers: { scope },
        data: generateQuotationRequestObject(quotation),
      });
      return response.data;
    },
    [request, scope]
  );

  const finalizeQuotation = useCallback(
    async (quotationId: number, quotation: IFinalizeQuotationFormValues) => {
      const response = await request<IQuotation>({
        method: 'patch',
        url: endpoints.quotations.update.replace(
          ':quotationId',
          quotationId.toString()
        ),
        headers: { scope },
        data: {
          is_finished: true,
          finished_at: quotation.finishedAt,
          is_lost: quotation.isLost,
          lost_reason: (quotation.isLost && quotation.lostReason) || null,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const deleteQuotation = useCallback(
    async (quotationId: number, password: string) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.quotations.delete.replace(
          ':quotationId',
          quotationId.toString()
        ),
        headers: { scope },
        data: { password },
      });

      return response.data;
    },
    [request, scope]
  );

  return {
    getQuotation,
    listQuotations,
    createQuotation,
    updateQuotation,
    finalizeQuotation,
    deleteQuotation,
  };
};

export default useQuotations;
