import React, { useCallback, useState } from 'react';

import moment from 'moment';

import MonthCalendar from '@components/MonthCalendar';
import PageHeader from '@components/PageHeader';

import Dashboard from './Dashboard';
import QuotationList from './QuotationList';
import { Content } from './styles';

const ListQuotations: React.FC = () => {
  const [yearMonth, setYearMonth] = useState(moment().format('YYYY-MM'));

  const handleMonthChange = useCallback((date?: Date) => {
    if (!date) {
      return;
    }
    setYearMonth(moment(date).format('YYYY-MM'));
  }, []);

  return (
    <>
      <PageHeader
        title="Orçamentos"
        subtitle="Listagem de todos os orçamentos do sistema"
      >
        <MonthCalendar
          initialDate={new Date()}
          onSelectMonth={handleMonthChange}
        />
      </PageHeader>
      <Content>
        <Dashboard yearMonth={yearMonth} />
        <QuotationList />
      </Content>
    </>
  );
};

export default ListQuotations;
