import React from 'react';

import { AxiosError } from 'axios';
import { phone } from 'b2utils';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { formatDocument } from 'utils/formats';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useProviders } from '@hooks';

import ContactItem from '@components/ContactItem';

import {
  ContactGroup,
  Name,
  MainContent,
  HeaderFirstLine,
  CustomInactivateSwitch,
  Card,
} from './styles';

interface ProviderInfoProps {
  provider: IProvider;
}

const ProviderInfo: React.FC<ProviderInfoProps> = ({ provider }) => {
  const { providerId } = useParams<{ providerId: string }>();

  const { updateIsActiveProviderStatus } = useProviders();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const { mutate: requestUpdateIsActiveProviderStatus } = useMutation(
    () => updateIsActiveProviderStatus(providerId!, !provider.is_active),
    {
      onSuccess: () => {
        addToast('Situação do fornecedor atualizada com sucesso', 'success');
        queryClient.invalidateQueries(['provider', providerId]);
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível alterar a situação do fornecedor', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  return (
    <Card>
      <MainContent>
        <HeaderFirstLine>
          <Name>
            {provider.name} ({provider.type.toLowerCase()})
          </Name>
          <CustomInactivateSwitch
            isActive={provider.is_active}
            onChange={requestUpdateIsActiveProviderStatus}
          />
        </HeaderFirstLine>
        <ContactGroup>
          <ContactItem label="Descrição" value={provider.description} />
          <ContactItem
            label="CNPJ/CPF"
            value={
              provider.document
                ? formatDocument(provider.document)
                : 'Não informado'
            }
          />
          <ContactItem
            label="E-mail"
            value={provider.email ?? 'Não informado'}
          />
          <ContactItem
            label="Celular"
            value={
              provider.phone
                ? phone.fromObject({
                    areaCode: provider.phone.area_code,
                    number: provider.phone.number,
                  })
                : 'Não informado'
            }
          />
        </ContactGroup>
      </MainContent>
    </Card>
  );
};

export default ProviderInfo;
