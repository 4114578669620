import React, { useCallback } from 'react';

import { AxiosError, HttpStatusCode } from 'axios';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { useMutation } from 'react-query';
import { CityType } from 'utils/enums';
import { throwToastApiErrors } from 'utils/helpers';
import { number, object, string } from 'yup';

import { useToast } from '@contexts/Toast';
import { useFiscal } from '@hooks';

import BaseCityForm from '@components/BaseCityForm';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import ModalFooterButtons from '@components/ModalFooterButtons';
import PasswordInput from '@components/PasswordInput';

import { errors } from '@utils';

import { CustomModal, ModalContent } from '../styles';

interface ICloseMdfeRequestValues {
  values: ICoseMdfeFormValues;
  helpers: FormikHelpers<ICoseMdfeFormValues>;
}

interface CloseMdfeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CloseMdfeModal: React.FC<CloseMdfeModalProps> = ({ isOpen, onClose }) => {
  const { closeMdfe } = useFiscal();
  const { addToast } = useToast();

  const closeMdfeRequest = useMutation(
    ({ values }: ICloseMdfeRequestValues) => closeMdfe(values),
    {
      onSuccess: (data, { helpers }) => {
        addToast(
          data.status_message || 'MDF-e finalizado com sucesso',
          'success'
        );
        onClose();
        helpers.resetForm();
      },
      onError: (error: AxiosError) => {
        addToast('Erro ao finalizar MDF-e', 'error');

        if (error.response?.status === HttpStatusCode.Forbidden) {
          throwToastApiErrors(error, addToast);
          return;
        }

        const responseData = error.response?.data as IMdfeKey;

        if (responseData.status_message) {
          addToast(responseData.status_message, 'error');
        }
      },
    }
  );

  const formikInitialValues: ICoseMdfeFormValues = {
    key: '',
    protocolNumber: '',
    closureDate: '',
    destination: {
      id: 0,
      name: '',
      state: '',
    },
    password: '',
  };

  const formikValidationSchema = object().shape({
    key: string().trim().required(errors.required),
    protocolNumber: string().trim().required(errors.required),
    closureDate: string().trim().required(errors.required),
    destination: object().shape({
      id: number().required(errors.required),
      name: string().trim().required(errors.required),
      state: string().trim().required(errors.required),
    }),
    password: string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: (values, helpers) => closeMdfeRequest.mutate({ values, helpers }),
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    onClose();
  }, [formik, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <CustomModal title="Finalizar MDF-e" isOpen={isOpen} onClose={handleClose}>
      <FormikProvider value={formik}>
        <ModalContent>
          <Form autoComplete="off">
            <FormRow>
              <FormGroup>
                <Label htmlFor="key">Chave do MDF-e *</Label>
                <Input
                  id="key"
                  name="key"
                  placeholder="Digite aqui"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.key}
                  invalidValue={!!formik.errors.key && !!formik.touched.key}
                />
                <FormError name="key" />
              </FormGroup>
            </FormRow>
            <FormRow>
              <FormGroup>
                <Label htmlFor="protocolNumber">Número do protocolo *</Label>
                <Input
                  id="protocolNumber"
                  name="protocolNumber"
                  placeholder="Digite aqui"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.protocolNumber}
                  invalidValue={
                    !!formik.errors.protocolNumber &&
                    !!formik.touched.protocolNumber
                  }
                />
                <FormError name="protocolNumber" />
              </FormGroup>
            </FormRow>
            <FormRow>
              <FormGroup>
                <Label htmlFor="closureDate">Data de finalização *</Label>
                <Input
                  type="date"
                  id="closureDate"
                  name="closureDate"
                  value={formik.values.closureDate}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  invalidValue={
                    !!formik.touched.closureDate && !!formik.errors.closureDate
                  }
                />
                <FormError name="closureDate" />
              </FormGroup>
            </FormRow>
            <BaseCityForm field="destination" cityType={CityType.DESTINATION} />
            <FormRow>
              <FormGroup>
                <Label htmlFor="password">
                  Senha para confirmar a operação *
                </Label>
                <PasswordInput
                  type="password"
                  autoComplete="new-password"
                  placeholder="Digite aqui"
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  invalidValue={
                    !!formik.touched.password && !!formik.errors.password
                  }
                />
                <FormError name="password" />
              </FormGroup>
            </FormRow>
          </Form>
        </ModalContent>
        <ModalFooterButtons
          isLoading={closeMdfeRequest.isLoading}
          confirmText="Finalizar MDF-e"
          onConfirm={() => formik.handleSubmit()}
          cancelText="Cancelar"
          onCancel={handleClose}
        />
      </FormikProvider>
    </CustomModal>
  );
};

export default CloseMdfeModal;
