import { B2TableRow } from 'react-b2components';
import styled from 'styled-components';

export const CustomTableRow = styled(B2TableRow)`
  td:nth-of-type(1) {
    width: 75%;
  }
  td:nth-of-type(2) {
    width: 25%;
  }
`;
