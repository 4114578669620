import React from 'react';

import FileItem from './FileItem';
import { Container, EmptyText } from './styles';

interface FilesListProps {
  files: Array<IFile>;
  onDeleteFile?: (imagePk: number) => void;
  isDeleteDisabled?: boolean;
  emptyText?: string;
}

const FilesList: React.FC<FilesListProps> = ({
  files,
  onDeleteFile,
  isDeleteDisabled,
  emptyText = 'Nenhum arquivo anexado',
}) => {
  const isEmpty = files.length === 0;

  if (isEmpty) {
    return (
      <EmptyText>
        <em>{emptyText}</em>
      </EmptyText>
    );
  }

  return (
    <Container>
      {files.map((file) => (
        <FileItem
          key={file.id}
          file={file}
          onDeleteFile={onDeleteFile}
          isDeleteDisabled={isDeleteDisabled}
        />
      ))}
    </Container>
  );
};

export default FilesList;
