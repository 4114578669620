import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.nine};

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
