import styled, { keyframes } from 'styled-components';

export const LandingPage = styled.div`
  width: 100svw;
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('/images/auth-background.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.white.main};
`;

const Pulse = keyframes`  
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
`;

export const LandingPageIcon = styled.img`
  width: 50%;
  animation: ${Pulse} 6s ease-out infinite;
`;
