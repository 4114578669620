import styled, { css } from 'styled-components';
import { addSpacing } from 'utils/styles';

import { ToggleInputStyleProps } from '..';

export const Toggle = styled.div<ToggleInputStyleProps>`
  position: relative;
  padding: ${({ theme }) => addSpacing(theme.spacing.four, theme.spacing.six)};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ $isChecked, theme }) =>
    $isChecked ? theme.colors.main : theme.colors.gray.four};
  transition: background-color 0.3s ease-in-out;
`;

export const ElementInside = styled.input<ToggleInputStyleProps>`
  position: absolute;
  border: none;
  top: 15%;
  ${({ $isChecked }) =>
    $isChecked
      ? css`
          left: 40%;
        `
      : css`
          left: 10%;
        `};
  width: 50%;
  height: 70%;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white.two};
  transition: left 0.3s ease-in-out;
  cursor: pointer;
`;
