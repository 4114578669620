import React, { useRef } from 'react';

import { currency } from 'b2utils';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useIncomes, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';
import Loading from '@components/Loading';

import RoutesPath from '@router/routes';

import IncomeInfoForm from '../Form/IncomeForm/IncomeInfoForm';
import routes from './routes';

const UpdateIncome: React.FC = () => {
  const { incomeId } = useParams<{ incomeId: string }>();
  const incomeFormRef = useRef<IFormStepRef>(null);

  const { getIncome, updateIncome } = useIncomes();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useVerifyScope({
    routePath: RoutesPath.private.financial.incomes.listIncomes.path,
    toastErrorMessage: 'Selecione uma empresa para editar um recebível',
  });

  const {
    data: income,
    refetch,
    isLoading,
  } = useQuery(['income', incomeId], () => getIncome(incomeId!), {
    onError: () => {
      addToast('Falha ao buscar os dados do recebível', 'error');
    },
    enabled: !!incomeId,
  });

  const updateIncomeRequest = useMutation(
    (values: IIncomeFormValues) => updateIncome(incomeId!, values),
    {
      onSuccess: () => {
        refetch();
        addToast('Dados do recebível atualizados com sucesso', 'success');
        navigate(RoutesPath.private.financial.incomes.listIncomes.path);
      },
      onError: () => {
        addToast('Erro ao tentar editar informações do recebível', 'error');
      },
    }
  );

  const getInitialValues = (income: IIncome): IIncomeFormValues => {
    return {
      date: income.date,
      client: income.client,
      personInCharge: income.person_in_charge,
      category: income.category,
      vehicleName: income.vehicle_name,
      vehicleIdentifier: income.vehicle_identifier,
      value: currency.brlMask(income.value.toString()),
      origin: income.origin,
      destination: income.destination,
    };
  };

  const steps: Array<IStep> = [
    {
      title: 'Informações do recebível',
      ref: incomeFormRef,
      component: IncomeInfoForm,
    },
  ];

  if (isLoading || !income) {
    return <Loading />;
  }

  return (
    <BaseForm
      steps={steps}
      createRequest={updateIncomeRequest}
      routes={routes}
      title="Editar recebível"
      initialValues={getInitialValues(income)}
    />
  );
};

export default UpdateIncome;
