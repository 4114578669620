import { ArrowRight2 } from 'iconsax-react';
import styled, { css } from 'styled-components';
import { addSpacing } from 'utils/styles';

import { headerHeight, sideBarCollapsedWidth, sideBarWidth } from '../styles';

export const SidebarContent = styled.div<{ $isCollapsed: boolean }>`
  height: calc(100svh - ${headerHeight}px);
  width: ${sideBarWidth}px;
  padding: ${({ theme }) => addSpacing(theme.spacing.four, '0')};
  background-color: ${({ theme }) => theme.colors.white.main};
  border-right: 2px solid ${({ theme }) => theme.colors.gray.one};
  z-index: 2;
  position: fixed;
  overflow: auto;
  transition: width 0.3s ease-in-out;

  @media (max-width: 768px) {
    display: none;
  }

  ${({ $isCollapsed }) =>
    !$isCollapsed &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      width: ${sideBarCollapsedWidth}px;

      svg {
        margin: 4px;
      }
    `}
`;

export const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SideBarSessionTitle = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.gray.three};
  margin: ${({ theme }) => `${theme.spacing.two} ${theme.spacing.four}`};
`;

export const Divider = styled.div`
  width: 50%;
  height: 2px;
  margin: ${({ theme }) => theme.spacing.four} 0;
  background-color: ${({ theme }) => theme.colors.gray.three};
`;

export const SideBarCollapsedContainer = styled.div<{ $isCollapsed: boolean }>`
  ${({ $isCollapsed }) =>
    !$isCollapsed &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}
`;

export const SideBarArrow = styled(ArrowRight2)<{
  $isCollapsed: boolean;
}>`
  position: absolute;
  z-index: 100;
  width: ${({ theme }) => theme.spacing.seven};
  height: ${({ theme }) => theme.spacing.seven};
  padding: ${({ theme }) => theme.spacing.one};
  margin: ${({ theme }) => theme.spacing.two};
  border: 1px solid ${({ theme }) => theme.colors.gray.two};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.gray.four};
  background-color: ${({ theme }) => theme.colors.white.one};
  cursor: pointer;
  left: calc(${sideBarWidth}px - 24px);
  transition: left 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: rotate(180deg);

  ${({ $isCollapsed }) =>
    !$isCollapsed &&
    css`
      left: calc(${sideBarCollapsedWidth}px - 24px);
      transform: rotate(0deg);
    `}

  @media (max-width: 768px) {
    display: none;
  }
`;
