import React, { useCallback } from 'react';

import { useInfiniteQuery, useQueryClient } from 'react-query';
import { DepartureStatus } from 'utils/enums';
import { getNextPageParam } from 'utils/helpers';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useDepartures } from '@hooks';

import EmptyList from '@components/EmptyList';
import Loading from '@components/Loading';

import DepartureCard from './DepartureCard';
import {
  Circle,
  Container,
  Header,
  DeparturesCount,
  DeparturesList,
  Title,
} from './styles';

interface DepartureStatusColumnProps {
  queryParams: IDeparturesQueryParams;
}

const DepartureStatusColumn: React.FC<DepartureStatusColumnProps> = ({
  queryParams,
}) => {
  const { listDepartures } = useDepartures();
  const { scope } = useScope();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const paramsWithoutPage = { ...queryParams, page: undefined };

  const {
    data: departures,
    fetchNextPage,
    refetch,
    isLoading,
    isRefetching,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['departures', paramsWithoutPage, scope],
    ({ pageParam: page = 1 }) => listDepartures({ ...paramsWithoutPage, page }),
    {
      onError: () => {
        addToast(
          `Não foi possível carregar a lista de embarques com status ${queryParams.status}`,
          'error'
        );
      },
      getNextPageParam,
    }
  );

  const isDataLoading = isLoading || isRefetching || isFetchingNextPage;

  const departuresList =
    departures?.pages?.flatMap((page) => page.results) || [];

  const isEmptyList = departuresList.length === 0;

  const onScroll = useCallback(
    (event: any) => {
      const element = event.target;
      if (
        !isDataLoading &&
        element.offsetHeight + element.scrollTop >= element.scrollHeight
      ) {
        fetchNextPage();
      }
    },
    [fetchNextPage, isDataLoading]
  );

  const handleFinalizeDeparture = useCallback(() => {
    refetch();
    queryClient.invalidateQueries([
      'departures',
      { ...queryParams, status: DepartureStatus.FINISHED },
      scope,
    ]);
  }, [queryClient, queryParams, refetch, scope]);

  if (!queryParams.status) {
    return null;
  }

  return (
    <Container>
      <Header status={queryParams.status}>
        <Circle status={queryParams.status} />
        <Title>{queryParams.status}</Title>
        <DeparturesCount>{departures?.pages?.[0]?.count || 0}</DeparturesCount>
      </Header>
      <DeparturesList onScroll={onScroll}>
        {!isDataLoading && isEmptyList && (
          <EmptyList>Nenhum embarque encontrado</EmptyList>
        )}
        {departuresList.map((departure) => (
          <DepartureCard
            key={departure.id}
            departure={departure}
            refetchDeparture={refetch}
            onFinalizeSuccess={handleFinalizeDeparture}
          />
        ))}
        <div>{isDataLoading && <Loading />}</div>
      </DeparturesList>
    </Container>
  );
};

export default DepartureStatusColumn;
