import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.drivers.listDrivers.path,
    label: 'Motoristas',
  },
  {
    path: RoutesPath.private.drivers.createDriver.path,
    label: 'Cadastrar motorista',
  },
];

export default routes;
