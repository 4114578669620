import React from 'react';

import { AxiosError } from 'axios';
import { B2Switch, B2TableRow } from 'react-b2components';
import { useMutation, useQuery } from 'react-query';
import { ExpenseType } from 'utils/enums';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import {
  useExpenseCategories,
  useNavigateWithScope,
  useQueryParams,
} from '@hooks';

import ExpenseTypeLabel from '@components/ExpenseTypeLabel';
import ListFilters from '@components/ListFilters';
import Select from '@components/Select';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import RoutesPath from '@router/routes';

const ExpenseCategoriesListing: React.FC = () => {
  const {
    queryParams,
    setQueryParams,
    onSearch,
    changePage,
    changeIsActiveStatus,
  } = useQueryParams<IExpenseCategoryQueryParams>({ page: 1 });

  const { listExpenseCategories, updateIsActiveExpenseCategoryStatus } =
    useExpenseCategories();
  const { isManagementUser } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();
  const { navigateWithScope } = useNavigateWithScope();

  const expenseCategoryTypes = Object.values(ExpenseType);

  const tableHeaderData = isManagementUser
    ? ['Tipo', 'Nome', 'Empresa', 'Situação']
    : ['Tipo', 'Nome', 'Situação'];

  const {
    data: expenseCategories,
    refetch: refetchCategory,
    isLoading,
  } = useQuery(
    ['expenseCategories', queryParams, scope],
    () => listExpenseCategories(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista de categorias de despesas',
          'error'
        );
      },
    }
  );

  const { mutate: requestUpdateIsActiveCategoryStatus } = useMutation(
    (category: IExpenseCategory) =>
      updateIsActiveExpenseCategoryStatus(category.id, !category.is_active),
    {
      onSuccess: () => {
        addToast('Situação da categoria atualizada com sucesso', 'success');
        refetchCategory();
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível alterar a situação da categoria', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const handleSelectExpenseType = (type: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      type: type ? (type as ExpenseType) : undefined,
    }));
  };

  return (
    <>
      <ListFilters
        onSearch={onSearch}
        changeIsActiveStatus={{ queryParams, changeIsActiveStatus }}
      >
        <Select
          value={queryParams?.type || ''}
          onChange={(event) => handleSelectExpenseType(event.target.value)}
        >
          <option value="">Tipo de despesa: todos</option>
          {expenseCategoryTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Select>
      </ListFilters>
      <Table
        data={expenseCategories?.results || []}
        headerData={tableHeaderData}
        emptyMessage="Nenhuma categoria encontrada"
        isLoading={isLoading}
        renderRow={(category) => (
          <B2TableRow
            key={category.id}
            onClick={() =>
              navigateWithScope({
                routePath:
                  RoutesPath.private.categories.expenses.editCategory.path.replace(
                    ':categoryId',
                    category.id.toString()
                  ),
                company: category.company,
              })
            }
          >
            <TableDataCell>
              <ExpenseTypeLabel type={category.type} />
            </TableDataCell>
            <TableDataCell>{category.name}</TableDataCell>
            {isManagementUser && (
              <TableDataCell>{category.company.name}</TableDataCell>
            )}
            <TableDataCell onClick={(event) => event.stopPropagation()}>
              <B2Switch
                isChecked={category.is_active}
                onChange={() => requestUpdateIsActiveCategoryStatus(category)}
              />
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={expenseCategories?.count}
        changePage={changePage}
      />
    </>
  );
};

export default ExpenseCategoriesListing;
