import React, { useRef } from 'react';

import { useMutation } from 'react-query';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useAuthentication } from '@hooks';

import BaseForm from '@components/BaseForm';
import Loading from '@components/Loading';

import UpdateEmailForm from './UpdateEmailForm';

const UpdateEmail: React.FC = () => {
  const updateEmailFormRef = useRef<IFormStepRef>(null);

  const { updateEmail } = useAuthentication();
  const { userInfo } = useAuth();
  const { addToast } = useToast();

  const updateEmailRequest = useMutation(
    (values: IUpdateEmail) => updateEmail(values),
    {
      onSuccess: () => {
        addToast('Acabamos de lhe enviar um e-mail de confirmação', 'success');
      },
      onError: () => {
        addToast('Erro ao tentar atualizar e-mail', 'error');
      },
    }
  );

  const steps: Array<IStep> = [
    {
      title: 'Atualizar e-mail',
      ref: updateEmailFormRef,
      component: UpdateEmailForm,
    },
  ];

  if (!userInfo) {
    return <Loading />;
  }

  return <BaseForm steps={steps} createRequest={updateEmailRequest} />;
};

export default UpdateEmail;
