import React, { useRef, useState } from 'react';

import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { ManagementButtonType } from 'utils/enums';
import {
  managementButtonTypeProps,
  throwToastApiErrors,
  toAddressString,
} from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useAddresses, useAuthentication, useClients } from '@hooks';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';
import DeleteModal from '@components/DeleteModal';
import Input from '@components/Input';
import ModalFooterButtons from '@components/ModalFooterButtons';
import SingleAddressForm from '@components/SingleAddressForm';

import { AddressFormContainer } from '../styles';
import { Address, ButtonsContainer, Container, CustomButton } from './styles';

interface AddressItemProps {
  address: IAddress;
  clientId: number;
  defaultAddressId?: number;
  homeAddressId?: number;
  refetchClientData: () => void;
  canEditAndDelete: boolean;
}

const AddressItem: React.FC<AddressItemProps> = ({
  address,
  clientId,
  defaultAddressId,
  homeAddressId,
  refetchClientData,
  canEditAndDelete,
}) => {
  const addressFormRef = useRef<IFormStepRef>(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddressFormModalOpen, setIsAddressFormModalOpen] = useState(false);

  const { isClientUser } = useAuth();
  const { updateDefaultAddress, updateHomeAddress } = useAuthentication();
  const { updateAddress, deleteAddress } = useAddresses();
  const { updateDefaultClientAddress, updateHomeClientAddress } = useClients();
  const { addToast } = useToast();

  const isDefaultAddress = defaultAddressId === address.id;
  const isHomeAddress = homeAddressId === address.id;

  const updateDefaultAddressRequest = () => {
    if (isClientUser) {
      return updateDefaultAddress(address.id);
    }
    return updateDefaultClientAddress(clientId!, address.id);
  };

  const updateHomeAddressRequest = () => {
    if (isClientUser) {
      return updateHomeAddress(address.id);
    }
    return updateHomeClientAddress(clientId!, address.id);
  };

  const handleDeleteAddress = () => {
    if (isHomeAddress) {
      addToast('Não é possível apagar endereço sede / residencial', 'error');
    } else {
      setIsDeleteModalOpen(true);
    }
  };

  const { mutate: requestUpdateAddress, isLoading: isUpdateAddressLoading } =
    useMutation(
      (addressValues: IAddressForm) => updateAddress(address.id, addressValues),
      {
        onSuccess: () => {
          addToast('Endereço atualizado com sucesso', 'success');
          refetchClientData();
          setIsAddressFormModalOpen(false);
        },
        onError: (error: AxiosError) => {
          addToast('Erro ao tentar editar endereço', 'error');
          throwToastApiErrors(error, addToast);
        },
      }
    );

  const { mutate: requestDeleteAddress, isLoading: isDeleteAddressLoading } =
    useMutation(() => deleteAddress(address.id), {
      onSuccess: () => {
        addToast('Endereço deletado com sucesso', 'success');
        refetchClientData();
        setIsDeleteModalOpen(false);
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível deletar o endereço', 'error');
        throwToastApiErrors(error, addToast);
      },
    });

  const { mutate: requestUpdateDefaultAddress } = useMutation(
    () => updateDefaultAddressRequest(),
    {
      onSuccess: () => {
        addToast('Endereço padrão alterado com sucesso', 'success');
        refetchClientData();
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível alterar o endereço padrão', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const { mutate: requestUpdateHomeAddress } = useMutation(
    () => updateHomeAddressRequest(),
    {
      onSuccess: () => {
        addToast('Endereço residencial alterado com sucesso', 'success');
        refetchClientData();
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível alterar o endereço residencial', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const addressInitialFormValues: IAddressFormik = {
    address: {
      ...address,
      zipCode: address.zip_code,
      complement: address.additional_info || '',
    },
  };

  const { icon: EditIcon } =
    managementButtonTypeProps[ManagementButtonType.EDIT];
  const { icon: DeleteIcon } =
    managementButtonTypeProps[ManagementButtonType.DELETE];

  return (
    <>
      <Container>
        <Address>{toAddressString(address)}</Address>
        <ButtonsContainer>
          <Input
            type="radio"
            checked={isHomeAddress}
            onChange={() => requestUpdateHomeAddress()}
          />
          <Input
            type="radio"
            checked={isDefaultAddress}
            onChange={() => requestUpdateDefaultAddress()}
          />
          {canEditAndDelete && (
            <>
              <CustomButton
                buttonType={ManagementButtonType.EDIT}
                onClick={() => setIsAddressFormModalOpen(true)}
                title="Editar"
              >
                <EditIcon />
              </CustomButton>
              <CustomButton
                buttonType={ManagementButtonType.DELETE}
                onClick={handleDeleteAddress}
                title="Deletar"
              >
                <DeleteIcon />
              </CustomButton>
            </>
          )}
        </ButtonsContainer>
      </Container>
      <CustomBaseModal
        isOpen={isAddressFormModalOpen}
        onClose={() => setIsAddressFormModalOpen(false)}
        title="Editar endereço"
      >
        {isAddressFormModalOpen && (
          <>
            <AddressFormContainer>
              <SingleAddressForm
                ref={addressFormRef}
                initialValues={addressInitialFormValues}
                onFinish={({ address }) => requestUpdateAddress(address)}
              />
            </AddressFormContainer>
            <ModalFooterButtons
              isLoading={isUpdateAddressLoading}
              confirmText="Salvar alterações"
              onConfirm={() => addressFormRef.current?.submit()}
              cancelText="Cancelar"
              onCancel={() => setIsAddressFormModalOpen(false)}
            />
          </>
        )}
      </CustomBaseModal>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Tem certeza que deseja deletar este endereço?"
        onConfirm={() => requestDeleteAddress()}
        isLoading={isDeleteAddressLoading}
      />
    </>
  );
};

export default AddressItem;
