import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { Lock } from 'iconsax-react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useClients, useNavigateWithScope } from '@hooks';

import AddressesList from '@components/AddressesList';
import ChangePasswordModal from '@components/ChangePasswordModal';
import Loading from '@components/Loading';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import ClientInfo from './ClientInfo';
import IncomesPaymentsList from './IncomesPaymentsList';
import ServiceList from './ServiceList';
import routes from './routes';
import { Container, MainCard } from './styles';

const DetailClient: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();

  const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] =
    useState(false);

  const { getClient, updateIsActiveClientStatus, changeClientUserPassword } =
    useClients();
  const { addToast } = useToast();
  const { navigateWithScope } = useNavigateWithScope();
  const { isManagementUser } = useAuth();

  const {
    data: client,
    refetch: refetchClient,
    isLoading,
  } = useQuery(['client', clientId], () => getClient(clientId!), {
    onError: () => {
      addToast('Falha ao buscar os dados do cliente', 'error');
    },
  });

  const { mutate: requestUpdateIsActiveClientStatus } = useMutation(
    () => updateIsActiveClientStatus(clientId!, !client?.is_active),
    {
      onSuccess: () => {
        addToast('Situação do cliente atualizada com sucesso', 'success');
        refetchClient();
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível alterar a situação do cliente', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const {
    mutate: handleChangeClientUserPassword,
    isLoading: isLoadingChangeClientUserPassword,
  } = useMutation(
    (newPassword: string) =>
      changeClientUserPassword(Number(clientId), newPassword),
    {
      onSuccess: () => {
        addToast('Senha alterada com sucesso', 'success');
        setIsOpenChangePasswordModal(false);
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível alterar a senha', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const isDataLoading = isLoading || !client;

  return (
    <>
      <PageHeader
        title="Detalhes do cliente"
        routes={routes}
        buttonProps={
          isManagementUser
            ? {
                text: 'Trocar senha',
                icon: Lock,
                disabled: isLoadingChangeClientUserPassword,
                onClick: () => setIsOpenChangePasswordModal(true),
              }
            : undefined
        }
        editButtonProps={{
          onClick: () =>
            navigateWithScope({
              routePath: RoutesPath.private.clients.editClient.path.replace(
                ':clientId',
                clientId!
              ),
              company: client?.company,
            }),
        }}
      />
      {isDataLoading ? (
        <Loading />
      ) : (
        <Container>
          <MainCard>
            <ClientInfo
              client={client}
              onUpdateIsActiveClientStatus={requestUpdateIsActiveClientStatus}
            />
            <AddressesList client={client} />
          </MainCard>
          <ServiceList client={client} />
          <IncomesPaymentsList />
        </Container>
      )}
      <ChangePasswordModal
        isOpenModal={isOpenChangePasswordModal}
        isLoading={isLoadingChangeClientUserPassword}
        onClose={() => setIsOpenChangePasswordModal(false)}
        requestChangePassword={({ password }) =>
          handleChangeClientUserPassword(password)
        }
      />
    </>
  );
};

export default DetailClient;
