import { useCallback } from 'react';

import { generateClientRequestObject } from 'utils/helpers';

import { useApi } from '@contexts/Api';
import { endpoints } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const useClients = () => {
  const { scope } = useScope();
  const { request } = useApi();

  const listClients = useCallback(
    async (params: IQueryParams) => {
      const response = await request<IPaginated<IClientUser>>({
        method: 'get',
        url: endpoints.clients.list,
        headers: { scope },
        params: {
          page: params.page,
          search: params.search,
          is_active: params.isActive,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getClient = useCallback(
    async (clientId: string) => {
      const response = await request<IClientUser>({
        method: 'get',
        url: endpoints.clients.get.replace(':clientId', clientId),
      });

      return response.data;
    },
    [request]
  );

  const createClient = useCallback(
    async (client: IClientFormValues) => {
      const data = generateClientRequestObject(client);
      const response = await request<IClientUser>({
        method: 'post',
        url: endpoints.clients.create,
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const updateClient = useCallback(
    async (clientId: string, client: IClientFormValues) => {
      const data = generateClientRequestObject(client);

      const response = await request<IClientUser>({
        method: 'patch',
        url: endpoints.clients.update.replace(':clientId', clientId),
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const updateDefaultClientAddress = useCallback(
    async (clientId: number, addressId: number) => {
      const response = await request<IClientUser>({
        method: 'patch',
        url: endpoints.clients.update.replace(':clientId', clientId.toString()),
        data: {
          default_address: addressId,
        },
      });

      return response.data;
    },
    [request]
  );

  const updateHomeClientAddress = useCallback(
    async (clientId: number, addressId: number) => {
      const response = await request<IClientUser>({
        method: 'patch',
        url: endpoints.clients.update.replace(':clientId', clientId.toString()),
        data: {
          home_address: addressId,
        },
      });

      return response.data;
    },
    [request]
  );

  const updateIsActiveClientStatus = useCallback(
    async (clientId: string, isActive: boolean) => {
      const response = await request<IClientUser>({
        method: 'patch',
        url: endpoints.clients.update.replace(':clientId', clientId),
        data: { is_active: isActive },
      });

      return response.data;
    },
    [request]
  );

  const changeClientUserPassword = useCallback(
    async (id: number, password: string) => {
      const response = await request<IClientUser>({
        method: 'patch',
        url: endpoints.clients.update.replace(':clientId', id.toString()),
        headers: { scope },
        data: { password },
      });
      return response.data;
    },
    [request, scope]
  );

  return {
    listClients,
    getClient,
    createClient,
    updateClient,
    updateDefaultClientAddress,
    updateHomeClientAddress,
    updateIsActiveClientStatus,
    changeClientUserPassword,
  };
};

export default useClients;
