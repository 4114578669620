import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { B2TableRow } from 'react-b2components';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { throwToastApiErrors } from 'utils/helpers';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { usePaymentTerms, useQueryParams } from '@hooks';

import ActionIcon from '@components/ActionIcon';
import DeleteModal from '@components/DeleteModal';
import ListFilters from '@components/ListFilters';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import RoutesPath from '@router/routes';

import { TrashIcon } from './styles';

const ListPaymentTerm: React.FC = () => {
  const { queryParams, onSearch, changePage, changeIsActiveStatus } =
    useQueryParams<IQueryParams>({ page: 1 });

  const [currentPaymentTerm, setCurrentPaymentTerm] = useState<IPaymentTerm>();

  const { listPaymentTerms, deletePaymentTerm } = usePaymentTerms();
  const { addToast } = useToast();
  const { scope } = useScope();
  const navigate = useNavigate();

  const {
    data: paymentTerms,
    isLoading,
    refetch,
  } = useQuery(
    ['paymentTerms', queryParams, scope],
    () => listPaymentTerms(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista de forma de pagamento',
          'error'
        );
      },
    }
  );

  const requestDeletePaymentTerm = useMutation(
    (requestData: IPaymentTermsRequest) => deletePaymentTerm(requestData),
    {
      onSuccess: () => {
        addToast('Forma de pagamento deletada com sucesso', 'success');
        refetch();
        setCurrentPaymentTerm(undefined);
      },
      onError: (error: AxiosError) => {
        addToast('Erro ao deletar forma de pagamento', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  return (
    <>
      <ListFilters
        onSearch={onSearch}
        changeIsActiveStatus={{ queryParams, changeIsActiveStatus }}
      />
      <Tooltip id="payment-form-list-tooltip" />
      <Table
        data={paymentTerms?.results || []}
        headerData={['Descrição', 'Ação']}
        emptyMessage="Nenhuma forma de pagamento encontrada"
        isLoading={isLoading}
        renderRow={(paymentTerm) => (
          <B2TableRow
            key={paymentTerm.id}
            onClick={() =>
              navigate(
                RoutesPath.private.paymentMethods.updatePaymentTerm.path.replace(
                  ':paymentTermId',
                  paymentTerm.id.toString()
                ),
                {
                  state: { data: paymentTerm },
                }
              )
            }
          >
            <TableDataCell>{paymentTerm.description}</TableDataCell>
            <TableDataCell>
              <ActionIcon
                tooltipId="payment-form-list-tooltip"
                icon={TrashIcon}
                tooltipMessage="Deletar recebível"
                onClick={() => setCurrentPaymentTerm(paymentTerm)}
              />
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={paymentTerms?.count}
        changePage={changePage}
      />
      {currentPaymentTerm && (
        <DeleteModal
          title="Deseja realmente deletar a forma de pagamento ?"
          isOpen={!!currentPaymentTerm}
          onClose={() => setCurrentPaymentTerm(undefined)}
          onConfirm={(password) =>
            requestDeletePaymentTerm.mutate({
              id: currentPaymentTerm.id,
              password: password!,
            })
          }
          isLoading={requestDeletePaymentTerm.isLoading}
          passwordRequired
        />
      )}
    </>
  );
};

export default ListPaymentTerm;
