import React from 'react';

import { CteIssuanceStatus } from 'utils/enums';

import { Container, CteBadge, Square } from './styles';

interface CteStatusProps {
  status: CteIssuanceStatus;
}

const CteStatus: React.FC<CteStatusProps> = ({ status }) => {
  return (
    <Container>
      <Square $status={status} />
      <CteBadge $status={status}>{status}</CteBadge>
    </Container>
  );
};

export default CteStatus;
