import styled from 'styled-components';

const FormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.two};
`;

export default FormGroup;
