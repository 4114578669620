import React from 'react';

import { formatToBrlDate } from 'utils/formats';

import { BoldMessage, Card, Date, Message, UserName } from './styles';

const LogCard: React.FC<LogProps> = ({
  log,
  logActionMap,
  backgroundColor,
}) => {
  return (
    <Card style={{ backgroundColor }}>
      <Date>{formatToBrlDate(log.created)}</Date>
      {log.previous_value && log.new_value ? (
        <div>
          <UserName>{log.user.name}</UserName>
          <Message>
            {' mudou de '}
            <BoldMessage>{log.previous_value}</BoldMessage>
            {' para '}
            <BoldMessage>{log.new_value}</BoldMessage>
          </Message>
        </div>
      ) : (
        <div>
          <Message>{`${logActionMap} por `}</Message>
          <UserName>{log.user.name}</UserName>
          {!!log.departure && typeof log.departure !== 'number' && (
            <Message>
              {' com origem em '}
              <BoldMessage>
                {log.departure.origin.name}/{log.departure.origin.state}
              </BoldMessage>
              {' e destino em '}
              <BoldMessage>
                {log.departure.destination.name}/
                {log.departure.destination.state}
              </BoldMessage>
            </Message>
          )}
        </div>
      )}
    </Card>
  );
};

export default LogCard;
