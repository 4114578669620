import React from 'react';

import { currency } from 'b2utils';
import { FinancialType } from 'utils/enums';
import { financialTransactionTypeProps } from 'utils/helpers';

import { CustomLoading } from '../styles';
import {
  Container,
  Description,
  ForecastSum,
  InfoContainer,
  Label,
  MainContent,
  PaymentSum,
  Progress,
  ProgressContainer,
} from './styles';

interface FinancialTransactionForecastCardProps {
  values?: IFinancialForecast;
  type: FinancialType;
  isLoading: boolean;
}

const FinancialTransactionForecastCard: React.FC<
  FinancialTransactionForecastCardProps
> = ({ values, type, isLoading }) => {
  const getProgress = () => {
    const paymentsSum = values?.payments_sum ?? 0;
    const valuesSum = values?.values_sum ?? 0;

    if (paymentsSum === 0 && valuesSum === 0) {
      return 0;
    }

    if (paymentsSum > valuesSum) {
      return 100;
    }

    return (paymentsSum / valuesSum) * 100;
  };

  if (type === FinancialType.TRANSFER) {
    return null;
  }

  return (
    <Container>
      <MainContent>
        <InfoContainer>
          <Label>{financialTransactionTypeProps[type].forecast?.label}</Label>
          <Description>
            {financialTransactionTypeProps[type].forecast?.description}
          </Description>
        </InfoContainer>
        {isLoading ? (
          <CustomLoading />
        ) : (
          <ForecastSum>
            <PaymentSum type={type}>
              {currency.centsToBrl(values?.payments_sum || 0)}
            </PaymentSum>
            {` / ${currency.centsToBrl(values?.values_sum || 0)}`}
          </ForecastSum>
        )}
      </MainContent>
      <ProgressContainer>
        <Progress progress={getProgress()} type={type} />
      </ProgressContainer>
    </Container>
  );
};

export default FinancialTransactionForecastCard;
