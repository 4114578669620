import { useCallback } from 'react';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const useForecast = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const getFinancialForecast = useCallback(
    async (params: IMetricQueryParamsByYearMonth) => {
      const response = await request<IFinancialForecast>({
        method: 'get',
        url: endpoints.financial.forecast.get,
        headers: { scope },
        params: {
          year_month: params.yearMonth,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  return { getFinancialForecast };
};

export default useForecast;
