import {
  checkIfDateIsAfterNow,
  isCurrencyFieldGraterThan,
  isCurrencyFieldLessEqualThan,
} from 'utils/helpers';
import regex from 'utils/regex';
import * as yup from 'yup';

import { errors } from '@utils';

export const incomePaymentFormValidationSchema = yup.object().shape({
  value: yup
    .string()
    .trim()
    .when('incomes', {
      is: (incomes: Array<IPaymentToIncomeFormValues>) => incomes.length > 1,
      then: (schema) =>
        schema
          .required(errors.required)
          .test('is-valid-value', errors.minCurrency(0), (value) =>
            isCurrencyFieldGraterThan({ value, minValue: 0 })
          ),
    }),
  accrualDate: yup
    .string()
    .required(errors.required)
    .test('is-future-date', errors.futureDate, checkIfDateIsAfterNow),
  incomes: yup
    .array()
    .of(
      yup.object({
        value: yup
          .string()
          .trim()
          .required(errors.required)
          .test('min-payment-value', errors.minCurrency(0), (value) =>
            isCurrencyFieldGraterThan({ value, minValue: 0 })
          )
          .test(
            'max-payment-value',
            errors.maxIncomePaymentValue,
            (value, context) => {
              const { income }: { income: IIncome } = context.parent;
              return isCurrencyFieldLessEqualThan({
                value,
                maxValue: income.value - income.paid_value,
              });
            }
          ),
      })
    )
    .test(
      'total-sum-of-payments',
      errors.totalSumOfIncomePayments,
      (incomes, context) => {
        if (!incomes) {
          return false;
        }

        if (incomes.length === 1) {
          return true;
        }

        const formValues: IIncomePaymentFormValues = context.parent;
        const totalPaymentValue = Number(
          formValues.value?.replace(regex.onlyNumbers, '') || ''
        );
        const incomesValue = incomes.reduce(
          (total, { value }) =>
            total + Number(value?.replace(regex.onlyNumbers, '') || ''),
          0
        );

        return incomesValue === totalPaymentValue;
      }
    ),
  paymentMethod: yup
    .object()
    .nullable()
    .notOneOf([null], errors.required)
    .required(errors.required),
  bankAccount: yup
    .object()
    .nullable()
    .notOneOf([null], errors.required)
    .required(errors.required),
});
