import { B2Button } from 'react-b2components';
import styled from 'styled-components';
import { ManagementButtonType } from 'utils/enums';
import { managementButtonTypeProps } from 'utils/helpers';

interface CustomButtonProps {
  buttonType: ManagementButtonType;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.two};

  @media (min-width: 390px) {
    margin-left: auto;
  }
`;

export const CustomButton = styled(B2Button)<CustomButtonProps>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.white.main};
  background-color: ${({ theme, buttonType }) =>
    managementButtonTypeProps[buttonType].color(theme)};
  svg {
    width: ${({ theme }) => theme.spacing.five};
  }
`;
