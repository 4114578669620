import React, { useCallback, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { currency } from 'b2utils';
import { B2TableRow } from 'react-b2components';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { formatDate } from 'utils/formats';
import { getExpenseDescription, throwToastApiErrors } from 'utils/helpers';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useExpensePayments, useQueryParams } from '@hooks';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';
import BasePaymentListingFilters from '@components/BasePaymentListingFilters';
import DeleteModal from '@components/DeleteModal';
import ModalTable from '@components/ModalTable';
import PaymentInfo from '@components/PaymentInfo';
import { TableDataCell } from '@components/Table/styles';

import {
  ActionIconsContainer,
  ModalContent,
  ReceiptIcon,
  TrashIcon,
} from '../../styles';

interface PaymentsListModalProps
  extends Pick<IBaseDeleteModalProps, 'isOpen' | 'onClose'> {
  expense?: IExpense;
  provider?: IProvider;
  refetchExpenses: () => void;
}

const PaymentsListModal: React.FC<PaymentsListModalProps> = ({
  isOpen,
  onClose,
  expense,
  provider,
  refetchExpenses,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedExpensesPayment, setSelectedExpensesPayment] =
    useState<IExpensePayment>();

  const { queryParams, setQueryParams, onSearch, changePage } =
    useQueryParams<IExpensePaymentsQueryParams>({
      page: 1,
      expense: expense && !provider ? expense.id : undefined,
      provider: provider && !expense ? provider.id : undefined,
    });

  const { listExpensePayments, deleteExpensePayment } = useExpensePayments();
  const { scope } = useScope();
  const { addToast } = useToast();

  const {
    data: expensePayments,
    refetch: refetchExpensePayments,
    isLoading,
  } = useQuery(
    ['expensePayments', queryParams, scope],
    () => listExpensePayments(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista pagamentos da despesa',
          'error'
        );
      },
    }
  );

  const {
    mutate: requestDeleteExpensePayment,
    isLoading: isDeleteExpensePaymentLoading,
  } = useMutation(
    (password: string) =>
      deleteExpensePayment(selectedExpensesPayment!.id, password),
    {
      onSuccess: () => {
        refetchExpensePayments();
        refetchExpenses();
        setIsDeleteModalOpen(false);
        addToast('Pagamento deletado com sucesso', 'success');
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível deletar o pagamento', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const handleDeleteExpensePaymentClick = useCallback(
    (expensesPayment: IExpensePayment) => {
      setIsDeleteModalOpen(true);
      setSelectedExpensesPayment(expensesPayment);
    },
    []
  );

  const handleDeleteExpensePayment = useCallback(
    (password?: string) => {
      if (password) {
        requestDeleteExpensePayment(password);
      }
    },
    [requestDeleteExpensePayment]
  );

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
    setSelectedExpensesPayment(undefined);
  }, []);

  const headerTitle = useMemo(() => {
    if (expense) {
      return `Listagem de pagamentos da despesa: ${getExpenseDescription(
        expense
      )}`;
    }

    return `Listagem de pagamentos do fornecedor: ${provider?.name}`;
  }, [expense, provider?.name]);

  return (
    <CustomBaseModal title={headerTitle} isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <Tooltip id="expense-payments-list-tooltip" />
        {expense && (
          <PaymentInfo value={expense.value} paidValue={expense.paid_value} />
        )}
        <BasePaymentListingFilters
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          onSearch={onSearch}
        />
        <ModalTable
          data={expensePayments?.results || []}
          isLoading={isLoading}
          headerData={[
            'Competência',
            'Método de pagamento',
            'Conta bancária',
            'Valor pago',
            'Observações',
            'Ações',
          ]}
          emptyMessage="Nenhum pagamento encontrado"
          renderRow={(expensePayment) => (
            <B2TableRow key={expensePayment.id}>
              <TableDataCell>
                {formatDate(expensePayment.accrual_date)}
              </TableDataCell>
              <TableDataCell>
                {expensePayment.payment_method.name}
              </TableDataCell>
              <TableDataCell>{expensePayment.bank_account.name}</TableDataCell>
              <TableDataCell>
                {currency.brlMask(expensePayment.value.toString())}
              </TableDataCell>
              <TableDataCell>
                {expensePayment.observations || '-'}
              </TableDataCell>
              <TableDataCell>
                <ActionIconsContainer>
                  <TrashIcon
                    data-tooltip-id="expense-payments-list-tooltip"
                    data-tooltip-content="Deletar pagamento"
                    onClick={() =>
                      handleDeleteExpensePaymentClick(expensePayment)
                    }
                  />
                  {expensePayment?.proof_of_payment?.url ? (
                    <Link
                      to={expensePayment.proof_of_payment.url}
                      target="_blank"
                    >
                      <ReceiptIcon
                        data-tooltip-id="expense-payments-list-tooltip"
                        data-tooltip-content="Baixar comprovante"
                      />
                    </Link>
                  ) : (
                    <ReceiptIcon
                      disabled
                      data-tooltip-id="expense-payments-list-tooltip"
                      data-tooltip-content="Sem comprovante"
                    />
                  )}
                </ActionIconsContainer>
              </TableDataCell>
            </B2TableRow>
          )}
          hasClick={false}
          paginator
          amountPerPage={20}
          currentPage={queryParams.page}
          total={expensePayments?.count}
          changePage={changePage}
        />
        {isDeleteModalOpen && selectedExpensesPayment && (
          <DeleteModal
            title={`Deseja realmente deletar o pagamento do dia ${formatDate(
              selectedExpensesPayment.accrual_date
            )} no valor de ${currency.brlMask(
              selectedExpensesPayment.value.toString()
            )}?`}
            isOpen={isDeleteModalOpen}
            onClose={handleCloseDeleteModal}
            onConfirm={handleDeleteExpensePayment}
            isLoading={isDeleteExpensePaymentLoading}
            passwordRequired
          />
        )}
      </ModalContent>
    </CustomBaseModal>
  );
};

export default PaymentsListModal;
