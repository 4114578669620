import React, { useCallback } from 'react';

import { AxiosError } from 'axios';
import moment from 'moment';
import { useQuery } from 'react-query';
import {
  FileExtension,
  FileStatus,
  FileStatusMapping,
  FileType,
  FinancialReportType,
  FinancialReportTypePluralMap,
  ReportDetailLevel,
} from 'utils/enums';
import { pluralize, throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';

import DownloadingAnimation from '../../pages/Documents/FiscalZipFileModal/DownloadingAnimation';
import { FileGeneration } from './styles';

interface ReportGenerationStatusProps<Type extends IBaseReport> {
  id: string;
  type: FileType;
  getFileRequest: (id: string) => Promise<Type>;
  queryKey: string;
  fileExtension: FileExtension;
  detailLevel?: ReportDetailLevel;
}

const ReportGenerationStatus = <Type extends IBaseReport>({
  id,
  type,
  getFileRequest,
  queryKey,
  fileExtension,
  detailLevel,
}: ReportGenerationStatusProps<Type>) => {
  const { addToast } = useToast();

  const downloadFile = useCallback(
    async (data: Type) => {
      const response = await fetch(data.file!.url);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');

      anchor.href = url;

      if ('start_at' in data && 'end_at' in data) {
        const fiscalData = data as Partial<IFiscalZipFile>;
        if (fiscalData.start_at && fiscalData.end_at) {
          const startAt = moment(fiscalData.start_at).format('DD-MM-YYYY');
          const endAt = moment(fiscalData.end_at).format('DD-MM-YYYY');

          anchor.download = `${type} - ${startAt} - ${endAt}.${fileExtension}`;
        }
      } else {
        anchor.download = `${type}.${fileExtension}`;
      }

      document.body.appendChild(anchor);
      anchor.click();
    },
    [fileExtension, type]
  );

  const fetchFile = useQuery([queryKey, id], () => getFileRequest(id), {
    onSuccess: async (data) => {
      if (data.file?.url) {
        await downloadFile(data);
      }
    },
    onError: (error: AxiosError) => {
      addToast('Ocorreu um erro ao baixar documento', 'error');
      throwToastApiErrors(error, addToast);
    },
    refetchInterval: (data) => {
      if (
        data?.status === FileStatus.FINISHED ||
        data?.status === FileStatus.FAILED
      ) {
        return false;
      }
      return 3000;
    },
  });

  const isSimplifiedReport = detailLevel === ReportDetailLevel.SIMPLIFIED;

  const getIncomeLabel = useCallback(
    (quantity: number) => {
      if (isSimplifiedReport) {
        return pluralize(quantity, 'cliente');
      }

      return pluralize(
        quantity,
        FinancialReportType.INCOME,
        FinancialReportTypePluralMap[FinancialReportType.INCOME].toLowerCase()
      );
    },
    [isSimplifiedReport]
  );

  const getExpenseLabel = useCallback(
    (quantity: number) => {
      if (isSimplifiedReport) {
        return pluralize(quantity, 'fornecedor', 'fornecedores');
      }

      return pluralize(
        quantity,
        FinancialReportType.EXPENSE,
        FinancialReportTypePluralMap[FinancialReportType.EXPENSE].toLowerCase()
      );
    },
    [isSimplifiedReport]
  );

  const getMovementLabel = useCallback((quantity: number) => {
    return pluralize(quantity, 'movimentação', 'movimentações');
  }, []);

  const getFinancialLabel = useCallback(
    (quantity: number) => {
      if (type === FileType.CTE || type === FileType.MDFE) {
        return '';
      }

      const labelMap = {
        [FileType.INCOME]: getIncomeLabel,
        [FileType.EXPENSE]: getExpenseLabel,
        [FileType.MOVEMENT]: getMovementLabel,
      };

      return labelMap[type](quantity);
    },
    [getExpenseLabel, getIncomeLabel, getMovementLabel, type]
  );

  const getLabel = useCallback(
    (quantity: number | null) => {
      if (quantity === null) {
        return '';
      }

      const isFiscalType = type === FileType.CTE || type === FileType.MDFE;
      const fiscalLabel = pluralize(quantity, ' arquivo');
      const label = isFiscalType ? fiscalLabel : getFinancialLabel(quantity);

      return ` (${quantity} ${label})`;
    },
    [getFinancialLabel, type]
  );

  return (
    <FileGeneration>
      <DownloadingAnimation
        loop={fetchFile.isLoading || fetchFile.isRefetching}
      />
      <h2>
        {fetchFile.data && FileStatusMapping[fetchFile.data.status]}
        {fetchFile.data && getLabel(fetchFile.data.files_qty)}
      </h2>
    </FileGeneration>
  );
};

export default ReportGenerationStatus;
