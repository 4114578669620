import styled, { css } from 'styled-components';
import { CteIssuanceStatus } from 'utils/enums';
import { cteIssuanceStatusProps } from 'utils/helpers';

interface Props {
  $status: CteIssuanceStatus;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const Square = styled.div<Props>`
  flex-shrink: 0;
  border-radius: ${({ theme }) => theme.spacing.one};
  width: ${({ theme }) => theme.spacing.three};
  height: ${({ theme }) => theme.spacing.three};
  background-color: ${({ theme, $status }) =>
    cteIssuanceStatusProps[$status].color(theme)};
`;

export const CteBadge = styled.p<Props>`
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  ${({ theme, $status }) =>
    css`
      color: ${cteIssuanceStatusProps[$status].color(theme)};
    `}
`;
