import React from 'react';

import { phone } from 'b2utils';
import { StateRegistrationStatus } from 'utils/enums';
import { formatDate, formatDocument } from 'utils/formats';

import ContactItem from '@components/ContactItem';

import {
  ContactGroup,
  Name,
  MainContent,
  HeaderFirstLine,
  CustomInactivateSwitch,
} from './styles';

interface ClientInfoProps {
  client: IClientUser;
  onUpdateIsActiveClientStatus: () => void;
}

const ClientInfo: React.FC<ClientInfoProps> = ({
  client,
  onUpdateIsActiveClientStatus,
}) => {
  const isCnpjDocument = client.document.length === 14;

  const stateRegistration =
    client.state_registration_status === StateRegistrationStatus.TAXPAYER
      ? client.state_registration
      : client.state_registration_status;

  return (
    <MainContent>
      <HeaderFirstLine>
        <Name>{client.name}</Name>
        <CustomInactivateSwitch
          isActive={client.is_active}
          onChange={onUpdateIsActiveClientStatus}
        />
      </HeaderFirstLine>
      <ContactGroup>
        <ContactItem label="E-mail" value={client.email ?? 'Não informado'} />
        <ContactItem
          label="Celular"
          value={phone.fromObject({
            areaCode: client.phone.area_code,
            number: client.phone.number,
          })}
        />
        <ContactItem
          label="Documento"
          value={formatDocument(client.document)}
        />
        {isCnpjDocument && (
          <ContactItem
            label="Inscrição estadual"
            value={stateRegistration || ''}
          />
        )}
        <ContactItem
          label="Data de nascimento"
          value={formatDate(client.date_of_birth)}
        />
      </ContactGroup>
    </MainContent>
  );
};

export default ClientInfo;
