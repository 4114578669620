import React from 'react';

import { phone } from 'b2utils';
import { departureStatusProps } from 'utils/helpers';

import DepartureActionIcons from '@components/DepartureActionIcons';

import RoutesPath from '@router/routes';

import {
  Container,
  DateInfoStyle,
  Footer,
  PrimaryInfoStyle,
  SecondaryInfoStyle,
  VehicleType,
} from './styles';

interface DepartureCardProps {
  departure: IDeparture;
  refetchDeparture: () => void;
  onFinalizeSuccess: () => void;
}

const DepartureCard: React.FC<DepartureCardProps> = ({
  departure,
  refetchDeparture,
  onFinalizeSuccess,
}) => {
  const dateLabel = departureStatusProps[departure.status].dateLabel(departure);

  return (
    <Container
      status={departure.status}
      to={RoutesPath.private.departures.detailDeparture.path.replace(
        ':departureId',
        departure.id.toString()
      )}
    >
      <PrimaryInfoStyle>{departure.driver.name}</PrimaryInfoStyle>
      <SecondaryInfoStyle>
        Telefone:{' '}
        {phone.fromObject({
          areaCode: departure.driver.phone.area_code,
          number: departure.driver.phone.number,
        })}
      </SecondaryInfoStyle>
      <SecondaryInfoStyle>
        Fornecedor: {departure.provider.name}
      </SecondaryInfoStyle>
      <PrimaryInfoStyle>
        Tipo do caminhão {departure.truck.vehicle_type}
      </PrimaryInfoStyle>
      <PrimaryInfoStyle>
        Placa do caminhão {departure.truck.truck_license_plate}
      </PrimaryInfoStyle>
      {dateLabel && <DateInfoStyle>{dateLabel}</DateInfoStyle>}
      <SecondaryInfoStyle>
        Origem: {`${departure.origin.name}/${departure.origin.state}`}
      </SecondaryInfoStyle>
      <SecondaryInfoStyle>
        Destino:{' '}
        {`${departure.destination.name}/${departure.destination.state}`}
      </SecondaryInfoStyle>
      <Footer>
        <DepartureActionIcons
          departure={departure}
          refetchDeparture={refetchDeparture}
          onFinalizeSuccess={onFinalizeSuccess}
        />
        <VehicleType status={departure.status}>
          {departure.ownership}
        </VehicleType>
      </Footer>
    </Container>
  );
};

export default DepartureCard;
