import React from 'react';

import { currency } from 'b2utils';
import { useFormikContext } from 'formik';
import { IcmsType, TaxRegime } from 'utils/enums';
import {
  isIcmsRateRequired,
  isIcmsReductionRequired,
  isOptingForCreditGrantedRequired,
} from 'utils/helpers';
import regex from 'utils/regex';

import { useAuth } from '@contexts/Auth';
import { useCfops } from '@hooks';

import { InputGroup } from '@components/Base/PrivateBase/styles';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';
import TextArea from '@components/TextArea';

import { CustomChooseItemFilter } from '../styles';

interface BaseServiceCteFormProps {
  companyIcmsType?: string;
}

const BaseServiceCteForm: React.FC<BaseServiceCteFormProps> = ({
  companyIcmsType,
}) => {
  const { listCfops } = useCfops();
  const { isClientUser } = useAuth();
  const formik = useFormikContext<IServiceCte>();

  const emptyCfop = { id: 0, code: '', description: 'Nenhum' };

  const icmsTypes = Object.values(IcmsType);

  const shouldRenderIcmsRate = isIcmsRateRequired(formik.values.icmsType);
  const shouldRenderIcmsReduction = isIcmsReductionRequired(
    formik.values.icmsType
  );
  const shouldRenderOptingForCreditGranted = isOptingForCreditGrantedRequired(
    formik.values.icmsType
  );

  const handleSelectCfop = (cfop: ICfop) => {
    formik.setFieldValue('cfop', cfop);
  };

  return (
    <>
      <FormRow>
        <FormGroup>
          <Label>CFOP *</Label>
          <CustomChooseItemFilter
            filterValue={
              formik.values?.cfop?.id !== 0
                ? `${formik.values.cfop.code} - ${formik.values.cfop.description}`
                : 'Selecionar CFOP'
            }
            modalTitle="CFOP - Código Fiscal de Operações e de Prestações"
            fetch={listCfops}
            queryKey="cfops"
            inputLabel="Selecionar CFOP"
            selectedItemLabel="CFOP selecionado"
            shouldRenderSelectedItem={formik.values.cfop.id !== 0}
            onSelect={handleSelectCfop}
            selectedItem={formik.values.cfop || emptyCfop}
            renderItemText={(cfop) => `${cfop.code} - ${cfop.description}`}
            invalidValue={!!formik.touched.cfop?.id && !!formik.errors.cfop?.id}
            disabled={isClientUser}
          />
          <FormError name="cfop.id" />
        </FormGroup>
      </FormRow>
      {(shouldRenderIcmsRate || shouldRenderIcmsReduction) && (
        <FormRow>
          {shouldRenderIcmsRate && (
            <FormGroup>
              <Label htmlFor="icmsRate">Aliquota ICMS (%) *</Label>
              <Input
                type="text"
                id="icmsRate"
                name="icmsRate"
                placeholder="000.00%"
                maxLength={6}
                value={formik.values.icmsRate}
                onBlur={formik.handleBlur}
                onChange={(event) =>
                  formik.setFieldValue(
                    'icmsRate',
                    event.target.value
                      .replace(regex.decimalWithComma, '$1.$2')
                      .replace(regex.fieldWithoutSpecialCharacters, '')
                  )
                }
                invalidValue={
                  !!formik.touched.icmsRate && !!formik.errors.icmsRate
                }
                disabled={isClientUser}
              />
              <FormError name="icmsRate" />
            </FormGroup>
          )}
          {shouldRenderIcmsReduction && (
            <FormGroup>
              <Label htmlFor="icmsReduction">Redução (%) *</Label>
              <Input
                type="text"
                id="icmsReduction"
                name="icmsReduction"
                placeholder="000.00%"
                maxLength={6}
                value={formik.values.icmsReduction}
                onBlur={formik.handleBlur}
                onChange={(event) =>
                  formik.setFieldValue(
                    'icmsReduction',
                    event.target.value
                      .replace(regex.decimalWithComma, '$1.$2')
                      .replace(regex.fieldWithoutSpecialCharacters, '')
                  )
                }
                invalidValue={
                  !!formik.touched.icmsReduction &&
                  !!formik.errors.icmsReduction
                }
                disabled={isClientUser}
              />
              <FormError name="icmsReduction" />
            </FormGroup>
          )}
        </FormRow>
      )}
      {shouldRenderOptingForCreditGranted && (
        <FormRow>
          <FormGroup>
            <InputGroup>
              <Input
                type="checkbox"
                checked={formik.values.optingForCreditGranted}
                onChange={() =>
                  formik.setFieldValue(
                    'optingForCreditGranted',
                    !formik.values.optingForCreditGranted
                  )
                }
                disabled={isClientUser}
              />
              <Label
                onClick={() =>
                  !isClientUser &&
                  formik.setFieldValue(
                    'optingForCreditGranted',
                    !formik.values.optingForCreditGranted
                  )
                }
              >
                Optante pelo crédito outorgado
              </Label>
            </InputGroup>
          </FormGroup>
        </FormRow>
      )}
      <FormRow>
        <FormGroup>
          <Label htmlFor="icmsType">Tipo de ICMS *</Label>
          <Select
            id="icmsType"
            name="icmsType"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.icmsType}
            disabled={
              companyIcmsType === TaxRegime.SIMPLE_NATIONAL || isClientUser
            }
          >
            <option value="">Selecionar tipo de ICMS</option>
            {icmsTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
          <FormError name="icmsType" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="cteFreightCost">Valor do frete no CT-e *</Label>
          <Input
            type="text"
            id="cteFreightCost"
            name="cteFreightCost"
            placeholder="Digite aqui o valor do frete no CT-e"
            value={formik.values.cteFreightCost}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'cteFreightCost',
                currency.brlMask(
                  event.target.value.replace(regex.onlyNumbers, '')
                )
              )
            }
            invalidValue={
              !!formik.touched.cteFreightCost && !!formik.errors.cteFreightCost
            }
            disabled={isClientUser}
          />
          <FormError name="cteFreightCost" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="cteGeneralObservations">
            Observações gerais para o CT-e
          </Label>
          <TextArea
            id="cteGeneralObservations"
            name="cteGeneralObservations"
            placeholder="Caso necessário, digite aqui observações gerais para o CT-e"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cteGeneralObservations}
            invalidValue={
              !!formik.touched.cteGeneralObservations &&
              !!formik.errors.cteGeneralObservations
            }
            disabled={isClientUser}
          />
          <FormError name="cteGeneralObservations" />
        </FormGroup>
      </FormRow>
    </>
  );
};

export default BaseServiceCteForm;
