import React from 'react';

import InspectionImage from './InspectionImage';
import {
  ButtonToLeft,
  EmptyView,
  ButtonToRight,
  EmptyViewContainer,
  CustomLoading,
} from './styles';

interface ModalContentProps {
  carouselPosition: number;
  setCarouselPosition: React.Dispatch<React.SetStateAction<number>>;
  inspectionImages: Array<Pick<IInspectionImage, 'image' | 'id'>>;
  infiniteQueryProps?: InfiniteQueryProps;
}

const ModalContent: React.FC<ModalContentProps> = ({
  carouselPosition,
  setCarouselPosition,
  inspectionImages,
  infiniteQueryProps,
}) => {
  const isLastImage =
    carouselPosition < inspectionImages.length - 1 &&
    inspectionImages.length > 1;

  const shouldRenderRightButton =
    isLastImage || infiniteQueryProps?.hasNextPage;

  const handleRightButtonClick = () => {
    setCarouselPosition((prevState) => prevState + 1);

    if (isLastImage && infiniteQueryProps?.hasNextPage) {
      infiniteQueryProps.fetchNextPage();
    }
  };

  return (
    <>
      {carouselPosition > 0 && (
        <ButtonToLeft
          variant="Bold"
          onClick={() => setCarouselPosition((prevState) => prevState - 1)}
        />
      )}
      {inspectionImages.length ? (
        inspectionImages.map(({ image }) => (
          <InspectionImage
            key={image.id}
            image={image}
            carouselPosition={carouselPosition}
          />
        ))
      ) : (
        <EmptyViewContainer>
          <EmptyView>Nenhuma foto foi tirada nessa vista</EmptyView>
        </EmptyViewContainer>
      )}
      {shouldRenderRightButton &&
        (infiniteQueryProps?.isFetchingNextPage ? (
          <CustomLoading />
        ) : (
          <ButtonToRight variant="Bold" onClick={handleRightButtonClick} />
        ))}
    </>
  );
};

export default ModalContent;
