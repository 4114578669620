import React from 'react';

import { DriverVehicleType } from 'utils/enums';
import { formatDocument } from 'utils/formats';

import DetailSection from '@components/DetailSection';

import { Card } from '../styles';

interface VehicleInfoProps {
  truck: IDriverTruck;
}

const VehicleInfo: React.FC<VehicleInfoProps> = ({ truck }) => {
  const getVehicleInfo = (): Array<ISection> => {
    const truckInfoSection: ISection = {
      title: 'Informações do veículo',
      fields: [
        {
          name: 'Tipo do veículo:',
          description: truck.vehicle_type,
        },
        {
          name: 'Placa do veículo:',
          description: truck.truck_license_plate,
        },
        {
          name: 'Estado da licença do veículo:',
          description: truck.truck_license_state,
        },
        {
          name: 'Tara do veículo (kg):',
          description: truck.truck_tare_weight.toString(),
        },
        {
          name: 'ANTT:',
          description: truck.antt,
        },
      ],
    };

    const trailerInfoSection: ISection = {
      title: 'Informações da carreta',
      fields: [
        {
          name: 'Placa da carreta:',
          description: truck.trailer_license_plate || '',
        },
        {
          name: 'Estado da licença da carreta:',
          description: truck.trailer_license_state || '',
        },
        {
          name: 'Tara da carreta (kg):',
          description: truck.trailer_tare_weight
            ? truck.trailer_tare_weight.toString()
            : '',
        },
        {
          name: 'Capacidade da carreta (kg):',
          description: truck.trailer_payload_capacity
            ? truck.trailer_payload_capacity.toString()
            : '',
        },
      ],
    };

    const vehicleOwnerInfoSection: ISection = {
      title: 'Informações do proprietário do veículo',
      fields: [
        {
          name: 'Nome:',
          description: truck.vehicle_owner_name,
        },
        {
          name: 'CPF/CNPJ:',
          description: formatDocument(truck.vehicle_owner_document),
        },
        {
          name: 'Inscrição estadual:',
          description:
            truck.state_registration || truck.state_registration_status,
        },
      ],
    };

    const sections = [truckInfoSection, vehicleOwnerInfoSection];

    if (truck.vehicle_type === DriverVehicleType.CAR_CARRIER_TRAILER) {
      sections.splice(1, 0, trailerInfoSection);
    }

    return sections;
  };

  return (
    <Card>
      <DetailSection data={getVehicleInfo()} />
    </Card>
  );
};

export default VehicleInfo;
