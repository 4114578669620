import React from 'react';

import { useDebounce } from 'hooks';

import { CustomInput } from './styles';

interface SearchFilterProps {
  onSearch: (value: string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
}

const SearchFilter: React.FC<SearchFilterProps> = ({
  onSearch,
  placeholder = 'Buscar',
  disabled,
  className,
}) => {
  const debouncedSearch = useDebounce((search: string) => {
    onSearch(search);
  }, 1000);

  return (
    <CustomInput
      className={className}
      type="text"
      placeholder={placeholder}
      onChange={(event) => {
        debouncedSearch(event.target.value);
      }}
      disabled={disabled}
    />
  );
};

export default SearchFilter;
