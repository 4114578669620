import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.financial.incomes.listIncomes.path,
    label: 'Financeiro',
  },
  {
    path: RoutesPath.private.financial.incomes.updateIncome.path,
    label: 'Editar recebível',
  },
];

export default routes;
