import React, { forwardRef, useImperativeHandle } from 'react';

import { currency } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { BankAccountType } from 'utils/enums';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';
import ToggleInput from '@components/ToggleInput';

import { errors } from '@utils';

interface BankAccountInfoProps {
  initialValues?: IBankAccountFormValues;
  onFinish: (data: IBankAccountFormValues) => void;
}

const BankAccountInfo: React.ForwardRefRenderFunction<
  IFormStepRef,
  BankAccountInfoProps
> = ({ initialValues, onFinish }, ref) => {
  const bankAccountTypes = Object.values(BankAccountType);

  const formikInitialValues: IBankAccountFormValues = {
    name: '',
    initialBalance: '',
    type: undefined,
    isMain: false,
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    name: yup.string().trim().required(errors.required),
    initialBalance: yup.string().trim().required(errors.required),
    type: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <ToggleInput.RootToggle isChecked={formik.values.isMain}>
            <ToggleInput.Label htmlFor="isMain">
              Conta principal
            </ToggleInput.Label>
            <ToggleInput.Switch
              name="isMain"
              checked={formik.values.isMain}
              onChange={formik.setFieldValue}
            />
          </ToggleInput.RootToggle>
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="name">Nome *</Label>
          <Input
            id="name"
            name="name"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            invalidValue={!!formik.errors.name && !!formik.touched.name}
          />
          <FormError name="name" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="initialBalance">Saldo inicial *</Label>
          <Input
            type="text"
            id="initialBalance"
            name="initialBalance"
            placeholder="Digite aqui"
            value={currency.brlMask(formik.values.initialBalance)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={
              !!formik.touched.initialBalance && !!formik.errors.initialBalance
            }
          />
          <FormError name="initialBalance" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="type">Tipo da conta *</Label>
          <Select
            id="type"
            name="type"
            value={formik.values.type}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.errors.type && !!formik.touched.type}
          >
            <option value="">Selecione um tipo</option>
            {bankAccountTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
          <FormError name="type" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(BankAccountInfo);
