import React from 'react';

import { B2Button } from 'react-b2components';

import Loading from '@components/Loading';

import {
  Container,
  TimeLineContainer,
  Circle,
  Line,
  ItemContent,
  EmptyMessage,
  Footer,
} from './styles';

interface GenericItem {
  id: string | number;
}

interface TimeLineCardProps<ItemType extends GenericItem> {
  items: Array<ItemType>;
  emptyMessage?: string;
  renderItem: (item: ItemType) => React.ReactNode;
  infiniteQueryProps?: InfiniteQueryProps;
}

const TimeLineCard = <ItemType extends GenericItem>({
  items,
  emptyMessage = 'Nada por aqui',
  renderItem,
  infiniteQueryProps,
}: TimeLineCardProps<ItemType>) => {
  return (
    <Container>
      {items.length ? (
        items.map((item) => (
          <TimeLineContainer key={item.id}>
            <Line>
              <Circle />
            </Line>
            <ItemContent>{renderItem(item)}</ItemContent>
          </TimeLineContainer>
        ))
      ) : (
        <EmptyMessage>{emptyMessage}</EmptyMessage>
      )}
      {infiniteQueryProps?.hasNextPage && (
        <Footer>
          {infiniteQueryProps.isFetchingNextPage ? (
            <Loading />
          ) : (
            <B2Button
              variant="outline"
              onClick={() => infiniteQueryProps.fetchNextPage()}
            >
              Carregar mais
            </B2Button>
          )}
        </Footer>
      )}
    </Container>
  );
};

export default TimeLineCard;
