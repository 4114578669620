import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  gap: 4px;
  display: flex;
  flex-direction: column;
`;

export const EmptyText = styled.pre`
  margin-right: auto;
  color: ${({ theme }) => theme.colors.gray.twelve};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
