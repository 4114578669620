import React from 'react';

import { FieldArray, useFormikContext } from 'formik';
import { TaxRegime } from 'utils/enums';
import regex from 'utils/regex';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';

import {
  AddButton,
  AddButtonContainer,
  AddButtonLabel,
  ArrayFieldContainer,
  FieldArrayContainer,
  InputContainer,
  RemoveButton,
} from './styles';

const Rates: React.FC = () => {
  const formik = useFormikContext<ITransporterFormInfo>();

  if (
    !formik.values.taxRegime ||
    formik.values.taxRegime === TaxRegime.SIMPLE_NATIONAL
  ) {
    return null;
  }

  return (
    <FormRow>
      <FormGroup>
        <Label>Alíquotas de ICMS *</Label>
        <FieldArray
          name="rates"
          render={(arrayHelpers) => (
            <FieldArrayContainer>
              {formik.values.rates.map((_, index) => (
                <ArrayFieldContainer key={index}>
                  <InputContainer>
                    <Input
                      placeholder="000.00%"
                      type="text"
                      value={formik.values.rates[index] || ''}
                      maxLength={6}
                      name={`rates[${index}]`}
                      onBlur={formik.handleBlur}
                      onChange={(event) =>
                        formik.setFieldValue(
                          `rates[${index}]`,
                          event.target.value
                            .replace(regex.decimalWithComma, '$1.$2')
                            .replace(regex.fieldWithoutSpecialCharacters, '')
                        )
                      }
                    />
                    <FormError name={`rates[${index}]`} />
                  </InputContainer>
                  {formik.values.rates.length > 1 && (
                    <RemoveButton
                      variant="Bold"
                      onClick={() => arrayHelpers.remove(index)}
                    />
                  )}
                </ArrayFieldContainer>
              ))}
              <AddButtonContainer>
                <AddButton
                  variant="Bold"
                  onClick={() => arrayHelpers.push('')}
                />
                {!formik.values.rates.length && (
                  <AddButtonLabel>Adicionar</AddButtonLabel>
                )}
              </AddButtonContainer>
            </FieldArrayContainer>
          )}
        />
      </FormGroup>
    </FormRow>
  );
};

export default Rates;
