import styled from 'styled-components';

export const RadioButtonsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.three};
  margin-bottom: ${({ theme }) => theme.spacing.two};

  p {
    margin: 0;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.three};

  input,
  label {
    cursor: pointer;
    margin: 0;
    font-size: ${({ theme }) => theme.fontSize.small};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }

  input:disabled + label {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
