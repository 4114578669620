import React, { useRef } from 'react';

import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useServices } from '@hooks';

import CommentsList from '@components/CommentsList';

import RoutesPath from '@router/routes';

const Comments: React.FC = () => {
  const { serviceId } = useParams<{ serviceId: string }>();

  const commentsListRef = useRef<CommentsListRef>(null);

  const { listComments, createComment, updateComment, deleteComment } =
    useServices();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const {
    data: comments,
    refetch: refetchComments,
    isLoading: isCommentsLoading,
  } = useQuery(
    ['serviceComments', serviceId],
    () => listComments(Number(serviceId)),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar os comentários do serviço',
          'error'
        );
        navigate(RoutesPath.private.services.listServices.path, {
          replace: true,
        });
      },
    }
  );

  const createCommentRequest = useMutation(
    ({ text }: ICommentFormValues) => createComment(serviceId!, text),
    {
      onSuccess: () => {
        refetchComments();
        commentsListRef.current?.onCreateSuccess();
        addToast('Comentário adicionado com sucesso', 'success');
      },
      onError: (error: AxiosError) => {
        addToast('Erro ao enviar comentário', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const updateCommentRequest = useMutation(
    ({ id, text }: IUpdateCommentFormValues) => updateComment(id, text),
    {
      onSuccess: () => {
        refetchComments();
        commentsListRef.current?.onUpdateSuccess();
        addToast('Comentário editado com sucesso', 'success');
      },
      onError: (error: AxiosError) => {
        addToast('Erro ao editar comentário', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const deleteCommentRequest = useMutation(
    ({ id, password }: IDeleteComment) => deleteComment(id, password),
    {
      onSuccess: () => {
        refetchComments();
        commentsListRef.current?.onDeleteSuccess();
        addToast('Comentário deletado com sucesso', 'success');
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível deletar o comentário', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const isLoading = isCommentsLoading || !comments;

  return (
    <CommentsList
      ref={commentsListRef}
      isLoading={isLoading}
      comments={comments || []}
      onCreate={createCommentRequest}
      onUpdate={updateCommentRequest}
      onDelete={deleteCommentRequest}
    />
  );
};

export default Comments;
