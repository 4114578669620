import React, { useMemo } from 'react';

import { CteEndorsementStatus } from 'utils/enums';
import { formatToBrlDate } from 'utils/formats';

import { Container, CustomLoading, Infos, Status, Title } from './styles';

interface StatusLabelProps {
  cteEndorsement: ICteEndorsement;
  isLoading: boolean;
}

const StatusLabel: React.FC<StatusLabelProps> = ({
  cteEndorsement,
  isLoading,
}) => {
  const errorMessage = useMemo(() => {
    if (
      cteEndorsement.status !== CteEndorsementStatus.FAILED ||
      !cteEndorsement.error_reason
    ) {
      return;
    }

    const splittedErrorReason = cteEndorsement.error_reason.split(' - ');

    if (splittedErrorReason.length === 1) {
      return cteEndorsement.error_reason;
    } else {
      return splittedErrorReason[1];
    }
  }, [cteEndorsement.error_reason, cteEndorsement.status]);

  return (
    <>
      <Container status={cteEndorsement.status}>
        <Title>
          Situação do seguro
          {!isLoading && (
            <>
              {': '}
              <Status status={cteEndorsement.status}>
                {cteEndorsement.status}
              </Status>
            </>
          )}
        </Title>
        {isLoading ? (
          <CustomLoading />
        ) : (
          <Infos>
            {cteEndorsement.endorsement_date &&
              `Averbado em: ${formatToBrlDate(
                cteEndorsement.endorsement_date
              )}`}
            {cteEndorsement.cancel_date &&
              ` / Cancelado em: ${formatToBrlDate(cteEndorsement.cancel_date)}`}
            {errorMessage}
          </Infos>
        )}
      </Container>
    </>
  );
};

export default StatusLabel;
