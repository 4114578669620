import { color } from 'b2utils';
import { ArrowDown2 } from 'iconsax-react';
import { B2Button } from 'react-b2components';
import styled, { css } from 'styled-components';
import { addSpacing } from 'utils/styles';

interface GeneralProps {
  $expanded: boolean;
}

export const CustomButton = styled(B2Button)<GeneralProps>`
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 10px;
  gap: ${({ theme }) => theme.spacing.two};
  font-size: ${({ theme }) => theme.fontSize.small};
  padding: ${({ theme }) => addSpacing(theme.spacing.one, theme.spacing.three)};
  color: ${({ theme }) => theme.colors.main};
  background-color: ${({ theme }) =>
    color.addOpacityOnHexColor(theme.colors.main, 0.2)};

  svg {
    width: ${({ theme }) => theme.spacing.five};
    height: ${({ theme }) => theme.spacing.five};
  }

  ${({ $expanded }) =>
    $expanded &&
    css`
      opacity: 1 !important;
    `};

  @media (max-width: 1180px) {
    & > span {
      display: none;
    }
  }
`;

export const ButtonGroup = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};

  @media (max-width: 560px) {
    display: none;
  }
`;

export const VerticalDivider = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${({ theme }) =>
    color.addOpacityOnHexColor(theme.colors.main, 0.7)};
  margin: ${({ theme }) => addSpacing('0', theme.spacing.one)};
`;

export const ArrowIcon = styled(ArrowDown2)<GeneralProps>`
  color: ${({ theme }) => theme.colors.main};
  width: ${({ theme }) => theme.spacing.five};
  transform: rotate(${({ $expanded }) => ($expanded ? '-180deg' : '0deg')});
  transition: transform 0.5s;
`;

export const OptionsContainer = styled.div<GeneralProps>`
  display: grid;
  grid-template-rows: 0fr;
  transition: all 0.5s ease;
  overflow: hidden;
  position: absolute;
  top: 50px;
  right: 0;
  width: 100%;
  min-width: 200px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  border-radius: 10px;

  select {
    border: 1px solid ${({ theme }) => theme.colors.white.three};
    background-color: ${({ theme }) => theme.colors.white.main};
    background-image: url('/images/arrow-drop-down-variant.svg');
  }

  ${({ theme, $expanded }) =>
    $expanded &&
    css`
      grid-template-rows: 1fr;
      margin-bottom: ${theme.spacing.four};
    `};
`;

export const OptionsWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  gap: ${({ theme }) => theme.spacing.two};
  background-color: ${({ theme }) => theme.colors.white.main};
  z-index: 10;

  & > a:first-of-type {
    padding-top: ${({ theme }) => theme.spacing.three};
  }

  & > a:last-of-type {
    padding-bottom: ${({ theme }) => theme.spacing.three};
  }
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.white.three};
`;
