import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.financial.expenses.listExpenses.path,
    label: 'Financeiro',
  },
  {
    path: RoutesPath.private.financial.expenses.updateExpense.path,
    label: 'Editar despesa',
  },
];

export default routes;
