import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const BodyMessage = styled.p`
  text-align: center;
  padding: ${({ theme }) => addSpacing(theme.spacing.nine, theme.spacing.four)};
  color: ${({ theme }) => theme.colors.gray.five};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
