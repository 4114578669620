import React from 'react';

import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useCompanyUsers, useQueryParams } from '@hooks';

import ListFilters from '@components/ListFilters';
import PageHeader from '@components/PageHeader';
import SituationBadge from '@components/SituationBadge';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import RoutesPath from '@router/routes';

const ListCompanyUsers: React.FC = () => {
  const { changePage, onSearch, queryParams, changeIsActiveStatus } =
    useQueryParams({ page: 1 });
  const { listCompanyUsers } = useCompanyUsers();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { scope } = useScope();
  const { isManagementUser } = useAuth();

  const { isLoading, data: paginatedCompanyUsers } = useQuery(
    ['companyUsers', queryParams, scope],
    () => listCompanyUsers(queryParams),
    {
      onError() {
        addToast('Não foi possível carregar os usuários da empresa', 'error');
      },
    }
  );

  const title = isManagementUser ? 'Usuários da empresa' : 'Usuários internos';

  const subtitle = isManagementUser
    ? 'Listagem dos usuários da empresa no sistema'
    : 'Listagem dos usuários internos no sistema';

  const headerData = isManagementUser
    ? ['Empresa', 'Usuário', 'Email', 'Função', 'Situação']
    : ['Nome', 'Email', 'Função', 'Situação'];

  const navigateToCreateService = () => {
    if (isManagementUser && !scope) {
      addToast(
        'Não é possível cadastrar um usuário de empresa sem estar com uma empresa selecionada',
        'error'
      );
      return;
    }
    navigate(RoutesPath.private.companyUsers.createCompanyUser.path);
  };

  return (
    <>
      <PageHeader
        title={title}
        subtitle={subtitle}
        buttonProps={{
          text: 'Adicionar usuário',
          onClick: navigateToCreateService,
        }}
      />
      <ListFilters
        onSearch={onSearch}
        changeIsActiveStatus={{ queryParams, changeIsActiveStatus }}
      />
      <Table
        data={paginatedCompanyUsers?.results || []}
        headerData={headerData}
        emptyMessage="Nenhum usuário encontrado"
        isLoading={isLoading}
        renderRow={(companyUser) => (
          <B2TableRow
            key={companyUser.id}
            onClick={() =>
              navigate(
                RoutesPath.private.companyUsers.detailCompanyUser.path.replace(
                  ':companyUserId',
                  companyUser.id.toString()
                )
              )
            }
          >
            {isManagementUser && (
              <TableDataCell>{companyUser.company.name}</TableDataCell>
            )}
            <TableDataCell>{companyUser.name}</TableDataCell>
            <TableDataCell>{companyUser.email}</TableDataCell>
            <TableDataCell>{companyUser.role}</TableDataCell>
            <TableDataCell>
              <SituationBadge isActive={companyUser.is_active} />
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={paginatedCompanyUsers?.count || 0}
        changePage={changePage}
      />
    </>
  );
};

export default ListCompanyUsers;
