import React, { useRef } from 'react';

import { phone } from 'b2utils';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { StateRegistrationStatus } from 'utils/enums';
import { formatDocument } from 'utils/formats';

import { useToast } from '@contexts/Toast';
import { useClients, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';
import ClientInfo from '@components/ClientForm/ClientInfo';
import Loading from '@components/Loading';

import RoutesPath from '@router/routes';

import getRoutes from './routes';

const UpdateClient: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const clientInfoStepRef = useRef<IFormStepRef>(null);

  const { getClient, updateClient } = useClients();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useVerifyScope({
    routePath: RoutesPath.private.clients.listClients.path,
    toastErrorMessage: 'Selecione uma empresa para editar um cliente',
  });

  const { data: client, isLoading } = useQuery(
    ['client', clientId],
    () => getClient(clientId!),
    {
      onError: () => {
        addToast('Falha ao buscar os dados do cliente', 'error');
      },
    }
  );

  const updateClientRequest = useMutation(
    (client: IClientFormValues) => updateClient(clientId!, client),
    {
      onSuccess: () => {
        addToast('Dados do cliente atualizados com sucesso', 'success');
        navigate(
          RoutesPath.private.clients.detailClient.path.replace(
            ':clientId',
            clientId!
          )
        );
      },
      onError: () => {
        addToast('Erro ao tentar editar informações do cliente', 'error');
      },
    }
  );

  const clientInfoInitialValues: IClientInfoStepFormValues = {
    avatar: client?.avatar || null,
    name: client?.name || '',
    email: client?.email || '',
    document: client?.document ? formatDocument(client?.document) : '',
    phone: client?.phone
      ? phone.fromObject({
          areaCode: client.phone.area_code,
          number: client.phone.number,
        })
      : '',
    date_of_birth: client?.date_of_birth || '',
    state_registration_status:
      client?.state_registration_status || StateRegistrationStatus.EXEMPT,
    state_registration: client?.state_registration || '',
  };

  const steps: Array<IStep> = [
    {
      title: 'Informações do cliente',
      ref: clientInfoStepRef,
      component: ClientInfo,
    },
  ];

  if (isLoading || !client) {
    return <Loading />;
  }

  return (
    <BaseForm
      steps={steps}
      createRequest={updateClientRequest}
      routes={getRoutes(clientId!)}
      title="Editar cliente"
      initialValues={clientInfoInitialValues}
    />
  );
};

export default UpdateClient;
