import { color } from 'b2utils';
import styled from 'styled-components';
import { FinancialReportType } from 'utils/enums';
import { financialReportTypeProps } from 'utils/helpers';

interface CommonProps {
  type: FinancialReportType;
}

interface InputGroupProps extends CommonProps {
  checked: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RadioButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.three};
  margin-bottom: ${({ theme }) => theme.spacing.two};
`;

export const InputGroup = styled.div<InputGroupProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 140px;
  max-width: 360px;
  gap: ${({ theme }) => theme.spacing.three};
  padding: ${({ theme }) => theme.spacing.three};
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: all 0.5s ease-in-out;
  background-color: ${({ theme, checked, type }) =>
    checked
      ? color.addOpacityOnHexColor(
          financialReportTypeProps[type].color(theme),
          0.3
        )
      : theme.colors.white.three};

  &,
  label {
    cursor: pointer;
  }

  input[type='radio']:checked {
    background-color: ${({ theme, type }) =>
      financialReportTypeProps[type].color(theme)};
    border-color: ${({ theme, type }) =>
      financialReportTypeProps[type].color(theme)};
  }
`;

export const IconGroup = styled.div<CommonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.one};
  color: ${({ theme, type }) => financialReportTypeProps[type].color(theme)};

  svg {
    width: ${({ theme }) => theme.spacing.eight};
    height: ${({ theme }) => theme.spacing.eight};
  }
`;

export const Label = styled.label`
  margin-bottom: ${({ theme }) => theme.spacing.ten};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;
