import React, { useMemo } from 'react';

import {
  FileExtension,
  FileType,
  FinancialReportType,
  FinancialReportTypePluralMap,
  ReportDetailLevel,
} from 'utils/enums';

import { useReports } from '@hooks';

import BaseModal from '@components/BaseModal';
import ReportGenerationStatus from '@components/ReportGenerationStatus';

import { ModalContainer } from './styles';

interface ReportGenerationModalProps {
  id?: string;
  onClose: () => void;
  type?: FinancialReportType;
  detailLevel?: ReportDetailLevel;
}

const ReportGenerationModal: React.FC<ReportGenerationModalProps> = ({
  id,
  onClose,
  type,
  detailLevel,
}) => {
  const { getReports } = useReports();

  const typeProps = useMemo(() => {
    const props = {
      [FinancialReportType.INCOME]: {
        queryKey: 'incomePdfReport',
        fileType: FileType.INCOME,
      },
      [FinancialReportType.EXPENSE]: {
        queryKey: 'expensePdfReport',
        fileType: FileType.EXPENSE,
      },
      [FinancialReportType.MOVEMENT]: {
        queryKey: 'movementPdfReport',
        fileType: FileType.MOVEMENT,
      },
    };

    return props[type ?? FinancialReportType.INCOME];
  }, [type]);

  if (!id) {
    return null;
  }

  return (
    <BaseModal
      title={`Baixando relatório de ${
        type ? FinancialReportTypePluralMap[type].toLowerCase() : ''
      }`}
      isOpen
      onClose={onClose}
      onBackdropClick={onClose}
    >
      <ModalContainer>
        <ReportGenerationStatus
          id={id}
          type={typeProps.fileType}
          getFileRequest={getReports}
          queryKey={typeProps.queryKey}
          fileExtension={FileExtension.PDF}
          detailLevel={detailLevel}
        />
      </ModalContainer>
    </BaseModal>
  );
};

export default ReportGenerationModal;
