import { B2Button } from 'react-b2components';
import styled from 'styled-components';
import { ManagementButtonType } from 'utils/enums';
import { managementButtonTypeProps } from 'utils/helpers';
import { addSpacing } from 'utils/styles';

interface CustomButtonProps {
  buttonType: ManagementButtonType;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  gap: ${({ theme }) => theme.spacing.four};
  padding: ${({ theme }) => theme.spacing.four};
  border: 1px solid ${({ theme }) => theme.colors.white.three};
`;

export const Address = styled.p`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.three};

  input[type='radio'] {
    border-width: 1px;
    margin: ${({ theme }) =>
      addSpacing('0', theme.spacing.four, '0', theme.spacing.seven)};
  }
`;

export const CustomButton = styled(B2Button)<CustomButtonProps>`
  display: flex;
  align-items: center;
  border-radius: 50%;
  background-color: transparent;
  color: ${({ theme, buttonType }) =>
    managementButtonTypeProps[buttonType].color(theme)};
  padding: ${({ theme }) => theme.spacing.one};
  svg {
    width: ${({ theme }) => theme.spacing.five};
  }
`;
