import { ArrowCircleLeft2, ArrowCircleRight2 } from 'iconsax-react';
import styled, { css } from 'styled-components';

import Loading from '@components/Loading';

const Button = css`
  position: absolute;
  width: 5%;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white.two};
  transition: background-color 0.3s ease-in-out, width 0.3s ease-in-out;
  z-index: 1000;
  cursor: pointer;

  @media (max-width: 800px) {
    width: 10%;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.white.three};
    color: ${({ theme }) => theme.colors.gray.four};
  }
`;

export const ButtonToLeft = styled(ArrowCircleLeft2)`
  ${Button}
  left: ${({ theme }) => theme.spacing.two};
`;

export const ButtonToRight = styled(ArrowCircleRight2)`
  ${Button}
  right: ${({ theme }) => theme.spacing.two};
`;

export const CustomLoading = styled(Loading)`
  position: absolute;
  top: 50%;
  right: ${({ theme }) => theme.spacing.six};
  border-top-color: ${({ theme }) => theme.colors.white.main};
`;

export const EmptyView = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const EmptyViewContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
