import { useCallback } from 'react';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const generateExpenseReportRequestObject = (
  income: IExpenseReportFormValues
) => {
  return {
    date__gte: income.dateGte || undefined,
    date__lte: income.dateLte || undefined,
    provider: income.provider?.id || undefined,
    category: income.category?.id || undefined,
    type: income.type,
    status: income.status,
  };
};

const useExpenseReports = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const listExpenseReport = useCallback(
    async (params: IExpenseReportFormValues) => {
      const response = await request<IFinancialReport>({
        method: 'get',
        url: endpoints.financial.expenses.pdfReport.list,
        headers: { scope },
        params: generateExpenseReportRequestObject(params),
      });

      return response.data;
    },
    [request, scope]
  );

  const listSimplifiedExpenseReport = useCallback(
    async (params: IExpenseReportFormValues) => {
      const response = await request<IFinancialReport>({
        method: 'get',
        url: endpoints.financial.expenses.pdfReport.byProvider,
        headers: { scope },
        params: generateExpenseReportRequestObject(params),
      });

      return response.data;
    },
    [request, scope]
  );

  return { listExpenseReport, listSimplifiedExpenseReport };
};

export default useExpenseReports;
