import React, { useCallback } from 'react';

import { Anchor, Container, DocumentDownloadIcon } from './styles';

interface DocumentDownloadButtonProps {
  fileName?: string;
  label?: string;
  fileUrl: string;
}

const DocumentDownloadButton: React.FC<DocumentDownloadButtonProps> = ({
  label = 'Baixar documento',
  fileName,
  fileUrl,
}) => {
  const downloadFileFromBlob = useCallback(async () => {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');

    anchor.href = url;
    anchor.download = fileName!;
    document.body.appendChild(anchor);
    anchor.click();
  }, [fileName, fileUrl]);

  if (fileName) {
    return (
      <Container onClick={downloadFileFromBlob}>
        {label} <DocumentDownloadIcon variant="Bold" />
      </Container>
    );
  }

  return (
    <Anchor href={fileUrl} target="_blank" rel="noreferrer" download>
      <Container>
        {label} <DocumentDownloadIcon variant="Bold" />
      </Container>
    </Anchor>
  );
};

export default DocumentDownloadButton;
