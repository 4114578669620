import { useCallback } from 'react';

import { phone } from 'b2utils';
import regex from 'utils/regex';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const generateCompanyUserObject = (companyUser: ICompanyUserFormValues) => {
  const phoneObject = companyUser?.phone
    ? phone.toObject(companyUser.phone)
    : null;

  return {
    ...companyUser,
    avatar: companyUser.avatar?.id || null,
    document: companyUser.document?.replace(regex.onlyNumbers, ''),
    phone: phoneObject && {
      area_code: phoneObject.areaCode || '',
      number: phoneObject.number,
    },
  };
};

const useCompanyUsers = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const listCompanyUsers = useCallback(
    async (params: CompanyUsersQueryParams) => {
      const response = await request<IPaginated<ICompanyUser>>({
        method: 'get',
        url: endpoints.companyUsers.list,
        headers: { scope },
        params: {
          page: params.page,
          search: params.search || undefined,
          is_active: params.isActive || undefined,
          can_be_in_charge_of_services: params.canBeInChargeOfServices,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getCompanyUser = useCallback(
    async (id: string) => {
      const response = await request<ICompanyUser>({
        method: 'get',
        url: endpoints.companyUsers.get.replace(':companyUserId', id),
        headers: { scope },
      });
      return response.data;
    },
    [request, scope]
  );

  const updateCompanyUserIsActiveSituation = useCallback(
    async (id: string, isActive: boolean) => {
      const response = await request<ICompanyUser>({
        method: 'patch',
        url: endpoints.companyUsers.update.replace(':companyUserId', id),
        headers: { scope },
        data: { is_active: isActive },
      });
      return response.data;
    },
    [request, scope]
  );

  const createCompanyUser = useCallback(
    async (companyUser: ICompanyUserFormValues) => {
      const response = await request<ICompanyUser>({
        method: 'post',
        url: endpoints.companyUsers.create,
        headers: { scope },
        data: generateCompanyUserObject(companyUser),
      });
      return response.data;
    },
    [request, scope]
  );

  const updateCompanyUser = useCallback(
    async (id: string, companyUser: ICompanyUserFormValues) => {
      const response = await request<ICompanyUser>({
        method: 'patch',
        url: endpoints.companyUsers.update.replace(':companyUserId', id),
        headers: { scope },
        data: generateCompanyUserObject(companyUser),
      });
      return response.data;
    },
    [request, scope]
  );

  const changeCompanyUserPassword = useCallback(
    async (id: number, password: string) => {
      const response = await request<ICompanyUser>({
        method: 'patch',
        url: endpoints.companyUsers.update.replace(
          ':companyUserId',
          id.toString()
        ),
        headers: { scope },
        data: { password },
      });
      return response.data;
    },
    [request, scope]
  );

  const deleteCompanyUser = useCallback(
    async (id: number, password: string) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.companyUsers.delete.replace(
          ':companyUserId',
          id.toString()
        ),
        headers: { scope },
        data: {
          password,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  return {
    listCompanyUsers,
    getCompanyUser,
    createCompanyUser,
    updateCompanyUserIsActiveSituation,
    updateCompanyUser,
    changeCompanyUserPassword,
    deleteCompanyUser,
  };
};

export default useCompanyUsers;
