import React from 'react';

import { ElementInside, Toggle } from './styles';

interface SwitchProps {
  checked: boolean;
  onChange: (field: string, value: boolean) => void;
  name: string;
}

const Switch: React.FC<SwitchProps> = ({ checked, name, onChange }) => {
  return (
    <Toggle $isChecked={checked}>
      <ElementInside
        id={name}
        name={name}
        type="button"
        $isChecked={checked}
        onClick={() => onChange(name, !checked)}
      />
    </Toggle>
  );
};

export default Switch;
