import React from 'react';

import { Icon as IconType } from 'iconsax-react';
import { Link } from 'react-router-dom';

interface ActionIconProps {
  icon: IconType;
  tooltipMessage: string;
  routePath?: string;
  onClick?: () => void;
  tooltipId: string;
}

const ActionIcon: React.FC<ActionIconProps> = ({
  icon: Icon,
  tooltipMessage,
  routePath,
  onClick,
  tooltipId,
}) => {
  if (routePath) {
    return (
      <Link to={routePath} target="_blank">
        <Icon
          data-tooltip-id={tooltipId}
          data-tooltip-content={tooltipMessage}
        />
      </Link>
    );
  }

  return (
    <Icon
      data-tooltip-id={tooltipId}
      data-tooltip-content={tooltipMessage}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.();
      }}
    />
  );
};

export default ActionIcon;
