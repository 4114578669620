import { MdOpenInNew, MdDelete } from 'react-icons/md';
import styled, { css } from 'styled-components';

interface IconProps {
  disabled?: boolean;
}

export const Container = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
`;

export const FileNameContainer = styled.a`
  width: 100%;
  gap: 12px;
  display: flex;
  align-items: center;
  padding: 14px 24px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.white.two};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const FileName = styled.span`
  color: ${({ theme }) => theme.colors.info};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const OpenInNewIcon = styled(MdOpenInNew)`
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.info};
  font-size: ${({ theme }) => theme.fontSize.medium};
`;

export const DeleteIcon = styled(MdDelete)<IconProps>`
  margin-left: 8px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fontSize.large};

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        `}
`;
