import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.spacing.four};
  gap: ${({ theme }) => theme.spacing.one};
  flex-wrap: wrap;
  align-items: center;
`;

export const FieldName = styled.label`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.black.one};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export const Description = styled.span<{ hasHighlight?: boolean }>`
  color: ${({ theme, hasHighlight }) =>
    hasHighlight ? theme.colors.main : theme.colors.gray.six};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export const LinkContainer = styled.a`
  display: flex;
  gap: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.gray.six};
  font-size: ${({ theme }) => theme.fontSize.small};
  word-break: break-word;

  &:hover {
    color: ${({ theme }) => theme.colors.info};
    cursor: pointer;
  }
`;

export const SwitchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
