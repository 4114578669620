import React, { useMemo, useState } from 'react';

import { Tooltip } from 'react-tooltip';
import {
  CompanyUserRole,
  InspectionStatus,
  ServiceStatus,
  ServiceType,
} from 'utils/enums';
import {
  cteEndorsementStatusProps,
  cteIssuanceStatusProps,
} from 'utils/helpers';

import { useAuth } from '@contexts/Auth';

import CteEndorsementModal from '@components/CteEndorsementModal';
import CteIssuanceModal from '@components/CteIssuanceModal';
import DeliveryReceiptModal from '@components/DeliveryReceiptModal';
import InspectionModal from '@components/InspectionModal';
import ServiceContractModal from '@components/ServiceContractModal';

import ServiceProgress from './ServiceProgress';
import ServiceStatusButton from './ServiceStatusButton';
import {
  CarIcon,
  Container,
  DeliveryReceiptIcon,
  DocumentTextIcon,
  HistoryContainer,
  IconsContainer,
  ProgressIcon,
  ProgressText,
  ProgressTextContainer,
  RightSide,
  ServiceStatusLabel,
} from './styles';

interface ServiceStatusesInfoProps {
  service: IService;
  refetchServices: () => void;
}

const ServiceStatusesInfo: React.FC<ServiceStatusesInfoProps> = ({
  service,
  refetchServices,
}) => {
  const { userInfo, isManagementUser, isCompanyUser, isClientUser } = useAuth();

  const [isServiceContractOpen, setIsServiceContractOpen] = useState(false);
  const [isVehicleInspectionModalOpen, setIsVehicleInspectionModalOpen] =
    useState(false);
  const [isCteModalOpen, setIsCteModalOpen] = useState(false);
  const [isCteEndorsementModalOpen, setIsCteEndorsementModalOpen] =
    useState(false);
  const [isDeliveryReceiptModalOpen, setIsDeliveryReceiptModalOpen] =
    useState(false);

  const hasUserPermission = useMemo(
    () =>
      isManagementUser ||
      (isCompanyUser && userInfo?.role === CompanyUserRole.ADMIN) ||
      (isCompanyUser && userInfo?.role === CompanyUserRole.LOGISTICS),
    [isCompanyUser, isManagementUser, userInfo?.role]
  );

  const hasStatusButtonPermission = useMemo(
    () =>
      service.status === ServiceStatus.WAITING_COLLECTION ||
      service.status === ServiceStatus.WAITING_DELIVERY ||
      service.status === ServiceStatus.WAITING_DEPARTURE,
    [service.status]
  );

  const shouldRenderStatusButton =
    hasUserPermission && hasStatusButtonPermission;

  return (
    <>
      <Tooltip id="service-statuses-tooltip" />
      <Container>
        <HistoryContainer>
          <ProgressIcon variant="Bold" />
          <ProgressTextContainer>
            <ProgressText>Histórico de etapas</ProgressText>
            <ServiceProgress currentServiceStatus={service.status} />
          </ProgressTextContainer>
        </HistoryContainer>
        <RightSide>
          <IconsContainer>
            {service.type !== ServiceType.STORAGE &&
              !!service.contract?.status && (
                <>
                  <DocumentTextIcon
                    data-tooltip-id="service-statuses-tooltip"
                    data-tooltip-content="Contrato"
                    variant="Bold"
                    status={service.contract.status}
                    onClick={(event) => {
                      event.preventDefault();
                      setIsServiceContractOpen(true);
                    }}
                  />
                </>
              )}
            <CarIcon
              $isInspectionFinished={
                service.inspection.status === InspectionStatus.FINISHED
              }
              data-tooltip-id="service-statuses-tooltip"
              data-tooltip-content="Relatório de vistoria"
              variant="Bold"
              onClick={() =>
                setIsVehicleInspectionModalOpen((prevState) => !prevState)
              }
            />
            {service.type === ServiceType.OWN &&
              service.cte &&
              !isClientUser && (
                <>
                  <img
                    src={cteIssuanceStatusProps[service.cte.status].imageSource}
                    alt="CT-e ícone"
                    data-tooltip-id="service-statuses-tooltip"
                    data-tooltip-content={
                      cteIssuanceStatusProps[service.cte.status].tooltipMessage
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      setIsCteModalOpen(true);
                    }}
                  />
                </>
              )}
            {service.type === ServiceType.OWN && service.cte?.endorsement && (
              <img
                src={
                  cteEndorsementStatusProps[service.cte?.endorsement.status]
                    .imageSource
                }
                alt="Averbação de CT-e ícone"
                data-tooltip-id="service-statuses-tooltip"
                data-tooltip-content={
                  cteEndorsementStatusProps[service.cte?.endorsement.status]
                    .tooltipMessage
                }
                onClick={(event) => {
                  event.preventDefault();
                  setIsCteEndorsementModalOpen(true);
                }}
              />
            )}
            <DeliveryReceiptIcon
              data-tooltip-id="service-statuses-tooltip"
              data-tooltip-content="Termo de entrega"
              variant="Bold"
              $deliveryReceiptSent={service.delivery_receipt.has_file}
              onClick={(event) => {
                event.preventDefault();
                setIsDeliveryReceiptModalOpen((prevState) => !prevState);
              }}
            />
          </IconsContainer>
          {shouldRenderStatusButton ? (
            <ServiceStatusButton status={service.status} />
          ) : (
            <ServiceStatusLabel status={service.status}>
              {service.status}
            </ServiceStatusLabel>
          )}
        </RightSide>
      </Container>
      <InspectionModal
        isOpen={isVehicleInspectionModalOpen}
        onClose={() => setIsVehicleInspectionModalOpen(false)}
        service={service}
        onCancelInspectionSuccess={refetchServices}
      />
      {service.type === ServiceType.OWN && (
        <>
          <CteIssuanceModal
            service={service}
            isOpen={isCteModalOpen}
            onClose={() => setIsCteModalOpen(false)}
            refetchServices={refetchServices}
          />
          <CteEndorsementModal
            isOpen={isCteEndorsementModalOpen}
            onClose={() => setIsCteEndorsementModalOpen(false)}
            service={service}
            refetchServices={refetchServices}
          />
        </>
      )}
      <DeliveryReceiptModal
        deliveryReceiptId={service.delivery_receipt.id}
        serviceNumber={service.number}
        hasDelivered={service.delivery_receipt.has_file}
        isOpen={isDeliveryReceiptModalOpen}
        onClose={() => setIsDeliveryReceiptModalOpen(false)}
        onSubmitSuccess={refetchServices}
      />
      {service.type !== ServiceType.STORAGE && !!service.contract?.status && (
        <ServiceContractModal
          isOpen={isServiceContractOpen}
          onClose={() => setIsServiceContractOpen(false)}
          clientName={service.client.name}
          serviceNumber={service.number}
          contractId={service.contract.id}
          contractStatus={service.contract.status}
          onSubmitSuccess={refetchServices}
        />
      )}
    </>
  );
};

export default ServiceStatusesInfo;
