import React from 'react';

import { StateRegistrationStatus } from 'utils/enums';
import { formatDocument } from 'utils/formats';
import regex from 'utils/regex';

import {
  InputGroup,
  RadioButtonsContainer,
} from '@components/Base/PrivateBase/styles';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';

interface VehicleOwnerInfoProps {
  driver: IDriver;
}

const VehicleOwnerInfo: React.FC<VehicleOwnerInfoProps> = ({ driver }) => {
  const stateRegistrationStatuses = Object.values(StateRegistrationStatus);

  const isCnpj =
    driver.truck.vehicle_owner_document.replace(regex.onlyNumbers, '')
      .length === 14;

  const isTaxPayer =
    driver.truck.state_registration_status === StateRegistrationStatus.TAXPAYER;

  return (
    <>
      <FormRow>
        <FormGroup>
          <Label>Nome / Razão social do proprietário</Label>
          <Input type="text" disabled value={driver.truck.vehicle_owner_name} />
        </FormGroup>
        <FormGroup>
          <Label>CPF / CNPJ do proprietário</Label>
          <Input
            type="text"
            disabled
            value={formatDocument(driver.truck.vehicle_owner_document)}
          />
        </FormGroup>
      </FormRow>
      {isCnpj && (
        <FormRow>
          <FormGroup>
            <Label>Inscrição estadual</Label>
            <RadioButtonsContainer>
              {stateRegistrationStatuses.map((value) => (
                <InputGroup key={value}>
                  <Input
                    type="radio"
                    disabled
                    checked={driver.truck.state_registration_status === value}
                  />
                  <Label>{value}</Label>
                </InputGroup>
              ))}
            </RadioButtonsContainer>
            {isTaxPayer && (
              <Input
                type="text"
                disabled
                placeholder="Digite aqui"
                value={driver.truck.state_registration || ''}
              />
            )}
          </FormGroup>
        </FormRow>
      )}
    </>
  );
};

export default VehicleOwnerInfo;
