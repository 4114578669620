import React, { useEffect, useMemo } from 'react';

import { Form, FormikProvider, useFormik } from 'formik';
import { B2Button } from 'react-b2components';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import RoutesPath from 'router/routes';
import { errors } from 'utils';
import * as yup from 'yup';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useCompanies, useDebounce } from '@hooks';

import { SubmitContainer } from '@components/Base/AuthBase/styles';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Loading from '@components/Loading';
import PasswordInput from '@components/PasswordInput';

import { ButtonsContainer } from '../styles';

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const { login, setCompanyLogo } = useAuth();
  const { getCompanyLogo } = useCompanies();
  const { addToast } = useToast();

  const searchParam = useMemo(() => new URLSearchParams(search), [search]);

  const scopeParam = searchParam.get('empresa');

  const isCompanyLogin = pathname === RoutesPath.auth.login.path;

  const onLoginFormSubmit = useMutation(
    (values: ILoginFormValues) => login(values),
    {
      onError: () => {
        addToast(
          'Nenhuma conta ativa encontrada com as credenciais dadas',
          'error'
        );
      },
    }
  );

  const { mutate: requestScopeLogo } = useMutation(
    ['companyLogo', scopeParam],
    (value: string) => getCompanyLogo(value),
    {
      onSuccess: (data) => {
        setCompanyLogo(data);
      },
      onError: () => {
        setCompanyLogo(undefined);
      },
    }
  );

  const debouceCompanyScope = useDebounce((value: string) => {
    requestScopeLogo(value);
  }, 1000);

  useEffect(
    () => requestScopeLogo(scopeParam!),
    [requestScopeLogo, scopeParam]
  );

  const formikInitialValues: ILoginFormValues = {
    email: '',
    password: '',
    scope: scopeParam || '',
  };

  const formikValidationSchema = yup.object().shape({
    email: yup.string().trim().email(errors.email).required(errors.required),
    password: yup.string().trim().required(errors.required),
    scope: yup
      .string()
      .trim()
      .when({
        is: () => !!isCompanyLogin,
        then: (schema) => schema.required(errors.required),
      }),
  });

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: (values) => onLoginFormSubmit.mutate(values),
  });

  const navigateToResetPassword = () => {
    if (isCompanyLogin) {
      navigate(RoutesPath.auth.resetPassword.path);
    } else {
      navigate(RoutesPath.auth.managementResetPassword.path);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        {isCompanyLogin && (
          <FormRow>
            <FormGroup>
              <Label htmlFor="scope">Identificador da empresa</Label>
              <Input
                type="text"
                id="scope"
                name="scope"
                placeholder="Identificador da empresa"
                onChange={(event) => {
                  formik.setFieldValue('scope', event.target.value);
                  debouceCompanyScope(event.target.value);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.scope}
                disabled={!!scopeParam}
                invalidValue={!!formik.touched.scope && !!formik.errors.scope}
              />
              <FormError name="scope" />
            </FormGroup>
          </FormRow>
        )}
        <FormRow>
          <FormGroup>
            <Label htmlFor="email">E-mail</Label>
            <Input
              type="email"
              placeholder="Digite o seu e-mail"
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              invalidValue={!!formik.touched.email && !!formik.errors.email}
            />
            <FormError name="email" />
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Label htmlFor="password">Senha</Label>
            <PasswordInput
              type="password"
              placeholder="Digite sua senha"
              id="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              invalidValue={
                !!formik.touched.password && !!formik.errors.password
              }
            />
            <FormError name="password" />
          </FormGroup>
        </FormRow>
        <SubmitContainer>
          {onLoginFormSubmit.isLoading ? (
            <Loading />
          ) : (
            <ButtonsContainer>
              <B2Button variant="primary" type="submit">
                Entrar
              </B2Button>
              <B2Button
                variant="transparent"
                onClick={() => navigateToResetPassword()}
              >
                Esqueceu a senha?
              </B2Button>
            </ButtonsContainer>
          )}
        </SubmitContainer>
      </Form>
    </FormikProvider>
  );
};

export default LoginForm;
