import React from 'react';

import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useNavigateWithScope, useProviders } from '@hooks';

import Loading from '@components/Loading';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import ExpensesList from './ExpensesList';
import ExpensesPaymentsList from './ExpensesPaymentsList';
import ProviderInfo from './ProviderInfo';
import routes from './routes';
import { Container } from './styles';

const DetailProvider: React.FC = () => {
  const { providerId } = useParams<{ providerId: string }>();

  const { getProvider } = useProviders();
  const { navigateWithScope } = useNavigateWithScope();
  const { addToast } = useToast();

  const { data: provider, isLoading } = useQuery(
    ['provider', providerId],
    () => getProvider(providerId!),
    {
      onError: () => {
        addToast('Falha ao buscar os dados do fornecedor', 'error');
      },
    }
  );

  const isDataLoading = isLoading || !provider;

  return (
    <>
      <PageHeader
        title="Detalhes do fornecedor"
        routes={routes}
        editButtonProps={{
          onClick: () =>
            navigateWithScope({
              routePath: RoutesPath.private.providers.editProvider.path.replace(
                ':providerId',
                providerId!
              ),
              company: provider?.company,
            }),
        }}
      />
      {isDataLoading ? (
        <Loading />
      ) : (
        <Container>
          <ProviderInfo provider={provider} />
          <ExpensesList provider={provider} />
          <ExpensesPaymentsList />
        </Container>
      )}
    </>
  );
};

export default DetailProvider;
