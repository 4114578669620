export const endpoints = {
  auth: {
    login: '/auth/token/',
    profile: '/auth/profile/',
    resetPassword: '/auth/reset-password/',
    updatePassword: '/auth/update-password/',
    updateEmail: '/auth/update-email/',
    confirmEmail: '/auth/confirm-email/',
    removeAccount: '/auth/remove-account/',
  },
  assets: {
    images: {
      post: '/assets/images/',
      presignedUrl: {
        get: '/assets/images/get-presigned-post-url/',
      },
    },
    files: {
      post: '/assets/files/',
      presignedUrl: {
        get: '/assets/files/get-presigned-post-url/',
      },
    },
  },
  zipCode: {
    get: '/cep/v1/:zipCode',
  },
  core: {
    cities: {
      list: '/core/cities/',
    },
    addresses: {
      create: '/core/addresses/',
      update: '/core/addresses/:addressId/',
      delete: '/core/addresses/:addressId/',
    },
  },
  companies: {
    list: '/companies/',
    get: '/companies/:companyId/',
    create: '/companies/',
    update: '/companies/:companyId/',
    delete: '/companies/:companyId/',
    checkField: '/companies/check-unique-fields/',
    logo: '/companies/logo/',
  },
  companyUsers: {
    list: '/auth/company-users/',
    get: '/auth/company-users/:companyUserId/',
    create: '/auth/company-users/',
    update: '/auth/company-users/:companyUserId/',
    delete: '/auth/company-users/:companyUserId/',
  },
  managementUsers: {
    list: '/auth/management-users/',
    get: '/auth/management-users/:managementUserId/',
    create: '/auth/management-users/',
    update: '/auth/management-users/:managementUserId/',
  },
  clients: {
    list: '/auth/clients/',
    get: '/auth/clients/:clientId/',
    create: '/auth/clients/',
    update: '/auth/clients/:clientId/',
  },
  services: {
    list: '/services/',
    get: '/services/:serviceId/',
    create: '/services/',
    cancel: '/services/:serviceId/cancel/',
    finalize: '/services/:serviceId/finalize/',
    update: '/services/:serviceId/',
    changeLastKnownLocation: '/services/:serviceId/change-last-known-location/',
    cfops: {
      list: '/services/cfop/',
    },
    logs: {
      list: '/services/logs/',
    },
    deliveryReceipt: {
      list: '/services/delivery-receipts/',
      get: '/services/delivery-receipts/:receiptId/',
      post: '/services/delivery-receipts/:receiptId/complete/',
      template: '/services/delivery-receipts/:receiptId/template/',
    },
    comments: {
      list: '/services/comments/',
      create: '/services/comments/',
      update: '/services/comments/:commentId/',
      delete: '/services/comments/:commentId/',
    },
    contracts: {
      template: '/services/contracts/:contractId/template/',
      upload: '/services/contracts/:contractId/upload/',
      get: '/services/contracts/:contractId/',
      list: '/services/contracts/',
    },
  },
  inspections: {
    list: '/inspections/',
    images: '/inspections/inspection-images/',
    views: '/inspections/vehicle-views/',
    cancel: '/inspections/:inspectionCode/cancel/',
    report: '/inspections/:inspectionCode/report/',
  },
  providers: {
    create: '/providers/',
    list: '/providers/',
    get: '/providers/:providerId/',
    update: '/providers/:providerId/',
  },
  drivers: {
    create: '/auth/drivers/',
    list: '/auth/drivers/',
    get: '/auth/drivers/:driverId/',
    update: '/auth/drivers/:driverId/',
  },
  departures: {
    list: '/departures/',
    create: '/departures/',
    get: '/departures/:departureId/',
    update: '/departures/:departureId/',
    finish: '/departures/:departureId/finish/',
    cancel: '/departures/:departureId/cancel/',
    comments: {
      list: '/departures/comments/',
      create: '/departures/comments/',
      update: '/departures/comments/:commentId/',
      delete: '/departures/comments/:commentId/',
    },
    logs: {
      list: '/departures/logs/',
    },
    services: {
      list: '/departures/departures-services/',
      update: '/departures/departures-services/:id/',
    },
  },
  fiscal: {
    cte: {
      list: '/fiscal/cte/',
      get: '/fiscal/cte/:cteId/',
      issue: '/fiscal/cte/:cteId/issue/',
      cancel: '/fiscal/cte/:cteId/cancel/',
      rectify: '/fiscal/cte/:cteId/rectify/',
    },
    mdfe: {
      get: '/fiscal/mdfe/:mdfeId/',
      issue: '/fiscal/mdfe/:mdfeId/issue/',
      cancel: '/fiscal/mdfe/:mdfeId/cancel/',
      list: '/fiscal/mdfe/',
    },
    mdfeKeys: {
      close: '/fiscal/mdfe-keys/close/',
    },
    endorsement: {
      get: '/fiscal/endorsement/:endorsementId/',
      retry: '/fiscal/endorsement/:endorsementId/retry-endorsement/',
    },
    zipFiles: {
      requestFileGeneration: '/fiscal/zip-files/new/',
      get: '/fiscal/zip-files/:FiscalZipFileId',
    },
  },
  financial: {
    paymentTerm: {
      create: '/financial/payment-terms/',
      list: '/financial/payment-terms/',
      update: '/financial/payment-terms/:id/',
      delete: '/financial/payment-terms/:id/',
      get: '/financial/payment-terms/:id/',
    },
    incomes: {
      list: '/financial/incomes/',
      listByClient: '/financial/incomes/by-client/',
      get: '/financial/incomes/:incomeId/',
      create: '/financial/incomes/',
      update: '/financial/incomes/:incomeId/',
      delete: '/financial/incomes/:incomeId/',
      categories: {
        list: '/financial/incomes/categories/',
        get: '/financial/incomes/categories/:categoryId/',
        create: '/financial/incomes/categories/',
        update: '/financial/incomes/categories/:categoryId/',
      },
      payments: {
        list: '/financial/incomes/payments/',
        create: '/financial/incomes/payments/',
        delete: '/financial/incomes/payments/:paymentId/',
      },
      paymentsToIncomes: {
        list: '/financial/incomes/payments-to-incomes/',
      },
      pdfReport: {
        list: '/financial/incomes/pdf-report/',
        byClient: '/financial/incomes/pdf-report-by-client/',
      },
    },
    expenses: {
      list: '/financial/expenses/',
      get: '/financial/expenses/:expenseId/',
      create: '/financial/expenses/',
      update: '/financial/expenses/:expenseId/',
      delete: '/financial/expenses/:expenseId/',
      categories: {
        list: '/financial/expenses/categories/',
        get: '/financial/expenses/categories/:categoryId/',
        create: '/financial/expenses/categories/',
        update: '/financial/expenses/categories/:categoryId/',
      },
      payments: {
        list: '/financial/expenses/payments/',
        create: '/financial/expenses/payments/',
        delete: '/financial/expenses/payments/:paymentId/',
      },
      paymentsToExpense: {
        list: '/financial/expenses/payments-to-expense/',
      },
      report: {
        get: '/financial/expenses/report/',
      },
      byProvider: {
        list: '/financial/expenses/by-provider/',
      },
      pdfReport: {
        list: '/financial/expenses/pdf-report/',
        byProvider: '/financial/expenses/pdf-report-by-provider/',
      },
    },
    payments: {
      paymentMethods: {
        list: '/financial/payments/payment-methods/',
        get: '/financial/payments/payment-methods/:paymentMethodId/',
        create: '/financial/payments/payment-methods/',
        update: '/financial/payments/payment-methods/:paymentMethodId/',
      },
      bankAccounts: {
        list: '/financial/payments/bank-accounts/',
        get: '/financial/payments/bank-accounts/:bankAccountId/',
        create: '/financial/payments/bank-accounts/',
        update: '/financial/payments/bank-accounts/:bankAccountId/',
      },
    },
    forecast: {
      get: '/financial/forecast/',
    },
    transfers: {
      get: '/financial/transfers/:transferId/',
      create: '/financial/transfers/',
      list: '/financial/transfers/',
      update: '/financial/transfers/:transferId/',
      delete: '/financial/transfers/:transferId/',
    },
    movements: {
      list: '/financial/movements/',
      pdfReport: {
        get: '/financial/movements/pdf-report/:id',
        list: '/financial/movements/pdf-report/',
      },
    },
    reports: '/financial/reports/:id',
  },
  quotations: {
    get: '/quotations/:quotationId/',
    list: '/quotations/',
    create: '/quotations/',
    update: '/quotations/:quotationId/',
    delete: '/quotations/:quotationId/',
  },
  metrics: {
    quotations: {
      count: '/metrics/quotations/count/',
      dateAggregation: '/metrics/quotations/date-aggregation/',
    },
    services: {
      count: '/metrics/services/count/',
      dateAggregation: '/metrics/services/date-aggregation/',
      countByRelatedEntity: '/metrics/services/count-by-related-entity/',
    },
    departures: {
      count: '/metrics/departures/count/',
      dateAggregation: '/metrics/departures/date-aggregation/',
    },
    inspections: {
      count: '/metrics/inspections/count/',
      dateAggregation: '/metrics/inspections/date-aggregation/',
    },
  },
};
