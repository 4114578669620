import { useCallback } from 'react';

import { phone } from 'b2utils';
import { DriverVehicleType, StateRegistrationStatus } from 'utils/enums';
import regex from 'utils/regex';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const generateDriverObject = (driver: IDriverFormValues) => {
  const isCarrierTrailer =
    driver.vehicleType === DriverVehicleType.CAR_CARRIER_TRAILER;

  const phoneObject = driver?.phone ? phone.toObject(driver.phone) : null;

  const isTaxPayer =
    driver.stateRegistrationStatus === StateRegistrationStatus.TAXPAYER;

  const isCnpjDocument =
    driver.vehicleOwnerDocument.replace(regex.onlyNumbers, '').length === 14;

  const truck = {
    vehicle_type: driver.vehicleType,
    truck_license_plate: driver.truckLicensePlate,
    truck_license_state: driver.truckLicenseState,
    truck_tare_weight: Number(driver.truckTareWeight),
    antt: driver.antt,
    antt_file: driver.anttFile?.id || null,
    truck_crlv_file: driver.truckCrlvFile?.id || null,
    trailer_license_plate: isCarrierTrailer ? driver.trailerLicensePlate : null,
    trailer_license_state: isCarrierTrailer ? driver.trailerLicenseState : null,
    trailer_tare_weight: isCarrierTrailer
      ? Number(driver.trailerTareWeight)
      : null,
    trailer_payload_capacity: isCarrierTrailer
      ? Number(driver.trailerPayloadCapacity)
      : null,
    trailer_crlv_file: isCarrierTrailer
      ? driver.trailerCrlvFile?.id || null
      : null,
    vehicle_owner_name: driver.vehicleOwnerName,
    vehicle_owner_document: driver.vehicleOwnerDocument.replace(
      regex.onlyNumbers,
      ''
    ),
    state_registration_status: isCnpjDocument
      ? driver.stateRegistrationStatus
      : StateRegistrationStatus.NON_TAXPAYER,
    state_registration:
      isTaxPayer && isCnpjDocument ? driver.stateRegistration : null,
  };

  return {
    name: driver.name,
    email: driver.email || null,
    date_of_birth: driver.dateOfBirth || null,
    document: driver.document.replace(regex.onlyNumbers, ''),
    phone: phoneObject && {
      area_code: phoneObject.areaCode || '',
      number: phoneObject.number,
    },
    carrier_type: driver.carrierType,
    drivers_license_file: driver.driversLicenseFile?.id || null,
    truck,
  };
};

const useDrivers = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const createDriver = useCallback(
    async (driver: IDriverFormValues) => {
      const response = await request<IDriver>({
        method: 'post',
        url: endpoints.drivers.create,
        headers: { scope },
        data: generateDriverObject(driver),
      });
      return response.data;
    },
    [request, scope]
  );

  const listDrivers = useCallback(
    async (params: IQueryParams) => {
      const response = await request<IPaginated<IDriver>>({
        method: 'get',
        url: endpoints.drivers.list,
        headers: { scope },
        params: {
          page: params.page,
          search: params.search || undefined,
          is_active: params.isActive,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getDriver = useCallback(
    async (driverId: string) => {
      const response = await request<IDriver>({
        method: 'get',
        url: endpoints.drivers.get.replace(':driverId', driverId),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const updateIsActiveDriverStatus = useCallback(
    async (driverId: string, isActive: boolean) => {
      const response = await request<IDriver>({
        method: 'patch',
        url: endpoints.drivers.update.replace(':driverId', driverId),
        headers: { scope },
        data: { is_active: isActive },
      });

      return response.data;
    },
    [request, scope]
  );

  const updateDriver = useCallback(
    async (driverId: string, driver: IDriverFormValues) => {
      const response = await request<IDriver>({
        method: 'patch',
        url: endpoints.drivers.update.replace(':driverId', driverId),
        headers: { scope },
        data: generateDriverObject(driver),
      });

      return response.data;
    },
    [request, scope]
  );

  return {
    createDriver,
    listDrivers,
    getDriver,
    updateIsActiveDriverStatus,
    updateDriver,
  };
};

export default useDrivers;
