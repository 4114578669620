import { B2Button } from 'react-b2components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const CustomButton = styled(B2Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.three};
  border-radius: ${({ theme }) => theme.spacing.three};
`;
