import React from 'react';

import { ContractStatusMap, SendingStatus } from 'utils/enums';

import { Container, Label, Square } from './styles';

interface SendingStatusLabelProps {
  isContractStatus?: boolean;
  status: SendingStatus;
}

const SendingStatusLabel: React.FC<SendingStatusLabelProps> = ({
  isContractStatus = false,
  status,
}) => {
  return (
    <Container>
      <Square status={status} />
      <Label status={status}>
        {isContractStatus ? ContractStatusMap[status] : status}
      </Label>
    </Container>
  );
};

export default SendingStatusLabel;
