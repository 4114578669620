import React, { forwardRef, useImperativeHandle } from 'react';

import { currency } from 'b2utils';
import { FormikProvider, getIn, useFormik } from 'formik';
import { Additem, UserAdd } from 'iconsax-react';
import { MAX_VALUE } from 'utils/constants';
import { CompanyUserRole, ServiceType, TakerType } from 'utils/enums';
import { isCurrencyFieldValueBetween, toAddressString } from 'utils/helpers';
import regex from 'utils/regex';
import * as yup from 'yup';

import { useAuth } from '@contexts/Auth';
import {
  useClients,
  useCompanyUsers,
  useIncomeCategories,
  usePaymentMethods,
  usePaymentTerms,
} from '@hooks';

import AddNewEntityButton from '@components/AddNewEntityButton';
import {
  InputGroup,
  RadioButtonsContainer,
} from '@components/Base/PrivateBase/styles';
import BaseDateInput from '@components/BaseDateInput';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';
import ToggleInput from '@components/ToggleInput';

import RoutesPath from '@router/routes';
import { errors } from '@utils';

import { ChooseItemContainer, CustomChooseItemFilter } from '../styles';
import { SwitchContainer } from './styles';

interface GeneralServiceInfoProps {
  initialValues?: IGeneralServiceInfo;
  onFinish: (data: IGeneralServiceInfo) => void;
}

const VALID_ACCESS_KEY_LENGTH = 44;

const GeneralServiceInfo: React.ForwardRefRenderFunction<
  IFormStepRef,
  GeneralServiceInfoProps
> = ({ onFinish, initialValues }, ref) => {
  const { isCompanyUser, userInfo } = useAuth();
  const { listIncomeCategories } = useIncomeCategories();
  const { listPaymentMethods } = usePaymentMethods();
  const { listPaymentTerms } = usePaymentTerms();
  const { listCompanyUsers } = useCompanyUsers();
  const { listClients } = useClients();

  const isSalesUser = isCompanyUser && userInfo?.role === CompanyUserRole.SALES;
  const takerTypes = Object.values(TakerType);
  const emptyClient = { id: 0 } as IClientUser;

  const formikInitialValues: IGeneralServiceInfo = {
    category: { id: 0 } as IIncomeCategory,
    personInCharge: { id: isSalesUser ? userInfo.id : 0 } as ICompanyUser,
    takerType: TakerType.SENDER,
    client: emptyClient,
    clientAddressId: 0,
    estimatedCollectionDate: '',
    estimatedMinDaysForDelivery: '',
    estimatedMaxDaysForDelivery: '',
    paymentMethod: { id: 0 } as IPaymentMethod,
    type: '',
    value: '',
    paymentTerm: { id: 0 } as IPaymentTerm,
    cteAccessKey: '',
    collectionDate: '',
    redeemable: false,
    notifyClient: false,
    ...initialValues,
  };
  const isOwnService = formikInitialValues.type === ServiceType.OWN;

  const formikValidationSchema = yup.object().shape({
    category: yup.object().shape({
      id: yup.number().when('type', {
        is: () => ServiceType.OWN === formikInitialValues.type,
        then: (schema) =>
          schema.notOneOf([0], errors.required).required(errors.required),
      }),
    }),
    personInCharge: yup.object().shape({
      id: yup.number().when({
        is: () => !isSalesUser,
        then: (schema) =>
          schema.notOneOf([0], errors.required).required(errors.required),
      }),
    }),
    takerType: yup
      .string()
      .trim()
      .when('type', {
        is: ServiceType.OWN,
        then: (schema) => schema.required(errors.required),
      }),
    client: yup.object().when('takerType', {
      is: TakerType.ANOTHER,
      then: (schema) =>
        schema.shape({
          id: yup
            .number()
            .notOneOf([0], errors.required)
            .required(errors.required),
        }),
    }),
    clientAddressId: yup.number().when('takerType', {
      is: TakerType.ANOTHER,
      then: (schema) =>
        schema.notOneOf([0], errors.required).required(errors.required),
    }),
    estimatedCollectionDate: yup
      .string()
      .trim()
      .when('type', {
        is: (value: ServiceType) =>
          value === ServiceType.OWN || value === ServiceType.THIRD_PARTY,
        then: (schema) => schema.required(errors.required),
      }),
    estimatedMinDaysForDelivery: yup
      .string()
      .trim()
      .when('type', {
        is: () => ServiceType.OWN === formikInitialValues.type,
        then: (schema) => schema.required(errors.required),
      }),
    estimatedMaxDaysForDelivery: yup
      .string()
      .trim()
      .when('type', {
        is: () => ServiceType.OWN === formikInitialValues.type,
        then: (schema) =>
          schema
            .test(
              'is-max-days-valid',
              'Insira uma quantidade de dias maior ou igual à previsão mínima de dias',
              (estimatedMaxDaysForDeliveryValue, context) => {
                const estimatedMinDaysForDeliveryValue =
                  context.parent.estimatedMinDaysForDelivery;

                if (
                  !estimatedMinDaysForDeliveryValue ||
                  !estimatedMaxDaysForDeliveryValue ||
                  Number(estimatedMaxDaysForDeliveryValue) <
                    Number(estimatedMinDaysForDeliveryValue)
                ) {
                  return false;
                }

                return true;
              }
            )
            .required(errors.required),
      }),
    value: yup
      .string()
      .trim()
      .test(
        'is-the-value-of-the-service-valid',
        errors.currencyBetween(0, MAX_VALUE),
        (value) =>
          isCurrencyFieldValueBetween({
            value,
            minValue: 0,
            maxValue: MAX_VALUE,
            required: true,
          })
      )
      .required(errors.required),
    paymentMethod: yup.object().shape({
      id: yup.number().notOneOf([0], errors.required).required(errors.required),
    }),
    paymentTerm: yup.object().shape({
      id: yup.number().notOneOf([0], errors.required).required(errors.required),
    }),
    cteAccessKey: yup
      .string()
      .trim()
      .when('type', {
        is: ServiceType.THIRD_PARTY,
        then: (schema) =>
          schema.test(
            'is-a-valid-cte-access-key',
            errors.length(VALID_ACCESS_KEY_LENGTH),
            (value) => {
              const accessKeyRegex = value?.replace(regex.onlyNumbers, '');
              const isValidKey =
                !accessKeyRegex ||
                accessKeyRegex.length === VALID_ACCESS_KEY_LENGTH;
              return isValidKey;
            }
          ),
      }),
    collectionDate: yup
      .string()
      .trim()
      .when('type', {
        is: ServiceType.STORAGE,
        then: (schema) => schema.required(errors.required),
      }),
    redeemable: yup.boolean().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
    resetForm: formik.resetForm,
  }));

  const handleSelectClient = (client: IClientUser) => {
    formik.setFieldTouched('client');
    formik.setFieldValue('client', client);
    formik.setFieldValue('clientAddressId', client.default_address?.id || 0);
  };

  const dateInput = {
    [ServiceType.OWN]: (
      <BaseDateInput
        formikKey="estimatedCollectionDate"
        label="Previsão para coleta *"
      />
    ),
    [ServiceType.THIRD_PARTY]: (
      <BaseDateInput
        formikKey="estimatedCollectionDate"
        label="Previsão para coleta *"
      />
    ),
    [ServiceType.STORAGE]: (
      <BaseDateInput formikKey="collectionDate" label="Data de chegada *" />
    ),
  };

  return (
    <FormikProvider value={formik}>
      <FormRow>
        {formik.values.type === ServiceType.OWN && (
          <FormGroup>
            <Label>Categoria do serviço *</Label>
            <ChooseItemContainer>
              <CustomChooseItemFilter
                filterValue={
                  formik.values.category.id !== 0
                    ? formik.values.category.name
                    : 'Selecionar categoria'
                }
                modalTitle="Selecionar categoria"
                fetch={listIncomeCategories}
                queryParams={{ isActive: 'true' }}
                queryKey="incomeCategories"
                inputLabel="Selecionar categoria *"
                selectedItemLabel="Categoria selecionada"
                shouldRenderSelectedItem={formik.values.category.id !== 0}
                onSelect={(category) =>
                  formik.setFieldValue('category', category)
                }
                selectedItem={formik.values.category}
                renderItemText={(category) => category.name}
                refetchOnWindowFocus="always"
                invalidValue={
                  !!formik.touched.category?.id && !!formik.errors.category?.id
                }
              />
              <AddNewEntityButton
                routePath={
                  RoutesPath.private.categories.incomes.createCategory.path
                }
                tooltip="Adicionar nova categoria"
                icon={Additem}
              />
            </ChooseItemContainer>
            <FormError name="category.id" />
          </FormGroup>
        )}
        {!isSalesUser && !!formik.values.personInCharge && (
          <FormGroup>
            <Label>Responsável *</Label>
            <ChooseItemContainer>
              <CustomChooseItemFilter
                filterValue={
                  formik.values.personInCharge.id !== 0
                    ? formik.values.personInCharge.name
                    : 'Selecionar responsável'
                }
                modalTitle="Selecionar responsável"
                fetch={listCompanyUsers}
                queryParams={{
                  isActive: 'true',
                  canBeInChargeOfServices: true,
                }}
                queryKey="companyUsers"
                inputLabel="Selecionar responsável *"
                selectedItemLabel="Responsável selecionado"
                shouldRenderSelectedItem={formik.values.personInCharge.id !== 0}
                onSelect={(personInCharge) =>
                  formik.setFieldValue('personInCharge', personInCharge)
                }
                selectedItem={formik.values.personInCharge}
                renderItemText={(personInCharge) => personInCharge.name}
                refetchOnWindowFocus="always"
                invalidValue={
                  !!getIn(formik.touched, 'personInCharge.id') &&
                  !!getIn(formik.errors, 'personInCharge.id')
                }
              />
              <AddNewEntityButton
                routePath={
                  RoutesPath.private.companyUsers.createCompanyUser.path
                }
                tooltip="Adicionar novo responsável"
                icon={UserAdd}
              />
            </ChooseItemContainer>
            <FormError name="personInCharge.id" />
          </FormGroup>
        )}
      </FormRow>
      <FormRow>
        {isOwnService && (
          <FormGroup>
            <Label htmlFor="takerType">Tomador do serviço *</Label>
            <RadioButtonsContainer>
              {takerTypes.map((type) => (
                <InputGroup key={type}>
                  <Input
                    type="radio"
                    id={`takerType${type}`}
                    name="takerType"
                    checked={formik.values.takerType === type}
                    onChange={() => formik.setFieldValue('takerType', type)}
                    onBlur={formik.handleBlur}
                  />
                  <Label htmlFor={`takerType${type}`}>{type}</Label>
                </InputGroup>
              ))}
              <FormError name="takerType" />
            </RadioButtonsContainer>
          </FormGroup>
        )}
      </FormRow>
      {formik.values.takerType === TakerType.ANOTHER && (
        <FormRow>
          <FormGroup>
            <Label>{isOwnService ? 'Tomador *' : 'Cliente *'}</Label>
            <ChooseItemContainer>
              <CustomChooseItemFilter
                filterValue={
                  formik.values.client && formik.values.client.id !== 0
                    ? formik.values.client.name
                    : isOwnService
                    ? 'Selecionar tomador'
                    : 'Selecionar cliente'
                }
                modalTitle={
                  isOwnService ? 'Selecionar tomador' : 'Selecionar cliente'
                }
                fetch={listClients}
                queryParams={{ isActive: 'true' }}
                queryKey="clients"
                inputLabel={
                  isOwnService ? 'Selecionar tomador *' : 'Selecionar cliente *'
                }
                selectedItemLabel={
                  isOwnService ? 'Tomador selecionado' : 'Cliente selecionado'
                }
                shouldRenderSelectedItem={formik.values.client?.id !== 0}
                onSelect={(client) => handleSelectClient(client)}
                selectedItem={formik.values.client || emptyClient}
                renderItemText={(client) => client?.name}
                refetchOnWindowFocus="always"
                invalidValue={
                  !!formik.touched.client?.id && !!formik.errors.client
                }
              />
              <AddNewEntityButton
                routePath={RoutesPath.private.clients.createClient.path}
                tooltip={
                  isOwnService
                    ? 'Adicionar novo tomador'
                    : 'Adicionar novo cliente'
                }
                icon={UserAdd}
              />
            </ChooseItemContainer>
            <FormError name="client.id" />
          </FormGroup>
          <FormGroup>
            <Label>Endereço de {isOwnService ? 'tomador' : 'cliente'} *</Label>
            <ChooseItemContainer>
              <Select
                id="clientAddressId"
                name="clientAddressId"
                onChange={(event) =>
                  formik.setFieldValue(
                    'clientAddressId',
                    Number(event.target.value)
                  )
                }
                value={formik.values.clientAddressId}
                disabled={formik.values.client?.id === 0}
                invalidValue={
                  !!formik.touched.clientAddressId &&
                  !!formik.errors.clientAddressId
                }
              >
                <option value={0}>Selecionar endereço</option>
                {formik.values.client?.addresses?.map((address) => (
                  <option value={address.id} key={address.id}>
                    {toAddressString(address)}
                  </option>
                ))}
              </Select>
            </ChooseItemContainer>
            <FormError name="clientAddressId" />
          </FormGroup>
        </FormRow>
      )}
      <FormRow>
        <FormGroup>
          <Label htmlFor="value">Valor do serviço *</Label>
          <Input
            type="text"
            id="value"
            name="value"
            placeholder="R$ 0,00"
            value={formik.values.value}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'value',
                currency.brlMask(event.target.value)
              )
            }
            invalidValue={!!formik.touched.value && !!formik.errors.value}
          />
          <FormError name="value" />
        </FormGroup>
        {dateInput[formik.values.type as ServiceType]}
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Método de pagamento *</Label>
          <ChooseItemContainer>
            <CustomChooseItemFilter
              filterValue={
                formik.values.paymentMethod.id !== 0
                  ? formik.values.paymentMethod.name
                  : 'Selecionar método de pagamento'
              }
              modalTitle="Selecionar método de pagamento"
              fetch={listPaymentMethods}
              queryParams={{ isActive: 'true' }}
              queryKey="paymentMethods"
              inputLabel="Selecionar método de pagamento *"
              selectedItemLabel="Método de pagamento selecionado"
              shouldRenderSelectedItem={formik.values.paymentMethod.id !== 0}
              onSelect={(paymentMethod) =>
                formik.setFieldValue('paymentMethod', paymentMethod)
              }
              selectedItem={formik.values.paymentMethod}
              renderItemText={(paymentMethod) => paymentMethod.name}
              refetchOnWindowFocus="always"
              invalidValue={
                !!formik.touched.paymentMethod?.id &&
                !!formik.errors.paymentMethod?.id
              }
            />
            <AddNewEntityButton
              routePath={
                RoutesPath.private.paymentMethods.createPaymentMethod.path
              }
              tooltip="Adicionar novo método de pagamento"
              icon={Additem}
            />
          </ChooseItemContainer>
          <FormError name="paymentMethod.id" />
        </FormGroup>
        <FormGroup>
          <Label>Forma de pagamento *</Label>
          <ChooseItemContainer>
            <CustomChooseItemFilter
              filterValue={
                formik.values.paymentTerm.id !== 0
                  ? formik.values.paymentTerm.description
                  : 'Selecionar forma de pagamento'
              }
              modalTitle="Selecionar forma de pagamento"
              fetch={listPaymentTerms}
              queryParams={{ isActive: 'true' }}
              queryKey="paymentTerms"
              inputLabel="Selecionar forma de pagamento *"
              selectedItemLabel="Forma de pagamento selecionada"
              shouldRenderSelectedItem={formik.values.paymentTerm.id !== 0}
              onSelect={(paymentTerm) =>
                formik.setFieldValue('paymentTerm', paymentTerm)
              }
              selectedItem={formik.values.paymentTerm}
              renderItemText={(paymentTerm) => paymentTerm.description}
              refetchOnWindowFocus="always"
              invalidValue={
                !!formik.touched.paymentTerm?.id &&
                !!formik.errors.paymentTerm?.id
              }
            />
            <AddNewEntityButton
              routePath={
                RoutesPath.private.paymentMethods.createPaymentTerm.path
              }
              tooltip="Adicionar nova forma de pagamento"
              icon={Additem}
            />
          </ChooseItemContainer>
          <FormError name="paymentTerm.id" />
        </FormGroup>
      </FormRow>
      {formikInitialValues.type === ServiceType.OWN && (
        <FormRow>
          <FormGroup>
            <Label htmlFor="estimatedMinDaysForDelivery">
              Previsão de entrega mínima (em dias) *
            </Label>
            <Input
              id="estimatedMinDaysForDelivery"
              name="estimatedMinDaysForDelivery"
              type="text"
              placeholder="0"
              value={formik.values.estimatedMinDaysForDelivery}
              onChange={(event) =>
                formik.setFieldValue(
                  'estimatedMinDaysForDelivery',
                  event.target.value.replace(regex.onlyNumbers, '')
                )
              }
              onBlur={formik.handleBlur}
              invalidValue={
                !!formik.touched.estimatedMinDaysForDelivery &&
                !!formik.errors.estimatedMinDaysForDelivery
              }
            />
            <FormError name="estimatedMinDaysForDelivery" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="estimatedMaxDaysForDelivery">
              Previsão de entrega máxima (em dias) *
            </Label>
            <Input
              id="estimatedMaxDaysForDelivery"
              name="estimatedMaxDaysForDelivery"
              type="text"
              placeholder="0"
              value={formik.values.estimatedMaxDaysForDelivery}
              onChange={(event) =>
                formik.setFieldValue(
                  'estimatedMaxDaysForDelivery',
                  event.target.value.replace(regex.onlyNumbers, '')
                )
              }
              onBlur={formik.handleBlur}
              invalidValue={
                !!formik.touched.estimatedMaxDaysForDelivery &&
                !!formik.errors.estimatedMaxDaysForDelivery
              }
            />
            <FormError name="estimatedMaxDaysForDelivery" />
          </FormGroup>
        </FormRow>
      )}
      {formik.values.type === ServiceType.THIRD_PARTY && (
        <FormRow>
          <FormGroup>
            <Label htmlFor="cteAccessKey">Chave de acesso CT-e</Label>
            <Input
              id="cteAccessKey"
              placeholder="Digite a chave de acesso do CT-e"
              name="cteAccessKey"
              onChange={(event) =>
                formik.setFieldValue(
                  'cteAccessKey',
                  event.target.value.replace(
                    regex.fieldWithoutSpecialCharacters,
                    ''
                  )
                )
              }
              onBlur={formik.handleBlur}
              value={formik.values.cteAccessKey}
            />
            <FormError name="cteAccessKey" />
          </FormGroup>
        </FormRow>
      )}
      <FormRow>
        <FormGroup>
          <SwitchContainer>
            <ToggleInput.Label htmlFor="redeemable">
              Vistoria resgatável
            </ToggleInput.Label>
            <ToggleInput.Switch
              checked={formik.values.redeemable}
              name="redeemable"
              onChange={formik.setFieldValue}
            />
          </SwitchContainer>
          <FormError name="redeemable" />
        </FormGroup>
        <FormGroup>
          <SwitchContainer>
            <ToggleInput.Label htmlFor="notifyClient">
              Notificar cliente por e-mail
            </ToggleInput.Label>
            <ToggleInput.Switch
              checked={formik.values.notifyClient}
              name="notifyClient"
              onChange={formik.setFieldValue}
            />
          </SwitchContainer>
          <FormError name="notifyClient" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(GeneralServiceInfo);
