import React, { useState, useEffect } from 'react';

import { AxiosError } from 'axios';
import { currency } from 'b2utils';
import { B2TableRow } from 'react-b2components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Tooltip } from 'react-tooltip';
import { DepartureStatus } from 'utils/enums';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useDepartures, useQueryParams } from '@hooks';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';
import { IBaseModalProps } from '@components/BaseModal';
import ConfirmationModal from '@components/ConfirmationModal';
import LabelWithLink from '@components/LabelWithLink';
import ModalTable from '@components/ModalTable';
import { TableDataCell } from '@components/Table/styles';

import RoutesPath from '@router/routes';

import ServiceExpenseValue from './ServiceExpenseValue';
import { CustomButton, CustomTableDataCell, ModalContent } from './styles';

type StartDepartureModalProps = Omit<IBaseModalProps, 'title' | 'children'> & {
  departure: IDeparture;
};

const StartDepartureModal: React.FC<StartDepartureModalProps> = ({
  isOpen,
  onClose,
  departure,
}) => {
  const [enableFetch, setEnableFetch] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { queryParams, changePage } =
    useQueryParams<IDepartureServicesQueryParams>({
      page: 1,
      departure: departure.id,
    });

  const {
    listDepartureServices,
    updateDepartureServiceExpenseValue,
    updateDepartureStatus,
  } = useDepartures();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const isWaitingStart = departure.status === DepartureStatus.WAITING_START;

  const {
    data: departureServices,
    refetch,
    isLoading,
  } = useQuery(
    ['departureServices', queryParams],
    () => listDepartureServices(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista de veículos do embarque',
          'error'
        );
      },
      enabled: enableFetch,
    }
  );

  const updateDepartureServiceExpenseValueRequest = useMutation(
    (values: IUpdateDepartureServiceExpenseValue) =>
      updateDepartureServiceExpenseValue(values),
    {
      onSuccess: (data) => {
        refetch();
        addToast(
          `Valor da despesa do serviço ${data.service.number} do cliente ${data.service.client.name} atualizado com sucesso`,
          'success'
        );
      },
      onError: (error: AxiosError) => {
        addToast(
          'Não foi possível alterar o valor da despesa do serviço',
          'error'
        );
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const { mutate: requestStartDeparture, isLoading: isStartDepartureLoading } =
    useMutation(
      () => updateDepartureStatus(departure.id, DepartureStatus.IN_TRAFFIC),
      {
        onSuccess: () => {
          queryClient.invalidateQueries('departures');
          queryClient.invalidateQueries(['departure', departure.id.toString()]);
          refetch();
          setIsConfirmationModalOpen(false);
          onClose();
          addToast('Embarque iniciado com sucesso', 'success');
        },
        onError: (error: AxiosError) => {
          addToast('Não foi possível alterar o status do serviço', 'error');
          throwToastApiErrors(error, addToast);
        },
      }
    );

  useEffect(() => {
    if (isOpen) {
      setEnableFetch(true);
    }
  }, [isOpen]);

  return (
    <CustomBaseModal
      isOpen={isOpen}
      onClose={onClose}
      title={isWaitingStart ? 'Iniciar embarque' : 'Acompanhar serviços'}
    >
      <ModalContent onClick={(event) => event.preventDefault()}>
        <Tooltip id="services-list-tooltip" />
        <ModalTable
          data={departureServices?.results || []}
          isLoading={isLoading}
          headerData={[
            'Nº do serviço',
            'Cliente',
            'Veículo',
            'Origem',
            'Destino',
            'Valor da despesa',
          ]}
          emptyMessage="Nenhum veículo encontrado"
          renderRow={(departureService) => (
            <B2TableRow key={departureService.id}>
              <TableDataCell>
                <LabelWithLink
                  label={departureService.service.number}
                  href={RoutesPath.private.services.detailService.path.replace(
                    ':serviceId',
                    departureService.service.id.toString()
                  )}
                  tooltipMessage="Ir para a página do serviço"
                />
              </TableDataCell>
              <TableDataCell>
                {departureService.service.client.name}
              </TableDataCell>
              <TableDataCell>
                {`${departureService.service.vehicle.model} - ${departureService.service.vehicle.identifier}`}
              </TableDataCell>
              <TableDataCell>
                {`${departureService.service.origin.name}/${departureService.service.origin.state}`}
              </TableDataCell>
              <TableDataCell>
                {departureService.service.destination
                  ? `${departureService.service.destination.name}/${departureService.service.destination.state}`
                  : '-'}
              </TableDataCell>
              <CustomTableDataCell>
                <ServiceExpenseValue
                  initialValues={{
                    id: departureService.id,
                    expenseValue: currency.brlMask(
                      departureService.expense_value?.toString() || ''
                    ),
                  }}
                  updateRequest={updateDepartureServiceExpenseValueRequest}
                />
              </CustomTableDataCell>
            </B2TableRow>
          )}
          hasClick={false}
          paginator
          amountPerPage={20}
          currentPage={queryParams.page}
          total={departureServices?.count}
          changePage={changePage}
        />
        {isWaitingStart && (
          <CustomButton onClick={() => setIsConfirmationModalOpen(true)}>
            Confirmar
          </CustomButton>
        )}
      </ModalContent>
      <ConfirmationModal
        title="Tem certeza que deseja iniciar o embarque?"
        isOpen={isConfirmationModalOpen}
        isLoading={isStartDepartureLoading}
        confirmButtonText="Sim, iniciar"
        onConfirm={requestStartDeparture}
        cancelButtonText="Não, cancelar"
        onClose={() => setIsConfirmationModalOpen(false)}
      />
    </CustomBaseModal>
  );
};

export default StartDepartureModal;
