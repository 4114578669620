import Label from './Label';
import RootToggle from './RootToggle';
import Switch from './Switch';

export interface ToggleInputStyleProps {
  $isChecked: boolean;
}

const ToggleInput = {
  RootToggle: RootToggle,
  Label: Label,
  Switch: Switch,
};

export default ToggleInput;
