import React from 'react';

import { Link } from 'react-router-dom';
import { UserType } from 'utils/enums';

import { useAuth } from '@contexts/Auth';

import RoutesPath from '@router/routes';

import HeaderMenu from './HeaderMenu';
import MobileMenu from './MobileMenu';
import SelectScope from './SelectScope';
import { Container, Logo, RightContainer } from './styles';

const Header: React.FC = () => {
  const { userInfo } = useAuth();

  const isManagementUser = userInfo?.type === UserType.MANAGEMENT;
  const isDriverUser = userInfo?.type === UserType.DRIVER;

  const showDefaultLogo = isManagementUser || isDriverUser;

  return (
    <Container>
      <MobileMenu />
      <Link to={RoutesPath.private.home.path}>
        <Logo
          src={
            showDefaultLogo
              ? '/images/logo.svg'
              : userInfo?.company.logo.image_high_url
          }
          alt="Logo"
        />
      </Link>
      <RightContainer>
        {isManagementUser && <SelectScope />}
        <HeaderMenu />
      </RightContainer>
    </Container>
  );
};

export default Header;
