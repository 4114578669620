import React, { useRef } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useDepartures } from '@hooks';

import BaseForm from '@components/BaseForm';

import RoutesPath from '@router/routes';

import DepartureInfo from '../Form/DepartureInfo';
import DriverInfo from '../Form/DriverInfo';
import ServiceList from '../Form/ServiceList';
import routes from './routes';

const CreateDeparture: React.FC = () => {
  const driverInfoStepFormRef = useRef<IFormStepRef>(null);
  const departureInfoStepFormRef = useRef<IFormStepRef>(null);
  const departureServiceListStepFormRef = useRef<IFormStepRef>(null);

  const { createDeparture } = useDepartures();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const submitCreateDeparture = useMutation(
    (driverValues: IDepartureFormValues) => createDeparture(driverValues),
    {
      onSuccess: () => {
        addToast('Embarque cadastrado com sucesso', 'success');
        navigate(RoutesPath.private.departures.listDepartures.path, {
          replace: true,
        });
      },
      onError: () => {
        addToast('Erro ao cadastrar embarque', 'error');
      },
    }
  );

  const steps: Array<IStep> = [
    {
      title: 'Informações do motorista',
      ref: driverInfoStepFormRef,
      component: DriverInfo,
    },
    {
      title: 'Informações do embarque',
      ref: departureInfoStepFormRef,
      component: DepartureInfo,
    },
    {
      title: 'Veículos do embarque',
      ref: departureServiceListStepFormRef,
      component: ServiceList,
    },
  ];

  return (
    <BaseForm
      steps={steps}
      createRequest={submitCreateDeparture}
      routes={routes}
      title="Cadastrar embarque"
    />
  );
};

export default CreateDeparture;
