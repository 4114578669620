import React from 'react';

import { AxiosError } from 'axios';
import { B2Switch, B2TableRow } from 'react-b2components';
import { useMutation, useQuery } from 'react-query';
import { throwToastApiErrors } from 'utils/helpers';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import {
  useNavigateWithScope,
  usePaymentMethods,
  useQueryParams,
} from '@hooks';

import ListFilters from '@components/ListFilters';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import RoutesPath from '@router/routes';

const ListPaymentMethods: React.FC = () => {
  const { queryParams, onSearch, changePage, changeIsActiveStatus } =
    useQueryParams<IQueryParams>({ page: 1 });

  const { listPaymentMethods, updateIsActivePaymentMethodStatus } =
    usePaymentMethods();
  const { scope } = useScope();

  const { addToast } = useToast();

  const { navigateWithScope } = useNavigateWithScope();

  const {
    data: paymentMethods,
    refetch: refetchPaymentMethods,
    isLoading,
  } = useQuery(
    ['paymentMethods', queryParams, scope],
    () => listPaymentMethods(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista de métodos de pagamento',
          'error'
        );
      },
    }
  );

  const { mutate: requestUpdateIsActivePaymentMethodsStatus } = useMutation(
    (paymentMethod: IPaymentMethod) =>
      updateIsActivePaymentMethodStatus(
        paymentMethod.id,
        !paymentMethod.is_active
      ),
    {
      onSuccess: () => {
        refetchPaymentMethods();
        addToast(
          'Situação do método de pagamento atualizado com sucesso',
          'success'
        );
      },
      onError: (error: AxiosError) => {
        addToast(
          'Não foi possível alterar a situação do método de pagamento',
          'error'
        );
        throwToastApiErrors(error, addToast);
      },
    }
  );

  return (
    <>
      <ListFilters
        onSearch={onSearch}
        changeIsActiveStatus={{ queryParams, changeIsActiveStatus }}
      />
      <Table
        data={paymentMethods?.results || []}
        headerData={['Nome', 'Situação']}
        emptyMessage="Nenhum método de pagamento encontrado"
        isLoading={isLoading}
        renderRow={(paymentMethod) => (
          <B2TableRow
            key={paymentMethod.id}
            onClick={() =>
              navigateWithScope({
                routePath:
                  RoutesPath.private.paymentMethods.updatePaymentMethod.path.replace(
                    ':paymentMethodId',
                    paymentMethod.id.toString()
                  ),
                company: paymentMethod.company,
              })
            }
          >
            <TableDataCell>{paymentMethod.name}</TableDataCell>
            <TableDataCell onClick={(event) => event.stopPropagation()}>
              <B2Switch
                isChecked={paymentMethod.is_active}
                onChange={() =>
                  requestUpdateIsActivePaymentMethodsStatus(paymentMethod)
                }
              />
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={paymentMethods?.count}
        changePage={changePage}
      />
    </>
  );
};

export default ListPaymentMethods;
