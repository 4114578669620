import React from 'react';

import { FormikProvider, useFormik } from 'formik';
import { FinancialType } from 'utils/enums';
import { financialTransactionTypeProps } from 'utils/helpers';
import * as yup from 'yup';

import Input from '@components/Input';
import SectionHeader from '@components/SectionHeader';

import { errors } from '@utils';

import {
  Button,
  ButtonsContainer,
  Container,
  CustomFormError,
  IconGroup,
  InputGroup,
  Label,
  RadioButtonsContainer,
} from './styles';

interface IFinancialTypeFormProps {
  initialValue?: FinancialType;
  selectedFinancialTransactionType?: FinancialType;
  setSelectedFinancialTransactionType: (value: FinancialType) => void;
}

const FinancialTypeForm: React.FC<IFinancialTypeFormProps> = ({
  initialValue,
  selectedFinancialTransactionType,
  setSelectedFinancialTransactionType,
}) => {
  const financialTransactionTypes = Object.values(FinancialType);

  const formikInitialValues: IFinancialTypeForm = {
    type: initialValue || selectedFinancialTransactionType,
  };

  const formikValidationSchema = yup.object().shape({
    type: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: ({ type }) => setSelectedFinancialTransactionType(type!),
  });

  return (
    <FormikProvider value={formik}>
      <Container>
        <SectionHeader title="Selecione um tipo de movimentação" />
        <RadioButtonsContainer>
          {financialTransactionTypes.map((type) => {
            const { icon: Icon } = financialTransactionTypeProps[type];
            return (
              <InputGroup
                key={type}
                type={type}
                checked={formik.values.type === type}
                onClick={() => formik.setFieldValue('type', type)}
              >
                <Input
                  type="radio"
                  checked={formik.values.type === type}
                  onChange={() => setSelectedFinancialTransactionType(type)}
                />
                <IconGroup type={type}>
                  <Icon />
                  <Label>{type}</Label>
                </IconGroup>
              </InputGroup>
            );
          })}
        </RadioButtonsContainer>
        <CustomFormError name="type" />
      </Container>
      <ButtonsContainer>
        <Button type="button" onClick={() => formik.handleSubmit()}>
          Próximo
        </Button>
      </ButtonsContainer>
    </FormikProvider>
  );
};

export default FinancialTypeForm;
