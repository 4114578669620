import { CloseCircle, HambergerMenu } from 'iconsax-react';
import { B2Modal } from 'react-b2components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HamburgerMenu = styled(HambergerMenu)`
  color: ${({ theme }) => theme.colors.main};
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-right: 10px;

  @media (min-width: 769px) {
    display: none;
  }
`;

export const Modal = styled(B2Modal)`
  background-color: ${({ theme }) => theme.colors.main};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  padding: 0;
  z-index: 2147483647;
`;

export const CloseButton = styled(CloseCircle)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 30px;
  color: ${({ theme }) => theme.colors.white.main};
`;

export const MenuItem = styled(Link)`
  color: ${({ theme }) => theme.colors.white.two};
  text-decoration: none;
  margin: 5px 0px;
`;

export const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
