import RoutesPath from '@router/routes';

const getRoutes = (managementUserId: string) => {
  return [
    {
      label: 'Usuários internos',
      path: RoutesPath.private.managementUsers.listManagementUsers.path,
    },
    {
      label: 'Detalhes do usuário',
      path: RoutesPath.private.managementUsers.detailManagementUser.path.replace(
        ':managementUserId',
        managementUserId
      ),
    },
    {
      label: 'Editar usuário',
      path: RoutesPath.private.managementUsers.updateManagementUser.path.replace(
        ':managementUserId',
        managementUserId
      ),
    },
  ];
};

export default getRoutes;
