import React from 'react';

import { useNavigate } from 'react-router-dom';

import RoutesPath from '@router/routes';

import { EditButton } from './styles';

interface EditDriverButton {
  driver: IDriver;
}
const EditDriverButton: React.FC<EditDriverButton> = ({ driver }) => {
  const navigate = useNavigate();

  return (
    <EditButton
      onClick={() =>
        navigate(
          RoutesPath.private.drivers.editDriver.path.replace(
            ':driverId',
            driver.id.toString()
          )
        )
      }
    />
  );
};

export default EditDriverButton;
