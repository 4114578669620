import React, { useState } from 'react';

import { cpf } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { toDateTimeInput } from 'utils/helpers';
import * as yup from 'yup';

import { Button } from '@components/BaseForm/styles';
import ConfirmationModal from '@components/ConfirmationModal';
import FileSinglePicker from '@components/FileSinglePicker';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Loading from '@components/Loading';
import TextArea from '@components/TextArea';

import { errors } from '@utils';

import { ButtonsContainer } from './styles';

interface IDeliveryReceiptFormProps {
  onSubmit: (values: IDeliveryReceiptFormValues) => void;
  isLoading: boolean;
}

const DeliveryReceiptForm: React.FC<IDeliveryReceiptFormProps> = ({
  onSubmit,
  isLoading,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const isCpfFieldValid = (value?: string) => {
    const isFieldEmpty = !value?.length;

    if (isFieldEmpty) {
      return false;
    }

    return cpf.validate(value);
  };

  const formikInitialValues: IDeliveryReceiptFormValues = {
    receiverName: '',
    cpf: '',
    observations: '',
    deliveredAt: '',
    file: undefined,
  };

  const validationSchema = yup.object().shape({
    receiverName: yup.string().trim().required(errors.required),
    cpf: yup
      .string()
      .trim()
      .required(errors.required)
      .test('is-cpf-valid', errors.cpf, isCpfFieldValid),
    file: yup.object().required(errors.required),
    deliveredAt: yup.string().required(errors.required),
    observations: yup.string().trim(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema,
    onSubmit: () => setIsOpenModal((prevState) => !prevState),
  });

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="receiverName">Nome *</Label>
          <Input
            id="receiverName"
            name="receiverName"
            placeholder="Digite o nome do usuário"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.receiverName}
            invalidValue={
              !!formik.errors.receiverName && !!formik.touched.receiverName
            }
          />
          <FormError name="receiverName" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="cpf">CPF *</Label>
          <Input
            type="text"
            id="cpf"
            name="cpf"
            placeholder="Digite aqui"
            value={formik.values.cpf}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue('cpf', cpf.mask(event.target.value))
            }
            invalidValue={!!formik.touched.cpf && !!formik.errors.cpf}
          />
          <FormError name="cpf" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Data de entrega do veículo *</Label>
          <Input
            type="datetime-local"
            name="deliveredAt"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.deliveredAt}
            max={toDateTimeInput(new Date())}
          />
          <FormError name="deliveredAt" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="file">Anexar documento do recebedor *</Label>
          <FileSinglePicker
            file={formik.values.file}
            onDeleteFile={() => formik.setFieldValue('file', undefined)}
            onAddFile={(file) => formik.setFieldValue('file', file)}
          />
          <FormError name="file" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="observations">Observações</Label>
          <TextArea
            id="observations"
            name="observations"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.observations}
            invalidValue={
              !!formik.touched.observations && !!formik.errors.observations
            }
          />
        </FormGroup>
      </FormRow>
      <ButtonsContainer>
        {isLoading ? (
          <Loading />
        ) : (
          <Button type="button" onClick={() => formik.handleSubmit()}>
            Enviar
          </Button>
        )}
      </ButtonsContainer>
      <ConfirmationModal
        title="Tem certeza que deseja enviar o termo de entrega?"
        message="Confira se os dados informados estão corretos. Ao enviar o termo de entrega, você não poderá mais editá-lo."
        onClose={() => setIsOpenModal((prevState) => !prevState)}
        onConfirm={() => {
          onSubmit(formik.values);
          setIsOpenModal(false);
        }}
        isLoading={isLoading}
        isOpen={isOpenModal}
        confirmButtonText="Sim, enviar"
        cancelButtonText="Não, cancelar"
      />
    </FormikProvider>
  );
};

export default DeliveryReceiptForm;
