import React, { useCallback, useState } from 'react';

import { AxiosError } from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { B2Button } from 'react-b2components';
import { useMutation } from 'react-query';
import { useTheme } from 'styled-components';
import { throwBlobError, toDateTimeInput } from 'utils/helpers';
import * as yup from 'yup';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useServices } from '@hooks';

import { CustomFormGroup } from '@components/Base/PrivateBase/styles';
import ClipBoardButton from '@components/ClipBoardButton';
import ConfirmationModal from '@components/ConfirmationModal';
import DocumentPicker from '@components/DocumentPicker';
import FormError from '@components/FormError';
import FormRow from '@components/FormRow';
import Label from '@components/Label';
import Loading from '@components/Loading';
import {
  DocumentDownloadButton,
  DocumentDownloadIcon,
} from '@components/ServiceContractModal/SubmitServiceContract/styles';

import { errors } from '@utils';

import DocumentPreview from './DocumentPreview';
import { Content, CustomInput, Description } from './styles';

interface SubmitDeliveryReceiptProps {
  deliveryReceiptId: string;
  serviceNumber: string;
  onSubmitSuccess: () => void;
}

const SubmitDeliveryReceipt: React.FC<SubmitDeliveryReceiptProps> = ({
  deliveryReceiptId,
  serviceNumber,
  onSubmitSuccess,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { isClientUser } = useAuth();
  const { sendDeliveryReceipt, getDeliveryReceiptTemplate } = useServices();
  const { addToast } = useToast();

  const theme = useTheme();

  const createURLFromBinary = useCallback((data: string) => {
    const blob = new Blob([data], {
      type: 'application/pdf',
    });
    const url = window.URL.createObjectURL(blob);

    return url;
  }, []);

  const downloadDeliveryReceiptTemplate = useCallback(
    (url: string) => {
      const anchor = document.createElement('a');
      anchor.download = `Termo de entrega do veículo ${serviceNumber}`;
      anchor.href = url;
      document.body.appendChild(anchor);
      anchor.click();
    },
    [serviceNumber]
  );

  const downloadPDFFromBlob = useCallback(
    (data: string) => {
      const url = createURLFromBinary(data);
      downloadDeliveryReceiptTemplate(url);
    },
    [createURLFromBinary, downloadDeliveryReceiptTemplate]
  );

  const {
    mutate: requestDeliveryReceiptTemplate,
    isLoading: isDeliveryReceiptTemplateLoading,
  } = useMutation(() => getDeliveryReceiptTemplate(deliveryReceiptId), {
    onSuccess: (data) => {
      addToast('Termo de entrega gerado com sucesso', 'success');
      downloadPDFFromBlob(data);
    },
    onError: (error: AxiosError) => {
      addToast('Não foi possível gerar o termo de entrega', 'error');
      throwBlobError(error, addToast);
    },
  });

  const { mutate: handleSendDeliveryReceipt, isLoading: isSendReceiptLoading } =
    useMutation(
      (values: IDeliveryReceiptFormValues) =>
        sendDeliveryReceipt(deliveryReceiptId, values),
      {
        onSuccess: () => {
          addToast('Termo de entrega enviado com sucesso', 'success');
          onSubmitSuccess();
          setIsConfirmationModalOpen(false);
        },
        onError: () => {
          addToast('Não foi possível enviar o termo de entrega', 'error');
        },
      }
    );

  const formikInitialValues: IDeliveryReceiptFormValues = {
    deliveredAt: '',
    file: undefined,
  };

  const validationSchema = yup.object().shape({
    deliveredAt: yup.string().required(errors.required),
    file: yup.object().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema,
    onSubmit: () => setIsConfirmationModalOpen(true),
  });

  const deliveryReceiptFormPath = `${window.location.origin}/termo-de-entrega/${deliveryReceiptId}`;

  if (isClientUser) {
    return (
      <Content>
        <Description>
          Acesse link abaixo e preencha o formulário do termo de entrega
        </Description>
        <ClipBoardButton
          value={deliveryReceiptFormPath}
          toastMessage="Link copiado com sucesso"
          tooltipMessage="Copiar link"
        />
      </Content>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Content>
        <Description>
          Envie o link do formulário do termo de entrega para o cliente
          preenchê-lo
        </Description>
        <ClipBoardButton
          value={deliveryReceiptFormPath}
          toastMessage="Link copiado com sucesso"
          tooltipMessage="Copiar link"
        />
        <Description>ou anexe um documento comprobatório.</Description>
        <FormRow>
          <CustomFormGroup>
            <Label>Baixar termo de entrega</Label>
            <DocumentDownloadButton
              onClick={() => requestDeliveryReceiptTemplate()}
            >
              {isDeliveryReceiptTemplateLoading ? (
                <Loading color={theme.colors.white.main} />
              ) : (
                <>
                  Termo de entrega <DocumentDownloadIcon variant="Bold" />
                </>
              )}
            </DocumentDownloadButton>
          </CustomFormGroup>
          <CustomFormGroup>
            <Label>Anexar documento *</Label>
            <DocumentPicker
              onAddFile={(file) => formik.setFieldValue('file', file)}
            />
            <FormError name="file" />
          </CustomFormGroup>
        </FormRow>
        <DocumentPreview
          document={formik.values.file}
          onDeleteDocument={() => formik.setFieldValue('file', undefined)}
        />
        <FormRow>
          <CustomFormGroup>
            <Label>Data de entrega do veículo *</Label>
            <CustomInput
              type="datetime-local"
              name="deliveredAt"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.deliveredAt}
              max={toDateTimeInput(new Date())}
              invalidValue={
                !!formik.touched.deliveredAt && !!formik.errors.deliveredAt
              }
            />
            <FormError name="deliveredAt" />
          </CustomFormGroup>
        </FormRow>
        <B2Button
          variant="secondary"
          type="submit"
          onClick={() => formik.handleSubmit()}
        >
          Finalizar
        </B2Button>
      </Content>
      <ConfirmationModal
        title="Tem certeza que deseja enviar o termo de entrega?"
        message="Confira se os dados informados estão corretos. Ao enviar o termo de entrega, você não poderá mais editá-lo."
        onClose={() => setIsConfirmationModalOpen((prevState) => !prevState)}
        onConfirm={() => {
          handleSendDeliveryReceipt({
            deliveredAt: formik.values.deliveredAt,
            file: formik.values.file!,
          });
        }}
        isLoading={isSendReceiptLoading}
        isOpen={isConfirmationModalOpen}
        confirmButtonText="Sim, enviar"
        cancelButtonText="Não, cancelar"
      />
    </FormikProvider>
  );
};

export default SubmitDeliveryReceipt;
