import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import ProfileImagePicker from '@components/ProfileImagePicker';
import SectionHeader from '@components/SectionHeader';

import { errors } from '@utils';

interface IProfileInfoFormProps {
  initialValues: IUpdateProfileFormValues;
  onFinish: (data: IUpdateProfileFormValues) => void;
}

const ProfileInfoForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  IProfileInfoFormProps
> = ({ onFinish, initialValues }, ref) => {
  const formikValidationSchema = yup.object().shape({
    name: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <SectionHeader title="Informações pessoais" isFormHeader />
      <ProfileImagePicker />
      <FormRow>
        <FormGroup>
          <Label htmlFor="name">Nome *</Label>
          <Input
            type="text"
            id="name"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            invalidValue={!!formik.touched.name && !!formik.errors.name}
          />
          <FormError name="name" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor='"email"'>E-mail *</Label>
          <Input
            type="email"
            id="email"
            name="email"
            placeholder="E-mail"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            invalidValue={!!formik.touched.email && !!formik.errors.email}
            disabled
          />
          <FormError name="email" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(ProfileInfoForm);
