import styled, { css } from 'styled-components';
import { commonInputStyle } from 'utils/styles';

interface SelectProps {
  invalidValue?: boolean;
  defaultValueSelected?: boolean;
}

export const StyledSelect = styled.select<SelectProps>`
  ${commonInputStyle};

  background-image: url('/images/arrow-drop-down.svg');
  padding-right: ${({ theme }) => theme.spacing.eleven};
  background-size: ${({ theme }) => theme.spacing.six};
  background-repeat: no-repeat;
  background-position-x: calc(100% - ${({ theme }) => theme.spacing.four});
  background-position-y: 50%;
  text-overflow: ellipsis;
  appearance: none;

  option {
    color: ${({ theme }) => theme.colors.gray.twelve};
    font-size: ${({ theme }) => theme.fontSize.small};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    background-color: ${({ theme }) => theme.colors.white.one};
  }

  ${({ defaultValueSelected }) =>
    defaultValueSelected &&
    css`
      color: ${({ theme }) => theme.colors.gray.four};
    `};
`;
