import { useCallback } from 'react';

import axios from 'axios';
import { useApi, endpoints } from 'contexts/Api';
import { generateClientRequestObject } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';

const useAuthentication = () => {
  const { request } = useApi();
  const { isClientUser } = useAuth();

  const performLogin = useCallback(
    async (loginValues: ILoginFormValues) => {
      const data = {
        ...loginValues,
        scope: loginValues.scope || undefined,
      };

      const response = await request<ILoginResponse>({
        method: 'post',
        url: endpoints.auth.login,
        data,
      });

      return response.data;
    },
    [request]
  );

  const getProfile = useCallback(async () => {
    const response = await request<IUser>({
      method: 'get',
      url: endpoints.auth.profile,
    });

    return response.data;
  }, [request]);

  const resetPassword = useCallback(
    async (resetPasswordValues: IResetPasswordRequest) => {
      const data = {
        ...resetPasswordValues,
        scope: resetPasswordValues.scope || undefined,
      };

      const response = await request<IResetPasswordResponse>({
        method: 'post',
        url: endpoints.auth.resetPassword,
        data,
      });

      return response.data;
    },
    [request]
  );

  const updatePassword = useCallback(
    async (updatePasswordValues: IUpdatePasswordFormValues) => {
      const { password, confirmPassword, token } = updatePasswordValues;

      const requestConfig: IRequestConfig = {
        method: 'post',
        url: endpoints.auth.updatePassword,
        data: {
          password,
          confirm_password: confirmPassword,
        },
      };

      if (token) {
        requestConfig.headers = {
          Authorization: `Bearer ${token}`,
        };
      }

      const response = await request<void>(requestConfig);

      return response.data;
    },
    [request]
  );

  const updateProfile = useCallback(
    async (user: IUpdateProfileFormValues) => {
      const getData: () => IUpdateProfileRequest = () => {
        if (isClientUser) {
          return generateClientRequestObject(user as IClientFormValues);
        }

        return {
          name: user.name,
          avatar: user.avatar?.id ?? null,
        };
      };

      const response = await request<IUser>({
        method: 'patch',
        url: endpoints.auth.profile,
        data: getData(),
      });

      return response.data;
    },
    [isClientUser, request]
  );

  const updateDefaultAddress = useCallback(
    async (addressId: number) => {
      const response = await request<IUser>({
        method: 'patch',
        url: endpoints.auth.profile,
        data: {
          default_address: addressId,
        },
      });

      return response.data;
    },
    [request]
  );

  const updateHomeAddress = useCallback(
    async (addressId: number) => {
      const response = await request<IUser>({
        method: 'patch',
        url: endpoints.auth.profile,
        data: {
          home_address: addressId,
        },
      });

      return response.data;
    },
    [request]
  );

  const updateEmail = useCallback(
    async (data: IUpdateEmail) => {
      const response = await request<IUser>({
        method: 'post',
        url: endpoints.auth.updateEmail,
        data,
      });

      return response.data;
    },
    [request]
  );

  const confirmEmail = useCallback(async (token: string) => {
    const response = await axios.request<void>({
      method: 'post',
      url: endpoints.auth.confirmEmail,
      data: { token },
      baseURL: import.meta.env.VITE_API_BASE_URL,
      headers: {
        Accept: `application/json; version=${import.meta.env.VITE_API_VERSION}`,
      },
    });

    return response.data;
  }, []);

  const deleteAccount = useCallback(
    async (password: string) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.auth.removeAccount,
        data: { password },
      });

      return response.data;
    },
    [request]
  );

  return {
    getProfile,
    performLogin,
    resetPassword,
    updatePassword,
    updateProfile,
    updateDefaultAddress,
    updateHomeAddress,
    updateEmail,
    confirmEmail,
    deleteAccount,
  };
};

export default useAuthentication;
