import React, { useState } from 'react';

import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

import Input from '@components/Input';

import { ButtonContainer, Container } from './styles';

interface PasswordInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  invalidValue?: boolean;
}

const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  return (
    <Container invalidValue={props.invalidValue}>
      <Input {...props} type={isShowingPassword ? 'text' : 'password'} />
      <ButtonContainer invalidValue={props.invalidValue}>
        {isShowingPassword ? (
          <IoMdEyeOff onClick={() => setIsShowingPassword(false)} />
        ) : (
          <IoMdEye onClick={() => setIsShowingPassword(true)} />
        )}
      </ButtonContainer>
    </Container>
  );
};

export default PasswordInput;
