import styled from 'styled-components';

export const AddressesFormsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.four};

  button:last-child {
    align-self: flex-end;
  }
`;
