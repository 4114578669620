import { useNavigate } from 'react-router-dom';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';

const useNavigateWithScope = () => {
  const { isCompanyUser } = useAuth();
  const { scope, selectScope } = useScope();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const navigateWithScope = ({
    routePath,
    company,
    state,
  }: {
    routePath: string;
    company?: ICompany;
    state?: any;
  }) => {
    if (isCompanyUser) {
      navigate(routePath, { state });
      return;
    }

    if (!company) {
      return;
    }

    if (company.id !== scope) {
      selectScope(company);
      addToast(`Empresa ${company.name} selecionada`, 'info');
    }

    navigate(routePath, { state });
  };

  return { navigateWithScope };
};

export default useNavigateWithScope;
