import { DocumentDownload } from 'iconsax-react';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Anchor = styled.a`
  all: unset;
`;

export const Container = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.white.one};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: ${({ theme }) => theme.fontSize.small};
  background-color: ${({ theme }) => theme.colors.main};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.four)};

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const DocumentDownloadIcon = styled(DocumentDownload)`
  width: ${({ theme }) => theme.spacing.six};
  height: ${({ theme }) => theme.spacing.six};
  color: ${({ theme }) => theme.colors.white.one};
`;
