import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { B2Button } from 'react-b2components';
import regex from 'utils/regex';
import * as yup from 'yup';

import BaseAddressForm from '@components/BaseAddressForm';
import SectionHeader from '@components/SectionHeader';

import { errors } from '@utils';

import { AddressesFormsContainer } from './styles';

interface IMultipleAddressesFormProps {
  initialValues?: IAddressesFormik;
  onFinish: (data: IAddressesFormik) => void;
}

const MultipleAddressesForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  IMultipleAddressesFormProps
> = ({ onFinish, initialValues }, ref) => {
  const emptyAddress: IAddressForm = {
    city: {
      id: 0,
      name: '',
      state: '',
    },
    street: '',
    number: '',
    complement: '',
    district: '',
    zipCode: '',
  };

  const formikInitialValues: IAddressesFormik = initialValues?.addresses?.length
    ? initialValues
    : {
        addresses: [emptyAddress],
      };

  const addressValidationSchema = yup.object().shape({
    street: yup.string().trim().required(errors.required),
    district: yup.string().trim().required(errors.required),
    number: yup.string().trim().required(errors.required),
    complement: yup.string().trim(),
    zipCode: yup
      .string()
      .trim()
      .test('is-valid-zipCode', errors.length(8), (value) => {
        const onlyNumbers = value?.replace(regex.onlyNumbers, '');

        return onlyNumbers?.length === 8;
      })
      .required(errors.required),
    city: yup.object().shape({
      id: yup.number().min(1, errors.required).required(errors.required),
      name: yup.string().trim().required(errors.required),
      state: yup.string().trim().required(errors.required),
    }),
  });

  const formikValidationSchema = yup.object().shape({
    addresses: yup.array().of(addressValidationSchema),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  const handleDeleteAddress = (index: number) => {
    if (formik.values.addresses.length === 1) {
      return;
    }

    const newAddresses = formik.values.addresses.filter(
      (_, itemIndex) => itemIndex !== index
    );

    formik.setFieldValue('addresses', newAddresses);
  };

  const handleAddNewAddress = async () => {
    const newAddresses: Array<IAddressForm> = [
      ...formik.values.addresses,
      emptyAddress,
    ];
    formik.setFieldValue('addresses', newAddresses);
  };

  return (
    <FormikProvider value={formik}>
      <AddressesFormsContainer>
        {formik.values.addresses.map((_, index) => (
          <div key={index}>
            <SectionHeader
              title={`Endereço ${index + 1}${
                index === 0 ? ' (sede / residencial)' : ''
              }`}
              deleteButton={{
                text: 'Remover endereço',
                onClick: () => handleDeleteAddress(index),
                disabled: formik.values.addresses?.length === 1,
              }}
              isFormHeader
            />
            <BaseAddressForm index={index} />
          </div>
        ))}
        <B2Button variant="transparent" onClick={handleAddNewAddress}>
          Adicionar novo endereço
        </B2Button>
      </AddressesFormsContainer>
    </FormikProvider>
  );
};

export default forwardRef(MultipleAddressesForm);
