import { Car, Category, People, ClipboardText, Category2 } from 'iconsax-react';
import RoutesPath from 'router/routes';

export const salesSidebarData: Array<ISidebarSection> = [
  {
    label: 'Início',
    sideBarItems: [
      {
        name: 'Dashboard',
        icon: Category2,
        route: RoutesPath.private.dashboard.path,
      },
      {
        name: 'Orçamentos',
        icon: ClipboardText,
        route: RoutesPath.private.quotations.listQuotations.path,
      },
    ],
  },
  {
    label: 'Monitoramento',
    sideBarItems: [
      {
        name: 'Serviços',
        icon: Car,
        route: RoutesPath.private.services.listServices.path,
      },
    ],
  },
  {
    label: 'Gerenciamento',
    sideBarItems: [
      {
        name: 'Clientes',
        icon: People,
        route: RoutesPath.private.clients.listClients.path,
      },
      {
        name: 'Documentos',
        icon: Category,
        route: RoutesPath.private.documents.listServices.path,
      },
    ],
  },
];
