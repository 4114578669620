import React, { useCallback, useMemo, useRef } from 'react';

import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import {
  FileStatus,
  FinancialReportType,
  ReportDetailLevel,
} from 'utils/enums';
import { throwBlobError } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import {
  useExpenseReports,
  useIncomeReports,
  useMovementReports,
} from '@hooks';

import BaseFormModal from '@components/BaseFormModal';

import ExpenseReportForm from './Form/ExpenseReportForm';
import FinancialTypeSelect from './Form/FinancialReportTypeSelect';
import IncomeReportForm from './Form/IncomeReportForm';
import MovementReportForm from './Form/MovementReportForm';
import ExpenseListing from './List/ExpenseListing';
import IncomeListing from './List/IncomeListing';
import MovementListing from './List/MovementListing';

interface FinancialReportFormProps {
  isOpen: boolean;
  onClose: () => void;
  type?: FinancialReportType;
  setType: (type: FinancialReportType) => void;
  setId: (id: string) => void;
  setDetailLevel: (type: ReportDetailLevel) => void;
}

const FinancialReportForm: React.FC<FinancialReportFormProps> = ({
  isOpen,
  onClose,
  type,
  setType,
  setId,
  setDetailLevel,
}) => {
  const typeSelectRef = useRef<IFormStepRef>(null);
  const financialReportFormRef = useRef<IFormStepRef>(null);
  const financialListingRef = useRef<IFormStepRef>(null);

  const { listIncomeReport, listSimplifiedIncomeReport } = useIncomeReports();
  const { listExpenseReport, listSimplifiedExpenseReport } =
    useExpenseReports();
  const { listMovementReport } = useMovementReports();
  const { addToast } = useToast();

  const emptyReportLabel = useMemo(() => {
    const labels = {
      [FinancialReportType.INCOME]: 'Nenhuma receita encontrada',
      [FinancialReportType.EXPENSE]: 'Nenhuma despesa encontrada',
      [FinancialReportType.MOVEMENT]: 'Nenhuma movimentação encontrada',
    };
    return labels[type ?? FinancialReportType.INCOME];
  }, [type]);

  const onSuccess = useCallback(
    (data: IFinancialReport) => {
      if (data.status === FileStatus.FAILED) {
        addToast('Ocorreu um erro ao gerar o relatório');
        return;
      }

      if (data.files_qty === 0) {
        addToast(emptyReportLabel, 'warning');
        return;
      }

      setId(data.id);
    },
    [addToast, emptyReportLabel, setId]
  );

  const onError = useCallback(
    (error: AxiosError) => {
      addToast('Erro ao gerar relatório', 'error');
      throwBlobError(error, addToast);
    },
    [addToast]
  );

  const requestIncomePdfReport = useMutation(
    (data: IIncomeReportFormValues) => {
      setDetailLevel(data.detailLevel);

      if (data.detailLevel === ReportDetailLevel.SIMPLIFIED) {
        return listSimplifiedIncomeReport(data);
      }

      return listIncomeReport(data);
    },
    {
      onSuccess,
      onError,
    }
  );

  const requestExpenseReport = useMutation(
    (data: IExpenseReportFormValues) => {
      setDetailLevel(data.detailLevel);

      if (data.detailLevel === ReportDetailLevel.SIMPLIFIED) {
        return listSimplifiedExpenseReport(data);
      }

      return listExpenseReport(data);
    },
    {
      onSuccess,
      onError,
    }
  );

  const requestMovementReport = useMutation(listMovementReport, {
    onSuccess,
    onError,
  });

  const handleSelectReportType = useCallback(
    ({ reportType }: IFinancialReportTypeFormValues) => {
      if (!reportType) {
        return;
      }

      setType(reportType);
    },
    [setType]
  );

  const submitFormRequest = useMemo(() => {
    const requests = {
      [FinancialReportType.INCOME]: requestIncomePdfReport,
      [FinancialReportType.EXPENSE]: requestExpenseReport,
      [FinancialReportType.MOVEMENT]: requestMovementReport,
    };

    return requests[type ?? FinancialReportType.INCOME];
  }, [
    requestIncomePdfReport,
    requestExpenseReport,
    requestMovementReport,
    type,
  ]);

  const financialReportForm = useMemo(() => {
    const forms = {
      [FinancialReportType.INCOME]: IncomeReportForm,
      [FinancialReportType.EXPENSE]: ExpenseReportForm,
      [FinancialReportType.MOVEMENT]: MovementReportForm,
    };
    return forms[type ?? FinancialReportType.INCOME];
  }, [type]);

  const financialListing = useMemo(() => {
    const listings = {
      [FinancialReportType.INCOME]: IncomeListing,
      [FinancialReportType.EXPENSE]: ExpenseListing,
      [FinancialReportType.MOVEMENT]: MovementListing,
    };
    return listings[type ?? FinancialReportType.INCOME];
  }, [type]);

  return (
    <BaseFormModal
      isOpen={isOpen}
      onClose={onClose}
      steps={[
        {
          title: 'Tipo de relatório movimentação',
          component: FinancialTypeSelect,
          ref: typeSelectRef,
          saveStepData: handleSelectReportType,
        },
        {
          title: 'Aplicar filtros',
          component: financialReportForm,
          ref: financialReportFormRef,
        },
        {
          title: 'Resumo',
          component: financialListing,
          ref: financialListingRef,
        },
      ]}
      lastButtonText="Gerar relatório"
      submitFormRequest={submitFormRequest}
    />
  );
};

export default FinancialReportForm;
