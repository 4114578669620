import styled from 'styled-components';
import { modifyColor } from 'utils/styles';

export const Container = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) =>
    modifyColor({ hex: theme.colors.main, lightness: 0.93 })};
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.gray.ten};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const ClickableText = styled.strong`
  color: ${({ theme }) => theme.colors.gray.ten};
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-left: ${({ theme }) => theme.spacing.one};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
