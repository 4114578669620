import React, { useRef } from 'react';

import { UseMutationResult } from 'react-query';

import BaseForm from '@components/BaseForm';

import IncomeInfoForm from './IncomeInfoForm';

interface IIncomeFormProps {
  onFinish: UseMutationResult<IIncome, unknown, IIncomeFormValues, unknown>;
  onGoBackOnFirstStep?: () => void;
  initialValues?: IIncomeFormValues;
}

const IncomeForm: React.FC<IIncomeFormProps> = ({
  onFinish,
  onGoBackOnFirstStep,
  initialValues,
}) => {
  const incomeInfoFormRef = useRef<IFormStepRef>(null);

  const handleGoBackOnFirstStep = () => {
    onGoBackOnFirstStep?.();
    incomeInfoFormRef.current?.resetForm?.();
  };

  return (
    <BaseForm
      initialValues={initialValues}
      onGoBackOnFirstStep={
        onGoBackOnFirstStep ? handleGoBackOnFirstStep : undefined
      }
      createRequest={onFinish}
      steps={[
        {
          title: 'Informações do recebível',
          ref: incomeInfoFormRef,
          component: IncomeInfoForm,
        },
      ]}
    />
  );
};

export default IncomeForm;
