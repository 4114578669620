import RoutesPath from '@router/routes';

const getRoutes = (bankAccountId: string) => {
  return [
    {
      path: RoutesPath.private.bankAccounts.listBankAccounts.path,
      label: 'Contas bancárias',
    },
    {
      path: RoutesPath.private.bankAccounts.updateBankAccount.path.replace(
        ':bankAccountId',
        bankAccountId
      ),
      label: 'Editar conta bancária',
    },
  ];
};

export default getRoutes;
