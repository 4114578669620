import React from 'react';

import { Trash } from 'iconsax-react';

import { Container } from './styles';

interface DeleteButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
  text,
  onClick,
  disabled = false,
}) => {
  return (
    <Container onClick={() => !disabled && onClick()} disabled={disabled}>
      {text}
      <Trash />
    </Container>
  );
};

export default DeleteButton;
