import React from 'react';

import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { CustomFormGroup } from '@components/Base/PrivateBase/styles';
import FormError from '@components/FormError';
import Label from '@components/Label';
import PasswordInput from '@components/PasswordInput';

import { errors } from '@utils';

import BaseDeleteModal from './BaseDeleteModal';

const DeleteModal: React.FC<IDeleteModalProps> = ({
  onConfirm,
  passwordRequired = false,
  ...props
}) => {
  const formikInitialValues = {
    password: '',
  };

  const formikValidationSchema = yup.object().shape({
    password: yup
      .string()
      .trim()
      .when({
        is: () => !!passwordRequired,
        then: (schema) => schema.required(errors.required),
      }),
  });

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: ({ password }) => onConfirm(password),
  });

  return (
    <BaseDeleteModal {...props} onConfirm={() => formik.handleSubmit()}>
      {passwordRequired && (
        <FormikProvider value={formik}>
          <Form autoComplete="off">
            <CustomFormGroup>
              <Label htmlFor={`password-${props.title}`}>Senha *</Label>
              <PasswordInput
                type="password"
                placeholder="Digite sua senha"
                id={`password-${props.title}`}
                name="password"
                autoComplete="new-password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                invalidValue={
                  !!formik.touched.password && !!formik.errors.password
                }
              />
              <FormError name="password" />
            </CustomFormGroup>
          </Form>
        </FormikProvider>
      )}
    </BaseDeleteModal>
  );
};

export default DeleteModal;
