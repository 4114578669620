import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import TextArea from '@components/TextArea';

import { errors } from '@utils';

import DepartureAddress from './DepartureAddress';
import StatesSelect from './StatesSelect';

interface IDepartureInfoProps {
  initialValues?: IDepartureInfoStepForm;
  onFinish: (data: IDepartureInfoStepForm) => void;
}

const DepartureInfo: React.ForwardRefRenderFunction<
  IFormStepRef,
  IDepartureInfoProps
> = ({ onFinish, initialValues }, ref) => {
  const formikInitialValues: IDepartureInfoStepForm = {
    loadingZipCode: '',
    origin: {
      id: 0,
      name: '',
      state: '',
    },
    destinationZipCode: '',
    destination: {
      id: 0,
      name: '',
      state: '',
    },
    date: '',
    routeStates: [],
    routeObservations: '',
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    loadingZipCode: yup.string().trim().required(errors.required),
    origin: yup.object().shape({
      id: yup.number().required(errors.required),
      name: yup.string().trim().required(errors.required),
      state: yup.string().trim().required(errors.required),
    }),
    destinationZipCode: yup.string().trim().required(errors.required),
    destination: yup.object().shape({
      id: yup.number().required(errors.required),
      name: yup.string().trim().required(errors.required),
      state: yup.string().trim().required(errors.required),
    }),
    date: yup.string().trim().required(errors.required),
    routeObservations: yup.string().trim(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="date">Data de saída *</Label>
          <Input
            type="date"
            id="date"
            name="date"
            value={formik.values.date}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.touched.date && !!formik.errors.date}
          />
          <FormError name="date" />
        </FormGroup>
      </FormRow>
      <DepartureAddress zipCodeKey="loadingZipCode" cityKey="origin" />
      <DepartureAddress zipCodeKey="destinationZipCode" cityKey="destination" />
      <StatesSelect />
      <FormRow>
        <FormGroup>
          <Label htmlFor="routeObservations">Observações</Label>
          <TextArea
            id="routeObservations"
            name="routeObservations"
            placeholder="Digite aqui"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.routeObservations}
            invalidValue={
              !!formik.touched.routeObservations &&
              !!formik.errors.routeObservations
            }
          />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(DepartureInfo);
