import { color } from 'b2utils';
import { B2Card } from 'react-b2components';
import styled, { css } from 'styled-components';
import { addSpacing } from 'utils/styles';

interface CardProps {
  isFormModal: boolean;
}

export const Container = styled(B2Card)<CardProps>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.five};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.five, theme.spacing.eight)};

  ${({ theme, isFormModal }) =>
    isFormModal
      ? css`
          box-shadow: none;
          margin: ${({ theme }) =>
            addSpacing(theme.spacing.six, theme.spacing.six, '0')};
          background-color: ${color.addOpacityOnHexColor(
            theme.colors.main,
            0.1
          )};
        `
      : css`
          margin-bottom: ${({ theme }) => theme.spacing.five};
          background-color: ${({ theme }) => theme.colors.white.main};
        `};
`;

export const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.one};
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Subtitle = styled.span`
  color: ${({ theme }) => theme.colors.gray.ten};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
