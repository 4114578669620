import React, { useCallback } from 'react';

import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { ServiceStatus, ServiceType } from 'utils/enums';
import { formatDate } from 'utils/formats';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useQueryParams, useServices } from '@hooks';

import LabelWithLink from '@components/LabelWithLink';
import ServiceFilters from '@components/ServiceFilters';
import ServiceStatusLabel from '@components/ServiceStatusLabel';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import RoutesPath from '@router/routes';

const ServicesList: React.FC = () => {
  const { queryParams, setQueryParams, onSearch, changePage } =
    useQueryParams<IServicesQueryParams>({ page: 1 });

  const { listServices } = useServices();
  const { scope } = useScope();
  const { addToast } = useToast();

  const { data: services, isLoading } = useQuery(
    ['services', queryParams, scope],
    () => listServices(queryParams),
    {
      onError: () => {
        addToast('Não foi possível carregar a lista de serviços', 'error');
      },
    }
  );

  const getLastKnownLocation = useCallback((service: IService) => {
    if (!service.last_known_location) {
      return '-';
    }

    if (service.status === ServiceStatus.IN_TRANSIT) {
      return 'Em deslocamento';
    }

    return `${service.last_known_location.name}/${service.last_known_location.state}`;
  }, []);

  return (
    <>
      <ServiceFilters
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onSearch={onSearch}
        showStatusFilter
      />
      <Table
        data={services?.results || []}
        isLoading={isLoading}
        headerData={[
          'Nº do serviço',
          'Previsão/data de coleta',
          'Situação',
          'Cliente',
          'Veículo',
          'Origem',
          'Destino',
          'Localização atual',
          'Responsável',
        ]}
        emptyMessage="Nenhum serviço encontrado"
        renderRow={(service) => (
          <B2TableRow key={service.id}>
            <TableDataCell>
              <LabelWithLink
                label={service.number}
                href={RoutesPath.private.services.detailService.path.replace(
                  ':serviceId',
                  service.id.toString()
                )}
                tooltipMessage="Ir para a página do serviço"
              />
            </TableDataCell>
            <TableDataCell>
              {service.type === ServiceType.STORAGE
                ? formatDate(service?.collection_date || '')
                : formatDate(service?.estimated_collection_date || '')}
            </TableDataCell>
            <TableDataCell>
              <ServiceStatusLabel status={service.status} />
            </TableDataCell>
            <TableDataCell>{service.client.name}</TableDataCell>
            <TableDataCell>
              {service.vehicle.model} - {service.vehicle.identifier}
            </TableDataCell>
            <TableDataCell>
              {service.origin.name}/{service.origin.state}
            </TableDataCell>
            <TableDataCell>
              {service.destination
                ? `${service.destination.name}/${service.destination.state}`
                : '-'}
            </TableDataCell>
            <TableDataCell>{getLastKnownLocation(service)}</TableDataCell>
            <TableDataCell>{service.person_in_charge.name}</TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={services?.count}
        changePage={changePage}
        hasClick={false}
      />
    </>
  );
};

export default ServicesList;
