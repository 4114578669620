import React, { forwardRef, useCallback } from 'react';

import { cep, cnpj, currency, phone } from 'b2utils';
import { ToWords } from 'to-words';

import VehiclesTable from './VehiclesTable';
import {
  Container,
  Grid,
  Header,
  HeaderInfoContainer,
  InfoGroup,
  Logo,
  MainTitle,
  Signature,
  Title,
  TitleContainer,
} from './styles';

interface QuotationPdfProps {
  quotation: IQuotation;
}

const QuotationPdf: React.ForwardRefRenderFunction<
  HTMLDivElement,
  QuotationPdfProps
> = ({ quotation }, ref) => {
  const toWords = new ToWords({
    localeCode: 'pt-BR',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
    },
  });

  const getFormattedAddress = useCallback(
    (address: IAddress) =>
      `${address.street}, ${address.number}${
        address.additional_info ? address.additional_info : ''
      } - ${address.district} - ${address.city.name}/${
        address.city.state
      } - CEP: ${cep.mask(address.zip_code)}`,
    []
  );

  return (
    <div hidden>
      <Container ref={ref}>
        <Header>
          <Logo src={quotation.company.logo.image_medium_url} alt="Logo" />
          <HeaderInfoContainer>
            <strong>{quotation.company.name}</strong>
            <strong>CNPJ: {cnpj.mask(quotation.company.cnpj)}</strong>
            <strong>
              END: {getFormattedAddress(quotation.company.address)}
            </strong>
            <strong>
              SITE: {quotation.company.website_url}. FONE:{' '}
              {phone.fromObject({
                areaCode: quotation.company.phone.area_code,
                number: quotation.company.phone.number,
              })}
            </strong>
          </HeaderInfoContainer>
        </Header>
        <MainTitle>
          ORÇAMENTO DE TRANSPORTE VEICULAR - {quotation.number}
        </MainTitle>
        <InfoGroup>
          <span>
            VIMOS, POR ESTE INSTRUMENTO, INFORMAR-LHE, NA FORMA QUE SE SEGUE,
            DADOS PERTINENTES AO ORÇAMENTO PARA TRANSPORTE NO TRECHO ABAIXO
            INFORMADO:
          </span>
        </InfoGroup>
        <div>
          <TitleContainer>
            <Title>INFORMAÇÕES GERAIS</Title>
          </TitleContainer>
          <Grid>
            <div>
              <strong>CLIENTE:</strong>
              <span>{quotation.client_name}</span>
            </div>
            <div>
              <strong>TELEFONE:</strong>
              <span>
                {phone.fromObject({
                  areaCode: quotation.phone.area_code,
                  number: quotation.phone.number,
                })}
              </span>
            </div>
            <div>
              <strong>MÉTODO DE PAGAMENTO:</strong>
              <span>{quotation.payment_method.name}</span>
            </div>
            <div>
              <strong>TIPO DE COLETA (ORIGEM):</strong>
              <span>{quotation.collection_type}</span>
            </div>
            <div>
              <strong>ENDEREÇO DE COLETA:</strong>
              <span>{quotation.collection_address || 'NÃO INFORMADO'}</span>
            </div>
            <div />
            <div>
              <strong>TIPO DE ENTREGA (DESTINO):</strong>
              <span>{quotation.delivery_type}</span>
            </div>
            <div>
              <strong>ENDEREÇO DE ENTREGA:</strong>
              <span>{quotation.delivery_address || 'NÃO INFORMADO'}</span>
            </div>
            <div />
            <div>
              <strong>VALIDADE DO ORÇAMENTO:</strong>
              <span>
                {quotation.expiration} DIA
                {quotation.expiration === 1 ? '' : 'S'}
              </span>
            </div>
          </Grid>
        </div>
        <div>
          <TitleContainer>
            <Title>
              INFORMAÇÕES{' '}
              {quotation.vehicles.length === 1 ? 'DO VEÍCULO' : 'DOS VEÍCULOS'}
            </Title>
          </TitleContainer>
          <VehiclesTable vehicles={quotation.vehicles} />
        </div>
        <div>
          <div>
            <strong>SEGURADORA:</strong>
            <span>{quotation.company.insurer_name || 'NÃO INFORMADA'}</span>
          </div>
          <div>
            <strong>
              LIMITE DE RESPONSABILIDADE CIVIL POR CARGA TRANSPORTADA:
            </strong>
            <span>
              {quotation.company.insurer_value_limit_per_load
                ? `${currency.centsToBrl(
                    quotation.company.insurer_value_limit_per_load
                  )} (${toWords.convert(
                    quotation.company.insurer_value_limit_per_load / 100
                  )} DE REAIS)`
                : 'NÃO INFORMADO'}
            </span>
          </div>
        </div>
        <InfoGroup>
          <span>
            VEÍCULOS TRANSPORTADOS EM CAMINHÃO CEGONHA APROPRIADO, COM
            MONITORAMENTO REALIZADO PELA NOSSA CENTRAL DE ATENDIMENTO.
          </span>
          <span>OBRIGADO PELA PREFERÊNCIA.</span>
        </InfoGroup>
        <span>ATENCIOSAMENTE,</span>
        <strong>
          {quotation.company.address.city.name}/
          {quotation.company.address.city.state}{' '}
          {new Date().toLocaleDateString('pt-BR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}
        </strong>
        <Signature>
          <strong>{quotation.person_in_charge.name}</strong>
        </Signature>
      </Container>
    </div>
  );
};

export default forwardRef(QuotationPdf);
