import React, { useCallback } from 'react';

import { color } from 'b2utils';
import { useInfiniteQuery } from 'react-query';
import { useTheme } from 'styled-components';
import { DepartureLogActionMap, DepartureStatus } from 'utils/enums';
import { departureStatusProps, getNextPageParam } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useDepartures } from '@hooks';

import CommonCard from '@components/CommonCard';
import Loading from '@components/Loading';
import Log from '@components/Log';
import TimeLineCard from '@components/TimeLineCard';

interface LogCardProps {
  departureId: number;
}

const LogCard: React.FC<LogCardProps> = ({ departureId }) => {
  const { listDepartureLogs } = useDepartures();
  const { addToast } = useToast();
  const theme = useTheme();

  const queryParams: IDepartureLogQueryParams = {
    pageSize: 5,
    departure: departureId,
  };

  const fetchLogs = useInfiniteQuery(
    ['departureLogs', queryParams],
    ({ pageParam: page = 1 }) => listDepartureLogs({ page, ...queryParams }),
    {
      onError: () => {
        addToast('Não foi possível carregar os logs do embarque', 'error');
      },
      getNextPageParam,
    }
  );

  const logsList = fetchLogs.data?.pages?.flatMap((page) => page.results) || [];

  const getBackgroundColor = useCallback(
    (status: DepartureStatus | null) =>
      color.addOpacityOnHexColor(
        departureStatusProps[status || DepartureStatus.WAITING_START].color(
          theme
        ),
        0.3
      ),
    [theme]
  );

  return (
    <CommonCard title="Histórico">
      {fetchLogs.isLoading ? (
        <Loading />
      ) : (
        <TimeLineCard
          items={logsList}
          renderItem={(log) => (
            <Log
              log={log}
              logActionMap={DepartureLogActionMap[log.action]}
              backgroundColor={getBackgroundColor(log.new_value)}
            />
          )}
          infiniteQueryProps={{
            hasNextPage: fetchLogs.hasNextPage,
            fetchNextPage: fetchLogs.fetchNextPage,
            isFetchingNextPage: fetchLogs.isFetchingNextPage,
          }}
        />
      )}
    </CommonCard>
  );
};

export default LogCard;
