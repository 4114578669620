import { ArrowDown2 } from 'iconsax-react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { ServiceStatus } from 'utils/enums';
import { serviceStatusProps } from 'utils/helpers';
import { addSpacing } from 'utils/styles';

import Loading from '@components/Loading';

import { ServiceStatusLabel } from '../styles';

interface StatusProps {
  status: ServiceStatus;
}

interface MenuProps {
  $isMenuOpen: boolean;
}

export const Container = styled(ServiceStatusLabel)`
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
  gap: ${({ theme }) => theme.spacing.two};
  cursor: pointer;
`;

export const Text = styled.p<StatusProps>`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding-right: ${({ theme }) => theme.spacing.two};
  border-right: 1.5px solid
    ${({ theme, status }) => serviceStatusProps[status].color(theme)};
`;

export const ArrowDownIcon = styled(ArrowDown2)<MenuProps>`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};
  transition: transform 0.5s;

  ${({ $isMenuOpen }) =>
    $isMenuOpen
      ? css`
          transform: rotate(-180deg);
        `
      : css`
          transform: rotate(0deg);
        `}
`;

export const CustomLoading = styled(Loading)`
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};
`;

export const StatusMenuContainer = styled.div<MenuProps>`
  min-width: 100%;
  padding: 0;
  border-radius: 8px;
  position: absolute;
  top: 40px;
  right: 0;
  background-color: ${({ theme }) => theme.colors.white.main};
  box-shadow: ${({ theme }) => theme.boxShadow};
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease;

  div {
    &:first-child {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    overflow: hidden;
  }

  & > div > div:not(div:first-child) {
    border-top: 1px solid ${({ theme }) => theme.colors.gray.one};
  }

  ${({ $isMenuOpen }) =>
    $isMenuOpen &&
    css`
      grid-template-rows: 1fr;
    `};
`;

export const StatusMenuItem = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.four};
  color: ${({ theme }) => theme.colors.gray.six};
  padding: ${({ theme }) => addSpacing(theme.spacing.four, theme.spacing.six)};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white.two};
  }
`;
