import styled from 'styled-components';

export const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};
  margin-bottom: ${({ theme }) => theme.spacing.four};

  div {
    flex: 1;
    box-shadow: none;
    min-width: 375px !important;
  }
`;
