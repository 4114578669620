import React, { useRef } from 'react';

import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useServices, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';
import Loading from '@components/Loading';

import RoutesPath from '@router/routes';

import LastServiceLocationInfo from '../FormSteps/LastServiceLocationInfo';
import getRoutes from './routes';

const UpdateLastServiceLocation: React.FC = () => {
  const { serviceId } = useParams<{ serviceId: string }>();

  const lastServiceLocationInfoStepFormRef = useRef<IFormStepRef>(null);

  const { getService, updateLastServiceLocation } = useServices();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useVerifyScope({
    routePath: RoutesPath.private.services.listServices.path,
    toastErrorMessage: 'Selecione uma empresa para editar um serviço',
  });

  const { data: service, isLoading } = useQuery(
    ['service', serviceId],
    () => getService(serviceId!),
    {
      onError: () => {
        addToast('Erro ao carregar serviço', 'error');
        navigate(
          RoutesPath.private.services.detailService.path.replace(
            ':serviceId',
            serviceId!
          )
        );
      },
      enabled: !!serviceId,
    }
  );

  const updateLastServiceLocationRequest = useMutation(
    (city: ICity) => updateLastServiceLocation(serviceId!, city),
    {
      onSuccess: () => {
        addToast(
          'Última localização do serviço editada com sucesso',
          'success'
        );
        navigate(
          RoutesPath.private.services.detailService.path.replace(
            ':serviceId',
            serviceId!
          )
        );
      },
      onError: (error: AxiosError) => {
        addToast('Erro ao editar última localização do serviço', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  if (isLoading || !service) {
    return <Loading />;
  }

  return (
    <BaseForm
      steps={[
        {
          title: 'Informações da última localização',
          ref: lastServiceLocationInfoStepFormRef,
          component: LastServiceLocationInfo,
        },
      ]}
      createRequest={updateLastServiceLocationRequest}
      routes={getRoutes(serviceId!)}
      title="Editar localização atual do serviço"
      initialValues={{
        city: service.last_known_location,
      }}
    />
  );
};

export default UpdateLastServiceLocation;
