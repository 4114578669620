import { color } from 'b2utils';
import { Car, DocumentText1, DocumentUpload } from 'iconsax-react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { SendingStatus, ServiceStatus } from 'utils/enums';
import { serviceStatusProps } from 'utils/helpers';
import { addSpacing } from 'utils/styles';

interface CarIconProps {
  $isInspectionFinished: boolean;
}

interface DeliveryReceiptIconProps {
  $deliveryReceiptSent: boolean;
}

interface ServiceStatusLabelProps {
  status: ServiceStatus;
}

const IconStyles = css`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.seven};
  height: ${({ theme }) => theme.spacing.seven};
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.four};

  @media (max-width: 1260px) {
    justify-content: center;
  }

  @media (max-width: 1060px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const HistoryContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.four};

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const ProgressIcon = styled(Car)`
  width: ${({ theme }) => theme.spacing.ten};
  height: ${({ theme }) => theme.spacing.ten};
  padding: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.white.main};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.main};
  flex-shrink: 0;
  outline: ${({ theme }) =>
    `${theme.spacing.one} solid ${color.addOpacityOnHexColor(
      theme.colors.main,
      0.2
    )}`};
`;

export const ProgressTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ProgressText = styled.span`
  color: ${({ theme }) => theme.colors.black.two};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.four};

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.four};

  & > svg,
  & > img {
    ${IconStyles}
  }

  & > img:nth-of-type(2) {
    width: ${({ theme }) => theme.spacing.eight};
    height: ${({ theme }) => theme.spacing.eight};
  }

  @media (max-width: 425px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export const CarIcon = styled(Car)<CarIconProps>`
  color: ${({ theme, $isInspectionFinished }) =>
    $isInspectionFinished
      ? color.addOpacityOnHexColor(theme.colors.success, 0.8)
      : color.addOpacityOnHexColor(theme.colors.gray.six, 0.8)};
`;

export const DeliveryReceiptIcon = styled(
  DocumentUpload
)<DeliveryReceiptIconProps>`
  color: ${({ theme, $deliveryReceiptSent }) =>
    $deliveryReceiptSent ? theme.colors.success : theme.colors.gray.six};
`;

export const DocumentTextIcon = styled(DocumentText1)<{
  status: SendingStatus;
}>`
  ${IconStyles}
  color: ${({ theme, status }) =>
    status === SendingStatus.FINISHED
      ? theme.colors.success
      : theme.colors.gray.six};
`;

export const ServiceStatusLabel = styled.div<ServiceStatusLabelProps>`
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-radius: 12px;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) => addSpacing(theme.spacing.two, theme.spacing.four)};
  color: ${({ theme }) => theme.colors.white.main};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme, status }) => serviceStatusProps[status].color(theme)};
  background-color: ${({ theme, status }) =>
    color.addOpacityOnHexColor(serviceStatusProps[status].color(theme), 0.2)};
`;
