import { color } from 'b2utils';
import { User } from 'iconsax-react';
import styled from 'styled-components';

export const Session = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing.two} 0;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.spacing.four};
  gap: ${({ theme }) => theme.spacing.one};
  flex-wrap: wrap;
  align-items: center;
`;

export const FieldName = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.black.one};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export const Description = styled.span`
  word-break: break-all;
  color: ${({ theme }) => theme.colors.gray.six};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export const UserImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing.four} 0;
`;

export const AnchorContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  max-height: 50%;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const UserImage = styled.img`
  max-width: 80%;
  max-height: 80%;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: ${({ theme }) => theme.spacing.two};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  padding: ${({ theme }) => theme.spacing.two};
  background-color: ${({ theme }) =>
    color.addOpacityOnHexColor(theme.colors.main, 0.4)};

  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`;

export const DefaultIcon = styled(User)`
  width: ${({ theme }) => theme.spacing.ten};
  height: ${({ theme }) => theme.spacing.ten};
  padding: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.white.main};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.main};
`;

export const SwitchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
