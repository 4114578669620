import { useCallback } from 'react';

import { useApi, endpoints } from 'contexts/Api';

import { useScope } from '@contexts/Scope';

const useFiscal = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const getCte = useCallback(
    async (cteId: number) => {
      const response = await request<ICte>({
        method: 'get',
        url: endpoints.fiscal.cte.get.replace(':cteId', cteId.toString()),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const listCtes = useCallback(
    async (params: ListCteParams) => {
      const response = await request<IPaginated<ICte>>({
        method: 'get',
        url: endpoints.fiscal.cte.list,
        params: {
          only_issued: true,
          page: params.page,
          search: params.search || undefined,
          issued_at__gte: params.issuanceGte || undefined,
          issued_at__lte: params.issuanceLte || undefined,
          service__origin__state: params.origin || undefined,
          service__destination__state: params.destination || undefined,
          issuance_status: params.status || undefined,
        },
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const listMDFes = useCallback(
    async (params: ListMDFeParams) => {
      const response = await request<IPaginated<IMdfe>>({
        method: 'get',
        url: endpoints.fiscal.mdfe.list,
        params: {
          only_issued: true,
          page: params.page,
          search: params.search || undefined,
          issued_at__gte: params.issuanceGte || undefined,
          issued_at__lte: params.issuanceLte || undefined,
          departure__origin__state: params.origin || undefined,
          departure__destination__state: params.destination || undefined,
          issuance_status: params.status || undefined,
        },
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const issueCte = useCallback(
    async (cteId: number) => {
      const response = await request<ICte>({
        method: 'post',
        url: endpoints.fiscal.cte.issue.replace(':cteId', cteId.toString()),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const cancelCte = useCallback(
    async (cteId: number, data: ICancelFiscalDocument) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.fiscal.cte.cancel.replace(':cteId', cteId.toString()),
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const rectifyCte = useCallback(
    async (cteId: number) => {
      const response = await request<ICte>({
        method: 'post',
        url: endpoints.fiscal.cte.rectify.replace(':cteId', cteId.toString()),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const getMdfe = useCallback(
    async (mdfeId: number) => {
      const response = await request<IMdfe>({
        method: 'get',
        url: endpoints.fiscal.mdfe.get.replace(':mdfeId', mdfeId.toString()),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const issueMdfe = useCallback(
    async (mdfeId: number, values: IIssueMdfeFormValues) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.fiscal.mdfe.issue.replace(':mdfeId', mdfeId.toString()),
        headers: { scope },
        data: {
          weight: values.weight,
          password: values.password || undefined,
          reason_for_missing_cte_keys:
            values.reasonForMissingCteKeys || undefined,
        },
      });

      return response.data;
    },
    [request, scope]
  );

  const cancelMdfe = useCallback(
    async (mdfeId: number, data: ICancelFiscalDocument) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.fiscal.mdfe.cancel.replace(':mdfeId', mdfeId.toString()),
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const closeMdfe = useCallback(
    async (values: ICoseMdfeFormValues) => {
      const response = await request<IMdfeKey>({
        method: 'post',
        url: endpoints.fiscal.mdfeKeys.close,
        headers: { scope },
        data: {
          key: values.key,
          protocol_number: values.protocolNumber,
          closure_date: values.closureDate,
          destination: values.destination.id,
          password: values.password,
        },
      });

      return response.data;
    },
    [request, scope]
  );

  const getCteEndorsement = useCallback(
    async (endorsementId: number) => {
      const response = await request<ICteEndorsement>({
        method: 'get',
        url: endpoints.fiscal.endorsement.get.replace(
          ':endorsementId',
          endorsementId.toString()
        ),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const retryCteEndorsement = useCallback(
    async (endorsementId: number) => {
      const response = await request<ICteEndorsement>({
        method: 'post',
        url: endpoints.fiscal.endorsement.retry.replace(
          ':endorsementId',
          endorsementId.toString()
        ),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const requestFiscalZipFileDownload = useCallback(
    async (values: IFiscalZipFileFormValues) => {
      const response = await request<IFiscalZipFile>({
        method: 'post',
        url: endpoints.fiscal.zipFiles.requestFileGeneration,
        headers: { scope },
        data: {
          start_at: values.startAt,
          end_at: values.endAt,
          type: values.type,
        },
      });

      return response.data;
    },
    [request, scope]
  );

  const getFiscalZipFile = useCallback(
    async (zipFiscalFileId: string) => {
      const response = await request<IFiscalZipFile>({
        method: 'get',
        url: endpoints.fiscal.zipFiles.get.replace(
          ':FiscalZipFileId',
          zipFiscalFileId.toString()
        ),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  return {
    getCte,
    issueCte,
    cancelCte,
    rectifyCte,
    getMdfe,
    issueMdfe,
    cancelMdfe,
    closeMdfe,
    getCteEndorsement,
    retryCteEndorsement,
    listCtes,
    listMDFes,
    requestFiscalZipFileDownload,
    getFiscalZipFile,
  };
};

export default useFiscal;
