import React from 'react';

import { SendingStatus } from 'utils/enums';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';
import { IBaseModalProps } from '@components/BaseModal';

import ServiceContractDetail from './ServiceContractDetail';
import SubmitServiceContract from './SubmitServiceContract';
import { Container } from './styles';

interface ServiceContractModalProps
  extends Omit<IBaseModalProps, 'title' | 'children'> {
  clientName: string;
  serviceNumber: string;
  contractId: string;
  contractStatus: SendingStatus;
  onSubmitSuccess: () => void;
}

const ServiceContractModal: React.FC<ServiceContractModalProps> = ({
  isOpen,
  onClose,
  clientName,
  serviceNumber,
  contractId,
  contractStatus,
  onSubmitSuccess,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <CustomBaseModal
      isOpen={isOpen}
      onClose={onClose}
      title={`Contrato - ${serviceNumber}`}
    >
      <Container>
        {contractStatus === SendingStatus.PENDING ? (
          <SubmitServiceContract
            contractId={contractId}
            serviceNumber={serviceNumber}
            clientName={clientName}
            onSubmitSuccess={onSubmitSuccess}
          />
        ) : (
          <ServiceContractDetail
            isModalOpen={isOpen}
            contractId={contractId}
            fileName={`CONTRATO - ${clientName} - ${serviceNumber}`}
          />
        )}
      </Container>
    </CustomBaseModal>
  );
};

export default ServiceContractModal;
