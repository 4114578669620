import { useCallback } from 'react';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const useIncomeCategories = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const listIncomeCategories = useCallback(
    async (params: IQueryParams) => {
      const response = await request<IPaginated<IIncomeCategory>>({
        method: 'get',
        url: endpoints.financial.incomes.categories.list,
        headers: { scope },
        params: {
          page: params.page,
          search: params.search || undefined,
          is_active: params.isActive || undefined,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getIncomeCategory = useCallback(
    async (categoryId: string) => {
      const response = await request<IIncomeCategory>({
        method: 'get',
        url: endpoints.financial.incomes.categories.get.replace(
          ':categoryId',
          categoryId
        ),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const createIncomeCategory = useCallback(
    async (data: ICategoryBaseFormValues) => {
      const response = await request<IIncomeCategory>({
        method: 'post',
        url: endpoints.financial.incomes.categories.create,
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const updateIncomeCategory = useCallback(
    async (categoryId: string, data: ICategoryBaseFormValues) => {
      const response = await request<IIncomeCategory>({
        method: 'patch',
        url: endpoints.financial.incomes.categories.update.replace(
          ':categoryId',
          categoryId
        ),
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const updateIsActiveIncomeCategoryStatus = useCallback(
    async (categoryId: number, isActive: boolean) => {
      const response = await request<IIncomeCategory>({
        method: 'patch',
        url: endpoints.financial.incomes.categories.update.replace(
          ':categoryId',
          categoryId.toString()
        ),
        data: { is_active: isActive },
      });

      return response.data;
    },
    [request]
  );

  return {
    listIncomeCategories,
    getIncomeCategory,
    createIncomeCategory,
    updateIncomeCategory,
    updateIsActiveIncomeCategoryStatus,
  };
};

export default useIncomeCategories;
