import { B2Card } from 'react-b2components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.five};
`;

export const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 35% 1fr;
  gap: ${({ theme }) => theme.spacing.three};

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.three};
`;

export const CustomCard = styled(B2Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
