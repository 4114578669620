import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import RoutesPath from '@router/routes';

import pages from './pages';

const PublicRoutes: React.FC = () => {
  return (
    <Routes>
      {pages.map((page) => (
        <Route
          key={page.route.path}
          path={page.route.path}
          element={<page.component />}
        />
      ))}
      <Route
        path="*"
        element={<Navigate to={RoutesPath.auth.root.path} replace />}
      />
    </Routes>
  );
};

export default PublicRoutes;
