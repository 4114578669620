import React from 'react';

import BaseModal from '@components/BaseModal';
import ModalFooterButtons from '@components/ModalFooterButtons';

import { CancelImage, BodyMessage, Body } from './styles';

const BaseDeleteModal: React.FC<IBaseDeleteModalProps> = ({
  title = 'Tem certeza que deseja deletar?',
  message = 'Esta ação é irreversível',
  isOpen,
  onClose,
  confirmText = 'Sim, desejo deletar',
  onConfirm,
  cancelText = 'Não, cancelar',
  isLoading = false,
  children,
}) => {
  return (
    <BaseModal isOpen={isOpen} title={title} onClose={onClose}>
      <Body>
        <CancelImage src="/images/cancel.svg" alt="Cancel image" />
        <BodyMessage>{message}</BodyMessage>
        {children}
      </Body>
      <ModalFooterButtons
        isLoading={isLoading}
        confirmText={confirmText}
        onConfirm={onConfirm}
        cancelText={cancelText}
        onCancel={onClose}
      />
    </BaseModal>
  );
};

export default BaseDeleteModal;
