import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Container = styled.div`
  width: fit-content;
  display: flex;
  gap: ${({ theme }) => theme.spacing.four};
  margin: ${({ theme }) =>
    addSpacing(theme.spacing.two, '0', theme.spacing.two, 'auto')};
`;
