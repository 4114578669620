import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

export const Container = styled.div<{ index: number }>`
  height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  transform: translateX(${({ index }) => index * -100}%);
  padding: ${({ theme }) => theme.spacing.two};
`;

export const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const CustomSkeleton = styled(Skeleton)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
`;
