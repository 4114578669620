import React, { useEffect, useRef } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { usePaymentTerms } from '@hooks';

import BaseForm from '@components/BaseForm';

import RoutesPath from '@router/routes';

import PaymentTermForm from '../Form/PaymentTermForm';
import routes from './routes';

const CreatePaymentTerm: React.FC = () => {
  const paymentTermFormRef = useRef<IFormStepRef>(null);

  const { createPaymentTerm } = usePaymentTerms();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { scope } = useScope();
  const { isManagementUser } = useAuth();

  const createPaymentTermRequest = useMutation(
    (values: IPaymentTermFormValues) => createPaymentTerm(values),
    {
      onSuccess: () => {
        addToast('Forma de pagamento cadastrada com sucesso', 'success');
        navigate(RoutesPath.private.paymentMethods.listPaymentTerms.path, {
          replace: true,
        });
      },
      onError: () => {
        addToast('Erro ao cadastrar forma de pagamento', 'error');
      },
    }
  );

  useEffect(() => {
    if (!scope && isManagementUser) {
      addToast(
        'Selecione uma empresa para cadastrar uma forma de pagamento',
        'error'
      );
      navigate(RoutesPath.private.paymentMethods.listPaymentTerms.path, {
        replace: true,
      });
    }
  }, [scope, navigate, addToast, isManagementUser]);

  const steps: Array<IStep> = [
    {
      title: 'Informações da forma de pagamento',
      ref: paymentTermFormRef,
      component: PaymentTermForm,
    },
  ];

  return (
    <BaseForm
      title="Cadastrar forma de pagamento"
      steps={steps}
      createRequest={createPaymentTermRequest}
      routes={routes}
    />
  );
};

export default CreatePaymentTerm;
