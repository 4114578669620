import React from 'react';

import { currency } from 'b2utils';

import { Container, CustomContactItem } from './styles';

interface PaymentInfoProps {
  value: number;
  paidValue: number;
}

const PaymentInfo: React.FC<PaymentInfoProps> = ({ value, paidValue }) => {
  return (
    <Container>
      <CustomContactItem
        label="Valor total da despesa"
        value={currency.centsToBrl(value)}
      />
      {paidValue !== value && (
        <CustomContactItem
          label="Valor restante"
          value={currency.centsToBrl(value - paidValue)}
        />
      )}
    </Container>
  );
};

export default PaymentInfo;
