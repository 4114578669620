import React, { forwardRef, useImperativeHandle } from 'react';

import { cnpj, cpf, phone } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { StateRegistrationStatus } from 'utils/enums';
import { toDateInput } from 'utils/helpers';
import regex from 'utils/regex';
import * as yup from 'yup';

import {
  InputGroup,
  RadioButtonsContainer,
} from '@components/Base/PrivateBase/styles';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import SectionHeader from '@components/SectionHeader';

import RoutesPath from '@router/routes';
import { errors } from '@utils';

interface IClientInfoProps {
  initialValues?: IClientInfoStepFormValues;
  onFinish: (data: IClientInfoStepFormValues) => void;
}

const ClientInfo: React.ForwardRefRenderFunction<
  IFormStepRef,
  IClientInfoProps
> = ({ onFinish, initialValues }, ref) => {
  const { pathname } = useLocation();

  const isEditingProfileInfo =
    pathname === RoutesPath.private.profile.editProfile.path;

  const isCpfFieldValid = (value?: string) => {
    const isFieldEmpty = !value?.length;

    if (isFieldEmpty) {
      return false;
    }

    if (value.length > 14) {
      return true;
    }

    return cpf.validate(value);
  };

  const isCnpjFieldValid = (value?: string) => {
    const isFieldEmpty = !value?.length;

    if (isFieldEmpty) {
      return false;
    }

    if (value.length <= 14) {
      return true;
    }

    return cnpj.validate(value);
  };

  const formikInitialValues: IClientInfoStepFormValues = {
    avatar: null,
    name: '',
    document: '',
    date_of_birth: '',
    email: '',
    phone: '',
    state_registration_status: StateRegistrationStatus.NON_TAXPAYER,
    state_registration: '',
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    name: yup.string().trim().required(errors.required),
    document: yup
      .string()
      .trim()
      .test('is-cpf-valid', errors.cpf, isCpfFieldValid)
      .test('is-cnpj-valid', errors.cnpj, isCnpjFieldValid),
    date_of_birth: yup.string().trim().required(errors.required),
    email: yup.string().email(errors.email).required(errors.required),
    phone: yup
      .string()
      .trim()
      .required(errors.required)
      .test(
        'is-phone-valid',
        errors.phone,
        (value) => !!value && phone.validate(value)
      ),
    state_registration_status: yup.string().trim().required(errors.required),
    state_registration: yup
      .string()
      .trim()
      .when(['document', 'state_registration_status'], {
        is: (
          document?: string,
          stateRegistrationStatus?: StateRegistrationStatus
        ) =>
          document?.replace(regex.onlyNumbers, '').length === 14 &&
          stateRegistrationStatus === StateRegistrationStatus.TAXPAYER,
        then: (schema) =>
          schema
            .min(2, errors.minLength(2))
            .max(14, errors.maxLength(14))
            .required(errors.required),
      }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  const handleDocumentFieldChange = (value: string) => {
    if (value.replace(regex.onlyNumbers, '').length <= 11) {
      formik.setFieldValue('document', cpf.mask(value));
    } else {
      formik.setFieldValue('document', cnpj.mask(value));
    }
  };

  const stateRegistrationStatuses = Object.values(StateRegistrationStatus);

  const isStateRegistrationStatusInputDisabled =
    formik.values.document.length !== 18;

  const isStateRegistrationInputDisabled =
    isStateRegistrationStatusInputDisabled ||
    formik.values.state_registration_status !==
      StateRegistrationStatus.TAXPAYER;

  return (
    <FormikProvider value={formik}>
      {isEditingProfileInfo && (
        <SectionHeader title="Informações pessoais" isFormHeader />
      )}
      <FormRow>
        <FormGroup>
          <Label htmlFor="name">Nome *</Label>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder="Digite aqui"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.touched.name && !!formik.errors.name}
          />
          <FormError name="name" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="email">E-mail *</Label>
          <Input
            type="text"
            id="email"
            name="email"
            placeholder="Digite aqui"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.touched.email && !!formik.errors.email}
            disabled={isEditingProfileInfo}
          />
          <FormError name="email" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="document">Documento *</Label>
          <Input
            type="text"
            id="document"
            name="document"
            placeholder="Digite aqui o CPF ou o CNPJ"
            value={formik.values.document}
            onBlur={formik.handleBlur}
            onChange={(event) => handleDocumentFieldChange(event.target.value)}
            invalidValue={!!formik.touched.document && !!formik.errors.document}
          />
          <FormError name="document" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="state_registration">Inscrição estadual</Label>
          <RadioButtonsContainer>
            {stateRegistrationStatuses.map((value) => (
              <InputGroup key={value}>
                <Input
                  type="radio"
                  id={`state_registration_status${value}`}
                  name="state_registration_status"
                  checked={formik.values.state_registration_status === value}
                  onChange={() =>
                    formik.setFieldValue('state_registration_status', value)
                  }
                  onBlur={formik.handleBlur}
                  disabled={isStateRegistrationStatusInputDisabled}
                />
                <Label htmlFor={`state_registration_status${value}`}>
                  {value}
                </Label>
              </InputGroup>
            ))}
            <FormError name="state_registration_status" />
          </RadioButtonsContainer>
          <Input
            type="text"
            id="state_registration"
            name="state_registration"
            placeholder="Digite aqui"
            minLength={2}
            maxLength={14}
            value={formik.values.state_registration}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'state_registration',
                event.target.value.replace(regex.onlyNumbers, '')
              )
            }
            disabled={isStateRegistrationInputDisabled}
            invalidValue={
              !!formik.touched.state_registration &&
              !!formik.errors.state_registration
            }
          />
          <FormError name="state_registration" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="phone">Celular *</Label>
          <Input
            type="text"
            id="phone"
            name="phone"
            placeholder="Digite aqui"
            maxLength={15}
            value={formik.values.phone}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue('phone', phone.mask(event.target.value))
            }
            invalidValue={!!formik.touched.phone && !!formik.errors.phone}
          />
          <FormError name="phone" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="date_of_birth">Data de nascimento *</Label>
          <Input
            type="date"
            id="date_of_birth"
            name="date_of_birth"
            value={formik.values.date_of_birth}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            max={toDateInput(new Date())}
            invalidValue={
              !!formik.touched.date_of_birth && !!formik.errors.date_of_birth
            }
          />
          <FormError name="date_of_birth" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(ClientInfo);
