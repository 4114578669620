import React, { useCallback, useRef, useState } from 'react';

import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ServiceStatus } from 'utils/enums';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useOutsideClick, useServices } from '@hooks';

import ConfirmationModal from '@components/ConfirmationModal';
import DeleteModal from '@components/DeleteModal';

import {
  ArrowDownIcon,
  Container,
  CustomLoading,
  StatusMenuContainer,
  StatusMenuItem,
  Text,
} from './styles';

interface ServiceStatusButtonProps {
  status: ServiceStatus;
}

const ServiceStatusButton: React.FC<ServiceStatusButtonProps> = ({
  status,
}) => {
  const { serviceId } = useParams<{ serviceId: string }>();
  const menuRef = useRef<HTMLDivElement | null>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [
    isFinalizeConfirmationModalOpten,
    setIsFinalizeConfirmationModalOpten,
  ] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<ServiceStatus>();

  const { updateServiceStatus, finalizeService } = useServices();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  useOutsideClick(menuRef, () => setIsMenuOpen(false));

  const logQueryParams: IServiceLogQueryParams = {
    pageSize: 5,
    service: Number(serviceId),
  };

  const refetchServiceData = () => {
    queryClient.invalidateQueries(['service', serviceId]);
    queryClient.invalidateQueries(['serviceLogs', logQueryParams]);
  };

  const { mutate: requestUpdateServiceStatus, isLoading } = useMutation(
    (status: ServiceStatus) => updateServiceStatus(serviceId!, status),
    {
      onSuccess: () => {
        refetchServiceData();
        addToast('Status do serviço alterado com sucesso', 'success');
        setIsConfirmationModalOpen(false);
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível alterar o status do serviço', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const { isLoading: isFinalizeServiceLoading, mutate: handleFinalizeService } =
    useMutation((password: string) => finalizeService(serviceId!, password), {
      onSuccess: () => {
        addToast('Serviço finalizado com sucesso', 'success');
        refetchServiceData();
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível finalizado o serviço', 'error');
        throwToastApiErrors(error, addToast);
      },
    });

  const handleStatusMenuItemClick = useCallback(
    (newStatus: ServiceStatus) => {
      if (newStatus !== status) {
        setSelectedStatus(newStatus);
        setIsConfirmationModalOpen(true);
      }
      setIsMenuOpen(false);
    },
    [status]
  );

  return (
    <>
      <Tooltip id="service-status-button-tooltip" />
      <Container
        ref={menuRef}
        status={status}
        onClick={(event) => {
          event.stopPropagation();
          setIsMenuOpen(!isMenuOpen);
        }}
        data-tooltip-id="service-status-button-tooltip"
        data-tooltip-content="Alterar o status do serviço"
      >
        <Text status={status}>{status}</Text>
        {isLoading ? (
          <CustomLoading />
        ) : (
          <ArrowDownIcon $isMenuOpen={isMenuOpen} />
        )}
        <StatusMenuContainer $isMenuOpen={isMenuOpen}>
          <div>
            <StatusMenuItem
              onClick={() =>
                handleStatusMenuItemClick(ServiceStatus.WAITING_COLLECTION)
              }
            >
              {ServiceStatus.WAITING_COLLECTION}
            </StatusMenuItem>
            <StatusMenuItem
              onClick={() =>
                handleStatusMenuItemClick(ServiceStatus.WAITING_DEPARTURE)
              }
            >
              {ServiceStatus.WAITING_DEPARTURE}
            </StatusMenuItem>
            <StatusMenuItem
              onClick={() =>
                handleStatusMenuItemClick(ServiceStatus.WAITING_DELIVERY)
              }
            >
              {ServiceStatus.WAITING_DELIVERY}
            </StatusMenuItem>
            <StatusMenuItem
              onClick={() => setIsFinalizeConfirmationModalOpten(true)}
            >
              {ServiceStatus.FINISHED}
            </StatusMenuItem>
          </div>
        </StatusMenuContainer>
      </Container>
      <ConfirmationModal
        title={`Tem certeza que deseja alterar o status do serviço para ${selectedStatus}?`}
        isOpen={isConfirmationModalOpen}
        isLoading={isLoading}
        confirmButtonText="Sim, alterar"
        onConfirm={() =>
          selectedStatus && requestUpdateServiceStatus(selectedStatus)
        }
        cancelButtonText="Não, cancelar"
        onClose={() => setIsConfirmationModalOpen(false)}
      />
      <DeleteModal
        title="Tem certeza que deseja finalizar o serviço?"
        isOpen={isFinalizeConfirmationModalOpten}
        isLoading={isFinalizeServiceLoading}
        confirmText="Sim, finalizar"
        onConfirm={(password) => password && handleFinalizeService(password)}
        onClose={() => setIsFinalizeConfirmationModalOpten(false)}
        passwordRequired
      />
    </>
  );
};

export default ServiceStatusButton;
