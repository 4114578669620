import { useCallback } from 'react';

import { endpoints, useApi } from '@contexts/Api';

const generateManagementUserObject = (
  managementUser: IManagementUserFormValues
) => {
  return {
    ...managementUser,
    avatar: managementUser.avatar?.id || null,
  };
};

const useManagementUsers = () => {
  const { request } = useApi();

  const listManagementUsers = useCallback(
    async (params: IQueryParams) => {
      const response = await request<IPaginated<IManagementUser>>({
        method: 'get',
        url: endpoints.managementUsers.list,
        params: {
          page: params.page,
          search: params.search || undefined,
          is_active: params.isActive || undefined,
        },
      });
      return response.data;
    },
    [request]
  );

  const getManagementUser = useCallback(
    async (id: string) => {
      const response = await request<IManagementUser>({
        method: 'get',
        url: endpoints.managementUsers.get.replace(':managementUserId', id),
      });
      return response.data;
    },
    [request]
  );

  const createManagementUser = useCallback(
    async (managementUser: IManagementUserFormValues) => {
      const data = generateManagementUserObject(managementUser);
      const response = await request<IManagementUser>({
        method: 'post',
        url: endpoints.managementUsers.create,
        data,
      });
      return response.data;
    },
    [request]
  );

  const updateManagementUser = useCallback(
    async (
      managementUserId: string,
      managementUser: IManagementUserFormValues
    ) => {
      const data = generateManagementUserObject(managementUser);
      const response = await request<IManagementUser>({
        method: 'patch',
        url: endpoints.managementUsers.update.replace(
          ':managementUserId',
          managementUserId
        ),
        data,
      });
      return response.data;
    },
    [request]
  );

  const updateIsActiveManagementStatus = useCallback(
    async (managementUserId: string, isActive: boolean) => {
      const response = await request<IManagementUser>({
        method: 'patch',
        url: endpoints.managementUsers.update.replace(
          ':managementUserId',
          managementUserId
        ),
        data: { is_active: isActive },
      });

      return response.data;
    },
    [request]
  );

  return {
    listManagementUsers,
    getManagementUser,
    createManagementUser,
    updateManagementUser,
    updateIsActiveManagementStatus,
  };
};

export default useManagementUsers;
