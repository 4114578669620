import React from 'react';

import { DepartureStatus, DriverVehicleType } from 'utils/enums';
import { toDateInput } from 'utils/helpers';

import { useDrivers, useProviders } from '@hooks';

import ChooseItemFilter from '@components/ChooseItemFilter';
import DateRangeCalendarFilter from '@components/DateRangeCalendarFilter';
import ExpandableFilter from '@components/ExpandableFilter';
import ListFilters from '@components/ListFilters';
import Select from '@components/Select';

import { helpers } from '@utils';

import { Container } from './styles';

interface DepartureFiltersProps {
  queryParams: IDeparturesQueryParams;
  setQueryParams: React.Dispatch<React.SetStateAction<IDeparturesQueryParams>>;
  onSearch: (value: string) => void;
  showStatusFilter?: boolean;
}

const DepartureFilters: React.FC<DepartureFiltersProps> = ({
  queryParams,
  setQueryParams,
  onSearch,
  showStatusFilter = false,
}) => {
  const { listDrivers } = useDrivers();
  const { listProviders } = useProviders();

  const emptyDriver = {
    id: 0,
    name: 'Todos',
  } as IDriver;

  const emptyProvider = {
    id: 0,
    name: 'Todos',
  } as IProvider;

  const departureStatuses = Object.values(DepartureStatus);
  const driverVehicleTypes = Object.values(DriverVehicleType);

  const handleDateRangeFilterSelected = (startDate?: Date, endDate?: Date) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      date__gte: startDate ? toDateInput(startDate) : undefined,
      date__lte: endDate ? toDateInput(endDate) : undefined,
    }));
  };

  const handleSelectDriver = (driver: IDriver) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      driver,
    }));
  };

  const handleSelectProvider = (provider: IProvider) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      provider,
    }));
  };

  const handleSelectDepartureStatus = (status: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      status: status ? (status as DepartureStatus) : undefined,
    }));
  };

  const handleSelectOriginState = (state: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      originState: state || undefined,
    }));
  };

  const handleSelectDestinationState = (state: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      destinationState: state || undefined,
    }));
  };

  const handleSelectVehicleType = (type: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      vehicleType: type ? (type as DriverVehicleType) : undefined,
    }));
  };

  return (
    <Container>
      <ListFilters
        searchPlaceholder="Buscar por placa do caminhão, motorista e cidades"
        onSearch={onSearch}
      >
        <DateRangeCalendarFilter
          label="Selecionar um intervalo de datas de embarque"
          clearableDateRange
          onDateRangeFilterSelected={handleDateRangeFilterSelected}
        />
      </ListFilters>
      <ExpandableFilter>
        <ChooseItemFilter
          filterValue={
            queryParams?.driver?.id && queryParams?.driver?.id !== 0
              ? queryParams?.driver?.name
              : 'Motorista: todos'
          }
          modalTitle="Filtrar embarques por motorista"
          fetch={listDrivers}
          queryParams={{ isActive: 'true' }}
          queryKey="drivers"
          inputLabel="Selecionar motorista *"
          selectedItemLabel="Motorista selecionado"
          shouldRenderSelectedItem
          onSelect={handleSelectDriver}
          selectedItem={queryParams.driver || emptyDriver}
          additionalItem={emptyDriver}
          renderItemText={(driver) => driver.name}
        />
        <ChooseItemFilter
          filterValue={
            queryParams?.provider?.id && queryParams?.provider?.id !== 0
              ? queryParams?.provider?.name
              : 'Fornecedor: todos'
          }
          modalTitle="Filtrar embarques por fornecedor"
          fetch={listProviders}
          queryParams={{ isActive: 'true' }}
          queryKey="providers"
          inputLabel="Selecionar fornecedor *"
          selectedItemLabel="Fornecedor selecionado"
          shouldRenderSelectedItem
          onSelect={handleSelectProvider}
          selectedItem={queryParams.provider || emptyProvider}
          additionalItem={emptyProvider}
          renderItemText={(provider) => provider.name}
        />
        <Select
          value={queryParams?.originState || ''}
          onChange={(event) => handleSelectOriginState(event.target.value)}
        >
          <option value="">Estado de origem: todos</option>
          {helpers.ufOptions.map(
            ({ label, value }, index) =>
              index !== 0 && (
                <option key={value} value={value}>
                  {label}
                </option>
              )
          )}
        </Select>
        <Select
          value={queryParams?.destinationState || ''}
          onChange={(event) => handleSelectDestinationState(event.target.value)}
        >
          <option value="">Estado de destino: todos</option>
          {helpers.ufOptions.map(
            ({ label, value }, index) =>
              index !== 0 && (
                <option key={value} value={value}>
                  {label}
                </option>
              )
          )}
        </Select>
        <Select
          value={queryParams?.vehicleType || ''}
          onChange={(event) => handleSelectVehicleType(event.target.value)}
        >
          <option value="">Tipo do caminhão: todos</option>
          {driverVehicleTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Select>
        {showStatusFilter && (
          <Select
            value={queryParams?.status || ''}
            onChange={(event) =>
              handleSelectDepartureStatus(event.target.value as DepartureStatus)
            }
          >
            <option value="">Situação do embarque: todas</option>
            {departureStatuses.map((status) => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </Select>
        )}
      </ExpandableFilter>
    </Container>
  );
};

export default DepartureFilters;
