import styled, { css } from 'styled-components';

import ChooseItemFilter, {
  ChooseItemFilterProps,
} from '@components/ChooseItemFilter';

interface IconContainerProps {
  disabled?: boolean;
}

export const ChooseItemContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.one};

  select {
    height: 100%;
  }
`;

export const CustomChooseItemFilter = styled(ChooseItemFilter)`
  background-color: ${({ theme }) => theme.colors.white.two};
` as <ItemType extends GenericItem, FetchQueryParams extends IQueryParams>(
  props: ChooseItemFilterProps<ItemType, FetchQueryParams>
) => React.ReactElement;

export const IconContainer = styled.a<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 3.6rem;
  width: max-content;
  border-radius: ${({ theme }) => theme.spacing.three};
  border: 1px solid ${({ theme }) => theme.colors.white.three};
  background-color: ${({ theme }) => theme.colors.white.two};
  transition: all 0.25s ease-in-out;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    color: ${({ theme }) => theme.colors.gray.six};
    width: ${({ theme }) => theme.spacing.five};
    height: ${({ theme }) => theme.spacing.five};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      &:hover {
        cursor: not-allowed;
      }
    `};
`;
