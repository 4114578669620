import React from 'react';

import { currency } from 'b2utils';
import { getIn, useFormikContext } from 'formik';
import { CityType } from 'utils/enums';
import regex from 'utils/regex';

import BaseCityForm from '@components/BaseCityForm';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';

interface VehicleQuotationProps {
  index: number;
}

const VehicleQuotation: React.FC<VehicleQuotationProps> = ({ index }) => {
  const formik = useFormikContext<IVehicleQuotationsFormValues>();

  return (
    <>
      <FormRow>
        <FormGroup>
          <Label htmlFor={`vehicles[${index}].model`}>Veículo *</Label>
          <Input
            type="text"
            id={`vehicles[${index}].model`}
            name={`vehicles[${index}].model`}
            placeholder="Digite aqui"
            value={formik.values.vehicles[index].model}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={
              !!getIn(formik.touched.vehicles?.[index], 'model') &&
              !!getIn(formik.errors.vehicles?.[index], 'model')
            }
          />
          <FormError name={`vehicles[${index}].model`} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor={`vehicles[${index}].insuranceValue`}>
            Valor para seguro *
          </Label>
          <Input
            type="text"
            id={`vehicles[${index}].insuranceValue`}
            name={`vehicles[${index}].insuranceValue`}
            placeholder="R$ 0,00"
            value={formik.values.vehicles[index].insuranceValue}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                `vehicles[${index}].insuranceValue`,
                currency.brlMask(event.target.value)
              )
            }
            invalidValue={
              !!getIn(formik.touched.vehicles?.[index], 'insuranceValue') &&
              !!getIn(formik.errors.vehicles?.[index], 'insuranceValue')
            }
          />
          <FormError name={`vehicles[${index}].insuranceValue`} />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor={`vehicles[${index}].freightValue`}>
            Valor do frete *
          </Label>
          <Input
            type="text"
            id={`vehicles[${index}].freightValue`}
            name={`vehicles[${index}].freightValue`}
            placeholder="R$ 0,00"
            value={formik.values.vehicles[index].freightValue}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                `vehicles[${index}].freightValue`,
                currency.brlMask(event.target.value)
              )
            }
            invalidValue={
              !!getIn(formik.touched.vehicles?.[index], 'freightValue') &&
              !!getIn(formik.errors.vehicles?.[index], 'freightValue')
            }
          />
          <FormError name={`vehicles[${index}].freightValue`} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor={`vehicles[${index}].estimatedDeliveryDays`}>
            Prazo de entrega (dias) *
          </Label>
          <Input
            type="text"
            id={`vehicles[${index}].estimatedDeliveryDays`}
            name={`vehicles[${index}].estimatedDeliveryDays`}
            placeholder="Digite aqui"
            value={formik.values.vehicles[index].estimatedDeliveryDays}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                `vehicles[${index}].estimatedDeliveryDays`,
                event.target.value.replace(regex.onlyNumbers, '')
              )
            }
            invalidValue={
              !!getIn(
                formik.touched.vehicles?.[index],
                'estimatedDeliveryDays'
              ) &&
              !!getIn(formik.errors.vehicles?.[index], 'estimatedDeliveryDays')
            }
          />
          <FormError name={`vehicles[${index}].estimatedDeliveryDays`} />
        </FormGroup>
      </FormRow>
      <BaseCityForm
        field={`vehicles[${index}].origin`}
        cityType={CityType.ORIGIN}
      />
      <BaseCityForm
        field={`vehicles[${index}].destination`}
        cityType={CityType.DESTINATION}
      />
    </>
  );
};

export default VehicleQuotation;
