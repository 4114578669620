import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { MovementType, MovementTypeMap } from 'utils/enums';

import { useBankAccounts, usePaymentMethods } from '@hooks';

import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';

import { CustomChooseItemFilter } from '@pages/Services/FormSteps/styles';

interface MovementReportFormProps {
  initialValues?: IMovementReportFormValues;
  onFinish: (data: IMovementReportFormValues) => void;
}

const MovementReportForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  MovementReportFormProps
> = ({ initialValues, onFinish }, ref) => {
  const { listBankAccounts } = useBankAccounts();
  const { listPaymentMethods } = usePaymentMethods();

  const movementTypes = Object.values(MovementType);

  const emptyBankAccount = {
    id: 0,
    name: 'Todas',
  } as IBankAccount;

  const emptyPaymentMethod = {
    id: 0,
    name: 'Todos',
  } as IPaymentMethod;

  const formikDefaultInitialValues: IMovementReportFormValues = {
    accrualDateGte: '',
    accrualDateLte: '',
    type: null,
    bankAccount: emptyBankAccount,
    originBankAccount: emptyBankAccount,
    destinationBankAccount: emptyBankAccount,
    paymentMethod: emptyPaymentMethod,
  };

  const formikInitialValues: IMovementReportFormValues = {
    ...formikDefaultInitialValues,
    ...initialValues,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
    resetForm: () => {
      formik.setValues({
        ...initialValues,
        ...formikDefaultInitialValues,
      });
    },
  }));

  const handleSelectMovementType = useCallback(
    (movement: string) => {
      formik.setFieldTouched('type');
      formik.setFieldValue('type', movement || null);
    },
    [formik]
  );

  const handleSelectBankAccountField = useCallback(
    (field: string, bankAccount: IBankAccount) => {
      formik.setFieldTouched(field);
      formik.setFieldValue(field, bankAccount);
    },
    [formik]
  );

  const handleSelectPaymentMethod = useCallback(
    (paymentMethod: IPaymentMethod) => {
      formik.setFieldTouched('paymentMethod');
      formik.setFieldValue('paymentMethod', paymentMethod);
    },
    [formik]
  );

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="accrualDateGte">Data inicial</Label>
          <Input
            type="date"
            id="accrualDateGte"
            name="accrualDateGte"
            value={formik.values.accrualDateGte}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={
              !!formik.touched.accrualDateGte && !!formik.errors.accrualDateGte
            }
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="accrualDateLte">Data final</Label>
          <Input
            type="date"
            id="accrualDateLte"
            name="accrualDateLte"
            value={formik.values.accrualDateLte}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={
              !!formik.touched.accrualDateLte && !!formik.errors.accrualDateLte
            }
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="type">Tipo de movimentação</Label>
          <Select
            id="type"
            name="type"
            value={formik.values.type || ''}
            onChange={(event) => handleSelectMovementType(event.target.value)}
          >
            <option value="">Todas</option>
            {movementTypes.map((type) => (
              <option key={type} value={type}>
                {MovementTypeMap[type]}
              </option>
            ))}
          </Select>
        </FormGroup>
        <FormGroup>
          <Label>Conta bancária</Label>
          <CustomChooseItemFilter
            filterValue={formik.values.bankAccount?.name || ''}
            modalTitle="Selecionar conta bancária"
            fetch={listBankAccounts}
            queryParams={{ isActive: 'true' }}
            queryKey="bankAccounts"
            inputLabel="Selecionar conta bancária"
            selectedItemLabel="Conta bancária selecionado"
            shouldRenderSelectedItem
            onSelect={(bankAccount) =>
              handleSelectBankAccountField('bankAccount', bankAccount)
            }
            selectedItem={formik.values.bankAccount}
            renderItemText={(bankAccount) => bankAccount.name}
            additionalItem={emptyBankAccount}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Conta bancária de origem</Label>
          <CustomChooseItemFilter
            filterValue={formik.values.originBankAccount?.name || ''}
            modalTitle="Selecionar conta bancária de origem"
            fetch={listBankAccounts}
            queryParams={{ isActive: 'true' }}
            queryKey="originBankAccounts"
            inputLabel="Selecionar conta bancária de origem"
            selectedItemLabel="Conta bancária de origem selecionado"
            shouldRenderSelectedItem
            onSelect={(originBankAccount) =>
              handleSelectBankAccountField(
                'originBankAccount',
                originBankAccount
              )
            }
            selectedItem={formik.values.originBankAccount}
            renderItemText={(originBankAccount) => originBankAccount.name}
            additionalItem={emptyBankAccount}
          />
        </FormGroup>
        <FormGroup>
          <Label>Conta bancária de destino</Label>
          <CustomChooseItemFilter
            filterValue={formik.values.destinationBankAccount?.name || ''}
            modalTitle="Selecionar conta bancária de destino"
            fetch={listBankAccounts}
            queryParams={{ isActive: 'true' }}
            queryKey="destinationBankAccounts"
            inputLabel="Selecionar conta bancária de destino"
            selectedItemLabel="Conta bancária de destino selecionado"
            shouldRenderSelectedItem
            onSelect={(destinationBankAccount) =>
              handleSelectBankAccountField(
                'destinationBankAccount',
                destinationBankAccount
              )
            }
            selectedItem={formik.values.destinationBankAccount}
            renderItemText={(destinationBankAccount) =>
              destinationBankAccount.name
            }
            additionalItem={emptyBankAccount}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Método de pagamento</Label>
          <CustomChooseItemFilter
            filterValue={formik.values.paymentMethod?.name || ''}
            modalTitle="Selecionar método de pagamento"
            fetch={listPaymentMethods}
            queryParams={{ isActive: 'true' }}
            queryKey="paymentMethods"
            inputLabel="Selecionar método de pagamento"
            selectedItemLabel="Método de pagamento selecionado"
            shouldRenderSelectedItem
            onSelect={(paymentMethod) =>
              handleSelectPaymentMethod(paymentMethod)
            }
            selectedItem={formik.values.paymentMethod}
            renderItemText={(paymentMethod) => paymentMethod.name}
            additionalItem={emptyPaymentMethod}
          />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(MovementReportForm);
