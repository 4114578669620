import { color } from 'b2utils';
import { DocumentCopy } from 'iconsax-react';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Input = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white.three};
  border-radius: ${({ theme }) => theme.spacing.three};
  padding-left: ${({ theme }) => theme.spacing.four};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const InputText = styled.pre`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.info};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90%;
`;

export const IconWrapper = styled.div`
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: ${({ theme }) => addSpacing(theme.spacing.two, theme.spacing.three)};
  background-color: ${({ theme }) =>
    color.addOpacityOnHexColor(theme.colors.main, 0.4)};
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

export const Icon = styled(DocumentCopy)`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.six};
  height: ${({ theme }) => theme.spacing.six};
  color: ${({ theme }) => theme.colors.main};
`;
