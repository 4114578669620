import React, { useRef } from 'react';

import { phone } from 'b2utils';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDocument } from 'utils/formats';

import { useToast } from '@contexts/Toast';
import { useProviders, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';
import Loading from '@components/Loading';

import RoutesPath from '@router/routes';

import ProviderInfo from '../Form/ProviderInfo';
import getRoutes from './routes';

const UpdateProvider: React.FC = () => {
  const { providerId } = useParams<{ providerId: string }>();

  const providerInfoStepRef = useRef<IFormStepRef>(null);

  const { getProvider, updateProvider } = useProviders();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useVerifyScope({
    routePath: RoutesPath.private.providers.listProviders.path,
    toastErrorMessage: 'Selecione uma empresa para editar um fornecedor',
  });

  const { data: provider, isLoading } = useQuery(
    ['provider', providerId],
    () => getProvider(providerId!),
    {
      onError: () => {
        addToast('Falha ao buscar os dados do fornecedor', 'error');
      },
    }
  );

  const updateProviderRequest = useMutation(
    (provider: IProviderInfoFormValues) =>
      updateProvider(providerId!, provider),
    {
      onSuccess: () => {
        addToast('Dados do fornecedor atualizados com sucesso', 'success');
        navigate(
          RoutesPath.private.providers.detailProvider.path.replace(
            ':providerId',
            providerId!
          )
        );
      },
      onError: () => {
        addToast('Erro ao tentar editar informações do fornecedor', 'error');
      },
    }
  );

  const providerInfoInitialValues: IProviderInfoFormValues = {
    name: provider?.name || '',
    document: provider?.document ? formatDocument(provider?.document) : '',
    phone: provider?.phone
      ? phone.fromObject({
          areaCode: provider.phone.area_code,
          number: provider.phone.number,
        })
      : '',
    email: provider?.email || '',
    description: provider?.description || '',
    type: provider?.type,
  };

  const steps: Array<IStep> = [
    {
      title: 'Informações do fornecedor',
      ref: providerInfoStepRef,
      component: ProviderInfo,
    },
  ];

  if (isLoading || !provider) {
    return <Loading />;
  }

  return (
    <BaseForm
      steps={steps}
      createRequest={updateProviderRequest}
      routes={getRoutes(providerId!)}
      title="Editar o fornecedor"
      initialValues={providerInfoInitialValues}
    />
  );
};

export default UpdateProvider;
