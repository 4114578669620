import React from 'react';

import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { Tooltip } from 'react-tooltip';

import { useToast } from '@contexts/Toast';
import { useQueryParams, useServices } from '@hooks';

import CteStatusLabel from '@components/CteStatusLabel';
import Label from '@components/Label';
import LabelWithLink from '@components/LabelWithLink';
import ModalTable from '@components/ModalTable';
import { TableDataCell } from '@components/Table/styles';

import RoutesPath from '@router/routes';

interface ServiceListProps {
  departureId: number;
}

const ServiceList: React.FC<ServiceListProps> = ({ departureId }) => {
  const { queryParams, changePage } = useQueryParams<IServicesQueryParams>({
    page: 1,
    departure: departureId.toString(),
  });

  const { listServices } = useServices();
  const { addToast } = useToast();

  const { data: services, isLoading } = useQuery(
    ['services', queryParams],
    () => listServices(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista de serviços do embarque',
          'error'
        );
      },
    }
  );

  return (
    <div>
      <Label>Lista de serviços do embarque</Label>
      <Tooltip id="services-list-tooltip" />
      <ModalTable
        data={services?.results || []}
        isLoading={isLoading}
        headerData={[
          'Nº do serviço',
          'Cliente',
          'Veículo',
          'Origem',
          'Destino',
          'Tipo',
          'CT-e',
        ]}
        emptyMessage="Nenhum veículo encontrado"
        renderRow={(service) => (
          <B2TableRow key={service.id}>
            <TableDataCell>
              <LabelWithLink
                label={service.number}
                href={RoutesPath.private.services.detailService.path.replace(
                  ':serviceId',
                  service.id.toString()
                )}
                tooltipMessage="Ir para a página do serviço"
              />
            </TableDataCell>
            <TableDataCell>{service.client.name}</TableDataCell>
            <TableDataCell>
              {`${service.vehicle.model} - ${service.vehicle.identifier}`}
            </TableDataCell>
            <TableDataCell>
              {`${service.origin.name}/${service.origin.state}`}
            </TableDataCell>
            <TableDataCell>
              {service.destination
                ? `${service.destination.name}/${service.destination.state}`
                : '-'}
            </TableDataCell>
            <TableDataCell>{service.type}</TableDataCell>
            <TableDataCell>
              <CteStatusLabel
                status={service.cte?.status}
                accessKey={service.cte_access_key}
              />
            </TableDataCell>
          </B2TableRow>
        )}
        hasClick={false}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={services?.count}
        changePage={changePage}
      />
    </div>
  );
};

export default ServiceList;
