import { B2Button } from 'react-b2components';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.five, theme.spacing.six, theme.spacing.eight)};
  border-top: 1px solid ${({ theme }) => theme.colors.white.three};
  @media (max-width: 490px) {
    flex-direction: column;
  }
`;

export const CustomButton = styled(B2Button)`
  flex: 1;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;
