import React, { useRef } from 'react';

import { useMutation } from 'react-query';

import { useToast } from '@contexts/Toast';
import { useAuthentication } from '@hooks';

import BaseForm from '@components/BaseForm';

import UpdatePasswordForm from './UpdatePasswordForm';

const UpdatePassword: React.FC = () => {
  const updatePasswordFormRef = useRef<IFormStepRef>(null);

  const { updatePassword } = useAuthentication();
  const { addToast } = useToast();

  const updatePasswordRequest = useMutation(
    (values: IUpdatePasswordFormValues) => updatePassword(values),
    {
      onSuccess: () => {
        updatePasswordFormRef.current?.resetForm?.();
        addToast('Senha atualizada com sucesso', 'success');
      },
      onError: () => {
        addToast('Falha ao tentar atualizar senha', 'error');
      },
    }
  );

  const steps: Array<IStep> = [
    {
      title: 'Atualizar senha',
      ref: updatePasswordFormRef,
      component: UpdatePasswordForm,
    },
  ];

  return <BaseForm steps={steps} createRequest={updatePasswordRequest} />;
};

export default UpdatePassword;
