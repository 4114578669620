import React, { forwardRef, useImperativeHandle } from 'react';

import { Form, FormikProvider, useFormik } from 'formik';
import { Tooltip } from 'react-tooltip';
import { TaxRegime, TaxRegimeCode, TaxRegimeCodeMap } from 'utils/enums';
import { validateTwoDecimalPlaces } from 'utils/helpers';
import * as yup from 'yup';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';

import FileSinglePicker from '@components/FileSinglePicker';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import PasswordInput from '@components/PasswordInput';
import Select from '@components/Select';
import ToggleInput from '@components/ToggleInput';

import { errors } from '@utils';

import Rates from './Rates';

interface ISimplesNationalRateTest {
  context: yup.TestContext<yup.AnyObject>;
  validate: () => boolean;
}

interface ITransportFormProps {
  initialValues?: ITransporterFormInfo;
  initialDigitalCertificateId?: number;
  onFinish: (data: ITransporterFormInfo) => void;
}

const TransportForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  ITransportFormProps
> = ({ initialValues, initialDigitalCertificateId, onFinish }, ref) => {
  const { hasAdminPermission, isManagementUser } = useAuth();
  const { addToast } = useToast();

  const taxRegimes = Object.values(TaxRegime);
  const taxRegimeCodes = Object.values(TaxRegimeCode);

  const formikInitialValues: ITransporterFormInfo = {
    cteEmissionIsEnabled: false,
    mdfeEmissionIsEnabled: false,
    digitalCertificate: null,
    digitalCertificatePassword: '',
    cteSeries: '',
    initialCteNumber: '',
    lastCteNumber: '',
    mdfeSeries: '',
    initialMdfeNumber: '',
    lastMdfeNumber: '',
    taxRegime: undefined,
    taxRegimeCode: undefined,
    rates: [''],
    ...initialValues,
  };

  const validateRateIfIsSimpleNational = ({
    context,
    validate,
  }: ISimplesNationalRateTest) => {
    const valuesInContext: ITransporterFormInfo = context.from![0].value;

    if (valuesInContext.taxRegime === TaxRegime.SIMPLE_NATIONAL) {
      return true;
    }

    return validate();
  };

  const validationSchema = yup.object().shape({
    cteEmissionIsEnabled: yup.boolean().required(errors.required),
    mdfeEmissionIsEnabled: yup.boolean().required(errors.required),
    digitalCertificate: yup
      .object()
      .nullable()
      .when('cteEmissionIsEnabled', {
        is: (cteEmissionIsEnabled: boolean) => cteEmissionIsEnabled,
        then: (schema) =>
          schema.notOneOf([null], errors.required).required(errors.required),
      }),
    digitalCertificatePassword: yup
      .string()
      .trim()
      .when('digitalCertificate', {
        is: (digitalCertificate: IFile | null) =>
          !!digitalCertificate &&
          digitalCertificate?.id !== initialDigitalCertificateId,
        then: (schema) => schema.required(errors.required),
      }),
    initialCteNumber: yup
      .string()
      .max(10, errors.maxLength(10))
      .when('cteEmissionIsEnabled', {
        is: true,
        then: (schema) => schema.required(errors.required),
      }),
    cteSeries: yup
      .string()
      .max(3, errors.maxLength(3))
      .when('cteEmissionIsEnabled', {
        is: true,
        then: (schema) => schema.required(errors.required),
      }),
    mdfeSeries: yup
      .string()
      .max(3, errors.maxLength(3))
      .when('mdfeEmissionIsEnabled', {
        is: true,
        then: (schema) => schema.required(errors.required),
      }),
    initialMdfeNumber: yup
      .string()
      .max(10, errors.maxLength(10))
      .when('mdfeEmissionIsEnabled', {
        is: true,
        then: (schema) => schema.required(errors.required),
      }),
    taxRegime: yup.string().trim().required(errors.required),
    taxRegimeCode: yup
      .string()
      .trim()
      .when('taxRegime', {
        is: TaxRegime.SIMPLE_NATIONAL,
        then: (schema) => schema.required(errors.required),
      }),
    rates: yup.array().of(
      yup
        .string()
        .max(6, errors.maxLength(5))
        .test('is-rates-required', errors.required, (fieldValue, context) =>
          validateRateIfIsSimpleNational({
            context,
            validate: () => !!fieldValue,
          })
        )
        .test('is-rate-valid', errors.percentage, (fieldValue, context) =>
          validateRateIfIsSimpleNational({
            context,
            validate: () => {
              const numberValue = Number(fieldValue);
              return !!fieldValue && numberValue <= 100 && numberValue > 0;
            },
          })
        )
        .test(
          'has-two-decimal-places',
          errors.maxDecimalValue(2),
          (fieldValue, context) =>
            validateRateIfIsSimpleNational({
              context,
              validate: () => validateTwoDecimalPlaces(fieldValue),
            })
        )
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  const handleSwitchChange = (field: string, value: boolean) => {
    if (isManagementUser) {
      formik.setFieldValue(field, value);
    } else {
      addToast('Você não possui permissão para alterar esse campo', 'error');
    }
  };

  const handleFileChange = (file?: IFile) => {
    formik.setFieldValue('digitalCertificate', file ?? null);
  };

  const handleTaxRegimeChange = (tax: string) => {
    if (tax !== TaxRegime.SIMPLE_NATIONAL) {
      formik.setFieldValue('taxRegimeCode', TaxRegimeCode.NORMAL_REGIME);
    }
    formik.setFieldValue('taxRegime', tax);
  };

  const getDigitalCertificatePasswordTooltipMessage = () => {
    if (!hasAdminPermission) {
      return 'Você não possui permissão para editar esse campo';
    }
    if (!formik.errors.digitalCertificatePassword) {
      return 'Para editar a senha do certificado digital, é necessário que você adicione um novo certificado';
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="false">
        <FormRow>
          <FormGroup>
            <ToggleInput.RootToggle
              isChecked={formik.values.cteEmissionIsEnabled}
            >
              <ToggleInput.Label htmlFor="cteEmissionIsEnabled">
                Emissão de CT-e
              </ToggleInput.Label>
              <ToggleInput.Switch
                checked={formik.values.cteEmissionIsEnabled}
                name="cteEmissionIsEnabled"
                onChange={handleSwitchChange}
              />
            </ToggleInput.RootToggle>
            <FormError name="cteEmissionIsEnabled" />
          </FormGroup>
          <FormGroup>
            <ToggleInput.RootToggle
              isChecked={formik.values.mdfeEmissionIsEnabled}
            >
              <ToggleInput.Label htmlFor="mdfeEmissionIsEnabled">
                Emissão de MDF-e
              </ToggleInput.Label>
              <ToggleInput.Switch
                checked={formik.values.mdfeEmissionIsEnabled}
                name="mdfeEmissionIsEnabled"
                onChange={handleSwitchChange}
              />
            </ToggleInput.RootToggle>
            <FormError name="mdfeEmissionIsEnabled" />
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Label>Certificado digital</Label>
            <FileSinglePicker
              file={formik.values.digitalCertificate}
              onDeleteFile={() => handleFileChange()}
              onAddFile={handleFileChange}
              disabled={!hasAdminPermission}
            />
            <FormError name="digitalCertificate" />
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Label htmlFor="digitalCertificatePassword">
              Senha do certificado
            </Label>
            <Tooltip id="digital-certificate-password-tooltip" />
            <PasswordInput
              type="password"
              placeholder="Senha do certificado digital"
              id="digitalCertificatePassword"
              name="digitalCertificatePassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.digitalCertificatePassword}
              disabled={
                !hasAdminPermission ||
                !formik.values.digitalCertificate?.id ||
                formik.values.digitalCertificate?.id ===
                  initialDigitalCertificateId
              }
              invalidValue={
                !!formik.touched.digitalCertificatePassword &&
                !!formik.errors.digitalCertificatePassword
              }
              data-tooltip-id="digital-certificate-password-tooltip"
              data-tooltip-content={getDigitalCertificatePasswordTooltipMessage()}
            />
            <FormError name="digitalCertificatePassword" />
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Label htmlFor="cteSeries">
              Série do CT-e {formik.values.cteEmissionIsEnabled && '*'}
            </Label>
            <Input
              id="cteSeries"
              name="cteSeries"
              placeholder="Digite a série do CT-e"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={3}
              value={formik.values.cteSeries}
              disabled={
                !formik.values.cteEmissionIsEnabled || !hasAdminPermission
              }
              invalidValue={
                !!formik.touched.cteSeries && !!formik.errors.cteSeries
              }
            />
            <FormError name="cteSeries" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="initialCteNumber">
              Número do CT-e inicial {formik.values.cteEmissionIsEnabled && '*'}
            </Label>
            <Input
              id="initialCteNumber"
              name="initialCteNumber"
              placeholder="Digite o número do CT-e inicial"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={10}
              value={formik.values.initialCteNumber}
              disabled={
                !formik.values.cteEmissionIsEnabled || !hasAdminPermission
              }
              invalidValue={
                !!formik.touched.initialCteNumber &&
                !!formik.errors.initialCteNumber
              }
            />
            <FormError name="initialCteNumber" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="lastCteNumber">Número do último CT-e emitido</Label>
            <Input
              id="lastCteNumber"
              name="lastCteNumber"
              placeholder="Número do último CT-e emitido"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={10}
              value={formik.values.lastCteNumber}
              disabled
            />
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Label htmlFor="mdfeSeries">
              Série do MDF-e {formik.values.mdfeEmissionIsEnabled && '*'}
            </Label>
            <Input
              id="mdfeSeries"
              name="mdfeSeries"
              placeholder="Digite a série do MDF-e"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={3}
              value={formik.values.mdfeSeries}
              disabled={
                !formik.values.mdfeEmissionIsEnabled || !hasAdminPermission
              }
              invalidValue={
                !!formik.touched.mdfeSeries && !!formik.errors.mdfeSeries
              }
            />
            <FormError name="mdfeSeries" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="initialMdfeNumber">
              Número do MDF-e inicial
              {formik.values.mdfeEmissionIsEnabled && ' *'}
            </Label>
            <Input
              id="initialMdfeNumber"
              name="initialMdfeNumber"
              placeholder=" Digite o número do MDF-e inicial"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={10}
              value={formik.values.initialMdfeNumber}
              disabled={
                !formik.values.mdfeEmissionIsEnabled || !hasAdminPermission
              }
              invalidValue={
                !!formik.touched.initialMdfeNumber &&
                !!formik.errors.initialMdfeNumber
              }
            />
            <FormError name="initialMdfeNumber" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="lastMdfeNumber">
              Número do último MDF-e emitido
            </Label>
            <Input
              id="lastMdfeNumber"
              name="lastMdfeNumber"
              placeholder="Número do último MDF-e emitido"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={10}
              value={formik.values.lastMdfeNumber}
              disabled
            />
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Label htmlFor="taxRegime">Regime tributário *</Label>
            <Select
              id="taxRegime"
              name="taxRegime"
              onBlur={formik.handleBlur}
              onChange={(event) => handleTaxRegimeChange(event.target.value)}
              value={formik.values.taxRegime}
            >
              <option value="">Selecionar regime tributário</option>
              {taxRegimes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Select>
            <FormError name="taxRegime" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="taxRegimeCode">Código do regime tributário *</Label>
            <Select
              id="taxRegimeCode"
              name="taxRegimeCode"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.taxRegimeCode}
              disabled={formik.values.taxRegime !== TaxRegime.SIMPLE_NATIONAL}
            >
              <option value="">Selecionar código do regime tributário</option>
              {taxRegimeCodes.map((code) => (
                <option key={code} value={code}>
                  {TaxRegimeCodeMap[code]}
                </option>
              ))}
            </Select>
            <FormError name="taxRegimeCode" />
          </FormGroup>
        </FormRow>
        <Rates />
      </Form>
    </FormikProvider>
  );
};

export default forwardRef(TransportForm);
