import { B2Button, B2Card } from 'react-b2components';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Card = styled(B2Card)`
  margin: 0 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.four};
  color: ${({ theme }) => theme.colors.error};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.five, theme.spacing.eight)};

  img {
    max-width: 30%;
  }

  @media (max-width: 1024px) {
    img {
      max-width: 50%;
    }
  }
`;

export const Message = styled.span`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  @media (max-width: 1024px) {
    font-size: ${({ theme }) => theme.fontSize.regular};
  }
`;

export const CustomButton = styled(B2Button)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.white.main};
  background-color: ${({ theme }) => theme.colors.error};
  border-color: ${({ theme }) => theme.colors.error};

  svg {
    flex-shrink: 0;
    width: ${({ theme }) => theme.fontSize.medium};
    height: ${({ theme }) => theme.fontSize.medium};
  }
`;
