import React, { useState } from 'react';

import { IB2Button } from 'react-b2components';
import { ManagementButtonType } from 'utils/enums';
import { managementButtonTypeProps } from 'utils/helpers';

import DeleteModal from '@components/DeleteModal';

import { Container, CustomButton } from './styles';

export interface ManagementButtonProps extends Omit<IB2Button, 'children'> {
  text?: string;
}

interface DeleteModalProps extends CustomModalButtonsText {
  title: string;
  message?: string;
  onConfirm: (password?: string) => void;
  isLoading?: boolean;
  passwordRequired?: boolean;
}

interface DeleteButtonProps {
  button?: Omit<ManagementButtonProps, 'onClick'>;
  modal: DeleteModalProps;
}

export interface ManagementButtonsProps {
  editButtonProps?: ManagementButtonProps;
  deleteButtonProps?: DeleteButtonProps;
  children?: React.ReactNode;
}

const ManagementButtons: React.FC<ManagementButtonsProps> = ({
  editButtonProps,
  deleteButtonProps,
  children,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { icon: EditIcon } =
    managementButtonTypeProps[ManagementButtonType.EDIT];
  const { icon: DeleteIcon } =
    managementButtonTypeProps[ManagementButtonType.DELETE];

  if (!editButtonProps && !deleteButtonProps && !children) {
    return null;
  }

  return (
    <Container>
      {editButtonProps && (
        <CustomButton
          {...editButtonProps}
          buttonType={ManagementButtonType.EDIT}
        >
          <EditIcon variant="Bold" />
          {editButtonProps.text || 'Editar'}
        </CustomButton>
      )}
      {deleteButtonProps && (
        <>
          <DeleteModal
            {...deleteButtonProps.modal}
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
          />
          <CustomButton
            {...deleteButtonProps.button}
            onClick={() => setIsOpenModal(true)}
            buttonType={ManagementButtonType.DELETE}
          >
            <DeleteIcon variant="Bold" />
            {deleteButtonProps.button?.text || 'Deletar'}
          </CustomButton>
        </>
      )}
      {children}
    </Container>
  );
};

export default ManagementButtons;
