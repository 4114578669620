import { B2Card } from 'react-b2components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.five};
`;

export const Header = styled(B2Card)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.four};

  @media (max-width: 1260px) {
    flex-direction: column;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray.one};
  margin-left: ${({ theme }) => theme.spacing.five};

  @media (max-width: 1260px) {
    width: 100%;
    height: 1px;
    margin-left: 0;
    margin-right: ${({ theme }) => theme.spacing.five};
  }
`;

export const HorizontalGroup = styled.div`
  display: grid;
  grid-template-columns: 35% 1fr;
  gap: ${({ theme }) => theme.spacing.three};

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const VerticalGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.three};

  & > div:last-child {
    flex: 1;
  }
`;
