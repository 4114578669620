import React, { useState } from 'react';

import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { formatToBrlDate } from 'utils/formats';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useQueryParams, useServices } from '@hooks';

import SendingStatusLabel from '@components/SendingStatusLabel';
import ServiceContractModal from '@components/ServiceContractModal';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import Filters from './Filters';

const ServiceContractsList: React.FC = () => {
  const { queryParams, setQueryParams, onSearch, changePage } =
    useQueryParams<IServiceContractQueryParams>({ page: 1 });

  const [selectedServiceContract, setSelectedServiceContract] =
    useState<IServiceContract>();
  const [isServiceContractModalOpen, setIsServiceContractModalOpen] =
    useState(false);

  const { listServiceContracts } = useServices();
  const { scope } = useScope();
  const { addToast } = useToast();

  const {
    data: serviceContracts,
    refetch: refetchServiceContracts,
    isLoading,
  } = useQuery(
    ['serviceContracts', queryParams, scope],
    () => listServiceContracts(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista de termos de contratos',
          'error'
        );
      },
    }
  );

  return (
    <>
      <Filters
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onSearch={onSearch}
      />
      <Table
        data={serviceContracts?.results || []}
        isLoading={isLoading}
        headerData={[
          'Nº do serviço',
          'Situação',
          'Veículo',
          'Cliente',
          'Data de assinatura',
        ]}
        emptyMessage="Nenhum contrato encontrado"
        renderRow={(serviceContract) => (
          <B2TableRow
            key={serviceContract.id}
            onClick={() => {
              setSelectedServiceContract(serviceContract);
              setIsServiceContractModalOpen(true);
            }}
          >
            <TableDataCell>{serviceContract.service.number}</TableDataCell>
            <TableDataCell>
              <SendingStatusLabel
                status={serviceContract.status}
                isContractStatus
              />
            </TableDataCell>
            <TableDataCell>
              {serviceContract.service.vehicle.model} -{' '}
              {serviceContract.service.vehicle.identifier}
            </TableDataCell>
            <TableDataCell>{serviceContract.service.client.name}</TableDataCell>
            <TableDataCell>
              {serviceContract.uploaded_at
                ? formatToBrlDate(serviceContract.uploaded_at)
                : 'Contrato não assinado'}
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={serviceContracts?.count}
        changePage={changePage}
      />
      {selectedServiceContract && (
        <ServiceContractModal
          isOpen={isServiceContractModalOpen}
          onClose={() => setIsServiceContractModalOpen(false)}
          clientName={selectedServiceContract.service.client.name}
          serviceNumber={selectedServiceContract.service.number}
          contractId={selectedServiceContract.id}
          contractStatus={selectedServiceContract.status}
          onSubmitSuccess={refetchServiceContracts}
        />
      )}
    </>
  );
};

export default ServiceContractsList;
