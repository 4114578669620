import styled from 'styled-components';
import { ExpenseType } from 'utils/enums';
import { providerTypeProps } from 'utils/helpers';

interface LabelProps {
  type: ExpenseType;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const Square = styled.div<LabelProps>`
  border-radius: ${({ theme }) => theme.spacing.one};
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.three};
  height: ${({ theme }) => theme.spacing.three};
  background-color: ${({ theme, type }) =>
    providerTypeProps[type].color(theme)};
`;

export const Label = styled.p<LabelProps>`
  color: ${({ theme, type }) => providerTypeProps[type].color(theme)};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
