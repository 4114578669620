import { Link1 } from 'iconsax-react';
import { B2Button } from 'react-b2components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

import { TableDataCell } from '@components/Table/styles';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.five, theme.spacing.eight)};

  td {
    max-width: 160px;
  }
`;

export const ServiceNumber = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LinkIcon = styled(Link1)`
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};
  color: ${({ theme }) => theme.colors.info};
`;

export const CustomTableDataCell = styled(TableDataCell)`
  min-width: 200px;
  padding: ${({ theme }) => theme.spacing.three};
`;

export const CustomButton = styled(B2Button)`
  margin-left: auto;
`;
