import React from 'react';

import { Container, Label, Value } from './styles';

interface ContactItemProps {
  label: string;
  value: string;
  className?: string;
}

const ContactItem: React.FC<ContactItemProps> = ({
  label,
  value,
  className,
}) => {
  return (
    <Container className={className}>
      <Label>{label}:</Label>
      <Value>{value}</Value>
    </Container>
  );
};

export default ContactItem;
