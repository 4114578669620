import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';

import { errors } from '@utils';

interface IPaymentMethodFormProps {
  initialValues?: IPaymentMethodFormValues;
  onFinish: (data: IPaymentMethodFormValues) => void;
}

const IncomePaymentMethodForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  IPaymentMethodFormProps
> = ({ onFinish, initialValues }, ref) => {
  const formikInitialValues: IPaymentMethodFormValues = {
    name: '',
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    name: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="name">Nome do método de pagamento *</Label>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder="Digite aqui"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.touched.name && !!formik.errors.name}
          />
          <FormError name="name" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(IncomePaymentMethodForm);
