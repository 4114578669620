import styled, { css } from 'styled-components';

interface IRouteProps {
  isLast: boolean;
}

export const Container = styled.div`
  display: flex;
`;

export const RouteLabel = styled.span<IRouteProps>`
  font-size: ${({ theme }) => theme.fontSize.small};
  ${({ isLast, theme }) =>
    isLast
      ? css`
          color: ${theme.colors.main};
          font-weight: ${theme.fontWeight.semiBold};
          cursor: default;
        `
      : css`
          color: ${theme.colors.gray.eight};
          font-weight: ${theme.fontWeight.regular};
          cursor: pointer;
        `}
`;

export const Separator = styled.span`
  margin: 0px 12px;
`;
