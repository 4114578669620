import { useCallback } from 'react';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const usePaymentTerms = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const getPaymentTerm = useCallback(
    async (id: number) => {
      const response = await request<IPaymentTerm>({
        method: 'get',
        url: endpoints.financial.paymentTerm.get.replace(':id', id.toString()),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const listPaymentTerms = useCallback(
    async (params: IQueryParams) => {
      const response = await request<IPaginated<IPaymentTerm>>({
        method: 'get',
        url: endpoints.financial.paymentTerm.list,
        headers: { scope },
        params: {
          page: params.page,
          search: params.search || undefined,
          page_size: params.pageSize || undefined,
          is_active: params.isActive || undefined,
        },
      });

      return response.data;
    },
    [request, scope]
  );

  const createPaymentTerm = useCallback(
    async (data: IPaymentTermFormValues) => {
      const response = await request<IPaymentTerm>({
        method: 'post',
        url: endpoints.financial.paymentTerm.create,
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const updatePaymentTerm = useCallback(
    async (id: number, data: IPaymentTermFormValues) => {
      const response = await request<IPaymentTerm>({
        method: 'patch',
        url: endpoints.financial.paymentTerm.update.replace(
          ':id',
          id.toString()
        ),
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const deletePaymentTerm = useCallback(
    async ({ id, password }: IPaymentTermsRequest) => {
      const response = await request<IPaymentTerm>({
        method: 'delete',
        url: endpoints.financial.paymentTerm.delete.replace(
          ':id',
          id.toString()
        ),
        headers: { scope },
        data: { password },
      });

      return response.data;
    },
    [request, scope]
  );

  return {
    getPaymentTerm,
    listPaymentTerms,
    createPaymentTerm,
    updatePaymentTerm,
    deletePaymentTerm,
  };
};

export default usePaymentTerms;
