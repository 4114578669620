import styled from 'styled-components';
import { addSpacing, modifyColor } from 'utils/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.one};
  padding: ${({ theme }) => addSpacing(theme.spacing.four, theme.spacing.six)};
  border-radius: 16px;
  background-color: ${({ theme }) =>
    modifyColor({ hex: theme.colors.main, lightness: 0.93 })};
`;

export const Title = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.one};
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.gray.ten};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
