import React from 'react';

import { Container, ImageContainer } from './styles';

interface PreviewImagesProps {
  image: IImage;
  onImageClick: () => void;
  isSelected: boolean;
}

const PreviewImages: React.FC<PreviewImagesProps> = ({
  image,
  onImageClick,
  isSelected,
}) => {
  return (
    <Container selectedImage={isSelected}>
      <ImageContainer src={image.image_low_url} onClick={onImageClick} />
    </Container>
  );
};

export default PreviewImages;
