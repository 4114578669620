import { color } from 'b2utils';
import { Edit } from 'iconsax-react';
import styled from 'styled-components';
import { iconButtonStyle } from 'utils/styles';

export const EditButton = styled(Edit)`
  ${iconButtonStyle}
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing.one};
  color: ${({ theme }) => theme.colors.info};
  width: ${({ theme }) => theme.spacing.seven};
  height: ${({ theme }) => theme.spacing.seven};
  background-color: ${({ theme }) =>
    color.addOpacityOnHexColor(theme.colors.info, 0.2)};
`;
