import React from 'react';

import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useCompanies } from '@hooks';

import Loading from '@components/Loading';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import CompanyInfo from './CompanyInfo';
import routes from './routes';

const DetailCompany: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { isManagementUser } = useAuth();
  const { getCompany } = useCompanies();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const {
    isLoading,
    data: companyData,
    refetch,
  } = useQuery(['company', companyId], () => getCompany(companyId!), {
    onError: () => {
      addToast('Não foi possível carregar os dados da empresa', 'error');
      navigate(RoutesPath.private.companies.listCompanies.path, {
        replace: true,
      });
    },
  });

  const isDataLoading = isLoading || !companyData;

  const navigateToUpdateCompanyPage = (id: string) => {
    return navigate(
      RoutesPath.private.companies.updateCompany.path.replace(':companyId', id)
    );
  };

  return (
    <>
      <PageHeader
        title="Detalhes da empresa"
        routes={isManagementUser ? routes : undefined}
        editButtonProps={{
          onClick: () => navigateToUpdateCompanyPage(companyId!),
        }}
      />
      {isDataLoading ? (
        <Loading />
      ) : (
        <>
          <CompanyInfo company={companyData} refetch={refetch} />
        </>
      )}
    </>
  );
};

export default DetailCompany;
