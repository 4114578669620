import React, { forwardRef } from 'react';

import { MdUpload } from 'react-icons/md';

import Loading from '@components/Loading';

import {
  Button,
  ButtonText,
  Container,
  FileName,
  FirstLineContent,
  Input,
  MaxSize,
} from './styles';

interface PickerButtonProps {
  fileName: string;
  inputAccept: string;
  buttonText: string;
  onButtonClick: () => void;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  isLoading: boolean;
  disabled: boolean;
}

const PickerButton: React.ForwardRefRenderFunction<
  HTMLInputElement,
  PickerButtonProps
> = (
  {
    fileName,
    inputAccept,
    buttonText,
    onButtonClick,
    handleInputChange,
    isLoading,
    disabled,
  },
  ref
) => {
  return (
    <Container>
      <Button onClick={onButtonClick} disabled={disabled}>
        {isLoading ? (
          <Loading color="white" />
        ) : (
          <>
            <FirstLineContent>
              <ButtonText>{buttonText}</ButtonText>
              <MdUpload />
            </FirstLineContent>
            <MaxSize>Tamanho máx.: 5MB</MaxSize>
          </>
        )}
      </Button>
      <FileName>{fileName}</FileName>
      <Input
        type="file"
        accept={inputAccept}
        onChange={handleInputChange}
        ref={ref}
        disabled={disabled}
      />
    </Container>
  );
};

export default forwardRef(PickerButton);
