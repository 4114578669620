import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import { phone } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { CollectionAndDeliveryType } from 'utils/enums';
import regex from 'utils/regex';
import * as yup from 'yup';

import { usePaymentMethods } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';

import { CustomChooseItemFilter } from '@pages/Services/FormSteps/styles';
import { errors } from '@utils';

import { InputGroup, RadioButtonsContainer } from './styles';

interface QuotationInfoProps {
  initialValues?: IQuotationInfoStepForm;
  onFinish: (data: IQuotationInfoStepForm) => void;
}

const QuotationInfo: React.ForwardRefRenderFunction<
  IFormStepRef,
  QuotationInfoProps
> = ({ initialValues, onFinish }, ref) => {
  const { listPaymentMethods } = usePaymentMethods();

  const collectionAndDeliveryTypes = Object.values(CollectionAndDeliveryType);

  const formikInitialValues: IQuotationInfoStepForm = {
    clientName: '',
    phone: '',
    paymentMethod: null,
    collectionType: CollectionAndDeliveryType.BASE,
    collectionAddress: '',
    deliveryType: CollectionAndDeliveryType.BASE,
    deliveryAddress: '',
    estimatedShippingDate: '',
    expiration: '',
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    clientName: yup.string().trim().required(errors.required),
    phone: yup
      .string()
      .trim()
      .required(errors.required)
      .test(
        'is-phone-valid',
        errors.phone,
        (value) => !!value && phone.validate(value)
      ),
    paymentMethod: yup
      .object()
      .nullable()
      .notOneOf([null], errors.required)
      .required(errors.required),
    expiration: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  const handleSelectPaymentMethod = useCallback(
    (paymentMethod: IPaymentMethod) => {
      formik.setFieldTouched('paymentMethod');
      formik.setFieldValue('paymentMethod', paymentMethod);
    },
    [formik]
  );

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="clientName">Cliente *</Label>
          <Input
            type="text"
            id="clientName"
            name="clientName"
            placeholder="Digite aqui"
            value={formik.values.clientName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={
              !!formik.touched.clientName && !!formik.errors.clientName
            }
          />
          <FormError name="clientName" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="phone">Telefone *</Label>
          <Input
            type="text"
            id="phone"
            name="phone"
            placeholder="Digite aqui"
            maxLength={15}
            value={formik.values.phone}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue('phone', phone.mask(event.target.value))
            }
            invalidValue={!!formik.touched.phone && !!formik.errors.phone}
          />
          <FormError name="phone" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Método de pagamento *</Label>
          <CustomChooseItemFilter
            filterValue={
              formik.values.paymentMethod?.name ||
              'Selecionar método de pagamento'
            }
            modalTitle="Selecionar método de pagamento"
            fetch={listPaymentMethods}
            queryParams={{ isActive: 'true' }}
            queryKey="paymentMethods"
            inputLabel="Selecionar método de pagamento *"
            selectedItemLabel="Método de pagamento selecionado"
            shouldRenderSelectedItem={!!formik.values.paymentMethod}
            onSelect={(paymentMethod) =>
              handleSelectPaymentMethod(paymentMethod)
            }
            selectedItem={formik.values.paymentMethod || undefined}
            renderItemText={(paymentMethod) => paymentMethod?.name}
            invalidValue={
              !!formik.touched.paymentMethod && !!formik.errors.paymentMethod
            }
          />
          <FormError name="paymentMethod" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Tipo de coleta (origem) *</Label>
          <RadioButtonsContainer>
            {collectionAndDeliveryTypes.map((type) => (
              <InputGroup key={type}>
                <Input
                  type="radio"
                  id={`collectionType${type}`}
                  name="collectionType"
                  checked={formik.values.collectionType === type}
                  onChange={() => formik.setFieldValue('collectionType', type)}
                  onBlur={formik.handleBlur}
                />
                <Label htmlFor={`collectionType${type}`}>{type}</Label>
              </InputGroup>
            ))}
            <FormError name="collectionType" />
          </RadioButtonsContainer>
        </FormGroup>
        <FormGroup>
          <Label>Endereço de coleta</Label>
          <Input
            type="text"
            id="collectionAddress"
            name="collectionAddress"
            placeholder="Digite aqui"
            value={formik.values.collectionAddress}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Tipo de entrega (destino) *</Label>
          <RadioButtonsContainer>
            {collectionAndDeliveryTypes.map((type) => (
              <InputGroup key={type}>
                <Input
                  type="radio"
                  id={`deliveryType${type}`}
                  name="deliveryType"
                  checked={formik.values.deliveryType === type}
                  onChange={() => formik.setFieldValue('deliveryType', type)}
                  onBlur={formik.handleBlur}
                />
                <Label htmlFor={`deliveryType${type}`}>{type}</Label>
              </InputGroup>
            ))}
            <FormError name="deliveryType" />
          </RadioButtonsContainer>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="deliveryAddress">Endereço de entrega</Label>
          <Input
            type="text"
            id="deliveryAddress"
            name="deliveryAddress"
            placeholder="Digite aqui"
            value={formik.values.deliveryAddress}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="estimatedShippingDate">Previsão para envio</Label>
          <Input
            type="date"
            id="estimatedShippingDate"
            name="estimatedShippingDate"
            value={formik.values.estimatedShippingDate}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="expiration">Validade do orçamento (dias) *</Label>
          <Input
            type="text"
            id="expiration"
            name="expiration"
            placeholder="Digite aqui"
            value={formik.values.expiration}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'expiration',
                event.target.value.replace(regex.onlyNumbers, '')
              )
            }
            invalidValue={
              !!formik.touched.expiration && !!formik.errors.expiration
            }
          />
          <FormError name="expiration" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(QuotationInfo);
