import React from 'react';

import { IB2Modal } from 'react-b2components';

import {
  Modal,
  Body,
  CloseButton,
  Container,
  Header,
  HeaderGroup,
  Title,
} from './styles';

export interface IBaseModalProps extends IB2Modal {
  title: string;
  onClose: () => void;
  headerComponent?: React.ReactNode;
}

const BaseModal: React.FC<IBaseModalProps> = ({
  title,
  onClose,
  headerComponent,
  ...props
}) => {
  return (
    <Modal {...props}>
      <Container>
        <Header>
          <HeaderGroup>
            <Title>{title}</Title>
            {!!headerComponent && headerComponent}
          </HeaderGroup>
          <CloseButton onClick={() => onClose?.()} />
        </Header>
        <Body>{props.children}</Body>
      </Container>
    </Modal>
  );
};

export default BaseModal;
