import React, { forwardRef, useImperativeHandle, useMemo } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { IcmsType, TaxRegime, UserType } from 'utils/enums';
import { getServiceCteValidationSchema } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';

import BaseServiceCteForm from '../BaseServiceCteForm';

interface ServiceCteFormProps {
  initialValues?: IServiceCte;
  onFinish: (data: IServiceCte) => void;
}

const ServiceCteForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  ServiceCteFormProps
> = ({ onFinish, initialValues }, ref) => {
  const { userInfo } = useAuth();
  const { taxRegime } = useScope();

  const emptyCfop = { id: 0, code: '', description: 'Nenhum' };

  const companyIcmsType = useMemo(() => {
    if (userInfo?.type === UserType.COMPANY) {
      return userInfo?.company.tax_regime;
    } else {
      return taxRegime || undefined;
    }
  }, [taxRegime, userInfo]);

  const isSimpleNational = useMemo(
    () => companyIcmsType === TaxRegime.SIMPLE_NATIONAL,
    [companyIcmsType]
  );

  const formikInitialValues: IServiceCte = {
    cfop: emptyCfop,
    icmsType: isSimpleNational ? IcmsType.SIMPLE_NATIONAL : undefined,
    icmsRate: '',
    icmsReduction: '',
    optingForCreditGranted: false,
    cteFreightCost: '',
    cteGeneralObservations: '',
    ...initialValues,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: getServiceCteValidationSchema(companyIcmsType),
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <BaseServiceCteForm companyIcmsType={companyIcmsType} />
    </FormikProvider>
  );
};
export default forwardRef(ServiceCteForm);
