import React, { useCallback, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { currency } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import { B2Button, B2TableRow } from 'react-b2components';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { PaymentStatus, PaymentStatusMap } from 'utils/enums';
import { formatDate } from 'utils/formats';
import { getExpenseDescription, throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import {
  useExpensePayments,
  useExpenses,
  useNavigateWithScope,
  useQueryParams,
} from '@hooks';

import DeleteModal from '@components/DeleteModal';
import ExpenseTypeLabel from '@components/ExpenseTypeLabel';
import Input from '@components/Input';
import LabelWithPaymentIcon from '@components/LabelWithPaymentIcon';
import { TableDataCell } from '@components/Table/styles';
import TableVariant from '@components/TableVariant';

import RoutesPath from '@router/routes';

import {
  ActionIconsContainer,
  CarIcon,
  EditIcon,
  EmptyWalletIcon,
  PaymentCard,
  PaymentStatusLabel,
  PaymentText,
  TrashIcon,
  WalletTickIcon,
} from '../styles';
import ExpensePaymentModal from './ExpensePaymentModal';
import Filters from './Filters';
import PaymentsListModal from './PaymentsListModal';
import { expensePaymentFormValidationSchema } from './helpers';

interface IRequestCreateExpensePayment {
  values: IExpensePaymentFormValues;
  resetForm: () => void;
}

interface ExpensesListingProps {
  yearMonth?: string;
  provider?: IProvider;
}

const ExpensesListing: React.FC<ExpensesListingProps> = ({
  yearMonth,
  provider,
}) => {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState<IExpense>();
  const [selectedProvider, setSelectedProvider] = useState<IProvider>();
  const [isPaymentsListModalOpen, setIsPaymentsListModalOpen] = useState(false);
  const [paymentToExpensesCopy, setPaymentToExpensesCopy] = useState<
    Array<IPaymentToExpenseFormValues>
  >([]);

  const { queryParams, setQueryParams, onSearch, changePage } =
    useQueryParams<IExpenseQueryParams>({
      page: 1,
      date__gte: yearMonth
        ? moment(yearMonth).startOf('month').format('YYYY-MM-DD')
        : undefined,
      date__lte: yearMonth
        ? moment(yearMonth).endOf('month').format('YYYY-MM-DD')
        : undefined,
      provider,
    });

  const { listExpenses, deleteExpense } = useExpenses();
  const { createExpensePayment } = useExpensePayments();
  const { scope } = useScope();
  const { isManagementUser, hasAdminPermission } = useAuth();
  const { addToast } = useToast();
  const { navigateWithScope } = useNavigateWithScope();

  const {
    data: expenses,
    refetch: refetchExpenses,
    isLoading,
  } = useQuery(
    ['expenses', queryParams, scope],
    () => listExpenses(queryParams),
    {
      onError: () => {
        addToast('Não foi possível carregar a lista de despesas', 'error');
      },
    }
  );

  const { mutate: requestDeleteExpense, isLoading: isDeleteExpenseLoading } =
    useMutation(
      (password: string) => deleteExpense(selectedExpense!.id, password),
      {
        onSuccess: () => {
          refetchExpenses();
          setIsDeleteModalOpen(false);
          addToast('Despesa deletada com sucesso', 'success');
        },
        onError: (error: AxiosError) => {
          addToast('Não foi possível deletar a despesa', 'error');
          throwToastApiErrors(error, addToast);
        },
      }
    );

  const { mutate: requestCreatePayment, isLoading: isCreatePaymentLoading } =
    useMutation(
      ({ values }: IRequestCreateExpensePayment) =>
        createExpensePayment(values),
      {
        onSuccess: (_, { resetForm }) => {
          refetchExpenses();
          setIsPaymentModalOpen(false);
          resetForm();
          addToast('Pagamento cadastrado com sucesso', 'success');
        },
        onError: (error: AxiosError) => {
          addToast('Não foi possível adicionar pagamento', 'error');
          throwToastApiErrors(error, addToast);
        },
      }
    );

  const formikInitialValues: IExpensePaymentFormValues = useMemo(
    () => ({
      value: '',
      accrualDate: '',
      expenses: [],
      paymentMethod: null,
      bankAccount: null,
      proofOfPayment: null,
      observations: '',
    }),
    []
  );

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: expensePaymentFormValidationSchema,
    onSubmit: (values, { resetForm }) =>
      requestCreatePayment({ values, resetForm }),
  });

  const totalRemainingAmount = useMemo(
    () =>
      formik.values.expenses.reduce(
        (total, { expense }) => total + expense.value - expense.paid_value,
        0
      ),
    [formik.values.expenses]
  );

  const expensesList = useMemo(
    () => expenses?.results || [],
    [expenses?.results]
  );

  const selectedProviderId = useMemo(() => {
    if (formik.values.expenses.length === 0) {
      return null;
    }
    return formik.values.expenses[0].expense.provider.id;
  }, [formik.values.expenses]);

  const expensesWithSameProvider = useMemo(() => {
    if (!selectedProviderId) {
      return [];
    }
    return expensesList.filter(
      (expense) => expense.provider.id === selectedProviderId
    );
  }, [expensesList, selectedProviderId]);

  const hasSelectedExpensesInPage = useMemo(
    () =>
      formik.values.expenses.some(({ expense }) =>
        expensesWithSameProvider.some(({ id }) => id === expense.id)
      ),
    [expensesWithSameProvider, formik.values.expenses]
  );

  const areAllExpensesSelected = useMemo(
    () =>
      !!expensesWithSameProvider.length &&
      expensesWithSameProvider.every(
        (expense) =>
          formik.values.expenses.some(
            (selectedExpense) => selectedExpense.expense.id === expense.id
          ) || expense.status === PaymentStatus.PAID
      ),
    [expensesWithSameProvider, formik.values.expenses]
  );

  const handleSelectAllExpenses = useCallback(() => {
    if (formik.values.expenses.length === 0 && !hasSelectedExpensesInPage) {
      return;
    }

    if (isManagementUser && !scope) {
      addToast('Selecione uma empresa para continuar', 'error');
      return;
    }

    if (areAllExpensesSelected) {
      const newExpenses = formik.values.expenses.filter(
        ({ expense }) =>
          !expensesList.some((expenseInList) => expenseInList.id === expense.id)
      );
      formik.setFieldValue('expenses', newExpenses);
    } else {
      const newExpenses = expensesWithSameProvider
        .filter((currentExpense) => {
          const isExpenseInFormik = formik.values.expenses.some(
            ({ expense: expenseInFormik }) =>
              expenseInFormik.id === currentExpense.id
          );
          const isPaymentPending = currentExpense.status !== PaymentStatus.PAID;

          return !isExpenseInFormik && isPaymentPending;
        })
        .map((expense) => ({ value: '', expense }));

      formik.setFieldValue('expenses', [
        ...formik.values.expenses,
        ...newExpenses,
      ]);
    }
  }, [
    addToast,
    areAllExpensesSelected,
    expensesList,
    expensesWithSameProvider,
    formik,
    hasSelectedExpensesInPage,
    isManagementUser,
    scope,
  ]);

  const handleSelectExpense = useCallback(
    (selectedExpense: IExpense) => {
      if (
        !!formik.values.expenses.length &&
        formik.values.expenses[0].expense.provider.id !==
          selectedExpense.provider.id
      ) {
        return;
      }

      if (isManagementUser && !scope) {
        addToast('Selecione uma empresa para continuar', 'error');
        return;
      }
      const expenseIndex = formik.values.expenses.findIndex(
        ({ expense }) => expense.id === selectedExpense.id
      );

      const isSelected = expenseIndex !== -1;

      if (isSelected) {
        formik.setFieldValue(
          'expenses',
          formik.values.expenses.filter(
            ({ expense }) => expense.id !== selectedExpense.id
          )
        );
      } else {
        formik.setFieldValue('expenses', [
          ...formik.values.expenses,
          { value: '', expense: selectedExpense },
        ]);
      }
    },
    [addToast, formik, isManagementUser, scope]
  );

  const handleDeleteExpenseClick = useCallback((expense: IExpense) => {
    setIsDeleteModalOpen(true);
    setSelectedExpense(expense);
  }, []);

  const handleDeleteExpense = useCallback(
    (password?: string) => {
      if (!password) {
        return;
      }
      requestDeleteExpense(password);
    },
    [requestDeleteExpense]
  );

  const handleEditExpenseClick = useCallback(
    (expense: IExpense) => {
      navigateWithScope({
        routePath:
          RoutesPath.private.financial.expenses.updateExpense.path.replace(
            ':expenseId',
            expense.id.toString()
          ),
        company: expense.company,
      });
    },
    [navigateWithScope]
  );

  const handleAddPaymentClick = useCallback(() => {
    if (isManagementUser && !scope) {
      addToast(
        'Não é possível cadastrar um pagamento sem estar com uma empresa selecionada',
        'error'
      );
    } else {
      setPaymentToExpensesCopy(formik.values.expenses);
      setIsPaymentModalOpen(true);
    }
  }, [addToast, formik.values.expenses, isManagementUser, scope]);

  const handleClosePaymentModal = useCallback(() => {
    formik.setValues({
      ...formikInitialValues,
      expenses: paymentToExpensesCopy,
    });
    formik.setTouched({});
    setIsPaymentModalOpen(false);
  }, [formik, formikInitialValues, paymentToExpensesCopy]);

  const handleOpenPaymentsListModal = useCallback(
    ({ expense, provider }: { expense?: IExpense; provider?: IProvider }) => {
      setIsPaymentsListModalOpen(true);
      setSelectedExpense(expense);
      setSelectedProvider(provider);
    },
    []
  );

  const handleClosePaymentsListModal = useCallback(() => {
    setIsPaymentsListModalOpen(false);
    setSelectedExpense(undefined);
    setSelectedProvider(undefined);
  }, []);

  const headerData = useMemo(() => {
    if (provider) {
      return [
        'Data',
        'Descrição',
        'Valor',
        'Tipo',
        'Categoria',
        'Situação',
        hasAdminPermission ? 'Ações' : 'Ação',
      ];
    }
    return [
      (
        <Input
          type="checkbox"
          id="select-all-expenses-checkbox"
          value="select-all-expenses-checkbox"
          name="expense-checkbox"
          data-tooltip-id="expenses-list-tooltip"
          data-tooltip-content="Selecionar todas as despesas"
          checked={areAllExpensesSelected}
          onChange={handleSelectAllExpenses}
          disabled={
            formik.values.expenses.length === 0 && !hasSelectedExpensesInPage
          }
        />
      ) as unknown as string,
      'Data',
      'Fornecedor',
      'Descrição',
      'Valor',
      'Tipo',
      'Categoria',
      'Situação',
      hasAdminPermission ? 'Ações' : 'Ação',
    ];
  }, [
    areAllExpensesSelected,
    formik.values.expenses.length,
    handleSelectAllExpenses,
    hasAdminPermission,
    hasSelectedExpensesInPage,
    provider,
  ]);

  return (
    <FormikProvider value={formik}>
      <Filters
        yearMonth={yearMonth}
        removeProviderFilter={!!provider}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onSearch={onSearch}
      />
      <Tooltip id="expenses-list-tooltip" />
      {formik.values.expenses.length > 0 && (
        <PaymentCard>
          <PaymentText>
            <strong>{formik.values.expenses.length}</strong>
            {formik.values.expenses.length > 1
              ? ' despesas selecionadas '
              : ' despesa selecionada '}
            no valor de
            <strong> {currency.centsToBrl(totalRemainingAmount)}</strong>
          </PaymentText>
          <B2Button onClick={handleAddPaymentClick}>
            Pagar ({formik.values.expenses.length})
          </B2Button>
        </PaymentCard>
      )}
      <TableVariant
        data={expensesList}
        isLoading={isLoading}
        headerData={headerData}
        emptyMessage="Nenhuma despesa encontrada"
        renderRow={(expense) => (
          <B2TableRow key={expense.id}>
            {!provider && (
              <TableDataCell>
                {expense.status === PaymentStatus.PAID ? (
                  <WalletTickIcon
                    data-tooltip-id="expenses-list-tooltip"
                    data-tooltip-content="Pago"
                  />
                ) : (
                  <Input
                    id={`expense-checkbox-${expense.id}`}
                    value={`expense-checkbox-${expense.id}`}
                    name="expense-checkbox"
                    type="checkbox"
                    checked={formik.values.expenses.some(
                      (selectedExpense) =>
                        selectedExpense.expense.id === expense.id
                    )}
                    onChange={() => handleSelectExpense(expense)}
                    disabled={
                      !!formik.values.expenses.length &&
                      formik.values.expenses[0].expense.provider.id !==
                        expense.provider.id
                    }
                  />
                )}
              </TableDataCell>
            )}
            <TableDataCell>{formatDate(expense.date)}</TableDataCell>
            {!provider && (
              <TableDataCell>
                <LabelWithPaymentIcon
                  label={expense.provider.name}
                  tooltipMessage="Ver pagamentos"
                  onClick={() =>
                    handleOpenPaymentsListModal({ provider: expense.provider })
                  }
                />
              </TableDataCell>
            )}
            <TableDataCell>{getExpenseDescription(expense)}</TableDataCell>
            <TableDataCell>
              {currency.centsToBrl(expense.value)}
              {expense.paid_value !== expense.value &&
                ` (${currency.centsToBrl(expense.value - expense.paid_value)})`}
            </TableDataCell>
            <TableDataCell>
              <ExpenseTypeLabel type={expense.type} />
            </TableDataCell>
            <TableDataCell>{expense.category?.name || '-'}</TableDataCell>
            <TableDataCell>
              <PaymentStatusLabel status={expense.status}>
                {PaymentStatusMap[expense.status]}
              </PaymentStatusLabel>
            </TableDataCell>
            <TableDataCell>
              <ActionIconsContainer>
                <EmptyWalletIcon
                  data-tooltip-id="expenses-list-tooltip"
                  data-tooltip-content="Ver pagamentos"
                  onClick={() => handleOpenPaymentsListModal({ expense })}
                />
                {expense.departure ? (
                  <Link
                    to={RoutesPath.private.departures.detailDeparture.path.replace(
                      ':departureId',
                      expense.departure.id.toString()
                    )}
                    target="_blank"
                    data-tooltip-id="expenses-list-tooltip"
                    data-tooltip-content="Ir para a página do embarque"
                  >
                    <CarIcon />
                  </Link>
                ) : (
                  <EditIcon
                    data-tooltip-id="expenses-list-tooltip"
                    data-tooltip-content="Editar despesa"
                    onClick={() => handleEditExpenseClick(expense)}
                  />
                )}
                {hasAdminPermission && !expense.departure && (
                  <TrashIcon
                    data-tooltip-id="expenses-list-tooltip"
                    data-tooltip-content="Deletar despesa"
                    onClick={() => handleDeleteExpenseClick(expense)}
                  />
                )}
              </ActionIconsContainer>
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={expenses?.count}
        changePage={changePage}
        hasClick={false}
      />
      {isDeleteModalOpen && selectedExpense && (
        <DeleteModal
          title={`Deseja realmente deletar a despesa: ${selectedExpense.description}?`}
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDeleteExpense}
          isLoading={isDeleteExpenseLoading}
          passwordRequired
        />
      )}
      {isPaymentsListModalOpen && (
        <PaymentsListModal
          isOpen={isPaymentsListModalOpen}
          onClose={handleClosePaymentsListModal}
          expense={selectedExpense}
          provider={selectedProvider}
          refetchExpenses={refetchExpenses}
        />
      )}
      <ExpensePaymentModal
        isOpen={isPaymentModalOpen}
        onClose={handleClosePaymentModal}
        isLoading={isCreatePaymentLoading}
        totalRemainingAmount={totalRemainingAmount}
      />
    </FormikProvider>
  );
};

export default ExpensesListing;
