import React from 'react';

import { useFormikContext } from 'formik';
import { B2Button } from 'react-b2components';
import { UseMutationResult } from 'react-query';
import { useTheme } from 'styled-components';

import { useAuth } from '@contexts/Auth';

import Loading from '@components/Loading';

import BaseServiceCteForm from '@pages/Services/FormSteps/BaseServiceCteForm';

import { ButtonContainer } from './styles';

interface ServiceCteFormProps {
  service: IService;
  onSubmit: UseMutationResult<IService, unknown, IServiceCte, unknown>;
}

const ServiceCteForm: React.FC<ServiceCteFormProps> = ({
  service,
  onSubmit,
}) => {
  const { isClientUser } = useAuth();
  const formik = useFormikContext<IServiceCte>();
  const theme = useTheme();

  return (
    <>
      <BaseServiceCteForm companyIcmsType={service.company.tax_regime} />
      {!isClientUser && (
        <ButtonContainer>
          <B2Button
            type="button"
            variant="primary"
            onClick={() => !onSubmit.isLoading && formik.handleSubmit()}
          >
            {onSubmit.isLoading ? (
              <Loading
                color={theme.colors.white.main}
                size={theme.spacing.five}
              />
            ) : (
              'Salvar alterações'
            )}
          </B2Button>
        </ButtonContainer>
      )}
    </>
  );
};

export default ServiceCteForm;
