import React from 'react';

import { cep } from 'b2utils';
import { useFormikContext } from 'formik';
import { useMutation, useQuery } from 'react-query';
import regex from 'utils/regex';

import { useToast } from '@contexts/Toast';
import { useBrasilApi, useCities } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';

import { helpers } from '@utils';

type ZipCodeKeyOptions = 'loadingZipCode' | 'destinationZipCode';
type CityKeyOptions = 'origin' | 'destination';

interface DepartureAddressProps {
  zipCodeKey: ZipCodeKeyOptions;
  cityKey: CityKeyOptions;
}

const DepartureAddress: React.FC<DepartureAddressProps> = ({
  zipCodeKey,
  cityKey,
}) => {
  const formik = useFormikContext<IDepartureInfoStepForm>();

  const { getAddressByZipCode } = useBrasilApi();
  const { listCitiesByState } = useCities();
  const { addToast } = useToast();

  const findCityAndSetField = (
    cities: Array<ICity>,
    query: {
      cityName?: string;
      cityId?: number;
    }
  ) => {
    const selectedCity = cities.find(
      (city) => city.id === query.cityId || city.name === query.cityName
    );
    if (selectedCity) {
      formik.setFieldValue(cityKey, selectedCity);
    }
  };

  const { data: cities, isLoading: isLoadingCities } = useQuery(
    ['citiesByState', formik.values[cityKey]?.state],
    () => listCitiesByState({ state: formik.values[cityKey]?.state }),
    {
      onSuccess: (cities) => {
        findCityAndSetField(cities, { cityName: formik.values[cityKey]?.name });
      },
      onError: () => {
        addToast('Não foi possível carregar as cidades desse estado', 'error');
      },
      enabled: !!formik.values[cityKey]?.state,
    }
  );

  const { mutate: fetchAddressByZipCode, isLoading: isLoadingAddress } =
    useMutation((zipCode: string) => getAddressByZipCode(zipCode), {
      onMutate: () => {
        addToast('Carregando informações a partir do CEP...', 'info');
      },
      onSuccess: (address: IBrasilApiAddress) => {
        if (cities && address.state === formik.values[cityKey]?.state) {
          findCityAndSetField(cities, { cityName: address.city });
        } else {
          formik.setFieldValue(cityKey, {
            id: 0,
            name: address.city,
            state: address.state,
          });
        }
      },
      onError: () => {
        formik.setFieldValue(cityKey, {
          id: 0,
          name: '',
          state: '',
        });
        addToast(
          'Não foi possível carregar o endereço baseado no CEP informado',
          'error'
        );
      },
    });

  const isLoading = isLoadingAddress || isLoadingCities;

  const handleChangeZipCodeText = (zipCode: string) => {
    const cleanZipCode = zipCode.replace(regex.onlyNumbers, '');

    if (cleanZipCode.length === 8) {
      fetchAddressByZipCode(cleanZipCode);
    }

    formik.setFieldValue(zipCodeKey, cep.mask(cleanZipCode));
  };

  const handleSelectState = (state?: string) => {
    formik.setFieldValue(cityKey, {
      id: 0,
      name: '',
      state: state || '',
    });
  };

  const handleSelectCity = (cityName: string) => {
    if (cityName && cities) {
      findCityAndSetField(cities, { cityName });
    } else {
      formik.setFieldValue(cityKey, {
        id: 0,
        name: '',
        state: formik.values[cityKey]?.state || '',
      });
    }
  };

  const label = zipCodeKey === 'loadingZipCode' ? 'de origem' : 'de destino';

  return (
    <>
      <FormRow>
        <FormGroup>
          <Label htmlFor={zipCodeKey}>CEP {label} *</Label>
          <Input
            type="text"
            id={zipCodeKey}
            name={zipCodeKey}
            placeholder="Ex: 00000-000"
            onBlur={formik.handleBlur}
            value={formik.values[zipCodeKey]}
            onChange={(event) => handleChangeZipCodeText(event.target.value)}
            invalidValue={
              !!formik.touched[zipCodeKey] && !!formik.errors[zipCodeKey]
            }
          />
          <FormError name={zipCodeKey} />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor={`${cityKey}.state`}>Estado {label} *</Label>
          <Select
            id={`${cityKey}.state`}
            name={`${cityKey}.state`}
            value={formik.values[cityKey]?.state || ''}
            onBlur={formik.handleBlur}
            onChange={(event) => handleSelectState(event.target.value)}
            disabled={isLoading}
            invalidValue={
              !!formik.touched[cityKey] &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore Formik has the wrong type for touched object
              !!formik.errors[cityKey]?.state
            }
          >
            {helpers.ufOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <FormError name={`${cityKey}.state`} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor={`${cityKey}.name`}>Cidade {label} *</Label>
          <Select
            id={`${cityKey}.name`}
            name={`${cityKey}.name`}
            value={formik.values[cityKey]?.name || ''}
            onChange={(event) => handleSelectCity(event.target.value)}
            onBlur={formik.handleBlur}
            disabled={isLoading}
            invalidValue={
              !!formik.touched[cityKey] &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore Formik has the wrong type for touched object
              !!formik.errors[cityKey]?.name
            }
          >
            <option value="">Selecione a cidade</option>
            {cities?.map((option) => (
              <option key={option.id} value={option.name}>
                {option.name}
              </option>
            ))}
          </Select>
          <FormError name={`${cityKey}.name`} />
        </FormGroup>
      </FormRow>
    </>
  );
};

export default DepartureAddress;
