import styled from 'styled-components';
import { addSpacing, modifyColor } from 'utils/styles';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.five, theme.spacing.eight)};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.one};
  padding: ${({ theme }) => addSpacing(theme.spacing.four, theme.spacing.six)};
  border-radius: 16px;
  background-color: ${({ theme }) =>
    modifyColor({ hex: theme.colors.main, lightness: 0.93 })};
`;

export const HeaderText = styled.p`
  color: ${({ theme }) => theme.colors.gray.ten};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const RadioButtonsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.three};
  margin-bottom: ${({ theme }) => theme.spacing.two};
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.three};

  input,
  label {
    cursor: pointer;
    margin: 0;
    font-size: ${({ theme }) => theme.fontSize.small};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }

  input:disabled + label {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
