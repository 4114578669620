import React from 'react';

import { useNavigate } from 'react-router-dom';

import RoutesPath from '@router/routes';

import { Container, Content, Header, Logo } from './styles';

interface IPublicBaseProps {
  children: React.ReactNode;
}

const PublicBase: React.FC<IPublicBaseProps> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Header>
        <Logo
          src="/images/logo.svg"
          alt="Logo"
          onClick={() => navigate(RoutesPath.auth.login.path)}
        />
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

export default PublicBase;
