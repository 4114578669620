import { useCallback } from 'react';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const usePaymentMethods = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const listPaymentMethods = useCallback(
    async (params: IQueryParams) => {
      const response = await request<IPaginated<IPaymentMethod>>({
        method: 'get',
        url: endpoints.financial.payments.paymentMethods.list,
        headers: { scope },
        params: {
          page: params.page,
          search: params.search || undefined,
          is_active: params.isActive || undefined,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getPaymentMethod = useCallback(
    async (paymentMethodId: string) => {
      const response = await request<IPaymentMethod>({
        method: 'get',
        url: endpoints.financial.payments.paymentMethods.get.replace(
          ':paymentMethodId',
          paymentMethodId
        ),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const createPaymentMethod = useCallback(
    async (data: IPaymentMethodFormValues) => {
      const response = await request<IPaymentMethod>({
        method: 'post',
        url: endpoints.financial.payments.paymentMethods.create,
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const updatePaymentMethod = useCallback(
    async (paymentMethodId: string, data: IPaymentMethodFormValues) => {
      const response = await request<IPaymentMethod>({
        method: 'patch',
        url: endpoints.financial.payments.paymentMethods.update.replace(
          ':paymentMethodId',
          paymentMethodId
        ),
        data,
      });

      return response.data;
    },
    [request]
  );

  const updateIsActivePaymentMethodStatus = useCallback(
    async (paymentMethodId: number, isActive: boolean) => {
      const response = await request<IPaymentMethod>({
        method: 'patch',
        url: endpoints.financial.payments.paymentMethods.update.replace(
          ':paymentMethodId',
          paymentMethodId.toString()
        ),
        data: { is_active: isActive },
      });

      return response.data;
    },
    [request]
  );

  return {
    listPaymentMethods,
    getPaymentMethod,
    createPaymentMethod,
    updatePaymentMethod,
    updateIsActivePaymentMethodStatus,
  };
};

export default usePaymentMethods;
