import { addOpacityOnHexColor } from 'b2utils/dist/color';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DepartureStatus } from 'utils/enums';
import { departureStatusProps } from 'utils/helpers';
import { addSpacing } from 'utils/styles';

interface CommonStyleProps {
  status: DepartureStatus;
}

export const Container = styled(Link)<CommonStyleProps>`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  gap: ${({ theme }) => theme.spacing.one};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.four)};
  background-color: ${({ theme, status }) =>
    addOpacityOnHexColor(departureStatusProps[status].color(theme), 0.2)};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:has(svg:hover, img:hover) {
    opacity: 1;
  }
`;

export const PrimaryInfoStyle = styled.p`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const DateInfoStyle = styled(PrimaryInfoStyle)`
  color: ${({ theme }) => theme.colors.info};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const SecondaryInfoStyle = styled(DateInfoStyle)`
  color: ${({ theme }) => theme.colors.gray.nine};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.two};
  margin-top: ${({ theme }) => theme.spacing.two};
`;

export const VehicleType = styled.div<CommonStyleProps>`
  border-radius: 8px;
  width: fit-content;
  color: ${({ theme }) => theme.colors.main};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme, status }) => departureStatusProps[status].color(theme)};
  padding: ${({ theme }) => addSpacing(theme.spacing.one, theme.spacing.two)};
  background-color: ${({ theme, status }) =>
    addOpacityOnHexColor(departureStatusProps[status].color(theme), 0.2)};
`;
