import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';

import * as Sentry from '@sentry/react';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { CompanyUserRole, UserType } from 'utils/enums';

import { useAuthentication } from '@hooks';

import SplashScreen from '@components/SplashScreen';

import { useApi } from './Api';
import { useToast } from './Toast';

interface IAuthContextValues {
  userInfo?: IUser;
  authorized: boolean;
  isManagementUser: boolean;
  isCompanyUser: boolean;
  isClientUser: boolean;
  hasAdminPermission: boolean;
  login: (values: ILoginFormValues) => Promise<ILoginResponse>;
  logout: () => void;
  updateUserInfo: () => void;
  companyLogo?: IImage;
  setCompanyLogo: (logo?: IImage) => void;
}

const AuthContext = createContext<IAuthContextValues>({} as IAuthContextValues);

interface IAuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const [companyLogo, setCompanyLogo] = useState<IImage>();
  const [isLoadingInitialValues, setIsLoadingInitialValues] = useState(true);
  const [accessToken, setAccessToken] = useState<string>();

  const { performLogin, getProfile } = useAuthentication();
  const { setAuthorizationHeader, handleUnauthorizedRequest } = useApi();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const fetchUserInfo = useQuery('userInfo', getProfile, {
    onSuccess: (data) => {
      Sentry.setUser({
        id: data.id.toString(),
        email: data.email || '',
        role: data.role,
        type: data.type,
      });
    },
    onError: () => {
      addToast('Falha ao obter informações do usuário', 'error');
      logout();
    },
    onSettled: () => setIsLoadingInitialValues(false),
    staleTime: Infinity,
    enabled: !!accessToken,
  });

  const requestLogin = useMutation(
    (values: ILoginFormValues) => performLogin(values),
    {
      onSuccess: (data) => {
        if (
          data.user.role === CompanyUserRole.INSPECTOR ||
          data.user.type === UserType.DRIVER
        ) {
          addToast('Você não possui permissão', 'error');
          return;
        }

        localStorage.setItem('tokens', JSON.stringify(data.tokens));

        setAccessToken(data.tokens.access);
        setAuthorizationHeader(data.tokens.access);

        queryClient.setQueryData('userInfo', data.user);
        Sentry.setUser({
          id: data.user?.id.toString(),
          email: data.user?.email,
          role: data.user?.role,
          type: data.user?.type,
        });
      },
    }
  );

  const logout = useCallback(() => {
    setAuthorizationHeader(undefined);

    localStorage.removeItem('tokens');
    localStorage.removeItem('scope');
    localStorage.removeItem('company_name');
    localStorage.removeItem('tax_regime');
    localStorage.removeItem('digital_certificate_expiration');

    setAccessToken(undefined);
    setCompanyLogo(undefined);

    queryClient.clear();
    Sentry.setUser(null);
  }, [queryClient, setAuthorizationHeader]);

  useEffect(() => {
    handleUnauthorizedRequest(logout);
  }, [handleUnauthorizedRequest, logout]);

  useEffect(() => {
    if (accessToken) {
      return;
    }

    const tokens = localStorage.getItem('tokens');

    if (tokens) {
      const parsedTokens: ITokens = JSON.parse(tokens);

      setAccessToken(parsedTokens.access);
      setAuthorizationHeader(parsedTokens.access);
    } else {
      setIsLoadingInitialValues(false);
    }
  }, [accessToken, logout, setAuthorizationHeader]);

  return (
    <AuthContext.Provider
      value={{
        userInfo: fetchUserInfo.data,
        authorized: !!fetchUserInfo.data,
        isManagementUser: fetchUserInfo.data?.type === UserType.MANAGEMENT,
        isCompanyUser: fetchUserInfo.data?.type === UserType.COMPANY,
        isClientUser: fetchUserInfo.data?.type === UserType.CLIENT,
        hasAdminPermission:
          fetchUserInfo.data?.type === UserType.MANAGEMENT ||
          (fetchUserInfo.data?.type === UserType.COMPANY &&
            fetchUserInfo.data?.role === CompanyUserRole.ADMIN),
        updateUserInfo: fetchUserInfo.refetch,
        setCompanyLogo,
        companyLogo,
        login: requestLogin.mutateAsync,
        logout,
      }}
    >
      {isLoadingInitialValues ? <SplashScreen /> : children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
