import RoutesPath from '@router/routes';

const getRoutes = (departureId: string) => {
  return [
    {
      label: 'Listagem de embarques',
      path: RoutesPath.private.departures.listDepartures.path,
    },
    {
      label: 'Detalhes do embarque',
      path: RoutesPath.private.departures.detailDeparture.path.replace(
        ':departureId',
        departureId
      ),
    },
  ];
};

export default getRoutes;
