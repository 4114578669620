import { B2Table, IB2Table } from 'react-b2components';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

const VehiclePdfTable = styled(B2Table)`
  table {
    border-collapse: collapse;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.three};
    table-layout: fixed;
    width: 100%;
  }

  th:first-of-type {
    width: 40px;
  }

  tr:has(> th) {
    height: fit-content;
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray.three};
  }

  tr:has(> td) {
    height: fit-content;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.three};
  }

  th,
  td {
    padding: ${({ theme }) =>
      addSpacing(theme.spacing.two, theme.spacing.three)};
  }
` as <Type>(props: IB2Table<Type>) => React.ReactElement;

export default VehiclePdfTable;
