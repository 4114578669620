import styled from 'styled-components';

export const ProgressContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.one};

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const ProgressValue = styled.div<{ isCurrentValue: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme, isCurrentValue }) =>
    isCurrentValue ? theme.colors.main : theme.colors.success};
`;
