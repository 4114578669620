import React, { useCallback, useMemo, useRef, useState } from 'react';

import { useInfiniteQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { getNextPageParam } from 'utils/helpers';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useCompanies, useOutsideClick, useQueryParams } from '@hooks';

import ListFilters from '@components/ListFilters';
import Loading from '@components/Loading';

import {
  Content,
  Icon,
  Item,
  Menu,
  ScopeContainer,
  ScopeButton,
  Text,
} from './styles';

const SelectScope: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const { onSearch, queryParams } = useQueryParams({
    page: 1,
    isActive: 'true',
  });

  const { listCompaniesWithoutScope } = useCompanies();
  const { companyName, selectScope } = useScope();
  const { addToast } = useToast();
  const location = useLocation();

  const companies = useInfiniteQuery(
    ['companyScope', queryParams],
    ({ pageParam: page = 1 }) =>
      listCompaniesWithoutScope({ ...queryParams, page }),
    {
      getNextPageParam,
    }
  );

  useOutsideClick(menuRef, () => setIsOpen(false));

  const isLoading = companies.isLoading || companies.isFetchingNextPage;

  const itemList = useMemo(() => {
    return companies?.data?.pages?.flatMap((page) => page.results) || [];
  }, [companies?.data?.pages]);

  const onScroll = useCallback(
    (event: any) => {
      const element = event.target;

      if (
        !isLoading &&
        Math.ceil(element.offsetHeight + element.scrollTop) >=
          element.scrollHeight
      ) {
        companies.fetchNextPage();
      }
    },
    [companies, isLoading]
  );

  const handleSelectScope = (company?: ICompany) => {
    const checkPathName =
      location.pathname.includes('detalhes') ||
      location.pathname.includes('criar') ||
      location.pathname.includes('editar');

    if (checkPathName) {
      addToast(
        'Não é possível selecionar uma empresa estando nesta página',
        'error'
      );
      return;
    }

    selectScope(company);
    setIsOpen(false);
  };

  const renderCompanyName = useCallback(() => {
    return companyName || 'Selecione uma empresa';
  }, [companyName]);

  return (
    <>
      <ScopeButton ref={menuRef} onClick={() => setIsOpen(!isOpen)}>
        <Text>{renderCompanyName()}</Text>
        <Icon $isOpen={isOpen} />
      </ScopeButton>
      <ScopeContainer $isOpen={isOpen}>
        <Content onClick={(event) => event.stopPropagation()}>
          <ListFilters onSearch={onSearch} />
          <Menu onScroll={onScroll}>
            <Item onClick={() => handleSelectScope()}>
              <Text>Selecione uma empresa</Text>
            </Item>
            {itemList.map((company) => (
              <Item key={company.id} onClick={() => handleSelectScope(company)}>
                <Text>{company.name}</Text>
              </Item>
            ))}
            {isLoading && <Loading />}
          </Menu>
        </Content>
      </ScopeContainer>
    </>
  );
};

export default SelectScope;
