import React, { useState } from 'react';

import { FinancialReportType, ReportDetailLevel } from 'utils/enums';

import FinancialReportForm from './FinancialReportForm';
import ReportGenerationModal from './ReportGenerationModal';

interface FinancialReportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const FinancialReportModal: React.FC<FinancialReportModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [type, setType] = useState<FinancialReportType>();
  const [id, setId] = useState<string>();
  const [detailLevel, setDetailLevel] = useState<ReportDetailLevel>();

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <FinancialReportForm
        isOpen={isOpen}
        onClose={onClose}
        type={type}
        setType={setType}
        setId={setId}
        setDetailLevel={setDetailLevel}
      />
      <ReportGenerationModal
        id={id}
        onClose={() => setId(undefined)}
        type={type}
        detailLevel={detailLevel}
      />
    </>
  );
};

export default FinancialReportModal;
