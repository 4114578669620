import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.four};
  padding: ${({ theme }) => addSpacing(theme.spacing.six, theme.spacing.eight)};
`;

export const CancelImage = styled.img`
  height: 12.5rem;
`;

export const BodyMessage = styled.pre`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray.five};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  padding: ${({ theme }) => addSpacing('0', theme.spacing.eight)};
`;
