import React, { useCallback, useState } from 'react';

import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { DepartureStatus } from 'utils/enums';
import { formatDate } from 'utils/formats';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useDepartures, useQueryParams } from '@hooks';

import DepartureFilters from '@components/DepartureFilters';
import DepartureStatusLabel from '@components/DepartureStatusLabel';
import StartDepartureModal from '@components/StartDepartureModal';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import RoutesPath from '@router/routes';

import { NumberOfVehicles } from './styles';

const DeparturesList: React.FC = () => {
  const { queryParams, setQueryParams, onSearch, changePage } =
    useQueryParams<IDeparturesQueryParams>({ page: 1 });

  const [selectedDeparture, setSelectedDeparture] = useState<IDeparture>();
  const [isStartDepartureModalOpen, setIsStartDepartureModalOpen] =
    useState(false);

  const { listDepartures } = useDepartures();
  const { scope } = useScope();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const { data: departures, isLoading } = useQuery(
    ['departures', queryParams, scope],
    () => listDepartures(queryParams),
    {
      onError: () => {
        addToast('Não foi possível carregar a lista de embarques', 'error');
      },
    }
  );

  const navigateToDepartureDetailPage = useCallback(
    (departureId: number) => {
      navigate(
        RoutesPath.private.departures.detailDeparture.path.replace(
          ':departureId',
          departureId.toString()
        )
      );
    },
    [navigate]
  );

  return (
    <>
      <Tooltip id="departures-list-tooltip" />
      <DepartureFilters
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onSearch={onSearch}
        showStatusFilter
      />
      <Table
        data={departures?.results || []}
        isLoading={isLoading}
        headerData={[
          'Situação',
          'Data de saída',
          'Motorista',
          'Caminhão',
          'Fornecedor',
          'Nº veículos',
          'Origem',
          'Destino',
        ]}
        emptyMessage="Nenhum serviço encontrado"
        renderRow={(departure) => (
          <B2TableRow
            key={departure.id}
            onClick={() => navigateToDepartureDetailPage(departure.id)}
          >
            <TableDataCell>
              <DepartureStatusLabel status={departure.status} />
            </TableDataCell>
            <TableDataCell>{formatDate(departure.date)}</TableDataCell>
            <TableDataCell>{departure.driver.name}</TableDataCell>
            <TableDataCell>
              {departure.truck.vehicle_type} -{' '}
              {departure.truck.truck_license_plate}
            </TableDataCell>
            <TableDataCell>{departure.provider.name}</TableDataCell>
            <TableDataCell
              onClick={(event) => {
                event.stopPropagation();
                setSelectedDeparture(departure);
                setIsStartDepartureModalOpen(true);
              }}
            >
              <NumberOfVehicles
                data-tooltip-id="departures-list-tooltip"
                data-tooltip-content={
                  departure.status === DepartureStatus.WAITING_START
                    ? 'Iniciar embarque'
                    : 'Acompanhar serviços'
                }
              >
                {`${departure.services_count} veículo${
                  departure.services_count !== 1 ? 's' : ''
                }`}
              </NumberOfVehicles>
            </TableDataCell>
            <TableDataCell>
              {departure.origin.name}/{departure.origin.state}
            </TableDataCell>
            <TableDataCell>
              {departure.destination.name}/{departure.destination.state}
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={departures?.count}
        changePage={changePage}
      />
      {selectedDeparture && (
        <StartDepartureModal
          departure={selectedDeparture}
          isOpen={isStartDepartureModalOpen}
          onClose={() => {
            setSelectedDeparture(undefined);
            setIsStartDepartureModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default DeparturesList;
