import React from 'react';

import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useDrivers, useNavigateWithScope } from '@hooks';

import DocumentsSection from '@components/DocumentsSection';
import Loading from '@components/Loading';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import DepartureList from './DepartureList';
import DriverInfo from './DriverInfo';
import VehicleInfo from './VehicleInfo';
import { getDriverDocuments } from './driverDocuments';
import routes from './routes';
import { Content, Card } from './styles';

const DetailDriver: React.FC = () => {
  const { driverId } = useParams<{ driverId: string }>();

  const { getDriver } = useDrivers();
  const { navigateWithScope } = useNavigateWithScope();
  const { addToast } = useToast();

  const { data: driver, isLoading } = useQuery(
    ['driver', driverId],
    () => getDriver(driverId!),
    {
      onError: () => {
        addToast('Falha ao buscar os dados do motorista', 'error');
      },
      enabled: !!driverId,
    }
  );

  const isDataLoading = isLoading || !driver;

  return (
    <>
      <PageHeader
        title="Detalhes do motorista"
        routes={routes}
        editButtonProps={{
          onClick: () =>
            navigateWithScope({
              routePath: RoutesPath.private.drivers.editDriver.path.replace(
                ':driverId',
                driverId!
              ),
              company: driver?.company,
            }),
        }}
      />
      <Content>
        <Card>
          {isDataLoading ? <Loading /> : <DriverInfo driver={driver} />}
        </Card>
        {!isDataLoading && (
          <>
            <VehicleInfo truck={driver.truck} />
            <DocumentsSection data={getDriverDocuments(driver)} />
            <DepartureList driver={driver} />
          </>
        )}
      </Content>
    </>
  );
};

export default DetailDriver;
