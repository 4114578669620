import React, { forwardRef, useImperativeHandle } from 'react';

import { AxiosError } from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { UseMutationResult } from 'react-query';
import * as yup from 'yup';

import { errors } from '@utils';

import CommentForm from '../CommentForm';

interface CreateCommentProps {
  onCreate: UseMutationResult<
    IComment,
    AxiosError<unknown, any>,
    ICommentFormValues,
    unknown
  >;
}

const CreateComment: React.ForwardRefRenderFunction<
  CreateCommentRef,
  CreateCommentProps
> = ({ onCreate }, ref) => {
  const formikInitialValues: ICommentFormValues = {
    text: '',
  };

  const formikValidationSchema = yup.object().shape({
    text: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: (values) => onCreate.mutate(values),
  });

  useImperativeHandle(ref, () => ({
    onCreateSuccess: () => formik.resetForm(),
  }));

  return (
    <FormikProvider value={formik}>
      <CommentForm id="createComment" isLoading={onCreate.isLoading} />
    </FormikProvider>
  );
};

export default forwardRef(CreateComment);
