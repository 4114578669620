import { DocumentUpload } from 'iconsax-react';
import { B2Button } from 'react-b2components';
import styled from 'styled-components';

import Loading from '@components/Loading';

export const Button = styled(B2Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const Icon = styled(DocumentUpload)`
  width: ${({ theme }) => theme.spacing.six};
  height: ${({ theme }) => theme.spacing.six};
  color: ${({ theme }) => theme.colors.white.one};
`;

export const Input = styled.input`
  display: none;
`;

export const CustomLoading = styled(Loading)`
  border-top-color: ${({ theme }) => theme.colors.white.two};
`;
