import { color } from 'b2utils';
import { Bank, Moneys } from 'iconsax-react';
import { B2Card } from 'react-b2components';
import styled from 'styled-components';
import { css } from 'styled-components';

import Loading from '@components/Loading';

const iconStyles = css`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.seven};
  height: ${({ theme }) => theme.spacing.seven};
`;

export const FlexGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.three};

  & > * {
    flex: 1 1 380px;
  }
`;

export const BaseCard = styled(B2Card)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.five};
  padding: ${({ theme }) => theme.spacing.four};
`;

export const BaseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: ${({ theme }) => theme.spacing.four};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const MoneysIconWrapper = styled(BaseIconWrapper)`
  background-color: ${({ theme }) =>
    color.addOpacityOnHexColor(theme.colors.main, 0.2)};
`;

export const MoneysIcon = styled(Moneys)`
  ${iconStyles};
  color: ${({ theme }) => theme.colors.main};
`;

export const BankIconWrapper = styled(MoneysIconWrapper)`
  background-color: ${({ theme }) =>
    color.addOpacityOnHexColor(theme.colors.info, 0.2)};
`;

export const BankIcon = styled(Bank)`
  ${iconStyles};
  color: ${({ theme }) => theme.colors.info};
`;

export const CustomLoading = styled(Loading)`
  margin: 0;
`;

export const BaseCardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BaseCardTitle = styled.span`
  color: ${({ theme }) => theme.colors.gray.eleven};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const BaseCardValue = styled.span`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
