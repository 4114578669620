import { Edit } from 'iconsax-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 4px;
`;

export const FieldContainer = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.spacing.four} 0;
  gap: ${({ theme }) => theme.spacing.one};
  flex-wrap: wrap;
  align-items: center;
`;

export const FieldName = styled.label`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.black.one};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export const Description = styled.span<{ hasHighlight?: boolean }>`
  word-break: break-all;
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme, hasHighlight }) =>
    hasHighlight ? theme.colors.main : theme.colors.gray.eight};
`;

export const LinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.one};
`;

export const EditIcon = styled(Edit)`
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};
  color: ${({ theme }) => theme.colors.info};
`;
