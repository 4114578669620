import React from 'react';

import { InspectionStatus } from 'utils/enums';

import { Container, Label, Square } from './styles';

interface InspectionStatusLabelProps {
  status: InspectionStatus;
}

const InspectionStatusLabel: React.FC<InspectionStatusLabelProps> = ({
  status,
}) => {
  return (
    <Container>
      <Square status={status} />
      <Label status={status}>{status}</Label>
    </Container>
  );
};

export default InspectionStatusLabel;
