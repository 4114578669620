import React from 'react';

import { ServiceType } from 'utils/enums';

import { Container, Strong, Term } from './styles';

interface AcceptanceOfTermsProps {
  deliveryReceipt: IDeliveryReceipt;
}

const AcceptanceOfTerms: React.FC<AcceptanceOfTermsProps> = ({
  deliveryReceipt,
}) => {
  const isStorageService = deliveryReceipt.service.type === ServiceType.STORAGE;

  return (
    <Container>
      <Term>
        Declaro, sob as penas da lei, que sou o responsável legal (proprietário
        ou seu representante) pelo recebimento do veículo{' '}
        <Strong>{`${deliveryReceipt.service.vehicle.model} - ${deliveryReceipt.service.vehicle.identifier}`}</Strong>
        .
      </Term>
      <Term>
        {isStorageService ? (
          <>
            O referido automóvel, que esteve armazenado na cidade de{' '}
            <Strong>{`${deliveryReceipt.service.origin.name}/${deliveryReceipt.service.origin.state}`}</Strong>
            , está sendo recebido nesta data
          </>
        ) : (
          <>
            O referido automóvel, que saiu de cidade de{' '}
            <Strong>{`${deliveryReceipt.service.origin.name}/${deliveryReceipt.service.origin.state}`}</Strong>
            , está sendo recebido nesta data, na cidade de{' '}
            <Strong>{`${deliveryReceipt.service.destination?.name}/${deliveryReceipt.service.destination?.state}`}</Strong>
          </>
        )}
        , onde foi regularmente conferido por mim, junto ao(à) preposto(a) ou
        parceiro(a) da <Strong>{deliveryReceipt.service.company.name}</Strong>,
        tendo sido constatado o perfeito estado do veículo, em absoluta
        conformidade com a vistoria inicial nº{' '}
        <Strong>{`${deliveryReceipt.service.number}`}</Strong>.
      </Term>
      <Term>
        Declaro, por fim, que, após a minuciosa análise do automóvel, o que
        tiver a reclamar, o farei no campo “observações” abaixo, de modo que,
        não tendo nada a apontar, o deixarei em branco, ciente de que a{' '}
        <Strong>{deliveryReceipt.service.company.name}</Strong> não aceitará
        reclamações posteriores, isto é, não poderá ser responsabilizada por
        eventuais avarias no veículo, nos termos do artigo 9º, parágrafo único,
        da Lei nº 11.442/2007.
      </Term>
    </Container>
  );
};

export default AcceptanceOfTerms;
