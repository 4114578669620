import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';

import { ClickableText, Container, Text } from './styles';

interface SelectAllSectionProps {
  services?: IPaginated<IService>;
  isAllServicesInThePageSelected: boolean;
}

const SelectAllSection: React.FC<SelectAllSectionProps> = ({
  services,
  isAllServicesInThePageSelected,
}) => {
  const formik = useFormikContext<IFinishDepartureValues>();

  const { servicesSelectedPerPage, isAllServicesSelected } = formik.values;

  const selectedServicesCount = useMemo(() => {
    const servicesSelectedInPages: Array<Array<number>> = Object.values(
      servicesSelectedPerPage
    );
    const servicesSelected = servicesSelectedInPages.flat();
    return servicesSelected.length;
  }, [servicesSelectedPerPage]);

  const servicesTotalCount = services?.count || 0;

  const servicesInPageCount = services?.results.length || 0;

  const handleSelectAllServices = () => {
    const pages = Object.keys(servicesSelectedPerPage);
    if (isAllServicesSelected) {
      const newServicesSelectedPerPage = pages.reduce((previous, page) => {
        return {
          ...previous,
          [page]: [],
        };
      }, {});
      formik.setFieldValue(
        'servicesSelectedPerPage',
        newServicesSelectedPerPage
      );
      formik.setFieldValue('isAllServicesSelected', false);
    } else {
      const newServicesSelectedPerPage = pages.reduce((previous, page) => {
        return {
          ...previous,
          [page]: services?.results.map((service) => service.id) || [],
        };
      }, {});
      formik.setFieldValue(
        'servicesSelectedPerPage',
        newServicesSelectedPerPage
      );
      formik.setFieldValue('isAllServicesSelected', true);
    }
  };

  const shouldRenderSelectAllSection =
    servicesInPageCount < servicesTotalCount &&
    (isAllServicesInThePageSelected || isAllServicesSelected);

  if (!shouldRenderSelectAllSection) {
    return null;
  }

  return (
    <Container>
      {isAllServicesSelected ? (
        <Text>
          Todos os {servicesTotalCount} serviços estão selecionados.
          <ClickableText onClick={handleSelectAllServices}>
            Limpar seleção
          </ClickableText>
        </Text>
      ) : (
        <Text>
          Todos os serviços desta página estão selecionados (
          {selectedServicesCount} selecionados no total).
          <ClickableText onClick={handleSelectAllServices}>
            Selecionar todos os {servicesTotalCount} serviços disponíveis
          </ClickableText>
        </Text>
      )}
    </Container>
  );
};

export default SelectAllSection;
