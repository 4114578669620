import { B2Card } from 'react-b2components';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

interface MessageProps {
  hasError: boolean;
}

export const Card = styled(B2Card)<MessageProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.four};
  margin: 0 10%;
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.five, theme.spacing.eight)};
  color: ${({ theme, hasError }) =>
    hasError ? theme.colors.error : theme.colors.success};

  svg {
    width: ${({ theme }) => theme.fontSize.big};
    height: ${({ theme }) => theme.fontSize.big};
  }
`;

export const Message = styled.span`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.big};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
