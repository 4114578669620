import React, { useCallback } from 'react';

import { color } from 'b2utils';
import { useInfiniteQuery } from 'react-query';
import { useTheme } from 'styled-components';
import { ServiceLogActionMap, ServiceStatus } from 'utils/enums';
import { getNextPageParam, serviceStatusProps } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useServices } from '@hooks';

import CommonCard from '@components/CommonCard';
import Loading from '@components/Loading';
import Log from '@components/Log';
import TimeLineCard from '@components/TimeLineCard';

interface LogCardProps {
  serviceId: number;
}

const LogCard: React.FC<LogCardProps> = ({ serviceId }) => {
  const { listServiceLogs } = useServices();
  const { addToast } = useToast();
  const theme = useTheme();

  const queryParams: IServiceLogQueryParams = {
    pageSize: 5,
    service: serviceId,
  };

  const fetchLogs = useInfiniteQuery(
    ['serviceLogs', queryParams],
    ({ pageParam: page = 1 }) => listServiceLogs({ page, ...queryParams }),
    {
      onError: () => {
        addToast('Não foi possível carregar os logs do embarque', 'error');
      },
      getNextPageParam,
    }
  );

  const logsList = fetchLogs.data?.pages?.flatMap((page) => page.results) || [];

  const getBackgroundColor = useCallback(
    (status: ServiceStatus | null) =>
      color.addOpacityOnHexColor(
        serviceStatusProps[status || ServiceStatus.WAITING_COLLECTION].color(
          theme
        ),
        0.3
      ),
    [theme]
  );

  return (
    <CommonCard title="Histórico">
      {fetchLogs.isLoading ? (
        <Loading />
      ) : (
        <TimeLineCard
          items={logsList}
          renderItem={(log) => (
            <Log
              log={log}
              logActionMap={ServiceLogActionMap[log.action]}
              backgroundColor={getBackgroundColor(log.new_value)}
            />
          )}
          infiniteQueryProps={{
            hasNextPage: fetchLogs.hasNextPage,
            fetchNextPage: fetchLogs.fetchNextPage,
            isFetchingNextPage: fetchLogs.isFetchingNextPage,
          }}
        />
      )}
    </CommonCard>
  );
};

export default LogCard;
