import { color } from 'b2utils';
import { TruckFast } from 'iconsax-react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.four};
`;

export const TruckFastIcon = styled(TruckFast)`
  width: ${({ theme }) => theme.spacing.ten};
  height: ${({ theme }) => theme.spacing.ten};
  padding: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.white.main};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.main};
  flex-shrink: 0;
  outline: ${({ theme }) =>
    `${theme.spacing.one} solid ${color.addOpacityOnHexColor(
      theme.colors.main,
      0.2
    )}`};
`;

export const ProgressTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ProgressText = styled.span`
  color: ${({ theme }) => theme.colors.black.two};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.one};
`;

export const ProgressStatusItem = styled.div<{ isCurrent: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.colors.main : theme.colors.success};
`;
