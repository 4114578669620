import React, { useMemo } from 'react';

import { useAuth } from '@contexts/Auth';

import { sidebarData } from '../sidebarData';
import SideBarItem from './SideBarItem';
import {
  Divider,
  SideBarArrow,
  SideBarCollapsedContainer,
  SideBarSessionTitle,
  SidebarContent,
} from './styles';

interface SideBarProps {
  collapsedSideBar: boolean;
  setCollapsedSideBar: (collapsedSideBar: boolean) => void;
}

const SideBar: React.FC<SideBarProps> = ({
  collapsedSideBar,
  setCollapsedSideBar,
}) => {
  const { userInfo } = useAuth();

  const sideBarItems: Array<ISidebarSection> = useMemo(
    () => (userInfo ? sidebarData(userInfo) : []),
    [userInfo]
  );

  return (
    <>
      <SideBarArrow
        $isCollapsed={collapsedSideBar}
        onClick={() => {
          setCollapsedSideBar(!collapsedSideBar);
        }}
      />
      <SidebarContent $isCollapsed={collapsedSideBar}>
        {sideBarItems.map(({ label, sideBarItems }, index) => (
          <SideBarCollapsedContainer
            key={label}
            $isCollapsed={collapsedSideBar}
          >
            {collapsedSideBar ? (
              <SideBarSessionTitle>{label}</SideBarSessionTitle>
            ) : (
              <>{index !== 0 && <Divider />}</>
            )}
            {sideBarItems.map(({ icon, name, route }, index) => (
              <SideBarItem
                key={name}
                index={index}
                icon={icon}
                name={name}
                route={route}
                isCollapsed={collapsedSideBar}
              />
            ))}
          </SideBarCollapsedContainer>
        ))}
      </SidebarContent>
    </>
  );
};

export default SideBar;
