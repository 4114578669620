import React, { useMemo } from 'react';

import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import { useTheme } from 'styled-components';
import { CountMetricType } from 'utils/enums';
import { countMetricTypeProps } from 'utils/helpers';

import { Container, CustomReactApexChart } from './styles';

interface LineChartProps {
  series: ApexAxisChartSeries;
}

const LineChart: React.FC<LineChartProps> = ({ series }) => {
  const theme = useTheme();

  const apexOptions: ApexOptions = useMemo(() => {
    const commonTextStyle = {
      fontFamily: theme.fontFamily,
      colors: theme.colors.gray.seven,
      fontSize: theme.fontSize.smaller,
      fontWeight: theme.fontWeight.regular,
    };

    return {
      chart: {
        type: 'line',
        zoom: {
          autoScaleYaxis: true,
          type: 'xy',
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          style: commonTextStyle,
          formatter: (_, timestamp) => moment(timestamp).format('MMM/YYYY'),
        },
      },
      yaxis: {
        labels: {
          offsetX: -16,
          style: commonTextStyle,
          formatter: (value) => String(value),
        },
      },
      colors: [
        countMetricTypeProps[CountMetricType.QUOTATIONS].color(theme),
        countMetricTypeProps[CountMetricType.SERVICES].color(theme),
        countMetricTypeProps[CountMetricType.DEPARTURES].color(theme),
        countMetricTypeProps[CountMetricType.INSPECTIONS].color(theme),
      ],
      stroke: {
        width: 4,
        curve: 'smooth',
      },
      markers: {
        size: 8,
      },
      tooltip: {
        style: commonTextStyle,
        marker: {
          show: false,
        },
        y: {
          formatter: (value) => String(value),
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        offsetY: 16,
        fontFamily: commonTextStyle.fontFamily,
        fontWeight: commonTextStyle.fontWeight,
        fontSize: '12',
        horizontalAlign: 'center',
        position: 'top',
        labels: {
          colors: commonTextStyle.colors,
        },
        markers: {
          radius: 4,
          offsetX: -4,
        },
        itemMargin: {
          horizontal: 12,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
      responsive: [
        {
          breakpoint: 550,
          options: {
            chart: {
              width: 600,
            },
          },
        },
      ],
      noData: {
        text: 'Sem dados para exibir no momento',
        align: 'center',
        verticalAlign: 'middle',
        offsetY: 0,
        style: commonTextStyle,
      },
    };
  }, [theme]);

  return (
    <Container>
      <CustomReactApexChart options={apexOptions} series={series} />
    </Container>
  );
};

export default LineChart;
