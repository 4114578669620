import { useCallback } from 'react';

import regex from 'utils/regex';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const generateBankAccountRequestObject = (
  bankAccount: IBankAccountFormValues
) => {
  return {
    name: bankAccount.name,
    initial_balance: bankAccount.initialBalance.replace(regex.onlyNumbers, ''),
    type: bankAccount.type,
    is_main: bankAccount.isMain,
  };
};

const useBankAccounts = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const listBankAccounts = useCallback(
    async (params: IBankAccountQueryParams) => {
      const response = await request<IPaginatedBankAccount>({
        method: 'get',
        url: endpoints.financial.payments.bankAccounts.list,
        headers: { scope },
        params: {
          page: params.page,
          search: params.search || undefined,
          is_active: params.isActive || undefined,
          type: params?.type || undefined,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getBankAccount = useCallback(
    async (bankAccountId: string) => {
      const response = await request<IBankAccount>({
        method: 'get',
        url: endpoints.financial.payments.bankAccounts.get.replace(
          ':bankAccountId',
          bankAccountId
        ),
        headers: { scope },
      });
      return response.data;
    },
    [request, scope]
  );

  const createBankAccount = useCallback(
    async (values: IBankAccountFormValues) => {
      const response = await request<IBankAccount>({
        method: 'post',
        url: endpoints.financial.payments.bankAccounts.create,
        headers: { scope },
        data: generateBankAccountRequestObject(values),
      });

      return response.data;
    },
    [request, scope]
  );

  const updateBankAccount = useCallback(
    async (bankAccountId: string, values: IBankAccountFormValues) => {
      const response = await request<IBankAccount>({
        method: 'patch',
        url: endpoints.financial.payments.bankAccounts.update.replace(
          ':bankAccountId',
          bankAccountId
        ),
        headers: { scope },
        data: generateBankAccountRequestObject(values),
      });
      return response.data;
    },
    [request, scope]
  );

  const updateMainBankAccount = useCallback(
    async (bankAccountId: number) => {
      const response = await request<IBankAccount>({
        method: 'patch',
        url: endpoints.financial.payments.bankAccounts.update.replace(
          ':bankAccountId',
          bankAccountId.toString()
        ),
        data: { is_main: true },
      });

      return response.data;
    },
    [request]
  );

  const updateIsActiveBankAccountStatus = useCallback(
    async (bankAccountId: number, isActive: boolean) => {
      const response = await request<IBankAccount>({
        method: 'patch',
        url: endpoints.financial.payments.bankAccounts.update.replace(
          ':bankAccountId',
          bankAccountId.toString()
        ),
        data: { is_active: isActive },
      });

      return response.data;
    },
    [request]
  );

  return {
    listBankAccounts,
    createBankAccount,
    getBankAccount,
    updateBankAccount,
    updateMainBankAccount,
    updateIsActiveBankAccountStatus,
  };
};

export default useBankAccounts;
