import RoutesPath from '@router/routes';

const getRoutes = (providerId: string) => {
  return [
    {
      path: RoutesPath.private.providers.listProviders.path,
      label: 'Fornecedores',
    },
    {
      path: RoutesPath.private.providers.detailProvider.path.replace(
        ':providerId',
        providerId
      ),
      label: 'Detalhes do fornecedor',
    },
    {
      path: RoutesPath.private.providers.editProvider.path.replace(
        ':providerId',
        providerId
      ),
      label: 'Editar fornecedor',
    },
  ];
};

export default getRoutes;
