import React, { useState, useCallback, useEffect } from 'react';

import { nanoid } from 'nanoid';
import { B2Toast, IToast } from 'react-b2components';
import { useNavigate } from 'react-router-dom';
import { UserType } from 'utils/enums';
import { formatToBrlDate } from 'utils/formats';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';

import RoutesPath from '@router/routes';

const TEN_DAYS_IN_MILLISECONDS = 10 * 24 * 60 * 60 * 1000;

const DigitalCertificateExpirationToast: React.FC = () => {
  const [toast, setToast] = useState<IToast | undefined>();

  const { authorized, userInfo } = useAuth();
  const { scope, digitalCertificateExpiration } = useScope();
  const navigate = useNavigate();

  const isCompanyUser = userInfo?.type === UserType.COMPANY;

  const setToastMessage = useCallback(
    (digitalCertificateExpiration: string | null) => {
      if (!digitalCertificateExpiration) {
        setToast(undefined);
        return;
      }

      const expirationDate = new Date(digitalCertificateExpiration);
      const noticeStartDate = new Date(
        expirationDate.getTime() - TEN_DAYS_IN_MILLISECONDS
      );
      const todaysDate = new Date();

      if (todaysDate >= noticeStartDate) {
        setToast({
          id: nanoid(),
          text: `O certificado digital da sua empresa irá se expirar no dia ${formatToBrlDate(
            expirationDate
          )}`,
          type: 'error',
        });
      } else {
        setToast(undefined);
      }
    },
    []
  );

  useEffect(() => {
    if (isCompanyUser) {
      setToastMessage(userInfo?.company.digital_certificate_expiration);
    } else {
      setToastMessage(digitalCertificateExpiration);
    }
  }, [digitalCertificateExpiration, isCompanyUser, setToastMessage, userInfo]);

  const handleToastButtonClick = useCallback(() => {
    if (userInfo && isCompanyUser) {
      navigate(
        RoutesPath.private.companies.updateCompany.path.replace(
          ':companyId',
          userInfo.company.id.toString()
        )
      );
    } else {
      navigate(
        RoutesPath.private.companies.updateCompany.path.replace(
          ':companyId',
          scope?.toString() || ''
        )
      );
    }
    setToast(undefined);
  }, [isCompanyUser, navigate, scope, userInfo]);

  if (!authorized) {
    return null;
  }

  return (
    <B2Toast
      list={toast ? [toast] : []}
      remove={() => setToast(undefined)}
      buttonProps={{
        text: 'Editar dados da minha empresa',
        onClick: handleToastButtonClick,
      }}
    />
  );
};

export default DigitalCertificateExpirationToast;
