import styled from 'styled-components';

export const NumberOfVehicles = styled.span`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
