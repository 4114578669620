import React, { useState } from 'react';

import { currency } from 'b2utils';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ServiceType, VehicleDocumentType } from 'utils/enums';
import { getServiceTakerType } from 'utils/helpers';
import regex from 'utils/regex';

import { useToast } from '@contexts/Toast';
import { useServices, useVerifyScope } from '@hooks';

import Loading from '@components/Loading';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import OwnService from '../FormSteps/OwnService';
import StorageService from '../FormSteps/StorageService';
import ThirdPartyService from '../FormSteps/ThirdPartyService';
import getRoutes from './routes';

const UpdateService: React.FC = () => {
  const { serviceId } = useParams<{ serviceId: string }>();
  const { state }: { state: IService | undefined } = useLocation();

  const [selectedServiceType, setSelectedServiceType] = useState(state?.type);

  const { updateService, getService } = useServices();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useVerifyScope({
    routePath: RoutesPath.private.services.listServices.path,
    toastErrorMessage: 'Selecione uma empresa para editar um serviço',
  });

  const { data: service, isLoading } = useQuery(
    ['service', serviceId],
    () => getService(serviceId!),
    {
      onSuccess: (data) => {
        setSelectedServiceType(data.type);
      },
      onError: () => {
        addToast('Erro ao carregar serviço', 'error');
        navigate(
          RoutesPath.private.services.detailService.path.replace(
            ':serviceId',
            serviceId!
          ),
          {
            replace: true,
          }
        );
      },
      enabled: !state,
      placeholderData: state,
    }
  );

  const collectionDate = moment(service?.collection_date);
  const estimatedCollectionDate = moment(service?.estimated_collection_date);

  const editServiceRequest = useMutation(
    (values: IServiceFormValues) => {
      const newCollectionDate = moment(values.collectionDate);
      const hasCollectionDateChanged =
        !newCollectionDate.isSame(collectionDate);

      const newEstimatedCollectionDate = moment(values.estimatedCollectionDate);
      const hasEstimatedCollectionDateChanged =
        !newEstimatedCollectionDate.isSame(estimatedCollectionDate);

      const isStorageService = selectedServiceType === ServiceType.STORAGE;

      const newService: IEditServiceFormValues = {
        ...values,
        collectionDate:
          isStorageService && hasCollectionDateChanged
            ? values.collectionDate
            : undefined,
        estimatedCollectionDate:
          !isStorageService && hasEstimatedCollectionDateChanged
            ? values.estimatedCollectionDate
            : undefined,
      };

      return updateService(Number(serviceId), newService);
    },
    {
      onSuccess: () => {
        addToast('Serviço editado com sucesso', 'success');
        navigate(
          RoutesPath.private.services.detailService.path.replace(
            ':serviceId',
            serviceId!
          )
        );
      },
      onError: () => {
        addToast('Erro ao editar serviço', 'error');
      },
    }
  );

  if (isLoading || !service) {
    return <Loading />;
  }

  const isAnotherDocumentType = !Object.values(VehicleDocumentType).includes(
    service.vehicle.document_type as VehicleDocumentType
  );

  const initialValues: IServiceInitialValuesObject = {
    [ServiceType.OWN]: {
      type: service.type,
      category: service.category!,
      personInCharge: service.person_in_charge,
      estimatedCollectionDate: service.estimated_collection_date || '',
      estimatedMinDaysForDelivery:
        service.estimated_min_days_for_delivery?.toString() || '',
      estimatedMaxDaysForDelivery:
        service.estimated_max_days_for_delivery?.toString() || '',
      sender: service.sender || ({ id: 0 } as IClientUser),
      collectionType: service.collection_type!,
      collectionAddressId: service.collection_address?.id || 0,
      recipient: service.recipient || ({ id: 0 } as IClientUser),
      deliveryType: service.delivery_type!,
      deliveryAddressId: service.delivery_address?.id || 0,
      cfop: service.cfop!,
      icmsType: service.icms_type!,
      icmsRate: service.icms_rate?.toString() || '',
      icmsReduction: service.icms_reduction?.toString() || '',
      optingForCreditGranted: service.opting_for_credit_granted ?? false,
      cteFreightCost: currency.brlMask(
        service.cte_freight_cost?.toString().replace(regex.onlyNumbers, '') ||
          ''
      ),
      cteGeneralObservations: service.cte_general_observations || '',
      value: currency.brlMask(service.value.toString()),
      paymentMethod:
        service.income_payment_method || ({ id: 0 } as IPaymentMethod),
      paymentTerm: service.payment_terms || ({ id: 0 } as IPaymentTerm),
      destination: service.destination || { id: 0, name: '', state: '' },
      origin: service.origin,
      document: service.vehicle.document || '',
      vehicleType: service.vehicle.type,
      documentType: isAnotherDocumentType
        ? VehicleDocumentType.ANOTHER
        : (service.vehicle.document_type as VehicleDocumentType),
      model: service.vehicle.model,
      identifier: service.vehicle.identifier,
      documentTypeValue: isAnotherDocumentType
        ? (service.vehicle.document_type as VehicleDocumentType)
        : '',
      year: service.vehicle.year?.toString() || '',
      client: service.client,
      clientAddressId: service.client_address?.id || 0,
      insuranceValue: currency.brlMask(
        service.vehicle.insurance_value.toString()
      ),
      takerType: getServiceTakerType(service),
      redeemable: service.inspection_is_redeemable,
      notifyClient: service.notify_client,
    },
    [ServiceType.THIRD_PARTY]: {
      type: service.type,
      personInCharge: service.person_in_charge,
      client: service.client,
      clientAddressId: service.client_address?.id || 0,
      value: currency.brlMask(service.value.toString()),
      paymentTerm: service.payment_terms || ({ id: 0 } as IPaymentTerm),
      paymentMethod:
        service.income_payment_method || ({ id: 0 } as IPaymentMethod),
      origin: service.origin,
      destination: service.destination || { id: 0, name: '', state: '' },
      cteAccessKey: service.cte_access_key || '',
      estimatedCollectionDate: service.estimated_collection_date || '',
      vehicleType: service.vehicle.type,
      model: service.vehicle.model,
      identifier: service.vehicle.identifier,
      year: service.vehicle.year?.toString() || '',
      insuranceValue: currency.brlMask(
        service.vehicle.insurance_value.toString()
      ),
      redeemable: service.inspection_is_redeemable,
      notifyClient: service.notify_client,
    },
    [ServiceType.STORAGE]: {
      type: service.type,
      personInCharge: service.person_in_charge,
      client: service.client,
      clientAddressId: service.client_address?.id || 0,
      value: currency.brlMask(service.value.toString()),
      paymentMethod:
        service.income_payment_method || ({ id: 0 } as IPaymentMethod),
      paymentTerm: service.payment_terms || ({ id: 0 } as IPaymentTerm),
      origin: service.origin,
      collectionDate: service.collection_date || '',
      vehicleType: service.vehicle.type,
      model: service.vehicle.model,
      identifier: service.vehicle.identifier,
      year: service.vehicle.year?.toString() || '',
      insuranceValue: currency.brlMask(
        service.vehicle.insurance_value.toString()
      ),
      redeemable: service.inspection_is_redeemable,
      notifyClient: service.notify_client,
    },
  };

  const serviceBaseForm = {
    [ServiceType.OWN]: (
      <OwnService
        onFinish={editServiceRequest}
        initialValues={initialValues[selectedServiceType!]}
      />
    ),
    [ServiceType.STORAGE]: (
      <StorageService
        onFinish={editServiceRequest}
        initialValues={initialValues[selectedServiceType!]}
      />
    ),
    [ServiceType.THIRD_PARTY]: (
      <ThirdPartyService
        onFinish={editServiceRequest}
        initialValues={initialValues[selectedServiceType!]}
      />
    ),
  };

  return (
    <>
      <PageHeader
        title={`Editar serviço ${service?.number || ''}`}
        subtitle={`Editar serviço do tipo ${service.type}`}
        routes={getRoutes(serviceId!)}
      />
      {selectedServiceType && serviceBaseForm[selectedServiceType]}
    </>
  );
};

export default UpdateService;
