import { color } from 'b2utils';
import styled, { css } from 'styled-components';

interface ButtonProps {
  disabled: boolean;
}

export const Container = styled.div<ButtonProps>`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 8px;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  background-color: ${({ theme }) =>
    color.addOpacityOnHexColor(theme.colors.error, 0.1)};
  color: ${({ theme }) => theme.colors.error};
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    width: ${({ theme }) => theme.fontSize.medium};
  }

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.7;
          cursor: not-allowed;
          &:active {
            transform: scale(1);
          }
        `
      : css`
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        `};
`;
