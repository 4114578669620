import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { ServiceStatus } from 'utils/enums';
import * as yup from 'yup';

import { useServices } from '@hooks';

import FormRow from '@components/FormRow';
import SelectItems from '@components/SelectItems';

import RoutesPath from '@router/routes';
import { errors } from '@utils';

interface IDepartureServiceListProps {
  initialValues?: IDepartureServiceListStepForm;
  onFinish: (data: IDepartureServiceListStepForm) => void;
}

const ServiceList: React.ForwardRefRenderFunction<
  IFormStepRef,
  IDepartureServiceListProps
> = ({ onFinish, initialValues }, ref) => {
  const { departureId } = useParams<{ departureId: string }>();

  const { listServices } = useServices();

  const servicesQueryParams: IServicesQueryParams = {
    status: ServiceStatus.WAITING_DEPARTURE,
    linkedToDepartureOrUnlinked: Number(departureId) || undefined,
  };

  const formikInitialValues: IDepartureServiceListStepForm = {
    services: [],
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    services: yup.array().min(1, errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  const handleServiceCheckboxClick = (selectedService: IService) => {
    const isChecked = formik.values.services.some(
      (service) => service.id === selectedService.id
    );

    if (isChecked) {
      const filteredOptions = formik.values.services.filter(
        (service) => service.id !== selectedService.id
      );
      formik.setFieldValue('services', filteredOptions);
      return;
    }

    const newOptions = [...formik.values.services, selectedService];
    formik.setFieldValue('services', newOptions);
  };

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <SelectItems
          fetch={listServices}
          queryParams={servicesQueryParams}
          queryKey="services"
          inputLabel="Selecionar veículos *"
          selectedItemLabel="Veículos selecionado"
          formikErrorName="services"
          onSelect={(service) => handleServiceCheckboxClick(service)}
          selectedItems={formik.values.services}
          renderItemText={(service) =>
            `Serviço ${service.number}: ${service.vehicle.model} - ${service.vehicle.identifier}`
          }
          itemRoutePath={(service) =>
            RoutesPath.private.services.detailService.path.replace(
              ':serviceId',
              service.id.toString()
            )
          }
        />
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(ServiceList);
