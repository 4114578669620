import React from 'react';

import moment from 'moment';
import { ContractStatusMap, SendingStatus } from 'utils/enums';
import { toISOStringWithTimezone } from 'utils/helpers';

import { useCompanyUsers } from '@hooks';

import ChooseItemFilter from '@components/ChooseItemFilter';
import DateRangeCalendarFilter from '@components/DateRangeCalendarFilter';
import ExpandableFilter from '@components/ExpandableFilter';
import ListFilters from '@components/ListFilters';
import Select from '@components/Select';

import { Container } from './styles';

interface FiltersProps {
  queryParams: IServiceContractQueryParams;
  setQueryParams: React.Dispatch<
    React.SetStateAction<IServiceContractQueryParams>
  >;
  onSearch: (value: string) => void;
}

const Filters: React.FC<FiltersProps> = ({
  queryParams,
  setQueryParams,
  onSearch,
}) => {
  const { listCompanyUsers } = useCompanyUsers();

  const emptyPersonInCharge = {
    id: 0,
    name: 'Todos',
  } as ICompanyUser;

  const sendingStatuses = Object.values(SendingStatus);

  const handleSelectPersonInCharge = (personInCharge: ICompanyUser) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      personInCharge,
    }));
  };

  const handleSelectSendingStatus = (status: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      status: status ? (status as SendingStatus) : undefined,
    }));
  };

  const handleUploadedDateRangeFilterSelected = (
    startDate?: Date,
    endDate?: Date
  ) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      uploadedAtGte: startDate ? toISOStringWithTimezone(startDate) : undefined,
      uploadedAtLte: endDate ? toISOStringWithTimezone(endDate) : undefined,
    }));
  };

  const handleCreatedDateRangeFilterSelected = (
    startDate?: Date,
    endDate?: Date
  ) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      createdAtGte: startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : undefined,
      createdAtLte: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
    }));
  };

  return (
    <Container>
      <ListFilters
        searchPlaceholder="Buscar por nº do serviço ou veículo"
        onSearch={onSearch}
      >
        <DateRangeCalendarFilter
          label="Filtrar por data de assinatura"
          clearableDateRange
          onDateRangeFilterSelected={handleUploadedDateRangeFilterSelected}
        />
        <DateRangeCalendarFilter
          label="Filtrar por data de criação do serviço"
          clearableDateRange
          onDateRangeFilterSelected={handleCreatedDateRangeFilterSelected}
        />
      </ListFilters>
      <ExpandableFilter>
        <ChooseItemFilter
          filterValue={
            queryParams?.personInCharge?.id &&
            queryParams?.personInCharge?.id !== 0
              ? queryParams?.personInCharge?.name
              : 'Responsáveis: todos'
          }
          modalTitle="Filtrar serviços por responsável"
          fetch={listCompanyUsers}
          queryParams={{ isActive: 'true', canBeInChargeOfServices: true }}
          queryKey="companyUsers"
          inputLabel="Selecionar responsável *"
          selectedItemLabel="Responsável selecionado"
          shouldRenderSelectedItem
          onSelect={handleSelectPersonInCharge}
          selectedItem={queryParams.personInCharge || emptyPersonInCharge}
          additionalItem={emptyPersonInCharge}
          renderItemText={(personInCharge) => personInCharge.name}
        />
        <Select
          value={queryParams?.status || ''}
          onChange={(event) =>
            handleSelectSendingStatus(event.target.value as SendingStatus)
          }
        >
          <option value="">Situação do contrato: todas</option>
          {sendingStatuses.map((status) => (
            <option key={status} value={status}>
              {ContractStatusMap[status]}
            </option>
          ))}
        </Select>
      </ExpandableFilter>
    </Container>
  );
};

export default Filters;
