import ReactApexChart from 'react-apexcharts';
import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`;

export const CustomReactApexChart = styled(ReactApexChart)`
  margin: 0 auto;
  max-width: 700px;
  width: 90%;

  div > span {
    cursor: pointer;
  }
`;
