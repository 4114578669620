import { color } from 'b2utils';
import { B2Button, B2Card } from 'react-b2components';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

import { baseContainerAnimation } from '@components/Base/PrivateBase/styles';
import FormGroup from '@components/FormGroup';

interface InputGroupProps {
  checked: boolean;
}

export const Container = styled(B2Card)`
  margin-bottom: 4rem;
  animation: ${baseContainerAnimation} 0.5s ease-in-out;
  padding: ${({ theme }) => theme.spacing.six};
`;

export const CustomFormGroup = styled(FormGroup)`
  margin: 0;
  align-items: center;
`;

export const RadioButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.four};
  padding-top: ${({ theme }) => theme.spacing.five};
`;

export const InputGroup = styled.div<InputGroupProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 140px;
  max-width: 360px;
  gap: ${({ theme }) => theme.spacing.three};
  padding: ${({ theme }) => theme.spacing.three};
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: all 0.5s ease-in-out;

  &,
  label {
    cursor: pointer;
  }

  background-color: ${({ theme, checked }) =>
    checked
      ? color.addOpacityOnHexColor(theme.colors.main, 0.3)
      : theme.colors.white.three};
`;

export const IconGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.main};

  img {
    width: ${({ theme }) => theme.spacing.ten};
    height: ${({ theme }) => theme.spacing.ten};
  }
`;

export const Label = styled.label`
  margin-bottom: ${({ theme }) => theme.spacing.eight};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.twelve)};
  box-sizing: border-box;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.white.one};
`;

export const Button = styled(B2Button)`
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.two, theme.spacing.twelve)};
`;
