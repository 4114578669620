import React, { forwardRef, useImperativeHandle } from 'react';

import { cpf, phone } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import UserForm from '@components/UserForm';

import { enums, errors } from '@utils';

interface ICompanyUserFormProps {
  initialValues?: ICompanyUserFormValues;
  onFinish: (data: ICompanyUserFormValues) => void;
}

const CompanyUserForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  ICompanyUserFormProps
> = ({ onFinish, initialValues }, ref) => {
  const formikInitialValues: ICompanyUserFormValues = {
    avatar: null,
    name: '',
    email: '',
    document: '',
    phone: '',
    role: undefined,
    ...initialValues,
  };

  const validationSchema = yup.object().shape({
    name: yup.string().trim().required(errors.required),
    email: yup.string().trim().email(errors.email).required(errors.required),
    document: yup
      .string()
      .trim()
      .required(errors.required)
      .test(
        'is-cpf-valid',
        errors.cpf,
        (value) => !!value && cpf.validate(value)
      ),
    phone: yup
      .string()
      .trim()
      .test('is-phone-valid', errors.phone, (value) => {
        const isFieldEmpty = !value?.length;

        if (isFieldEmpty) {
          return true;
        }

        return phone.validate(value);
      }),
    role: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <UserForm
        isCompanyUserForm
        roles={Object.values(enums.CompanyUserRole)}
      />
    </FormikProvider>
  );
};

export default forwardRef(CompanyUserForm);
