import React from 'react';

import { FormikProvider, useFormik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';

import { useToast } from '@contexts/Toast';
import { useQuotations } from '@hooks';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import ModalFooterButtons from '@components/ModalFooterButtons';

import { errors } from '@utils';

import {
  Header,
  HeaderText,
  InputGroup,
  ModalContent,
  RadioButtonsContainer,
} from './styles';

interface FinalizeQuotationModalProps
  extends Pick<IBaseDeleteModalProps, 'isOpen' | 'onClose'> {
  quotation: IQuotation;
  onFinalizeSuccess: () => void;
}

const FinalizeQuotationModal: React.FC<FinalizeQuotationModalProps> = ({
  isOpen,
  onClose,
  quotation,
  onFinalizeSuccess,
}) => {
  const { finalizeQuotation } = useQuotations();
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const finalizeQuotationRequest = useMutation(
    (values: IFinalizeQuotationFormValues) =>
      finalizeQuotation(quotation.id, values),
    {
      onSuccess: () => {
        addToast('Orçamento finalizado com sucesso', 'success');
        onFinalizeSuccess();
        queryClient.refetchQueries(['quotation', quotation.id.toString()]);
        onClose();
      },
      onError: () => {
        addToast('Erro ao finalizar orçamento', 'error');
      },
    }
  );

  const formikInitialValues: IFinalizeQuotationFormValues = {
    finishedAt: '',
    isLost: false,
    lostReason: '',
  };

  const formikValidationSchema = yup.object().shape({
    finishedAt: yup.string().trim().required(errors.required),
    isLost: yup.boolean(),
    lostReason: yup
      .string()
      .trim()
      .when('isLost', {
        is: true,
        then: (schema) => schema.required(errors.required),
      }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: (values) => finalizeQuotationRequest.mutate(values),
  });

  return (
    <CustomBaseModal
      isOpen={isOpen}
      onClose={onClose}
      title={`Finalizar orçamento ${quotation.number} para o cliente ${quotation.client_name}`}
    >
      <ModalContent>
        <Header>
          <HeaderText>
            Aqui é possível <strong>finalizar</strong> o orçamento. Defina a
            data de finalização e se ele foi <strong>ganho</strong> ou{' '}
            <strong>perdido</strong>. Caso tenha sido perdido, escreva uma
            justificativa.
          </HeaderText>
        </Header>
        <FormikProvider value={formik}>
          <FormRow>
            <FormGroup>
              <Label htmlFor="finishedAt">Data de finalização *</Label>
              <Input
                type="date"
                id="finishedAt"
                name="finishedAt"
                value={formik.values.finishedAt}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <FormError name="finishedAt" />
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormGroup>
              <Label>Orçamento ganho ou perdido?</Label>
              <RadioButtonsContainer>
                <InputGroup>
                  <Input
                    type="radio"
                    id="isLost-false"
                    name="isLost"
                    checked={!formik.values.isLost}
                    onChange={() => formik.setFieldValue('isLost', false)}
                    onBlur={formik.handleBlur}
                  />
                  <Label htmlFor="isLost-false">Ganho</Label>
                </InputGroup>
                <InputGroup>
                  <Input
                    type="radio"
                    id="isLost-true"
                    name="isLost"
                    checked={formik.values.isLost}
                    onChange={() => formik.setFieldValue('isLost', true)}
                    onBlur={formik.handleBlur}
                  />
                  <Label htmlFor="isLost-true">Perdido</Label>
                </InputGroup>
              </RadioButtonsContainer>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="lostReason">
                Justificativa para o orçamento perdido
                {formik.values.isLost && ' *'}
              </Label>
              <Input
                type="text"
                id="lostReason"
                name="lostReason"
                placeholder="Digite aqui"
                value={formik.values.lostReason}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled={!formik.values.isLost}
              />
              <FormError name="lostReason" />
            </FormGroup>
          </FormRow>
          <ModalFooterButtons
            isLoading={finalizeQuotationRequest.isLoading}
            confirmText="Finalizar"
            onConfirm={() => formik.handleSubmit()}
            cancelText="Voltar"
            onCancel={onClose}
          />
        </FormikProvider>
      </ModalContent>
    </CustomBaseModal>
  );
};

export default FinalizeQuotationModal;
