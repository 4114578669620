import React, { useState } from 'react';

import HorizontalFilterLine from './HorizontalFilterLine';
import { FiltersContainer, FiltersWrapper } from './styles';

interface ExpandableFilterProps {
  children: React.ReactNode;
}

const ExpandableFilter: React.FC<ExpandableFilterProps> = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <HorizontalFilterLine
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
      <FiltersContainer expanded={isExpanded}>
        <FiltersWrapper>{children}</FiltersWrapper>
      </FiltersContainer>
    </>
  );
};

export default ExpandableFilter;
