import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { CloseCircle } from 'iconsax-react';
import { useMutation } from 'react-query';
import { ServiceStatus, ServiceType } from 'utils/enums';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useInspections } from '@hooks';

import DeleteModal from '@components/DeleteModal';

import { CustomButton } from './styles';

interface CancelInspectionButtonProps {
  service: IService;
  onSuccess: () => void;
}

const CancelInspectionButton: React.FC<CancelInspectionButtonProps> = ({
  service,
  onSuccess,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { cancelInspection } = useInspections();
  const { hasAdminPermission } = useAuth();
  const { addToast } = useToast();

  const {
    mutate: requestCancelInspection,
    isLoading: isCancelInspectionRequestLoading,
  } = useMutation(
    (password: string) => cancelInspection(service.inspection.code, password),
    {
      onSuccess: () => {
        onSuccess();
        setIsDeleteModalOpen(false);
        addToast('Vistoria cancelada com sucesso', 'success');
      },
      onError: (error: AxiosError) => {
        addToast('Erro ao cancelar vistoria', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const checkIfItIsAbleToCancel = () => {
    if (!hasAdminPermission) {
      return false;
    }

    if (
      service.type === ServiceType.OWN ||
      service.type === ServiceType.THIRD_PARTY
    ) {
      return (
        service.status === ServiceStatus.WAITING_COLLECTION ||
        service.status === ServiceStatus.WAITING_DEPARTURE
      );
    } else {
      return (
        service.status === ServiceStatus.WAITING_COLLECTION ||
        service.status === ServiceStatus.WAITING_DELIVERY
      );
    }
  };

  const shouldRenderCancelInspectionButton = checkIfItIsAbleToCancel();

  if (!shouldRenderCancelInspectionButton) {
    return null;
  }

  return (
    <>
      <CustomButton onClick={() => setIsDeleteModalOpen(true)}>
        Cancelar vistoria <CloseCircle variant="Bold" />
      </CustomButton>
      <DeleteModal
        title="Tem certeza que deseja cancelar a vistoria?"
        message="Ao cancelar a vistoria, todos os dados serão perdidos e esta ação é irreversível"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        confirmText="Sim, desejo cancelar"
        onConfirm={(password) => password && requestCancelInspection(password)}
        cancelText="Não, voltar"
        passwordRequired
        isLoading={isCancelInspectionRequestLoading}
      />
    </>
  );
};

export default CancelInspectionButton;
