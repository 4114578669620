import React, { useCallback, useEffect, useRef, useState } from 'react';

import { AxiosError } from 'axios';
import { phone } from 'b2utils';
import { B2TableRow } from 'react-b2components';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Tooltip } from 'react-tooltip';
import { QuotationStatus } from 'utils/enums';
import { formatDate } from 'utils/formats';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useNavigateWithScope, useQueryParams, useQuotations } from '@hooks';

import DashboardListingWrapper from '@components/DashboardListingWrapper';
import DeleteModal from '@components/DeleteModal';
import { TableDataCell } from '@components/Table/styles';
import TableVariant from '@components/TableVariant';

import RoutesPath from '@router/routes';

import FinalizeQuotationModal from './FinalizeQuotationModal';
import QuotationFilters from './QuotationFilters';
import QuotationPdf from './QuotationPdf';
import QuotationStatusLabel from './QuotationStatusLabel';
import VehicleQuoteListingModal from './VehicleQuoteListingModal';
import {
  ActionIconsContainer,
  CarIcon,
  DocumentDownloadIcon,
  TickCircleIcon,
  TrashIcon,
} from './styles';

const QuotationList: React.FC = () => {
  const quotationPdfRef = useRef<HTMLDivElement>(null);

  const { queryParams, setQueryParams, onSearch, changePage } =
    useQueryParams<IQuotationQueryParams>({ page: 1 });

  const [selectedQuotation, setSelectedQuotation] = useState<IQuotation>();
  const [isVehicleQuoteListingModalOpen, setIsVehicleQuoteListingModalOpen] =
    useState(false);
  const [isPrintOpen, setIsPrintOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isFinalizeModalOpen, setIsFinalizeModalOpen] = useState(false);

  const { listQuotations, deleteQuotation } = useQuotations();
  const { isManagementUser, hasAdminPermission } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { navigateWithScope } = useNavigateWithScope();
  const handlePrint = useReactToPrint({
    documentTitle: `ORÇAMENTO - ${selectedQuotation?.number}`,
    content: () => quotationPdfRef.current,
    onAfterPrint: () => setIsPrintOpen(false),
  });

  const {
    data: quotations,
    refetch: refetchQuotations,
    isLoading,
  } = useQuery(
    ['quotations', queryParams, scope],
    () => listQuotations(queryParams),
    {
      onError: () => {
        addToast('Não foi possível carregar a lista de orçamentos', 'error');
      },
    }
  );

  const {
    mutate: requestDeleteQuotation,
    isLoading: isDeleteQuotationLoading,
  } = useMutation(
    (password: string) => deleteQuotation(selectedQuotation!.id, password),
    {
      onSuccess: () => {
        refetchQuotations();
        setIsDeleteModalOpen(false);
        addToast('Orçamento deletado com sucesso', 'success');
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível deletar o orçamento', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const navigateToCreateQuotation = useCallback(() => {
    if (isManagementUser && !scope) {
      addToast(
        'Não é possível cadastrar um orçamento sem estar com uma empresa selecionada',
        'error'
      );
      return;
    }
    navigate(RoutesPath.private.quotations.createQuotation.path);
  }, [addToast, isManagementUser, navigate, scope]);

  const handleDeleteQuotation = useCallback(
    (password?: string) => {
      if (password) {
        requestDeleteQuotation(password);
      }
    },
    [requestDeleteQuotation]
  );

  useEffect(() => {
    if (selectedQuotation && isPrintOpen) {
      handlePrint();
    }
  }, [handlePrint, isPrintOpen, selectedQuotation]);
  return (
    <DashboardListingWrapper
      title="Lista de orçamentos"
      buttonProps={{
        text: 'Adicionar orçamento',
        onClick: navigateToCreateQuotation,
      }}
    >
      <QuotationFilters
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onSearch={onSearch}
      />
      <Tooltip id="quotations-list-tooltip" />
      <TableVariant
        data={quotations?.results || []}
        headerData={[
          'Situação',
          'Número',
          'Cliente',
          'Telefone',
          'Previsão para envio',
          'Gerado por',
          'Justificativa para perca',
          'Ações',
        ]}
        emptyMessage="Nenhum orçamento encontrado"
        isLoading={isLoading}
        renderRow={(quotation) => (
          <B2TableRow
            key={quotation.id}
            onClick={() =>
              navigateWithScope({
                routePath:
                  RoutesPath.private.quotations.updateQuotation.path.replace(
                    ':quotationId',
                    quotation.id.toString()
                  ),
                company: quotation.company,
              })
            }
          >
            <TableDataCell>
              <QuotationStatusLabel status={quotation.status} />
            </TableDataCell>
            <TableDataCell>{quotation.number}</TableDataCell>
            <TableDataCell>{quotation.client_name}</TableDataCell>
            <TableDataCell>
              {phone.fromObject({
                areaCode: quotation.phone.area_code,
                number: quotation.phone.number,
              })}
            </TableDataCell>
            <TableDataCell>
              {formatDate(quotation.estimated_shipping_date) || '-'}
            </TableDataCell>
            <TableDataCell>{quotation.person_in_charge.name}</TableDataCell>
            <TableDataCell>{quotation.lost_reason || '-'}</TableDataCell>
            <TableDataCell>
              <ActionIconsContainer>
                {hasAdminPermission && (
                  <CarIcon
                    data-tooltip-id="quotations-list-tooltip"
                    data-tooltip-content="Ver veículos orçados"
                    onClick={(event) => {
                      event.stopPropagation();
                      setSelectedQuotation(quotation);
                      setIsVehicleQuoteListingModalOpen(true);
                    }}
                  />
                )}
                <DocumentDownloadIcon
                  data-tooltip-id="quotations-list-tooltip"
                  data-tooltip-content="Baixar orçamento"
                  onClick={(event) => {
                    event.stopPropagation();
                    setSelectedQuotation(quotation);
                    setIsPrintOpen(true);
                  }}
                />
                <TrashIcon
                  data-tooltip-id="quotations-list-tooltip"
                  data-tooltip-content="Excluir orçamento"
                  onClick={(event) => {
                    event.stopPropagation();
                    setSelectedQuotation(quotation);
                    setIsDeleteModalOpen(true);
                  }}
                />
                {quotation.status === QuotationStatus.IN_PROGRESS && (
                  <TickCircleIcon
                    data-tooltip-id="quotations-list-tooltip"
                    data-tooltip-content="Finalizar orçamento"
                    onClick={(event) => {
                      event.stopPropagation();
                      setSelectedQuotation(quotation);
                      setIsFinalizeModalOpen(true);
                    }}
                  />
                )}
              </ActionIconsContainer>
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={quotations?.count}
        changePage={changePage}
      />
      {selectedQuotation && (
        <VehicleQuoteListingModal
          isOpen={isVehicleQuoteListingModalOpen}
          onClose={() => {
            setIsVehicleQuoteListingModalOpen(false);
            setSelectedQuotation(undefined);
          }}
          quotation={selectedQuotation}
        />
      )}
      {selectedQuotation && (
        <FinalizeQuotationModal
          isOpen={isFinalizeModalOpen}
          onClose={() => {
            setIsVehicleQuoteListingModalOpen(false);
            setSelectedQuotation(undefined);
          }}
          onFinalizeSuccess={refetchQuotations}
          quotation={selectedQuotation}
        />
      )}
      {isDeleteModalOpen && selectedQuotation && (
        <DeleteModal
          title={`Tem certeza que deseja excluir o orçamento ${selectedQuotation.number} para o cliente ${selectedQuotation.client_name}`}
          isOpen={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
            setSelectedQuotation(undefined);
          }}
          onConfirm={handleDeleteQuotation}
          isLoading={isDeleteQuotationLoading}
          passwordRequired
        />
      )}
      {selectedQuotation && (
        <QuotationPdf ref={quotationPdfRef} quotation={selectedQuotation} />
      )}
    </DashboardListingWrapper>
  );
};

export default QuotationList;
