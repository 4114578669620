import { InfoCircle } from 'iconsax-react';
import { B2Button } from 'react-b2components';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.two};
  padding-top: ${({ theme }) => theme.spacing.four};
  border-top: 1px solid ${({ theme }) => theme.colors.white.three};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const ActionText = styled.p`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const InfoCircleIcon = styled(InfoCircle)`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.four};
  height: ${({ theme }) => theme.spacing.four};
  color: ${({ theme }) => theme.colors.info};
  margin: ${({ theme }) =>
    addSpacing('0', '0', theme.spacing.one, theme.spacing.one)};
`;

export const CustomButton = styled(B2Button)`
  align-self: flex-end;
  width: fit-content;
`;

export const AddressFormContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) =>
    addSpacing('0', theme.spacing.eight, theme.spacing.five)};
`;
