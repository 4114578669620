import React from 'react';

import { CteIssuanceStatus } from 'utils/enums';
import { formatToBrlDate } from 'utils/formats';

import {
  Container,
  CustomLoading,
  IssuanceDates,
  Status,
  Title,
} from './styles';

interface IssuanceStatusLabelProps {
  cte: ICte;
  isLoading: boolean;
}

const IssuanceStatusLabel: React.FC<IssuanceStatusLabelProps> = ({
  cte,
  isLoading,
}) => {
  const shouldRenderStatusMessage =
    cte.issuance_status === CteIssuanceStatus.FAILED ||
    cte.issuance_status === CteIssuanceStatus.CANCELED;

  const getMessage = () => {
    if (cte.issuance_status === CteIssuanceStatus.FAILED) {
      return cte.status_message?.replace('Rejeição:', ' -');
    } else {
      return ` - ${cte.cancel_reason}`;
    }
  };

  return (
    <>
      <Container status={cte.issuance_status}>
        <Title>
          Situação do CT-e
          {!isLoading && (
            <>
              {': '}
              <Status status={cte.issuance_status}>
                {cte.issuance_status}
                {shouldRenderStatusMessage && <>{getMessage()}</>}
              </Status>
            </>
          )}
        </Title>
        {isLoading ? (
          <CustomLoading />
        ) : (
          cte.requested_at && (
            <IssuanceDates>
              Requisitado em: {formatToBrlDate(cte.requested_at)}
              {cte.issued_at &&
                ` / Emitido em: ${formatToBrlDate(cte.issued_at)}`}
              {cte.canceled_at &&
                ` / Cancelado em: ${formatToBrlDate(cte.canceled_at)}`}
            </IssuanceDates>
          )
        )}
      </Container>
    </>
  );
};

export default IssuanceStatusLabel;
