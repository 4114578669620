import { Edit, Trash } from 'iconsax-react';
import { B2Card } from 'react-b2components';
import styled from 'styled-components';
import { iconButtonStyle } from 'utils/styles';

export const Card = styled(B2Card)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.two};
  background-color: ${({ theme }) => theme.colors.white.three};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const IconsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const EditButton = styled(Edit)`
  ${iconButtonStyle}
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};
  color: ${({ theme }) => theme.colors.info};
`;

export const TrashButton = styled(Trash)`
  ${iconButtonStyle}
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};
  color: ${({ theme }) => theme.colors.error};
`;

export const Date = styled.span`
  font-size: ${({ theme }) => theme.fontSize.smaller};
  color: ${({ theme }) => theme.colors.gray.ten};
`;

export const UserName = styled.span`
  font-size: ${({ theme }) => theme.fontSize.smaller};
  color: ${({ theme }) => theme.colors.info};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Message = styled.span`
  hyphens: auto;
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.gray.ten};
`;

export const EditedMessage = styled(Message)`
  margin-left: ${({ theme }) => theme.spacing.one};
  font-size: ${({ theme }) => theme.fontSize.tiny};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;
