import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { FinancialReportType, ReportDetailLevel } from 'utils/enums';

import { useClients, useCompanyUsers, useIncomeCategories } from '@hooks';

import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import PaymentStatusReportFilter from '@components/PaymentStatusReportFilter';

import { CustomChooseItemFilter } from '@pages/Services/FormSteps/styles';

interface IncomeReportFormProps {
  initialValues?: IIncomeReportFormValues;
  onFinish: (data: IIncomeReportFormValues) => void;
}

const IncomeReportForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  IncomeReportFormProps
> = ({ initialValues, onFinish }, ref) => {
  const { listClients } = useClients();
  const { listCompanyUsers } = useCompanyUsers();
  const { listIncomeCategories } = useIncomeCategories();

  const emptyClient = {
    id: 0,
    name: 'Todos',
  } as IClientUser;

  const emptyPersonInCharge = {
    id: 0,
    name: 'Todos',
  } as ICompanyUser;

  const emptyCategory = {
    id: 0,
    name: 'Todas',
  } as IIncomeCategory;

  const formikDefaultInitialValues: IIncomeReportFormValues = {
    dateGte: '',
    dateLte: '',
    client: emptyClient,
    personInCharge: emptyPersonInCharge,
    category: emptyCategory,
    status: null,
    detailLevel: ReportDetailLevel.DETAILED,
  };

  const formikInitialValues: IIncomeReportFormValues = {
    ...formikDefaultInitialValues,
    ...initialValues,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
    resetForm: () => {
      formik.setValues({
        ...initialValues,
        ...formikDefaultInitialValues,
      });
    },
  }));

  const handleSelectClient = useCallback(
    (client: IClientUser) => {
      formik.setFieldTouched('client');
      formik.setFieldValue('client', client);
    },
    [formik]
  );

  const handleSelectPersonInCharge = useCallback(
    (personInCharge: ICompanyUser) => {
      formik.setFieldTouched('personInCharge');
      formik.setFieldValue('personInCharge', personInCharge);
    },
    [formik]
  );

  const handleSelectCategory = useCallback(
    (category: IIncomeCategory) => {
      formik.setFieldTouched('category');
      formik.setFieldValue('category', category);
    },
    [formik]
  );

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="dateGte">Data inicial</Label>
          <Input
            type="date"
            id="dateGte"
            name="dateGte"
            value={formik.values.dateGte}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.touched.dateGte && !!formik.errors.dateGte}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="dateLte">Data final</Label>
          <Input
            type="date"
            id="dateLte"
            name="dateLte"
            value={formik.values.dateLte}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.touched.dateLte && !!formik.errors.dateLte}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Cliente</Label>
          <CustomChooseItemFilter
            filterValue={formik.values.client.name}
            modalTitle="Selecionar cliente"
            fetch={listClients}
            queryParams={{ isActive: 'true' }}
            queryKey="clients"
            inputLabel="Selecionar cliente"
            selectedItemLabel="Cliente selecionado"
            shouldRenderSelectedItem
            onSelect={(client) => handleSelectClient(client)}
            selectedItem={formik.values.client}
            renderItemText={(client) => client.name}
            additionalItem={emptyClient}
          />
        </FormGroup>
        <FormGroup>
          <Label>Responsável</Label>
          <CustomChooseItemFilter
            filterValue={formik.values.personInCharge.name}
            modalTitle="Selecionar responsável"
            fetch={listCompanyUsers}
            queryParams={{ isActive: 'true', canBeInChargeOfServices: true }}
            queryKey="companyUsers"
            inputLabel="Selecionar responsável"
            selectedItemLabel="Responsável selecionado"
            shouldRenderSelectedItem
            onSelect={(personInCharge) =>
              handleSelectPersonInCharge(personInCharge)
            }
            selectedItem={formik.values.personInCharge}
            renderItemText={(personInCharge) => personInCharge.name}
            additionalItem={emptyPersonInCharge}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Categoria</Label>
          <CustomChooseItemFilter
            filterValue={formik.values.category.name}
            modalTitle="Selecionar categoria"
            fetch={listIncomeCategories}
            queryParams={{ isActive: 'true' }}
            queryKey="incomeCategories"
            inputLabel="Selecionar categoria"
            selectedItemLabel="Categoria selecionada"
            shouldRenderSelectedItem
            onSelect={(category) => handleSelectCategory(category)}
            selectedItem={formik.values.category}
            renderItemText={(category) => category.name}
            additionalItem={emptyCategory}
          />
        </FormGroup>
      </FormRow>
      <PaymentStatusReportFilter type={FinancialReportType.INCOME} />
    </FormikProvider>
  );
};

export default forwardRef(IncomeReportForm);
