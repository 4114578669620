import React, { forwardRef, useImperativeHandle, useMemo } from 'react';

import { currency } from 'b2utils';
import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { PaymentStatus, ReportDetailLevel } from 'utils/enums';
import { formatDate } from 'utils/formats';
import { getIncomeDescription } from 'utils/helpers';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useIncomes, useQueryParams } from '@hooks';

import ModalTable from '@components/ModalTable';
import { TableDataCell } from '@components/Table/styles';

import { PaymentStatusLabel } from '@pages/Financial/FinancialDashboard/styles';

import { CustomTableRow } from '../styles';

interface IncomeListingProps {
  initialValues: IIncomeReportFormValues;
  onFinish: () => void;
}

const IncomeListing: React.ForwardRefRenderFunction<
  IFormStepRef,
  IncomeListingProps
> = ({ initialValues, onFinish }, ref) => {
  const { queryParams, changePage } = useQueryParams<IIncomeQueryParams>({
    page: 1,
    date__gte: initialValues.dateGte,
    date__lte: initialValues.dateLte,
    client: initialValues.client,
    personInCharge: initialValues.personInCharge,
    category: initialValues.category,
    status: initialValues.status,
  });

  const { listIncomes, listSimplifiedIncomes } = useIncomes();
  const { scope } = useScope();
  const { addToast } = useToast();

  const onError = () => {
    addToast('Não foi possível carregar a lista de recebíveis', 'error');
  };

  const { data: incomes, isLoading } = useQuery(
    ['incomes', queryParams, scope],
    () => listIncomes(queryParams),
    {
      enabled: initialValues.detailLevel === ReportDetailLevel.DETAILED,
      onError,
    }
  );

  const { data: simplifiedIncomes, isLoading: isLoadingSimplifiedIncomes } =
    useQuery(
      ['simplifiedIncomes', queryParams, scope],
      () => listSimplifiedIncomes(queryParams),
      {
        enabled: initialValues.detailLevel === ReportDetailLevel.SIMPLIFIED,
        onError,
      }
    );

  useImperativeHandle(ref, () => ({
    submit: onFinish,
  }));

  const headerData = useMemo(() => {
    if (initialValues.detailLevel === ReportDetailLevel.DETAILED) {
      return [
        'Data',
        'Cliente',
        'Descrição',
        'Situação',
        'Valor a receber',
        'Valor recebido',
      ];
    }

    if (!queryParams.status) {
      return ['Cliente', 'Valor total'];
    }

    if (queryParams.status === PaymentStatus.PAID) {
      return ['Cliente', 'Valor recebido'];
    }

    return ['Cliente', 'Valor a receber'];
  }, [initialValues.detailLevel, queryParams.status]);

  if (initialValues.detailLevel === ReportDetailLevel.SIMPLIFIED) {
    return (
      <ModalTable
        data={simplifiedIncomes?.results || []}
        isLoading={isLoadingSimplifiedIncomes}
        headerData={headerData}
        emptyMessage="Nenhum recebível encontrado"
        renderRow={(simplifiedIncome) => (
          <CustomTableRow key={simplifiedIncome.client_id}>
            <TableDataCell>{simplifiedIncome.client_name}</TableDataCell>
            <TableDataCell>
              {currency.centsToBrl(simplifiedIncome.sum)}
            </TableDataCell>
          </CustomTableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={simplifiedIncomes?.count}
        changePage={changePage}
        hasClick={false}
      />
    );
  }

  return (
    <ModalTable
      data={incomes?.results || []}
      isLoading={isLoading}
      headerData={headerData}
      emptyMessage="Nenhum recebível encontrado"
      renderRow={(income) => (
        <B2TableRow key={income.id}>
          <TableDataCell>{formatDate(income.date)}</TableDataCell>
          <TableDataCell>{income.client.name}</TableDataCell>
          <TableDataCell>{getIncomeDescription(income)}</TableDataCell>
          <TableDataCell>
            <PaymentStatusLabel status={income.status}>
              {income.status}
            </PaymentStatusLabel>
          </TableDataCell>
          <TableDataCell>{currency.centsToBrl(income.value)}</TableDataCell>
          <TableDataCell>
            {currency.centsToBrl(income.paid_value)}
          </TableDataCell>
        </B2TableRow>
      )}
      paginator
      amountPerPage={20}
      currentPage={queryParams.page}
      total={incomes?.count}
      changePage={changePage}
      hasClick={false}
    />
  );
};

export default forwardRef(IncomeListing);
