import React from 'react';

import BaseFilePicker from 'components/BaseFilePicker';

import FilesList from '@components/FilesList';

interface FileSinglePickerProps {
  onAddFile: (file: IFile) => void;
  onDeleteFile: (filePk: number) => void;
  file?: IFile | null;
  disabled?: boolean;
}

const FileSinglePicker: React.FC<FileSinglePickerProps> = ({
  onAddFile,
  onDeleteFile,
  file,
  disabled = false,
}) => {
  return (
    <BaseFilePicker onAddFile={onAddFile} disabled={disabled}>
      <FilesList
        files={file ? [file] : []}
        isDeleteDisabled={disabled}
        onDeleteFile={onDeleteFile}
      />
    </BaseFilePicker>
  );
};

export default FileSinglePicker;
