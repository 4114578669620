import { useCallback } from 'react';

import { useApi, endpoints } from 'contexts/Api';
import regex from 'utils/regex';

import { useScope } from '@contexts/Scope';

const generateAddressRequestObject = (address: IAddressForm) => {
  const requestObject: IAddressRequest = {
    city: address.city.id,
    street: address.street,
    number: address.number,
    additional_info: address.complement,
    district: address.district,
    zip_code: address.zipCode.replace(regex.onlyNumbers, ''),
  };
  return requestObject;
};

const useAddresses = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const createAddress = useCallback(
    async (values: ICreateAddress) => {
      const address = generateAddressRequestObject(values.address);
      const data: ICreateAddressRequest = {
        ...address,
        client: values.clientId,
      };

      const response = await request<void>({
        method: 'post',
        url: endpoints.core.addresses.create,
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const updateAddress = useCallback(
    async (addressId: number, address: IAddressForm) => {
      const data = generateAddressRequestObject(address);
      const response = await request<void>({
        method: 'patch',
        url: endpoints.core.addresses.update.replace(
          ':addressId',
          addressId.toString()
        ),
        data,
      });

      return response.data;
    },
    [request]
  );

  const deleteAddress = useCallback(
    async (addressId: number) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.core.addresses.delete.replace(
          ':addressId',
          addressId.toString()
        ),
      });

      return response.data;
    },
    [request]
  );

  return { createAddress, updateAddress, deleteAddress };
};

export default useAddresses;
