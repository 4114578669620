import React, { useEffect, useState } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { FinancialType } from 'utils/enums';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useExpenses, useIncomes, useTransfers } from '@hooks';

import { BaseFormWrapper } from '@components/Base/PrivateBase/styles';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import ExpenseForm from '../Form/ExpenseForm';
import FinancialTypeForm from '../Form/FinancialTypeForm';
import IncomeForm from '../Form/IncomeForm';
import TransferForm from '../Form/TransferForm';
import routes from './routes';

const CreateFinancialTransaction: React.FC = () => {
  const [financialType, setFinancialType] = useState<FinancialType>();
  const [
    selectedFinancialTransactionType,
    setSelectedFinancialTransactionType,
  ] = useState<FinancialType>();

  const { createIncome } = useIncomes();
  const { createExpense } = useExpenses();
  const { createTransfer } = useTransfers();
  const { isManagementUser } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();

  const navigate = useNavigate();

  const createIncomeRequest = useMutation(
    (values: IIncomeFormValues) => createIncome(values),
    {
      onSuccess: () => {
        addToast('Recebível cadastrada com sucesso', 'success');
        navigate(RoutesPath.private.financial.incomes.listIncomes.path);
      },
      onError: () => {
        addToast('Erro ao cadastrar recebível', 'error');
      },
    }
  );

  const createExpenseRequest = useMutation(
    (values: IExpenseFormValues) => createExpense(values),
    {
      onSuccess: () => {
        addToast('Despesa cadastrado com sucesso', 'success');
        navigate(RoutesPath.private.financial.expenses.listExpenses.path);
      },
      onError: () => {
        addToast('Erro ao cadastrar despesa', 'error');
      },
    }
  );

  const createTransferRequest = useMutation(
    (values: ITransferFormValues) => createTransfer(values),
    {
      onSuccess: () => {
        addToast('Transação cadastrada com sucesso', 'success');
        navigate(RoutesPath.private.financial.transfers.listTransfers.path);
      },
      onError: () => {
        addToast('Erro ao cadastrar transação', 'error');
      },
    }
  );

  useEffect(() => {
    if (!scope && isManagementUser) {
      addToast(
        'Selecione uma empresa para adicionar uma movimentação',
        'error'
      );
      navigate(-1);
    }
  }, [scope, navigate, addToast, isManagementUser]);

  const handleGoBackOnFirstStep = () =>
    setSelectedFinancialTransactionType(undefined);

  const financialTransactionForm = {
    [FinancialType.INCOME]: (
      <IncomeForm
        onFinish={createIncomeRequest}
        onGoBackOnFirstStep={handleGoBackOnFirstStep}
      />
    ),
    [FinancialType.EXPENSE]: (
      <ExpenseForm
        onFinish={createExpenseRequest}
        onGoBackOnFirstStep={handleGoBackOnFirstStep}
      />
    ),
    [FinancialType.TRANSFER]: (
      <TransferForm
        onFinish={createTransferRequest}
        onGoBackOnFirstStep={handleGoBackOnFirstStep}
      />
    ),
    [FinancialType.CAPITAL_INJECTION]: (
      <TransferForm
        isCapitalInjection
        onFinish={createTransferRequest}
        onGoBackOnFirstStep={handleGoBackOnFirstStep}
      />
    ),
  };

  return (
    <>
      <PageHeader title="Cadastrar movimentação" routes={routes} />
      <BaseFormWrapper visible={!!selectedFinancialTransactionType}>
        {financialTransactionForm[financialType!]}
      </BaseFormWrapper>
      {!selectedFinancialTransactionType && (
        <FinancialTypeForm
          selectedFinancialTransactionType={selectedFinancialTransactionType}
          setSelectedFinancialTransactionType={(type) => {
            setFinancialType(type);
            setSelectedFinancialTransactionType(type);
          }}
        />
      )}
    </>
  );
};

export default CreateFinancialTransaction;
