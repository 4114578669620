import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10%;
  background-color: ${({ theme }) => theme.colors.white.three};
  border-radius: ${({ theme }) => theme.spacing.three};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.four)};
`;
