import React, { useCallback, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { UserType } from 'utils/enums';

import { useAuth } from '@contexts/Auth';

import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import Addresses from './Addresses';
import DeleteAccount from './DeleteAccount';
import { ProfileInfo } from './ProfileInfo';
import UpdateEmail from './UpdateEmail';
import UpdatePassword from './UpdatePassword';
import { TabName, TabNames } from './VerticalTabs';
import { Container, Content } from './styles';

const tabs = [
  {
    name: 'Dados do usuário',
    component: <ProfileInfo />,
    path: RoutesPath.private.profile.editProfile.path,
    userType: [UserType.MANAGEMENT, UserType.COMPANY, UserType.CLIENT],
  },
  {
    name: 'Meus endereços',
    component: <Addresses />,
    path: RoutesPath.private.profile.manageAddresses.path,
    userType: [UserType.CLIENT],
  },
  {
    name: 'Atualizar e-mail',
    component: <UpdateEmail />,
    path: RoutesPath.private.profile.editEmail.path,
    userType: [UserType.MANAGEMENT, UserType.COMPANY, UserType.CLIENT],
  },
  {
    name: 'Atualizar senha',
    component: <UpdatePassword />,
    path: RoutesPath.private.profile.editPassword.path,
    userType: [UserType.MANAGEMENT, UserType.COMPANY, UserType.CLIENT],
  },
  {
    name: 'Deletar conta',
    component: <DeleteAccount />,
    path: RoutesPath.private.profile.deleteAccount.path,
    userType: [UserType.MANAGEMENT, UserType.COMPANY],
  },
];

const Profile: React.FC = () => {
  const { userInfo } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const filteredTabs = tabs.filter((page) =>
    page.userType.includes(userInfo!.type)
  );

  const getCurrentTab = useCallback(() => {
    const currentTab = filteredTabs.findIndex(
      (tab) => tab.path === location.pathname
    );

    return currentTab !== -1 ? currentTab : 0;
  }, [filteredTabs, location.pathname]);

  const [selectedTab, setSelectedTab] = useState(getCurrentTab());

  useEffect(() => {
    const newTab = getCurrentTab();

    setSelectedTab(newTab);
  }, [getCurrentTab]);

  return (
    <Container>
      <PageHeader title="Perfil" />
      <Content>
        <TabNames>
          {filteredTabs.map((tab, index) => (
            <TabName
              key={tab.name}
              onClick={() => {
                setSelectedTab(index);
                if (tab.path) {
                  navigate(tab.path);
                }
              }}
              selected={selectedTab === index}
            >
              {tab.name}
            </TabName>
          ))}
        </TabNames>
        {filteredTabs[selectedTab].component}
      </Content>
    </Container>
  );
};

export default Profile;
