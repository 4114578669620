import React, { useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { Form, FormikProvider, useFormik } from 'formik';
import { useMutation } from 'react-query';
import { useTheme } from 'styled-components';
import {
  CompanyUserRole,
  DepartureStatus,
  MdfeIssuanceStatus,
} from 'utils/enums';
import { throwToastApiErrors } from 'utils/helpers';
import regex from 'utils/regex';
import * as yup from 'yup';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useFiscal } from '@hooks';

import { CustomFormGroup } from '@components/Base/PrivateBase/styles';
import ConfirmationModal from '@components/ConfirmationModal';
import FormError from '@components/FormError';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Loading from '@components/Loading';

import { errors } from '@utils';

import ReasonForMissingCteModal from './ReasonForMissingCteModal';
import { CustomButton } from './styles';

interface MdfeIssuanceFormProps {
  mdfe: IMdfe;
  departureWeight: number | null;
  departureStatus: DepartureStatus;
  onRequestSuccess: () => void;
}

const MAX_WEIGHT = 99999999999.9999;

const MdfeIssuanceForm: React.FC<MdfeIssuanceFormProps> = ({
  mdfe,
  departureWeight,
  departureStatus,
  onRequestSuccess,
}) => {
  const [isIssuanceConfirmationModalOpen, setIsIssuanceConfirmationModalOpen] =
    useState(false);
  const [isReasonForMissingCteModalOpen, setIsReasonForMissingCteModalOpen] =
    useState(false);

  const { userInfo, isManagementUser, isCompanyUser } = useAuth();
  const { issueMdfe } = useFiscal();
  const { addToast } = useToast();
  const theme = useTheme();

  const hasSomeServiceWithoutCte = mdfe.services_without_cte_qty > 0;

  const formikInitialValues: IIssueMdfeFormValues = {
    weight: departureWeight ? departureWeight.toString() : '',
    password: '',
    reasonForMissingCteKeys: '',
  };

  const formikValidationSchema = yup.object().shape({
    weight: yup
      .string()
      .trim()
      .test(
        'is-weight-valid',
        errors.maxWeight(MAX_WEIGHT),
        (value) => !!value && Number(value) <= MAX_WEIGHT
      )
      .required(errors.required),
    reasonForMissingCteKeys: yup
      .string()
      .trim()
      .when({
        is: () => hasSomeServiceWithoutCte && isReasonForMissingCteModalOpen,
        then: (schema) =>
          schema
            .required(errors.required)
            .min(15, errors.minLength(15))
            .max(255, errors.minLength(255)),
      }),
    password: yup
      .string()
      .trim()
      .when({
        is: () => hasSomeServiceWithoutCte && isReasonForMissingCteModalOpen,
        then: (schema) => schema.required(errors.required),
      }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: (values) => {
      if (!hasSomeServiceWithoutCte) {
        setIsIssuanceConfirmationModalOpen(true);
        return;
      }
      if (hasSomeServiceWithoutCte && !isReasonForMissingCteModalOpen) {
        setIsReasonForMissingCteModalOpen(true);
        return;
      }
      if (hasSomeServiceWithoutCte && isReasonForMissingCteModalOpen) {
        requestIssueMdfe(values);
        return;
      }
    },
  });

  const { mutate: requestIssueMdfe, isLoading: isMdfeIssueLoading } =
    useMutation((values: IIssueMdfeFormValues) => issueMdfe(mdfe.id, values), {
      onSuccess: () => {
        addToast('MDF-e emitido para a Sefaz', 'success');
        setIsIssuanceConfirmationModalOpen(false);
        setIsReasonForMissingCteModalOpen(false);
        formik.setFieldValue('reasonForMissingCteKeys', '');
        formik.setFieldValue('password', '');
        onRequestSuccess();
      },
      onError: (error: AxiosError) => {
        addToast('Erro ao emitir MDF-e', 'error');
        throwToastApiErrors(error, addToast);
      },
    });

  const hasStatus = useMemo(
    () =>
      (departureStatus === DepartureStatus.WAITING_START ||
        departureStatus === DepartureStatus.IN_TRAFFIC) &&
      (mdfe?.issuance_status === MdfeIssuanceStatus.PENDING ||
        mdfe?.issuance_status === MdfeIssuanceStatus.FAILED),
    [departureStatus, mdfe?.issuance_status]
  );

  const hasPermission = useMemo(
    () =>
      isManagementUser ||
      (isCompanyUser && userInfo?.role === CompanyUserRole.ADMIN) ||
      (isCompanyUser && userInfo?.role === CompanyUserRole.LOGISTICS),
    [isCompanyUser, isManagementUser, userInfo?.role]
  );

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="false">
        <FormRow>
          <CustomFormGroup>
            <Label htmlFor="weight">Peso bruto total da carga (kg) *</Label>
            <Input
              id="weight"
              name="weight"
              type="text"
              placeholder="Digite aqui"
              value={formik.values.weight}
              onChange={(event) =>
                formik.setFieldValue(
                  'weight',
                  event.target.value.replace(regex.onlyNumbersAndDot, '')
                )
              }
              onBlur={formik.handleBlur}
              invalidValue={!!formik.touched.weight && !!formik.errors.weight}
              disabled={!hasStatus}
            />
            <FormError name="weight" />
          </CustomFormGroup>
        </FormRow>
        {hasStatus && hasPermission && (
          <>
            <CustomButton
              type="button"
              onClick={() => !isMdfeIssueLoading && formik.handleSubmit()}
            >
              {isMdfeIssueLoading ? (
                <Loading color={theme.colors.white.main} />
              ) : (
                <>
                  Emitir MDF-e
                  <img src="/images/mdfe.svg" alt="MDF-e ícone" />
                </>
              )}
            </CustomButton>
            <ConfirmationModal
              title="Tem certeza que deseja emitir o MDF-e?"
              isOpen={isIssuanceConfirmationModalOpen}
              isLoading={isMdfeIssueLoading}
              confirmButtonText="Sim, emitir"
              onConfirm={() =>
                requestIssueMdfe({ weight: formik.values.weight })
              }
              cancelButtonText="Não, cancelar"
              onClose={() => setIsIssuanceConfirmationModalOpen(false)}
            />
            <ReasonForMissingCteModal
              isOpen={isReasonForMissingCteModalOpen}
              onClose={() => {
                setIsReasonForMissingCteModalOpen(false);
                formik.setFieldValue('reasonForMissingCteKeys', '');
                formik.setFieldValue('password', '');
              }}
              onConfirm={() => formik.handleSubmit()}
              isLoading={isMdfeIssueLoading}
              servicesWithoutCteQty={mdfe.services_without_cte_qty}
            />
          </>
        )}
      </Form>
    </FormikProvider>
  );
};

export default MdfeIssuanceForm;
