import React, { ReactNode, useCallback, useRef, useState } from 'react';

import { useToast } from 'contexts/Toast';
import { useAssets } from 'hooks';
import { useMutation } from 'react-query';

import PickerButton from '@components/PickerButton';

import { Container } from './styles';

interface BaseFilePickerProps {
  onAddFile: (file: IFile) => void;
  disabled?: boolean;
  children: ReactNode;
}

const BaseFilePicker: React.FC<BaseFilePickerProps> = ({
  onAddFile,
  children,
  disabled = false,
}) => {
  const [fileName, setFileName] = useState<string>(
    'Nenhum arquivo selecionado'
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const { getPresignedFileUrl, uploadS3File, uploadFile } = useAssets();
  const { addToast } = useToast();

  const onError = useCallback(() => {
    addToast(
      'Não foi possível enviar o arquivo, tente novamente mais tarde',
      'error'
    );
  }, [addToast]);

  const { mutateAsync: fetchPresignedUrl, isLoading: isLoadingURL } =
    useMutation(getPresignedFileUrl, { onError });

  const { mutate: sendS3UrlToApi, isLoading: isLoadingAPI } = useMutation(
    uploadFile,
    {
      onSuccess: (file) => {
        setFileName('Selecione um novo arquivo');
        onAddFile(file);
      },
      onMutate: () => {
        setFileName('Carregando arquivo...');
      },
      onSettled: () => {
        setFileName('Selecione um novo arquivo');
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      },
      onError,
    }
  );

  const { mutate: requestUploadS3File, isLoading: isLoadingS3 } = useMutation(
    ({ presignedUrl, file }: { presignedUrl: IPresignedUrl; file: File }) =>
      uploadS3File(presignedUrl, file),
    {
      onSuccess: (_, variables) => {
        sendS3UrlToApi(variables.presignedUrl);
      },
      onError,
    }
  );

  const getFileExtension = useCallback((type: string) => {
    const extension = type.split('/')[1];
    if (extension === 'x-pkcs12') {
      return 'pfx';
    } else {
      return extension;
    }
  }, []);

  const handleInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (!file) {
        return;
      }

      if (file.type.includes('video')) {
        addToast('O arquivo não pode ser um vídeo', 'error');
        return;
      }

      const extension = getFileExtension(file.type);

      const presignedUrl = await fetchPresignedUrl(extension);

      requestUploadS3File({ presignedUrl, file });
    },
    [addToast, fetchPresignedUrl, getFileExtension, requestUploadS3File]
  );

  return (
    <Container>
      <PickerButton
        fileName={fileName}
        inputAccept="*"
        buttonText="Adicionar arquivo"
        onButtonClick={() => {
          inputRef.current?.click();
        }}
        handleInputChange={handleInputChange}
        isLoading={isLoadingAPI || isLoadingS3 || isLoadingURL}
        disabled={disabled}
        ref={inputRef}
      />
      {children}
    </Container>
  );
};

export default BaseFilePicker;
