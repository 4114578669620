import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { ExpenseType } from 'utils/enums';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Label from '@components/Label';
import Select from '@components/Select';

import CategoryBaseForm from '@pages/Categories/Form/CategoryBaseForm';
import { errors } from '@utils';

interface ICategoryFormProps {
  initialValues?: IExpenseCategoryFormValues;
  onFinish: (data: IExpenseCategoryFormValues) => void;
}

const ExpenseCategoryForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  ICategoryFormProps
> = ({ onFinish, initialValues }, ref) => {
  const expenseCategoryTypes = Object.values(ExpenseType);

  const formikInitialValues: IExpenseCategoryFormValues = {
    name: '',
    type: undefined,
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    name: yup.string().trim().required(errors.required),
    type: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <CategoryBaseForm />
      <FormRow>
        <FormGroup>
          <Label htmlFor="type">Tipo *</Label>
          <Select
            id="type"
            name="type"
            value={formik.values.type}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.errors.type && !!formik.touched.type}
          >
            <option value="">Selecione um tipo</option>
            {expenseCategoryTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
          <FormError name="type" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default forwardRef(ExpenseCategoryForm);
