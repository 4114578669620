import React from 'react';

import { Circle, Container, Label } from './styles';

interface ColorLabelProps {
  color: string;
  label: string;
}

const ColorLabel: React.FC<ColorLabelProps> = ({ color, label }) => {
  return (
    <Container>
      <Circle color={color} />
      <Label>{label}</Label>
    </Container>
  );
};

export default ColorLabel;
