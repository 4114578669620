import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useQuery } from 'react-query';

import { useToast } from '@contexts/Toast';
import { useServices } from '@hooks';

import ContactItem from '@components/ContactItem';
import DocumentDownloadButton from '@components/DocumentDownloadButton';
import Loading from '@components/Loading';

import { Container } from '../styles';
import { ContactContainer } from './styles';

interface ServiceContractDetailProps {
  isModalOpen: boolean;
  contractId: string;
  fileName: string;
}

const ServiceContractDetail: React.FC<ServiceContractDetailProps> = ({
  isModalOpen,
  contractId,
  fileName,
}) => {
  const [enableFetch, setEnableFetch] = useState(false);

  const { getServiceContract } = useServices();
  const { addToast } = useToast();

  const { data: serviceContract, isLoading: isServiceContractLoading } =
    useQuery(
      ['serviceContract', contractId],
      () => getServiceContract(contractId),
      {
        onError: () => {
          addToast('Não foi possível carregar contrato', 'error');
        },
        enabled: enableFetch,
      }
    );

  const isLoading = isServiceContractLoading || !serviceContract;

  useEffect(() => {
    if (isModalOpen) {
      setEnableFetch(true);
    }
  }, [enableFetch, isModalOpen]);

  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : (
        <ContactContainer>
          <ContactItem
            label="Contrato enviado em"
            value={moment(serviceContract.uploaded_at).format('LLL')}
          />
          <ContactItem
            label="Data de assinatura do contrato"
            value={moment(serviceContract.sign_date).format('LL')}
          />
          <DocumentDownloadButton
            fileName={fileName}
            fileUrl={serviceContract.file.url}
          />
        </ContactContainer>
      )}
    </Container>
  );
};

export default ServiceContractDetail;
