import React from 'react';

import { B2ButtonsPage, IB2Table } from 'react-b2components';

import EmptyList from '@components/EmptyList';
import Loading from '@components/Loading';

import {
  Container,
  CustomTable,
  Footer,
  TableLoadingContainer,
} from './styles';

export interface TableProps<Type>
  extends Omit<IB2Table<Type>, 'emptyTableComponent'> {
  isLoading: boolean;
  emptyMessage: string;
  hasClick?: boolean;
  className?: string;
}

const Table = <Type,>({
  isLoading,
  emptyMessage,
  hasClick = true,
  className,
  ...props
}: TableProps<Type>) => {
  const { paginator, total, currentPage, changePage, amountPerPage } = props;

  const hasPagination =
    !!paginator && !!currentPage && !!changePage && !!amountPerPage;

  const totalCount = total || 0;
  const newCurrentPage = currentPage || 0;
  const pages = totalCount === 0 ? 1 : Math.ceil(totalCount / amountPerPage!);
  const paginationInitialCount =
    totalCount === 0 ? 0 : amountPerPage! * (newCurrentPage - 1) + 1;
  const paginationFinalCount =
    paginationInitialCount + (amountPerPage! - 1) < totalCount
      ? paginationInitialCount + (amountPerPage! - 1)
      : totalCount;

  return (
    <Container className={className}>
      <CustomTable
        hasClick={hasClick}
        emptyTableComponent={() => {
          if (isLoading) {
            return (
              <TableLoadingContainer>
                <Loading />
              </TableLoadingContainer>
            );
          }

          return <EmptyList>{emptyMessage}</EmptyList>;
        }}
        {...props}
        paginator={false}
        hasPagination={hasPagination}
      />
      {hasPagination && (
        <Footer>
          <span>
            {isLoading
              ? 'Carregando...'
              : `Mostrando de ${paginationInitialCount} a ${paginationFinalCount} de ${' '} ${totalCount}`}
          </span>
          <B2ButtonsPage
            pages={pages}
            currentPage={newCurrentPage}
            changePage={changePage!}
          />
        </Footer>
      )}
    </Container>
  );
};

export default Table;
