import { PaymentMethodListType } from 'utils/enums';

import RoutesPath from '@router/routes';

export const paymentListProps = {
  [PaymentMethodListType.PAYMENT_METHOD]: {
    type: PaymentMethodListType.PAYMENT_METHOD,
    listPath: RoutesPath.private.paymentMethods.listPaymentMethods.path,
    subtitle:
      'Aqui você irá encontrar a lista de todos os métodos de pagamento cadastrados no sistema',
    actionButtonProps: {
      buttonText: 'Cadastrar método de pagamento',
      toastMessage:
        'Não é possível cadastrar um método de pagamento sem estar com uma empresa selecionada',
      createPath: RoutesPath.private.paymentMethods.createPaymentMethod.path,
    },
  },
  [PaymentMethodListType.PAYMENT_TERM]: {
    type: PaymentMethodListType.PAYMENT_TERM,
    listPath: RoutesPath.private.paymentMethods.listPaymentTerms.path,
    subtitle:
      'Aqui você irá encontrar a lista de todas as formas de pagamento cadastradas no sistema',
    actionButtonProps: {
      buttonText: 'Cadastrar forma de pagamento',
      toastMessage:
        'Não é possível cadastrar uma forma de pagamento sem estar com uma empresa selecionada',
      createPath: RoutesPath.private.paymentMethods.createPaymentTerm.path,
    },
  },
};
