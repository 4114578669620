import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';

interface VerifyScopeProps {
  toastErrorMessage: string;
  routePath: string;
}

const useVerifyScope = ({ toastErrorMessage, routePath }: VerifyScopeProps) => {
  const { isManagementUser } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (!scope && isManagementUser) {
      addToast(toastErrorMessage, 'error');
      navigate(routePath, {
        replace: true,
      });
    }
  }, [
    scope,
    navigate,
    addToast,
    isManagementUser,
    toastErrorMessage,
    routePath,
  ]);
};

export default useVerifyScope;
