import React from 'react';

import { AxiosError } from 'axios';
import { currency } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { UseMutationResult } from 'react-query';
import { MAX_VALUE } from 'utils/constants';
import { isCurrencyFieldValueBetween } from 'utils/helpers';
import regex from 'utils/regex';
import * as yup from 'yup';

import { CustomFormGroup } from '@components/Base/PrivateBase/styles';
import FormError from '@components/FormError';
import Input from '@components/Input';

import { errors } from '@utils';

interface ServiceExpenseValueProps {
  isTouched?: boolean;
  initialValues: IUpdateDepartureServiceExpenseValue;
  updateRequest: UseMutationResult<
    IDepartureService,
    AxiosError<unknown, any>,
    IUpdateDepartureServiceExpenseValue,
    unknown
  >;
}

const ServiceExpenseValue: React.FC<ServiceExpenseValueProps> = ({
  initialValues,
  updateRequest,
}) => {
  const formikValidationSchema = yup.object().shape({
    expenseValue: yup
      .string()
      .trim()
      .test(
        'is-freight-cost-value-valid',
        errors.currencyBetween(0, MAX_VALUE),
        (value) =>
          isCurrencyFieldValueBetween({
            value,
            minValue: 0,
            maxValue: MAX_VALUE,
            required: false,
          })
      )
      .required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: formikValidationSchema,
    onSubmit: (values) => updateRequest.mutate(values),
  });

  return (
    <FormikProvider value={formik}>
      <CustomFormGroup>
        <Input
          type="text"
          id="expenseValue"
          name="expenseValue"
          placeholder="R$ 0,00"
          value={formik.values.expenseValue}
          onBlur={() => formik.handleSubmit()}
          onChange={(event) =>
            formik.setFieldValue(
              'expenseValue',
              currency.brlMask(
                event.target.value.replace(regex.onlyNumbers, '')
              )
            )
          }
          invalidValue={
            !!formik.touched.expenseValue && !!formik.errors.expenseValue
          }
        />
        <FormError name="expenseValue" />
      </CustomFormGroup>
    </FormikProvider>
  );
};

export default ServiceExpenseValue;
