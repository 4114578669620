import styled, { css } from 'styled-components';
import { commonInputStyle } from 'utils/styles';

interface InputProps {
  invalidValue?: boolean;
}

export const CustomInput = styled.input<InputProps>`
  ${commonInputStyle};

  &[type='radio'] {
    cursor: pointer;
    width: ${({ theme }) => theme.spacing.four};
    height: ${({ theme }) => theme.spacing.four};
    appearance: none;
    display: inline-block;
    padding: 0.2rem;
    background-clip: content-box;
    border: 2px solid ${({ theme }) => theme.colors.gray.four};
    background-color: ${({ theme }) => theme.colors.white.main};
    transition: background-color ease-in 0.25s, border-color ease-in 0.25s;
    flex-shrink: 0;
    &:checked {
      background-color: ${({ theme }) => theme.colors.main};
      border-color: ${({ theme }) => theme.colors.main};
    }
    &:active {
      transform: scale(0.95);
    }
  }

  &[type='checkbox'] {
    width: ${({ theme }) => theme.spacing.four};
    height: ${({ theme }) => theme.spacing.four};
    accent-color: ${({ theme }) => theme.colors.main};
    transition: all ease-in 0.25s;
  }

  ${({ theme, invalidValue }) =>
    invalidValue &&
    css`
      &[type='date'] {
        padding-right: ${theme.spacing.eleven};
      }
    `}
`;
