import React from 'react';

import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useServices } from '@hooks';

import Loading from '@components/Loading';
import PageHeader from '@components/PageHeader';
import SectionHeader from '@components/SectionHeader';

import AcceptanceOfTerms from './AcceptanceOfTerms';
import DeliveryReceiptForm from './DeliveryReceiptForm';
import { Container, Message, TickCircleIcon } from './styles';

const BaseDeliveryReceiptForm: React.FC = () => {
  const { receiptId } = useParams<{ receiptId: string }>();

  const { userInfo } = useAuth();
  const { getDeliveryReceipt, sendDeliveryReceipt } = useServices();
  const { addToast } = useToast();

  const {
    data: deliveryReceipt,
    error: queryError,
    refetch,
    isLoading,
  } = useQuery(
    ['deliveryReceipt', receiptId],
    () => getDeliveryReceipt(receiptId!),
    {
      enabled: !!receiptId,
      retry: false,
    }
  );

  const {
    mutate: submitDeliveryReceipt,
    isLoading: isSubmitDeliveryReceiptLoading,
  } = useMutation(
    (values: IDeliveryReceiptFormValues) =>
      sendDeliveryReceipt(receiptId!, values),
    {
      onSuccess: () => {
        addToast('Termo de entrega enviado com sucesso', 'success');
        refetch();
      },
      onError: () => {
        addToast('Não foi possível enviar o termo de entrega', 'error');
      },
    }
  );

  const isAuthenticated = !!userInfo;
  const isCompleted = !!queryError || !!deliveryReceipt?.file;

  if (isLoading || !deliveryReceipt) {
    return <Loading />;
  }

  return (
    <>
      {isAuthenticated && (
        <PageHeader
          title={`Termo de entrega do veículo ${deliveryReceipt.service.number}`}
        />
      )}
      <Container $isCompleted={isCompleted}>
        {isCompleted ? (
          <>
            <TickCircleIcon />
            <Message>Termo de entrega já preenchido!</Message>
          </>
        ) : (
          <>
            <SectionHeader title="Termo de entrega" isFormHeader />
            <AcceptanceOfTerms deliveryReceipt={deliveryReceipt} />
            <DeliveryReceiptForm
              onSubmit={submitDeliveryReceipt}
              isLoading={isSubmitDeliveryReceiptLoading}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default BaseDeliveryReceiptForm;
