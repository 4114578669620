import { color } from 'b2utils';
import { Maximize4 } from 'iconsax-react';
import styled, { css } from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Container = styled.div<{
  $invalidValue?: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.four};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.four)};
  border-radius: ${({ theme }) => theme.spacing.three};
  background-color: ${({ theme }) => theme.colors.white.main};
  border: 1px solid ${({ theme }) => theme.colors.white.three};
  transition: all 0.25s ease-in-out;

  &:active {
    transform: scale(0.99);
  }

  ${({ theme, $invalidValue }) =>
    $invalidValue &&
    css`
      outline: 2px solid ${color.addOpacityOnHexColor(theme.colors.error, 0.1)};
      border: 1px solid ${theme.colors.error};
    `}

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.7;
          &:hover {
            cursor: not-allowed;
          }
        `
      : css`
          &:hover {
            opacity: 0.7;
            cursor: pointer;
          }
        `}
`;

export const ValueContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.one};
`;

export const Value = styled.span<{ defaultValueSelected?: boolean }>`
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ defaultValueSelected, theme }) =>
    defaultValueSelected ? theme.colors.gray.four : theme.colors.black.two};
`;

export const MaximizeIcon = styled(Maximize4)`
  color: ${({ theme }) => theme.colors.gray.six};
  width: ${({ theme }) => theme.fontSize.small};
  height: ${({ theme }) => theme.fontSize.small};
  flex-shrink: 0;
`;

export const ModalBody = styled.div`
  width: 100%;
  padding: ${({ theme }) =>
    addSpacing('0', theme.spacing.eight, theme.spacing.five)};
`;
