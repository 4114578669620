import React, { useEffect, useState } from 'react';

import BaseModal from '@components/BaseModal';
import ChooseItem, { ChooseItemProps } from '@components/ChooseItem';
import ModalFooterButtons from '@components/ModalFooterButtons';

import { Container, MaximizeIcon, ModalBody, Value } from './styles';

export interface ChooseItemFilterProps<
  ItemType extends GenericItem,
  FetchQueryParams extends IQueryParams
> extends ChooseItemProps<ItemType, FetchQueryParams> {
  filterValue: string;
  modalTitle: string;
  invalidValue?: boolean;
  className?: string;
}

const ChooseItemFilter = <
  ItemType extends GenericItem,
  FetchQueryParams extends IQueryParams
>({
  filterValue,
  modalTitle,
  refetchOnWindowFocus,
  invalidValue,
  className,
  ...props
}: ChooseItemFilterProps<ItemType, FetchQueryParams>) => {
  const [temporarilySelectedItem, setTemporarilySelectedItem] = useState(
    props.selectedItem
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSelectCompany = (item: ItemType) => {
    setTemporarilySelectedItem(item);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmModal = () => {
    if (
      temporarilySelectedItem &&
      props.selectedItem?.id !== temporarilySelectedItem?.id
    ) {
      props.onSelect(temporarilySelectedItem);
    }
    handleCloseModal();
  };

  useEffect(() => {
    if (isModalOpen) {
      setTemporarilySelectedItem(props.selectedItem);
    }
  }, [isModalOpen, props.selectedItem]);

  return (
    <>
      <Container
        $invalidValue={invalidValue}
        onClick={() => !props.disabled && setIsModalOpen(true)}
        disabled={props.disabled}
        className={className}
      >
        <Value defaultValueSelected={!props.selectedItem?.id}>
          {filterValue}
        </Value>
        <MaximizeIcon />
      </Container>
      <BaseModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={modalTitle}
      >
        <ModalBody>
          <ChooseItem
            {...props}
            refetchOnWindowFocus={refetchOnWindowFocus}
            shouldRenderSelectedItem={
              props.shouldRenderSelectedItem || !!temporarilySelectedItem?.id
            }
            onSelect={handleSelectCompany}
            selectedItem={temporarilySelectedItem}
          />
        </ModalBody>
        <ModalFooterButtons
          isLoading={false}
          confirmText="Salvar alterações"
          onConfirm={handleConfirmModal}
          cancelText="Cancelar"
          onCancel={handleCloseModal}
        />
      </BaseModal>
    </>
  );
};

export default ChooseItemFilter;
