import React, { useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { MdfeIssuanceType, UnitOfMeasurement } from 'utils/enums';

import { useToast } from '@contexts/Toast';
import { useFiscal } from '@hooks';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';
import { IBaseModalProps } from '@components/BaseModal';
import ClipBoardButton from '@components/ClipBoardButton';
import Loading from '@components/Loading';

import HeaderButtons from './HeaderButtons';
import IssuanceStatusLabel from './IssuanceStatusLabel';
import MdfeIssuanceForm from './MdfeIssuanceForm';
import ServiceList from './ServiceList';
import { ModalContent } from './styles';

type MdfeIssuanceModalProps = Omit<IBaseModalProps, 'title' | 'children'> & {
  departure: IDeparture;
  refetchDeparture: () => void;
};

const MdfeIssuanceModal: React.FC<MdfeIssuanceModalProps> = ({
  isOpen,
  onClose,
  departure,
  refetchDeparture,
}) => {
  const [enableFetchMdfe, setEnableFetchMdfe] = useState(false);

  const { getMdfe } = useFiscal();
  const { addToast } = useToast();

  const {
    data: mdfe,
    isLoading: isMdfeLoading,
    refetch: refetchMdfe,
    isRefetching: isMdfeRefetching,
  } = useQuery(['mdfe', departure.mdfe.id], () => getMdfe(departure.mdfe.id), {
    onSuccess: () => {
      refetchDeparture();
    },
    onError: () => {
      addToast('Não foi possível carregar os dados do MDF-e', 'error');
    },
    placeholderData: {
      id: departure.mdfe.id,
      departure: departure.id,
      issuance_status: departure.mdfe.issuance_status,
      status_message: null,
      issued_at: null,
      number: null,
      issuance_type: MdfeIssuanceType.NORMAL,
      unit_of_measurement: UnitOfMeasurement.KG,
      key: null,
      ns_receipt: null,
      authorization_protocol_number: null,
      pdf_file: null,
      xml_file: null,
      cancel_reason: null,
      canceled_at: null,
      cancel_pdf_file: null,
      cancel_xml_file: null,
      closed_at: null,
      closure_pdf_file: null,
      closure_xml_file: null,
      services_without_cte_qty: 0,
    },
    enabled: enableFetchMdfe,
  });

  useEffect(() => {
    if (isOpen) {
      setEnableFetchMdfe(true);
      if (enableFetchMdfe) {
        refetchMdfe();
      }
    }
  }, [enableFetchMdfe, isOpen, refetchMdfe]);

  const isLoading = isMdfeLoading || !mdfe;

  if (!isOpen) {
    return null;
  }

  return (
    <CustomBaseModal
      title={`Emissão de MDF-e ${
        mdfe?.number
          ? `: série ${departure.company.mdfe_series} / n° ${mdfe.number}`
          : ''
      }`}
      isOpen={isOpen}
      onClose={onClose}
      headerComponent={
        <HeaderButtons mdfe={mdfe} onRequestSuccess={refetchMdfe} />
      }
    >
      <ModalContent>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <IssuanceStatusLabel mdfe={mdfe} isLoading={isMdfeRefetching} />
            {mdfe.key && (
              <ClipBoardButton
                label="Chave do MDF-e"
                value={mdfe.key}
                toastMessage="Chave do MDF-e copiada com sucesso"
                tooltipMessage="Copiar chave"
              />
            )}
            {mdfe.authorization_protocol_number && (
              <ClipBoardButton
                label="Número do protocolo"
                value={mdfe.authorization_protocol_number}
                toastMessage="Número do protocolo copiado com sucesso"
                tooltipMessage="Copiar número"
              />
            )}
            <ServiceList departureId={departure.id} />
            <MdfeIssuanceForm
              mdfe={mdfe}
              departureWeight={departure.weight}
              departureStatus={departure.status}
              onRequestSuccess={refetchMdfe}
            />
          </>
        )}
      </ModalContent>
    </CustomBaseModal>
  );
};

export default MdfeIssuanceModal;
