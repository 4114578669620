import { useCallback } from 'react';

import regex from 'utils/regex';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const generateExpensePaymentRequestObject = (
  expense: IExpensePaymentFormValues
) => {
  return {
    value: expense.value.replace(regex.onlyNumbers, ''),
    accrual_date: expense.accrualDate,
    expenses: expense.expenses.map(({ expense, value }) => ({
      expense: expense.id,
      value: value.replace(regex.onlyNumbers, ''),
    })),
    payment_method: expense.paymentMethod!.id,
    bank_account: expense.bankAccount!.id,
    proof_of_payment: expense.proofOfPayment?.id ?? undefined,
    observations: expense.observations,
  };
};

const useExpensePayments = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const listExpensePayments = useCallback(
    async (params: IExpensePaymentsQueryParams) => {
      const response = await request<IPaginated<IExpensePayment>>({
        method: 'get',
        url: endpoints.financial.expenses.payments.list,
        headers: { scope },
        params: {
          page: params.page,
          search: params.search || undefined,
          provider: params.provider,
          accrual_date__lte: params?.accrualDateLte || undefined,
          accrual_date__gte: params?.accrualDateGte || undefined,
          expense: params.expense || undefined,
          payment_method: params?.paymentMethod?.id || undefined,
          bank_account: params?.bankAccount?.id || undefined,
        },
      });

      return response.data;
    },
    [request, scope]
  );

  const createExpensePayment = useCallback(
    async (values: IExpensePaymentFormValues) => {
      const response = await request<IExpensePayment>({
        method: 'post',
        url: endpoints.financial.expenses.payments.create,
        headers: { scope },
        data: generateExpensePaymentRequestObject(values),
      });

      return response.data;
    },
    [request, scope]
  );

  const deleteExpensePayment = useCallback(
    async (paymentId: number, password: string) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.financial.expenses.payments.delete.replace(
          ':paymentId',
          paymentId.toString()
        ),
        headers: { scope },
        data: { password },
      });

      return response.data;
    },
    [request, scope]
  );

  return {
    listExpensePayments,
    createExpensePayment,
    deleteExpensePayment,
  };
};

export default useExpensePayments;
