import React from 'react';

import {
  ArrowLeftIcon,
  CloseButton,
  Container,
  LeftGroup,
  Title,
} from './styles';

interface HeaderProps {
  title: string;
  isFirstStep: boolean;
  onBack: () => void;
  onClose: () => void;
}

const Header: React.FC<HeaderProps> = ({
  title,
  isFirstStep,
  onBack,
  onClose,
}) => {
  return (
    <Container $isFirstStep={isFirstStep}>
      <LeftGroup $isFirstStep={isFirstStep} onClick={onBack}>
        {!isFirstStep && <ArrowLeftIcon />}
        <Title>{title}</Title>
      </LeftGroup>
      <CloseButton $isFirstStep={isFirstStep} onClick={onClose} />
    </Container>
  );
};

export default Header;
