import { Link1 } from 'iconsax-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LinkIcon = styled(Link1)`
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};
  color: ${({ theme }) => theme.colors.info};
`;
