import Login from '@pages/Login';
import PublicConfirmEmail from '@pages/PublicConfirmEmail';
import PublicDeliveryReceipt from '@pages/PublicDeliveryReceipt';
import ResetPassword from '@pages/ResetPassword';
import UpdatePassword from '@pages/UpdatePassword';
import RoutesPath from '@router/routes';

export default [
  {
    route: RoutesPath.auth.login,
    component: Login,
  },
  {
    route: RoutesPath.auth.managementLogin,
    component: Login,
  },
  {
    route: RoutesPath.auth.resetPassword,
    component: ResetPassword,
  },
  {
    route: RoutesPath.auth.managementResetPassword,
    component: ResetPassword,
  },
  {
    route: RoutesPath.auth.updatePassword,
    component: UpdatePassword,
  },
  {
    route: RoutesPath.private.confirmEmail,
    component: PublicConfirmEmail,
  },
  {
    route: RoutesPath.private.deliveryReceipt,
    component: PublicDeliveryReceipt,
  },
];
