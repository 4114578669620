import React from 'react';

import { Container } from './styles';

interface RootToggleProps {
  isChecked: boolean;
  children: React.ReactNode;
}

const RootToggle: React.FC<RootToggleProps> = ({ isChecked, children }) => {
  return <Container $isChecked={isChecked}>{children}</Container>;
};

export default RootToggle;
