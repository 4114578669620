import React from 'react';

import { FormikProvider, useFormik } from 'formik';
import { B2Card } from 'react-b2components';
import { ServiceType } from 'utils/enums';
import { serviceTypeProps } from 'utils/helpers';
import * as yup from 'yup';

import Input from '@components/Input';
import SectionHeader from '@components/SectionHeader';

import { errors } from '@utils';

import {
  RadioButtonsContainer,
  IconGroup,
  InputGroup,
  Label,
  ButtonsContainer,
  Button,
  CustomFormError,
} from './styles';

interface IServiceTypeType {
  serviceType?: ServiceType;
}

interface IServiceTypeFormProps {
  initialValue?: ServiceType;
  selectedServiceType?: ServiceType;
  setSelectedServiceType: (value: ServiceType) => void;
}

const ServiceTypeForm: React.FC<IServiceTypeFormProps> = ({
  initialValue,
  selectedServiceType,
  setSelectedServiceType,
}) => {
  const servicesTypes = Object.values(ServiceType);

  const formikInitialValues: IServiceTypeType = {
    serviceType: initialValue || selectedServiceType,
  };

  const formikValidationSchema = yup.object().shape({
    serviceType: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: ({ serviceType }) => setSelectedServiceType(serviceType!),
  });

  return (
    <FormikProvider value={formik}>
      <B2Card>
        <SectionHeader title="Selecione um tipo de serviço para prosseguir" />
        <RadioButtonsContainer>
          {servicesTypes.map((type) => {
            const { icon: Icon } = serviceTypeProps[type];
            return (
              <InputGroup
                key={type}
                checked={formik.values.serviceType === type}
                onClick={() => formik.setFieldValue('serviceType', type)}
              >
                <Input
                  type="radio"
                  checked={formik.values.serviceType === type}
                  onChange={() => setSelectedServiceType(type)}
                />
                <IconGroup>
                  {typeof Icon === 'string' ? (
                    <img src={Icon} alt="Ícone do Tipo de serviço" />
                  ) : (
                    <Icon variant="Bold" />
                  )}
                  <Label>{type}</Label>
                </IconGroup>
              </InputGroup>
            );
          })}
        </RadioButtonsContainer>
        <CustomFormError name="serviceType" />
      </B2Card>
      <ButtonsContainer>
        <Button type="button" onClick={() => formik.handleSubmit()}>
          Próximo
        </Button>
      </ButtonsContainer>
    </FormikProvider>
  );
};

export default ServiceTypeForm;
