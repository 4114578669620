import React, { useCallback } from 'react';

import { FormikHelpers } from 'formik';
import { useMutation } from 'react-query';

import { useToast } from '@contexts/Toast';
import { useAssets } from '@hooks';

import Loading from '@components/Loading';

import { Container, CustomImagePicker, DeleteImage } from './styles';

interface ImagePickerProps {
  alt: string;
  src?: string;
  onInvalidExtension: string;
  onInvalidSize: string;
  text: string;
  name: string;
  formik: FormikHelpers<any>;
}

const ImagePicker: React.FC<ImagePickerProps> = ({
  alt,
  onInvalidExtension,
  onInvalidSize,
  src,
  text,
  name,
  formik,
}) => {
  const { getPresignedImageUrl, uploadS3Image, uploadImage } = useAssets();
  const { addToast } = useToast();

  const { mutateAsync: fetchPresignedUrl } = useMutation(getPresignedImageUrl);

  const { mutateAsync: sendS3UrlToApi } = useMutation(uploadImage);

  const { mutateAsync: requestUploadS3Image } = useMutation(
    ({ presignedUrl, file }: { presignedUrl: IPresignedUrl; file: File }) =>
      uploadS3Image(presignedUrl, file)
  );

  const handleRemoveImage = () => {
    formik.setFieldValue(name, {
      id: 0,
      image_high_url: '',
      image_low_url: '',
      image_medium_url: '',
    });
  };

  const handleChooseImage = useCallback(
    async (file: File) => {
      try {
        const presignedUrl = await fetchPresignedUrl();
        await requestUploadS3Image({ presignedUrl, file });
        const result = await sendS3UrlToApi(presignedUrl);
        formik.setFieldValue(name, result);
      } catch (_) {
        addToast(
          'Não foi possível enviar a imagem, tente novamente mais tarde',
          'error'
        );
      }
    },
    [
      addToast,
      fetchPresignedUrl,
      formik,
      name,
      requestUploadS3Image,
      sendS3UrlToApi,
    ]
  );

  return (
    <Container>
      <DeleteImage variant="Bold" onClick={handleRemoveImage} />
      <CustomImagePicker
        text={text}
        imageAlt={alt}
        imageUrl={src}
        extensions={['image/jpg', 'image/jpeg', 'image/png']}
        maxSize={5000000}
        onChooseImage={handleChooseImage}
        onInvalidExtension={() => addToast(onInvalidExtension, 'error')}
        onInvalidSize={() => addToast(onInvalidSize, 'error')}
        loadingComponent={Loading}
      />
    </Container>
  );
};

export default ImagePicker;
