import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { errors } from 'utils';
import * as yup from 'yup';

import BaseUpdatePasswordForm from '@components/BaseUpdatePasswordForm';

interface UpdatePasswordFormProps {
  onFinish: (data: IUpdatePasswordFormValues) => void;
}

const UpdatePasswordForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  UpdatePasswordFormProps
> = ({ onFinish }, ref) => {
  const formikInitialValues: IUpdatePasswordFormValues = {
    password: '',
    confirmPassword: '',
  };

  const formikValidationSchema = yup.object().shape({
    password: yup
      .string()
      .trim()
      .required(errors.required)
      .min(8, errors.minLength(8)),
    confirmPassword: yup
      .string()
      .trim()
      .required(errors.required)
      .min(8, errors.minLength(8))
      .oneOf([yup.ref('password')], errors.passwordConfirm),
  });

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
    resetForm: formik.resetForm,
  }));

  return (
    <FormikProvider value={formik}>
      <BaseUpdatePasswordForm />
    </FormikProvider>
  );
};

export default forwardRef(UpdatePasswordForm);
