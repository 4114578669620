import React from 'react';

import { Badge } from './styles';

interface SituationBadgeProps {
  isActive: boolean;
}
const SituationBadge: React.FC<SituationBadgeProps> = ({ isActive }) => {
  return <Badge situation={isActive}>{isActive ? 'Ativo' : 'Inativo'}</Badge>;
};

export default SituationBadge;
