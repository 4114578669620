import React, { useRef } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useCompanyUsers } from '@hooks';

import BaseForm from '@components/BaseForm';
import CompanyUserForm from '@components/CompanyUserForm';

import RoutesPath from '@router/routes';

import routes from './routes';

const CreateCompanyUser: React.FC = () => {
  const companyUserFormStepRef = useRef<IFormStepRef>(null);

  const { createCompanyUser } = useCompanyUsers();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const handleSubmitCompanyUser = useMutation(
    (companyUserValues: ICompanyUserFormValues) =>
      createCompanyUser(companyUserValues),
    {
      onSuccess: () => {
        addToast('Usuário adicionado com sucesso', 'success');
        navigate(RoutesPath.private.companyUsers.listCompanyUsers.path, {
          replace: true,
        });
      },
      onError: () => {
        addToast('Erro ao adicionar o usuário interno', 'error');
      },
    }
  );

  const steps: Array<IStep> = [
    {
      title: 'Informações do usuário',
      ref: companyUserFormStepRef,
      component: CompanyUserForm,
    },
  ];

  return (
    <BaseForm
      title="Adicionar usuário interno"
      createRequest={handleSubmitCompanyUser}
      steps={steps}
      routes={routes}
    />
  );
};

export default CreateCompanyUser;
