import React, { useCallback, useState } from 'react';

import { cpf } from 'b2utils';
import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { SendingStatus, ServiceStatus } from 'utils/enums';
import { formatToBrlDate } from 'utils/formats';
import { numberOfDaysBetween } from 'utils/helpers';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useQueryParams, useServices } from '@hooks';

import DeliveryReceiptModal from '@components/DeliveryReceiptModal';
import SendingStatusLabel from '@components/SendingStatusLabel';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import Filters from './Filters';

const DeliveryReceiptsList: React.FC = () => {
  const { queryParams, setQueryParams, onSearch, changePage } =
    useQueryParams<IDeliveryReceiptQueryParams>({
      page: 1,
      serviceStatus: [ServiceStatus.WAITING_DELIVERY, ServiceStatus.FINISHED],
    });

  const [selectedDeliveryReceipt, setSelectedDeliveryReceipt] =
    useState<IDeliveryReceipt>();
  const [isDeliveryReceiptModalOpen, setIsDeliveryReceiptModalOpen] =
    useState(false);

  const { listDeliveryReceipts } = useServices();
  const { scope } = useScope();
  const { addToast } = useToast();

  const {
    data: deliveryReceipts,
    refetch: refetchServices,
    isLoading,
  } = useQuery(
    ['deliveryReceipts', queryParams, scope],
    () => listDeliveryReceipts(queryParams),
    {
      onError: () => {
        addToast(
          'Não foi possível carregar a lista de termos de entrega',
          'error'
        );
      },
    }
  );

  const getWaitingDaysText = useCallback(
    ({
      deliveredAt,
      created,
    }: {
      deliveredAt: string | null;
      created: string;
    }) => {
      if (deliveredAt) {
        return `Entregue em ${formatToBrlDate(deliveredAt)}`;
      }

      const numberOfDays = numberOfDaysBetween(new Date(created), new Date());

      return `Aguardando há ${numberOfDays} ${
        numberOfDays === 1 ? 'dia' : `dias`
      }`;
    },
    []
  );

  const getCpfText = useCallback(
    ({
      deliveredAt,
      receiverCpf,
    }: {
      deliveredAt: string | null;
      receiverCpf: string | null;
    }) => {
      if (!deliveredAt) {
        return 'Termo de recebimento pendente';
      }

      if (receiverCpf) {
        return cpf.mask(receiverCpf);
      }

      return 'Termo de recebimento finalizado por envio de arquivo';
    },
    []
  );

  return (
    <>
      <Filters
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onSearch={onSearch}
      />
      <Table
        data={deliveryReceipts?.results || []}
        isLoading={isLoading}
        headerData={[
          'Nº do serviço',
          'Situação',
          'Veículo',
          'Recebedor',
          'CPF',
          'Aguardando',
        ]}
        emptyMessage="Nenhum termo de entrega encontrado"
        renderRow={(deliveryReceipt) => (
          <B2TableRow
            key={deliveryReceipt.id}
            onClick={() => {
              setSelectedDeliveryReceipt(deliveryReceipt);
              setIsDeliveryReceiptModalOpen(true);
            }}
          >
            <TableDataCell>{deliveryReceipt.service.number}</TableDataCell>
            <TableDataCell>
              <SendingStatusLabel status={deliveryReceipt.status} />
            </TableDataCell>
            <TableDataCell>
              {deliveryReceipt.service.vehicle.model} -{' '}
              {deliveryReceipt.service.vehicle.identifier}
            </TableDataCell>
            <TableDataCell>
              {deliveryReceipt.delivered_at
                ? deliveryReceipt?.receiver_name ||
                  'Termo de recebimento finalizado por envio de arquivo'
                : 'Termo de recebimento pendente'}
            </TableDataCell>
            <TableDataCell>
              {getCpfText({
                deliveredAt: deliveryReceipt.delivered_at,
                receiverCpf: deliveryReceipt.cpf,
              })}
            </TableDataCell>
            <TableDataCell>
              {getWaitingDaysText({
                deliveredAt: deliveryReceipt.delivered_at,
                created: deliveryReceipt.created,
              })}
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={deliveryReceipts?.count}
        changePage={changePage}
      />
      {selectedDeliveryReceipt && (
        <DeliveryReceiptModal
          deliveryReceiptId={selectedDeliveryReceipt.id}
          serviceNumber={selectedDeliveryReceipt.service.number}
          hasDelivered={
            selectedDeliveryReceipt.status === SendingStatus.FINISHED
          }
          isOpen={isDeliveryReceiptModalOpen}
          onClose={() => setIsDeliveryReceiptModalOpen(false)}
          onSubmitSuccess={refetchServices}
        />
      )}
    </>
  );
};

export default DeliveryReceiptsList;
