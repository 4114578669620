import { useCallback } from 'react';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const useMovementReports = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const generateMovementReportRequestObject = useCallback(
    (movement: IMovementReportFormValues) => {
      return {
        accrual_date__gte: movement.accrualDateGte || undefined,
        accrual_date__lte: movement.accrualDateLte || undefined,
        type: movement.type || undefined,
        bank_account: movement.bankAccount?.id || undefined,
        origin_bank_account: movement.originBankAccount?.id || undefined,
        destination_bank_account:
          movement.destinationBankAccount?.id || undefined,
        payment_method: movement.paymentMethod?.id || undefined,
      };
    },
    []
  );

  const getMovementReport = useCallback(
    async (id: string) => {
      const response = await request<IFinancialReport>({
        method: 'get',
        url: endpoints.financial.movements.pdfReport.get.replace(':id', id),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const listMovementReport = useCallback(
    async (params: IMovementReportFormValues) => {
      const response = await request<IFinancialReport>({
        method: 'get',
        url: endpoints.financial.movements.pdfReport.list,
        headers: { scope },
        params: generateMovementReportRequestObject(params),
      });

      return response.data;
    },
    [generateMovementReportRequestObject, request, scope]
  );

  return {
    getMovementReport,
    listMovementReport,
    generateMovementReportRequestObject,
  };
};

export default useMovementReports;
