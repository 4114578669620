import React, { useMemo } from 'react';

import { getIn, useField, useFormikContext } from 'formik';
import { useQuery } from 'react-query';
import { CityType } from 'utils/enums';

import { useToast } from '@contexts/Toast';
import { useCities } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Label from '@components/Label';
import Select from '@components/Select';

import { helpers } from '@utils';

type BaseCityFormFormik = {
  [key: string]: ICity | null;
};

interface BaseCityFormProps {
  field: string;
  cityType?: CityType;
}

const BaseCityForm: React.FC<BaseCityFormProps> = ({ field, cityType }) => {
  const formik = useFormikContext<BaseCityFormFormik>();
  const [formikField] = useField<ICity | null>(field);

  const { listCitiesByState } = useCities();
  const { addToast } = useToast();

  const formikValue = formikField.value;

  const findCityAndSetField = (
    cities: Array<ICity>,
    query: {
      cityName?: string;
      cityId?: number;
    }
  ) => {
    const selectedCity = cities.find(
      (city) => city.id === query.cityId || city.name === query.cityName
    );
    if (selectedCity) {
      formik.setFieldValue(field, selectedCity);
    }
  };

  const { data: cities, isLoading: isLoadingCities } = useQuery(
    ['citiesByState', formikValue?.state],
    () => listCitiesByState({ state: formikValue?.state }),
    {
      onSuccess: (cities) => {
        findCityAndSetField(cities, { cityName: formikValue?.name });
      },
      onError: () => {
        addToast('Não foi possível carregar as cidades desse estado', 'error');
      },
      enabled: !!formikValue?.state,
    }
  );

  const handleSelectState = (state?: string) => {
    formik.setFieldValue(field, {
      id: 0,
      name: '',
      state: state || '',
    });
  };

  const handleSelectCity = (cityName: string) => {
    if (cityName && cities) {
      findCityAndSetField(cities, { cityName });
    } else {
      formik.setFieldValue(field, {
        id: 0,
        name: '',
        state: formikValue?.state || '',
      });
    }
  };

  const label = useMemo(() => {
    if (!cityType) {
      return '';
    }

    return cityType === CityType.ORIGIN ? 'de origem' : 'de destino';
  }, [cityType]);

  return (
    <>
      <FormRow>
        <FormGroup>
          <Label htmlFor={`${field}.state`}>Estado {label} *</Label>
          <Select
            id={`${field}.state`}
            name={`${field}.state`}
            value={formikValue?.state || ''}
            onBlur={formik.handleBlur}
            onChange={(event) => handleSelectState(event.target.value)}
            disabled={isLoadingCities}
            invalidValue={
              !!getIn(formik.touched, `${field}.state`) &&
              !!getIn(formik.errors, `${field}.state`)
            }
          >
            {helpers.ufOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <FormError name={`${field}.state`} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor={`${field}.name`}>Cidade {label} *</Label>
          <Select
            id={`${field}.name`}
            name={`${field}.name`}
            value={formikValue?.name || ''}
            onChange={(event) => handleSelectCity(event.target.value)}
            onBlur={formik.handleBlur}
            disabled={isLoadingCities}
            invalidValue={
              !!getIn(formik.touched, `${field}.name`) &&
              !!getIn(formik.errors, `${field}.name`)
            }
          >
            <option value="">Selecione a cidade</option>
            {cities?.map((option) => (
              <option key={option.id} value={option.name}>
                {option.name}
              </option>
            ))}
          </Select>
          <FormError name={`${field}.name`} />
        </FormGroup>
      </FormRow>
      {!formikValue && <FormError name={field} />}
    </>
  );
};

export default BaseCityForm;
