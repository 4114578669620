import React, { useRef, useState } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DriverVehicleType } from 'utils/enums';

import { useToast } from '@contexts/Toast';
import { useDrivers, useVerifyScope } from '@hooks';

import { BaseFormWrapper } from '@components/Base/PrivateBase/styles';
import BaseForm from '@components/BaseForm';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import DriverInfo from '../Form/DriverInfo';
import TrailerInfo from '../Form/TrailerInfo';
import TruckInfo from '../Form/TruckInfo';
import VehicleType from '../Form/VehicleType';
import routes from './routes';

const CreateDriver: React.FC = () => {
  const driverInfoStepFormRef = useRef<IFormStepRef>(null);
  const truckInfoStepFormRef = useRef<IFormStepRef>(null);
  const trailerInfoStepFormRef = useRef<IFormStepRef>(null);

  const [selectedVehicleType, setSelectedVehicleType] =
    useState<DriverVehicleType>();

  const { createDriver } = useDrivers();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const submitCreateDriver = useMutation(
    (driverValues: IDriverFormValues) =>
      createDriver({ ...driverValues, vehicleType: selectedVehicleType! }),
    {
      onSuccess: () => {
        addToast('Motorista cadastrado com sucesso', 'success');
        navigate(RoutesPath.private.drivers.listDrivers.path, {
          replace: true,
        });
      },
      onError: () => {
        addToast('Erro ao cadastrar motorista', 'error');
      },
    }
  );

  useVerifyScope({
    routePath: RoutesPath.private.drivers.listDrivers.path,
    toastErrorMessage: 'Selecione uma empresa para cadastrar um motorista',
  });

  const steps: Array<IStep> = [
    {
      title: 'Informações do motorista',
      ref: driverInfoStepFormRef,
      component: DriverInfo,
    },
    {
      title: 'Informações do caminhão',
      ref: truckInfoStepFormRef,
      component: TruckInfo,
    },
  ];

  if (selectedVehicleType === DriverVehicleType.CAR_CARRIER_TRAILER) {
    steps.push({
      title: 'Informações da carreta',
      ref: trailerInfoStepFormRef,
      component: TrailerInfo,
    });
  }

  return (
    <>
      <PageHeader title="Cadastrar motorista" routes={routes} />
      {!selectedVehicleType && (
        <VehicleType
          selectedServiceType={selectedVehicleType}
          setSelectedServiceType={setSelectedVehicleType}
        />
      )}
      <BaseFormWrapper visible={!!selectedVehicleType}>
        <BaseForm
          steps={steps}
          createRequest={submitCreateDriver}
          onGoBackOnFirstStep={() => setSelectedVehicleType(undefined)}
        />
      </BaseFormWrapper>
    </>
  );
};

export default CreateDriver;
