import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  gap: ${({ theme }) => theme.spacing.six};
  padding: ${({ theme }) => theme.spacing.ten};
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.smaller};

  strong ~ span {
    margin-left: ${({ theme }) => theme.spacing.one};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.five};
`;

export const Logo = styled.img`
  height: 140px;
`;

export const HeaderInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export const MainTitle = styled.h1`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const TitleContainer = styled.div`
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing.two};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.three};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${({ theme }) => `${theme.spacing.four} ${theme.spacing.two}`};
  margin-top: ${({ theme }) => theme.spacing.one};
`;

export const Signature = styled.div`
  width: 50%;
  margin-top: ${({ theme }) => theme.spacing.ten};
  padding-top: ${({ theme }) => theme.spacing.two};
  border-top: 1px solid ${({ theme }) => theme.colors.black.main};
`;
