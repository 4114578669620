import React from 'react';

import { Refresh } from 'iconsax-react';

import { LandingPage } from '@components/SplashScreen/styles';

import { Card, CustomButton, Message } from './styles';

interface ErrorBoundaryProps {
  error: IBoundaryError;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ error }) => {
  return (
    <LandingPage>
      <Card>
        <img
          src={
            error?.serverError
              ? '/images/server-down.svg'
              : '/images/bug-fixing.svg'
          }
          alt="Imagem de erro"
        />
        <Message>
          {error?.serverError
            ? 'Servidor em manutenção, tente novamente mais tarde.'
            : 'Ops! Ocorreu um erro inesperado durante a navegação. Por favor, tente novamente ou entre em contato com o suporte'}
        </Message>
        <CustomButton variant="outline" onClick={() => location.reload()}>
          Atualizar a página
          <Refresh />
        </CustomButton>
      </Card>
    </LandingPage>
  );
};

export default ErrorBoundary;
