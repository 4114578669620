import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { ManagementUserRole } from 'utils/enums';
import * as yup from 'yup';

import UserForm from '@components/UserForm';

import { errors } from '@utils';

interface IManagementUserInfoProps {
  initialValues?: IManagementUserFormValues;
  onFinish: (data: IManagementUserFormValues) => void;
}

const ManagementUserInfo: React.ForwardRefRenderFunction<
  IFormStepRef,
  IManagementUserInfoProps
> = ({ onFinish, initialValues }, ref) => {
  const formikInitialValues: IManagementUserFormValues = {
    avatar: null,
    name: '',
    email: '',
    role: undefined,
    ...initialValues,
  };

  const validationSchema = yup.object().shape({
    name: yup.string().trim().required(errors.required),
    email: yup.string().trim().email(errors.email).required(errors.required),
    role: yup.string().trim().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <UserForm roles={Object.values(ManagementUserRole)} />
    </FormikProvider>
  );
};

export default forwardRef(ManagementUserInfo);
