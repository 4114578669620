import { phone } from 'b2utils';
import { centsToBrl } from 'b2utils/dist/currency';
import { ServiceStatus, ServiceType } from 'utils/enums';
import { formatDate, formatDocument } from 'utils/formats';
import {
  getServiceTakerType,
  isIcmsRateRequired,
  isIcmsReductionRequired,
  isOptingForCreditGrantedRequired,
  toAddressString,
} from 'utils/helpers';

import RoutesPath from '@router/routes';

export const getServiceData = (
  service: IService,
  isClientUser: boolean
): Array<ISection> => {
  const getLastLocationDescription = () => {
    if (!service.last_known_location) {
      return '-';
    }

    if (service.status === ServiceStatus.IN_TRANSIT) {
      return 'Em deslocamento';
    }

    return `${service.last_known_location.name}/${service.last_known_location.state}`;
  };

  const baseServiceData = [
    {
      name: 'Tipo de serviço:',
      description: service.type,
    },
    {
      name: 'Categoria:',
      description: service.category?.name || '',
    },
    {
      name: 'Valor do serviço:',
      description: `${centsToBrl(service.value)}${
        service.income.remaining_value !== 0
          ? ` (${centsToBrl(service.income.remaining_value)})`
          : ''
      }`,
    },
    {
      name: 'Notificar clientes:',
      description: service.notify_client ? 'Sim' : 'Não',
      hasHighlight: true,
    },
    {
      name: 'Vistoria resgatável:',
      description: service.inspection_is_redeemable ? 'Sim' : 'Não',
      hasHighlight: true,
    },
    {
      name: 'Situação:',
      description: service.income.status,
    },
    {
      name: 'Método de pagamento:',
      description: service.income_payment_method
        ? service.income_payment_method.name
        : service.payment_method || 'Sem método de pagamento',
    },
    {
      name: 'Forma de pagamento:',
      description:
        service.payment_terms?.description || 'Sem forma de pagamento',
    },
  ];

  if (service.type === ServiceType.OWN) {
    baseServiceData.push(
      {
        name: 'Tipo de envio:',
        description: service.collection_type || '',
      },
      {
        name: 'Tipo de entrega:',
        description: service.delivery_type || '',
      },
      {
        name: 'Tipo do tomador:',
        description: getServiceTakerType(service),
      }
    );
  }

  if (service.inspection_is_redeemable) {
    baseServiceData.push({
      name: 'Código de vistoria:',
      description: service.inspection.code,
    });
  }

  const baseSenderData = [
    {
      name: 'Nome:',
      description: service.sender ? service.sender.name : '',
    },
    {
      name: 'Email:',
      description: service.sender ? service.sender.email : '',
    },
    {
      name: 'CPF/CNPJ:',
      description: service.sender
        ? formatDocument(service.sender?.document)
        : '',
    },
    {
      name: 'Telefone:',
      description: service.sender ? phone.fromObject(service.sender.phone) : '',
    },
    {
      name: 'Endereço:',
      description: service.collection_address
        ? toAddressString(service.collection_address)
        : '',
    },
  ];

  const baseRecipientData = [
    {
      name: 'Nome:',
      description: service.recipient ? service.recipient.name : '',
    },
    {
      name: 'Email:',
      description: service.recipient ? service.recipient.email : '',
    },
    {
      name: 'CPF/CNPJ:',
      description: service.recipient
        ? formatDocument(service.recipient.document)
        : '',
    },
    {
      name: 'Telefone:',
      description: service.recipient
        ? phone.fromObject(service.recipient.phone)
        : '',
    },
    {
      name: 'Endereço:',
      description: service.delivery_address
        ? toAddressString(service.delivery_address)
        : '',
    },
  ];

  const baseVehicleData = [
    {
      name: 'Marca/Modelo:',
      description: service.vehicle.model,
    },
    {
      name: 'Identificação:',
      description: service.vehicle.identifier,
    },
    {
      name: 'Tipo do veículo:',
      description: service.vehicle.type,
    },
  ];

  const basePersonInChargeData = [
    {
      name: 'Nome:',
      description: service.person_in_charge.name,
    },
    {
      name: 'Email:',
      description: service.person_in_charge.email,
    },
  ];

  const baseDeliveryData = [
    {
      name: 'Origem da entrega:',
      description: `${service.origin.name}/${service.origin.state}`,
    },
  ];

  const baseCteData = [
    {
      name: 'Tipo do ICMS:',
      description: service.icms_type ? service.icms_type : 'Não informado',
    },
    {
      name: 'Alíquota ICMS:',
      description: isIcmsRateRequired(service.icms_type)
        ? `${service.icms_rate}%`
        : 'Não se aplica',
    },
    {
      name: 'Redução:',
      description: isIcmsReductionRequired(service.icms_type)
        ? `${service.icms_reduction}%`
        : 'Não se aplica',
    },
    {
      name: 'Optante pelo crédito outorgado:',
      description: isOptingForCreditGrantedRequired(service.icms_type)
        ? service.opting_for_credit_granted
          ? 'Sim'
          : 'Não'
        : 'Não se aplica',
    },
    {
      name: 'Observações para o CT-e:',
      description: service.cte_general_observations || 'Nenhuma observação',
    },
    {
      name: 'Valor do frete:',
      description: service.cte_freight_cost
        ? centsToBrl(service.cte_freight_cost)
        : 'Não informado',
    },
    {
      name: 'Código:',
      description: service.cfop?.code || '',
    },
    {
      name: 'Descrição:',
      description: service.cfop?.description || '',
    },
  ];

  const lastLocationField = {
    name: 'Localização atual:',
    description: getLastLocationDescription(),
    editHref: RoutesPath.private.services.editLastServiceLocation.path.replace(
      ':serviceId',
      service.id.toString()
    ),
  };

  const serviceObject = {
    [ServiceType.OWN]: [
      {
        title: 'Dados de serviço',
        fields: baseServiceData,
      },
      {
        title: 'Dados do responsável',
        fields: basePersonInChargeData,
      },
      {
        title: 'Informações do remetente',
        fields: baseSenderData,
      },
      {
        title: 'Informações do destinatário',
        fields: baseRecipientData,
      },
      {
        title: 'Informações do veículo',
        fields: [
          ...baseVehicleData,
          {
            name: 'Ano de Fabricação:',
            description: service.vehicle.year || '',
          },
          {
            name: 'Valor do Seguro:',
            description: centsToBrl(service.vehicle.insurance_value),
          },
          {
            name: 'Documento:',
            description: service.vehicle.document || '',
          },
          {
            name: 'Tipo do documento do veículo:',
            description: service.vehicle.document_type || '',
          },
        ],
      },
      {
        title: 'Informações do frete',
        fields: [
          ...baseDeliveryData,
          {
            name: 'Destino da entrega:',
            description: service.destination
              ? `${service.destination.name}/${service.destination.state}`
              : '',
          },
          {
            name: 'Data prevista para coleta:',
            description: service.estimated_collection_date
              ? formatDate(service.estimated_collection_date)
              : '',
          },
          {
            name: 'Data de coleta:',
            description: service.collection_date
              ? formatDate(service.collection_date)
              : 'Não coletado',
          },
          {
            name: 'Tipo de entrega:',
            description: service.delivery_type
              ? service.delivery_type
              : 'Não informado',
          },
          {
            name: 'Mínimo de dias estimados para a entrega:',
            description: service.estimated_min_days_for_delivery ?? '',
          },
          {
            name: 'Máximo de dias estimados para a entrega:',
            description: service.estimated_max_days_for_delivery ?? '',
          },
          {
            name: 'Data de entrega:',
            description: service.delivery_receipt.delivered_at
              ? new Date(
                  service.delivery_receipt.delivered_at
                ).toLocaleDateString('Pt-BR')
              : 'Não entregue',
          },
          lastLocationField,
        ],
      },
    ],
    [ServiceType.STORAGE]: [
      {
        title: 'Dados de serviço',
        fields: baseServiceData,
      },
      {
        title: 'Dados do responsável',
        fields: basePersonInChargeData,
      },
      {
        title: 'Informações do veículo',
        fields: baseVehicleData,
      },
      {
        title: 'Informações do frete',
        fields: [
          {
            name: 'Origem do serviço:',
            description: `${service.origin.name}/${service.origin.state}`,
          },
          {
            name: 'Data de chegada:',
            description: service.collection_date
              ? formatDate(service.collection_date)
              : '',
          },
          {
            name: 'Data de entrega:',
            description: service.delivery_receipt.delivered_at
              ? new Date(
                  service.delivery_receipt.delivered_at
                ).toLocaleDateString('Pt-BR')
              : 'Não entregue',
          },
          lastLocationField,
        ],
      },
    ],
    [ServiceType.THIRD_PARTY]: [
      {
        title: 'Dados de serviço',
        fields: [
          ...baseServiceData,
          {
            name: 'Chave de acesso CT-e:',
            description: service.cte_access_key || 'Não informada',
          },
        ],
      },
      {
        title: 'Dados do responsável',
        fields: basePersonInChargeData,
      },
      {
        title: 'Informações do veículo',
        fields: [
          ...baseVehicleData,
          {
            name: 'Valor do Seguro:',
            description: centsToBrl(service.vehicle.insurance_value),
          },
        ],
      },
      {
        title: 'Informações do frete',
        fields: [
          ...baseDeliveryData,
          {
            name: 'Destino da entrega:',
            description: service.destination
              ? `${service.destination.name}/${service.destination.state}`
              : '',
          },
          {
            name: 'Data prevista para coleta:',
            description: service.estimated_collection_date
              ? formatDate(service.estimated_collection_date)
              : '',
          },
          {
            name: 'Data de coleta:',
            description: service.collection_date
              ? formatDate(service.collection_date)
              : 'Não coletado',
          },
          {
            name: 'Data de entrega:',
            description: service.delivery_receipt.delivered_at
              ? new Date(
                  service.delivery_receipt.delivered_at
                ).toLocaleDateString('Pt-BR')
              : 'Não entregue',
          },
          lastLocationField,
        ],
      },
    ],
  };

  if (!isClientUser) {
    serviceObject[ServiceType.OWN].push({
      title: 'Informações do CT-e',
      fields: baseCteData,
    });
  }

  return serviceObject[service.type];
};
