import React from 'react';

import { Content, Description, FieldName, LinkContainer } from '../styles';

interface LinkInfoProps {
  label: string;
  description?: string;
  href?: string;
}

const LinkInfo: React.FC<LinkInfoProps> = ({ label, description, href }) => {
  return (
    <Content>
      {description ? (
        <>
          <FieldName>{label}</FieldName>
          <LinkContainer
            target="_blank"
            rel="noreferrer"
            href={href || description || '#'}
          >
            {description}
          </LinkContainer>
        </>
      ) : (
        <>
          <FieldName>{label}</FieldName>
          <Description>Não informado</Description>
        </>
      )}
    </Content>
  );
};

export default LinkInfo;
