import React from 'react';

import { AxiosError } from 'axios';
import { phone } from 'b2utils';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { CarrierTypeMap } from 'utils/enums';
import { formatDate, formatDocument } from 'utils/formats';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useDrivers } from '@hooks';

import ContactItem from '@components/ContactItem';

import {
  Container,
  ContactGroup,
  Name,
  HeaderFirstLine,
  CustomInactivateSwitch,
} from './styles';

interface DriverInfoProps {
  driver: IDriver;
}

const DriverInfo: React.FC<DriverInfoProps> = ({ driver }) => {
  const { driverId } = useParams<{ driverId: string }>();

  const { updateIsActiveDriverStatus } = useDrivers();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const { mutate: requestUpdateIsActiveDriverStatus } = useMutation(
    () => updateIsActiveDriverStatus(driverId!, !driver.is_active),
    {
      onSuccess: () => {
        addToast('Situação do motorista atualizada com sucesso', 'success');
        queryClient.invalidateQueries(['driver', driverId]);
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível alterar a situação do motorista', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  return (
    <Container>
      <HeaderFirstLine>
        <Name>{driver.name}</Name>
        <CustomInactivateSwitch
          isActive={driver.is_active}
          onChange={requestUpdateIsActiveDriverStatus}
        />
      </HeaderFirstLine>
      <ContactGroup>
        <ContactItem label="E-mail" value={driver.email || '-'} />
        <ContactItem label="CNPJ/CPF" value={formatDocument(driver.document)} />
        <ContactItem
          label="Data de nascimento"
          value={driver.date_of_birth ? formatDate(driver.date_of_birth) : '-'}
        />
        <ContactItem
          label="Celular"
          value={phone.fromObject({
            areaCode: driver.phone.area_code,
            number: driver.phone.number,
          })}
        />
        <ContactItem
          label="Tipo do transportador"
          value={CarrierTypeMap[driver.carrier_type]}
        />
      </ContactGroup>
    </Container>
  );
};

export default DriverInfo;
