import { ArrowLeft, CloseCircle } from 'iconsax-react';
import styled, { css } from 'styled-components';
import { addSpacing, modifyColor } from 'utils/styles';

interface HeaderProps {
  $isFirstStep: boolean;
}

export const Container = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.four};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white.three};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.four, theme.spacing.eight)};
  transition: background-color 0.3s !important;

  ${({ $isFirstStep }) =>
    !$isFirstStep &&
    css`
      background-color: ${({ theme }) =>
        modifyColor({ hex: theme.colors.main, lightness: 0.93 })};
    `}
`;

export const LeftGroup = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};

  ${({ $isFirstStep }) =>
    !$isFirstStep &&
    css`
      transition: opacity 0.3s !important;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    `}
`;

export const ArrowLeftIcon = styled(ArrowLeft)`
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.main};
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.main};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const CloseButton = styled(CloseCircle)<HeaderProps>`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.six};
  height: ${({ theme }) => theme.spacing.six};
  color: ${({ theme, $isFirstStep }) =>
    $isFirstStep ? theme.colors.gray.four : theme.colors.main};
  transition: all 0.3s !important;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
