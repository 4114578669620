import React, { useRef } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useBankAccounts, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';
import Loading from '@components/Loading';

import RoutesPath from '@router/routes';

import BankAccountInfo from '../Form/BankAccountInfo';
import getRoutes from './routes';

const UpdateBankAccount: React.FC = () => {
  const { bankAccountId } = useParams<{ bankAccountId: string }>();

  const bankAccountFormRef = useRef<IFormStepRef>(null);

  const { getBankAccount, updateBankAccount } = useBankAccounts();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useVerifyScope({
    routePath: RoutesPath.private.bankAccounts.listBankAccounts.path,
    toastErrorMessage: 'Selecione uma empresa para editar uma conta bancária',
  });

  const {
    data: bankAccount,
    refetch,
    isLoading,
  } = useQuery(
    ['bankAccount', bankAccountId],
    () => getBankAccount(bankAccountId!),
    {
      onError: () => {
        addToast('Falha ao buscar os dados da conta bancária', 'error');
      },
    }
  );

  const updateBankAccountRequest = useMutation(
    (values: IBankAccountFormValues) =>
      updateBankAccount(bankAccountId!, values),
    {
      onSuccess: () => {
        refetch();
        addToast('Dados da conta bancária atualizados com sucesso', 'success');
        navigate(
          RoutesPath.private.bankAccounts.listBankAccounts.path.replace(
            ':bankAccountId',
            bankAccountId!
          )
        );
      },
      onError: () => {
        addToast(
          'Erro ao tentar editar informações da conta bancária',
          'error'
        );
      },
    }
  );

  const bankAccountInfoInitialValues: IBankAccountFormValues = {
    name: bankAccount?.name || '',
    initialBalance: bankAccount?.initial_balance?.toString() || '',
    type: bankAccount?.type,
    isMain: bankAccount?.is_main || false,
  };

  const steps: Array<IStep> = [
    {
      title: 'Informações da conta bancária',
      ref: bankAccountFormRef,
      component: BankAccountInfo,
    },
  ];

  if (isLoading || !bankAccount) {
    return <Loading />;
  }

  return (
    <BaseForm
      steps={steps}
      createRequest={updateBankAccountRequest}
      routes={getRoutes(bankAccountId!)}
      title="Editar conta bancária"
      initialValues={bankAccountInfoInitialValues}
    />
  );
};

export default UpdateBankAccount;
