import React, { useRef } from 'react';

import { cnpj, phone } from 'b2utils';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useCompanies } from '@hooks';

import BaseForm from '@components/BaseForm';
import CompanyInfo from '@components/CompanyForm/CompanyInfo';
import InsuranceForm from '@components/InsuranceForm';
import Loading from '@components/Loading';
import SingleAddressForm from '@components/SingleAddressForm';
import TransportForm from '@components/TransportForm';

import RoutesPath from '@router/routes';

import routes from './routes';

const UpdateCompany: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { updateCompany, getCompany } = useCompanies();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const FormInfoStepRef = useRef(null);
  const FormAddressStepRef = useRef(null);
  const FormInsuranceStepRef = useRef(null);
  const FormTransportStepRef = useRef(null);

  const { isLoading, data: company } = useQuery(
    ['company', companyId],
    () => getCompany(companyId!),
    {
      onError: () => {
        addToast('Não foi possível carregar os dados da empresa', 'error');
        navigate(RoutesPath.private.companies.listCompanies.path, {
          replace: true,
        });
      },
      enabled: !!companyId,
    }
  );

  const handleSubmitCompany = useMutation(
    (companyValues: ICompanyFormRequest) => {
      const hasDigitalCertificateChanged =
        company?.digital_certificate?.id !==
        companyValues.digitalCertificate?.id;

      if (hasDigitalCertificateChanged) {
        return updateCompany(companyId!, companyValues);
      }

      return updateCompany(companyId!, {
        ...companyValues,
        digitalCertificate: undefined,
        digitalCertificatePassword: undefined,
      });
    },
    {
      onSuccess: () => {
        addToast('Empresa atualizada com sucesso', 'success');
        navigate(
          RoutesPath.private.companies.detailCompany.path.replace(
            ':companyId',
            companyId!
          )
        );
      },
      onError: () => {
        addToast('Erro ao atualizar os dados empresa', 'error');
      },
    }
  );

  if (!company) return null;

  const steps: Array<IStep> = [
    {
      title: 'Informações básicas',
      ref: FormInfoStepRef,
      component: CompanyInfo,
      props: {
        validateValues: {
          email: company.email,
          scope: company.scope,
        },
      },
    },
    {
      title: 'Endereço',
      ref: FormAddressStepRef,
      component: SingleAddressForm,
    },
    {
      title: 'Informações de seguro',
      ref: FormInsuranceStepRef,
      component: InsuranceForm,
      props: {
        initialAtmUsername: company.atm_username,
        initialAtmCod: company.atm_cod,
      },
    },
    {
      title: 'Emissão de CT-e & MDF-e',
      ref: FormTransportStepRef,
      component: TransportForm,
      props: {
        initialDigitalCertificateId: company.digital_certificate?.id,
      },
    },
  ];

  const initialValues: ICompanyFormRequest | undefined = {
    logo: company.logo,
    name: company.name,
    email: company.email,
    scope: company.scope,
    antt: company.antt,
    primaryColor: company.primary_color,
    secondaryColor: company.secondary_color,
    stateRegistration: company.state_registration,
    phone: phone.fromObject({
      areaCode: company.phone.area_code,
      number: company.phone.number,
    }),
    webSiteUrl: company.website_url,
    budgetExpiration: company.budget_expiration.toString(),
    cnpj: cnpj.mask(company.cnpj),
    insurerName: company.insurer_name,
    insurerCnpj: cnpj.mask(company.insurer_cnpj),
    insurerPolicyNumber: company.insurer_policy_number,
    insurerValueLimitPerLoad:
      company.insurer_value_limit_per_load.toString() || '',
    isLoadUnloadingOperation: company.is_load_unloading_operation,
    isLoadLifting: company.is_load_lifting,
    isInternalRemoval: company.is_internal_removal,
    isTracked: company.is_tracked,
    isEscorted: company.is_escorted,
    isSelfOwnedVehicle: company.is_self_owned_vehicle,
    atmUsername: company.atm_username ?? '',
    atmCod: company.atm_cod ?? '',
    atmPassword: '',
    digitalCertificate: company.digital_certificate,
    digitalCertificatePassword: '',
    cteEmissionIsEnabled: company.cte_emission_is_enabled,
    cteSeries: company.cte_series || '',
    initialCteNumber: company.initial_cte_number || '',
    lastCteNumber: company.last_cte_number || '',
    mdfeEmissionIsEnabled: company.mdfe_emission_is_enabled,
    mdfeSeries: company.mdfe_series || '',
    initialMdfeNumber: company.initial_mdfe_number || '',
    lastMdfeNumber: company.last_mdfe_number || '',
    taxRegime: company.tax_regime,
    taxRegimeCode: company.tax_regime_code,
    rates: company.rates?.length ? company.rates : [''],
    hasDigitalCertificatePassword: company.has_digital_certificate_password,
    address: {
      city: company.address.city,
      street: company.address.street,
      number: company.address.number,
      zipCode: company.address.zip_code,
      complement: company.address.additional_info || '',
      district: company.address.district,
    },
  };

  if (isLoading) return <Loading />;

  return (
    <BaseForm
      createRequest={handleSubmitCompany}
      steps={steps}
      title="Editar empresa"
      routes={routes(companyId!)}
      initialValues={initialValues}
    />
  );
};

export default UpdateCompany;
