import { B2Card } from 'react-b2components';
import styled from 'styled-components';

export const Card = styled(B2Card)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.four};
  padding: ${({ theme }) => theme.spacing.five};

  div:nth-child(2) {
    border: none;
    padding-top: 0;
  }
`;

export const DocumentGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.four};
  padding-top: ${({ theme }) => theme.spacing.four};
  border-top: 1px solid ${({ theme }) => theme.colors.white.three};

  a {
    margin: 0;
  }

  @media (max-width: 375px) {
    flex-direction: column;
    align-items: flex-start;

    a {
      align-self: center;
    }
  }
`;

export const Label = styled.label`
  width: 140px;
  color: ${({ theme }) => theme.colors.black.one};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const EmptyText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.gray.eight};
`;
