import React, { useRef } from 'react';

import { cep } from 'b2utils';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useDepartures, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';
import Loading from '@components/Loading';

import RoutesPath from '@router/routes';

import DepartureInfo from '../Form/DepartureInfo';
import DriverInfo from '../Form/DriverInfo';
import ServiceList from '../Form/ServiceList';
import getRoutes from './routes';

const UpdateDeparture: React.FC = () => {
  const { departureId } = useParams<{ departureId: string }>();

  const driverInfoStepFormRef = useRef<IFormStepRef>(null);
  const departureInfoStepFormRef = useRef<IFormStepRef>(null);
  const departureServiceListStepFormRef = useRef<IFormStepRef>(null);

  const { getDeparture, updateDeparture } = useDepartures();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useVerifyScope({
    routePath: RoutesPath.private.departures.listDepartures.path,
    toastErrorMessage: 'Selecione uma empresa para editar um embarque',
  });

  const { data: departure, isLoading: isDepartureLoading } = useQuery(
    ['departure', departureId],
    () => getDeparture(departureId!),
    {
      onError: () => {
        addToast('Falha ao buscar os dados do embarque', 'error');
      },
    }
  );

  const updateDepartureRequest = useMutation(
    (departure: IDepartureFormValues) =>
      updateDeparture(departureId!, departure),
    {
      onSuccess: () => {
        addToast('Dados do embarque atualizados com sucesso', 'success');
        navigate(
          RoutesPath.private.departures.detailDeparture.path.replace(
            ':departureId',
            departureId!
          )
        );
      },
      onError: () => {
        addToast('Erro ao tentar editar informações do embarque', 'error');
      },
    }
  );

  const departureInitialValues: IDepartureFormValues = {
    provider: departure?.provider || null,
    driver: departure?.driver || null,
    ownership: departure?.ownership,
    loadingZipCode: cep.mask(departure?.loading_zip_code || ''),
    origin: departure?.origin || {
      id: 0,
      name: '',
      state: '',
    },
    destinationZipCode: cep.mask(departure?.destination_zip_code || ''),
    destination: departure?.destination || {
      id: 0,
      name: '',
      state: '',
    },
    date: departure?.date || '',
    routeStates: departure?.route_states || [],
    routeObservations: departure?.route_observations || '',
    services: departure?.services || [],
  };

  const steps: Array<IStep> = [
    {
      title: 'Informações do motorista',
      ref: driverInfoStepFormRef,
      component: DriverInfo,
    },
    {
      title: 'Informações do embarque',
      ref: departureInfoStepFormRef,
      component: DepartureInfo,
    },
    {
      title: 'Veículos do embarque',
      ref: departureServiceListStepFormRef,
      component: ServiceList,
    },
  ];

  if (isDepartureLoading || !departure) {
    return <Loading />;
  }

  return (
    <BaseForm
      steps={steps}
      createRequest={updateDepartureRequest}
      routes={getRoutes(departureId!)}
      title="Editar embarque"
      initialValues={departureInitialValues}
    />
  );
};

export default UpdateDeparture;
