import React, { useRef, useState } from 'react';

import { phone } from 'b2utils';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { DriverVehicleType, StateRegistrationStatus } from 'utils/enums';
import { formatDocument } from 'utils/formats';

import { useToast } from '@contexts/Toast';
import { useDrivers, useVerifyScope } from '@hooks';

import { BaseFormWrapper } from '@components/Base/PrivateBase/styles';
import BaseForm from '@components/BaseForm';
import Loading from '@components/Loading';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import DriverInfo from '../Form/DriverInfo';
import TrailerInfo from '../Form/TrailerInfo';
import TruckInfo from '../Form/TruckInfo';
import VehicleType from '../Form/VehicleType';
import getRoutes from './routes';

const UpdateDriver: React.FC = () => {
  const { driverId } = useParams<{ driverId: string }>();

  const driverInfoStepFormRef = useRef<IFormStepRef>(null);
  const truckInfoStepFormRef = useRef<IFormStepRef>(null);
  const trailerInfoStepFormRef = useRef<IFormStepRef>(null);

  const [selectedVehicleType, setSelectedVehicleType] =
    useState<DriverVehicleType>();
  const [initialVehicleType, setInitialVehicleType] =
    useState<DriverVehicleType>();

  const { getDriver, updateDriver } = useDrivers();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useVerifyScope({
    routePath: RoutesPath.private.drivers.listDrivers.path,
    toastErrorMessage: 'Selecione uma empresa para editar um motorista',
  });

  const { data: driver, isLoading } = useQuery(
    ['driver', driverId],
    () => getDriver(driverId!),
    {
      onSuccess: (data) => {
        setInitialVehicleType(data.truck.vehicle_type);
      },
      onError: () => {
        addToast('Falha ao buscar os dados do motorista', 'error');
      },
    }
  );

  const updateDriverRequest = useMutation(
    (driverValues: IDriverFormValues) =>
      updateDriver(driverId!, {
        ...driverValues,
        vehicleType: selectedVehicleType!,
      }),
    {
      onSuccess: () => {
        addToast('Dados do motorista atualizados com sucesso', 'success');
        navigate(-1);
      },
      onError: () => {
        addToast('Erro ao tentar editar informações do motorista', 'error');
      },
    }
  );

  const driverInfoInitialValues: IDriverFormValues = {
    name: driver?.name || '',
    email: driver?.email || '',
    document: driver?.document ? formatDocument(driver?.document) : '',
    dateOfBirth: driver?.date_of_birth || '',
    phone: driver?.phone
      ? phone.fromObject({
          areaCode: driver.phone.area_code,
          number: driver.phone.number,
        })
      : '',
    carrierType: driver?.carrier_type,
    driversLicenseFile: driver?.drivers_license_file,
    vehicleType: driver?.truck.vehicle_type,
    truckLicensePlate: driver?.truck.truck_license_plate || '',
    truckLicenseState: driver?.truck.truck_license_state || '',
    truckTareWeight: driver?.truck.truck_tare_weight.toString() || '',
    antt: driver?.truck.antt || '',
    anttFile: driver?.truck.antt_file,
    truckCrlvFile: driver?.truck.truck_crlv_file,
    trailerLicensePlate: driver?.truck.trailer_license_plate || '',
    trailerLicenseState: driver?.truck.trailer_license_state || '',
    trailerTareWeight: driver?.truck.trailer_tare_weight?.toString() || '',
    trailerPayloadCapacity:
      driver?.truck.trailer_payload_capacity?.toString() || '',
    trailerCrlvFile: driver?.truck.trailer_crlv_file || undefined,
    vehicleOwnerName: driver?.truck.vehicle_owner_name || '',
    vehicleOwnerDocument: driver?.truck.vehicle_owner_document
      ? formatDocument(driver.truck.vehicle_owner_document)
      : '',
    stateRegistrationStatus:
      driver?.truck.state_registration_status ||
      StateRegistrationStatus.NON_TAXPAYER,
    stateRegistration: driver?.truck.state_registration || '',
  };

  const isCarCarrierTrailer =
    selectedVehicleType === DriverVehicleType.CAR_CARRIER_TRAILER;

  const steps: Array<IStep> = [
    {
      title: 'Informações do motorista',
      ref: driverInfoStepFormRef,
      component: DriverInfo,
    },
    {
      title: 'Informações do caminhão',
      ref: truckInfoStepFormRef,
      component: TruckInfo,
    },
  ];

  if (isCarCarrierTrailer) {
    steps.push({
      title: 'Informações da carreta',
      ref: trailerInfoStepFormRef,
      component: TrailerInfo,
    });
  }

  if (isLoading || !driver) {
    return <Loading />;
  }

  return (
    <>
      <PageHeader title="Editar motorista" routes={getRoutes(driverId!)} />
      {!selectedVehicleType && (
        <VehicleType
          initialValue={initialVehicleType}
          selectedServiceType={selectedVehicleType}
          setSelectedServiceType={setSelectedVehicleType}
        />
      )}
      <BaseFormWrapper visible={!!selectedVehicleType}>
        <BaseForm
          steps={steps}
          createRequest={updateDriverRequest}
          initialValues={driverInfoInitialValues}
          onGoBackOnFirstStep={() => {
            setInitialVehicleType(selectedVehicleType);
            setSelectedVehicleType(undefined);
          }}
        />
      </BaseFormWrapper>
    </>
  );
};

export default UpdateDriver;
