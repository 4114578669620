import React from 'react';

import { ExpenseType, ExpenseTypeMap } from 'utils/enums';

import { Container, Label, Square } from './styles';

interface ExpenseTypeLabelProps {
  type: ExpenseType;
}

const ExpenseTypeLabel: React.FC<ExpenseTypeLabelProps> = ({ type }) => {
  return (
    <Container title={type}>
      <Square type={type} />
      <Label type={type}>{ExpenseTypeMap[type]}</Label>
    </Container>
  );
};

export default ExpenseTypeLabel;
