import React, { useCallback, useRef } from 'react';

import { useMutation } from 'react-query';

import { useToast } from '@contexts/Toast';
import { useAssets } from '@hooks';

import { Button, CustomLoading, Icon, Input } from './styles';

interface DocumentPickerProps {
  onAddFile: (file: IFile) => void;
}
const DocumentPicker: React.FC<DocumentPickerProps> = ({ onAddFile }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { getPresignedFileUrl, uploadS3File, uploadFile } = useAssets();
  const { addToast } = useToast();

  const onError = useCallback(() => {
    addToast(
      'Não foi possível enviar o arquivo, tente novamente mais tarde',
      'error'
    );
  }, [addToast]);

  const { mutateAsync: fetchPresignedUrl, isLoading: isLoadingURL } =
    useMutation(getPresignedFileUrl, { onError });

  const { mutate: sendS3UrlToApi, isLoading: isLoadingAPI } = useMutation(
    uploadFile,
    {
      onSuccess: onAddFile,
      onError,
      onSettled: () => {
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      },
    }
  );

  const { mutate: requestUploadS3File, isLoading: isLoadingS3 } = useMutation(
    ({ presignedUrl, file }: { presignedUrl: IPresignedUrl; file: File }) =>
      uploadS3File(presignedUrl, file),
    {
      onSuccess: (_, variables) => {
        sendS3UrlToApi(variables.presignedUrl);
      },

      onError,
    }
  );

  const handleInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (!file) {
        return;
      }

      if (file.type.includes('video')) {
        addToast('O arquivo não pode ser um vídeo', 'error');
        return;
      }

      const extension = file.type.split('/')[1];

      const presignedUrl = await fetchPresignedUrl(extension);
      requestUploadS3File({ presignedUrl, file });
    },
    [addToast, fetchPresignedUrl, requestUploadS3File]
  );

  const isLoading = isLoadingURL || isLoadingAPI || isLoadingS3;

  return (
    <Button onClick={() => inputRef.current?.click()}>
      {isLoading ? (
        <CustomLoading />
      ) : (
        <>
          Anexar documento <Icon variant="Bold" />
        </>
      )}
      <Input
        ref={inputRef}
        type="file"
        accept="*"
        onChange={handleInputChange}
      />
    </Button>
  );
};

export default DocumentPicker;
