import { useCallback } from 'react';

import regex from 'utils/regex';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const generateIncomePaymentRequestObject = (
  income: IIncomePaymentFormValues
) => {
  return {
    client: income.client || undefined,
    value: income.value.replace(regex.onlyNumbers, ''),
    accrual_date: income.accrualDate,
    incomes: income.incomes.map(({ income, value }) => ({
      income: income.id,
      value: value.replace(regex.onlyNumbers, ''),
    })),
    payment_method: income.paymentMethod!.id,
    bank_account: income.bankAccount!.id,
    observations: income.observations,
  };
};

const useIncomePayments = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const listIncomePayments = useCallback(
    async (params: IIncomePaymentsQueryParams) => {
      const response = await request<IPaginated<IIncomePayment>>({
        method: 'get',
        url: endpoints.financial.incomes.payments.list,
        headers: { scope },
        params: {
          page: params.page,
          search: params.search || undefined,
          accrual_date__lte: params?.accrualDateLte || undefined,
          accrual_date__gte: params?.accrualDateGte || undefined,
          income: params.income || undefined,
          client: params.client || undefined,
          payment_method: params?.paymentMethod?.id || undefined,
          bank_account: params?.bankAccount?.id || undefined,
        },
      });

      return response.data;
    },
    [request, scope]
  );

  const createIncomePayment = useCallback(
    async (values: IIncomePaymentFormValues) => {
      const response = await request<IIncomePayment>({
        method: 'post',
        url: endpoints.financial.incomes.payments.create,
        headers: { scope },
        data: generateIncomePaymentRequestObject(values),
      });

      return response.data;
    },
    [request, scope]
  );

  const deleteIncomesPayment = useCallback(
    async (paymentId: number, password: string) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.financial.incomes.payments.delete.replace(
          ':paymentId',
          paymentId.toString()
        ),
        headers: { scope },
        data: { password },
      });

      return response.data;
    },
    [request, scope]
  );

  return {
    listIncomePayments,
    createIncomePayment,
    deleteIncomesPayment,
  };
};

export default useIncomePayments;
