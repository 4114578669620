import React, { useRef } from 'react';

import { UseMutationResult } from 'react-query';

import BaseForm from '@components/BaseForm';

import TransferInfoForm from './TransferInfoForm';

interface ITransferFormProps {
  onFinish: UseMutationResult<ITransfer, unknown, ITransferFormValues, unknown>;
  onGoBackOnFirstStep: () => void;
  initialValues?: ITransferFormValues;
  isCapitalInjection?: boolean;
}

const TransferForm: React.FC<ITransferFormProps> = ({
  onFinish,
  onGoBackOnFirstStep,
  initialValues,
  isCapitalInjection,
}) => {
  const transferInfoFormRef = useRef<IFormStepRef>(null);

  const handleGoBackOnFirstStep = () => {
    onGoBackOnFirstStep();
    transferInfoFormRef.current?.resetForm?.();
  };

  return (
    <BaseForm
      initialValues={{ ...initialValues, isCapitalInjection }}
      onGoBackOnFirstStep={handleGoBackOnFirstStep}
      createRequest={onFinish}
      steps={[
        {
          title: `Informações ${
            isCapitalInjection ? 'do aporte' : 'da transferência'
          }`,
          ref: transferInfoFormRef,
          component: TransferInfoForm,
          props: { isCapitalInjection },
        },
      ]}
    />
  );
};

export default TransferForm;
