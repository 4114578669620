import React, { useMemo } from 'react';

import {
  ExpensePaymentStatusFilterMap,
  FinancialReportType,
  PaymentStatusFilterMap,
} from 'utils/enums';

import Select from '@components/Select';

interface PaymentStatusFilterProps {
  type: FinancialReportType;
  queryParams: IFinancialQueryParams;
  setQueryParams: React.Dispatch<React.SetStateAction<IFinancialQueryParams>>;
}

const PaymentStatusFilter: React.FC<PaymentStatusFilterProps> = ({
  type,
  queryParams,
  setQueryParams,
}) => {
  const paymentStatusFilterValues = Object.values(PaymentStatusFilterMap);
  const paymentStatusFilterKeys = useMemo(() => {
    if (type === FinancialReportType.INCOME) {
      return Object.keys(PaymentStatusFilterMap);
    }
    return Object.keys(ExpensePaymentStatusFilterMap);
  }, [type]);

  const handleSelectServiceStatus = (status: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      status: status || undefined,
    }));
  };

  return (
    <Select
      value={queryParams?.status || ''}
      onChange={(event) => handleSelectServiceStatus(event.target.value)}
    >
      <option value="">Situação do pagamento: todas</option>
      {paymentStatusFilterValues.map((status, index) => (
        <option key={status} value={status}>
          {paymentStatusFilterKeys[index]}
        </option>
      ))}
    </Select>
  );
};

export default PaymentStatusFilter;
