import { B2Button, B2Card } from 'react-b2components';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Container = styled(B2Card)`
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.five, theme.spacing.eight)};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.three};
  margin-bottom: ${({ theme }) => theme.spacing.four};

  @media (max-width: 1100px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const Title = styled.h2`
  margin-left: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const CustomButton = styled(B2Button)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.one};

  svg {
    flex-shrink: 0;
    width: ${({ theme }) => theme.spacing.five};
    height: ${({ theme }) => theme.spacing.five};
  }
`;
