import React, { useCallback } from 'react';

import { useFormikContext } from 'formik';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Label from '@components/Label';
import Select from '@components/Select';

import { helpers } from '@utils';

import State from './State';
import { Option, RoutesContainer } from './styles';

const StatesSelect: React.FC = () => {
  const formik = useFormikContext<IDepartureInfoStepForm>();

  const isStateSelected = useCallback(
    (state: string) => formik.values.routeStates.includes(state),
    [formik.values.routeStates]
  );

  const selectNewState = useCallback(
    (state: string) => {
      formik.setFieldValue('routeStates', [
        ...formik.values.routeStates,
        state,
      ]);
    },
    [formik]
  );

  const unselectState = useCallback(
    (state: string) => {
      const newStates = formik.values.routeStates.filter(
        (item) => item !== state
      );
      formik.setFieldValue('routeStates', newStates);
    },
    [formik]
  );

  const handleSelectState = useCallback(
    (state: string) => {
      if (isStateSelected(state)) {
        unselectState(state);
      } else {
        selectNewState(state);
      }
    },
    [isStateSelected, selectNewState, unselectState]
  );

  return (
    <>
      <FormRow>
        <FormGroup>
          <Label htmlFor="routeStates">Estados do percurso</Label>
          <Select
            id="routeStates"
            name="routeStates"
            onBlur={formik.handleBlur}
            value=""
            onChange={(event) =>
              event.target.value && handleSelectState(event.target.value)
            }
            invalidValue={
              !!formik.touched.routeStates && !!formik.errors.routeStates
            }
          >
            {helpers.ufOptions.map((option) => (
              <Option
                key={option.value}
                value={option.value}
                isSelected={isStateSelected(option.value)}
              >
                {option.label}
              </Option>
            ))}
          </Select>
          <FormError name="routeStates" />
        </FormGroup>
      </FormRow>
      <RoutesContainer>
        {formik.values.routeStates.map((state) => (
          <State
            key={state}
            state={state}
            onClick={() => unselectState(state)}
          />
        ))}
      </RoutesContainer>
    </>
  );
};

export default StatesSelect;
