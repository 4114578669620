import React, { useEffect, useState } from 'react';

import { DocumentCode, DocumentDownload, DocumentText } from 'iconsax-react';
import { formatToBrlDate } from 'utils/formats';

import DownloadAnchor from '@components/DownloadAnchor';

import { CustomTooltip } from '../styles';
import {
  ArrowIcon,
  CustomButton,
  VerticalDivider,
  OptionsContainer,
  OptionsWrapper,
  HorizontalDivider,
  ButtonGroup,
} from './styles';

interface DownloadsButtonProps {
  cte: ICte;
}

const DownloadsButton: React.FC<DownloadsButtonProps> = ({ cte }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    cte.rectifications.reverse();
  });

  return (
    <>
      <CustomTooltip id="download-cte-documents-tooltip" />
      <CustomButton
        data-tooltip-id="download-cte-documents-tooltip"
        data-tooltip-content={isExpanded ? undefined : 'Baixar documentos'}
        $expanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <DocumentDownload variant="Bold" />
        <span>Baixar documentos</span>
        <ButtonGroup>
          <VerticalDivider />
          <ArrowIcon $expanded={isExpanded} />
        </ButtonGroup>
      </CustomButton>
      <OptionsContainer $expanded={isExpanded}>
        <OptionsWrapper>
          <DownloadAnchor
            href={cte.pdf_file?.url || ''}
            label="CT-e em PDF"
            icon={DocumentText}
          />
          <HorizontalDivider />
          <DownloadAnchor
            href={cte.xml_file?.url || ''}
            label="CT-e em XML"
            icon={DocumentCode}
          />
          {cte.rectifications.map((rectification) => (
            <React.Fragment key={rectification.id}>
              <HorizontalDivider />
              <DownloadAnchor
                href={rectification.pdf_file.url}
                label={`Retificação em PDF ${formatToBrlDate(
                  rectification.received_at
                )}`}
                icon={DocumentText}
              />
              <HorizontalDivider />
              <DownloadAnchor
                href={rectification.xml_file.url}
                label={`Retificação em XML ${formatToBrlDate(
                  rectification.received_at
                )}`}
                icon={DocumentText}
              />
            </React.Fragment>
          ))}
          {cte.pdf_cancel_file && (
            <>
              <HorizontalDivider />
              <DownloadAnchor
                href={cte.pdf_cancel_file.url}
                label="PDF de cancelamento"
                icon={DocumentText}
              />
            </>
          )}
          {cte.xml_cancel_file && (
            <>
              <HorizontalDivider />
              <DownloadAnchor
                href={cte.xml_cancel_file.url}
                label="XML de cancelamento"
                icon={DocumentCode}
              />
            </>
          )}
        </OptionsWrapper>
      </OptionsContainer>
    </>
  );
};

export default DownloadsButton;
