import React, { forwardRef, useImperativeHandle } from 'react';

import { currency } from 'b2utils';
import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { MovementType } from 'utils/enums';
import { formatDate } from 'utils/formats';

import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useMovements, useQueryParams } from '@hooks';

import ModalTable from '@components/ModalTable';
import { TableDataCell } from '@components/Table/styles';

import { PaymentDescription } from '@pages/Clients/DetailClient/IncomesPaymentsList/styles';

interface MovementListingProps {
  initialValues: IMovementReportFormValues;
  onFinish: () => void;
}

const MovementListing: React.ForwardRefRenderFunction<
  IFormStepRef,
  MovementListingProps
> = ({ initialValues, onFinish }, ref) => {
  const { queryParams, changePage } = useQueryParams<IMovementQueryParams>({
    page: 1,
    accrualDateGte: initialValues.accrualDateGte,
    accrualDateLte: initialValues.accrualDateLte,
    type: initialValues.type || undefined,
    bankAccount: initialValues.bankAccount,
    originBankAccount: initialValues.originBankAccount,
    destinationBankAccount: initialValues.destinationBankAccount,
    paymentMethod: initialValues.paymentMethod,
  });

  const { listMovements } = useMovements();
  const { scope } = useScope();
  const { addToast } = useToast();

  const { data: movements, isLoading } = useQuery(
    ['incomes', queryParams, scope],
    () => listMovements(queryParams),
    {
      onError: () => {
        addToast('Não foi possível carregar a lista de movimentações', 'error');
      },
    }
  );

  useImperativeHandle(ref, () => ({
    submit: onFinish,
  }));

  return (
    <ModalTable
      data={movements?.results || []}
      isLoading={isLoading}
      headerData={[
        'Competência',
        'Tipo',
        'Conta bancária',
        'Método de pagamento',
        'Valor',
        'Descrição',
      ]}
      emptyMessage="Nenhuma movimentação encontrada"
      renderRow={(movement, index) => (
        <B2TableRow key={index}>
          <TableDataCell>{formatDate(movement.accrual_date)}</TableDataCell>
          <TableDataCell>{movement.type_display}</TableDataCell>
          <TableDataCell>
            {movement.type === MovementType.TRANSFER
              ? movement.description
              : movement.payment?.bank_account.name}
          </TableDataCell>
          <TableDataCell>{movement.payment_method?.name || '-'}</TableDataCell>
          <TableDataCell>
            {currency.centsToBrl(movement.movement_value)}
          </TableDataCell>
          <TableDataCell>
            <PaymentDescription>
              {movement.description.replaceAll('\n', '\n\n')}
            </PaymentDescription>
          </TableDataCell>
        </B2TableRow>
      )}
      paginator
      amountPerPage={20}
      currentPage={queryParams.page}
      total={movements?.count}
      changePage={changePage}
      hasClick={false}
    />
  );
};

export default forwardRef(MovementListing);
