import React, { useState } from 'react';

import { DocumentCode, DocumentText } from 'iconsax-react';
import { B2Button, B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { formatToBrlDate } from 'utils/formats';

import { useScope } from '@contexts/Scope';
import { useFiscal, useQueryParams } from '@hooks';

import BaseModal from '@components/BaseModal';
import CteStatus from '@components/CteStatus';
import DownloadAnchor from '@components/DownloadAnchor';
import { HorizontalDivider } from '@components/MdfeIssuanceModal/HeaderButtons/DownloadsButton/styles';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import { FiscalDocDownloadModalContainer } from '../styles';
import Filters from './Filters';

const CteList: React.FC = () => {
  const { queryParams, changePage, setQueryParams, onSearch } =
    useQueryParams<ListCteParams>({
      page: 1,
      search: '',
    });

  const [selectedCte, setSelectedCte] = useState<ICte>();

  const { listCtes } = useFiscal();
  const { scope } = useScope();

  const { data: paginatedCte, isLoading } = useQuery(
    ['listCtes', queryParams, scope],
    () => listCtes(queryParams)
  );

  return (
    <>
      <Filters
        onSearch={onSearch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
      <Table
        data={paginatedCte?.results || []}
        isLoading={isLoading}
        headerData={[
          'Nº do CT-e',
          'Emissão',
          'Nº do serviço',
          'Cliente',
          'Veículo',
          'Situação',
          'Origem',
          'Destino',
          'Documentos',
        ]}
        emptyMessage="Nenhum CT-e encontrado"
        renderRow={(cte) => (
          <B2TableRow key={cte.id}>
            <TableDataCell>{cte.number}</TableDataCell>
            <TableDataCell>{formatToBrlDate(cte.issued_at!)}</TableDataCell>
            <TableDataCell>{cte.service.number}</TableDataCell>
            <TableDataCell>{cte.service.client.name}</TableDataCell>
            <TableDataCell>
              {`${cte.service.vehicle.model} - ${cte.service.vehicle.identifier}`}
            </TableDataCell>
            <TableDataCell>
              <CteStatus status={cte.issuance_status} />
            </TableDataCell>
            <TableDataCell>
              {cte.service.origin.name}/{cte.service.origin.state}
            </TableDataCell>
            <TableDataCell>
              {cte.service.destination?.name}/{cte.service.destination?.state}
            </TableDataCell>
            <TableDataCell>
              <B2Button onClick={() => setSelectedCte(cte)}>
                Ver documentos
              </B2Button>
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={paginatedCte?.count}
        changePage={changePage}
        hasClick={false}
      />
      <BaseModal
        title="Documentos"
        onClose={() => setSelectedCte(undefined)}
        isOpen={!!selectedCte}
        onBackdropClick={() => setSelectedCte(undefined)}
      >
        {selectedCte && (
          <FiscalDocDownloadModalContainer>
            <DownloadAnchor
              href={selectedCte.pdf_file?.url || ''}
              label="CT-e em PDF"
              icon={DocumentText}
            />
            <HorizontalDivider />
            <DownloadAnchor
              href={selectedCte.xml_file?.url || ''}
              label="CT-e em XML"
              icon={DocumentCode}
            />
            {selectedCte.rectifications.map((rectification) => (
              <React.Fragment key={rectification.id}>
                <HorizontalDivider />
                <DownloadAnchor
                  href={rectification.pdf_file.url}
                  label={`Retificação em PDF ${formatToBrlDate(
                    rectification.received_at
                  )}`}
                  icon={DocumentText}
                />
                <HorizontalDivider />
                <DownloadAnchor
                  href={rectification.xml_file.url}
                  label={`Retificação em XML ${formatToBrlDate(
                    rectification.received_at
                  )}`}
                  icon={DocumentText}
                />
              </React.Fragment>
            ))}
            {selectedCte.pdf_cancel_file && (
              <>
                <HorizontalDivider />
                <DownloadAnchor
                  href={selectedCte.pdf_cancel_file.url}
                  label="PDF de cancelamento"
                  icon={DocumentText}
                />
              </>
            )}
            {selectedCte.xml_cancel_file && (
              <>
                <HorizontalDivider />
                <DownloadAnchor
                  href={selectedCte.xml_cancel_file.url}
                  label="XML de cancelamento"
                  icon={DocumentCode}
                />
              </>
            )}
          </FiscalDocDownloadModalContainer>
        )}
      </BaseModal>
    </>
  );
};

export default CteList;
