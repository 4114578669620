import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 3px;
  border-radius: 50%;
  width: fit-content;
  border: 2px solid ${({ theme }) => theme.colors.main};
  margin-left: ${({ theme }) => theme.spacing.five};
  box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
  &:active {
    transform: scale(0.95);
  }
`;

export const Circle = styled.div<{ checked: boolean }>`
  width: ${({ theme }) => theme.spacing.three};
  height: ${({ theme }) => theme.spacing.three};
  border-radius: 50%;
  background-color: ${({ theme, checked }) =>
    checked ? theme.colors.main : 'transparent'};
  transition: background-color 0.3s ease-in-out;
`;
