import React, { useRef, useState } from 'react';

import { phone } from 'b2utils';
import { useMutation, useQueryClient } from 'react-query';
import { StateRegistrationStatus } from 'utils/enums';
import { formatDocument } from 'utils/formats';

import { useToast } from '@contexts/Toast';
import { useClients } from '@hooks';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';
import BaseForm from '@components/BaseForm';
import ClientInfo from '@components/ClientForm/ClientInfo';

import { EditButton } from './styles';

interface EditClientButton {
  clientData: IClientUser;
}
const EditClientButton: React.FC<EditClientButton> = ({ clientData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const IClientFormStepRef = useRef<IFormStepRef>(null);

  const { updateClient } = useClients();
  const { addToast } = useToast();
  const queryClient = useQueryClient();

  const updateClientRequest = useMutation(
    (client: IClientFormValues) =>
      updateClient(clientData.id.toString(), client),
    {
      onSuccess: () => {
        addToast('Dados do cliente alterados com sucesso', 'success');
        setIsModalOpen(!isModalOpen);
        queryClient.invalidateQueries('service');
      },
      onError: () => {
        addToast('Falha ao alterar os dados do cliente', 'error');
      },
    }
  );

  const steps: Array<IStep> = [
    {
      title: 'Informações do cliente',
      ref: IClientFormStepRef,
      component: ClientInfo,
    },
  ];

  const clientInfoInitialValues: IClientInfoStepFormValues = {
    avatar: clientData.avatar || null,
    name: clientData.name,
    email: clientData.email,
    document: formatDocument(clientData.document),
    phone: clientData.phone
      ? phone.fromObject({
          areaCode: clientData.phone.area_code,
          number: clientData.phone.number,
        })
      : '',
    date_of_birth: clientData.date_of_birth,
    state_registration_status:
      clientData?.state_registration_status || StateRegistrationStatus.EXEMPT,
    state_registration: clientData?.state_registration || '',
  };

  return (
    <>
      <EditButton onClick={() => setIsModalOpen(!isModalOpen)} />
      <CustomBaseModal
        title="Editar cliente"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(!isModalOpen)}
      >
        {isModalOpen && (
          <BaseForm
            steps={steps}
            createRequest={updateClientRequest}
            initialValues={clientInfoInitialValues}
            onCancelClick={() => setIsModalOpen(!isModalOpen)}
            isFormModal
          />
        )}
      </CustomBaseModal>
    </>
  );
};

export default EditClientButton;
