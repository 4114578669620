import { useCallback } from 'react';

import { useApi, endpoints } from 'contexts/Api';

const useCities = () => {
  const { request } = useApi();

  const listCitiesByState = useCallback(
    async (params: ICitiesQueryParams) => {
      const response = await request<Array<ICity>>({
        method: 'get',
        url: endpoints.core.cities.list,
        params: {
          paginate: false,
          ...params,
        },
      });

      return response.data;
    },
    [request]
  );

  return { listCitiesByState };
};

export default useCities;
