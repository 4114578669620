import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import {
  ExpensePaymentStatusFilterMap,
  FinancialReportType,
  PaymentStatusFilterMap,
  ReportDetailLevel,
} from 'utils/enums';

import { InputGroup } from '@components/Base/PrivateBase/styles';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';

interface FormikValues {
  status: string | null;
  detailLevel: ReportDetailLevel;
}

interface PaymentStatusReportFilterProps {
  type: FinancialReportType;
}

const PaymentStatusReportFilter: React.FC<PaymentStatusReportFilterProps> = ({
  type,
}) => {
  const formik = useFormikContext<FormikValues>();

  const paymentStatusFilterValues = Object.values(PaymentStatusFilterMap);
  const paymentStatusFilterKeys = useMemo(() => {
    if (type === FinancialReportType.INCOME) {
      return Object.keys(PaymentStatusFilterMap);
    }
    return Object.keys(ExpensePaymentStatusFilterMap);
  }, [type]);

  return (
    <FormRow>
      <FormGroup>
        <Label htmlFor="status">Situação</Label>
        <InputGroup>
          <Input
            type="radio"
            id="allPaymentStatuses"
            name="status"
            checked={!formik.values.status}
            onChange={() => formik.setFieldValue('status', null)}
            onBlur={formik.handleBlur}
          />
          <Label htmlFor="allPaymentStatuses">Todos</Label>
        </InputGroup>
        {paymentStatusFilterValues.map((status, index) => (
          <InputGroup key={status}>
            <Input
              type="radio"
              id={`paymentStatusFilter${status}`}
              name="status"
              checked={formik.values.status === status}
              onChange={() => formik.setFieldValue('status', status)}
              onBlur={formik.handleBlur}
            />
            <Label htmlFor={`paymentStatusFilter${status}`}>
              {paymentStatusFilterKeys[index]}
            </Label>
          </InputGroup>
        ))}
      </FormGroup>
      <FormGroup>
        <Label>Tipo de relatório</Label>
        {Object.values(ReportDetailLevel).map((level) => (
          <InputGroup key={level}>
            <Input
              type="radio"
              id={`detailLevel${level}`}
              name="detailLevel"
              checked={formik.values.detailLevel === level}
              onChange={() => formik.setFieldValue('detailLevel', level)}
              onBlur={formik.handleBlur}
            />
            <Label htmlFor={`detailLevel${level}`}>{level}</Label>
          </InputGroup>
        ))}
      </FormGroup>
    </FormRow>
  );
};

export default PaymentStatusReportFilter;
