import React, { useRef } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useIncomeCategories, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';

import RoutesPath from '@router/routes';

import IncomeCategoryForm from '../Form/IncomeCategoryForm';
import routes from './routes';

const CreateIncomeCategory: React.FC = () => {
  const categoryFormRef = useRef<IFormStepRef>(null);

  const { createIncomeCategory } = useIncomeCategories();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const createIncomeCategoryRequest = useMutation(
    (values: ICategoryBaseFormValues) => createIncomeCategory(values),
    {
      onSuccess: () => {
        addToast('Categoria cadastrada com sucesso', 'success');
        navigate(RoutesPath.private.categories.incomes.listCategories.path, {
          replace: true,
        });
      },
      onError: () => {
        addToast('Erro ao cadastrar categoria', 'error');
      },
    }
  );

  useVerifyScope({
    routePath: RoutesPath.private.categories.incomes.listCategories.path,
    toastErrorMessage: 'Selecione uma empresa para cadastrar uma categoria',
  });

  const steps: Array<IStep> = [
    {
      title: 'Informações da categoria de recebível',
      ref: categoryFormRef,
      component: IncomeCategoryForm,
    },
  ];

  return (
    <BaseForm
      steps={steps}
      createRequest={createIncomeCategoryRequest}
      routes={routes}
      title="Cadastrar categoria de recebível"
    />
  );
};

export default CreateIncomeCategory;
