import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import {
  ExpenseType,
  FinancialReportType,
  ReportDetailLevel,
} from 'utils/enums';

import { useExpenseCategories, useProviders } from '@hooks';

import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import PaymentStatusReportFilter from '@components/PaymentStatusReportFilter';
import Select from '@components/Select';

import { CustomChooseItemFilter } from '@pages/Services/FormSteps/styles';

interface ExpenseReportFormProps {
  initialValues?: IExpenseReportFormValues;
  onFinish: (data: IExpenseReportFormValues) => void;
}

const ExpenseReportForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  ExpenseReportFormProps
> = ({ initialValues, onFinish }, ref) => {
  const { listProviders } = useProviders();
  const { listExpenseCategories } = useExpenseCategories();

  const emptyProvider = {
    id: 0,
    name: 'Todos',
  } as IProvider;

  const emptyCategory = {
    id: 0,
    name: 'Todas',
  } as IExpenseCategory;

  const expenseTypes = Object.values(ExpenseType);

  const formikDefaultInitialValues: IExpenseReportFormValues = {
    dateGte: '',
    dateLte: '',
    provider: emptyProvider,
    category: emptyCategory,
    type: null,
    status: null,
    detailLevel: ReportDetailLevel.DETAILED,
  };

  const formikInitialValues: IExpenseReportFormValues = {
    ...formikDefaultInitialValues,
    ...initialValues,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    onSubmit: onFinish,
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
    resetForm: () => {
      formik.setValues({
        ...initialValues,
        ...formikDefaultInitialValues,
      });
    },
  }));

  const handleSelectProvider = useCallback(
    (provider: IProvider) => {
      formik.setFieldTouched('provider');
      formik.setFieldValue('provider', provider);
    },
    [formik]
  );

  const handleSelectCategory = useCallback(
    (category: IExpenseCategory) => {
      formik.setFieldTouched('category');
      formik.setFieldValue('category', category);
    },
    [formik]
  );

  const handleSelectExpenseType = (type: string) => {
    formik.setFieldTouched('type');
    formik.setFieldValue('type', type || null);
  };

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="dateGte">Data inicial</Label>
          <Input
            type="date"
            id="dateGte"
            name="dateGte"
            value={formik.values.dateGte}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.touched.dateGte && !!formik.errors.dateGte}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="dateLte">Data final</Label>
          <Input
            type="date"
            id="dateLte"
            name="dateLte"
            value={formik.values.dateLte}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.touched.dateLte && !!formik.errors.dateLte}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Fornecedor</Label>
          <CustomChooseItemFilter
            filterValue={formik.values.provider.name}
            modalTitle="Selecionar fornecedor"
            fetch={listProviders}
            queryParams={{ isActive: 'true' }}
            queryKey="providers"
            inputLabel="Selecionar fornecedor"
            selectedItemLabel="Fornecedor selecionado"
            shouldRenderSelectedItem
            onSelect={(provider) => handleSelectProvider(provider)}
            selectedItem={formik.values.provider}
            renderItemText={(provider) => provider.name}
            additionalItem={emptyProvider}
          />
        </FormGroup>
        <FormGroup>
          <Label>Categoria</Label>
          <CustomChooseItemFilter
            filterValue={formik.values.category.name}
            modalTitle="Selecionar categoria"
            fetch={listExpenseCategories}
            queryParams={{ isActive: 'true' }}
            queryKey="incomeCategories"
            inputLabel="Selecionar categoria"
            selectedItemLabel="Categoria selecionada"
            shouldRenderSelectedItem
            onSelect={(category) => handleSelectCategory(category)}
            selectedItem={formik.values.category}
            renderItemText={(category) => category.name}
            additionalItem={emptyCategory}
          />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label>Tipo de despesa</Label>
          <Select
            value={formik.values.type || ''}
            onChange={(event) =>
              handleSelectExpenseType(event.target.value as ExpenseType)
            }
          >
            <option value="">Todas</option>
            {expenseTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
        </FormGroup>
      </FormRow>
      <PaymentStatusReportFilter type={FinancialReportType.EXPENSE} />
    </FormikProvider>
  );
};

export default forwardRef(ExpenseReportForm);
