import React from 'react';

import MonitoringSectionUser from '@components/MonitoringSectionUser';

import StatusProgress from './StatusProgress';
import {
  Container,
  CustomDepartureActionIcons,
  Divider,
  RightSide,
  StatusGroup,
  StatusLabel,
} from './styles';

interface HeaderProps {
  departure: IDeparture;
  refetchDeparture: () => void;
}

const Header: React.FC<HeaderProps> = ({ departure, refetchDeparture }) => {
  return (
    <>
      <Container>
        <MonitoringSectionUser user={departure.driver} />
        <Divider />
        <StatusGroup>
          <StatusProgress currentStatus={departure.status} />
          <RightSide>
            <CustomDepartureActionIcons
              departure={departure}
              refetchDeparture={refetchDeparture}
              onFinalizeSuccess={refetchDeparture}
            />
            <StatusLabel status={departure.status}>
              {departure.status}
            </StatusLabel>
          </RightSide>
        </StatusGroup>
      </Container>
    </>
  );
};

export default Header;
