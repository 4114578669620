import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { currency } from 'b2utils';
import { InfiniteData } from 'react-query';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useBankAccounts } from '@hooks';

import {
  BankIcon,
  BankIconWrapper,
  BaseCard,
  BaseCardContent,
  BaseCardTitle,
  BaseCardValue,
  CustomLoading,
} from '../styles';
import { CustomChooseItemFilter, Group } from './styles';

const BankBalanceCard: React.FC = () => {
  const { scope } = useScope();

  const emptyBankAccount = useMemo(
    () =>
      ({
        id: 0,
        name: 'Selecionar',
        company: {
          id: scope,
        },
      } as IBankAccount),
    [scope]
  );

  const [bankAccount, setBankAccount] =
    useState<IBankAccount>(emptyBankAccount);
  const [totalBankAccountBalancesSum, setTotalBankAccountBalancesSum] =
    useState<number>();

  const { listBankAccounts } = useBankAccounts();
  const { isManagementUser } = useAuth();

  const formattedBankAccountBalancesSum = useMemo(() => {
    if (bankAccount.id !== 0) {
      return currency.centsToBrl(bankAccount.balance);
    }

    if (isManagementUser && !scope) {
      return '-';
    }

    return totalBankAccountBalancesSum !== undefined
      ? currency.centsToBrl(totalBankAccountBalancesSum)
      : undefined;
  }, [
    bankAccount.balance,
    bankAccount.id,
    totalBankAccountBalancesSum,
    isManagementUser,
    scope,
  ]);

  const hasScopeChanged = !!scope && scope !== bankAccount?.company?.id;

  const handleSuccessListingRequest = useCallback(
    (data: InfiniteData<IPaginatedBankAccount>) => {
      const typedData = data as InfiniteData<IPaginatedBankAccount>;
      const bankAccounts = typedData.pages[0].results;

      setTotalBankAccountBalancesSum(typedData.pages[0].balances_sum);

      if (bankAccount.id !== 0) {
        const newBankAccount = bankAccounts.find(
          (account) => account.id === bankAccount.id
        );

        setBankAccount(newBankAccount || emptyBankAccount);
      }
    },
    [bankAccount.id, emptyBankAccount]
  );

  useEffect(() => {
    if (isManagementUser && (!scope || hasScopeChanged)) {
      setBankAccount(emptyBankAccount);
      setTotalBankAccountBalancesSum(undefined);
    }
  }, [scope, bankAccount, isManagementUser, hasScopeChanged, emptyBankAccount]);

  return (
    <BaseCard>
      <BankIconWrapper>
        <BankIcon variant="Bold" />
      </BankIconWrapper>
      <BaseCardContent>
        <Group>
          <BaseCardTitle>Saldo da conta:</BaseCardTitle>
          <CustomChooseItemFilter
            filterValue={bankAccount.name}
            modalTitle="Selecionar conta bancária"
            fetch={listBankAccounts}
            queryParams={{ isActive: 'true' }}
            queryKey="bankAccounts"
            inputLabel="Selecionar conta bancária *"
            selectedItemLabel="Conta bancária selecionada"
            shouldRenderSelectedItem
            onSelect={(item) => setBankAccount(item)}
            selectedItem={bankAccount}
            additionalItem={emptyBankAccount}
            renderItemText={(item) => item?.name}
            disabled={isManagementUser && !scope}
            onSuccess={(data) =>
              handleSuccessListingRequest(
                data as InfiniteData<IPaginatedBankAccount>
              )
            }
          />
        </Group>
        {formattedBankAccountBalancesSum !== undefined ? (
          <BaseCardValue>{formattedBankAccountBalancesSum}</BaseCardValue>
        ) : (
          <CustomLoading />
        )}
      </BaseCardContent>
    </BaseCard>
  );
};

export default BankBalanceCard;
