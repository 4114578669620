import styled from 'styled-components';

import Input from '@components/Input';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.four};
`;

export const Description = styled.span`
  text-align: center;
  color: ${({ theme }) => theme.colors.gray.twelve};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const CustomInput = styled(Input)`
  border-radius: ${({ theme }) => theme.borderRadius};
`;
