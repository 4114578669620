import React, { useEffect, useRef } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { usePaymentMethods } from '@hooks';

import BaseForm from '@components/BaseForm';

import RoutesPath from '@router/routes';

import PaymentMethodForm from '../Form/PaymentMethodForm';
import routes from './routes';

const CreatePaymentMethod: React.FC = () => {
  const paymentMethodFormRef = useRef<IFormStepRef>(null);

  const { createPaymentMethod } = usePaymentMethods();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { scope } = useScope();
  const { isManagementUser } = useAuth();

  const createPaymentMethodRequest = useMutation(
    (values: IPaymentMethodFormValues) => createPaymentMethod(values),
    {
      onSuccess: () => {
        addToast('Método de pagamento cadastrado com sucesso', 'success');
        navigate(RoutesPath.private.paymentMethods.listPaymentMethods.path, {
          replace: true,
        });
      },
      onError: () => {
        addToast('Erro ao cadastrar método de pagamento', 'error');
      },
    }
  );

  useEffect(() => {
    if (!scope && isManagementUser) {
      addToast(
        'Selecione uma empresa para cadastrar um método de pagamento',
        'error'
      );
      navigate(RoutesPath.private.paymentMethods.listPaymentMethods.path, {
        replace: true,
      });
    }
  }, [scope, navigate, addToast, isManagementUser]);

  const steps: Array<IStep> = [
    {
      title: 'Informações método de pagamento',
      ref: paymentMethodFormRef,
      component: PaymentMethodForm,
    },
  ];

  return (
    <BaseForm
      steps={steps}
      createRequest={createPaymentMethodRequest}
      routes={routes}
      title="Cadastrar método de pagamento"
    />
  );
};

export default CreatePaymentMethod;
