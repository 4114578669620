import styled from 'styled-components';
import { modifyColor } from 'utils/styles';

import Table, { TableProps } from '@components/Table';

const TableVariant = styled(Table)`
  animation: none;
  box-shadow: none;
  border-radius: 0;
  background-color: ${({ theme }) => theme.colors.white.main};

  thead {
    background-color: ${({ theme }) =>
      modifyColor({ hex: theme.colors.main, lightness: 0.93 })};

    &,
    tr:first-child {
      border-bottom: 0;
    }

    th:first-child {
      border-top-left-radius: ${({ theme }) => theme.borderRadius};
    }

    th:last-child {
      border-top-right-radius: ${({ theme }) => theme.borderRadius};
    }
  }
` as <Type>(props: TableProps<Type>) => React.ReactElement;

export default TableVariant;
