import React from 'react';

import downloadingAnimation from './downloadingAnimation.json';
import { AnimationContainer, DownloadingLottie } from './styles';

interface DownloadingAnimationProps {
  className?: string;
  loop: boolean;
}

const DownloadingAnimation: React.FC<DownloadingAnimationProps> = ({
  className,
  loop,
}) => {
  return (
    <AnimationContainer className={className}>
      <DownloadingLottie loop={loop} animationData={downloadingAnimation} />
    </AnimationContainer>
  );
};

export default DownloadingAnimation;
