import { currency } from 'b2utils';

import { IcmsType } from './enums';

export const required = 'Campo obrigatório';
export const email = 'E-mail inválido';
export const newEmail = 'O novo e-mail deve ser diferente';
export const passwordConfirm = 'As senhas não conferem';
export const cpf = 'CPF inválido';
export const cnpj = 'CNPJ inválido';
export const rg = 'RG inválido';
export const cep = 'CEP inválido';
export const onlyLetters = 'Esse campos aceita apenas letras';
export const onlyNumbers = 'Esse campos aceita apenas números';
export const invalidYear = 'Digite um ano válido';
export const currencyBetween = (minValue: number, maxValue: number) =>
  `Esse campo deve ter um valor entre ${currency.brlMask(
    minValue.toString()
  )} e ${currency.brlMask(maxValue.toString())}`;
export const minCurrency = (value: number) =>
  `Esse campo deve ter um valor maior que ${currency.brlMask(
    value.toString()
  )}`;
export const minLength = (value: number) =>
  `Esse campo deve ter no mínimo ${value} caracteres`;
export const maxLength = (value: number) =>
  `Esse campo deve ter no máximo ${value} caracteres`;
export const length = (value: number) =>
  `Este campo deve ter ${value} ${value === 1 ? 'caractere' : 'caracteres'}`;
export const date = 'Data inválida';
export const time = 'Hora inválida';
export const minValue = (value: number) =>
  `Esse campo deve ser maior que ${value}`;
export const pastDate = 'Data no passado';
export const futureDate = 'Data no futuro';
export const phone = 'Telefone inválido';
export const url = 'Site inválido';
export const urlHttp = 'É necessário incluir http:// ou https://';
export const maxDecimalValue = (value: number) =>
  `Esse campo deve ter no máximo ${value} casas decimais`;
export const maxValue = (value: number) =>
  `Esse campo deve ter no máximo ${value}`;
export const maxWeight = (value: number) =>
  `Esse campo deve ter no máximo ${value} kg`;
export const percentage = 'Percentual inválido, insira um valor entre 0 e 100';
export const scope = 'Uma empresa com este ID já existe';
export const icmsType = `O tipo do ICMS deve ser ${IcmsType.SIMPLE_NATIONAL}, uma vez que esse é o regime tributário da empresa`;
export const mdfePastDate =
  'A data de finalização não pode ser menor que a data de emissão do MDF-e';
export const maxIncomePaymentValue =
  'O valor do recebimento excede o valor restante do recebível';
export const totalSumOfIncomePayments =
  'A soma dos valores dos recebimentos devem ser igual ao valor total do pagamento';
export const maxExpensePaymentValue =
  'O valor do pagamento excede o valor restante do da despesa';
export const totalSumOfExpensePayments =
  'A soma dos pagamentos das despesas devem ser igual ao valor total do pagamento';
export const bankAccounts = 'As contas bancárias devem ser diferentes';
