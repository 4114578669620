import { DocumentsListType } from 'utils/enums';

import RoutesPath from '@router/routes';

interface DocumentListValues {
  type: DocumentsListType;
  listPath: string;
  subtitle: string;
  actionButtonProps?: {
    buttonText: string;
    toastMessage: string;
    createPath?: string;
  };
  extraButtonProps?: {
    buttonText: string;
    toastMessage: string;
    createPath?: string;
  };
}

export const documentListProps: Record<DocumentsListType, DocumentListValues> =
  {
    [DocumentsListType.SERVICES]: {
      type: DocumentsListType.SERVICES,
      listPath: RoutesPath.private.documents.listServices.path,
      subtitle:
        'Aqui você irá encontrar a lista de todos os serviços cadastrados no sistema',
      actionButtonProps: {
        buttonText: 'Cadastrar serviço',
        toastMessage:
          'Não é possível cadastrar um serviço sem estar com uma empresa selecionada',
        createPath: RoutesPath.private.services.createService.path,
      },
    },
    [DocumentsListType.INSPECTIONS]: {
      type: DocumentsListType.INSPECTIONS,
      listPath: RoutesPath.private.documents.listInspections.path,
      subtitle:
        'Aqui você irá encontrar a lista de todas as vistorias cadastradas no sistema',
    },
    [DocumentsListType.DEPARTURES]: {
      type: DocumentsListType.DEPARTURES,
      listPath: RoutesPath.private.documents.listDepartures.path,
      subtitle:
        'Aqui você irá encontrar a lista de todos os embarques cadastrados no sistema',
      actionButtonProps: {
        buttonText: 'Cadastrar embarque',
        toastMessage:
          'Não é possível cadastrar um embarque sem estar com uma empresa selecionada',
        createPath: RoutesPath.private.departures.createDeparture.path,
      },
    },
    [DocumentsListType.SERVICE_CONTRACTS]: {
      type: DocumentsListType.SERVICE_CONTRACTS,
      listPath: RoutesPath.private.documents.listServiceContracts.path,
      subtitle:
        'Aqui você irá encontrar a lista de todos os termos de entrega cadastrados no sistema',
    },
    [DocumentsListType.TERM_OF_RECEIPTS]: {
      type: DocumentsListType.TERM_OF_RECEIPTS,
      listPath: RoutesPath.private.documents.listTermOfReceipts.path,
      subtitle:
        'Aqui você irá encontrar a lista de todos os termos de entrega cadastrados no sistema',
    },
    [DocumentsListType.CTE]: {
      type: DocumentsListType.CTE,
      listPath: RoutesPath.private.documents.listCte.path,
      subtitle:
        'Aqui você irá encontrar a lista de todos os CT-es emitidos no sistema',
      actionButtonProps: {
        buttonText: 'Baixar arquivos',
        toastMessage:
          'Não é possível baixar documentos sem estar com uma empresa selecionada',
      },
    },
    [DocumentsListType.MDFE]: {
      type: DocumentsListType.MDFE,
      listPath: RoutesPath.private.documents.listMDFe.path,
      subtitle:
        'Aqui você irá encontrar a lista de todos os MDF-es emitidos no sistema',
      actionButtonProps: {
        buttonText: 'Baixar arquivos',
        toastMessage:
          'Não é possível baixar documentos sem estar com uma empresa selecionada',
      },
      extraButtonProps: {
        buttonText: 'Finalizar MDF-e',
        toastMessage:
          'Não é possível finalizar um MDF-e sem estar com uma empresa selecionada',
      },
    },
  };
