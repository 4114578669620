import React from 'react';

import { FormikProvider, useFormikContext } from 'formik';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';

const CategoryBaseForm: React.FC = () => {
  const formik = useFormikContext<ICategoryBaseFormValues>();

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor="name">Nome da categoria *</Label>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder="Digite aqui"
            value={formik.values.name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={!!formik.touched.name && !!formik.errors.name}
          />
          <FormError name="name" />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default CategoryBaseForm;
