import { Car, DocumentDownload, TickCircle, Trash } from 'iconsax-react';
import styled, { css } from 'styled-components';

const IconStyle = css<{ disabled?: boolean }>`
  flex-shrink: 0;
  width: ${({ theme }) => theme.spacing.five};
  height: ${({ theme }) => theme.spacing.five};

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
          cursor: not-allowed;
          color: ${({ theme }) => theme.colors.gray.ten};
        `
      : css`
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        `}
`;

export const ActionIconsContainer = styled.div`
  display: flex;
  align-self: center;
  gap: ${({ theme }) => theme.spacing.four};
`;

export const TickCircleIcon = styled(TickCircle)`
  ${IconStyle}
  color: ${({ theme }) => theme.colors.success};
`;

export const CarIcon = styled(Car)`
  color: ${({ theme }) => theme.colors.gray.ten};
  ${IconStyle}
`;

export const DocumentDownloadIcon = styled(DocumentDownload)`
  color: ${({ theme }) => theme.colors.info};
  ${IconStyle}
`;

export const TrashIcon = styled(Trash)`
  color: ${({ theme }) => theme.colors.error};
  ${IconStyle}
`;
