import styled, { css } from 'styled-components';
import { addSpacing } from 'utils/styles';

interface ContainerProps {
  selectedImage: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  max-width: 100px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  padding: ${({ theme }) => addSpacing(theme.spacing.one, '0')};
  margin: ${({ theme }) => addSpacing(theme.spacing.one, '0')};

  ${({ selectedImage }) =>
    selectedImage &&
    css`
      box-shadow: 0px 0px 6px 1px ${({ theme }) => theme.colors.info};
      padding: ${({ theme }) => theme.spacing.one};
      margin: ${({ theme }) => theme.spacing.one};
    `}
`;

export const ImageContainer = styled.img`
  margin: ${({ theme }) => theme.spacing.one};
  width: 40px;
  height: 40px;
  object-fit: cover;
  cursor: pointer;
`;
