import { B2Card } from 'react-b2components';
import styled from 'styled-components';
import { FinancialType } from 'utils/enums';
import { financialTransactionTypeProps } from 'utils/helpers';

export const Container = styled(B2Card)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.four};
  padding: ${({ theme }) => theme.spacing.four};
`;

export const MainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.colors.gray.twelve};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const ForecastSum = styled.p`
  text-align: right;
  color: ${({ theme }) => theme.colors.gray.six};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const PaymentSum = styled.span<{ type: FinancialType }>`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme, type }) =>
    financialTransactionTypeProps[type].color(theme)};
`;

export const ProgressContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.gray.one};
  height: 16px;
`;

export const Progress = styled(ProgressContainer)<{
  progress: number;
  type: FinancialType;
}>`
  width: ${({ progress }) => progress}%;
  background-color: ${({ theme, type }) =>
    financialTransactionTypeProps[type].color(theme)};
  transition: all 1s ease-in-out;
`;
