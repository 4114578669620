import { color } from 'b2utils';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ISideBarItemProps {
  selected?: boolean;
  index: number;
}

export const Container = styled(Link)<ISideBarItemProps>`
  display: flex;
  align-items: center;
  opacity: 0;
  animation: show-rightwards 0.5s ease-out ${({ index }) => index * 0.1}s
      forwards,
    fade-in 0.5s ease-out ${({ index }) => index * 0.1}s forwards;
  cursor: pointer;
`;

export const Content = styled.div<ISideBarItemProps>`
  flex: 1;
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  word-break: word-break;

  svg {
    margin-right: 16px;
    flex-shrink: 0;
    transition: all 0.5s ease;
    width: ${({ theme }) => theme.sidebarIconSize};
    height: ${({ theme }) => theme.sidebarIconSize};
  }

  ${({ theme, selected }) =>
    selected
      ? css`
          margin: 0 ${theme.spacing.three};
          padding: ${theme.spacing.three} ${theme.spacing.two};
          background-color: ${color.addOpacityOnHexColor(
            theme.colors.main,
            0.1
          )};
          svg {
            margin-left: 10px;
            color: ${theme.colors.main};
          }
        `
      : css`
          padding: ${theme.spacing.three} ${theme.spacing.six};
          svg {
            color: ${theme.colors.gray.six};
          }
        `};
`;

export const Label = styled.p<ISideBarItemProps>`
  font-size: ${({ theme }) => theme.fontSize.small};
  ${({ theme, selected }) =>
    selected
      ? css`
          font-weight: ${theme.fontWeight.semiBold};
          color: ${theme.colors.black.two};
        `
      : css`
          font-weight: ${theme.fontWeight.medium};
          color: ${theme.colors.gray.six};
        `};
`;
