import React from 'react';

import { ServiceStatus } from 'utils/enums';

import { Container, Description, Title } from './styles';

interface CompletionInfoProps {
  destination: ICity;
}

const CompletionInfo: React.FC<CompletionInfoProps> = ({ destination }) => {
  return (
    <Container>
      <Title>
        Selecione os serviços abaixo cujo respectivos veículos ficarão no
        destino {destination.name}/{destination.state}
      </Title>
      <Description>
        <strong>Serviços selecionados:</strong> Os status dos serviços serão
        modificados automaticamente para{' '}
        <strong>{ServiceStatus.WAITING_DELIVERY}</strong>.
      </Description>
      <Description>
        <strong>Serviços não selecionados:</strong> Os status dos serviços serão
        modificados automaticamente para{' '}
        <strong>{ServiceStatus.WAITING_DEPARTURE}</strong>.
      </Description>
    </Container>
  );
};

export default CompletionInfo;
