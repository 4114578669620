import React, { useRef } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useClients, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';
import ClientInfo from '@components/ClientForm/ClientInfo';
import MultipleAddressesForm from '@components/MultipleAddressesForm';

import RoutesPath from '@router/routes';

import routes from './routes';

const CreateClient: React.FC = () => {
  const clientInfoStepRef = useRef<IFormStepRef>(null);
  const clientAddressStepRef = useRef<IFormStepRef>(null);

  const { createClient } = useClients();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const createClientRequest = useMutation(
    (client: IClientFormValues) => createClient(client),
    {
      onSuccess: () => {
        addToast('Cliente cadastrado com sucesso', 'success');
        navigate(RoutesPath.private.clients.listClients.path, {
          replace: true,
        });
      },
      onError: () => {
        addToast('Erro ao cadastrar cliente', 'error');
      },
    }
  );

  useVerifyScope({
    routePath: RoutesPath.private.clients.listClients.path,
    toastErrorMessage: 'Selecione uma empresa para cadastrar um cliente',
  });

  const steps: Array<IStep> = [
    {
      title: 'Informações do cliente',
      ref: clientInfoStepRef,
      component: ClientInfo,
    },
    {
      title: 'Endereços',
      ref: clientAddressStepRef,
      component: MultipleAddressesForm,
    },
  ];

  return (
    <BaseForm
      steps={steps}
      createRequest={createClientRequest}
      routes={routes}
      title="Cadastrar cliente"
    />
  );
};

export default CreateClient;
