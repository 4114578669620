import { B2Button } from 'react-b2components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  @media (max-width: 390px) {
    align-items: center;
  }
`;

export const PageTitles = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MainContent = styled.div`
  flex: 1;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing.two};

  @media (max-width: 390px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.gray.twelve};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  @media (max-width: 390px) {
    text-align: center;
  }
`;

export const Subtitle = styled.h2`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.gray.six};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  @media (max-width: 390px) {
    text-align: center;
  }
`;

export const CustomButton = styled(B2Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.two};
`;
