import React from 'react';

import FormError from 'components/FormError';
import FormGroup from 'components/FormGroup';
import FormRow from 'components/FormRow';
import Label from 'components/Label';
import { useFormikContext } from 'formik';

import PasswordInput from '@components/PasswordInput';

const BaseUpdatePasswordForm: React.FC = () => {
  const formik = useFormikContext<IUpdatePasswordFormValues>();

  return (
    <>
      <FormRow>
        <FormGroup>
          <Label htmlFor="password">Senha</Label>
          <PasswordInput
            type="password"
            autoComplete="new-password"
            placeholder="Insira sua senha"
            id="password"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            invalidValue={!!formik.touched.password && !!formik.errors.password}
          />
          <FormError name="password" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="confirmPassword">Confirmar senha</Label>
          <PasswordInput
            type="password"
            placeholder="Insira sua senha novamente"
            id="confirmPassword"
            name="confirmPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            invalidValue={
              !!formik.touched.confirmPassword &&
              !!formik.errors.confirmPassword
            }
          />
          <FormError name="confirmPassword" />
        </FormGroup>
      </FormRow>
    </>
  );
};

export default BaseUpdatePasswordForm;
