import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.one};
  margin-top: ${({ theme }) => theme.spacing.four};
`;

export const Term = styled.p`
  hyphens: auto;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const Strong = styled.strong`
  color: ${({ theme }) => theme.colors.main};
`;
