import { color } from 'b2utils';
import styled from 'styled-components';
import { MdfeIssuanceStatus } from 'utils/enums';
import { mdfeIssuanceStatusProps } from 'utils/helpers';
import { addSpacing } from 'utils/styles';

import Loading from '@components/Loading';

interface IssuanceStatusLabelProps {
  status: MdfeIssuanceStatus;
}

export const Container = styled.div<IssuanceStatusLabelProps>`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  gap: ${({ theme }) => theme.spacing.one};
  margin-top: ${({ theme }) => theme.spacing.five};
  padding: ${({ theme }) => addSpacing(theme.spacing.four, theme.spacing.six)};
  background-color: ${({ theme, status }) =>
    color.addOpacityOnHexColor(
      mdfeIssuanceStatusProps[status].color(theme),
      0.2
    )};
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const IssuanceDates = styled.p`
  color: ${({ theme }) => theme.colors.gray.nine};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const Status = styled.p<IssuanceStatusLabelProps>`
  color: ${({ theme, status }) =>
    mdfeIssuanceStatusProps[status].fontColor?.(theme) ||
    mdfeIssuanceStatusProps[status].color(theme)};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const CustomLoading = styled(Loading)`
  margin-right: auto;
  margin-left: 0;
`;
