import RoutesPath from '@router/routes';

const routes = [
  {
    label: 'Usuários internos',
    path: RoutesPath.private.companyUsers.listCompanyUsers.path,
  },

  {
    label: 'Adicionar usuário interno',
    path: RoutesPath.private.companyUsers.createCompanyUser.path,
  },
];

export default routes;
