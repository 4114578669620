import React, { useMemo } from 'react';

import { ClipboardText } from 'iconsax-react';
import moment from 'moment';
import { useQueries } from 'react-query';
import { QuotationStatus } from 'utils/enums';
import {
  getFormattedPercentageDifference,
  quotationStatusProps,
  throwToastApiErrors,
} from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useMetrics } from '@hooks';

import {
  Container,
  CustomLoading,
  Footer,
  IconWrapper,
  MainGroup,
  MetricTitle,
  MetricValue,
  TrendDownIcon,
  TrendDownText,
  TrendUpIcon,
  TrendUpText,
} from './styles';

interface MetricCardProps {
  yearMonth: string;
  status?: QuotationStatus;
}

const MetricCard: React.FC<MetricCardProps> = ({ yearMonth, status }) => {
  const { getQuotationsCount } = useMetrics();
  const { isCompanyUser, isManagementUser } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();

  const areQueriesEnabled = !!scope || isCompanyUser;
  const isManagementWithoutScope = isManagementUser && !scope;

  const currentMonthQueryParams: IQuotationsCountQueryParams = {
    yearMonth,
    status,
  };

  const previousMonthQueryParams: IQuotationsCountQueryParams = {
    yearMonth: moment(yearMonth).subtract(1, 'month').format('YYYY-MM'),
    status,
  };

  const [fetchCurrentMonthQuotationsCount, fetchPreviousMonthQuotationsCount] =
    useQueries([
      {
        queryKey: ['getQuotationsCount', currentMonthQueryParams, scope],
        enabled: areQueriesEnabled,
        queryFn: () => getQuotationsCount(currentMonthQueryParams),
        onError: (error: any) => {
          addToast('Não foi possível carregar métrica do orçamento', 'error');
          throwToastApiErrors(error, addToast);
        },
      },
      {
        queryKey: ['getQuotationsCount', previousMonthQueryParams, scope],
        enabled: areQueriesEnabled,
        queryFn: () => getQuotationsCount(previousMonthQueryParams),
        onError: (error: any) => {
          addToast('Não foi possível carregar métrica do orçamento', 'error');
          throwToastApiErrors(error, addToast);
        },
      },
    ]);

  const isLoading =
    fetchCurrentMonthQuotationsCount.isLoading ||
    fetchPreviousMonthQuotationsCount.isLoading;

  const currentValue = fetchCurrentMonthQuotationsCount.data?.count || 0;
  const previousValue = fetchPreviousMonthQuotationsCount.data?.count || 0;

  const Icon = useMemo(() => {
    if (status) {
      const { icon: Icon } = quotationStatusProps[status];
      return Icon || ClipboardText;
    }
    return ClipboardText;
  }, [status]);

  const formattedPercentageValue = useMemo(() => {
    if (isManagementWithoutScope) {
      return '-';
    }

    return getFormattedPercentageDifference(currentValue, previousValue);
  }, [currentValue, isManagementWithoutScope, previousValue]);

  return (
    <Container>
      <MainGroup>
        <div>
          <MetricTitle>{status ? `${status}s` : 'Totais'}</MetricTitle>
          {isLoading ? (
            <CustomLoading />
          ) : (
            <MetricValue>
              {isManagementWithoutScope ? '-' : currentValue}
            </MetricValue>
          )}
        </div>
        <IconWrapper status={status}>
          <Icon variant="Bold" />
        </IconWrapper>
      </MainGroup>
      {!isLoading && (
        <Footer>
          {currentValue >= previousValue ? (
            <>
              <TrendUpIcon />
              <TrendUpText>{formattedPercentageValue}</TrendUpText>
            </>
          ) : (
            <>
              <TrendDownIcon />
              <TrendDownText>{formattedPercentageValue}</TrendDownText>
            </>
          )}
          comparado ao mês anterior
        </Footer>
      )}
    </Container>
  );
};

export default MetricCard;
