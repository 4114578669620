import RoutesPath from '@router/routes';

const getRoutes = (driverId: string) => {
  return [
    { path: RoutesPath.private.drivers.listDrivers.path, label: 'Motoristas' },
    {
      path: RoutesPath.private.drivers.detailDriver.path.replace(
        ':driverId',
        driverId
      ),
      label: 'Detalhes do motorista',
    },
    {
      path: RoutesPath.private.drivers.editDriver.path.replace(
        ':driverId',
        driverId
      ),
      label: 'Editar motorista',
    },
  ];
};

export default getRoutes;
