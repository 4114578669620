import React from 'react';

import { Content, Description, FieldName } from '../styles';

interface RateInfoProps {
  label: string;
  description: Array<string> | null;
  hasHighlight?: boolean;
}

const RateInfo: React.FC<RateInfoProps> = ({
  description,
  label,
  hasHighlight,
}) => {
  if (!description) {
    return null;
  }

  return (
    <Content>
      <FieldName>{label}</FieldName>
      <Description hasHighlight={hasHighlight}>
        <span>
          {description
            ? description?.map((rate) => rate.concat('%')).join(', ')
            : 'Nenhuma alíquota cadastrada'}
        </span>
      </Description>
    </Content>
  );
};

export default RateInfo;
