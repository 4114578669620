import { useCallback } from 'react';

import regex from 'utils/regex';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const generateTransferRequestObject = (transfer: ITransferFormValues) => {
  if (transfer.isCapitalInjection) {
    return {
      title: transfer.title,
      date: transfer.date,
      destination: transfer.destination?.id,
      value: Number(transfer.value.replace(regex.onlyNumbers, '')),
    };
  }

  return {
    date: transfer.date,
    origin: transfer.origin?.id,
    destination: transfer.destination?.id,
    value: Number(transfer.value.replace(regex.onlyNumbers, '')),
  };
};

const useTransfers = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const getTransfer = useCallback(
    async (transferId: string) => {
      const response = await request<ITransfer>({
        method: 'get',
        url: endpoints.financial.transfers.get.replace(
          ':transferId',
          transferId
        ),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const listTransfers = useCallback(
    async (params: ITransferQueryParams) => {
      const response = await request<IPaginated<ITransfer>>({
        method: 'get',
        url: endpoints.financial.transfers.list,
        headers: { scope },
        params: {
          page: params.page,
          origin: params.origin?.id || undefined,
          destination: params.destination?.id || undefined,
        },
      });

      return response.data;
    },
    [request, scope]
  );

  const createTransfer = useCallback(
    async (transfer: ITransferFormValues) => {
      const response = await request<ITransfer>({
        method: 'post',
        url: endpoints.financial.transfers.create,
        headers: { scope },
        data: generateTransferRequestObject(transfer),
      });

      return response.data;
    },
    [request, scope]
  );

  const updateTransfer = useCallback(
    async (transferId: string, values: ITransferFormValues) => {
      const response = await request<ITransfer>({
        method: 'patch',
        url: endpoints.financial.transfers.update.replace(
          ':transferId',
          transferId
        ),
        headers: { scope },
        data: generateTransferRequestObject(values),
      });

      return response.data;
    },
    [request, scope]
  );

  const deleteTransfer = useCallback(
    async (transferId: number, password: string) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.financial.transfers.delete.replace(
          ':transferId',
          transferId.toString()
        ),
        headers: { scope },
        data: { password },
      });

      return response.data;
    },
    [request, scope]
  );

  return {
    getTransfer,
    createTransfer,
    listTransfers,
    updateTransfer,
    deleteTransfer,
  };
};

export default useTransfers;
