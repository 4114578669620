import React from 'react';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';

import InspectionContent from './InspectionContent';

interface InspectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  service: IService;
  onCancelInspectionSuccess: () => void;
}

const InspectionModal: React.FC<InspectionModalProps> = ({
  isOpen,
  onClose,
  service,
  onCancelInspectionSuccess,
}) => {
  return (
    <CustomBaseModal
      title="Relatório de vistoria"
      isOpen={isOpen}
      onClose={onClose}
    >
      <InspectionContent
        isOpen={isOpen}
        onClose={onClose}
        service={service}
        onCancelInspectionSuccess={onCancelInspectionSuccess}
      />
    </CustomBaseModal>
  );
};

export default InspectionModal;
