import React from 'react';

import { SendingStatus } from 'utils/enums';
import { toISOStringWithTimezone } from 'utils/helpers';

import DateRangeCalendarFilter from '@components/DateRangeCalendarFilter';
import ListFilters from '@components/ListFilters';
import Select from '@components/Select';

interface FiltersProps {
  queryParams: IDeliveryReceiptQueryParams;
  setQueryParams: React.Dispatch<
    React.SetStateAction<IDeliveryReceiptQueryParams>
  >;
  onSearch: (value: string) => void;
}

const Filters: React.FC<FiltersProps> = ({
  queryParams,
  setQueryParams,
  onSearch,
}) => {
  const sendingStatuses = Object.values(SendingStatus);

  const handleSelectSendingStatus = (status: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      status: status ? (status as SendingStatus) : undefined,
    }));
  };

  const handleDateRangeFilterSelected = (startDate?: Date, endDate?: Date) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      deliveredAtGte: startDate
        ? toISOStringWithTimezone(startDate)
        : undefined,
      deliveredAtLte: endDate ? toISOStringWithTimezone(endDate) : undefined,
    }));
  };

  return (
    <ListFilters
      searchPlaceholder="Buscar por nº do serviço ou veículo"
      onSearch={onSearch}
    >
      <Select
        value={queryParams?.status || ''}
        onChange={(event) =>
          handleSelectSendingStatus(event.target.value as SendingStatus)
        }
      >
        <option value="">Situação do termo de entrega: todas</option>
        {sendingStatuses.map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </Select>
      <DateRangeCalendarFilter
        label="Selecionar data de entrega"
        clearableDateRange
        onDateRangeFilterSelected={handleDateRangeFilterSelected}
      />
    </ListFilters>
  );
};

export default Filters;
