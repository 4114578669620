import RoutesPath from '@router/routes';

const getRoutes = (categoryId: string) => {
  return [
    {
      path: RoutesPath.private.categories.incomes.listCategories.path,
      label: 'Categorias',
    },
    {
      path: RoutesPath.private.categories.incomes.editCategory.path.replace(
        ':categoryId',
        categoryId
      ),
      label: 'Editar categoria de recebível',
    },
  ];
};

export default getRoutes;
