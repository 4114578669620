import React, { useState } from 'react';

import { useQueryClient } from 'react-query';
import { Tooltip } from 'react-tooltip';
import { InspectionStatus, ServiceStatus, ServiceType } from 'utils/enums';
import {
  cteEndorsementStatusProps,
  cteIssuanceStatusProps,
  serviceStatusProps,
} from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';

import CteEndorsementModal from '@components/CteEndorsementModal';
import CteIssuanceModal from '@components/CteIssuanceModal';
import DeliveryReceiptModal from '@components/DeliveryReceiptModal';
import InspectionModal from '@components/InspectionModal';
import ServiceContractModal from '@components/ServiceContractModal';

import RoutesPath from '@router/routes';

import {
  CarIcon,
  Container,
  DateInfoStyle,
  DeliveryReceiptIcon,
  DocumentTextIcon,
  IconContainer,
  InDepartureLabel,
  PrimaryInfoStyle,
  SecondaryInfoStyle,
  ServiceCategory,
  ServiceNumber,
} from './styles';

interface ServiceCardProps {
  service: IService;
  queryParams: IServicesQueryParams;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ service, queryParams }) => {
  const { isManagementUser, isClientUser } = useAuth();
  const { scope } = useScope();
  const queryClient = useQueryClient();

  const [isInspectionModalOpen, setIsInspectionModalOpen] = useState(false);
  const [isCteModalOpen, setIsCteModalOpen] = useState(false);
  const [isCteEndorsementModalOpen, setIsCteEndorsementModalOpen] =
    useState(false);
  const [isDeliveryReceiptModalOpen, setIsDeliveryReceiptModalOpen] =
    useState(false);
  const [isServiceContractOpen, setIsServiceContractOpen] = useState(false);

  const dateLabel = serviceStatusProps[service.status].dateLabel(service);

  const shouldShowInDepartureLabel =
    service.status === ServiceStatus.WAITING_DEPARTURE && service.in_departure;

  return (
    <>
      <Container
        status={service.status}
        to={RoutesPath.private.services.detailService.path.replace(
          ':serviceId',
          service.id.toString()
        )}
      >
        <Tooltip id="service-card-tooltip" />
        <ServiceNumber>{service.number}</ServiceNumber>
        {shouldShowInDepartureLabel && (
          <InDepartureLabel>Em separação para embarque</InDepartureLabel>
        )}
        <PrimaryInfoStyle>{service.client.name}</PrimaryInfoStyle>
        <PrimaryInfoStyle>
          {service.vehicle.model} - {service.vehicle.identifier}
        </PrimaryInfoStyle>
        {dateLabel && <DateInfoStyle>{dateLabel}</DateInfoStyle>}
        {service.last_known_location && (
          <SecondaryInfoStyle>
            Localização atual:{' '}
            {service.status === ServiceStatus.IN_TRANSIT
              ? `Em deslocamento`
              : `${service.last_known_location.name}/${service.last_known_location.state}`}
          </SecondaryInfoStyle>
        )}
        <SecondaryInfoStyle>
          Origem: {`${service.origin.name}/${service.origin.state}`}
        </SecondaryInfoStyle>
        {service.destination && (
          <SecondaryInfoStyle>
            Destino:{' '}
            {`${service.destination.name}/${service.destination.state}`}
          </SecondaryInfoStyle>
        )}
        {isManagementUser && (
          <SecondaryInfoStyle>
            Empresa: {service.company.name}
          </SecondaryInfoStyle>
        )}
        <SecondaryInfoStyle>Tipo do serviço: {service.type}</SecondaryInfoStyle>
        <IconContainer>
          {service.type !== ServiceType.STORAGE &&
            !!service.contract?.status && (
              <DocumentTextIcon
                data-tooltip-id="service-card-tooltip"
                data-tooltip-content="Contrato"
                variant="Bold"
                status={service.contract.status}
                onClick={(event) => {
                  event.preventDefault();
                  setIsServiceContractOpen(true);
                }}
              />
            )}
          <CarIcon
            $isInspectionFinished={
              service.inspection.status === InspectionStatus.FINISHED
            }
            data-tooltip-id="service-card-tooltip"
            data-tooltip-content="Relatório de vistoria"
            variant="Bold"
            onClick={(event) => {
              event.preventDefault();
              setIsInspectionModalOpen(true);
            }}
          />
          {service.type === ServiceType.OWN && service.cte && !isClientUser && (
            <img
              src={cteIssuanceStatusProps[service.cte.status].imageSource}
              alt="CT-e ícone"
              data-tooltip-id="service-card-tooltip"
              data-tooltip-content={
                cteIssuanceStatusProps[service.cte.status].tooltipMessage
              }
              onClick={(event) => {
                event.preventDefault();
                setIsCteModalOpen(true);
              }}
            />
          )}
          {service.type === ServiceType.OWN &&
            service.cte?.endorsement &&
            !isClientUser && (
              <img
                src={
                  cteEndorsementStatusProps[service.cte?.endorsement.status]
                    .imageSource
                }
                alt="Averbação de CT-e ícone"
                data-tooltip-id="service-card-tooltip"
                data-tooltip-content={
                  cteEndorsementStatusProps[service.cte?.endorsement.status]
                    .tooltipMessage
                }
                onClick={(event) => {
                  event.preventDefault();
                  setIsCteEndorsementModalOpen(true);
                }}
              />
            )}
          <DeliveryReceiptIcon
            data-tooltip-id="service-card-tooltip"
            data-tooltip-content="Termo de entrega"
            variant="Bold"
            $isDeliveryReceiptSent={service.delivery_receipt.has_file}
            onClick={(event) => {
              event.preventDefault();
              setIsDeliveryReceiptModalOpen(true);
            }}
          />
          <ServiceCategory>
            {service.category?.name || service.type}
          </ServiceCategory>
        </IconContainer>
      </Container>
      <InspectionModal
        isOpen={isInspectionModalOpen}
        onClose={() => setIsInspectionModalOpen(false)}
        service={service}
        onCancelInspectionSuccess={() =>
          queryClient.invalidateQueries('services')
        }
      />
      {service.type === ServiceType.OWN && (
        <>
          <CteIssuanceModal
            service={service}
            isOpen={isCteModalOpen}
            onClose={() => setIsCteModalOpen(false)}
            refetchServices={() =>
              queryClient.invalidateQueries(['services', queryParams, scope])
            }
          />
          <CteEndorsementModal
            isOpen={isCteEndorsementModalOpen}
            onClose={() => setIsCteEndorsementModalOpen(false)}
            service={service}
            refetchServices={() =>
              queryClient.invalidateQueries(['services', queryParams, scope])
            }
          />
        </>
      )}
      <DeliveryReceiptModal
        deliveryReceiptId={service.delivery_receipt.id}
        serviceNumber={service.number}
        hasDelivered={service.delivery_receipt.has_file}
        isOpen={isDeliveryReceiptModalOpen}
        onClose={() => setIsDeliveryReceiptModalOpen(false)}
        onSubmitSuccess={() => {
          queryClient.invalidateQueries([
            'services',
            { status: ServiceStatus.WAITING_COLLECTION },
          ]);
          queryClient.invalidateQueries([
            'services',
            { status: ServiceStatus.FINISHED },
          ]);
        }}
      />
      {service.type !== ServiceType.STORAGE && !!service.contract?.status && (
        <ServiceContractModal
          isOpen={isServiceContractOpen}
          onClose={() => setIsServiceContractOpen(false)}
          clientName={service.client.name}
          serviceNumber={service.number}
          contractId={service.contract.id}
          contractStatus={service.contract.status}
          onSubmitSuccess={() => {
            queryClient.invalidateQueries('services');
          }}
        />
      )}
    </>
  );
};

export default ServiceCard;
