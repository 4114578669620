import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.clients.listClients.path,
    label: 'Clientes',
  },
  {
    path: RoutesPath.private.clients.createClient.path,
    label: 'Cadastrar cliente',
  },
];

export default routes;
