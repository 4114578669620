import React, { useRef } from 'react';

import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useProviders, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';

import RoutesPath from '@router/routes';

import ProviderInfo from '../Form/ProviderInfo';
import routes from './routes';

const CreateProvider: React.FC = () => {
  const providerInfoStepRef = useRef<IFormStepRef>(null);

  const { createProvider } = useProviders();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const createProviderRequest = useMutation(
    (provider: IProviderInfoFormValues) => createProvider(provider),
    {
      onSuccess: () => {
        addToast('Fornecedor cadastrado com sucesso', 'success');
        navigate(RoutesPath.private.providers.listProviders.path, {
          replace: true,
        });
      },
      onError: () => {
        addToast('Erro ao cadastrar fornecedor', 'error');
      },
    }
  );

  useVerifyScope({
    routePath: RoutesPath.private.providers.listProviders.path,
    toastErrorMessage: 'Selecione uma empresa para cadastrar um fornecedor',
  });

  const steps: Array<IStep> = [
    {
      title: 'Informações do fornecedor',
      ref: providerInfoStepRef,
      component: ProviderInfo,
    },
  ];

  return (
    <BaseForm
      steps={steps}
      createRequest={createProviderRequest}
      routes={routes}
      title="Cadastrar fornecedor"
    />
  );
};

export default CreateProvider;
