import React, { useCallback, useState } from 'react';

import { AxiosError } from 'axios';
import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import { B2Button } from 'react-b2components';
import { useMutation } from 'react-query';
import { useTheme } from 'styled-components';
import { throwBlobError, throwToastApiErrors } from 'utils/helpers';
import * as yup from 'yup';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useServices } from '@hooks';

import ConfirmationModal from '@components/ConfirmationModal';
import DocumentPreview from '@components/DeliveryReceiptModal/SubmitDeliveryReceipt/DocumentPreview';
import { CustomInput } from '@components/DeliveryReceiptModal/SubmitDeliveryReceipt/styles';
import DocumentPicker from '@components/DocumentPicker';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Label from '@components/Label';
import Loading from '@components/Loading';

import { errors } from '@utils';

import {
  Content,
  Description,
  DocumentDownloadButton,
  DocumentDownloadIcon,
} from './styles';

interface SubmitServiceContractProps {
  contractId: string;
  serviceNumber: string;
  clientName: string;
  onSubmitSuccess: () => void;
}

const SubmitServiceContract: React.FC<SubmitServiceContractProps> = ({
  contractId,
  clientName,
  serviceNumber,
  onSubmitSuccess,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { getServiceContractTemplate, uploadServiceContract } = useServices();
  const { isClientUser } = useAuth();
  const { addToast } = useToast();
  const theme = useTheme();

  const createURLFromBinary = useCallback((data: string) => {
    const blob = new Blob([data], {
      type: 'application/pdf',
    });
    const url = window.URL.createObjectURL(blob);

    return url;
  }, []);

  const downloadServiceContract = useCallback(
    (url: string) => {
      const anchor = document.createElement('a');
      anchor.download = `CONTRATO - ${clientName} - ${serviceNumber}`;
      anchor.href = url;
      document.body.appendChild(anchor);
      anchor.click();
    },
    [clientName, serviceNumber]
  );

  const downloadPDFFromBlob = useCallback(
    (data: string) => {
      const url = createURLFromBinary(data);
      downloadServiceContract(url);
    },
    [createURLFromBinary, downloadServiceContract]
  );

  const {
    mutate: requestContractTemplate,
    isLoading: isContractTemplateLoading,
  } = useMutation(() => getServiceContractTemplate(contractId), {
    onSuccess: (data) => {
      addToast('Contrato gerado com sucesso', 'success');
      downloadPDFFromBlob(data);
    },
    onError: async (error: AxiosError) => {
      addToast('Não foi possível gerar o contrato', 'error');
      throwBlobError(error, addToast);
    },
  });

  const {
    mutate: handleUploadServiceContract,
    isLoading: isUploadServiceContractLoading,
  } = useMutation(
    (values: IServiceContractFormValues) =>
      uploadServiceContract(contractId, values),
    {
      onSuccess: () => {
        addToast('Contrato enviado com sucesso', 'success');
        onSubmitSuccess();
        setIsConfirmationModalOpen(false);
      },
      onError: (error: AxiosError) => {
        addToast('Não foi possível enviar o contrato', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const formikInitialValues: IServiceContractFormValues = {
    file: null,
    signDate: '',
  };

  const validationSchema = yup.object().shape({
    file: yup
      .object()
      .nullable()
      .notOneOf([null], errors.required)
      .required(errors.required),
    signDate: yup.string().required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema,
    onSubmit: () => setIsConfirmationModalOpen(true),
  });

  if (isClientUser) {
    return (
      <Content>
        <Description>Visualizar modelo de contrato</Description>
        <DocumentDownloadButton onClick={() => requestContractTemplate()}>
          {isContractTemplateLoading ? (
            <Loading />
          ) : (
            <>
              Modelo de contrato <DocumentDownloadIcon variant="Bold" />
            </>
          )}
        </DocumentDownloadButton>
      </Content>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Content>
        <Description>
          Baixe o contrato, envie para o cliente assinar, e faça o upload do
          contrato assinado
        </Description>
        <FormRow>
          <FormGroup>
            <Label>Baixar contrato</Label>
            <DocumentDownloadButton onClick={() => requestContractTemplate()}>
              {isContractTemplateLoading ? (
                <Loading color={theme.colors.white.main} />
              ) : (
                <>
                  Modelo de contrato <DocumentDownloadIcon variant="Bold" />
                </>
              )}
            </DocumentDownloadButton>
          </FormGroup>
          <FormGroup>
            <Label>Anexar contrato assinado *</Label>
            <DocumentPicker
              onAddFile={(file) => formik.setFieldValue('file', file)}
            />
            <FormError name="file" />
          </FormGroup>
        </FormRow>
        <FormRow>
          <FormGroup>
            <Label>Data da assinatura do contrato *</Label>
            <CustomInput
              type="date"
              name="signDate"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.signDate}
              max={moment().format('YYYY-MM-DD')}
              invalidValue={
                !!formik.touched.signDate && !!formik.errors.signDate
              }
            />
            <FormError name="signDate" />
          </FormGroup>
        </FormRow>
        <DocumentPreview
          document={formik.values.file}
          onDeleteDocument={() => formik.setFieldValue('file', undefined)}
        />
        <B2Button
          variant="secondary"
          type="submit"
          onClick={() => formik.handleSubmit()}
          disabled={isUploadServiceContractLoading}
        >
          Finalizar
        </B2Button>
      </Content>
      <ConfirmationModal
        title={`Tem certeza que deseja enviar o contrato para o serviço ${serviceNumber}?`}
        message="Confira se os dados informados estão corretos. Ao enviar o contrato assinado, você não poderá mais editá-lo."
        onClose={() => setIsConfirmationModalOpen((prevState) => !prevState)}
        onConfirm={() => handleUploadServiceContract(formik.values)}
        isLoading={isUploadServiceContractLoading}
        isOpen={isConfirmationModalOpen}
        confirmButtonText="Sim, enviar"
        cancelButtonText="Não, cancelar"
      />
    </FormikProvider>
  );
};

export default SubmitServiceContract;
