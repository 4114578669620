import React from 'react';

import CircleProgress from './CircularProgress';
import { Container, Subtitle, Title, TitlesContainer } from './styles';

interface IFormStepsProps {
  title: string;
  subtitle?: string;
  currentStep: number;
  stepsLength: number;
  isFormModal: boolean;
}

const FormSteps: React.FC<IFormStepsProps> = ({
  title,
  subtitle,
  currentStep,
  stepsLength,
  isFormModal,
}) => {
  return (
    <Container isFormModal={isFormModal}>
      <CircleProgress
        percentage={((currentStep + 1) / stepsLength) * 100}
        text={`${currentStep + 1} de ${stepsLength}`}
      />
      <TitlesContainer>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TitlesContainer>
    </Container>
  );
};

export default FormSteps;
