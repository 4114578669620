import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { CompanyUserRole, PaymentMethodListType } from 'utils/enums';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';

import PageHeader from '@components/PageHeader';
import Tabs from '@components/Tabs';

import RoutesPath from '@router/routes';

import { paymentListProps } from './paymentList';
import { tabs } from './tabs';

const PaymentMethods: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const { isCompanyUser, isManagementUser, userInfo } = useAuth();
  const { scope } = useScope();

  const getCurrentListType = useCallback(() => {
    const listPropsValues = Object.values(paymentListProps);
    const currentListProps = listPropsValues.find(
      (item) => item.listPath === location.pathname
    );

    return currentListProps
      ? currentListProps.type
      : PaymentMethodListType.PAYMENT_METHOD;
  }, [location.pathname]);

  const [selectedListType, setSelectListType] = useState(getCurrentListType());

  const { subtitle, actionButtonProps } = paymentListProps[selectedListType];
  const isSalesUser = isCompanyUser && userInfo?.role === CompanyUserRole.SALES;

  const navigateToCreatePage = useCallback(() => {
    if (!actionButtonProps?.createPath) {
      return;
    }

    if (isManagementUser && !scope) {
      addToast(actionButtonProps.toastMessage, 'error');
      return;
    }

    navigate(actionButtonProps.createPath);
  }, [addToast, actionButtonProps, isManagementUser, navigate, scope]);

  const filteredTabs = useMemo(() => {
    if (!isSalesUser) {
      return tabs;
    }

    return tabs.filter(
      (tab) =>
        tab.path !==
          RoutesPath.private.paymentMethods.listPaymentMethods.path &&
        tab.path !== RoutesPath.private.paymentMethods.listPaymentTerms.path
    );
  }, [isSalesUser]);

  useEffect(() => {
    const newListType = getCurrentListType();
    setSelectListType(newListType);
  }, [getCurrentListType]);

  return (
    <>
      <PageHeader
        title={selectedListType}
        subtitle={subtitle}
        buttonProps={
          actionButtonProps && {
            text: actionButtonProps.buttonText,
            onClick: navigateToCreatePage,
          }
        }
      />
      <Tabs tabs={filteredTabs} />
    </>
  );
};

export default PaymentMethods;
