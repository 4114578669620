import React from 'react';

import { currency } from 'b2utils';

import {
  BaseCard,
  BaseCardContent,
  BaseCardTitle,
  BaseCardValue,
  CustomLoading,
  MoneysIcon,
  MoneysIconWrapper,
} from '../styles';

interface MonthForecastCardProps {
  financialForecast?: IFinancialForecast;
  isLoading: boolean;
}

const MonthForecastCard: React.FC<MonthForecastCardProps> = ({
  financialForecast,
  isLoading,
}) => {
  return (
    <BaseCard>
      <MoneysIconWrapper>
        <MoneysIcon variant="Bold" />
      </MoneysIconWrapper>
      <BaseCardContent>
        <BaseCardTitle>Resultado previsto para o mês</BaseCardTitle>
        {isLoading ? (
          <CustomLoading />
        ) : (
          <BaseCardValue>
            {financialForecast
              ? currency.centsToBrl(
                  financialForecast.incomes.values_sum -
                    financialForecast.expenses.values_sum
                )
              : '-'}
          </BaseCardValue>
        )}
      </BaseCardContent>
    </BaseCard>
  );
};

export default MonthForecastCard;
