import RoutesPath from '@router/routes';

const getRoutes = (id: string, isManagementUser: boolean) => {
  return [
    {
      label: isManagementUser ? 'Usuários da empresa' : 'Usuários internos',
      path: RoutesPath.private.companyUsers.listCompanyUsers.path,
    },
    {
      label: 'Detalhes do usuário',
      path: RoutesPath.private.companyUsers.detailCompanyUser.path.replace(
        ':companyUserId',
        id
      ),
    },
  ];
};

export default getRoutes;
