import React from 'react';

import { useNavigate } from 'react-router-dom';
import { DepartureListOrdering, DepartureStatus, UserType } from 'utils/enums';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useQueryParams } from '@hooks';

import DepartureFilters from '@components/DepartureFilters';
import { KanbanColumns } from '@components/KanbanColumn';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import DepartureStatusColumn from './DepartureStatusColumn';

const ListDepartures: React.FC = () => {
  const { queryParams, setQueryParams, onSearch } = useQueryParams({
    pageSize: 10,
  } as IDeparturesQueryParams);

  const { userInfo } = useAuth();
  const { scope } = useScope();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const isManagementUser = userInfo?.type === UserType.MANAGEMENT;

  const departureStatuses = Object.values(DepartureStatus);

  const navigateToCreateDeparturePage = () => {
    if (isManagementUser && !scope) {
      addToast(
        'Não é possível cadastrar um embarque sem estar com uma empresa selecionada',
        'error'
      );
      return;
    }
    navigate(RoutesPath.private.departures.createDeparture.path);
  };

  return (
    <>
      <PageHeader
        title="Embarques"
        subtitle="Listagem de todos os embarques do sistema"
        buttonProps={{
          text: 'Cadastrar embarque',
          onClick: navigateToCreateDeparturePage,
        }}
      />
      <DepartureFilters
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        onSearch={onSearch}
      />
      <KanbanColumns length={departureStatuses.length}>
        {departureStatuses.map((status) => (
          <DepartureStatusColumn
            key={status}
            queryParams={{
              ...queryParams,
              status,
              ordering:
                status === DepartureStatus.WAITING_START
                  ? DepartureListOrdering.DATE
                  : DepartureListOrdering.DESCENDING_STATUS_CHANGED_AT,
            }}
          />
        ))}
      </KanbanColumns>
    </>
  );
};

export default ListDepartures;
