import React from 'react';

import { QuotationStatus } from 'utils/enums';

import MetricCard from './MetricCard';
import { FlexGroup } from './styles';

interface DashboardProps {
  yearMonth: string;
}

const Dashboard: React.FC<DashboardProps> = ({ yearMonth }) => {
  return (
    <FlexGroup>
      <FlexGroup>
        <MetricCard yearMonth={yearMonth} />
        <MetricCard yearMonth={yearMonth} status={QuotationStatus.WON} />
      </FlexGroup>
      <FlexGroup>
        <MetricCard yearMonth={yearMonth} status={QuotationStatus.LOST} />
        <MetricCard yearMonth={yearMonth} status={QuotationStatus.EXPIRED} />
      </FlexGroup>
    </FlexGroup>
  );
};

export default Dashboard;
