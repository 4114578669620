import { useCallback } from 'react';

import { DepartureStatus } from 'utils/enums';
import regex from 'utils/regex';

import { useApi } from '@contexts/Api';
import { endpoints } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const generateDepartureRequestObject = (departure: IDepartureFormValues) => {
  const requestObject = {
    provider: departure.provider?.id,
    driver: departure.driver?.id,
    ownership: departure.ownership,
    loading_zip_code: departure.loadingZipCode.replace(regex.onlyNumbers, ''),
    origin: departure.origin.id,
    destination_zip_code: departure.destinationZipCode.replace(
      regex.onlyNumbers,
      ''
    ),
    destination: departure.destination.id,
    date: departure.date,
    route_states: departure.routeStates?.length ? departure.routeStates : null,
    routes_observations: departure.routeObservations,
    services: departure.services.map((service) => service.id),
  };

  return requestObject;
};

const useDepartures = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const listDepartures = useCallback(
    async (params: IDeparturesQueryParams) => {
      const response = await request<IPaginated<IDeparture>>({
        method: 'get',
        url: endpoints.departures.list,
        headers: { scope },
        params: {
          page: params.page,
          status: params.status,
          page_size: params.pageSize || undefined,
          search: params.search || undefined,
          date__lte: params?.date__lte || undefined,
          date__gte: params?.date__gte || undefined,
          driver: params?.driver?.id || undefined,
          provider: params?.provider?.id || undefined,
          ordering: params?.ordering || undefined,
          origin__state: params?.originState || undefined,
          destination__state: params?.destinationState || undefined,
          vehicle_type: params?.vehicleType || undefined,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const createDeparture = useCallback(
    async (departure: IDepartureFormValues) => {
      const data = generateDepartureRequestObject(departure);
      const response = await request<IDeparture>({
        method: 'post',
        url: endpoints.departures.create,
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const getDeparture = useCallback(
    async (departureId: string) => {
      const response = await request<IDeparture>({
        method: 'get',
        url: endpoints.departures.get.replace(':departureId', departureId),
      });
      return response.data;
    },
    [request]
  );

  const updateDeparture = useCallback(
    async (departureId: string, departure: IDepartureFormValues) => {
      const data = generateDepartureRequestObject(departure);
      const response = await request<IDeparture>({
        method: 'patch',
        url: endpoints.departures.update.replace(':departureId', departureId),
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const updateDepartureStatus = useCallback(
    async (departureId: number, status: DepartureStatus) => {
      const response = await request<IDeparture>({
        method: 'patch',
        url: endpoints.departures.update.replace(
          ':departureId',
          departureId.toString()
        ),
        headers: { scope },
        data: { status },
      });

      return response.data;
    },
    [request, scope]
  );

  const cancelDeparture = useCallback(
    async (departureId: string, password: string) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.departures.cancel.replace(':departureId', departureId),
        data: { password },
      });
      return response.data;
    },
    [request]
  );

  const finishDeparture = useCallback(
    async (departureId: number, values: IFinishDepartureValues) => {
      const servicesSelectedInPages: Array<Array<number>> = Object.values(
        values.servicesSelectedPerPage
      );
      const servicesSelectedForDelivery = servicesSelectedInPages.flat();
      const response = await request<void>({
        method: 'post',
        url: endpoints.departures.finish.replace(
          ':departureId',
          departureId.toString()
        ),
        headers: { scope },
        data: {
          services_selected_for_delivery: values.isAllServicesSelected
            ? null
            : servicesSelectedForDelivery,
          finished_at: values.finishedAt,
        },
      });

      return response.data;
    },
    [request, scope]
  );

  const listComments = useCallback(
    async (departure: number) => {
      const response = await request<Array<IDepartureComment>>({
        method: 'get',
        url: endpoints.departures.comments.list,
        headers: { scope },
        params: {
          departure,
          paginate: false,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const createComment = useCallback(
    async (departure: string, text: string) => {
      const response = await request<IDepartureComment>({
        method: 'post',
        url: endpoints.departures.comments.create,
        headers: { scope },
        data: { departure, text },
      });

      return response.data;
    },
    [request, scope]
  );

  const updateComment = useCallback(
    async (commentId: number, text: string) => {
      const response = await request<IDepartureComment>({
        method: 'patch',
        url: endpoints.departures.comments.update.replace(
          ':commentId',
          commentId.toString()
        ),
        headers: { scope },
        data: { text },
      });

      return response.data;
    },
    [request, scope]
  );

  const deleteComment = useCallback(
    async (commentId: number, password: string) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.departures.comments.delete.replace(
          ':commentId',
          commentId.toString()
        ),
        data: { password },
      });

      return response.data;
    },
    [request]
  );

  const listDepartureLogs = useCallback(
    async (params: IDepartureLogQueryParams) => {
      const response = await request<IPaginated<IDepartureLog>>({
        method: 'get',
        url: endpoints.departures.logs.list,
        headers: { scope },
        params: {
          page: params.page,
          page_size: params.pageSize,
          departure: params.departure,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const listDepartureServices = useCallback(
    async (params: IDepartureServicesQueryParams) => {
      const response = await request<IPaginated<IDepartureService>>({
        method: 'get',
        url: endpoints.departures.services.list,
        headers: { scope },
        params: {
          page: params.page,
          departure: params.departure,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const updateDepartureServiceExpenseValue = useCallback(
    async ({ id, expenseValue }: IUpdateDepartureServiceExpenseValue) => {
      const response = await request<IDepartureService>({
        method: 'patch',
        url: endpoints.departures.services.update.replace(':id', id.toString()),
        data: {
          expense_value: Number(expenseValue.replace(regex.onlyNumbers, '')),
        },
      });

      return response.data;
    },
    [request]
  );

  return {
    listDepartures,
    createDeparture,
    getDeparture,
    updateDeparture,
    updateDepartureStatus,
    cancelDeparture,
    finishDeparture,
    listComments,
    createComment,
    updateComment,
    deleteComment,
    listDepartureLogs,
    listDepartureServices,
    updateDepartureServiceExpenseValue,
  };
};

export default useDepartures;
