import React from 'react';

import { InspectionStatus } from 'utils/enums';

import { useCompanyUsers } from '@hooks';

import ChooseItemFilter from '@components/ChooseItemFilter';
import ExpandableFilter from '@components/ExpandableFilter';
import ListFilters from '@components/ListFilters';
import Select from '@components/Select';

import { Container } from './styles';

interface FiltersProps {
  queryParams: IInspectionQueryParams;
  setQueryParams: React.Dispatch<React.SetStateAction<IInspectionQueryParams>>;
  onSearch: (value: string) => void;
}

const Filters: React.FC<FiltersProps> = ({
  queryParams,
  setQueryParams,
  onSearch,
}) => {
  const { listCompanyUsers } = useCompanyUsers();

  const emptyInspector = {
    id: 0,
    name: 'Todos',
  } as ICompanyUser;

  const inspectionStatuses = Object.values(InspectionStatus);

  const handleSelectInspector = (inspector: ICompanyUser) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      inspector,
    }));
  };

  const handleSelectInspectionStatus = (status: string) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      status: status ? (status as InspectionStatus) : undefined,
    }));
  };

  return (
    <Container>
      <ListFilters searchPlaceholder="Buscar por veículo" onSearch={onSearch} />
      <ExpandableFilter>
        <ChooseItemFilter
          filterValue={
            queryParams?.inspector?.id && queryParams?.inspector?.id !== 0
              ? queryParams?.inspector?.name
              : 'Vistoriador: todos'
          }
          modalTitle="Filtrar vistorias por vistoriador"
          fetch={listCompanyUsers}
          queryParams={{ isActive: 'true', canBeInChargeOfServices: false }}
          queryKey="companyUsers"
          inputLabel="Selecionar vistoriador *"
          selectedItemLabel="Vistoriador selecionado"
          shouldRenderSelectedItem
          onSelect={handleSelectInspector}
          selectedItem={queryParams.inspector || emptyInspector}
          additionalItem={emptyInspector}
          renderItemText={(inspector) => inspector.name}
        />
        <Select
          value={queryParams?.status || ''}
          onChange={(event) =>
            handleSelectInspectionStatus(event.target.value as InspectionStatus)
          }
        >
          <option value="">Situação da vistoria: todas</option>
          {inspectionStatuses.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </Select>
      </ExpandableFilter>
    </Container>
  );
};

export default Filters;
