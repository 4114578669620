import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing.two};
`;

export const TimeLineContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.six};
`;

export const Line = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: ${({ theme }) => theme.spacing.one};
  background-color: ${({ theme }) => theme.colors.gray.one};
`;

export const Circle = styled.div`
  position: absolute;
  width: ${({ theme }) => theme.spacing.three};
  height: ${({ theme }) => theme.spacing.three};
  background-color: ${({ theme }) => theme.colors.gray.one};
  border-radius: 50%;
`;

export const ItemContent = styled.div`
  flex: 1;
  display: flex;
  margin: ${({ theme }) => addSpacing(theme.spacing.three, '0')};
`;

export const EmptyMessage = styled.em`
  color: ${({ theme }) => theme.colors.gray.six};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const Footer = styled.div`
  margin: ${({ theme }) => addSpacing(theme.spacing.two, 'auto', '0')};
`;
