import React from 'react';

import { phone } from 'b2utils';
import { B2TableRow } from 'react-b2components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { formatDocument } from 'utils/formats';

import { useAuth } from '@contexts/Auth';
import { useScope } from '@contexts/Scope';
import { useToast } from '@contexts/Toast';
import { useClients, useQueryParams } from '@hooks';

import ListFilters from '@components/ListFilters';
import PageHeader from '@components/PageHeader';
import SituationBadge from '@components/SituationBadge';
import Table from '@components/Table';
import { TableDataCell } from '@components/Table/styles';

import RoutesPath from '@router/routes';

const ListClients: React.FC = () => {
  const { queryParams, onSearch, changePage, changeIsActiveStatus } =
    useQueryParams<IQueryParams>({ page: 1 });

  const { isManagementUser } = useAuth();
  const { listClients } = useClients();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { scope } = useScope();

  const tableHeaderData = isManagementUser
    ? ['Nome', 'Empresa', 'Documento', 'Telefone', 'Situação']
    : ['Nome', 'Documento', 'Telefone', 'Situação'];

  const { isLoading, data: clients } = useQuery(
    ['clients', queryParams, scope],
    () => listClients(queryParams),
    {
      onError: () => {
        addToast('Não foi possível carregar a lista de clientes', 'error');
      },
    }
  );

  const navigateToClientDetailPage = (clientId: number) => {
    navigate(
      RoutesPath.private.clients.detailClient.path.replace(
        ':clientId',
        clientId.toString()
      )
    );
  };

  const navigateToCreateClient = () => {
    if (isManagementUser && !scope) {
      addToast(
        'Não é possível cadastrar um cliente sem estar com uma empresa selecionada',
        'error'
      );
      return;
    }
    navigate(RoutesPath.private.clients.createClient.path);
  };

  return (
    <>
      <PageHeader
        title="Clientes"
        subtitle="Listagem de todos os clientes"
        buttonProps={{
          text: 'Cadastrar cliente',
          onClick: () => navigateToCreateClient(),
        }}
      />
      <ListFilters
        onSearch={onSearch}
        changeIsActiveStatus={{ queryParams, changeIsActiveStatus }}
      />
      <Table
        data={clients?.results || []}
        headerData={tableHeaderData}
        emptyMessage="Nenhum cliente encontrado"
        isLoading={isLoading}
        renderRow={(client) => (
          <B2TableRow
            key={client.id}
            onClick={() => navigateToClientDetailPage(client.id)}
          >
            <TableDataCell>{client.name}</TableDataCell>
            {isManagementUser && (
              <TableDataCell>{client.company.name}</TableDataCell>
            )}
            <TableDataCell>{formatDocument(client.document)}</TableDataCell>
            <TableDataCell>
              {phone.fromObject({
                areaCode: client.phone.area_code,
                number: client.phone.number,
              })}
            </TableDataCell>
            <TableDataCell>
              <SituationBadge isActive={client.is_active} />
            </TableDataCell>
          </B2TableRow>
        )}
        paginator
        amountPerPage={20}
        currentPage={queryParams.page}
        total={clients?.count}
        changePage={changePage}
      />
    </>
  );
};

export default ListClients;
