import React from 'react';

import { useFormikContext } from 'formik';
import { StateRegistrationStatus } from 'utils/enums';
import { formatDocument } from 'utils/formats';
import regex from 'utils/regex';

import {
  InputGroup,
  RadioButtonsContainer,
} from '@components/Base/PrivateBase/styles';
import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';

const VehicleOwnerInfo: React.FC = () => {
  const formik = useFormikContext<ITruckInfoStepForm>();

  const stateRegistrationStatuses = Object.values(StateRegistrationStatus);

  const isCnpj =
    formik.values.vehicleOwnerDocument.replace(regex.onlyNumbers, '').length ===
    14;

  const isStateRegistrationInputDisabled =
    !isCnpj ||
    formik.values.stateRegistrationStatus !== StateRegistrationStatus.TAXPAYER;

  return (
    <>
      <FormRow>
        <FormGroup>
          <Label htmlFor="vehicleOwnerName">
            Nome / Razão social do proprietário *
          </Label>
          <Input
            type="text"
            id="vehicleOwnerName"
            name="vehicleOwnerName"
            placeholder="Digite aqui"
            value={formik.values.vehicleOwnerName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalidValue={
              !!formik.touched.vehicleOwnerName &&
              !!formik.errors.vehicleOwnerName
            }
          />
          <FormError name="vehicleOwnerName" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="vehicleOwnerDocument">
            CPF / CNPJ do proprietário *
          </Label>
          <Input
            type="text"
            id="vehicleOwnerDocument"
            name="vehicleOwnerDocument"
            placeholder="Digite aqui"
            value={formik.values.vehicleOwnerDocument}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'vehicleOwnerDocument',
                formatDocument(event.target.value)
              )
            }
            maxLength={18}
            invalidValue={
              !!formik.touched.vehicleOwnerDocument &&
              !!formik.errors.vehicleOwnerDocument
            }
          />
          <FormError name="vehicleOwnerDocument" />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor="stateRegistration">
            Inscrição estadual {isCnpj && '*'}
          </Label>
          <RadioButtonsContainer>
            {stateRegistrationStatuses.map((value) => (
              <InputGroup key={value}>
                <Input
                  type="radio"
                  id={`stateRegistrationStatus${value}`}
                  name="stateRegistrationStatus"
                  checked={formik.values.stateRegistrationStatus === value}
                  onChange={() =>
                    formik.setFieldValue('stateRegistrationStatus', value)
                  }
                  onBlur={formik.handleBlur}
                  disabled={!isCnpj}
                />
                <Label htmlFor={`stateRegistrationStatus${value}`}>
                  {value}
                </Label>
              </InputGroup>
            ))}
            <FormError name="stateRegistrationStatus" />
          </RadioButtonsContainer>
          <Input
            type="text"
            id="stateRegistration"
            name="stateRegistration"
            placeholder="Digite aqui"
            minLength={2}
            maxLength={14}
            value={formik.values.stateRegistration}
            onBlur={formik.handleBlur}
            onChange={(event) =>
              formik.setFieldValue(
                'stateRegistration',
                event.target.value.replace(regex.onlyNumbers, '')
              )
            }
            disabled={isStateRegistrationInputDisabled}
            invalidValue={
              !!formik.touched.stateRegistration &&
              !!formik.errors.stateRegistration
            }
          />
          <FormError name="stateRegistration" />
        </FormGroup>
      </FormRow>
    </>
  );
};

export default VehicleOwnerInfo;
