import { useCallback } from 'react';

import { endpoints, useApi } from '@contexts/Api';
import { useScope } from '@contexts/Scope';

const useExpenseCategories = () => {
  const { request } = useApi();
  const { scope } = useScope();

  const listExpenseCategories = useCallback(
    async (params: IExpenseCategoryQueryParams) => {
      const response = await request<IPaginated<IExpenseCategory>>({
        method: 'get',
        url: endpoints.financial.expenses.categories.list,
        headers: { scope },
        params: {
          page: params.page,
          search: params.search || undefined,
          is_active: params.isActive || undefined,
          type: params.type || undefined,
        },
      });
      return response.data;
    },
    [request, scope]
  );

  const getExpenseCategory = useCallback(
    async (categoryId: string) => {
      const response = await request<IExpenseCategory>({
        method: 'get',
        url: endpoints.financial.expenses.categories.get.replace(
          ':categoryId',
          categoryId
        ),
        headers: { scope },
      });

      return response.data;
    },
    [request, scope]
  );

  const createExpenseCategory = useCallback(
    async (data: IExpenseCategoryFormValues) => {
      const response = await request<IExpenseCategory>({
        method: 'post',
        url: endpoints.financial.expenses.categories.create,
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const updateExpenseCategory = useCallback(
    async (categoryId: string, data: IExpenseCategoryFormValues) => {
      const response = await request<IExpenseCategory>({
        method: 'patch',
        url: endpoints.financial.expenses.categories.update.replace(
          ':categoryId',
          categoryId
        ),
        headers: { scope },
        data,
      });

      return response.data;
    },
    [request, scope]
  );

  const updateIsActiveExpenseCategoryStatus = useCallback(
    async (categoryId: number, isActive: boolean) => {
      const response = await request<IExpenseCategory>({
        method: 'patch',
        url: endpoints.financial.expenses.categories.update.replace(
          ':categoryId',
          categoryId.toString()
        ),
        data: { is_active: isActive },
      });

      return response.data;
    },
    [request]
  );

  return {
    listExpenseCategories,
    getExpenseCategory,
    createExpenseCategory,
    updateExpenseCategory,
    updateIsActiveExpenseCategoryStatus,
  };
};

export default useExpenseCategories;
