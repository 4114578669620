import { addOpacityOnHexColor } from 'b2utils/dist/color';
import { Car, DocumentText1, DocumentUpload } from 'iconsax-react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { SendingStatus, ServiceStatus } from 'utils/enums';
import { serviceStatusProps } from 'utils/helpers';
import { addSpacing } from 'utils/styles';

interface CommonStyleProps {
  status: ServiceStatus;
}

const IconStyles = css`
  width: ${({ theme }) => theme.spacing.seven};
  height: ${({ theme }) => theme.spacing.seven};
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`;

export const Container = styled(Link)<CommonStyleProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.one};
  border-radius: 12px;
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.three, theme.spacing.four)};
  background-color: ${({ theme, status }) =>
    addOpacityOnHexColor(serviceStatusProps[status].color(theme), 0.2)};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  &:has(svg:hover, img:hover) {
    opacity: 1;
  }
`;

export const PrimaryInfoStyle = styled.p`
  color: ${({ theme }) => theme.colors.black.two};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const DateInfoStyle = styled(PrimaryInfoStyle)`
  color: ${({ theme }) => theme.colors.info};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const ServiceNumber = styled(DateInfoStyle)`
  color: ${({ theme }) => theme.colors.gray.nine};
  align-self: center;
`;

export const SecondaryInfoStyle = styled(DateInfoStyle)`
  color: ${({ theme }) => theme.colors.gray.nine};
`;

export const InDepartureLabel = styled(SecondaryInfoStyle)`
  align-self: center;
  font-style: italic;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const ServiceCategory = styled.div`
  border-radius: 8px;
  width: fit-content;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.main};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  background-color: ${({ theme }) =>
    addOpacityOnHexColor(theme.colors.main, 0.2)};
  padding: ${({ theme }) => addSpacing(theme.spacing.one, theme.spacing.two)};
`;

export const IconContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.one};
  margin-top: ${({ theme }) => theme.spacing.two};
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  img {
    ${IconStyles}
    width: ${({ theme }) => theme.spacing.eight};
    height: ${({ theme }) => theme.spacing.eight};
  }
`;

export const CarIcon = styled(Car)<{ $isInspectionFinished: boolean }>`
  ${IconStyles}
  color: ${({ theme, $isInspectionFinished }) =>
    $isInspectionFinished ? theme.colors.success : theme.colors.gray.six};
`;

export const DocumentTextIcon = styled(DocumentText1)<{
  status: SendingStatus;
}>`
  ${IconStyles}
  color: ${({ theme, status }) =>
    status === SendingStatus.FINISHED
      ? theme.colors.success
      : theme.colors.gray.six};
`;

export const DeliveryReceiptIcon = styled(DocumentUpload)<{
  $isDeliveryReceiptSent: boolean;
}>`
  ${IconStyles}
  color: ${({ theme, $isDeliveryReceiptSent }) =>
    $isDeliveryReceiptSent ? theme.colors.success : theme.colors.gray.six};
`;
