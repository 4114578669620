import React from 'react';

import SearchFilter from '@components/SearchFilter';
import Select from '@components/Select';

import { Container } from './styles';

interface ListFiltersProps {
  variant?: boolean;
  searchPlaceholder?: string;
  onSearch: (value: string) => void;
  changeIsActiveStatus?: {
    changeIsActiveStatus: (value: string) => void;
    queryParams: IQueryParams;
  };
  children?: React.ReactNode;
}

const ListFilters: React.FC<ListFiltersProps> = ({
  variant = false,
  searchPlaceholder,
  onSearch,
  changeIsActiveStatus,
  children,
}) => {
  return (
    <Container variant={variant}>
      <SearchFilter placeholder={searchPlaceholder} onSearch={onSearch} />
      {changeIsActiveStatus && (
        <Select
          onChange={(event) =>
            changeIsActiveStatus.changeIsActiveStatus(event.target.value)
          }
          value={changeIsActiveStatus.queryParams?.isActive ?? ''}
        >
          <option value="">Todos</option>
          <option value="true">Ativos</option>
          <option value="false">Inativos</option>
        </Select>
      )}
      {children}
    </Container>
  );
};

export default ListFilters;
