import { cep } from 'b2utils';
import { DriverVehicleType } from 'utils/enums';
import { formatDate, formatDocument } from 'utils/formats';

export const getDepartureDetailedInfo = (
  departure: IDeparture
): Array<ISection> => {
  const shippingSection: ISection = {
    title: 'Informações do frete',
    fields: [
      {
        name: 'Origem:',
        description: `${departure.origin.name}/${departure.origin.state}`,
      },
      {
        name: 'CEP de origem:',
        description: `${cep.mask(departure.loading_zip_code)}`,
      },
      {
        name: 'Destino:',
        description: `${departure.destination.name}/${departure.destination.state}`,
      },
      {
        name: 'CEP de destino:',
        description: cep.mask(departure.destination_zip_code),
      },
      {
        name: 'Data de saída:',
        description: formatDate(departure.date),
      },
      {
        name: 'Data de chegada:',
        description: departure.finished_at
          ? formatDate(departure.finished_at)
          : 'Não finalizado',
      },
      {
        name: 'Peso (kg):',
        description: departure.weight || 'Não informado',
      },
      {
        name: 'Estados do percurso:',
        description: departure.route_states?.join(', ') || '-',
      },
      {
        name: 'Observações:',
        description:
          departure.route_observations || 'Nenhuma observação adicionada',
      },
    ],
  };

  const generalInfoSection: ISection = {
    title: 'Informações gerais',
    fields: [
      {
        name: 'Fornecedor:',
        description: departure.provider.name,
      },
      {
        name: 'Tipo do veículo:',
        description: departure.ownership,
      },
    ],
  };

  const vehicleSection: ISection = {
    title: 'Informações do veículo',
    fields: [
      {
        name: 'Tipo do veículo:',
        description: departure.truck.vehicle_type,
      },
      {
        name: 'Placa do caminhão:',
        description: departure.truck.truck_license_plate,
      },
      {
        name: 'Tara do caminhão (kg):',
        description: departure.truck.truck_tare_weight,
      },
      {
        name: 'ANTT:',
        description: departure.truck.antt,
      },
      {
        name: 'Proprietário do caminhão:',
        description: departure.truck.vehicle_owner_name,
      },
      {
        name: 'Documento do proprietário do caminhão:',
        description: formatDocument(departure.truck.vehicle_owner_document),
      },
    ],
  };

  if (departure.truck.vehicle_type === DriverVehicleType.CAR_CARRIER_TRAILER) {
    vehicleSection.fields.push(
      {
        name: 'Placa da carreta:',
        description: departure.truck.trailer_license_plate || '-',
      },
      {
        name: 'Tara da carreta (kg):',
        description: departure.truck.trailer_tare_weight || '-',
      },
      {
        name: 'Capacidade da carreta (kg):',
        description: departure.truck.trailer_payload_capacity || '-',
      }
    );
  }

  return [shippingSection, generalInfoSection, vehicleSection];
};
