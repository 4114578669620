import React from 'react';

import { useAuth } from '@contexts/Auth';

import {
  Container,
  RightPanel,
  Logo,
  Content,
  LeftPanel,
  Phrase,
  StrongPhrase,
  LeftPanelContent,
  EffectPhrases,
  RightPanelContent,
} from './styles';

interface IAuthBaseProps {
  children: React.ReactNode;
}

const AuthBase: React.FC<IAuthBaseProps> = ({ children }) => {
  const { companyLogo } = useAuth();

  return (
    <Container>
      <Content>
        <LeftPanel>
          <Logo
            src={companyLogo?.image_high_url || '/images/logo-variation.svg'}
            alt="Logo"
          />
          <LeftPanelContent>
            <EffectPhrases>
              <Phrase>Um sistema.</Phrase>
              <StrongPhrase>Muitas possibilidades.</StrongPhrase>
              <Phrase>Negócios fechados com segurança e transparência.</Phrase>
            </EffectPhrases>
          </LeftPanelContent>
        </LeftPanel>
        <RightPanel>
          <RightPanelContent>{children}</RightPanelContent>
        </RightPanel>
      </Content>
    </Container>
  );
};

export default AuthBase;
