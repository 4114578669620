import { color } from 'b2utils';
import { User } from 'iconsax-react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.three};
`;

export const UserContainer = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: ${({ theme }) => theme.spacing.eleven};
  height: ${({ theme }) => theme.spacing.eleven};
  background-color: ${({ theme }) =>
    color.addOpacityOnHexColor(theme.colors.main, 0.4)};
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const UserImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const UserIcon = styled(User)`
  width: ${({ theme }) => theme.spacing.ten};
  height: ${({ theme }) => theme.spacing.ten};
  padding: ${({ theme }) => theme.spacing.two};
  color: ${({ theme }) => theme.colors.white.main};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.main};
  flex-shrink: 0;
  outline: ${({ theme }) =>
    `${theme.spacing.one} solid ${color.addOpacityOnHexColor(
      theme.colors.main,
      0.2
    )}`};
`;

export const PersonInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PersonName = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const PersonResponsible = styled(PersonName)`
  color: ${({ theme }) => theme.colors.main};
  font-size: ${({ theme }) => theme.fontSize.smaller};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const UserImageModal = styled.img`
  max-width: 40%;
  border-radius: 8px;
  margin: ${({ theme }) => theme.spacing.four};
  min-width: 40%;
`;
