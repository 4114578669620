import React, { useEffect, useMemo } from 'react';

import Loading from 'components/Loading';
import { useToast } from 'contexts/Toast';
import { Form, FormikProvider, useFormik } from 'formik';
import { useAuthentication } from 'hooks';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import RoutesPath from 'router/routes';
import { errors } from 'utils';
import * as yup from 'yup';

import { FittedButton } from '@components/Base/PrivateBase/styles';
import BaseUpdatePasswordForm from '@components/BaseUpdatePasswordForm';

import { FormSubmitContainer } from './styles';

const UpdatePasswordForm: React.FC = () => {
  const { search } = useLocation();
  const { updatePassword } = useAuthentication();
  const { addToast } = useToast();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const token = query.get('j8');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(RoutesPath.auth.login.path);
    }
  }, [navigate, token]);

  const updatePasswordRequest = useMutation(
    (values: IUpdatePasswordFormValues) => updatePassword(values),
    {
      onSuccess: () => {
        addToast('Senha atualizada com sucesso', 'success');
        navigate(RoutesPath.auth.login.path);
      },
      onError: () => {
        addToast('Falha ao tentar atualizar senha', 'error');
      },
    }
  );

  const formikInitialValues: IUpdatePasswordFormValues = {
    password: '',
    confirmPassword: '',
    token: token || '',
  };

  const formikValidationSchema = yup.object().shape({
    password: yup
      .string()
      .trim()
      .required(errors.required)
      .min(8, errors.minLength(8)),
    confirmPassword: yup
      .string()
      .trim()
      .required(errors.required)
      .min(8, errors.minLength(8))
      .oneOf([yup.ref('password')], errors.passwordConfirm),
  });

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: (values) => updatePasswordRequest.mutate(values),
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <BaseUpdatePasswordForm />
        <FormSubmitContainer>
          {updatePasswordRequest.isLoading ? (
            <Loading />
          ) : (
            <FittedButton variant="primary" type="submit">
              Atualizar
            </FittedButton>
          )}
        </FormSubmitContainer>
      </Form>
    </FormikProvider>
  );
};

export default UpdatePasswordForm;
