import React from 'react';

import CommonCard from '@components/CommonCard';

import ExpensesListing from '@pages/Financial/FinancialDashboard/Tabs/ExpensesListing';

interface ExpensesListProps {
  provider: IProvider;
}

const ExpensesList: React.FC<ExpensesListProps> = ({ provider }) => {
  return (
    <CommonCard title="Lista de despesas">
      <ExpensesListing provider={provider} />
    </CommonCard>
  );
};

export default ExpensesList;
