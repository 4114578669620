import { UserType } from 'utils/enums';

const RoutesPath = {
  auth: {
    root: { path: '/entrar/' },
    login: { path: '/entrar/' },
    managementLogin: { path: '/entrar/admin/' },
    resetPassword: { path: '/esqueci-minha-senha/' },
    managementResetPassword: { path: '/esqueci-minha-senha/admin/' },
    updatePassword: { path: '/trocar-senha/' },
  },
  private: {
    home: {
      path: '/servicos/',
      userType: [UserType.MANAGEMENT, UserType.COMPANY, UserType.CLIENT],
    },
    profile: {
      editProfile: {
        path: '/perfil/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY, UserType.CLIENT],
      },
      manageAddresses: {
        path: '/perfil/meus-enderecos/',
        userType: [UserType.CLIENT],
      },
      editEmail: {
        path: '/perfil/alterar-email/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY, UserType.CLIENT],
      },
      editPassword: {
        path: '/perfil/alterar-senha',
        userType: [UserType.MANAGEMENT, UserType.COMPANY, UserType.CLIENT],
      },
      deleteAccount: {
        path: '/perfil/excluir-conta/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY, UserType.CLIENT],
      },
    },
    companies: {
      listCompanies: {
        path: '/empresas/',
        userType: [UserType.MANAGEMENT],
      },
      detailCompany: {
        path: '/empresas/detalhes/:companyId/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      updateCompany: {
        path: '/empresas/detalhes/:companyId/editar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      createCompany: {
        path: '/empresas/criar/',
        userType: [UserType.MANAGEMENT],
      },
    },
    companyUsers: {
      listCompanyUsers: {
        path: '/usuarios-da-empresa/',
        userType: [UserType.COMPANY, UserType.MANAGEMENT],
      },
      detailCompanyUser: {
        path: '/usuarios-da-empresa/:companyUserId/',
        userType: [UserType.COMPANY, UserType.MANAGEMENT],
      },
      createCompanyUser: {
        path: '/usuarios-da-empresa/criar/',
        userType: [UserType.COMPANY, UserType.MANAGEMENT],
      },
      updateCompanyUser: {
        path: '/usuarios-da-empresa/detalhes/:companyUserId/editar/',
        userType: [UserType.COMPANY, UserType.MANAGEMENT],
      },
    },
    managementUsers: {
      listManagementUsers: {
        path: '/usuarios-internos/',
        userType: [UserType.MANAGEMENT],
      },
      detailManagementUser: {
        path: '/usuarios-internos/:managementUserId/',
        userType: [UserType.MANAGEMENT],
      },
      createManagementUser: {
        path: '/usuarios-internos/criar/',
        userType: [UserType.MANAGEMENT],
      },
      updateManagementUser: {
        path: '/usuarios-internos/detalhes/:managementUserId/editar/',
        userType: [UserType.MANAGEMENT],
      },
    },
    clients: {
      listClients: {
        path: '/clientes/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      createClient: {
        path: '/clientes/criar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      detailClient: {
        path: '/clientes/detalhes/:clientId/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      editClient: {
        path: '/clientes/detalhes/:clientId/editar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
    },
    services: {
      listServices: {
        path: '/servicos/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY, UserType.CLIENT],
      },
      detailService: {
        path: '/servicos/detalhes/:serviceId/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY, UserType.CLIENT],
      },
      createService: {
        path: '/servicos/criar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      editService: {
        path: '/servicos/detalhes/:serviceId/editar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      editLastServiceLocation: {
        path: '/servicos/detalhes/:serviceId/editar-localizacao-atual/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
    },
    providers: {
      listProviders: {
        path: '/fornecedores/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      detailProvider: {
        path: '/fornecedores/detalhes/:providerId/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      createProvider: {
        path: '/fornecedores/criar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      editProvider: {
        path: '/fornecedores/detalhes/:providerId/editar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
    },
    drivers: {
      listDrivers: {
        path: '/motoristas/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      createDriver: {
        path: '/motoristas/criar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      detailDriver: {
        path: '/motoristas/detalhes/:driverId/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      editDriver: {
        path: '/motoristas/detalhes/:driverId/editar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
    },
    departures: {
      listDepartures: {
        path: '/embarques/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      createDeparture: {
        path: '/embarques/criar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      detailDeparture: {
        path: '/embarques/detalhes/:departureId/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      editDeparture: {
        path: '/embarques/detalhes/:departureId/editar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
    },
    categories: {
      incomes: {
        listCategories: {
          path: '/categorias/recebiveis/',
          userType: [UserType.MANAGEMENT, UserType.COMPANY],
        },
        createCategory: {
          path: '/categorias/recebiveis/criar/',
          userType: [UserType.MANAGEMENT, UserType.COMPANY],
        },
        editCategory: {
          path: '/categorias/recebiveis/detalhes/:categoryId/editar/',
          userType: [UserType.MANAGEMENT, UserType.COMPANY],
        },
      },
      expenses: {
        listCategories: {
          path: '/categorias/despesas/',
          userType: [UserType.MANAGEMENT, UserType.COMPANY],
        },
        createCategory: {
          path: '/categorias/despesas/criar/',
          userType: [UserType.MANAGEMENT, UserType.COMPANY],
        },
        editCategory: {
          path: '/categorias/despesas/detalhes/:categoryId/editar/',
          userType: [UserType.MANAGEMENT, UserType.COMPANY],
        },
      },
    },
    financial: {
      financialTransactions: {
        createFinancialTransaction: {
          path: '/financeiro/movimentacoes/criar/',
          userType: [UserType.MANAGEMENT, UserType.COMPANY],
        },
      },
      incomes: {
        listIncomes: {
          path: '/financeiro/recebiveis/',
          userType: [UserType.MANAGEMENT, UserType.COMPANY],
        },
        updateIncome: {
          path: '/financeiro/recebiveis/:incomeId/editar/',
          userType: [UserType.MANAGEMENT, UserType.COMPANY],
        },
      },
      expenses: {
        listExpenses: {
          path: '/financeiro/despesas/',
          userType: [UserType.MANAGEMENT, UserType.COMPANY],
        },
        updateExpense: {
          path: '/financeiro/despesas/:expenseId/editar/',
          userType: [UserType.MANAGEMENT, UserType.COMPANY],
        },
      },
      transfers: {
        listTransfers: {
          path: '/financeiro/transferencias/',
          userType: [UserType.MANAGEMENT, UserType.COMPANY],
        },
        updateTransfer: {
          path: '/financeiro/transferencias/:transferId/editar/',
          userType: [UserType.MANAGEMENT, UserType.COMPANY],
        },
      },
    },
    paymentMethods: {
      listPaymentTerms: {
        path: '/pagamento/forma-de-pagamento/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      createPaymentTerm: {
        path: '/pagamento/forma-de-pagamento/criar',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      updatePaymentTerm: {
        path: '/pagamento/forma-de-pagamento/detalhes/:paymentTermId/editar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      listPaymentMethods: {
        path: '/pagamento/metodos-de-pagamento/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      createPaymentMethod: {
        path: '/pagamento/metodos-de-pagamento/criar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      updatePaymentMethod: {
        path: '/pagamento/metodos-de-pagamento/detalhes/:paymentMethodId/editar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
    },
    bankAccounts: {
      listBankAccounts: {
        path: '/contas-bancarias/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      createBankAccount: {
        path: '/contas-bancarias/criar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      updateBankAccount: {
        path: '/contas-bancarias/:bankAccountId/editar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
    },
    quotations: {
      listQuotations: {
        path: '/orcamentos/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      createQuotation: {
        path: '/orcamentos/criar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      updateQuotation: {
        path: '/orcamentos/:quotationId/editar/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
    },
    documents: {
      listServices: {
        path: '/documentos/servicos/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      listInspections: {
        path: '/documentos/vistorias/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      listDepartures: {
        path: '/documentos/embarques/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      listServiceContracts: {
        path: '/documentos/contratos/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      listTermOfReceipts: {
        path: '/documentos/termos-de-entrega/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      listCte: {
        path: '/documentos/cte/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
      listMDFe: {
        path: '/documentos/mdfe/',
        userType: [UserType.MANAGEMENT, UserType.COMPANY],
      },
    },
    dashboard: {
      path: '/dashboard/',
      userType: [UserType.MANAGEMENT, UserType.COMPANY],
    },
    confirmEmail: {
      path: '/confirmar-email/',
      userType: [UserType.MANAGEMENT, UserType.COMPANY, UserType.CLIENT],
    },
    deliveryReceipt: {
      path: '/termo-de-entrega/:receiptId/',
      userType: [UserType.MANAGEMENT, UserType.COMPANY, UserType.CLIENT],
    },
  },
  public: {
    confirmEmail: {
      path: '/confirmar-email/',
    },
    deliveryReceipt: {
      path: '/termo-de-entrega/:receiptId/',
    },
  },
};

export default RoutesPath;
