import RoutesPath from '@router/routes';

const getRoutes = (clientId: string) => {
  return [
    { path: RoutesPath.private.clients.listClients.path, label: 'Clientes' },
    {
      path: RoutesPath.private.clients.detailClient.path.replace(
        ':clientId',
        clientId
      ),
      label: 'Detalhes do cliente',
    },
    {
      path: RoutesPath.private.clients.editClient.path.replace(
        ':clientId',
        clientId
      ),
      label: 'Editar cliente',
    },
  ];
};

export default getRoutes;
