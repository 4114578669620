import React from 'react';

import { formatToBrlDate } from 'utils/formats';

import { useAuth } from '@contexts/Auth';

import {
  Card,
  Date,
  EditButton,
  EditedMessage,
  Header,
  IconsContainer,
  Message,
  TrashButton,
  UserName,
} from './styles';

interface CommentProps {
  comment: IComment;
  onUpdateClick: (comment: IComment) => void;
  onDeleteClick: (commentId: number) => void;
}

const Comment: React.FC<CommentProps> = ({
  comment,
  onUpdateClick,
  onDeleteClick,
}) => {
  const { userInfo, hasAdminPermission } = useAuth();

  const hasPermissionToEdit = comment.user.id === userInfo?.id;

  return (
    <Card>
      <Header>
        <Date>{formatToBrlDate(comment.created)}</Date>
        <IconsContainer>
          {hasPermissionToEdit && (
            <EditButton onClick={() => onUpdateClick(comment)} />
          )}
          {hasAdminPermission && (
            <TrashButton onClick={() => onDeleteClick(comment.id)} />
          )}
        </IconsContainer>
      </Header>
      <div>
        <UserName>{comment.user.name}</UserName>
        <Message> comentou</Message>
      </div>
      <div>
        <Message>{comment.text}</Message>
        {comment.was_edited && <EditedMessage>(editado)</EditedMessage>}
      </div>
    </Card>
  );
};

export default Comment;
