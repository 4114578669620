import React, { useMemo, useState } from 'react';

import ReactDOM from 'react-dom';

import { useAuth } from '@contexts/Auth';

import { sidebarData } from '../../sidebarData';
import {
  CloseButton,
  HamburgerMenu,
  MenuItem,
  Modal,
  SideBarContainer,
} from './styles';

const MobileMenu: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);

  const { userInfo } = useAuth();

  const container = useMemo(() => document.getElementById('app'), []);

  const sideBarItems: Array<ISidebarSection> = useMemo(
    () => (userInfo ? sidebarData(userInfo) : []),
    [userInfo]
  );

  return (
    <>
      <HamburgerMenu onClick={() => setShowMenu(!showMenu)} />
      {ReactDOM.createPortal(
        <Modal isOpen={showMenu}>
          <CloseButton onClick={() => setShowMenu(false)} />
          {sideBarItems.map(({ sideBarItems }, index) => (
            <SideBarContainer key={index}>
              {sideBarItems.map(({ name, route }, index) => (
                <MenuItem
                  key={index}
                  to={route}
                  onClick={() => setShowMenu(false)}
                >
                  {name}
                </MenuItem>
              ))}
            </SideBarContainer>
          ))}
        </Modal>,
        container!
      )}
    </>
  );
};

export default MobileMenu;
