import { DefaultTheme } from 'styled-components';

const lightTheme: DefaultTheme = {
  borderRadius: '1rem',
  boxShadow: '0px 4px 16px 0px #00000040',
  sidebarIconSize: '1.25rem',
  colors: {
    main: '#F36406',
    secondary: '#262626',
    mainDarkVariation: '#A94503',
    info: '#2F80ED',
    success: '#27AE60',
    danger: '#E2B93B',
    error: '#EB5757',
    black: {
      main: '#000000',
      one: '#191919',
      two: '#262626',
    },
    white: {
      main: '#FFFFFF',
      one: '#FAFAFA',
      two: '#F4F4F4',
      three: '#F1F1F1',
    },
    gray: {
      one: '#D9D9D9',
      two: '#CCCCCC',
      three: '#BFBFBF',
      four: '#BFBFBF',
      five: '#B2B2B2',
      six: '#999999',
      seven: '#8C8C8C',
      eight: '#808080',
      nine: '#737373',
      ten: '#666666',
      eleven: '#595959',
      twelve: '#4C4C4C',
    },
    complementary: {
      one: '#BADDEA',
      two: '#FA7560',
      three: '#FEBE3F',
      four: '#FE6786',
      five: '#55CCD4',
      six: '#02274F',
      seven: '#7D06F3',
    },
    buttonText: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
      outline: '#FDCF00',
      text: '#02274F',
    },
  },
  fontFamily: "'Open Sans', sans-serif",
  fontSize: {
    tiny: '0.625rem',
    smaller: '0.75rem',
    small: '0.875rem',
    regular: '1rem',
    medium: '1.125rem',
    large: '1.5rem',
    big: '2rem',
  },
  fontWeight: {
    bold: '700',
    semiBold: '600',
    medium: '500',
    regular: '400',
    light: '300',
  },
  spacing: {
    one: '0.25rem',
    two: '0.5rem',
    three: '0.75rem',
    four: '1rem',
    five: '1.25rem',
    six: '1.5rem',
    seven: '1.75rem',
    eight: '2rem',
    nine: '2.25rem',
    ten: '2.5rem',
    eleven: '2.75rem',
    twelve: '3rem',
  },
};

export { lightTheme };
