import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Container = styled.div`
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.four};
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.white.three};
  padding: ${({ theme }) => addSpacing(theme.spacing.four, theme.spacing.six)};
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};
`;
