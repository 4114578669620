import React, { useCallback, useState } from 'react';

import { AxiosError } from 'axios';
import { Edit2 } from 'iconsax-react';
import { useMutation } from 'react-query';
import { useTheme } from 'styled-components';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useFiscal } from '@hooks';

import ConfirmationModal from '@components/ConfirmationModal';
import Loading from '@components/Loading';

import { CustomButton, CustomTooltip } from '../styles';

interface RectifyCteButtonProps {
  cte: ICte;
  hasUnsavedData: boolean;
  onRequestSuccess: () => void;
  openUnsavedDataModal: () => void;
}

const RectifyCteButton: React.FC<RectifyCteButtonProps> = ({
  cte,
  hasUnsavedData,
  onRequestSuccess,
  openUnsavedDataModal,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { rectifyCte } = useFiscal();
  const { addToast } = useToast();
  const theme = useTheme();

  const { mutate: requestRectifyCte, isLoading: isRectifyCteRequestLoading } =
    useMutation(() => rectifyCte(cte.id), {
      onSuccess: () => {
        onRequestSuccess();
        addToast('CT-e corrigido com sucesso', 'success');
      },
      onError: (error: AxiosError) => {
        addToast('Erro ao corrigir CT-e', 'error');
        throwToastApiErrors(error, addToast);
      },
      onSettled: () => {
        setIsConfirmationModalOpen(false);
      },
    });

  const handleConfirmClick = useCallback(() => {
    if (hasUnsavedData) {
      openUnsavedDataModal();
    } else {
      setIsConfirmationModalOpen(true);
    }
  }, [hasUnsavedData, openUnsavedDataModal]);

  return (
    <>
      <CustomTooltip id="rectify-cte-tooltip" />
      <CustomButton
        data-tooltip-id="rectify-cte-tooltip"
        data-tooltip-content="Corrigir CT-e"
        onClick={() => !isRectifyCteRequestLoading && handleConfirmClick()}
      >
        {isRectifyCteRequestLoading ? (
          <Loading color={theme.colors.white.main} />
        ) : (
          <>
            <Edit2 variant="Bold" />
            <span>Corrigir CT-e</span>
          </>
        )}
      </CustomButton>
      <ConfirmationModal
        title="Tem certeza que deseja corrigir o CT-e?"
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={requestRectifyCte}
        isLoading={isRectifyCteRequestLoading}
        confirmButtonText="Sim, corrigir"
        cancelButtonText="Não, cancelar"
      />
    </>
  );
};

export default RectifyCteButton;
