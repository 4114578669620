import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const Label = styled.span`
  hyphens: auto;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.colors.main};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Value = styled(Label)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;
