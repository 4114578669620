import React from 'react';

import { Tooltip } from 'react-tooltip';

import RoutesPath from '@router/routes';

import LogCard from './LogCard';
import { StyledLink } from './styles';

const Log: React.FC<LogProps> = (props) => {
  if (!props.log.departure || typeof props.log.departure === 'number') {
    return <LogCard {...props} />;
  }

  return (
    <>
      <Tooltip id="log-tooltip" />
      <StyledLink
        to={RoutesPath.private.departures.detailDeparture.path.replace(
          ':departureId',
          props.log.departure.id.toString()
        )}
        target="_blank"
        data-tooltip-id="log-tooltip"
        data-tooltip-content="Ir para a página do embarque"
      >
        <LogCard {...props} />
      </StyledLink>
    </>
  );
};

export default Log;
