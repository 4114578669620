import styled, { css } from 'styled-components';

interface FiltersContainerProps {
  expanded: boolean;
}

export const FiltersContainer = styled.div<FiltersContainerProps>`
  display: grid;
  grid-template-rows: 0fr;
  transition: all 0.5s ease;
  overflow: hidden;

  select {
    border: 1px solid ${({ theme }) => theme.colors.white.three};
    background-color: ${({ theme }) => theme.colors.white.main};
    background-image: url('/images/arrow-drop-down-variant.svg');
  }

  ${({ theme, expanded }) =>
    expanded &&
    css`
      grid-template-rows: 1fr;
      margin-bottom: ${theme.spacing.four};
    `};
`;

export const FiltersWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  gap: ${({ theme }) => theme.spacing.three};

  & > * {
    flex: 1 1 250px;
  }
`;
