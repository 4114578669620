import { B2Card } from 'react-b2components';
import styled from 'styled-components';

export const Card = styled(B2Card)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.two};
`;

export const Date = styled.span`
  font-size: ${({ theme }) => theme.fontSize.smaller};
  color: ${({ theme }) => theme.colors.gray.ten};
`;

export const UserName = styled.span`
  font-size: ${({ theme }) => theme.fontSize.smaller};
  color: ${({ theme }) => theme.colors.info};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Message = styled.span`
  hyphens: auto;
  font-size: ${({ theme }) => theme.fontSize.smaller};
  color: ${({ theme }) => theme.colors.gray.ten};
`;

export const BoldMessage = styled(Message)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
