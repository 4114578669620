import React, { useState } from 'react';

import Header from './Header';
import SideBar from './SideBar';
import { Container, MainContent, PrivateBaseContent } from './styles';

interface IPrivateBaseProps {
  children: React.ReactNode;
}

const PrivateBase: React.FC<IPrivateBaseProps> = ({ children }) => {
  const [collapsedSideBar, setCollapsedSideBar] = useState(true);

  return (
    <Container>
      <Header />
      <MainContent>
        <SideBar
          collapsedSideBar={collapsedSideBar}
          setCollapsedSideBar={setCollapsedSideBar}
        />
        <PrivateBaseContent sideBarCollapsed={collapsedSideBar}>
          {children}
        </PrivateBaseContent>
      </MainContent>
    </Container>
  );
};

export default PrivateBase;
