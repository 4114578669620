import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.categories.incomes.listCategories.path,
    label: 'Categorias',
  },
  {
    path: RoutesPath.private.categories.incomes.createCategory.path,
    label: 'Cadastrar categoria de recebível',
  },
];

export default routes;
