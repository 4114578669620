import React, { useRef } from 'react';

import { UseMutationResult } from 'react-query';

import BaseForm from '@components/BaseForm';

import ExpenseInfoForm from './ExpenseInfoForm';

interface IExpenseFormProps {
  onFinish: UseMutationResult<IExpense, unknown, IExpenseFormValues, unknown>;
  onGoBackOnFirstStep?: () => void;
  initialValues?: IExpenseFormValues;
}

const ExpenseForm: React.FC<IExpenseFormProps> = ({
  onFinish,
  onGoBackOnFirstStep,
  initialValues,
}) => {
  const expenseInfoFormRef = useRef<IFormStepRef>(null);

  const handleGoBackOnFirstStep = () => {
    onGoBackOnFirstStep?.();
    expenseInfoFormRef.current?.resetForm?.();
  };

  return (
    <BaseForm
      initialValues={initialValues}
      onGoBackOnFirstStep={
        onGoBackOnFirstStep ? handleGoBackOnFirstStep : undefined
      }
      createRequest={onFinish}
      steps={[
        {
          title: 'Informações da despesa',
          ref: expenseInfoFormRef,
          component: ExpenseInfoForm,
        },
      ]}
    />
  );
};

export default ExpenseForm;
