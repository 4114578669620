import React, { forwardRef, useImperativeHandle } from 'react';

import { cep } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import regex from 'utils/regex';
import * as yup from 'yup';

import BaseAddressForm from '@components/BaseAddressForm';

import { errors } from '@utils';

interface ISingleAddressFormProps {
  initialValues?: IAddressFormik;
  onFinish: (data: IAddressFormik) => void;
}

const SingleAddressForm: React.ForwardRefRenderFunction<
  IFormStepRef,
  ISingleAddressFormProps
> = ({ onFinish, initialValues }, ref) => {
  const formikInitialValues: IAddressesFormik = {
    addresses: [
      {
        city: {
          id: initialValues?.address?.city.id || 0,
          name: initialValues?.address?.city.name || '',
          state: initialValues?.address?.city.state || '',
        },
        street: initialValues?.address?.street || '',
        number: initialValues?.address?.number || '',
        complement: initialValues?.address?.complement || '',
        district: initialValues?.address?.district || '',
        zipCode: cep.mask(initialValues?.address?.zipCode || ''),
      },
    ],
  };

  const addressValidationSchema = yup.object().shape({
    street: yup.string().trim().required(errors.required),
    district: yup.string().trim().required(errors.required),
    number: yup.string().trim().required(errors.required),
    complement: yup.string().trim(),
    zipCode: yup
      .string()
      .trim()
      .test('is-valid-zipCode', errors.length(8), (value) => {
        const onlyNumbers = value?.replace(regex.onlyNumbers, '');

        return onlyNumbers?.length === 8;
      })
      .required(errors.required),
    city: yup.object().shape({
      id: yup.number().min(1, errors.required).required(errors.required),
      name: yup.string().trim().required(errors.required),
      state: yup.string().trim().required(errors.required),
    }),
  });

  const formikValidationSchema = yup.object().shape({
    addresses: yup.array().of(addressValidationSchema),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: (values) => onFinish({ address: values.addresses[0] }),
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <BaseAddressForm />
    </FormikProvider>
  );
};

export default forwardRef(SingleAddressForm);
