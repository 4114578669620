import { B2Button, B2Card } from 'react-b2components';
import styled from 'styled-components';

export const Content = styled(B2Card)`
  padding: ${({ theme }) => theme.spacing.five};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing.three};
  gap: ${({ theme }) => theme.spacing.three};
`;

export const Warning = styled.p`
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const DeleteButton = styled(B2Button)`
  color: ${({ theme }) => theme.colors.error};
  border-color: ${({ theme }) => theme.colors.error};

  &:hover {
    background-color: ${({ theme }) => theme.colors.error};
    color: ${({ theme }) => theme.colors.white.one};
  }
`;
