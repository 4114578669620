import {
  UserTick,
  Buildings,
  Car,
  Category,
  People,
  Personalcard,
  Profile2User,
  TruckFast,
  ClipboardText,
  Category2,
} from 'iconsax-react';
import RoutesPath from 'router/routes';

export const getLogisticsSidebarData = (
  companyId: number
): Array<ISidebarSection> => [
  {
    label: 'Início',
    sideBarItems: [
      {
        name: 'Dashboard',
        icon: Category2,
        route: RoutesPath.private.dashboard.path,
      },
      {
        name: 'Orçamentos',
        icon: ClipboardText,
        route: RoutesPath.private.quotations.listQuotations.path,
      },
    ],
  },
  {
    label: 'Monitoramento',
    sideBarItems: [
      {
        name: 'Serviços',
        icon: Car,
        route: RoutesPath.private.services.listServices.path,
      },
      {
        name: 'Embarques',
        icon: TruckFast,
        route: RoutesPath.private.departures.listDepartures.path,
      },
    ],
  },
  {
    label: 'Gerenciamento',
    sideBarItems: [
      {
        name: 'Minha empresa',
        icon: Buildings,
        route: RoutesPath.private.companies.detailCompany.path.replace(
          ':companyId',
          companyId.toString()
        ),
      },
      {
        name: 'Clientes',
        icon: People,
        route: RoutesPath.private.clients.listClients.path,
      },
      {
        name: 'Motoristas',
        icon: Personalcard,
        route: RoutesPath.private.drivers.listDrivers.path,
      },
      {
        name: 'Documentos',
        icon: Category,
        route: RoutesPath.private.documents.listServices.path,
      },
    ],
  },
  {
    label: 'Usuários',
    sideBarItems: [
      {
        name: 'Usuários da empresa',
        icon: Profile2User,
        route: RoutesPath.private.companyUsers.listCompanyUsers.path,
      },
    ],
  },
  {
    label: 'Financeiro',
    sideBarItems: [
      {
        name: 'Fornecedores',
        icon: UserTick,
        route: RoutesPath.private.providers.listProviders.path,
      },
    ],
  },
];
