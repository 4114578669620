import React from 'react';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';
import { IBaseModalProps } from '@components/BaseModal';

import ReceiptDetail from './ReceiptDetail';
import SubmitDeliveryReceipt from './SubmitDeliveryReceipt';
import { Container } from './styles';

type DeliveryReceiptModalProps = Omit<IBaseModalProps, 'title' | 'children'> & {
  onSubmitSuccess: () => void;
  deliveryReceiptId: string;
  serviceNumber: string;
  hasDelivered?: boolean;
};

const DeliveryReceiptModal: React.FC<DeliveryReceiptModalProps> = ({
  deliveryReceiptId,
  serviceNumber,
  hasDelivered,
  isOpen,
  onClose,
  onSubmitSuccess,
  className,
}) => {
  return (
    <CustomBaseModal
      isOpen={isOpen}
      onClose={onClose}
      title="Termo de entrega"
      className={className}
    >
      <Container>
        {hasDelivered ? (
          <ReceiptDetail
            isModalOpen={isOpen}
            deliveryReceiptId={deliveryReceiptId}
            hasDelivered={hasDelivered}
          />
        ) : (
          <SubmitDeliveryReceipt
            deliveryReceiptId={deliveryReceiptId}
            serviceNumber={serviceNumber}
            onSubmitSuccess={() => {
              onSubmitSuccess();
              onClose();
            }}
          />
        )}
      </Container>
    </CustomBaseModal>
  );
};

export default DeliveryReceiptModal;
