import React from 'react';

import { useTheme } from 'styled-components';

import ColorLabel from './ColorLabel';
import { Container } from './styles';

const ColorLabels: React.FC = () => {
  const theme = useTheme();

  return (
    <Container>
      <ColorLabel color={theme.colors.success} label="Quebrado" />
      <ColorLabel color={theme.colors.danger} label="Arranhado" />
      <ColorLabel color={theme.colors.error} label="Amassado" />
    </Container>
  );
};

export default ColorLabels;
