import React, { useCallback, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useTheme } from 'styled-components';
import { CompanyUserRole, CteIssuanceStatus, ServiceStatus } from 'utils/enums';
import { throwToastApiErrors } from 'utils/helpers';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useFiscal } from '@hooks';

import ConfirmationModal from '@components/ConfirmationModal';
import Loading from '@components/Loading';

import { CustomButton } from './styles';

interface IssueCteButtonProps {
  cte: ICte;
  hasUnsavedData: boolean;
  onRequestSuccess: () => void;
  openUnsavedDataModal: () => void;
}

const IssueCteButton: React.FC<IssueCteButtonProps> = ({
  cte,
  hasUnsavedData,
  onRequestSuccess,
  openUnsavedDataModal,
}) => {
  const [isIssuanceConfirmationModalOpen, setIsIssuanceConfirmationModalOpen] =
    useState(false);

  const { hasAdminPermission, isCompanyUser, userInfo } = useAuth();
  const { issueCte } = useFiscal();
  const { addToast } = useToast();
  const theme = useTheme();

  const { mutate: requestIssueCte, isLoading: isCteIssueLoading } = useMutation(
    issueCte,
    {
      onSuccess: () => {
        addToast('CT-e emitido para a Sefaz', 'success');
        onRequestSuccess();
      },
      onError: (error: AxiosError) => {
        addToast('Erro ao emitir CT-e', 'error');
        throwToastApiErrors(error, addToast);
      },
    }
  );

  const handleIssueCteClick = useCallback(() => {
    if (hasUnsavedData) {
      openUnsavedDataModal();
    } else {
      setIsIssuanceConfirmationModalOpen(true);
    }
  }, [hasUnsavedData, openUnsavedDataModal]);

  const hasStatus = useMemo(
    () =>
      cte?.service.status !== ServiceStatus.CANCELED &&
      (cte?.issuance_status === CteIssuanceStatus.PENDING ||
        cte?.issuance_status === CteIssuanceStatus.FAILED),
    [cte?.issuance_status, cte?.service.status]
  );

  const hasPermission = useMemo(
    () =>
      hasAdminPermission ||
      (isCompanyUser && userInfo?.role === CompanyUserRole.LOGISTICS),
    [hasAdminPermission, isCompanyUser, userInfo?.role]
  );

  if (!hasStatus || !hasPermission) {
    return null;
  }

  return (
    <>
      <CustomButton onClick={() => !isCteIssueLoading && handleIssueCteClick()}>
        {isCteIssueLoading ? (
          <Loading color={theme.colors.white.main} />
        ) : (
          <>
            Emitir CT-e
            <img src="/images/cte.svg" alt="CT-e ícone" />
          </>
        )}
      </CustomButton>
      <ConfirmationModal
        title="Tem certeza que deseja emitir o CT-e?"
        isOpen={isIssuanceConfirmationModalOpen}
        isLoading={false}
        confirmButtonText="Sim, emitir"
        onConfirm={() => {
          requestIssueCte(cte.id);
          setIsIssuanceConfirmationModalOpen(false);
        }}
        cancelButtonText="Não, cancelar"
        onClose={() => setIsIssuanceConfirmationModalOpen(false)}
      />
    </>
  );
};

export default IssueCteButton;
