import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.paymentMethods.listPaymentTerms.path,
    label: 'Formas de pagamento',
  },
  {
    path: RoutesPath.private.paymentMethods.createPaymentTerm.path,
    label: 'Cadastrar forma de pagamento',
  },
];
export default routes;
