import styled, { css } from 'styled-components';
import { addSpacing } from 'utils/styles';

interface ISwitchButtonProps {
  isActive: boolean;
}

export const SwitchContainer = styled.div`
  display: flex;
  position: relative;
  font-size: ${({ theme }) => theme.fontSize.small};
  border-radius: ${({ theme }) => theme.borderRadius};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  background-color: ${({ theme }) => theme.colors.white.three};
`;

export const SwitchBackground = styled.div<ISwitchButtonProps>`
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: ${({ isActive }) => (isActive ? 0 : '50%')};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.success : theme.colors.error};
  transition: left 0.5s, background-color 0.5s ease-in-out;
`;

export const SwitchButton = styled.div<ISwitchButtonProps>`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: ${({ theme }) => addSpacing(theme.spacing.one, theme.spacing.eight)};
  transition: all 0.5s ease-in-out;
  ${({ isActive, theme }) =>
    isActive
      ? css`
          color: ${theme.colors.white.one};
          cursor: default;
        `
      : css`
          color: ${theme.colors.gray.five};
          cursor: pointer;
        `}
`;
