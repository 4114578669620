import { B2Button } from 'react-b2components';
import styled from 'styled-components';

export const CustomButton = styled(B2Button)`
  display: flex;
  margin-left: auto;
  align-items: center;
  color: ${({ theme }) => theme.colors.white.main};
  background-color: ${({ theme }) => theme.colors.success};
  gap: ${({ theme }) => theme.spacing.two};
  margin-top: ${({ theme }) => theme.spacing.two};

  img {
    width: ${({ theme }) => theme.spacing.six};
    height: ${({ theme }) => theme.spacing.six};
  }
`;
