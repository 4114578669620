import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { cnpj, currency } from 'b2utils';
import { ArrowRotateLeft } from 'iconsax-react';
import { B2TableRow } from 'react-b2components';
import { useMutation, useQuery } from 'react-query';
import { useTheme } from 'styled-components';
import { CteEndorsementStatus } from 'utils/enums';
import { throwToastApiErrors } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useFiscal } from '@hooks';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';
import { IBaseModalProps } from '@components/BaseModal';
import ConfirmationModal from '@components/ConfirmationModal';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Loading from '@components/Loading';
import ModalTable from '@components/ModalTable';
import { TableDataCell } from '@components/Table/styles';

import StatusLabel from './StatusLabel';
import { CustomButton, ModalContent } from './styles';

interface CteEndorsementModalProps
  extends Omit<IBaseModalProps, 'title' | 'children'> {
  service: IService;
  refetchServices: () => void;
}

const CteEndorsementModal: React.FC<CteEndorsementModalProps> = ({
  isOpen,
  onClose,
  service,
  refetchServices,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { getCteEndorsement, retryCteEndorsement } = useFiscal();
  const { addToast } = useToast();
  const theme = useTheme();

  const {
    data: cteEndorsement,
    refetch: refetchCteEndorsement,
    isLoading: isCteEndorsementLoading,
  } = useQuery(
    ['cteEndorsement', service.cte?.endorsement?.id],
    () => getCteEndorsement(service.cte!.endorsement!.id),
    {
      onSuccess: () => {
        refetchServices();
      },
      onError: () => {
        addToast(
          'Não foi possível carregar os dados da averbação do  CT-e',
          'error'
        );
      },
      enabled: isOpen && !!service.cte?.endorsement,
    }
  );

  const {
    mutate: requestRetryEndorseCte,
    isLoading: isRetryCteEndorsementLoading,
  } = useMutation(() => retryCteEndorsement(service.cte!.endorsement!.id), {
    onSuccess: () => {
      refetchCteEndorsement();
      refetchServices();
      addToast('CT-e averbado com sucesso', 'success');
    },
    onError: (error: AxiosError) => {
      addToast('Erro ao averbar CT-e', 'error');
      throwToastApiErrors(error, addToast);
    },
    onSettled: () => {
      setIsConfirmationModalOpen(false);
    },
  });

  const isLoading = isCteEndorsementLoading || !cteEndorsement;

  return (
    <CustomBaseModal
      title={`Seguro do serviço ${service.number}`}
      isOpen={isOpen}
      onClose={onClose}
      headerComponent={
        cteEndorsement?.status === CteEndorsementStatus.FAILED && (
          <CustomButton
            onClick={() =>
              !isRetryCteEndorsementLoading && setIsConfirmationModalOpen(true)
            }
          >
            {isRetryCteEndorsementLoading ? (
              <Loading color={theme.colors.white.main} />
            ) : (
              <>
                Tentar novamente
                <ArrowRotateLeft />
              </>
            )}
          </CustomButton>
        )
      }
    >
      <ModalContent>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <StatusLabel
              cteEndorsement={cteEndorsement}
              isLoading={isCteEndorsementLoading}
            />
            <FormRow>
              <FormGroup>
                <Label>Seguradora</Label>
                <Input value={service.company.insurer_name} disabled />
              </FormGroup>
              <FormGroup>
                <Label>CNPJ da seguradora</Label>
                <Input
                  value={cnpj.mask(service.company.insurer_cnpj)}
                  disabled
                />
              </FormGroup>
            </FormRow>
            <FormRow>
              <FormGroup>
                <Label>Número da apólice</Label>
                <Input value={service.company.insurer_policy_number} disabled />
              </FormGroup>
              <FormGroup>
                <Label>Número da averbação</Label>
                <Input
                  value={cteEndorsement?.endorsement_number || '-'}
                  disabled
                />
              </FormGroup>
            </FormRow>
            <FormRow>
              <FormGroup>
                <Label>Protocolo</Label>
                <Input
                  value={cteEndorsement?.protocol_number || '-'}
                  disabled
                />
              </FormGroup>
            </FormRow>
            <ModalTable
              data={[service.vehicle]}
              isLoading={false}
              headerData={[
                'Cliente',
                'Identificador do veículo',
                'Valor do seguro',
                'Origem',
                'Destino',
              ]}
              emptyMessage="Nenhum veículo encontrado"
              renderRow={(vehicle) => (
                <B2TableRow key={vehicle.id}>
                  <TableDataCell>{service.client.name}</TableDataCell>
                  <TableDataCell>
                    {vehicle.model} - {vehicle.identifier}
                  </TableDataCell>
                  <TableDataCell>
                    {currency.centsToBrl(vehicle.insurance_value)}
                  </TableDataCell>
                  <TableDataCell>
                    {service.origin.name}/{service.origin.state}
                  </TableDataCell>
                  <TableDataCell>
                    {service.destination?.name}/{service.destination?.state}
                  </TableDataCell>
                </B2TableRow>
              )}
              hasClick={false}
            />
          </>
        )}
      </ModalContent>
      <ConfirmationModal
        title="Tem certeza que deseja averbar o CT-e?"
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={requestRetryEndorseCte}
        isLoading={isRetryCteEndorsementLoading}
        confirmButtonText="Sim, averbar"
        cancelButtonText="Não, cancelar"
      />
    </CustomBaseModal>
  );
};

export default CteEndorsementModal;
