import { DocumentDownload } from 'iconsax-react';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.five, theme.spacing.eight)};
`;

export const DownLoadIcon = styled(DocumentDownload)`
  width: ${({ theme }) => theme.spacing.eight};
  height: ${({ theme }) => theme.spacing.eight};
  color: ${({ theme }) => theme.colors.white.one};
`;
