import RoutesPath from '@router/routes';

const routes = [
  {
    path: RoutesPath.private.services.listServices.path,
    label: 'Serviços',
  },
  {
    path: RoutesPath.private.services.createService.path,
    label: 'Cadastrar serviço',
  },
];

export default routes;
