import React from 'react';

import { cep } from 'b2utils';
import { FormikProvider, useFormikContext } from 'formik';
import { useMutation, useQuery } from 'react-query';
import regex from 'utils/regex';

import { useToast } from '@contexts/Toast';
import { useBrasilApi, useCities } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import FormRow from '@components/FormRow';
import Input from '@components/Input';
import Label from '@components/Label';
import Select from '@components/Select';

import { helpers } from '@utils';

interface IBaseAddressFormProps {
  index?: number;
}

const BaseAddressForm: React.FC<IBaseAddressFormProps> = ({ index = 0 }) => {
  const formik = useFormikContext<IAddressesFormik>();

  const { addToast } = useToast();
  const { getAddressByZipCode } = useBrasilApi();
  const { listCitiesByState } = useCities();

  const { city: formikCity } = formik.values.addresses[index];

  const setCityFieldValue = (city: ICity) => {
    formik.setFieldValue(`addresses[${index}].city`, city);
  };

  const findCityAndSetField = (
    cities: Array<ICity>,
    query: {
      cityName?: string;
      cityId?: number;
    }
  ) => {
    const selectedCity = cities.find(
      (city) => city.id === query.cityId || city.name === query.cityName
    );
    if (selectedCity) {
      setCityFieldValue(selectedCity);
    }
  };

  const { data: cities, isLoading: isLoadingCities } = useQuery(
    ['citiesByState', formikCity.state],
    () => listCitiesByState({ state: formikCity.state }),
    {
      onSuccess: (cities) => {
        findCityAndSetField(cities, { cityName: formikCity.name });
      },
      onError: () => {
        addToast('Não foi possível carregar as cidades desse estado', 'error');
      },
      enabled: !!formikCity.state,
    }
  );

  const { mutate: fetchAddressByZipCode, isLoading: isLoadingAddress } =
    useMutation((zipCode: string) => getAddressByZipCode(zipCode), {
      onMutate: () => {
        addToast('Carregando informações a partir do CEP...', 'info');
      },
      onSuccess: (address: IBrasilApiAddress) => {
        formik.setFieldValue(
          `addresses[${index}].street`,
          address.street || ''
        );
        formik.setFieldValue(
          `addresses[${index}].district`,
          address.neighborhood || ''
        );

        if (cities && address.state === formikCity.state) {
          findCityAndSetField(cities, { cityName: address.city });
        } else {
          setCityFieldValue({
            id: 0,
            name: address.city,
            state: address.state,
          });
        }
      },
      onError: () => {
        clearAddressInfo();
        addToast(
          'Não foi possível carregar o endereço baseado no CEP informado',
          'error'
        );
      },
    });

  const isLoading = isLoadingAddress || isLoadingCities;

  const clearAddressInfo = () => {
    setCityFieldValue({
      id: 0,
      name: '',
      state: '',
    });
    formik.setFieldValue(`addresses[${index}].street`, '');
    formik.setFieldValue(`addresses[${index}].number`, '');
    formik.setFieldValue(`addresses[${index}].complement`, '');
    formik.setFieldValue(`addresses[${index}].district`, '');
  };

  const handleChangeZipCodeText = (zipCode: string) => {
    const cleanZipCode = zipCode.replace(regex.onlyNumbers, '');

    if (cleanZipCode.length === 8) {
      fetchAddressByZipCode(cleanZipCode);
    }

    formik.setFieldValue(`addresses[${index}].zipCode`, cep.mask(cleanZipCode));
  };

  const handleSelectState = (state?: string) => {
    setCityFieldValue({
      id: 0,
      name: '',
      state: state || '',
    });
  };

  const handleSelectCity = (cityId: number) => {
    if (cityId !== 0 && cities) {
      findCityAndSetField(cities, { cityId: cityId });
    } else {
      setCityFieldValue({
        id: 0,
        name: '',
        state: formikCity.state,
      });
    }
  };

  return (
    <FormikProvider value={formik}>
      <FormRow>
        <FormGroup>
          <Label htmlFor={`addresses[${index}].zipCode`}>CEP *</Label>
          <Input
            type="text"
            id={`addresses[${index}].zipCode`}
            name={`addresses[${index}].zipCode`}
            placeholder="Ex: 00000-000"
            onBlur={formik.handleBlur}
            value={formik.values.addresses[index].zipCode}
            onChange={(event) => handleChangeZipCodeText(event.target.value)}
            invalidValue={
              !!formik.touched.addresses?.[index]?.zipCode &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore Formik has the wrong type for touched object
              !!formik.errors.addresses?.[index]?.zipCode
            }
          />
          <FormError name={`addresses[${index}].zipCode`} />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor={`addresses[${index}].street`}>Endereço *</Label>
          <Input
            type="text"
            id={`addresses[${index}].street`}
            name={`addresses[${index}].street`}
            placeholder="Informe o endereço"
            value={formik.values.addresses[index].street}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={isLoading}
            invalidValue={
              !!formik.touched.addresses?.[index]?.street &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore Formik has the wrong type for touched object
              !!formik.errors.addresses?.[index]?.street
            }
          />
          <FormError name={`addresses[${index}].street`} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor={`addresses[${index}].number`}>Número *</Label>
          <Input
            type="text"
            id={`addresses[${index}].number`}
            name={`addresses[${index}].number`}
            placeholder="Ex: 123"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.addresses[index].number}
            disabled={isLoading}
            maxLength={10}
            invalidValue={
              !!formik.touched.addresses?.[index]?.number &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore Formik has the wrong type for touched object
              !!formik.errors.addresses?.[index]?.number
            }
          />
          <FormError name={`addresses[${index}].number`} />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor={`addresses[${index}].city.state`}>Estado *</Label>
          <Select
            id={`addresses[${index}].city.state`}
            name={`addresses[${index}].city.state`}
            value={formik.values.addresses[index].city.state}
            onBlur={formik.handleBlur}
            onChange={(event) => handleSelectState(event.target.value)}
            disabled={isLoading}
            invalidValue={
              !!formik.touched.addresses?.[index]?.city?.state &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore Formik has the wrong type for touched object
              !!formik.errors.addresses?.[index]?.city?.state
            }
          >
            {helpers.ufOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <FormError name={`addresses[${index}].city.state"`} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor={`addresses[${index}].city.id"`}>Cidade *</Label>
          <Select
            id={`addresses[${index}].city.id"`}
            name={`addresses[${index}].city.id"`}
            value={formik.values.addresses[index].city.id}
            onChange={(event) => handleSelectCity(Number(event.target.value))}
            onBlur={formik.handleBlur}
            disabled={isLoading}
            invalidValue={
              !!formik.touched.addresses?.[index]?.city?.id &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore Formik has the wrong type for touched object
              !!formik.errors.addresses?.[index]?.city?.id
            }
          >
            <option value={0}>Selecione a cidade</option>
            {cities?.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </Select>
          <FormError name={`addresses[${index}].city.id"`} />
        </FormGroup>
      </FormRow>
      <FormRow>
        <FormGroup>
          <Label htmlFor={`addresses[${index}].district`}>Bairro *</Label>
          <Input
            type="text"
            id={`addresses[${index}].district`}
            name={`addresses[${index}].district`}
            placeholder="Nome do bairro"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.addresses[index].district}
            disabled={isLoading}
            invalidValue={
              !!formik.touched.addresses?.[index]?.district &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore Formik has the wrong type for touched object
              !!formik.errors.addresses?.[index]?.district
            }
          />
          <FormError name={`addresses[${index}].district`} />
        </FormGroup>
        <FormGroup>
          <Label htmlFor={`addresses[${index}].complement`}>Complemento</Label>
          <Input
            type="text"
            id={`addresses[${index}].complement`}
            name={`addresses[${index}].complement`}
            placeholder="Informe o complemento"
            value={formik.values.addresses[index].complement}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={isLoading}
            invalidValue={
              !!formik.touched.addresses?.[index]?.complement &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore Formik has the wrong type for touched object
              !!formik.errors.addresses?.[index]?.complement
            }
          />
          <FormError name={`addresses[${index}].complement`} />
        </FormGroup>
      </FormRow>
    </FormikProvider>
  );
};

export default BaseAddressForm;
