import React from 'react';

import useBankAccounts from 'hooks/BankAccounts';
import { toDateInput } from 'utils/helpers';

import { usePaymentMethods } from '@hooks';

import ChooseItemFilter from '@components/ChooseItemFilter';
import DateRangeCalendarFilter from '@components/DateRangeCalendarFilter';
import ListFilters from '@components/ListFilters';

interface BasePaymentListingFiltersProps {
  searchPlaceholder?: string;
  queryParams: IBasePaymentsQueryParams;
  setQueryParams: React.Dispatch<
    React.SetStateAction<IBasePaymentsQueryParams>
  >;
  onSearch: (value: string) => void;
}

const BasePaymentListingFilters: React.FC<BasePaymentListingFiltersProps> = ({
  searchPlaceholder = 'Buscar por observações',
  queryParams,
  setQueryParams,
  onSearch,
}) => {
  const { listBankAccounts } = useBankAccounts();
  const { listPaymentMethods } = usePaymentMethods();

  const handleDateRangeFilterSelected = (startDate?: Date, endDate?: Date) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      accrualDateGte: startDate ? toDateInput(startDate) : undefined,
      accrualDateLte: endDate ? toDateInput(endDate) : undefined,
    }));
  };

  const handleSelectPaymentMethod = (paymentMethod: IPaymentMethod) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      paymentMethod,
    }));
  };

  const handleSelectBankAccount = (bankAccount: IBankAccount) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      bankAccount,
    }));
  };

  const emptyPaymentMethod = {
    id: 0,
    name: 'Todos',
  } as IPaymentMethod;

  const emptyBankAccount = {
    id: 0,
    name: 'Todas',
  } as IBankAccount;

  return (
    <ListFilters
      variant
      onSearch={onSearch}
      searchPlaceholder={searchPlaceholder}
    >
      <DateRangeCalendarFilter
        clearableDateRange
        label="Selecionar data de competência"
        onDateRangeFilterSelected={handleDateRangeFilterSelected}
      />
      <ChooseItemFilter
        filterValue={
          queryParams?.paymentMethod?.id && queryParams?.paymentMethod?.id !== 0
            ? queryParams?.paymentMethod?.name
            : 'Método de pagamento: todos'
        }
        modalTitle="Filtrar pagamentos por método de pagamento"
        fetch={listPaymentMethods}
        queryParams={{ isActive: 'true' }}
        queryKey="paymentMethods"
        inputLabel="Selecionar método de pagamento *"
        selectedItemLabel="Método de pagamento selecionado"
        shouldRenderSelectedItem
        onSelect={handleSelectPaymentMethod}
        selectedItem={queryParams.paymentMethod || emptyPaymentMethod}
        additionalItem={emptyPaymentMethod}
        renderItemText={(bankAccount) => bankAccount.name}
      />
      <ChooseItemFilter
        filterValue={
          queryParams?.bankAccount?.id && queryParams?.bankAccount?.id !== 0
            ? queryParams?.bankAccount?.name
            : 'Conta bancária: todas'
        }
        modalTitle="Filtrar pagamentos por conta bancária"
        fetch={listBankAccounts}
        queryParams={{ isActive: 'true' }}
        queryKey="bankAccounts"
        inputLabel="Selecionar conta bancária *"
        selectedItemLabel="Conta bancária selecionada"
        shouldRenderSelectedItem
        onSelect={handleSelectBankAccount}
        selectedItem={queryParams.bankAccount || emptyBankAccount}
        additionalItem={emptyBankAccount}
        renderItemText={(bankAccount) => bankAccount.name}
      />
    </ListFilters>
  );
};

export default BasePaymentListingFilters;
