import React from 'react';

import { Tooltip } from 'react-tooltip';

import { Container, CustomLink, LinkIcon } from './styles';

interface LabelWithLinkProps {
  label: string;
  href: string;
  tooltipMessage?: string;
}

const LabelWithLink: React.FC<LabelWithLinkProps> = ({
  label,
  href,
  tooltipMessage,
}) => {
  const tooltipId = `label-with-link-${label}-tooltip`;

  return (
    <Container>
      {label}
      <CustomLink
        to={href}
        target="_blank"
        data-tooltip-id={tooltipId}
        data-tooltip-content={tooltipMessage}
        onClick={(event) => event.stopPropagation()}
      >
        {tooltipMessage && <Tooltip id={tooltipId} />}
        <LinkIcon />
      </CustomLink>
    </Container>
  );
};

export default LabelWithLink;
