import React, { useEffect, useState } from 'react';

import { useInfiniteQuery, useQuery } from 'react-query';
import { InspectionStatus, InspectionType, ServiceStatus } from 'utils/enums';
import { getNextPageParam } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useInspections } from '@hooks';

import Loading from '@components/Loading';

import InspectionByPaperCheckList from './InspectionByCheckList';
import InspectionByView from './InspectionByView';
import ModalFooter from './ModalFooter';
import {
  Container,
  EmptyInspectionContainer,
  EmptyInspectionDetailText,
  EmptyInspectionText,
} from './styles';

interface InspectionContentProps {
  isOpen: boolean;
  onClose: () => void;
  service: IService;
  onCancelInspectionSuccess: () => void;
}

const InspectionContent: React.FC<InspectionContentProps> = ({
  isOpen,
  onClose,
  service,
  onCancelInspectionSuccess,
}) => {
  const [enableFetchInspectionData, setEnableFetchInspectionData] =
    useState(isOpen);

  const { listInspections, listVehicleViews, listInspectionImages } =
    useInspections();
  const { addToast } = useToast();

  const { data: inspection, isLoading: isInspectionLoading } = useQuery(
    ['inspections', service.id],
    () => listInspections({ service: service.id }),
    {
      select: (data) => data.results[0],
      onError: () => {
        addToast('Não foi possível carregar as imagens da vistoria', 'error');
      },
      enabled: enableFetchInspectionData,
    }
  );

  const enableInspectionByPhotosFetch =
    enableFetchInspectionData &&
    !isInspectionLoading &&
    !inspection?.paper_checklist.length;

  const { data: vehicleViews, isLoading: isVehicleViewsLoading } = useQuery(
    ['vehicleViews'],
    listVehicleViews,
    {
      onError: () => {
        addToast('Não foi possível carregar as vistas do veículo', 'error');
      },
      enabled: enableInspectionByPhotosFetch,
    }
  );

  const {
    data: infinityInspectionImages,
    isLoading: isInspectionImagesLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['inspectionImages', service.inspection.id],
    ({ pageParam: page = 1 }) =>
      listInspectionImages({ page, inspection: service.inspection.id }),
    {
      onError: () => {
        addToast('Não foi possível carregar as imagens da vistoria', 'error');
      },
      getNextPageParam,
      enabled: enableInspectionByPhotosFetch,
    }
  );

  const isInspectionFinished = inspection?.status === InspectionStatus.FINISHED;
  const isServiceCanceled = service.status === ServiceStatus.CANCELED;
  const inspectionImages =
    infinityInspectionImages?.pages?.flatMap((page) => page.results) || [];

  const isLoading =
    isInspectionLoading ||
    isVehicleViewsLoading ||
    isInspectionImagesLoading ||
    !inspection;

  const inspectionByType = {
    [InspectionType.PAPER_CHECKLIST]: (
      <InspectionByPaperCheckList
        inspectionImages={inspection?.paper_checklist || []}
      />
    ),
    [InspectionType.PHOTOS]: (
      <InspectionByView
        vehicleViews={vehicleViews || []}
        inspectionImages={inspectionImages}
        infiniteQueryProps={{
          hasNextPage,
          fetchNextPage,
          isFetchingNextPage,
        }}
      />
    ),
  };

  useEffect(() => {
    if (isOpen) {
      setEnableFetchInspectionData(true);
    }
  }, [isOpen]);

  if (isLoading) {
    return <Loading />;
  }

  if (isInspectionFinished && inspection.type) {
    return (
      <Container>
        {inspectionByType[inspection.type]}
        <ModalFooter
          service={service}
          onClose={onClose}
          onCancelInspectionSuccess={onCancelInspectionSuccess}
        />
      </Container>
    );
  }

  return (
    <Container>
      <EmptyInspectionContainer>
        <EmptyInspectionText>
          {isServiceCanceled ? 'Serviço cancelado' : 'Vistoria em andamento'}
        </EmptyInspectionText>
        <EmptyInspectionDetailText>
          {isServiceCanceled
            ? 'O serviço foi cancelado, não é possível visualizar a vistoria'
            : 'Assim que finalizada, estará disponível para ser visualizada'}
        </EmptyInspectionDetailText>
      </EmptyInspectionContainer>
    </Container>
  );
};

export default InspectionContent;
