import React, { useRef } from 'react';

import { ServiceType, TakerType } from 'utils/enums';

import BaseForm from '@components/BaseForm';

import DestinyOriginForm from '@pages/Services/FormSteps/DestinyOriginForm';
import GeneralServiceInfo from '@pages/Services/FormSteps/GeneralServiceInfo';
import ServiceVehicleForm from '@pages/Services/FormSteps/ServiceVehicleForm';

const StorageService: React.FC<IServiceFormProps> = ({
  onFinish,
  onGoBackOnFirstStep,
  initialValues,
}) => {
  const generalServiceInfoStepRef = useRef<IFormStepRef>(null);
  const serviceVehicleStepRef = useRef<IFormStepRef>(null);
  const destinyStepRef = useRef<IFormStepRef>(null);

  const handleGoBackOnFirstStep = () => {
    onGoBackOnFirstStep?.();
    generalServiceInfoStepRef.current?.resetForm?.();
  };

  return (
    <BaseForm
      initialValues={{
        type: ServiceType.STORAGE,
        takerType: TakerType.ANOTHER,
        ...initialValues,
      }}
      onGoBackOnFirstStep={
        onGoBackOnFirstStep ? handleGoBackOnFirstStep : undefined
      }
      createRequest={onFinish}
      steps={[
        {
          title: 'Informações do serviço',
          ref: generalServiceInfoStepRef,
          component: GeneralServiceInfo,
        },
        {
          title: 'Origem do serviço',
          ref: destinyStepRef,
          component: DestinyOriginForm,
        },
        {
          title: 'Veículo',
          ref: serviceVehicleStepRef,
          component: ServiceVehicleForm,
        },
      ]}
    />
  );
};

export default StorageService;
