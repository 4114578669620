import React, { useRef } from 'react';

import { currency } from 'b2utils';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useTransfers, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';
import Loading from '@components/Loading';

import RoutesPath from '@router/routes';

import TransferInfoForm from '../Form/TransferForm/TransferInfoForm';
import routes from './routes';

const UpdateTransfer: React.FC = () => {
  const { transferId } = useParams<{ transferId: string }>();
  const transferFormRef = useRef<IFormStepRef>(null);

  const { getTransfer, updateTransfer } = useTransfers();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useVerifyScope({
    routePath: RoutesPath.private.financial.transfers.listTransfers.path,
    toastErrorMessage: 'Selecione uma empresa para editar uma transação',
  });

  const {
    data: transfer,
    refetch,
    isLoading,
  } = useQuery(['transfer', transferId], () => getTransfer(transferId!), {
    onError: () => {
      addToast('Falha ao buscar os dados da transação', 'error');
    },
    enabled: !!transferId,
  });

  const updateTransferRequest = useMutation(
    (values: ITransferFormValues) => updateTransfer(transferId!, values),
    {
      onSuccess: () => {
        refetch();
        addToast('Dados da transação atualizados com sucesso', 'success');
        navigate(RoutesPath.private.financial.transfers.listTransfers.path);
      },
      onError: () => {
        addToast('Erro ao tentar editar informações da transação', 'error');
      },
    }
  );

  const getInitialValues = (transfer: ITransfer): ITransferFormValues => {
    return {
      date: transfer.date,
      title: transfer.title || '',
      origin: transfer.origin || null,
      destination: transfer.destination,
      value: currency.brlMask(transfer.value.toString()),
      isCapitalInjection: transfer.is_financial_support,
    };
  };

  const steps: Array<IStep> = [
    {
      title: 'Informações da transação',
      ref: transferFormRef,
      component: TransferInfoForm,
      props: {
        isCapitalInjection: transfer?.is_financial_support,
      },
    },
  ];

  if (isLoading || !transfer) {
    return <Loading />;
  }

  return (
    <BaseForm
      steps={steps}
      createRequest={updateTransferRequest}
      routes={routes}
      title="Editar transação"
      initialValues={getInitialValues(transfer)}
    />
  );
};

export default UpdateTransfer;
