import { B2Button } from 'react-b2components';
import styled, { css, keyframes } from 'styled-components';
import { addSpacing } from 'utils/styles';

import BaseModal from '@components/BaseModal';
import FormGroup from '@components/FormGroup';

export const headerHeight = 70;
export const sideBarWidth = 232;
export const sideBarCollapsedWidth = 100;

export const baseContainerAnimation = keyframes`
  0%{
    transform: translateX(10%);
    opacity: 0;
  }
  100%{
    transform: translateX(0);
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100svw;
  height: 100svh;
`;

export const MainContent = styled.div`
  display: flex;
  height: calc(100svh - ${headerHeight}px);
  background-color: ${({ theme }) => theme.colors.white.three};
`;

export const PrivateBaseContent = styled.div<{ sideBarCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: ${({ theme }) => addSpacing(theme.spacing.eight, theme.spacing.ten)};
  transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;

  ${({ sideBarCollapsed }) =>
    sideBarCollapsed
      ? css`
          width: calc(100% - ${sideBarWidth}px);
          margin-left: ${sideBarWidth}px;
        `
      : css`
          width: calc(100% - ${sideBarCollapsedWidth}px);
          margin-left: ${sideBarCollapsedWidth}px;
        `};

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const BaseContainer = styled.div`
  flex: 1;
  width: 100%;
  animation: ${baseContainerAnimation} 0.5s ease-in-out;
`;

export const LogoutButton = styled.span`
  color: ${({ theme }) => theme.colors.main};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const RadioButtonsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.three};
  margin-bottom: ${({ theme }) => theme.spacing.two};

  p {
    margin: 0;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.three};

  input,
  label {
    cursor: pointer;
    margin: 0;
    font-size: ${({ theme }) => theme.fontSize.small};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }

  input:disabled + label {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const FittedButton = styled(B2Button)`
  width: 100%;
`;

export const CustomBaseModal = styled(BaseModal)`
  width: 100%;
  max-width: 90svw;

  & > div:first-of-type {
    width: 100%;
  }
`;

export const BaseFormWrapper = styled.div<{ visible: boolean }>`
  width: 100%;
  ${({ visible }) =>
    !visible &&
    css`
      display: none;
    `}
`;

export const CustomFormGroup = styled(FormGroup)`
  margin-top: 0;
`;
