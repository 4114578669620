import { B2Button } from 'react-b2components';
import styled from 'styled-components';
import { addSpacing } from 'utils/styles';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.two};
  padding: ${({ theme }) =>
    addSpacing(theme.spacing.five, theme.spacing.eight)};

  td {
    max-width: 160px;
  }
`;

export const CustomButton = styled(B2Button)`
  margin-left: auto;
`;
