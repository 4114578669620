import styled, { css } from 'styled-components';
import { addSpacing } from 'utils/styles';

export const HeaderContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  white-space: pre-wrap;
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => addSpacing(theme.spacing.two, '0')};
`;

export const HeaderButton = styled.button<{ isSelected: boolean }>`
  border: none;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s ease-in-out;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ isSelected, theme }) =>
    isSelected ? theme.fontWeight.bold : theme.fontWeight.light};
  padding: ${({ theme }) =>
    addSpacing('0', theme.spacing.two, theme.spacing.one)};

  @media (max-width: 510px) {
    font-size: ${({ theme }) => theme.fontSize.small};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${({ theme }) => theme.colors.main};
    `}
`;
