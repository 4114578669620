import React, { useRef } from 'react';

import { phone } from 'b2utils';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useCompanyUsers, useVerifyScope } from '@hooks';

import BaseForm from '@components/BaseForm';
import CompanyUserForm from '@components/CompanyUserForm';
import Loading from '@components/Loading';

import RoutesPath from '@router/routes';

import getRoutes from './routes';

const UpdateCompanyUser: React.FC = () => {
  const companyUserFormStepRef = useRef<IFormStepRef>(null);

  const { companyUserId } = useParams<{ companyUserId: string }>();

  const { updateCompanyUser, getCompanyUser } = useCompanyUsers();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useVerifyScope({
    routePath: RoutesPath.private.companyUsers.listCompanyUsers.path,
    toastErrorMessage:
      'Selecione uma empresa para editar um usuário de empresa',
  });

  const { data: companyUser, isLoading } = useQuery(
    ['companyUser', companyUserId],
    () => getCompanyUser(companyUserId!),
    {
      onError() {
        addToast(
          'Não foi possível carregar os dados do usuário interno',
          'error'
        );
      },
    }
  );

  const handleSubmitCompanyUser = useMutation(
    (companyUserValues: ICompanyUserFormValues) =>
      updateCompanyUser(companyUserId!, companyUserValues),
    {
      onSuccess: (_, companyUserFormValues) => {
        addToast('Usuário interno editado com sucesso', 'success');
        if (companyUserFormValues.email !== companyUser?.email) {
          addToast('Confirmação de troca de e-mail enviada', 'success');
        }
        navigate(
          RoutesPath.private.companyUsers.detailCompanyUser.path.replace(
            ':companyUserId',
            companyUserId!
          )
        );
      },
      onError: () => {
        addToast('Erro ao editar o usuário interno', 'error');
      },
    }
  );

  const initialValues: ICompanyUserFormValues = {
    avatar: companyUser?.avatar || null,
    name: companyUser?.name || '',
    email: companyUser?.email || '',
    document: companyUser?.document || '',
    phone: companyUser?.phone
      ? phone.fromObject({
          areaCode: companyUser.phone.area_code,
          number: companyUser.phone.number,
        })
      : '',
    role: companyUser?.role || undefined,
  };

  const steps: Array<IStep> = [
    {
      title: 'Informações do usuário',
      ref: companyUserFormStepRef,
      component: CompanyUserForm,
    },
  ];

  if (isLoading || !companyUser) {
    return <Loading />;
  }

  return (
    <BaseForm
      title="Editar usuário"
      createRequest={handleSubmitCompanyUser}
      steps={steps}
      routes={getRoutes(companyUserId!)}
      initialValues={initialValues}
    />
  );
};

export default UpdateCompanyUser;
