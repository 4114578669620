import { B2Card } from 'react-b2components';
import styled from 'styled-components';

export const Container = styled(B2Card)`
  width: 70svw;
  padding: 0;
  display: flex;
  align-items: center;
  min-width: 300px;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.white.main};

  @media (max-width: 900px) {
    width: auto;
  }

  @media (max-width: 768px) {
    width: 90svw;
    flex-direction: column-reverse;
  }
`;

export const LeftPanel = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.six} 5%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const InfoContainer = styled.div``;

export const RightPanel = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 375px) {
    display: none;
  }
`;

export const SecureLoginImage = styled.img`
  width: 90%;
`;
