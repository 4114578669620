import React, { useRef, useState } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { Tooltip } from 'react-tooltip';

import { useAuth } from '@contexts/Auth';
import { useToast } from '@contexts/Toast';
import { useAddresses } from '@hooks';

import { CustomBaseModal } from '@components/Base/PrivateBase/styles';
import ModalFooterButtons from '@components/ModalFooterButtons';
import SingleAddressForm from '@components/SingleAddressForm';

import AddressItem from './AddressItem';
import {
  ActionText,
  ActionsContainer,
  AddressFormContainer,
  Container,
  CustomButton,
  Header,
  InfoCircleIcon,
  Title,
} from './styles';

interface AddressesListProps {
  client: IClientUser;
}

const AddressesList: React.FC<AddressesListProps> = ({ client }) => {
  const addressFormRef = useRef<IFormStepRef>(null);

  const [isAddressFormModalOpen, setIsAddressFormModalOpen] = useState(false);

  const { isClientUser, updateUserInfo } = useAuth();
  const { createAddress } = useAddresses();
  const queryClient = useQueryClient();
  const { addToast } = useToast();

  const refetchClientData = () => {
    if (isClientUser) {
      updateUserInfo();
    } else {
      queryClient.invalidateQueries(['client', client.id.toString()]);
    }
  };

  const { mutate: requestCreateAddress, isLoading: isCreateAddressLoading } =
    useMutation(
      (address: IAddressForm) =>
        createAddress({ address, clientId: client.id }),
      {
        onSuccess: () => {
          addToast('Endereço cadastrado com sucesso', 'success');
          refetchClientData();
          setIsAddressFormModalOpen(false);
        },
        onError: () => {
          addToast('Erro ao tentar cadastrado endereço', 'error');
        },
      }
    );

  const isCnpjDocument = client.document.length === 14;

  return (
    <>
      <Tooltip id="address-list-tooltip" />
      <Container>
        <Header>
          <Title>{!isClientUser && 'Endereços'}</Title>
          <ActionsContainer>
            <ActionText
              data-tooltip-id="address-list-tooltip"
              data-tooltip-content="Este endereço será usado nos contratos dos serviços"
            >
              {isCnpjDocument ? 'Sede' : 'Residencial'}
              <InfoCircleIcon />
            </ActionText>
            <ActionText>Padrão</ActionText>
            {!isClientUser && (
              <>
                <ActionText>Editar</ActionText>
                <ActionText>Excluir</ActionText>
              </>
            )}
          </ActionsContainer>
        </Header>
        {client.addresses.map((address) => (
          <AddressItem
            key={address.id}
            address={address}
            clientId={client.id}
            defaultAddressId={client.default_address?.id}
            homeAddressId={client.home_address?.id}
            refetchClientData={refetchClientData}
            canEditAndDelete={!isClientUser}
          />
        ))}
        {!isClientUser && (
          <CustomButton
            variant="transparent"
            onClick={() => setIsAddressFormModalOpen(true)}
          >
            Adicionar novo endereço
          </CustomButton>
        )}
      </Container>
      <CustomBaseModal
        isOpen={isAddressFormModalOpen}
        onClose={() => setIsAddressFormModalOpen(false)}
        title="Cadastrar endereço"
      >
        {isAddressFormModalOpen && (
          <>
            <AddressFormContainer>
              <SingleAddressForm
                ref={addressFormRef}
                onFinish={({ address }) => requestCreateAddress(address)}
              />
            </AddressFormContainer>
            <ModalFooterButtons
              isLoading={isCreateAddressLoading}
              confirmText="Salvar alterações"
              onConfirm={() => addressFormRef.current?.submit()}
              cancelText="Cancelar"
              onCancel={() => setIsAddressFormModalOpen(false)}
            />
          </>
        )}
      </CustomBaseModal>
    </>
  );
};

export default AddressesList;
