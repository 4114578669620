import { CloseCircle } from 'iconsax-react';
import styled from 'styled-components';

export const Container = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white.three};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding-left: ${({ theme }) => theme.spacing.three};
  overflow: hidden;
  animation: fade-in 0.8s ease-in-out;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`;

export const DocumentName = styled.span`
  max-width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.info};
`;

export const DeleteDocumentIcon = styled(CloseCircle)`
  background-color: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.white.one};
  padding: ${({ theme }) => theme.spacing.two};
  width: ${({ theme }) => theme.spacing.twelve};
  height: ${({ theme }) => theme.spacing.twelve};
`;
