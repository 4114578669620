import React, { useMemo } from 'react';

import { CloseCircle, TickCircle } from 'iconsax-react';
import { B2Button } from 'react-b2components';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '@contexts/Auth';
import { useAuthentication } from '@hooks';

import Loading from '@components/Loading';
import SplashScreen from '@components/SplashScreen';

import RoutesPath from '@router/routes';

import { Card, Message } from './styles';

const BaseConfirmEmail: React.FC = () => {
  const { userInfo, updateUserInfo } = useAuth();
  const { confirmEmail } = useAuthentication();
  const { search } = useLocation();
  const navigate = useNavigate();

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const token = useMemo(() => query.get('token'), [query]);

  const { isLoading, error: queryError } = useQuery(
    ['confirmEmail'],
    () => confirmEmail(token!),
    {
      onSuccess: () => {
        if (userInfo) {
          updateUserInfo();
        }
      },
      retry: false,
    }
  );

  const hasError = !!queryError;

  if (isLoading) {
    return userInfo ? <Loading /> : <SplashScreen />;
  }

  return (
    <Card hasError={hasError}>
      {hasError ? (
        <>
          <CloseCircle />
          <Message>Não foi possível confirmar o e-mail!</Message>
        </>
      ) : (
        <>
          <TickCircle />
          <Message>E-mail confirmado com sucesso!</Message>
        </>
      )}
      {!userInfo && (
        <B2Button
          variant="outline"
          onClick={() => {
            navigate(RoutesPath.auth.login.path);
          }}
        >
          Ir para tela de login
        </B2Button>
      )}
    </Card>
  );
};

export default BaseConfirmEmail;
